import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const renameExistingProject = createAsyncThunk(
  "projects/renameExistingProject",
  async ({ projectId, newName }, { getState }) => {
    const token = getState().user.access_token; // Get the access token from the user slice of the Redux state
    const user_id = getState().user.user_id;
    await renameProject(projectId, newName, user_id, token);
    return { projectId, newName };
  }
);

export const renameExistingProjectReducer = {
  [renameExistingProject.fulfilled]: (state, action) => {
    const { projectId, newName } = action.payload;
    const project = state.projectList.find((p) => p.project_id === projectId);
    if (project) {
      project.project_name = newName;
      if (state.currentProjectId === projectId) {
        state.currentProject = project;
      }
    }
  },
};

export const renameProject = async (projectId, newName, user_id, token) => {
  console.log("renameProject called with:", { projectId, newName, user_id });
  const response = await fetchWithToken(
    `${API_URL}/api/rename-project`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        new_name: newName,
        user_id: user_id,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to rename project");
  }

  const result = await response.json();
  return result.status === "success"
    ? JSON.parse(result.data).projects
    : Promise.reject(result.message);
};
