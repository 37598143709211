import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const updateNotifications = createAsyncThunk(
  "user/updateNotifications",
  async ({ unread, dismissed }, { getState }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;

    const result = await updateNotificationsAPI(
      userId,
      unread,
      dismissed,
      token
    );
    return { unread, dismissed };
  }
);

export const updateNotificationsAPI = async (
  userId,
  unread,
  dismissed,
  token
) => {
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/update-notifications`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          unread: unread,
          dismissed: dismissed,
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to update notifications");
    }

    const result = await response.json();
    return result.status === "success" ? result.data : null;
  } catch (error) {
    console.error("Error updating notifications:", error);
    throw error;
  }
};
