import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateUserFile } from "../user.js";

export const manageEditableNotebookAction = createAsyncThunk(
  "project/manageEditableNotebook",
  async (
    { projectId, mode, notebookName, notebookId },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const token = getState().user.access_token;

      // Save chat-related fields
      const currentProject = getState().project.currentProject;
      const chatFields = {
        agent_chat_files: currentProject.agent_chat_files,
        agent_chat_next_token: currentProject.agent_chat_next_token,
        chat_chat_files: currentProject.chat_chat_files,
        chat_chat_next_token: currentProject.chat_chat_next_token,
        popup_chat_files: currentProject.popup_chat_files,
      };

      const result = await manageEditableNotebook(
        projectId,
        mode,
        notebookName,
        notebookId,
        token
      );

      // Merge chat fields with result
      const updatedResult = {
        ...result,
        ...chatFields,
      };

      // Update user files if files are present in the response
      if (result.files) {
        dispatch(
          updateUserFile({
            replaceAll: true,
            updatedFile: {
              ...getState().user.files,
              ...result.files,
            },
          })
        );
      }

      return updatedResult;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const manageEditableNotebookActionReducer = {
  [manageEditableNotebookAction.pending]: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  [manageEditableNotebookAction.fulfilled]: (state, action) => {
    state.isLoading = false;
    const updatedProject = action.payload;
    const projectIndex = state.projectList.findIndex(
      (p) => p.project_id === updatedProject.project_id
    );
    if (projectIndex !== -1) {
      state.projectList[projectIndex] = updatedProject;
      if (state.currentProjectId === updatedProject.project_id) {
        state.currentProject = updatedProject;
      }
    }
  },
  [manageEditableNotebookAction.rejected]: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const manageEditableNotebook = async (
  projectId,
  mode,
  notebookName,
  notebookId,
  token
) => {
  console.log("=== manageEditableNotebook API Call ===");
  console.log("Input parameters:", {
    projectId,
    mode,
    notebookName,
    notebookId,
    token: "REDACTED",
  });

  const response = await fetchWithToken(
    `${API_URL}/api/manage-editable-notebook`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        mode,
        notebook_name: notebookName,
        notebook_id: notebookId,
      }),
    },
    token
  );

  console.log("Response status:", response.status);

  if (!response.ok) {
    const errorText = await response.text();
    console.error("API Error:", errorText);
    throw new Error("Failed to manage notebook");
  }

  const result = await response.json();
  console.log("Raw API Response:", result);

  // Parse the nested project JSON string
  const parsedProject = result.data.project
    ? JSON.parse(result.data.project)
    : null;
  console.log("Parsed project data:", parsedProject);
  console.log("=== End manageEditableNotebook API Call ===");

  return result.status === "success"
    ? parsedProject
    : Promise.reject(result.message);
};
