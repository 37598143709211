import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { fetchArtifactData } from "../project.js";

export const applyChatBlock = createAsyncThunk(
  "projects/applyChatBlock",
  async (
    { filename, chatId, projectId, messageIndex },
    { getState, dispatch }
  ) => {
    const token = getState().user.access_token;
    const project = getState().project.currentProject;

    const fileId = Object.keys(project.files).find(
      (id) => project.files[id].file_name === filename
    );

    if (!fileId) {
      throw new Error(`File not found: ${filename}`);
    }

    try {
      const response = await fetchWithToken(
        `${API_URL}/api/apply-chat-block`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            file_id: fileId,
            chat_id: chatId,
            project_id: projectId,
            message_index: messageIndex,
          }),
        },
        token
      );

      if (!response.ok) {
        throw new Error("Failed to apply chat block");
      }

      const result = await response.json();
      if (result.status === "success") {
        // After successful apply, fetch the artifact data
        await dispatch(fetchArtifactData({ fileId }));
        return { ...result.data, fileId, messageIndex };
      } else {
        throw new Error(result.message || "Failed to apply chat block");
      }
    } catch (error) {
      console.error("Error in applyChatBlock:", error);
      throw error;
    }
  }
);

export const applyChatBlockReducer = {
  [applyChatBlock.fulfilled]: (state, action) => {
    const { fileId, messageIndex } = action.payload;
    if (state.currentProject?.files[fileId]) {
      const file = state.currentProject.files[fileId];
      const url = file.s3_url;
      if (url) {
        const lastDotIndex = url.lastIndexOf(".");
        const newUrl =
          lastDotIndex !== -1
            ? url.slice(0, lastDotIndex) + "_edit" + url.slice(lastDotIndex)
            : url + "_edit";

        state.currentProject.files[fileId].s3_url = newUrl;
        state.currentProject.files[fileId].applied_message_index = messageIndex;

        // Update the inEditState of the current project
        state.currentProject.inEditState = true;
      }
    }
    state.applyingChatBlock = null;
  },
  [applyChatBlock.pending]: (state, action) => {
    state.error = null;
    // The payload will be set by the component when dispatching
    state.applyingChatBlock = action.meta.arg;
  },
  [applyChatBlock.rejected]: (state, action) => {
    state.error = action.error.message;
    state.applyingChatBlock = null;
    console.error("Error in applyChatBlock:", action.error.message);
  },
};
