import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { fetchProject } from "./fetchProject.js";
import { fetchArtifactData } from "./fetchArtifactData.js";

export const navigateDesign = createAsyncThunk(
  "projects/navigateDesign",
  async ({ projectId, fileId, direction }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const response = await navigateDesignAPI(
      projectId,
      fileId,
      direction,
      token
    );

    // After getting the new s3_url, fetch the updated artifact data
    await dispatch(fetchArtifactData({ fileId }));

    return response;
  }
);

export const navigateDesignReducer = {
  [navigateDesign.fulfilled]: (state, action) => {
    const fileId = state.artifact.file_id;
    state.currentProject.files[fileId].s3_url = action.payload;
    state.artifact.s3_url = action.payload;
  },
  [navigateDesign.rejected]: (state, action) => {
    state.error = action.error.message;
  },
};

export const navigateDesignAPI = async (
  projectId,
  fileId,
  direction,
  token
) => {
  console.log("navigateDesignAPI called with:", {
    projectId,
    fileId,
    direction,
  });
  const response = await fetchWithToken(
    `${API_URL}/api/navigate-design`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        file_id: fileId,
        direction: direction,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to navigate design");
  }

  const result = await response.json();
  console.log(
    "navigateDesignAPI response:",
    result,
    "result.status",
    result["status"]
  );
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
