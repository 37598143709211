import { fetchWithToken, API_URL } from "./api.js";

export const createCheckoutSession = async (token, userId, quantity) => {
  const response = await fetchWithToken(
    `${API_URL}/api/create-checkout-session`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: userId, quantity: quantity }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to create checkout session");
  }

  const result = await response.json();
  console.log("createCheckoutSession response", result);

  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};

export const processSubscription = async (token, userId) => {
  const response = await fetchWithToken(
    `${API_URL}/api/process-subscription`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        tier: 20, // Base tier
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to process subscription");
  }

  const result = await response.json();
  // Return the data object containing the URL if success
  return result.status === "success"
    ? result.data
    : Promise.reject(result.error);
};

export const manageSubscription = async (token, userId) => {
  const response = await fetchWithToken(
    `${API_URL}/api/manage-subscription`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to get subscription management URL");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.error);
};

export const updateSpendLimit = async (
  token,
  userId,
  monthlySpendLimit,
  allowMonthlySpendLimit
) => {
  const response = await fetchWithToken(
    `${API_URL}/api/update-spend-limit`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        monthly_spend_limit: parseInt(monthlySpendLimit),
        allow_monthly_spend_limit: allowMonthlySpendLimit,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to update spend limit");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.error);
};
