// notebookThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const fetchChat = createAsyncThunk(
  "projects/fetchChat",
  async ({ projectId, chatId = null }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    try {
      console.log("fetchChat called with:", { projectId, chatId });
      const response = await fetchChatMessages(token, chatId);
      return { chat: response };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchChatReducer = {
  [fetchChat.pending]: (state) => {
    state.error = null;
  },
  [fetchChat.fulfilled]: (state, action) => {
    const { chat } = action.payload;
    if (chat && chat.chat_id) {
      // Ensure chat has the correct structure
      state.chat[chat.chat_id] = {
        chat_contents: chat.chat_contents.map((msg) => ({
          content: msg.content || "",
          file_upload: msg.file_upload || null,
          role: msg.role || msg.sender || "assistant",
          timestamp: msg.timestamp || new Date().toISOString(),
        })),
        chat_id: chat.chat_id,
        project_id: chat.project_id,
      };
      state.currentChatId = chat.chat_id;
    } else {
      // Clear chat state when chatId is null
      state.chat = {};
      state.currentChatId = null;
    }
    state.isChatLoading = false;
  },
  [fetchChat.rejected]: (state, action) => {
    state.error = action.error.message;
    state.isChatLoading = false;
  },
};

export const fetchChatMessages = async (token, chatId = null) => {
  console.log("fetchChatMessages called with:", { chatId });

  // Return early if chatId is explicitly null (not undefined)
  if (chatId === null) {
    return { chat_contents: [] };
  }

  const endpoint = `${API_URL}/api/chat-messages/${chatId}`;

  const response = await fetchWithToken(
    endpoint,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to fetch chat messages");
  }

  const result = await response.json();
  console.log("chat data", result.data);

  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
