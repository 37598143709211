import React, {
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import "../style/FileExplorerModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFileFromLibrary,
  updateUserFile,
  updateUserFiles,
  setPopupMessage,
} from "../store/user.js";
import {
  renameFileGlobal,
  deleteProjectFile,
  updateModal,
  addFileToProject,
  updateArtifact,
  addToolsFileIds,
} from "../store/project.js";
import {
  downloadFiles,
  updateFileProperties,
  checkFilenameUnique,
} from "../api/files_api.js";
import { fetchNextFiles } from "../store/user.js";
import { searchUserFiles } from "../store/user.js";

// Custom filter function for case-insensitive file name search
const fileNameFilter = (rows, id, filterValue) => {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue !== undefined
      ? String(rowValue)
          .toLowerCase()
          .includes(String(filterValue).toLowerCase())
      : true;
  });
};

function FileExplorerModal({ is_modal = false, is_tool = false }) {
  const [editingFile, setEditingFile] = useState(null);
  const [deletingFiles, setDeletingFiles] = useState(null);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const modalRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const { currentProjectId, projectList, currentProject, modal } = useSelector(
    (state) => state.project
  );
  const [mouseOutsideDropdown, setMouseOutsideDropdown] = useState(false);
  const token = useSelector((state) => state.user.access_token);
  const [rerender, setRerender] = useState(false); // Add this line
  const [isFileUploadInProgress, setIsFileUploadInProgress] = useState(false);
  const isLoadingFiles = useSelector((state) => state.project.isLoadingFiles);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [topologyState, setTopologyState] = useState({});
  const [parentFileState, setParentFileState] = useState({});
  const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);
  const selectAllCheckboxRef = useRef(null);
  const filesNextToken = useSelector((state) => state.user.files_next_token);
  const isSearchActive = useSelector((state) => state.user.isSearchActive);
  const persistedSearchTerm = useSelector((state) => state.user.searchTerm);
  const [activeFileType, setActiveFileType] = useState("all");
  const nextTokens = useSelector((state) => state.user.next_tokens);

  const closeDropdownWithDelay = useCallback(() => {
    const timer = setTimeout(() => {
      if (mouseOutsideDropdown) {
        setActiveDropdown(null);
      }
    }, 100); // 100ms delay

    return () => clearTimeout(timer);
  }, [mouseOutsideDropdown]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (activeDropdown && dropdownRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect();
        const isOutside =
          event.clientX < dropdownRect.left ||
          event.clientX > dropdownRect.right ||
          event.clientY < dropdownRect.top ||
          event.clientY > dropdownRect.bottom;

        setMouseOutsideDropdown(isOutside);
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [activeDropdown]);

  useEffect(() => {
    if (mouseOutsideDropdown) {
      return closeDropdownWithDelay();
    }
  }, [mouseOutsideDropdown, closeDropdownWithDelay]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !isLoadingFiles &&
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !event.target.closest(".file-dropdown") &&
        !event.target.closest(".file-upload-modal-dialog")
      ) {
        if (!deletingFiles) {
          if (
            modal.name === "upload_general" ||
            modal.name === "upload_project"
          ) {
            if (modal.name === "upload_general") {
              dispatch(
                updateModal({ name: "dna_archive_general", data: null })
              );
            } else if (modal.name === "upload_project") {
              dispatch(
                updateModal({ name: "dna_archive_project", data: null })
              );
            }
          } else if (
            modal.name === "dna_archive_general" ||
            modal.name === "dna_archive_project"
          ) {
            dispatch(updateModal({ name: "", data: null }));
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [deletingFiles, dispatch, modal.name, isLoadingFiles]);

  useEffect(() => {
    if (editingFile && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingFile]);

  const handleFileRename = (file) => {
    setEditingFile(file);
    setActiveDropdown(null);
  };

  const handleFileDownload = (file) => {
    console.log("Downloading file:", file);
    downloadFiles(file.id, "file", token);
    setActiveDropdown(null);
  };

  const handleDelete = (file) => {
    setSelectedFiles([file.id]);
    handleDeleteSelected();
    setActiveDropdown(null);
  };

  const handleFileRenameSubmit = async (file, newName) => {
    try {
      if (!user || !user?.user_id) {
        throw new Error("User ID is not available");
      }

      // Get the extensions
      const oldExtension = file.name.split(".").pop().toLowerCase();
      const newExtension = newName.split(".").pop().toLowerCase();

      // Check if the new name has the same extension
      if (newExtension !== oldExtension) {
        dispatch(setPopupMessage("Error: File extension must remain the same"));
        setEditingFile(null);
        return;
      }

      // Check if the new filename is unique
      const isUnique = await checkFilenameUnique(user?.user_id, newName, token);
      if (!isUnique) {
        dispatch(
          setPopupMessage("Error: A file with this name already exists")
        );
        setEditingFile(null);
        return;
      }

      dispatch(
        renameFileGlobal({
          fileId: file.id,
          newName,
          oldName: file.name,
          userId: user?.user_id,
        })
      );

      dispatch(updateUserFiles({ fileId: file.id, newName }));

      setEditingFile(null);
    } catch (error) {
      console.error("Error renaming file:", error);
      dispatch(setPopupMessage("Error renaming file: " + error.message));
      setEditingFile(null);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setIsDeletingInProgress(true);
      if (!user || !user?.user_id) {
        throw new Error("User ID is not available");
      }

      const deleteFileRecursively = async (fileId) => {
        const file = user?.files[fileId];
        if (!file) return;

        // Recursively delete child files
        // for (const childId of file.child_file_ids) {
        //     console.log("deleting child file", childId);
        //     await deleteFileRecursively(childId);
        // }

        // Delete from project if it exists in the current project
        if (
          currentProjectId &&
          currentProject &&
          currentProject.files &&
          currentProject.files[fileId]
        ) {
          try {
            await dispatch(
              deleteProjectFile({
                projectId: currentProjectId,
                fileId: fileId,
              })
            ).unwrap();
          } catch (error) {
            console.error("Error deleting file from project:", error);
          }
        }

        // Delete from library
        await dispatch(
          deleteFileFromLibrary({
            fileIds: [fileId],
            fileNames: [file.file_name],
            userId: user.user_id,
          })
        ).unwrap();
      };

      // Delete each selected file and its children
      for (const fileId of selectedFiles) {
        await deleteFileRecursively(fileId);
      }

      setIsDeletingInProgress(false);
      setDeletingFiles(null);
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error deleting files:", error);
      setIsDeletingInProgress(false);
      setDeletingFiles(null);
    }
  };

  const toggleDropdown = (event, fileId) => {
    event.stopPropagation();
    setActiveDropdown((prevActiveDropdown) =>
      prevActiveDropdown === fileId ? null : fileId
    );
  };

  // const handleView = (file) => {
  //     // This function will be implemented later
  //     // Close the modal
  //     dispatch(updateModal({ name: null, data: null }));

  //     // Open the artifact panel and populate with the file info
  //     console.log("file", file);
  //     dispatch(updateArtifacts(file));
  //     setActiveDropdown(null);
  // };

  const handleAddFile = useCallback(
    (file) => {
      dispatch(
        addFileToProject({ fileId: file.id, projectId: currentProjectId })
      );
    },
    [dispatch, currentProjectId]
  );

  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return `${bytes} bytes`;
    } else if (bytes < 1024 * 1024) {
      return `${Math.floor(bytes / 1024)} KB`;
    } else {
      return `${Math.floor(bytes / (1024 * 1024))} MB`;
    }
  };

  const filteredData = useMemo(() => {
    if (!user || !user.files || !projectList) return [];

    return Object.values(user.files)
      .filter((file) => {
        // Exclude specific file types and .pickle files
        const excludedTypes = ["chat", "agent", "popup"];
        if (excludedTypes.includes(file.file_type)) return false;
        if (file.file_name.endsWith(".pickle")) return false;

        // Filter based on activeFileType
        if (activeFileType !== "all") {
          if (
            activeFileType === "Parts" &&
            !["DNA", "RNA", "Protein"].includes(file.file_type)
          )
            return false;
          if (activeFileType === "Design" && file.file_type !== "Design")
            return false;
          if (activeFileType === "Notebook" && file.file_type !== "Notebook")
            return false;
          if (activeFileType === "Paper" && file.file_type !== "Paper")
            return false;
          if (activeFileType === "Python" && file.file_type !== "Python")
            return false;
          if (activeFileType === "Data" && file.file_type !== "Data")
            return false;
        }

        // Keep digest file logic
        if (file.part_source === "digest") {
          return file.project_ids.includes(currentProjectId);
        }
        return file.part_source !== "digest";
      })
      .map((file) => {
        // Get project names, limit to 2 with ellipsis
        const projectNames = projectList
          .filter((project) => project.files && project.files[file.labkick_id])
          .map((project) => project.project_name);

        let displayProjectNames =
          projectNames.length === 0
            ? "None"
            : projectNames.length <= 2
            ? projectNames.join(", ")
            : `${projectNames.slice(0, 2).join(", ")}...`;

        // Map file types to display names
        let type;
        switch (file.file_type) {
          case "DNA":
          case "TargetConstruct":
            type = "DNA";
            break;
          case "RNA":
            type = "RNA";
            break;
          case "Protein":
            type = "Protein";
            break;
          case "markdown":
            type = "Document";
            break;
          case "protocol":
            type = "Protocol";
            break;
          case "Output":
            type = "Cloning Plan";
            break;
          case "design":
            type = "Design";
            break;
          default:
            type =
              file.file_type.charAt(0).toUpperCase() + file.file_type.slice(1);
        }

        // For RNA and Protein, set topology to linear if not provided
        let topology = file.sequence_topology;
        if (
          (file.file_type === "RNA" || file.file_type === "Protein") &&
          !topology
        ) {
          topology = "linear";
        }

        // Format date
        const formatDate = (dateString) => {
          return new Date(dateString).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
        };

        return {
          id: file.labkick_id || file.file_id || file.id,
          name: file.file_name,
          type: type,
          molecule: file.file_type, // Add molecule type
          numSequences: file.number_of_sequences,
          topology: topology,
          projectNames: displayProjectNames,
          uploadDate: formatDate(file.upload_date),
          fileSize: formatFileSize(file.file_size),
        };
      });
  }, [user, projectList, currentProjectId, activeFileType]);

  const handleSelectFile = (fileId) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.includes(fileId)
        ? prevSelectedFiles.filter((id) => id !== fileId)
        : [...prevSelectedFiles, fileId]
    );
  };

  const handleDownloadSelected = () => {
    selectedFiles.forEach((fileId) => {
      const file = filteredData.find((file) => file.id === fileId);
      if (file) {
        downloadFiles(file.id, "file", token);
      }
    });
    setSelectedFiles([]);
  };

  const handleDeleteSelected = () => {
    setDeletingFiles(
      selectedFiles.map((fileId) =>
        filteredData.find((file) => file.id === fileId)
      )
    );
  };

  const handleTopologyChange = useCallback(
    async (fileId, newTopology) => {
      try {
        setTopologyState((prev) => ({ ...prev, [fileId]: newTopology }));
        const updatedFile = await updateFileProperties(
          fileId,
          { topology: newTopology },
          user?.user_id,
          token
        );

        // Dispatch the updateUserFile action with the updated file
        dispatch(updateUserFile({ fileId, updatedFile }));
      } catch (error) {
        console.error("Error updating file topology:", error);
        // Revert the state if there's an error
        setTopologyState((prev) => ({
          ...prev,
          [fileId]: user?.files[fileId]?.sequence_topology,
        }));
      }
    },
    [user?.user_id, token, dispatch, user?.files]
  );

  // Add keyboard handler for dropdown
  const handleKeyDown = (event, fileId) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      toggleDropdown(event, fileId);
    }
  };

  // Add keyboard handler for dropdown items
  const handleDropdownKeyDown = (event, action, file) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      switch (action) {
        case "rename":
          handleFileRename(file);
          break;
        case "download":
          handleFileDownload(file);
          break;
        case "delete":
          handleDelete(file);
          break;
        default:
          break;
      }
    }
  };

  const baseColumns = useMemo(() => {
    // Start with select column
    const columns = [
      {
        Header: () => (
          <input
            ref={selectAllCheckboxRef}
            type="checkbox"
            aria-label="Select all files"
          />
        ),
        id: "select",
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedFiles.includes(row.original.id)}
            onChange={() => handleSelectFile(row.original.id)}
            aria-label={`Select ${row.original.name}`}
          />
        ),
      },
    ];

    // Add "add" column if in project mode
    if (modal.name.endsWith("_project")) {
      columns.unshift({
        Header: "",
        id: "add",
        Cell: ({ row }) => (
          <div className="add-file-action">
            <img
              src="/images/plus.svg"
              alt="Add"
              className="add-file-icon"
              onClick={() => handleAddFile(row.original)}
            />
          </div>
        ),
      });
    }

    // Common columns for all views
    columns.push({
      Header: "Name",
      accessor: "name",
      maxWidth: 437,
      Cell: ({ row }) =>
        editingFile && editingFile.id === row.original.id ? (
          <input
            ref={inputRef}
            type="text"
            defaultValue={row.original.name}
            onBlur={(e) => handleFileRenameSubmit(row.original, e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleFileRenameSubmit(row.original, e.target.value);
              }
            }}
            aria-label={`Rename ${row.original.name}`}
          />
        ) : (
          <div className="file-name-cell">{row.original.name}</div>
        ),
      filter: fileNameFilter,
    });

    // Add columns based on activeFileType
    if (activeFileType === "all") {
      columns.push({
        Header: "Type",
        accessor: "type",
      });
    } else if (activeFileType === "Parts") {
      columns.push(
        {
          Header: "Molecule",
          accessor: "molecule",
          Cell: ({ value }) => {
            switch (value) {
              case "DNA":
                return "DNA";
              case "RNA":
                return "RNA";
              case "Protein":
                return "Protein";
              default:
                return value;
            }
          },
        },
        {
          Header: "Sequences",
          accessor: "numSequences",
        },
        {
          Header: "Topology",
          accessor: "topology",
          Cell: ({ value }) => value, // Just display the value, no select element
        }
      );
    } else if (activeFileType === "Paper") {
      columns.push({
        Header: "Size",
        accessor: "fileSize",
      });
    }

    // Add Projects column for all views
    columns.push({
      Header: "Projects",
      accessor: "projectNames",
      maxWidth: 350,
      Cell: ({ value }) => (
        <div className="projects-cell">
          {value.length > 20 ? `${value.substring(0, 20)}...` : value}
        </div>
      ),
    });

    // Add Upload Date column for all views
    columns.push({
      Header: "Upload Date",
      accessor: "uploadDate",
      Cell: ({ value }) => <div>{value}</div>,
    });

    // Add actions column
    columns.push({
      Header: "",
      id: "actions",
      Cell: ({ row }) => (
        <div className="file-actions">
          <button
            className="file-menu-button"
            onClick={(event) => toggleDropdown(event, row.original.id)}
            onKeyDown={(event) => handleKeyDown(event, row.original.id)}
            aria-label={`Actions for ${row.original.name}`}
            aria-expanded={activeDropdown === row.original.id}
            aria-haspopup="true"
          >
            <img
              src="/images/three-dots.svg"
              alt=""
              className="file-menu-icon-always-visible"
              aria-hidden="true"
            />
          </button>
          {activeDropdown === row.original.id && (
            <div
              ref={dropdownRef}
              className="file-dropdown"
              role="menu"
              aria-label={`Actions for ${row.original.name}`}
              onMouseEnter={() => setMouseOutsideDropdown(false)}
            >
              <button
                className="dropdown-button"
                onClick={() => handleFileRename(row.original)}
                onKeyDown={(e) =>
                  handleDropdownKeyDown(e, "rename", row.original)
                }
                role="menuitem"
              >
                <span>Rename</span>
                <img
                  src="/images/pencil.svg"
                  alt=""
                  className="dropdown-icon"
                  aria-hidden="true"
                />
              </button>
              <button
                className="dropdown-button"
                onClick={() => handleFileDownload(row.original)}
                onKeyDown={(e) =>
                  handleDropdownKeyDown(e, "download", row.original)
                }
                role="menuitem"
              >
                <span>Download</span>
                <img
                  src="/images/download.svg"
                  alt=""
                  className="dropdown-icon"
                  aria-hidden="true"
                />
              </button>
              <button
                className="dropdown-button"
                onClick={() => handleDelete(row.original)}
                onKeyDown={(e) =>
                  handleDropdownKeyDown(e, "delete", row.original)
                }
                role="menuitem"
              >
                <span>Delete</span>
                <img
                  src="/images/trash.svg"
                  alt=""
                  className="dropdown-icon"
                  aria-hidden="true"
                />
              </button>
            </div>
          )}
        </div>
      ),
    });

    return columns;
  }, [
    editingFile,
    activeDropdown,
    modal.name,
    handleAddFile,
    selectedFiles,
    filteredData,
    user?.files,
    token,
    dispatch,
    activeFileType,
  ]);

  // First, the useTable hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: baseColumns,
      data: filteredData,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            id: "lastOpenedDate",
            desc: true,
          },
        ],
      },
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    const isLastPage = pageIndex === pageCount - 1;
    const isPartialPage = page.length < pageSize;
    let shouldFetchNext = false;

    // Never fetch if files_next_token is undefined
    if (filesNextToken === undefined) {
      shouldFetchNext = false;
    } else if (activeFileType === "all") {
      shouldFetchNext = !!filesNextToken;
    } else {
      const typeKey = activeFileType.toLowerCase();
      // Only fetch if:
      // 1. The type is not in next_tokens (first fetch), OR
      // 2. The type has a valid next token (not "no_more" or "all_files_present")
      shouldFetchNext =
        !(typeKey in nextTokens) ||
        (nextTokens[typeKey] &&
          nextTokens[typeKey] !== "all_files_present" &&
          nextTokens[typeKey] !== "no_more");
    }

    if ((isLastPage || isPartialPage) && shouldFetchNext && !isLoadingFiles) {
      dispatch(
        fetchNextFiles(activeFileType === "all" ? "all" : activeFileType)
      );
    }
  }, [
    pageIndex,
    pageCount,
    page.length,
    pageSize,
    filesNextToken,
    isLoadingFiles,
    dispatch,
    activeFileType,
    nextTokens,
  ]);

  const getCanNextPage = useCallback(() => {
    const isLastPage = pageIndex === pageCount - 1;
    const typeKey = activeFileType.toLowerCase();
    const typeToken = nextTokens[typeKey];
    const isEmptyPage = page.length === 0;

    // Never allow next page if files_next_token is undefined
    if (filesNextToken === undefined) {
      return false;
    }

    // For "all" files view
    if (activeFileType === "all") {
      const canNext =
        !isLastPage ||
        !!filesNextToken ||
        (page.length >= pageSize && filesNextToken !== null);
      console.log("[getCanNextPage] All files view result:", canNext);
      return canNext;
    }

    // For specific file types
    // If token is "no_more" and we have no files, don't allow next
    if (typeToken === "no_more" && isEmptyPage) {
      return false;
    }

    return (
      !isLastPage ||
      !(typeKey in nextTokens) ||
      (typeToken && typeToken !== "all_files_present")
    );
  }, [
    activeFileType,
    filesNextToken,
    nextTokens,
    page.length,
    pageSize,
    pageIndex,
    pageCount,
  ]);

  const canNextPage = getCanNextPage();

  const allPageIds = page.map((row) => row.original.id);
  const areAllPageItemsSelected = allPageIds.every((id) =>
    selectedFiles.includes(id)
  );
  const areSomePageItemsSelected = allPageIds.some((id) =>
    selectedFiles.includes(id)
  );

  const handleSelectAllFiles = useCallback(() => {
    const allPageIds = page.map((row) => row.original.id);
    const areAllSelected = allPageIds.every((id) => selectedFiles.includes(id));

    setSelectedFiles((prevSelectedFiles) =>
      areAllSelected
        ? prevSelectedFiles.filter((id) => !allPageIds.includes(id))
        : [...new Set([...prevSelectedFiles, ...allPageIds])]
    );
  }, [selectedFiles, page]);

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      selectAllCheckboxRef.current.checked = areAllPageItemsSelected;
      selectAllCheckboxRef.current.indeterminate =
        areSomePageItemsSelected && !areAllPageItemsSelected;
    }
  }, [areSomePageItemsSelected, areAllPageItemsSelected]);

  useEffect(() => {
    setSelectedFiles([]);
  }, [searchTerm, modal.name]);

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      selectAllCheckboxRef.current.onclick = handleSelectAllFiles;
    }
  }, [handleSelectAllFiles]);

  const handleSearch = () => {
    console.log("Searching for:", searchTerm);
    if (searchTerm.trim()) {
      dispatch(searchUserFiles({ searchTerm }));
    }
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearchInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);

    // If search is cleared, restore original files
    if (!newSearchTerm) {
      // Only dispatch search reset if we were previously in a search state
      if (isSearchActive) {
        dispatch(searchUserFiles({ searchTerm: "" }));
      }
    }
  };

  useEffect(() => {
    if (modal.isFileUploading !== undefined) {
      setIsFileUploadInProgress(modal.isFileUploading);
    }
  }, [modal, deletingFiles, dispatch]);

  const handleAddSelectedToProject = useCallback(async () => {
    try {
      // Process files sequentially
      for (const fileId of selectedFiles) {
        await dispatch(
          addFileToProject({ fileId, projectId: currentProjectId })
        ).unwrap();
      }
      // Only clear selection after all files have been added
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error adding files to project:", error);
    }
  }, [dispatch, selectedFiles, currentProjectId]);

  const handleClearSearch = () => {
    setSearchTerm("");
    dispatch(searchUserFiles({ searchTerm: "" }));
  };

  useEffect(() => {
    setSearchTerm(persistedSearchTerm);
  }, [persistedSearchTerm]);

  useEffect(() => {
    if (is_tool && selectedFiles.length > 0) {
      console.log("Selected DNA IDs for tool:", selectedFiles);
    }
  }, [selectedFiles, is_tool]);

  const handleAddFilesToTool = useCallback(() => {
    const selectedFileData = selectedFiles.map((fileId) => {
      const fileData = filteredData.find((file) => file.id === fileId);
      return {
        id: fileId,
        name: fileData.name,
      };
    });
    dispatch(addToolsFileIds(selectedFileData));
    dispatch(updateModal({ name: "", data: null }));
    setSelectedFiles([]);
  }, [dispatch, selectedFiles, filteredData]);

  const handleFileTypeChange = (type) => {
    setActiveFileType(type);
    setSelectedFiles([]);
    gotoPage(0); // Reset to first page (page index 0 = page 1)
  };

  const handleNextPage = useCallback(() => {
    nextPage();
  }, [nextPage]);

  return (
    <div
      className={`${
        is_modal
          ? "file-explorer-modal-overlay"
          : "file-explorer-inline-content"
      } ${isLoadingFiles ? "loading" : ""}`}
      onClick={(e) => e.stopPropagation()}
      role="dialog"
      aria-label="DNA Sequences File Explorer"
    >
      <div
        className={`${
          is_modal ? "file-explorer-content modal" : "file-explorer-content"
        }`}
        ref={modalRef}
      >
        <h2 id="modal-title">Files & Parts</h2>
        <div className="file-type-toggle" role="tablist">
          <button
            className={`toggle-button ${
              activeFileType === "all" ? "active" : ""
            }`}
            onClick={() => handleFileTypeChange("all")}
            role="tab"
            aria-selected={activeFileType === "all"}
          >
            All
          </button>
          <button
            className={`toggle-button ${
              activeFileType === "Parts" ? "active" : ""
            }`}
            onClick={() => handleFileTypeChange("Parts")}
            role="tab"
            aria-selected={activeFileType === "Parts"}
          >
            Parts
          </button>
          <button
            className={`toggle-button ${
              activeFileType === "Design" ? "active" : ""
            }`}
            onClick={() => handleFileTypeChange("Design")}
            role="tab"
            aria-selected={activeFileType === "Design"}
          >
            Cloning Plans
          </button>
          <button
            className={`toggle-button ${
              activeFileType === "Notebook" ? "active" : ""
            }`}
            onClick={() => handleFileTypeChange("Notebook")}
            role="tab"
            aria-selected={activeFileType === "Notebook"}
          >
            Notebooks
          </button>
          <button
            className={`toggle-button ${
              activeFileType === "Python" ? "active" : ""
            }`}
            onClick={() => handleFileTypeChange("Python")}
            role="tab"
            aria-selected={activeFileType === "Python"}
          >
            Analysis
          </button>
          <button
            className={`toggle-button ${
              activeFileType === "Data" ? "active" : ""
            }`}
            onClick={() => handleFileTypeChange("Data")}
            role="tab"
            aria-selected={activeFileType === "Data"}
          >
            Data
          </button>
          <button
            className={`toggle-button ${
              activeFileType === "Paper" ? "active" : ""
            }`}
            onClick={() => handleFileTypeChange("Paper")}
            role="tab"
            aria-selected={activeFileType === "Paper"}
          >
            Papers
          </button>
        </div>
        <div className="search-and-upload-container">
          <div className="search-bar-container">
            <div className="search-bar" role="search">
              <img
                src="/images/search.svg"
                alt="Search"
                className="search-icon"
              />
              <input
                id="file-search"
                type="text"
                placeholder="Search files..."
                value={searchTerm}
                onChange={handleSearchInputChange}
                onKeyPress={handleSearchKeyPress}
                aria-label="Search files"
              />
              <button
                onClick={handleSearch}
                className="search-button"
                aria-label="Search"
              >
                Search
              </button>
            </div>
          </div>
          <div className="multi-action-buttons">
            {currentProject && !is_tool && (
              <button
                onClick={handleAddSelectedToProject}
                className="multi-action-button"
                disabled={selectedFiles.length === 0}
                aria-label={`Add ${selectedFiles.length} selected files to ${currentProject.project_name}`}
              >
                Add Files To {currentProject.project_name}
              </button>
            )}
            {is_tool && (
              <button
                onClick={handleAddFilesToTool}
                className="multi-action-button"
                disabled={selectedFiles.length === 0}
                aria-label={`Add ${selectedFiles.length} selected files to tool`}
              >
                Add Files To Tool
              </button>
            )}
            <button
              onClick={handleDownloadSelected}
              className="multi-action-button"
              disabled={selectedFiles.length === 0}
              aria-label={`Download ${selectedFiles.length} selected files`}
            >
              Download Selected
            </button>
            <button
              onClick={handleDeleteSelected}
              className="multi-action-button"
              disabled={selectedFiles.length === 0}
              aria-label={`Delete ${selectedFiles.length} selected files`}
            >
              Delete Selected
            </button>
          </div>
          <button
            onClick={() => {
              const modalName = modal.name || "";
              dispatch(
                updateModal({
                  name: modalName.includes("dna_archive")
                    ? modalName.replace("dna_archive", "upload")
                    : "upload_general",
                  data: null,
                })
              );
            }}
            className="upload-file-button"
          >
            {modal.name.endsWith("_general")
              ? "Upload File"
              : modal.name.endsWith("_project") && currentProject
              ? `Upload File To ${currentProject.project_name}`
              : "Upload File"}
          </button>
        </div>

        {isSearchActive && (
          <div className="filter-tag-row">
            <div className="search-filter-tag">
              <span>Search results for: "{searchTerm}"</span>
              <button
                onClick={handleClearSearch}
                className="clear-search-button"
                aria-label="Clear search"
              >
                ×
              </button>
            </div>
          </div>
        )}

        <div className="table-container" role="region" aria-label="Files table">
          {filteredData.length === 0 ? (
            <div className="empty-state-container">
              <button
                onClick={() => {
                  const modalName = modal.name || "";
                  dispatch(
                    updateModal({
                      name: modalName.includes("dna_archive")
                        ? modalName.replace("dna_archive", "upload")
                        : "upload_general",
                      data: null,
                    })
                  );
                }}
                className="empty-state-upload-button"
              >
                {modal.name.endsWith("_general")
                  ? "Upload File"
                  : modal.name.endsWith("_project") && currentProject
                  ? `Upload File To ${currentProject.project_name}`
                  : "Upload File"}
              </button>
              <p className="empty-state-text">
                Welcome! You haven't uploaded any files yet. Click the button to
                upload your first file.
              </p>
            </div>
          ) : (
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, colIndex) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        key={colIndex}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={rowIndex}>
                      {row.cells.map((cell, cellIndex) => (
                        <td {...cell.getCellProps()} key={cellIndex}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <div className="pagination" role="navigation" aria-label="Pagination">
          <div className="pagination-controls">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>
            <button onClick={handleNextPage} disabled={!canNextPage}>
              {">"}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>
            <span>
              Page {/* <strong> */}
              {pageIndex + 1}
              {" |"}
              {/* </strong>{" "} */}
            </span>
            {/* <span>
                            | Go to page:{" "}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: "50px" }}
                            />
                        </span>{" "} */}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {deletingFiles && (
        <div className="delete-confirmation-overlay">
          <div className="delete-confirmation-dialog">
            <h3>
              {isDeletingInProgress
                ? "Deletion in Progress"
                : "Confirm Deletion"}
            </h3>
            {isDeletingInProgress ? (
              <p>Deleting selected file(s). Please wait...</p>
            ) : (
              <>
                <p>
                  Are you sure you want to permanently delete the selected
                  file(s)?
                </p>
                <p>This action cannot be undone.</p>
                <div className="delete-confirmation-buttons">
                  <button
                    onClick={handleDeleteConfirm}
                    className="delete-confirm-button"
                  >
                    Yes, Delete
                  </button>
                  <button
                    onClick={() => setDeletingFiles(null)}
                    className="delete-cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {isFileUploadInProgress && (
        <div className="file-upload-loading-overlay">
          <div className="file-upload-loading-spinner"></div>
        </div>
      )}
    </div>
  );
}

export default FileExplorerModal;
