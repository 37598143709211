import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../style/CSVViewer.css";

function CSVViewer() {
  const [parsedData, setParsedData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const artifact = useSelector((state) => state.project.artifact);

  useEffect(() => {
    if (artifact?.content) {
      try {
        // Parse CSV content
        const rows = artifact.content
          .split("\n")
          .map((row) =>
            row
              .split(",")
              .map((cell) => cell.trim().replace(/^["']|["']$/g, ""))
          );

        // Get headers from first row
        const headers = rows[0] || [];

        // Get data rows (excluding header)
        const data = rows
          .slice(1)
          .filter((row) => row.length === headers.length);

        setHeaders(headers);
        setParsedData(data);
      } catch (error) {
        console.error("Error parsing CSV:", error);
      }
    }
  }, [artifact?.content]);

  const handleCopyClick = () => {
    if (artifact?.content) {
      navigator.clipboard.writeText(artifact.content);
      setShowCopyNotification(true);
      setTimeout(() => {
        setShowCopyNotification(false);
      }, 2000);
    }
  };

  if (!artifact?.content) {
    return <div>No data to display</div>;
  }

  return (
    <div className="csv-viewer">
      <div className="csv-controls">
        <button
          className="csv-copy-button"
          onClick={handleCopyClick}
          title="Copy to clipboard"
          aria-label="Copy CSV content"
        >
          <img
            src="/images/copy-csv.svg"
            alt=""
            className="csv-copy-icon"
            aria-hidden="true"
          />
        </button>
        {showCopyNotification && (
          <div className="csv-copy-notification">CSV copied to clipboard</div>
        )}
      </div>
      <div className="csv-table-container">
        <table className="csv-table">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {parsedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CSVViewer;
