import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

// Rename the thunk action
export const addFileToProject = createAsyncThunk(
  "projects/addFileToProject",
  async ({ fileId, projectId }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const response = await addFileToProjectAPI(fileId, projectId, token);

    return { projectId, updatedProject: response };
  }
);

export const addFileToProjectReducer = {
  [addFileToProject.fulfilled]: (state, action) => {
    const { projectId, updatedProject } = action.payload;
    state.projectList = state.projectList.map((project) =>
      project.project_id === projectId ? updatedProject : project
    );

    if (state.currentProjectId === projectId) {
      state.currentProject = updatedProject;
    }
  },
};

export const addFileToProjectAPI = async (fileId, projectId, token) => {
  try {
    console.log("addFileToProject called with:", { fileId, projectId });
    const response = await fetchWithToken(
      `${API_URL}/api/add-file-to-project`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ file_id: fileId, project_id: projectId }),
      },
      token
    );

    if (!response.ok) {
      console.error("Failed to add file to project. Status:", response.status);
      throw new Error("Failed to add file to project");
    }

    const data = await response.json();
    console.log("Updated Project:", data);
    return data.status === "success" ? data.data : Promise.reject(data.message);
  } catch (error) {
    console.error("Error in addFileToProject:", error.message);
    throw error;
  }
};
