import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MonacoEditor, { DiffEditor } from "@monaco-editor/react";
import "../style/PythonEditor.css";
import {
  runPythonCode,
  editNotebookData,
  setNotebookHighlightedText,
  resetPythonOutput,
} from "../store/project.js";
import debounce from "lodash.debounce";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
// oops
function PythonEditor() {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const isRunningPython = project?.isRunningPython || false;
  const pythonOutput = project?.pythonOutput || { stdout: "", error: "" };
  const artifact = project?.artifact;
  const isMessageSending = useSelector(
    (state) => state.project.isMessageSending
  );
  const isMessageError = useSelector((state) => state.project.isMessageError);
  const isApplyingChanges = useSelector(
    (state) => state.project.applyingChatBlock
  );

  const [saveStatus, setSaveStatus] = useState("saved");
  const [code, setCode] = useState(() => {
    // Ensure we always have a string, even if empty
    return typeof artifact?.content === "string" ? artifact.content : "";
  });
  const [isVerticalLayout, setIsVerticalLayout] = useState(true);
  const [showDiffView, setShowDiffView] = useState(
    Boolean(
      typeof artifact?.content_old === "string" && artifact.content_old.trim()
    )
  );
  const [topSectionHeight, setTopSectionHeight] = useState("90%");
  const [isDragging, setIsDragging] = useState(false);

  const showDiff = Boolean(
    typeof artifact?.content_old === "string" && artifact.content_old.trim()
  );

  // Create debounced save function
  const debouncedSave = useRef(
    debounce(async (content, fileId) => {
      try {
        setSaveStatus("saving");
        await dispatch(
          editNotebookData({
            fileId,
            content,
          })
        );
        setSaveStatus("saved");
      } catch (error) {
        console.error("Error saving python file:", error);
        setSaveStatus("error");
      }
    }, 1500)
  ).current;

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => debouncedSave.cancel();
  }, [debouncedSave]);

  // Update code when artifact changes
  useEffect(() => {
    if (artifact) {
      // Ensure we always have a string
      const newContent =
        typeof artifact.content === "string" ? artifact.content : "";
      setCode(newContent);
      // Only show diff if content_old exists and is a string
      setShowDiffView(
        Boolean(
          typeof artifact.content_old === "string" &&
            artifact.content_old.trim()
        )
      );
    }
  }, [artifact]);

  // Reset Python output when switching files
  useEffect(() => {
    if (artifact?.file_id) {
      dispatch(
        resetPythonOutput({
          stdout: "",
          error: "",
        })
      );
    }
  }, [artifact?.file_id, dispatch]);

  // Handle code changes and trigger save
  const handleCodeChange = (newValue) => {
    setCode(newValue);
    if (artifact?.file_id) {
      setSaveStatus("editing");
      debouncedSave(newValue, artifact.file_id);
    }
  };

  const editorOptions = {
    fontFamily: "Source Code Pro",
    fontSize: 14,
    lineHeight: 21,
    minimap: { enabled: true },
    lineNumbers: "on",
    roundedSelection: false,
    scrollBeyondLastLine: false,
    readOnly: (isMessageSending && !isMessageError) || isApplyingChanges,
    cursorStyle: "line",
    automaticLayout: true,
    tabSize: 4,
    wordWrap: "on",
    renderWhitespace: "selection",
    rulers: [80],
    guides: { indentation: true },
    fontLigatures: true,
    theme: "vs",
    bracketPairColorization: {
      enabled: true,
    },
    "semanticHighlighting.enabled": true,
    fixedOverflowWidgets: true,
  };

  const handleRun = async () => {
    if (!artifact?.file_id) {
      console.error("No file ID available");
      return;
    }

    try {
      console.log("Running Python code for file:", artifact.file_id);
      const result = await dispatch(
        runPythonCode({
          fileId: artifact.file_id,
          projectId: currentProjectId,
        })
      ).unwrap();
      console.log("Python execution completed:", result);
    } catch (error) {
      console.error("Failed to run Python code:", error);
    }
  };

  const handleEditorDidMount = (editor, monaco) => {
    // Configure Python syntax highlighting for light theme
    monaco.editor.defineTheme("python-light", {
      base: "vs",
      inherit: true,
      rules: [
        { token: "keyword", foreground: "0000FF", fontStyle: "bold" }, // Blue
        { token: "string", foreground: "A31515" }, // Dark Red
        { token: "number", foreground: "098658" }, // Dark Green
        { token: "comment", foreground: "008000", fontStyle: "italic" }, // Green
        { token: "type", foreground: "267F99" }, // Teal
        { token: "function", foreground: "795E26" }, // Brown
      ],
      colors: {
        "editor.background": "#f8f9fa",
        "editor.foreground": "#000000",
        "editor.lineHighlightBackground": "#f0f0f0",
        "editorCursor.foreground": "#000000",
        "editor.selectionBackground": "#add6ff",
        "editorLineNumber.foreground": "#237893",
      },
    });

    monaco.editor.setTheme("python-light");
  };

  const toggleLayout = () => {
    setIsVerticalLayout(!isVerticalLayout);
  };

  // Helper to check if string is HTML
  const isHTML = (str) => {
    const doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
  };

  // Custom components for ReactMarkdown
  const components = {
    img: ({ node, ...props }) => {
      // Handle base64 images that don't have the data:image prefix
      const src = props.src.startsWith("data:")
        ? props.src
        : `data:image/png;base64,${props.src}`;

      return (
        <img
          {...props}
          src={src}
          className="py-output-image"
          alt={props.alt || "Python output"}
        />
      );
    },
    table: ({ node, ...props }) => (
      <table {...props} className="py-output-table" />
    ),
  };

  // Render output content with HTML and Markdown support
  const renderOutput = () => {
    if (isRunningPython) {
      return "Running...";
    }

    return (
      <>
        {pythonOutput.stdout && (
          <div className="py-stdout">
            {isHTML(pythonOutput.stdout) ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(pythonOutput.stdout, {
                    ADD_TAGS: ["img"],
                    ADD_ATTR: ["src", "alt", "class"],
                  }),
                }}
              />
            ) : (
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={components}
              >
                {(() => {
                  console.log("Markdown content:", pythonOutput.stdout);
                  return pythonOutput.stdout;
                })()}
              </ReactMarkdown>
            )}
          </div>
        )}
        {pythonOutput.error && (
          <div className="py-error">
            <pre>
              {(() => {
                console.log("Error content:", pythonOutput.error);
                return pythonOutput.error;
              })()}
            </pre>
          </div>
        )}
      </>
    );
  };

  // Update the handleEditorSelect callback to include more context
  const handleEditorSelect = useCallback(() => {
    const selection = window.getSelection();
    if (selection && selection.toString().trim()) {
      const text = selection.toString();
      // Get the full content from the editor
      const fullText = code || "";
      const start = fullText.indexOf(text);
      const end = start + text.length;

      // Dispatch with the same structure as EditableNotebook
      dispatch(
        setNotebookHighlightedText({
          text,
          fullText,
          start,
          end,
          type: "python",
        })
      );
      dispatch(setNotebookHighlightedText({ text, type: "python" }));
    } else {
      dispatch(setNotebookHighlightedText(null));
      dispatch(setNotebookHighlightedText(null));
    }
  }, [dispatch, code]);

  // Update the keyboard shortcut handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault();
        const selection = window.getSelection();
        if (selection && selection.toString().trim()) {
          const text = selection.toString();
          const fullText = code || "";
          const start = fullText.indexOf(text);
          const end = start + text.length;

          dispatch(
            setNotebookHighlightedText({
              text,
              fullText,
              start,
              end,
              type: "python",
            })
          );
          dispatch(setNotebookHighlightedText({ text, type: "python" }));
        }
      }
    };

    const editorContainer = document.querySelector(".py-monaco-container");
    if (editorContainer) {
      editorContainer.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (editorContainer) {
        editorContainer.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [dispatch, code]);

  // Update the handleMouseDown handler
  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    document.body.classList.add("dragging"); // Add dragging class to body
  };

  // Update the effect to handle auto-expansion based on content
  useEffect(() => {
    if (pythonOutput?.stdout || pythonOutput?.error) {
      // Only adjust if currently at the default height (90%)
      if (topSectionHeight === "90%") {
        // Get the output element to check its content height
        const outputContent = document.querySelector(".py-output-content");
        if (outputContent) {
          const contentHeight = outputContent.scrollHeight;
          const containerHeight =
            outputContent.parentElement.parentElement.clientHeight;

          // If content would take up more than 30% of the container
          if (contentHeight > containerHeight * 0.3) {
            setTopSectionHeight("50%"); // Expand to 50%
          } else {
            setTopSectionHeight("70%"); // Default expansion
          }
        }
      }
    } else {
      setTopSectionHeight("90%");
    }
  }, [pythonOutput?.stdout, pythonOutput?.error]);

  // Update the drag limits in the effect
  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;

      const container = document.querySelector(".py-content-section");
      if (!container) return;

      const containerRect = container.getBoundingClientRect();
      const newHeight =
        ((e.clientY - containerRect.top) / containerRect.height) * 100;

      // Limit the height between 20% and 95% for the editor section
      const clampedHeight = Math.min(Math.max(newHeight, 20), 95);
      setTopSectionHeight(`${clampedHeight}%`);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.classList.remove("dragging"); // Remove dragging class from body
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.classList.remove("dragging"); // Cleanup
    };
  }, [isDragging]);

  // Add SVG icons as components
  const PlayIcon = () => (
    <svg viewBox="0 0 24 24" fill="currentColor">
      <path d="M8 5v14l11-7z" />
    </svg>
  );

  const LayoutIcon = () => (
    <svg viewBox="0 0 24 24" fill="currentColor">
      {isVerticalLayout ? (
        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z M3 11h18v2H3z" />
      ) : (
        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V5h14v14z M11 3h2v18h-2z" />
      )}
    </svg>
  );

  return (
    <div className="py-wrapper">
      <div
        className={`py-editor-container ${
          isVerticalLayout ? "vertical" : "horizontal"
        }`}
        onMouseUp={handleEditorSelect}
        onKeyUp={handleEditorSelect}
      >
        <div className="py-toolbar">
          <button
            className="py-button"
            onClick={handleRun}
            disabled={
              (isMessageSending && !isMessageError) || isApplyingChanges
            }
            data-tooltip="Run"
          >
            <PlayIcon />
          </button>
          <button
            className="py-button"
            onClick={toggleLayout}
            data-tooltip={isVerticalLayout ? "Show Side by Side" : "Show Below"}
          >
            <LayoutIcon />
          </button>
          {/* Only show diff toggle button if content_old has content */}
          {typeof artifact?.content_old === "string" && (
            <button
              className="py-button"
              onClick={() => setShowDiffView(!showDiffView)}
              data-tooltip={showDiffView ? "Show Editor" : "Show Diff"}
            >
              <svg viewBox="0 0 24 24" fill="currentColor">
                <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zM6 20V4h7v5h5v11H6z" />
              </svg>
            </button>
          )}
          <div className="py-save-status">
            {isMessageSending && !isMessageError
              ? "Locked"
              : isApplyingChanges
              ? "Applying Changes..."
              : saveStatus}
          </div>
        </div>

        <div className="py-content-section">
          <div
            className="py-top-section"
            style={isVerticalLayout ? { height: topSectionHeight } : undefined}
          >
            {showDiffView && typeof artifact?.content_old === "string" ? (
              <div className="py-diff-container">
                <DiffEditor
                  height="100%"
                  language="python"
                  original={
                    typeof artifact.content_old === "string"
                      ? artifact.content_old
                      : ""
                  }
                  modified={typeof code === "string" ? code : ""}
                  options={{
                    ...editorOptions,
                    renderSideBySide: true,
                    readOnly: true,
                  }}
                  onMount={handleEditorDidMount}
                />
              </div>
            ) : (
              <div className="py-monaco-container">
                <MonacoEditor
                  height="100%"
                  defaultLanguage="python"
                  value={typeof code === "string" ? code : ""}
                  onChange={handleCodeChange}
                  options={editorOptions}
                  onMount={handleEditorDidMount}
                />
              </div>
            )}
          </div>

          {isVerticalLayout && (
            <div className="py-resize-handle" onMouseDown={handleMouseDown} />
          )}

          <div className="py-output-section">
            <div className="py-output-container">
              <div className="py-output-header">Output</div>
              <div className="py-output-content">{renderOutput()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PythonEditor;
