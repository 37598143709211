import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const uploadNotebookImage = createAsyncThunk(
  "projects/uploadNotebookImage",
  async (file, { getState }) => {
    const token = getState().user.access_token;
    const userId = getState().user.user_id;
    const imageUrl = await uploadImage(file, userId, token);
    return imageUrl;
  }
);

export const uploadImage = async (image, userId, token) => {
  console.log("Attempting to upload image:", {
    imageType: image.type,
    imageSize: image.size,
    filename: image.name,
    userId,
  });

  const formData = new FormData();
  formData.append("image", image, image.name);
  formData.append("user_id", userId);

  // Debug log to verify FormData contents
  console.log("FormData contents:");
  for (let [key, value] of formData.entries()) {
    console.log(
      `${key}:`,
      value instanceof File
        ? `File: ${value.name} (${value.type}, ${value.size} bytes)`
        : value
    );
  }

  const response = await fetch(`${API_URL}/api/upload-image`, {
    method: "POST",
    headers: {
      "Authorization": `Bearer ${token}`,
    },
    body: formData,
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    console.error("Upload failed:", errorData);
    throw new Error(errorData.message || "Failed to upload image");
  }

  const result = await response.json();
  console.log("Upload response:", result);
  return result.status === "success"
    ? result.data.imageUrl
    : Promise.reject(result.message);
};
