import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { setSearchState } from "../user.js";

export const searchUserFiles = createAsyncThunk(
  "user/searchUserFiles",
  async ({ searchTerm }, { getState, dispatch }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;

    if (!searchTerm) {
      // If search term is empty, restore original files
      dispatch(
        setSearchState({
          isSearchActive: false,
          searchTerm: "",
        })
      );
      return;
    }

    const searchResults = await searchFiles(userId, searchTerm, token);
    console.log("Search results received:", searchResults);

    dispatch(
      setSearchState({
        isSearchActive: true,
        searchResults: searchResults.files,
        searchToken: searchResults.nextToken,
        searchTerm: searchTerm,
      })
    );
    return searchResults;
  }
);

export const searchFiles = async (userId, searchTerm, token) => {
  console.log("searchFiles called with:", { userId, searchTerm });

  const response = await fetchWithToken(
    `${API_URL}/api/search-files`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        search_term: searchTerm,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to search files");
  }

  const result = await response.json();
  return result.status === "success"
    ? {
        files: result.data.files,
        nextToken: result.data.next_token,
      }
    : Promise.reject(result.message);
};
