import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { BiSend } from "react-icons/bi";
import "../style/ChatInput.css";
import DOMPurify from "dompurify";
import { useSelector, useDispatch } from "react-redux";
import AddFileTooltip from "./AddFileTooltip.js";
import {
  sendChatMessage,
  updateModal,
  setIsLoading,
  setError,
  clearSendMessageError,
  updateArtifact,
  manageChatAction,
  addUserMessage,
  setIsMessageSending,
  setCurrentPopupStreamedUpdate,
  setCurrentStreamedUpdate,
  setNotebookHighlightedText,
  FILE_TYPE_ICONS,
  fetchArtifactData,
} from "../store/project.js";
import { handleDiffChanges } from "../store/project_reducers/handleDiffChanges.js";
import { withEditStateCheck } from "../utils/editStateWrapper.js";
import { geneList } from "../store/gene_list.js";

const GENE_TAGS = geneList.map((geneName) => ({
  id: geneName,
  file_name: geneName,
  file_type: "DNA",
  isDefaultTag: true,
  icon: FILE_TYPE_ICONS.DNA,
}));

const DEFAULT_TAGS = [
  {
    id: "web",
    file_name: "web",
    isDefaultTag: true,
    file_type: "Web",
    icon: FILE_TYPE_ICONS.Web,
  },
  {
    id: "genes",
    file_name: "genes",
    isDefaultTag: true,
    file_type: "DNA",
    icon: FILE_TYPE_ICONS.DNA,
  },
  {
    id: "addgene",
    file_name: "addgene",
    isDefaultTag: true,
    file_type: "DNA",
    icon: FILE_TYPE_ICONS.DNA,
  },
  {
    id: "papers",
    file_name: "papers",
    isDefaultTag: true,
    file_type: "Paper",
    icon: FILE_TYPE_ICONS.Paper,
  },
];

// Combine the tags after defining them
const ALL_DEFAULT_TAGS = [...DEFAULT_TAGS, ...GENE_TAGS];

function ChatInput({ useEphemeral = false, onSendCallback, chatType }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const currentProject = useSelector((state) => state.project.currentProject);
  const currentChatId = useSelector((state) => state.project.currentChatId);
  const isResponseLoading = useSelector((state) => state.project.isLoading);

  const isMessageSending = useSelector(
    (state) => state.project.isMessageSending
  );

  const artifact = useSelector((state) => state.project.artifact);
  const currentSequence = useSelector((state) => state.project.currentSequence);

  const [inputHtml, setInputHtml] = useState("");
  const [inputHeight, setInputHeight] = useState("auto");
  const [showTooltip, setShowTooltip] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [caretPosition, setCaretPosition] = useState({ x: 0, y: 0 });
  const [lastAtSymbolIndex, setLastAtSymbolIndex] = useState(-1);
  const inputRef = useRef(null);
  const tooltipRef = useRef(null);
  const cursorPositionRef = useRef(0);
  const [showModelDropdown, setShowModelDropdown] = useState(false);
  const [selectedModel, setSelectedModel] = useState("L1");
  const modelDropdownRef = useRef(null);
  const plusButtonRef = useRef(null);
  const [showAddFileTooltip, setShowAddFileTooltip] = useState(false);

  // Local state for file pills, separate from artifact
  const [localFilePills, setLocalFilePills] = useState([]);

  // Add this near the top of the file with other state declarations
  const [showHighlightTooltip, setShowHighlightTooltip] = useState(false);

  const models = [
    { value: "L1", label: "L1" },
    { value: "L1-mini", label: "L1-mini" },
    { value: "L1-reasoning", label: "L1-reasoning" },
  ];

  const currentStreamedUpdate = useSelector((state) =>
    chatType === "popup"
      ? state.project.currentPopupStreamedUpdate
      : state.project.currentStreamedUpdate
  );

  const notebookHighlightedText = useSelector(
    (state) => state.project.notebookHighlightedText
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        modelDropdownRef.current &&
        !modelDropdownRef.current.contains(event.target)
      ) {
        setShowModelDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleRemovePill = (id) => {
    setLocalFilePills((prev) => prev.filter((pill) => pill.id !== id));
    // Also remove from editor when 'x' is clicked
    const mentionSpan = inputRef.current.querySelector(
      `[data-file-tag='${id}']`
    );
    if (mentionSpan) {
      mentionSpan.remove();
      // Trigger input event to update state
      const event = new Event("input", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  };

  useEffect(() => {
    // Ensure the selected item is always visible in the tooltip
    if (selectedIndex < visibleStartIndex) {
      setVisibleStartIndex(selectedIndex);
    } else if (selectedIndex >= visibleStartIndex + 6) {
      setVisibleStartIndex(selectedIndex - 5);
    }
  }, [selectedIndex]);

  useEffect(() => {
    adjustInputHeight();
  }, [inputHtml]);

  const handleSendMessage = async (message) => {
    try {
      console.log("Sending message with chatType:", chatType);

      if (!currentProjectId && !useEphemeral) {
        // If we do not have a project and it's not ephemeral, show error
        throw new Error(
          "No project selected. Please select a project before sending a message."
        );
      }

      dispatch(setIsMessageSending(true));

      const pillFileIds = localFilePills.map((pill) => pill.id);

      // For ephemeral "popup" usage, we may or may not want to skip addUserMessage
      // but let's keep it simpler for now:
      if (chatType !== "popup") {
        dispatch(
          addUserMessage({
            projectId: currentProjectId,
            message,
            chatId: currentChatId,
          })
        );
      }

      // THEN call your thunk:
      dispatch(
        sendChatMessage({
          message,
          chatId: currentChatId,
          projectId: currentProjectId || chatType,
          currentProject,
          status: currentProject?.status || "active",
          currentSequence,
          role: "user",
          chatType: chatType,
          pillFileIds,
          selectedModel,
        })
      );

      // Clear input
      setInputHtml("");
      setLocalFilePills([]);
      if (inputRef.current) {
        inputRef.current.innerHTML = "";
      }
      // Clear the appropriate streamed update after sending a message
      if (chatType === "popup") {
        dispatch(setCurrentPopupStreamedUpdate(null));
      } else {
        dispatch(setCurrentStreamedUpdate(null));
      }
    } catch (error) {
      console.error("Error sending message:", error);
      dispatch(setIsMessageSending(false));
    }
  };

  const adjustInputHeight = () => {
    if (inputRef.current) {
      // Temporarily set height to auto to get the correct scrollHeight
      inputRef.current.style.height = "auto";

      const scrollHeight = inputRef.current.scrollHeight;
      const minHeight = 48; // 3rem in pixels, adjust if needed
      const maxHeight = window.innerHeight * 0.4; // 40% of viewport height

      const newHeight = Math.min(Math.max(scrollHeight, minHeight), maxHeight);

      inputRef.current.style.height = `${newHeight}px`;
      setInputHeight(`${newHeight}px`);
    }
  };

  const updateCaretPosition = () => {
    if (inputRef.current) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const clonedRange = range.cloneRange();
        clonedRange.selectNodeContents(inputRef.current);
        clonedRange.setEnd(range.endContainer, range.endOffset);

        const rects = clonedRange.getClientRects();
        if (rects.length > 0) {
          const lastRect = rects[rects.length - 1];
          const inputRect = inputRef.current.getBoundingClientRect();

          setCaretPosition({
            x: lastRect.right - inputRect.left,
            y: lastRect.top - inputRect.top + lastRect.height, // Add height to position below text
          });
        }
      }
    }
  };

  useEffect(() => {
    if (tooltipRef.current && inputRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      const offset = 20;

      // Calculate position relative to viewport
      const top = inputRect.top + caretPosition.y + offset;
      const left = inputRect.left + caretPosition.x;

      // Ensure tooltip stays within viewport
      const tooltipHeight = tooltipRef.current.offsetHeight;
      const tooltipWidth = tooltipRef.current.offsetWidth;

      const maxTop = window.innerHeight - tooltipHeight - 10;
      const maxLeft = window.innerWidth - tooltipWidth - 10;

      tooltipRef.current.style.top = `${Math.min(top, maxTop)}px`;
      tooltipRef.current.style.left = `${Math.min(left, maxLeft)}px`;
      tooltipRef.current.style.display = "block";
    }
  }, [caretPosition]);

  useLayoutEffect(() => {
    if (inputRef.current) {
      restoreCursorPosition(cursorPositionRef.current);
    }
  }, [inputHtml]);

  const saveCursorPosition = () => {
    if (inputRef.current) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(inputRef.current);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        cursorPositionRef.current = preCaretRange.toString().length;
      }
    }
  };

  const restoreCursorPosition = (cursorPosition) => {
    if (inputRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(inputRef.current, 0);
      range.collapse(true);
      let nodeStack = [inputRef.current],
        node,
        foundStart = false,
        stop = false;
      let charCount = 0;

      while (!stop && (node = nodeStack.pop())) {
        if (node.nodeType === Node.TEXT_NODE) {
          const nextCharCount = charCount + node.length;
          if (
            !foundStart &&
            cursorPosition >= charCount &&
            cursorPosition <= nextCharCount
          ) {
            range.setStart(node, cursorPosition - charCount);
            foundStart = true;
          }
          if (
            foundStart &&
            cursorPosition >= charCount &&
            cursorPosition <= nextCharCount
          ) {
            range.setEnd(node, cursorPosition - charCount);
            stop = true;
          }
          charCount = nextCharCount;
        } else {
          let i = node.childNodes.length;
          while (i--) {
            nodeStack.push(node.childNodes[i]);
          }
        }
      }

      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const handleInputChange = (e) => {
    saveCursorPosition();

    // Convert pasted HTML content to plain text
    // NOTE: I commented this out because it was causing issues at some point, so try to comment this out if it's causing issues and see what happens
    if (e.type === "paste") {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      document.execCommand("insertText", false, text);
      return;
    }

    // Get pure text content
    const text = inputRef.current.innerText.trim();

    // If text is empty, ensure div is truly empty
    if (text === "") {
      inputRef.current.innerHTML = "";
    }

    // Update the input HTML
    const newHtml = inputRef.current.innerHTML;
    setInputHtml(newHtml);

    updateCaretPosition();
    adjustInputHeight();

    const textContent = inputRef.current.textContent;
    const newLastAtSymbolIndex = textContent.lastIndexOf("@");

    if (newLastAtSymbolIndex !== -1) {
      setLastAtSymbolIndex(newLastAtSymbolIndex);
      const searchTerm = textContent
        .slice(newLastAtSymbolIndex + 1)
        .toLowerCase();

      let filtered;
      if (searchTerm === "") {
        // Show all user files and first few gene suggestions
        const filteredFiles = user?.files ? Object.values(user.files) : [];
        const limitedGeneTags = GENE_TAGS.slice(0, 5); // Show only first 5 genes when no search term
        filtered = [...DEFAULT_TAGS, ...limitedGeneTags, ...filteredFiles];
      } else {
        const filteredFiles = user?.files
          ? Object.values(user.files).filter((file) =>
              file.file_name.toLowerCase().includes(searchTerm)
            )
          : [];
        // Include matching default tags and genes
        const matchingDefaultTags = ALL_DEFAULT_TAGS.filter((tag) =>
          tag.file_name.toLowerCase().includes(searchTerm)
        );
        filtered = [...matchingDefaultTags, ...filteredFiles];
      }

      setFilteredFiles(filtered);
      setShowTooltip(true);

      // Set selectedIndex to the last file
      const lastIndex = filtered.length - 1;
      setSelectedIndex(lastIndex);

      // Set visibleStartIndex to show the last four files
      const visibleStart = Math.max(0, filtered.length - 4);
      setVisibleStartIndex(visibleStart);
    } else if (lastAtSymbolIndex !== -1) {
      // If there was a previous '@' but it's gone now, hide the tooltip
      setShowTooltip(false);
      setFilteredFiles([]);
      setLastAtSymbolIndex(-1);
    }
  };

  // Helper function to get cursor position
  const getCursorPosition = (element) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  };

  // Helper function to set cursor position
  const setCursorPosition = (element, position) => {
    const range = document.createRange();
    const sel = window.getSelection();

    let currentNode = element.firstChild;
    let currentOffset = 0;

    while (currentNode) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        const nodeLength = currentNode.length;
        if (currentOffset + nodeLength >= position) {
          range.setStart(currentNode, position - currentOffset);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          return;
        }
        currentOffset += nodeLength;
      } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
        const nodeLength = currentNode.textContent.length;
        if (currentOffset + nodeLength >= position) {
          if (currentNode.childNodes.length > 0) {
            currentNode = currentNode.firstChild;
            continue;
          } else {
            range.setStartAfter(currentNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            return;
          }
        }
        currentOffset += nodeLength;
      }
      currentNode = currentNode.nextSibling;
    }

    // If we've gone through all nodes and haven't found the position,
    // set the cursor at the end of the content
    range.selectNodeContents(element);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  const measureTextWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = getComputedStyle(inputRef.current).font;
    return context.measureText(text).width;
  };

  const handleFileSelect = (file) => {
    if (file) {
      // Add to pillbox
      handleAddFile(file);

      const cursorPosition = getCursorPosition(inputRef.current);
      const textBeforeCursor = inputRef.current.textContent.slice(
        0,
        cursorPosition
      );
      const lastAtSymbolIndex = textBeforeCursor.lastIndexOf("@");

      // Create a temporary div to parse the HTML
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = inputHtml;

      // Insert the new file tag
      const newSpan = document.createElement("span");
      newSpan.className = "file-tag";
      newSpan.textContent = file.file_name;
      newSpan.dataset.fileTag = file.labkick_id || file.id;
      newSpan.setAttribute("contenteditable", "false");
      newSpan.setAttribute("tabindex", "-1");

      // Find the correct insertion point
      let currentNode = tempDiv.firstChild;
      let currentPosition = 0;
      let insertionNode = null;
      let insertionOffset = 0;

      while (currentNode) {
        if (currentNode.nodeType === Node.TEXT_NODE) {
          if (currentPosition + currentNode.length > lastAtSymbolIndex) {
            insertionNode = currentNode;
            insertionOffset = lastAtSymbolIndex - currentPosition;
            break;
          }
          currentPosition += currentNode.length;
        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
          currentPosition += currentNode.textContent.length;
        }
        currentNode = currentNode.nextSibling;
      }

      // Insert the new span
      if (insertionNode) {
        const beforeText = insertionNode.textContent.slice(0, insertionOffset);
        const afterText = insertionNode.textContent.slice(insertionOffset);

        // Remove everything from '@' to the next space or end of string
        const afterTextCleaned = afterText.replace(/^@\S*\s?/, "");

        const beforeTextNode = document.createTextNode(beforeText);
        const afterTextNode = document.createTextNode(afterTextCleaned);

        insertionNode.parentNode.insertBefore(beforeTextNode, insertionNode);
        insertionNode.parentNode.insertBefore(newSpan, insertionNode);
        insertionNode.parentNode.insertBefore(
          document.createTextNode(" "),
          insertionNode
        );
        insertionNode.parentNode.insertBefore(afterTextNode, insertionNode);
        insertionNode.parentNode.removeChild(insertionNode);
      } else {
        tempDiv.appendChild(newSpan);
        tempDiv.appendChild(document.createTextNode(" "));
      }

      // Set the new HTML
      setInputHtml(tempDiv.innerHTML);

      setShowTooltip(false);

      // Set cursor position after the inserted tag and space
      setTimeout(() => {
        const newCursorPosition = lastAtSymbolIndex + file.file_name.length + 1;
        setCursorPosition(inputRef.current, newCursorPosition);
        inputRef.current.focus();
      }, 0);
    }
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleKeyDown = (e) => {
    scrollToBottom();

    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const { startContainer, startOffset, collapsed } = range;

    // Declare these variables at the start of the function
    let nextCursorNode = null;
    let nextCursorOffset = null;

    if (e.key === "Backspace" || e.key === "Delete") {
      let nodeToCheck = null;

      if (e.key === "Backspace") {
        // For Backspace, check the previous node
        if (startContainer.nodeType === Node.TEXT_NODE) {
          if (startOffset === 0) {
            nodeToCheck = startContainer.previousSibling;
          }
        } else {
          nodeToCheck = startContainer.childNodes[startOffset - 1];
        }

        nextCursorNode = startContainer;
        nextCursorOffset = startOffset;
      } else {
        // Delete
        // For Delete, check the next node
        if (startContainer.nodeType === Node.TEXT_NODE) {
          if (startOffset === startContainer.length) {
            nodeToCheck = startContainer.nextSibling;
          }
        } else {
          nodeToCheck = startContainer.childNodes[startOffset];
        }

        nextCursorNode = startContainer;
        nextCursorOffset = startOffset;
      }

      // If we found a file tag
      if (nodeToCheck?.classList?.contains("file-tag")) {
        e.preventDefault();
        const parentNode = nodeToCheck.parentNode;
        const nextSibling = nodeToCheck.nextSibling;

        // Remove from pillbox
        const removedFileId = nodeToCheck.dataset.fileTag;
        handleRemoveFile(removedFileId);

        // Remove the node
        nodeToCheck.remove();

        // Set cursor position
        const newRange = document.createRange();
        if (nextSibling) {
          newRange.setStart(nextSibling, 0);
        } else {
          // If there's no next sibling, set cursor at the end of parentNode
          newRange.setStart(parentNode, parentNode.childNodes.length);
        }
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);

        // Trigger input event to update state
        const event = new Event("input", { bubbles: true });
        inputRef.current.dispatchEvent(event);
        return;
      }
    }

    if (showTooltip && filteredFiles.length > 0) {
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          setSelectedIndex((prevIndex) => Math.max(0, prevIndex - 1));
          break;
        case "ArrowDown":
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            Math.min(filteredFiles.length - 1, prevIndex + 1)
          );
          break;
        case "Tab":
        case "Enter":
          e.preventDefault();
          handleFileSelect(filteredFiles[selectedIndex]);
          break;
        default:
          break;
      }
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      // Only handle submit if not currently sending a message
      if (!isMessageSending) {
        handleSubmit(e);
      }
    }

    // Always update caret position, not just when tooltip is shown
    updateCaretPosition();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const html = inputRef.current.innerHTML;
    if (html.trim() && !isResponseLoading && currentProjectId) {
      dispatch(
        withEditStateCheck(() => handleSendMessage(html), currentProjectId)
      );
      setInputHtml("");
      if (inputRef.current) {
        inputRef.current.innerHTML = ""; // Clear the input
        inputRef.current.style.height = "auto"; // Reset height after sending
      }
      setInputHeight("auto"); // Reset height state after sending
    } else if (!currentProjectId) {
      console.log("No project selected. Cannot send message.");
    }
  };

  useEffect(() => {
    // Clear send message error when component unmounts
    return () => {
      dispatch(clearSendMessageError());
    };
  }, [dispatch]);

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      let { startContainer } = range;

      // Only adjust cursor if no text is selected (selection is collapsed)
      if (!selection.isCollapsed) {
        return;
      }

      // Move cursor outside if inside a file-tag
      if (startContainer.nodeType === Node.TEXT_NODE) {
        startContainer = startContainer.parentNode;
      }

      if (startContainer.classList?.contains("file-tag")) {
        const newRange = document.createRange();
        newRange.setStartAfter(startContainer);
        newRange.collapse(true);
        selection.removeAllRanges();
        selection.addRange(newRange);
      }
    };

    document.addEventListener("selectionchange", handleSelectionChange);
    return () =>
      document.removeEventListener("selectionchange", handleSelectionChange);
  }, []);

  const handleAddFile = (file) => {
    const newPill = {
      id: file.isDefaultTag ? file.file_name : file.labkick_id || file.id,
      label: file.file_name,
      isDefaultTag: file.isDefaultTag,
      file_type: file.file_type,
      icon:
        file.icon || FILE_TYPE_ICONS[file.file_type] || FILE_TYPE_ICONS.default,
      ...file,
    };
    setLocalFilePills((prev) => {
      if (prev.some((p) => p.id === newPill.id)) {
        return prev; // no duplicates
      }
      return [...prev, newPill];
    });
  };

  const handleRemoveFile = (fileId) => {
    setLocalFilePills((prev) => prev.filter((p) => p.id !== fileId));
  };

  useEffect(() => {
    // Clear all pills if project has no files
    if (
      !currentProject?.files ||
      Object.keys(currentProject?.files || {}).length === 0
    ) {
      setLocalFilePills([]);
      return;
    }

    if (artifact?.file_name) {
      setLocalFilePills((prev) => {
        // Remove any existing artifact pills first
        const filtered = prev.filter((p) => p.type !== "artifact");
        // Add the new artifact pill
        return [
          ...filtered,
          {
            id: artifact.file_id,
            label: artifact.file_name,
            type: "artifact",
            file_type: currentProject?.files?.[artifact.file_id]?.file_type,
            icon:
              FILE_TYPE_ICONS[
                currentProject?.files?.[artifact.file_id]?.file_type
              ] || FILE_TYPE_ICONS.default,
          },
        ];
      });
    }
  }, [artifact, currentProject]);

  const getContextPills = () => {
    const pills = [...localFilePills].map((pill) => ({
      ...pill,
      icon:
        pill.icon || FILE_TYPE_ICONS[pill.file_type] || FILE_TYPE_ICONS.default,
    }));

    if (notebookHighlightedText) {
      // Extract the text content from the highlighted text object
      const highlightedContent =
        notebookHighlightedText.text || notebookHighlightedText;

      // Truncate the content for display
      const truncatedText =
        typeof highlightedContent === "string" &&
        highlightedContent.length > 100
          ? highlightedContent.substring(0, 100) + "..."
          : highlightedContent;

      pills.push({
        id: "highlighted-text",
        label: "Highlighted Text",
        type: "highlight",
        icon:
          FILE_TYPE_ICONS[
            currentProject?.files?.[artifact?.file_id]?.file_type
          ] || FILE_TYPE_ICONS.default,
        fullText: highlightedContent,
        truncatedText: truncatedText,
      });
    }

    return pills;
  };

  return (
    <div
      className="chatinput-main-bottom chatinput-container"
      role="region"
      aria-label="Chat input area"
    >
      <form
        className="chatinput-form-container"
        onSubmit={handleSubmit}
        role="form"
        aria-label="Chat message form"
      >
        {/* Move edit state buttons here, inside the form */}
        {currentProject?.inEditState && (
          <div className="chatinput-edit-controls">
            <button
              className="chatinput-edit-button reject"
              onClick={() => {
                console.log("Reject changes clicked");
                dispatch(
                  handleDiffChanges({
                    projectId: currentProject.project_id,
                    action: "reject_all",
                    fileIds: [],
                  })
                ).then(() => {
                  if (artifact?.file_id) {
                    dispatch(fetchArtifactData({ fileId: artifact.file_id }));
                  }
                });
              }}
            >
              reject all
            </button>
            <button
              className="chatinput-edit-button accept"
              onClick={() => {
                console.log("Accept changes clicked");
                dispatch(
                  handleDiffChanges({
                    projectId: currentProject.project_id,
                    action: "accept_all",
                    fileIds: [],
                  })
                ).then(() => {
                  if (artifact?.file_id) {
                    dispatch(fetchArtifactData({ fileId: artifact.file_id }));
                  }
                });
              }}
            >
              accept all
            </button>
          </div>
        )}

        {/* Top Bar */}
        <div className="chatinput-top-bar">
          <button
            ref={plusButtonRef}
            className="chatinput-add-pill"
            onClick={(e) => {
              e.preventDefault();
              setShowAddFileTooltip((prev) => !prev);
            }}
            aria-label="Add file reference"
          >
            +
          </button>

          {/* Show context pills including highlighted text */}
          {getContextPills().map((pill) => (
            <div
              key={pill.id}
              className={`chatinput-context-pill ${
                pill.type === "highlight" ? "highlight-pill" : ""
              }`}
              onMouseEnter={() =>
                pill.type === "highlight" && setShowHighlightTooltip(true)
              }
              onMouseLeave={() =>
                pill.type === "highlight" && setShowHighlightTooltip(false)
              }
            >
              <div className="chatinput-pill-icon-wrapper">{pill.icon}</div>
              {pill.label}
              <span
                className="chatinput-close-icon"
                onClick={() => {
                  if (pill.type === "highlight") {
                    dispatch(setNotebookHighlightedText(null));
                  } else {
                    handleRemoveFile(pill.id);
                  }
                }}
              >
                ×
              </span>
              {pill.type === "highlight" && showHighlightTooltip && (
                <div className="chatinput-highlight-tooltip">
                  <pre>
                    <code>
                      {typeof pill.fullText === "string"
                        ? pill.fullText
                        : pill.fullText?.text || "No text available"}
                    </code>
                  </pre>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Add File Tooltip */}
        <AddFileTooltip
          isVisible={showAddFileTooltip}
          onClose={() => setShowAddFileTooltip(false)}
          addedFiles={localFilePills}
          allFiles={
            user?.files
              ? Object.values(user.files).filter(
                  (file) =>
                    !localFilePills.some(
                      (pill) => pill.id === (file.id || file.labkick_id)
                    )
                )
              : []
          }
          anchorRef={plusButtonRef}
          onRemoveFile={handleRemovePill}
          onFileClick={handleAddFile}
        />

        {/* Middle Input Area */}
        <div className="chatinput-middle">
          <div
            ref={inputRef}
            contentEditable
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputHtml) }}
            onInput={handleInputChange}
            onPaste={handleInputChange}
            onKeyDown={handleKeyDown}
            onKeyUp={updateCaretPosition}
            onClick={updateCaretPosition}
            data-placeholder="Ask LabKick to do anything, @ to mention"
            className="chatinput-input"
            style={{ maxHeight: "40vh", overflowY: "auto" }}
            role="textbox"
            aria-label="Chat message input"
            aria-multiline="true"
            aria-placeholder="Ask LabKick to do anything, @ to mention"
            tabIndex="0"
          />
        </div>

        {/* Bottom Controls */}
        <div className="chatinput-controls">
          <div className="chatinput-model-selector" ref={modelDropdownRef}>
            <div
              className="chatinput-dropdown"
              onClick={() => setShowModelDropdown(!showModelDropdown)}
            >
              {models.find((m) => m.value === selectedModel)?.label}
            </div>
            {showModelDropdown && (
              <div className="chatinput-tooltip-popper model-dropdown">
                {models.map((model) => (
                  <div
                    key={model.value}
                    className={`chatinput-tooltip-option ${
                      model.value === selectedModel ? "selected" : ""
                    }`}
                    onClick={() => {
                      setSelectedModel(model.value);
                      setShowModelDropdown(false);
                    }}
                  >
                    {model.label}
                  </div>
                ))}
              </div>
            )}
          </div>
          {!isResponseLoading && currentProjectId && (
            <button
              type="submit"
              className="chatinput-submit-button"
              aria-label="Send message"
              disabled={isMessageSending}
            >
              Send
              <span className="chatinput-enter-key">↵</span>
            </button>
          )}
        </div>

        {/* Tooltip */}
        {showTooltip && filteredFiles.length > 0 && (
          <div
            className={`chatinput-tooltip-popper 
              ${visibleStartIndex > 0 ? "has-top-hidden" : ""}
              ${
                visibleStartIndex + 6 < filteredFiles.length
                  ? "has-bottom-hidden"
                  : ""
              }`}
            ref={tooltipRef}
            role="listbox"
            aria-label="File suggestions"
            style={{ display: "block" }}
          >
            {filteredFiles
              .slice(visibleStartIndex, visibleStartIndex + 6)
              .map((file, index) => (
                <div
                  key={
                    file.isDefaultTag
                      ? file.file_name
                      : file.labkick_id || file.id
                  }
                  className={`chatinput-tooltip-option ${
                    visibleStartIndex + index === selectedIndex
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleFileSelect(file)}
                  role="option"
                  aria-selected={visibleStartIndex + index === selectedIndex}
                  tabIndex="0"
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleFileSelect(file);
                    }
                  }}
                >
                  <div className="chatinput-tooltip-icon-wrapper">
                    {FILE_TYPE_ICONS[file.file_type] || FILE_TYPE_ICONS.default}
                  </div>
                  {file.file_name}
                </div>
              ))}
          </div>
        )}
      </form>
    </div>
  );
}

export default ChatInput;
