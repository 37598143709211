import { fetchWithToken, API_URL } from "./api.js";

export const runDigestVisualization = async (
  fileIds,
  ladder,
  enzymes,
  userId,
  token
) => {
  console.log("runDigestVisualization called with:", {
    fileIds,
    ladder,
    enzymes,
    userId,
  });

  const response = await fetchWithToken(
    `${API_URL}/api/run-digest-visualization`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_ids: fileIds,
        user_id: userId,
        ladder: ladder,
        enzymes: enzymes,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to run digest visualization");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};

export const runPrimerDesign = async (
  fileIds,
  leftExtensions,
  rightExtensions,
  goal,
  userId,
  token
) => {
  console.log("runPrimerDesign called with:", {
    fileIds,
    leftExtensions,
    rightExtensions,
    goal,
    userId,
  });

  const response = await fetchWithToken(
    `${API_URL}/api/run-primer-design`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_ids: fileIds,
        user_id: userId,
        left_extensions: leftExtensions
          .split(",")
          .map((ext) => ext.trim())
          .filter((ext) => ext),
        right_extensions: rightExtensions
          .split(",")
          .map((ext) => ext.trim())
          .filter((ext) => ext),
        goal: goal,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to run primer design");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};

export const runAnnotation = async (fileIds, databases, userId, token) => {
  console.log("runAnnotation called with:", { fileIds, databases, userId });

  const response = await fetchWithToken(
    `${API_URL}/api/run-annotation`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_ids: fileIds,
        user_id: userId,
        databases: {
          common_features: databases.commonFeatures,
          fpbase: databases.fpbase,
          swissprot: databases.swissprot,
          rfam: databases.rfam,
          your_library: databases.yourLibrary,
        },
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to submit annotation request");
  }

  const result = await response.json();
  return {
    success: result.status === "success",
    message: result.message,
  };
};

export const runSequenceSearch = async (
  fileIds,
  query,
  databases,
  outputCount,
  userId,
  token
) => {
  console.log("runSequenceSearch called with:", {
    fileIds,
    query,
    databases,
    outputCount,
    userId,
  });

  const response = await fetchWithToken(
    `${API_URL}/api/run-sequence-search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_ids: fileIds,
        user_id: userId,
        query: query,
        databases: {
          addgene: databases.addgene,
          personal: databases.personal,
          company: databases.company,
        },
        output_count: parseInt(outputCount),
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to run sequence search");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};

export const runMaterialSearch = async (query, outputCount, userId, token) => {
  console.log("runMaterialSearch called with:", { query, outputCount, userId });

  const response = await fetchWithToken(
    `${API_URL}/api/run-material-search`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: query,
        user_id: userId,
        output_count: parseInt(outputCount),
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to run material search");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};

export const runComplexityAnalysis = async (
  fileIds,
  analysisOption,
  userId,
  token
) => {
  console.log("runComplexityAnalysis called with:", {
    fileIds,
    analysisOption,
    userId,
  });

  const response = await fetchWithToken(
    `${API_URL}/api/run-complexity-analysis`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_ids: fileIds,
        user_id: userId,
        analysis_option: analysisOption,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to submit complexity analysis");
  }

  const result = await response.json();
  return result.status === "success"; // Just return success status since we're not expecting data back
};

export const runSynthesisPrediction = async (
  fileIds,
  vendor,
  userId,
  token
) => {
  console.log("runSynthesisPrediction called with:", {
    fileIds,
    vendor,
    userId,
  });

  const response = await fetchWithToken(
    `${API_URL}/api/run-synthesis-prediction`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_ids: fileIds,
        user_id: userId,
        vendor: vendor,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to run synthesis prediction");
  }

  const result = await response.json();
  console.log("runSynthesisPrediction result", result);
  return result.status === "success"
    ? { predictions: result.data.predictions }
    : Promise.reject(result.message);
};
