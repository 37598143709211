import { fetchWithToken, API_URL } from "./api.js";

export const getUserPreferences = async (userId, token) => {
  try {
    console.log("getUserPreferences called with:", { userId });
    const response = await fetchWithToken(
      `${API_URL}/api/get-user-preferences`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      },
      token
    );

    if (!response.ok) {
      console.error(
        "Failed to fetch user preferences. Status:",
        response.status
      );
      throw new Error("Failed to fetch user preferences");
    }

    const data = await response.json();
    console.log("getUserPreferences data", data.data);
    return data.status === "success" ? data.data : Promise.reject(data.message);
  } catch (error) {
    console.error("Error in getUserPreferences:", error.message);
    throw error;
  }
};

export const saveUserPreferences = async (preferences, token) => {
  try {
    console.log("saveUserPreferences called with:", { preferences });
    const response = await fetchWithToken(
      `${API_URL}/api/save-user-preferences`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(preferences),
      },
      token
    );

    if (!response.ok) {
      console.error(
        "Failed to save user preferences. Status:",
        response.status
      );
      throw new Error("Failed to save user preferences");
    }

    const data = await response.json();
    return data.status === "success" ? data.data : Promise.reject(data.message);
  } catch (error) {
    console.error("Error in saveUserPreferences:", error.message);
    throw error;
  }
};
