import React, { useState, useMemo, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SeqViz } from "seqviz";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { createPortal } from "react-dom";
import "../style/Sequence.css";
import { downloadFiles } from "../api/files_api.js";
import {
  fetchArtifactData,
  setCurrentSequence,
  setSequenceViewerSelection,
  setSequenceViewerType,
} from "../store/project.js";

/* 
  Popup for filtering enzymes and annotations 
*/
function FilterPopup({
  selectedCutGroups,
  setSelectedCutGroups,
  enzymeGroups,
  annotations,
  selectedAnnotationTypes,
  setSelectedAnnotationTypes,
  searchQuery,
  setSearchQuery,
  onClose,
  buttonRef,
}) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const popupRef = useRef(null);
  const headerRef = useRef(null);

  const handleMouseDown = (e) => {
    // Only allow dragging from the header
    if (!headerRef.current?.contains(e.target)) return;

    setIsDragging(true);
    setDragStart({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - dragStart.x,
      y: e.clientY - dragStart.y,
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [isDragging]);

  // Collect all unique annotation types
  const annotationTypes = useMemo(() => {
    const types = new Set();
    annotations?.forEach((ann) => {
      if (ann.type) types.add(ann.type);
    });
    return Array.from(types);
  }, [annotations]);

  // Set initial position based on filter button location
  useEffect(() => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setPosition({
        x: rect.right - (popupRef.current?.offsetWidth || 0),
        y: rect.bottom + 12,
      });
    }
  }, [buttonRef]);

  return createPortal(
    <div
      className={`filter-popup ${isDragging ? "dragging" : ""}`}
      ref={popupRef}
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        position: "fixed",
      }}
    >
      <div
        className="filter-popup-header"
        ref={headerRef}
        onMouseDown={handleMouseDown}
      >
        <h3>Filter Controls</h3>
        <button className="filter-popup-close" onClick={onClose}>
          ×
        </button>
      </div>

      <div className="filter-popup-content">
        <div className="filter-section">
          <h3>Cut Site Groups</h3>
          <div className="enzyme-checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={selectedCutGroups.includes("single")}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedCutGroups([...selectedCutGroups, "single"]);
                  } else {
                    setSelectedCutGroups(
                      selectedCutGroups.filter((g) => g !== "single")
                    );
                  }
                }}
              />
              Single ({enzymeGroups.single?.length || 0})
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedCutGroups.includes("double")}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedCutGroups([...selectedCutGroups, "double"]);
                  } else {
                    setSelectedCutGroups(
                      selectedCutGroups.filter((g) => g !== "double")
                    );
                  }
                }}
              />
              Double ({enzymeGroups.double?.length || 0})
            </label>

            <label>
              <input
                type="checkbox"
                checked={selectedCutGroups.includes("multiple")}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedCutGroups([...selectedCutGroups, "multiple"]);
                  } else {
                    setSelectedCutGroups(
                      selectedCutGroups.filter((g) => g !== "multiple")
                    );
                  }
                }}
              />
              Multiple ({enzymeGroups.multiple?.length || 0})
            </label>
          </div>
        </div>

        <div className="filter-section">
          <h3>Annotation Types</h3>
          <div className="annotation-checkbox-group">
            {annotationTypes.map((type) => (
              <label key={type}>
                <input
                  type="checkbox"
                  checked={selectedAnnotationTypes.includes(type)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedAnnotationTypes([
                        ...selectedAnnotationTypes,
                        type,
                      ]);
                    } else {
                      setSelectedAnnotationTypes(
                        selectedAnnotationTypes.filter((t) => t !== type)
                      );
                    }
                  }}
                />
                {type}
              </label>
            ))}
          </div>
        </div>

        <div className="filter-section">
          <h3>Search Annotations</h3>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search annotation names..."
            className="annotation-search"
          />
        </div>
      </div>
    </div>,
    document.body
  );
}

/* 
  Controls (top bar) for the sequence viewer 
*/
function SequenceControls({
  viewerType,
  setViewerType,
  currentSequenceName,
  sequenceNames,
  handleSequenceChange,
  zoom,
  setZoom,
  selectedCutGroups,
  setSelectedCutGroups,
  enzymeGroups,
  annotations,
  setFilteredAnnotations,
  handleNavigation,
  showTooltip,
  tooltipMessage,
  topology,
  seqType,
}) {
  const [showFilter, setShowFilter] = useState(false);
  const [selectedAnnotationTypes, setSelectedAnnotationTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const filterButtonRef = useRef(null);

  const sequenceViewerSelection = useSelector(
    (state) => state.project.sequenceViewerSelection
  );

  // Collect all unique annotation types from raw annotations
  const annotationTypes = useMemo(() => {
    const types = new Set();
    annotations?.forEach((ann) => {
      if (ann.type) types.add(ann.type);
    });
    return Array.from(types);
  }, [annotations]);

  // Initialize annotation types on first load
  useEffect(() => {
    if (annotationTypes.length > 0 && selectedAnnotationTypes.length === 0) {
      setSelectedAnnotationTypes(annotationTypes);
    }
  }, [annotationTypes, selectedAnnotationTypes.length]);

  // Filter annotations by annotation type + search
  useEffect(() => {
    if (!annotations) return;

    let filtered = [...annotations];

    // Filter by type
    if (selectedAnnotationTypes.length > 0) {
      filtered = filtered.filter((ann) =>
        selectedAnnotationTypes.includes(ann.type)
      );
    }
    // Filter by search
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter((ann) =>
        ann.name?.toLowerCase().includes(query)
      );
    }

    setFilteredAnnotations(filtered);
  }, [
    annotations,
    selectedAnnotationTypes,
    searchQuery,
    setFilteredAnnotations,
  ]);

  return (
    <div className="viewer-controls">
      <div className="dropdowns-container">
        {/* Only show viewer type dropdown for DNA sequences */}
        {(!seqType || seqType === "dna") && (
          <div className="viewer-type-container">
            <label className="viewer-type-label" htmlFor="viewer-type-dropdown">
              Viewer
            </label>
            <select
              id="viewer-type-dropdown"
              className="viewer-type-dropdown"
              value={viewerType}
              onChange={(e) => setViewerType(e.target.value)}
            >
              <option value="linear">Linear</option>
              <option value="circular" disabled={topology === "linear"}>
                Circular {topology === "linear" ? "(not applicable)" : ""}
              </option>
              <option value="both" disabled={topology === "linear"}>
                Both (Circular Left){" "}
                {topology === "linear" ? "(not applicable)" : ""}
              </option>
              <option value="both_flip" disabled={topology === "linear"}>
                Both (Circular Right){" "}
                {topology === "linear" ? "(not applicable)" : ""}
              </option>
            </select>
          </div>
        )}

        {/* Sequence Name Selector (if multiple sequences) */}
        {sequenceNames.length > 1 && (
          <div className="sequence-select-container">
            <label
              className="sequence-select-label"
              htmlFor="sequence-select-dropdown"
            >
              Sequence
            </label>
            <select
              id="sequence-select-dropdown"
              className="sequence-select-dropdown"
              value={currentSequenceName}
              onChange={handleSequenceChange}
            >
              {sequenceNames.map((name) => (
                <option key={name} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Selection metadata */}
        <div className="selection-meta">
          <div className="meta-datum">
            <p className="field">Length</p>
            <p className="value">
              {sequenceViewerSelection?.length
                ? `${sequenceViewerSelection.length}bp`
                : "-"}
            </p>
          </div>
          <div className="meta-datum">
            <p className="field">Range</p>
            <p className="value">
              {sequenceViewerSelection?.start !== undefined &&
              sequenceViewerSelection?.end !== undefined
                ? sequenceViewerSelection.start === sequenceViewerSelection.end
                  ? `${sequenceViewerSelection.start + 1}`
                  : `${sequenceViewerSelection.start + 1} - ${
                      sequenceViewerSelection.end + 1
                    }`
                : "Select range"}
            </p>
          </div>
        </div>

        {/* Filter popup button */}
        <div className="filter-controls">
          <div className="filter-nav-group">
            <button
              className="filter-button"
              onClick={() => setShowFilter(!showFilter)}
              ref={filterButtonRef}
            >
              Filter
            </button>
          </div>
          {showFilter && (
            <FilterPopup
              selectedCutGroups={selectedCutGroups}
              setSelectedCutGroups={setSelectedCutGroups}
              enzymeGroups={enzymeGroups}
              annotations={annotations}
              selectedAnnotationTypes={selectedAnnotationTypes}
              setSelectedAnnotationTypes={setSelectedAnnotationTypes}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onClose={() => setShowFilter(false)}
              buttonRef={filterButtonRef}
            />
          )}
        </div>
      </div>

      <div className="sequence-controls-right">
        {/* Zoom (only visible for linear/both) */}
        {viewerType !== "circular" && (
          <div className="zoom-container">
            <label className="zoom-label" htmlFor="zoom-slider">
              Zoom
            </label>
            <input
              type="range"
              id="zoom-slider"
              className="zoom-slider"
              min="1"
              max="100"
              value={zoom}
              onChange={(e) => setZoom(parseInt(e.target.value))}
            />
          </div>
        )}

        <div className="nav-buttons">
          <button
            className="nav-button"
            onClick={() => handleNavigation("back")}
          >
            <FaArrowLeft />
          </button>
          <button
            className="nav-button"
            onClick={() => handleNavigation("forward")}
          >
            <FaArrowRight />
          </button>

          {/* Navigation tooltip (for "no earlier" or "no later" errors) */}
          {showTooltip && <div className="nav-tooltip">{tooltipMessage}</div>}
        </div>
      </div>
    </div>
  );
}

/* 
  Main Sequence component 
*/
function Sequence() {
  const dispatch = useDispatch();
  const artifact = useSelector((state) => state.project.artifact);
  const storedViewerType = useSelector(
    (state) => state.project.sequenceViewerType
  );
  const sequenceViewerSelection = useSelector(
    (state) => state.project.sequenceViewerSelection
  );
  const token = useSelector((state) => state.user.access_token);

  // Viewer state
  const [viewerType, setViewerType] = useState(storedViewerType || "circular");
  const [selectedSequenceName, setSelectedSequenceName] = useState(null);
  const [zoom, setZoom] = useState(50);

  // Restriction enzyme filtering
  const [selectedCutGroups, setSelectedCutGroups] = useState([]);
  const [filteredAnnotations, setFilteredAnnotations] = useState([]);

  // Tooltip for "no earlier/no later version"
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const tooltipTimeout = useRef(null);

  // --------------------------------------------------
  // Utility: collect valid sequences from artifact.content
  // --------------------------------------------------
  const validSequences = useMemo(() => {
    if (!artifact?.content) return [];
    const sequences = Object.keys(artifact.content).filter(
      (k) =>
        !["type", "file_id", "protocol_id", "digest_vendor"].includes(k) &&
        !k.startsWith("table_input_")
    );
    return sequences;
  }, [artifact]);

  // Auto-select the first available sequence if none chosen
  useEffect(() => {
    if (artifact?.content && validSequences.length > 0) {
      if (!selectedSequenceName || !artifact.content[selectedSequenceName]) {
        setSelectedSequenceName(validSequences[0]);
      }
    } else {
      setSelectedSequenceName(null);
    }
  }, [artifact, validSequences, selectedSequenceName]);

  // The actual sequence data object from artifact.content
  const sequenceData = useMemo(() => {
    if (!artifact?.content || !selectedSequenceName) return null;
    const data = artifact.content[selectedSequenceName];
    return data;
  }, [artifact, selectedSequenceName]);

  // Add logging for annotations
  useEffect(() => {
    if (sequenceData?.annotations) {
      setFilteredAnnotations(sequenceData.annotations);
    }
  }, [sequenceData?.annotations]);

  // Keep Redux updated with the current active sequence name
  useEffect(() => {
    if (selectedSequenceName) {
      dispatch(setCurrentSequence(selectedSequenceName));
    }
  }, [selectedSequenceName, dispatch]);

  // Clean sequence to only A/T/G/C (avoid N or non-bases)
  const cleanedSequence = useMemo(() => {
    if (!sequenceData?.seq) return "";
    // Get the sequence type from content
    const seqType = sequenceData.type || "dna"; // Default to DNA if not specified

    // Different cleaning rules based on sequence type
    switch (seqType.toLowerCase()) {
      case "aa":
        // Allow amino acid characters
        return sequenceData.seq.replace(/[^ACDEFGHIKLMNPQRSTVWY]/gi, "");
      case "rna":
        // Allow RNA bases (U instead of T)
        return sequenceData.seq.replace(/[^AUGC]/gi, "");
      case "dna":
      default:
        // Original DNA cleaning (A,T,G,C only)
        return sequenceData.seq.replace(/[^ATGC]/gi, "");
    }
  }, [sequenceData]);

  // Update viewer type state
  useEffect(() => {
    dispatch(setSequenceViewerType(viewerType));
  }, [viewerType, dispatch]);

  // Set initial viewer type based on topology
  useEffect(() => {
    if (!storedViewerType && sequenceData) {
      setViewerType("linear"); // Always default to linear
    }
  }, [sequenceData, storedViewerType]);

  // Force linear view if topology is linear
  useEffect(() => {
    if (sequenceData?.topology === "linear" && viewerType !== "linear") {
      setViewerType("linear");
    }
  }, [sequenceData?.topology, viewerType]);

  // Force linear view for RNA and protein sequences
  useEffect(() => {
    if (
      sequenceData?.type?.toLowerCase() === "aa" ||
      sequenceData?.type?.toLowerCase() === "rna"
    ) {
      setViewerType("linear");
    }
  }, [sequenceData?.type]);

  // Prepare enzyme grouping from "enzyme_counts" or "cutting_enzymes"
  const enzymeGroups = useMemo(() => {
    if (!sequenceData?.enzyme_counts) return {};
    const allGroups = { single: [], double: [], multiple: [] };

    // If we have enzyme_counts for each cut count, build the groups
    for (const [cutCountStr, enzymesArr] of Object.entries(
      sequenceData.enzyme_counts
    )) {
      const cutCount = parseInt(cutCountStr, 10);
      if (!Array.isArray(enzymesArr)) continue;
      enzymesArr.forEach((enzyme) => {
        // Convert cut values to integers
        const processedEnzyme = {
          ...enzyme,
          fcut: parseInt(enzyme.fcut, 10),
          rcut: parseInt(enzyme.rcut, 10),
        };

        if (processedEnzyme.fcut === 0 && processedEnzyme.rcut === 0) return; // skip nonsense cuts
        if (cutCount === 1) allGroups.single.push(processedEnzyme);
        else if (cutCount === 2) allGroups.double.push(processedEnzyme);
        else if (cutCount > 2) allGroups.multiple.push(processedEnzyme);
      });
    }
    return allGroups;
  }, [sequenceData]);

  // Combine user-chosen enzyme groups with any default cutting_enzymes
  const selectedEnzymes = useMemo(() => {
    if (!sequenceData) return [];
    const filteredEnzymes = selectedCutGroups.flatMap(
      (group) => enzymeGroups[group] || []
    );

    // If the sequence has a default cutting_enzymes array, include them
    if (sequenceData.cutting_enzymes) {
      // Handle if cutting_enzymes is an array or object
      const cutArr = Array.isArray(sequenceData.cutting_enzymes)
        ? sequenceData.cutting_enzymes
        : [];
      return [...filteredEnzymes, ...cutArr];
    }
    return filteredEnzymes;
  }, [sequenceData, enzymeGroups, selectedCutGroups]);

  // Navigation error tooltip (back/forward)
  const showNavigationErrorTooltip = (msg) => {
    setTooltipMessage(msg);
    setShowTooltip(true);
    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    tooltipTimeout.current = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    };
  }, []);

  // SequenceControls -> next/prev version
  const handleNavigation = (direction) => {
    if (!artifact?.file_id) return;
    dispatch(
      fetchArtifactData({
        fileId: artifact.file_id,
        direction,
      })
    )
      .unwrap()
      .catch((err) => {
        if (err.message.includes("No previous")) {
          showNavigationErrorTooltip("No earlier version");
        } else if (err.message.includes("No later")) {
          showNavigationErrorTooltip("No later version");
        } else {
          showNavigationErrorTooltip("Unable to load version");
        }
      });
  };

  // Handler for sequence dropdown
  const handleSequenceChange = (e) => {
    setSelectedSequenceName(e.target.value);
  };

  // If there's no sequence to display, show nothing
  if (!sequenceData) {
    return null;
  }

  return (
    <div className="sequence-content">
      <SequenceControls
        viewerType={viewerType}
        setViewerType={setViewerType}
        currentSequenceName={selectedSequenceName}
        sequenceNames={validSequences}
        handleSequenceChange={handleSequenceChange}
        zoom={zoom}
        setZoom={setZoom}
        selectedCutGroups={selectedCutGroups}
        setSelectedCutGroups={setSelectedCutGroups}
        enzymeGroups={enzymeGroups}
        annotations={sequenceData.annotations || []}
        setFilteredAnnotations={setFilteredAnnotations}
        handleNavigation={handleNavigation}
        showTooltip={showTooltip}
        tooltipMessage={tooltipMessage}
        topology={sequenceData?.topology}
        seqType={sequenceData.type?.toLowerCase()}
      />

      <SeqViz
        name={sequenceData.name || selectedSequenceName}
        seq={cleanedSequence}
        annotations={filteredAnnotations}
        enzymes={selectedEnzymes}
        // enzymes={["HindIII"]}
        highlights={sequenceData.highlights || []}
        viewer={viewerType}
        onSelection={(newSelection) =>
          dispatch(setSequenceViewerSelection(newSelection))
        }
        selection={sequenceViewerSelection}
        zoom={{ linear: zoom }}
        seqType={sequenceData.type?.toLowerCase() || "dna"}
      />
    </div>
  );
}

export default Sequence;
