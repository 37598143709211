import React from "react";
import ChatMessages from "./ChatMessages.js";
import ChatInput from "./ChatInput.js";
import RecentChats from "./RecentChats.js";
import ChatBar from "./ChatBar.js";
import { useSelector, useDispatch } from "react-redux";
import { setChatPanelOpen } from "../store/project.js";
import { FaChevronLeft } from "react-icons/fa";
import "../style/ChatPanel.css";

function ChatPanel() {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.project.chat);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const currentProject = useSelector((state) => state.project.currentProject);
  const currentChatId = useSelector((state) => state.project.currentChatId);
  const isMessageSending = useSelector(
    (state) => state.project.isMessageSending
  );
  const isChatPanelOpen = useSelector((state) => state.project.isChatPanelOpen);
  const activeChat = useSelector((state) => state.project.activeChat) || "chat";

  // Check if there are any chat files for the active chat type
  const hasChatFiles =
    currentProject?.[`${activeChat}_chat_files`] &&
    Object.keys(currentProject[`${activeChat}_chat_files`]).length > 0;

  return (
    <div
      className={`chat-panel-container ${!isChatPanelOpen ? "collapsed" : ""}`}
    >
      {!isChatPanelOpen && (
        <button
          className="chatbar-open-button"
          onClick={() => dispatch(setChatPanelOpen(true))}
          aria-label="Open chat panel"
        >
          <FaChevronLeft />
        </button>
      )}
      {isChatPanelOpen && (
        <>
          <ChatBar />
          {<ChatMessages chatType={activeChat} />}
          <div className="chat-panel-input">
            <ChatInput useEphemeral={false} chatType={activeChat} />
          </div>
          {currentProjectId &&
            !messages[currentChatId] &&
            !isMessageSending && <RecentChats />}
        </>
      )}
    </div>
  );
}

export default ChatPanel;
