import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { fetchArtifactData } from "../project.js";

export const applyToolCall = createAsyncThunk(
  "projects/applyToolCall",
  async (
    { filename, projectId, request, functionName },
    { getState, dispatch }
  ) => {
    const token = getState().user.access_token;
    const project = getState().project.currentProject;

    console.log("ApplyToolCall - Input params:", {
      filename,
      projectId,
      request,
      functionName,
    });

    const fileId = Object.keys(project.files).find(
      (id) => project.files[id].file_name === filename
    );

    if (!fileId) {
      throw new Error(`File not found: ${filename}`);
    }

    console.log("ApplyToolCall - Found fileId:", fileId);

    try {
      const requestBody = {
        filename,
        project_id: projectId,
        request,
        function_name: functionName,
      };


      const response = await fetchWithToken(
        `${API_URL}/api/apply-tool-call`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
        token
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("ApplyToolCall - Server error response:", {
          status: response.status,
          statusText: response.statusText,
          errorText,
        });
        throw new Error(`Failed to apply tool call edits: ${errorText}`);
      }

      const result = await response.json();
      console.log("ApplyToolCall - Server response:", result);

      if (result.status === "success") {
        // After successful apply, fetch the artifact data
        console.log(
          "ApplyToolCall - Fetching updated artifact data for fileId:",
          fileId
        );
        await dispatch(fetchArtifactData({ fileId }));
        return { ...result.data, fileId };
      } else {
        throw new Error(result.message || "Failed to apply tool call edits");
      }
    } catch (error) {
      console.error("ApplyToolCall - Error:", {
        name: error.name,
        message: error.message,
        stack: error.stack,
      });
      throw error;
    }
  }
);

export const applyToolCallReducer = {
  [applyToolCall.fulfilled]: (state, action) => {
    console.log("ApplyToolCall reducer - Fulfilled:", action.payload);
    const { fileId } = action.payload;
    if (state.currentProject?.files[fileId]) {
      const file = state.currentProject.files[fileId];
      const url = file.s3_url;
      if (url) {
        const lastDotIndex = url.lastIndexOf(".");
        const newUrl =
          lastDotIndex !== -1
            ? url.slice(0, lastDotIndex) + "_edit" + url.slice(lastDotIndex)
            : url + "_edit";

        state.currentProject.files[fileId].s3_url = newUrl;
        console.log("ApplyToolCall reducer - Updated file URL:", newUrl);

        // Update the inEditState of the current project
        state.currentProject.inEditState = true;
      }
    }
    state.applyingToolCall = null;
  },
  [applyToolCall.pending]: (state, action) => {
    console.log("ApplyToolCall reducer - Pending:", action.meta.arg);
    state.error = null;
    state.applyingToolCall = {
      filename: action.meta.arg.filename,
      request: action.meta.arg.request,
      projectId: action.meta.arg.projectId,
      functionName: action.meta.arg.functionName,
    };
  },
  [applyToolCall.rejected]: (state, action) => {
    console.error("ApplyToolCall reducer - Rejected:", {
      error: action.error.message,
      meta: action.meta,
    });
    state.error = action.error.message;
    state.applyingToolCall = null;
  },
};
