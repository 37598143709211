import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateProject } from "../project.js";

export const handleDiffChanges = createAsyncThunk(
  "projects/handleDiffChanges",
  async ({ projectId, action, fileIds = [] }, { getState, dispatch }) => {
    console.log("handleDiffChanges called");
    const token = getState().user.access_token;

    // Save the chat-related fields before making the API call
    const currentProject = getState().project.currentProject;
    const chatFields = {
      agent_chat_files: currentProject.agent_chat_files,
      agent_chat_next_token: currentProject.agent_chat_next_token,
      chat_chat_files: currentProject.chat_chat_files,
      chat_chat_next_token: currentProject.chat_chat_next_token,
      popup_chat_files: currentProject.popup_chat_files,
    };

    console.log("handleDiffChanges chatFields", chatFields);

    const response = await handleDiffChangesAPI(
      projectId,
      action,
      fileIds,
      token
    );

    console.log("handleDiffChanges response", response);

    // Merge the saved chat fields with the response
    const updatedResponse = {
      ...response,
      ...chatFields,
    };

    // Update project with the merged response
    dispatch(updateProject(updatedResponse));

    return updatedResponse;
  }
);

export const handleDiffChangesReducer = {
  [handleDiffChanges.fulfilled]: (state, action) => {
    // State is already updated by updateProject in the thunk
    state.editorNeedsRemount = true;
  },
};

export const handleDiffChangesAPI = async (
  projectId,
  action,
  fileIds,
  token
) => {
  try {
    console.log("handleDiffChanges called with:", {
      projectId,
      action,
      fileIds,
    });
    const response = await fetchWithToken(
      `${API_URL}/api/diff-file-changes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          project_id: projectId,
          action: action, // 'accept_all' or 'reject_all' or 'accept' or 'reject'
          file_ids: fileIds,
        }),
      },
      token
    );

    if (!response.ok) {
      console.error("Failed to handle diff changes. Status:", response.status);
      throw new Error("Failed to handle diff changes");
    }

    const data = await response.json();
    console.log("Updated Project:", data);
    return data.status === "success" ? data.data : Promise.reject(data.message);
  } catch (error) {
    console.error("Error in handleDiffChanges:", error.message);
    throw error;
  }
};
