import React, { useEffect, useRef, useCallback, useState } from "react";
import FileTab from "./FileTab.js";
import "../style/FileBar.css";
import { useSelector, useDispatch } from "react-redux";
import {
  removeFileTab,
  setActiveFileTab,
  fetchArtifactData,
  addFileTab,
} from "../store/project.js";
import { updateFilesOpen } from "../api/files_api.js";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function FileBar() {
  const dispatch = useDispatch();
  const openFiles = useSelector((state) => state.project.openFiles);
  const activeFileId = useSelector((state) => state.project.activeFileId);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const currentProject = useSelector((state) => state.project.currentProject);
  const userId = useSelector((state) => state.user.user_id);
  const token = useSelector((state) => state.user.access_token);
  const artifact = useSelector((state) => state.project.artifact);

  // Ref to track if we've already handled the initial file open for this project
  const initialFileOpenedRef = useRef({});
  // Ref to track the last fetched file ID to prevent duplicate fetches
  const lastFetchedFileRef = useRef(null);
  // Add a ref to track if files are being reordered
  const isReorderingRef = useRef(false);
  const fileBarRef = useRef(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);

  // Modify checkScrollPosition to be more precise
  const checkScrollPosition = useCallback(() => {
    if (fileBarRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = fileBarRef.current;
      // Only show left button if we've scrolled right
      setShowLeftScroll(scrollLeft > 1);
      // Only show right button if there's more content to scroll to
      setShowRightScroll(Math.ceil(scrollLeft + clientWidth) < scrollWidth - 1);
    }
  }, []);

  // Add effect to check scroll when openFiles changes
  useEffect(() => {
    checkScrollPosition();
  }, [openFiles, checkScrollPosition]);

  // Modify the existing useEffect for initial load
  useEffect(() => {
    const fileBar = fileBarRef.current;
    if (fileBar) {
      const resizeObserver = new ResizeObserver(() => {
        checkScrollPosition();
      });

      resizeObserver.observe(fileBar);
      fileBar.addEventListener("scroll", checkScrollPosition);

      // Initial check
      requestAnimationFrame(() => {
        checkScrollPosition();
      });

      return () => {
        resizeObserver.disconnect();
        fileBar.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, [checkScrollPosition]);

  // Add scroll handlers
  const handleScrollLeft = () => {
    if (fileBarRef.current) {
      fileBarRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    if (fileBarRef.current) {
      fileBarRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  // Add this near the top with other useEffect imports
  useEffect(() => {
    // Skip if no project loaded
    if (!currentProject?.files) {
      return;
    }

    // Get list of currently open files that no longer exist in project
    const removedFileIds = Object.keys(openFiles).filter(
      (fileId) => !currentProject.files[fileId]
    );

    // Remove any open files that are no longer in the project
    if (removedFileIds.length > 0) {
      removedFileIds.forEach((fileId) => {
        dispatch(removeFileTab(fileId));
      });

      // Update backend if any files were removed
      if (currentProjectId && userId && token) {
        const updatedFileIds = Object.keys(openFiles).filter(
          (id) => !removedFileIds.includes(id)
        );
        updateFilesOpen(currentProjectId, updatedFileIds, userId, token).catch(
          (error) => console.error("Failed to update files open:", error)
        );
      }
    }
  }, [
    currentProject?.files,
    openFiles,
    currentProjectId,
    userId,
    token,
    dispatch,
  ]);

  const handleCloseFile = (fileId) => {
    console.log(`Closing file: ${fileId}`, openFiles[fileId]?.name);

    // Find the next file to activate before removing the current one
    if (fileId === activeFileId) {
      const sortedFiles = Object.entries(openFiles)
        .sort(([, a], [, b]) => a.order - b.order)
        .map(([id]) => id);

      const currentIndex = sortedFiles.indexOf(fileId);
      // Try to get the file to the left, if not available get the first file
      const nextFileId =
        currentIndex > 0
          ? sortedFiles[currentIndex - 1]
          : sortedFiles.length > 1
          ? sortedFiles[currentIndex + 1]
          : null;

      if (nextFileId) {
        dispatch(setActiveFileTab(nextFileId));
        dispatch(fetchArtifactData({ fileId: nextFileId }));
      }
    }

    dispatch(removeFileTab(fileId));

    // Only update backend when explicitly closing a file
    if (currentProjectId && userId && token) {
      const updatedFileIds = Object.keys(openFiles).filter(
        (id) => id !== fileId
      );
      console.log(
        "Updated files_open list:",
        updatedFileIds.map((id) => openFiles[id]?.name)
      );
      updateFilesOpen(currentProjectId, updatedFileIds, userId, token).catch(
        (error) => console.error("Failed to update files open:", error)
      );
    }
  };

  const handleTabClick = (fileId) => {
    if (fileId === activeFileId) return;
    dispatch(setActiveFileTab(fileId));
    console.log("Fetching artifact data for newly activated file:", {
      activeFileId,
      lastFetched: lastFetchedFileRef.current,
    });
    dispatch(fetchArtifactData({ fileId }));
  };

  const handleDragStart = (e, fileId, order) => {
    e.dataTransfer.setData(
      "application/json",
      JSON.stringify({ fileId, order })
    );
    e.currentTarget.classList.add("dragging");
  };

  const handleDragEnd = (e) => {
    e.currentTarget.classList.remove("dragging");
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const dragTarget = e.target.closest(".ft-tab");
    if (dragTarget) {
      const allTabs = document.querySelectorAll(".ft-tab");
      allTabs.forEach((tab) => tab.classList.remove("drag-over"));
      dragTarget.classList.add("drag-over");
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    const dragTarget = e.target.closest(".ft-tab");
    if (dragTarget) {
      dragTarget.classList.remove("drag-over");
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Clear visual drag effects
    const allTabs = document.querySelectorAll(".ft-tab");
    allTabs.forEach((tab) => tab.classList.remove("drag-over"));

    const dropTarget = e.target.closest(".ft-tab");
    if (!dropTarget) return;

    try {
      const data = JSON.parse(e.dataTransfer.getData("application/json"));
      const fromOrder = parseInt(data.order);
      const toOrder = parseInt(dropTarget.dataset.order);

      if (isNaN(fromOrder) || isNaN(toOrder) || fromOrder === toOrder) return;

      // First convert to array and ensure it's properly sorted
      let filesArray = Object.entries(openFiles)
        .map(([id, file]) => ({
          id,
          name: file.name,
          type: file.type,
          content: file.content,
          order: file.order,
        }))
        .sort((a, b) => a.order - b.order);

      // Find the actual indices in the sorted array
      const fromIndex = filesArray.findIndex((f) => f.order === fromOrder);
      const toIndex = filesArray.findIndex((f) => f.order === toOrder);

      if (fromIndex === -1 || toIndex === -1) return;

      // Remove and insert at new position
      const [movedFile] = filesArray.splice(fromIndex, 1);
      filesArray.splice(toIndex, 0, movedFile);

      // Create new openFiles object with sequential orders
      const newOpenFiles = filesArray.reduce((acc, file, index) => {
        acc[file.id] = {
          name: file.name,
          type: file.type,
          content: file.content,
          order: index,
        };
        return acc;
      }, {});

      // Update Redux store
      dispatch({
        type: "projects/updateOpenFiles",
        payload: newOpenFiles,
      });

      // Update backend with new file order
      if (currentProjectId && userId && token) {
        const updatedFileIds = filesArray.map((file) => file.id);
        updateFilesOpen(currentProjectId, updatedFileIds, userId, token);
      }
    } catch (error) {
      console.error("Error handling drop:", error);
    }
  };

  // Replace the existing useEffect for scrolling active tab into view
  useEffect(() => {
    // Add a small delay to ensure DOM is updated
    const scrollTimer = setTimeout(() => {
      if (activeFileId && fileBarRef.current) {
        const activeTab = fileBarRef.current.querySelector(
          `[data-file-id="${activeFileId}"]`
        );
        if (activeTab) {
          const fileBar = fileBarRef.current;
          const tabRect = activeTab.getBoundingClientRect();
          const containerRect = fileBar.getBoundingClientRect();

          // Check if the tab is outside the visible area
          if (
            tabRect.left < containerRect.left ||
            tabRect.right > containerRect.right
          ) {
            const scrollLeft =
              activeTab.offsetLeft -
              (fileBar.clientWidth - activeTab.offsetWidth) / 2;
            fileBar.scrollTo({
              left: scrollLeft,
              behavior: "smooth",
            });
          }
        }
      }
    }, 100); // Small delay to ensure DOM updates

    return () => clearTimeout(scrollTimer);
  }, [activeFileId, currentProjectId]); // Add currentProjectId as dependency

  // Replace the existing useEffect for artifact sync
  useEffect(() => {
    // Only proceed if we have both an artifact and openFiles
    if (
      !artifact?.file_id ||
      !openFiles ||
      Object.keys(openFiles).length === 0
    ) {
      return;
    }

    // Check if the current active file matches the artifact
    const activeFileData = openFiles[activeFileId];
    const artifactFileName = artifact.file_name;

    // If active file doesn't match artifact file, find and set the correct one
    if (!activeFileData || activeFileData.name !== artifactFileName) {
      // Find the file ID that matches the artifact file name without changing order
      const matchingFileId = Object.entries(openFiles).find(
        ([, file]) => file.name === artifactFileName
      )?.[0];

      // If we found a matching file and it's different from current active file,
      // just change the active tab without modifying the order
      if (matchingFileId && matchingFileId !== activeFileId) {
        dispatch(setActiveFileTab(matchingFileId));
      }
    }
  }, [
    artifact?.file_id,
    artifact?.file_name,
    openFiles,
    activeFileId,
    dispatch,
  ]);

  return (
    <div className="fb-file-bar-container">
      <button
        className={`fb-scroll-button fb-scroll-left ${
          showLeftScroll ? "visible" : ""
        }`}
        onClick={handleScrollLeft}
        aria-label="Scroll tabs left"
      >
        <FaChevronLeft />
      </button>
      <div
        ref={fileBarRef}
        className="fb-file-bar"
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {Object.entries(openFiles)
          .sort(([, a], [, b]) => a.order - b.order)
          .map(([fileId, file]) => (
            <div
              key={fileId}
              className={`ft-tab ${fileId === activeFileId ? "active" : ""}`}
              draggable="true"
              onDragStart={(e) => handleDragStart(e, fileId, file.order)}
              onDragEnd={handleDragEnd}
              data-file-id={fileId}
              data-order={file.order}
              onClick={() => handleTabClick(fileId)}
            >
              <FileTab
                fileName={file.name}
                fileId={fileId}
                fileType={file.type}
                onClose={() => handleCloseFile(fileId)}
                isActive={fileId === activeFileId}
              />
            </div>
          ))}
      </div>
      <button
        className={`fb-scroll-button fb-scroll-right ${
          showRightScroll ? "visible" : ""
        }`}
        onClick={handleScrollRight}
        aria-label="Scroll tabs right"
      >
        <FaChevronRight />
      </button>
    </div>
  );
}

export default FileBar;
