// src/components/tools/ToolComplexityAnalyzer.js
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UploadToTool from "../UploadToTool.js";
import { removeToolFile } from "../../store/project.js";
import { runComplexityAnalysis } from "../../api/tools_api.js";
import "./ToolComplexityAnalyzer.css";

function ToolComplexityAnalyzer({ tool }) {
  const selectedFiles = useSelector((state) => state.project.tools_file_ids);
  const token = useSelector((state) => state.user.access_token);
  const userId = useSelector((state) => state.user.user_id);
  const dispatch = useDispatch();
  const [analysisOption, setAnalysisOption] = useState("createNew");
  const [analysisComplete, setAnalysisComplete] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [submittedFiles, setSubmittedFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedFiles.length > 10) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [selectedFiles]);

  const handleClickOutside = () => {
    setShowWarning(false);
  };

  useEffect(() => {
    if (showWarning) {
      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, [showWarning]);

  const handleAnalyze = async () => {
    if (selectedFiles.length === 0 || selectedFiles.length > 10) return;

    setIsLoading(true);
    setError(null);

    try {
      // Format file names with proper conjunction
      const fileNames = selectedFiles.map((file) => file.name);
      const formattedFileNames =
        fileNames.length > 1
          ? `${fileNames.slice(0, -1).join(", ")} and ${fileNames.slice(-1)}`
          : fileNames[0];

      const fileIds = selectedFiles.map((file) => file.id);
      const success = await runComplexityAnalysis(
        fileIds,
        analysisOption,
        userId,
        token,
      );

      if (success) {
        // Clear all selected files
        selectedFiles.forEach((file) => {
          dispatch(removeToolFile(file.id));
        });

        setSubmittedFiles(formattedFileNames);
        setAnalysisComplete(true);
      } else {
        throw new Error("Analysis submission failed");
      }
    } catch (err) {
      setError(err.message || "Failed to submit analysis");
      console.error("Error submitting analysis:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tool-complexityanalyzer-container">
      <h1 className="tool-complexityanalyzer-title">{tool.title}</h1>
      <p className="tool-complexityanalyzer-description">{tool.description}</p>

      <div className="tool-complexityanalyzer-upload">
        <UploadToTool />
      </div>

      {showWarning && (
        <div className="tool-complexityanalyzer-warning">
          Only 10 files can be analyzed at a time
        </div>
      )}

      <div className="tool-complexityanalyzer-options">
        <h3>Analysis Options</h3>
        <div className="tool-complexityanalyzer-radio">
          <div className="radio-group">
            <input
              type="radio"
              id="createNew"
              value="createNew"
              checked={analysisOption === "createNew"}
              onChange={() => setAnalysisOption("createNew")}
            />
            <label htmlFor="createNew">Create New File</label>
          </div>
          <div className="radio-group">
            <input
              type="radio"
              id="updateExisting"
              value="updateExisting"
              checked={analysisOption === "updateExisting"}
              onChange={() => setAnalysisOption("updateExisting")}
            />
            <label htmlFor="updateExisting">Update Existing File</label>
          </div>
        </div>
      </div>

      <div className="tool-complexityanalyzer-credits">
        Credits required: <strong>{Math.min(selectedFiles.length, 10)}</strong>
      </div>

      <button
        className="tool-complexityanalyzer-button"
        onClick={handleAnalyze}
        disabled={
          selectedFiles.length === 0 || selectedFiles.length > 10 || isLoading
        }
      >
        {isLoading ? "Submitting..." : "Analyze Files"}
      </button>

      {error && <div className="tool-complexityanalyzer-error">{error}</div>}

      {submittedFiles && !error && (
        <div className="tool-complexityanalyzer-success">
          {submittedFiles} submitted for complexity analysis. We will{" "}
          {analysisOption === "createNew"
            ? "create a new file"
            : "update the existing file"}{" "}
          with a summary analysis and table. You will receive an email when
          processing is complete.
        </div>
      )}
    </div>
  );
}

export default ToolComplexityAnalyzer;
