// notebookThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const editNotebookData = createAsyncThunk(
  "projects/editNotebookData",
  async ({ fileId, content }, { getState }) => {
    const token = getState().user.access_token;
    console.log("editNotebookData called with:", { fileId, content });
    const response = await editNotebookContent(fileId, content, token);
    if (response.status === "success") {
      return { content, file_id: fileId };
    }
    throw new Error("Failed to save notebook content");
  }
);

// Optional: Export extraReducers specifically for notebook-related cases
export const editNotebookDataReducer = {
  [editNotebookData.fulfilled]: (state, action) => {
    if (state.artifact && state.artifact.file_id === action.payload.file_id) {
      state.artifact.content = action.payload.content;
      console.log("editNotebookDataReducer fulfilled with:", {
        file_id: action.payload.file_id,
        content: action.payload.content,
      });
    }
  },
  [editNotebookData.rejected]: (state, action) => {
    state.error = action.error.message;
  },
};

export const editNotebookContent = async (fileId, content, token) => {
  console.log("editNotebookContent called with:", { fileId, content });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/edit-notebook-content`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          file_id: fileId,
          content: content,
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to edit notebook content");
    }

    const result = await response.json();
    return { status: result.status };
  } catch (error) {
    console.error("Error in editNotebookContent:", error);
    throw error;
  }
};
