export const enzyme_list = {
  unique_enzymes: [
    "AanI",
    "AasI",
    "AatI",
    "AatII",
    "Acc16I",
    "Acc36I",
    "Acc65I",
    "AccB1I",
    "AccB2I",
    "AccB7I",
    "AccBSI",
    "AccI",
    "AccII",
    "AccIII",
    "AciI",
    "AclI ",
    "AclI",
    "AcsI",
    "AcuI",
    "AcvI",
    "AcyI",
    "AdeI",
    "AfaI",
    "AfeI",
    "AfiI",
    "AflII",
    "AflIII",
    "AgeI",
    "AhdI",
    "AhlI",
    "AjnI",
    "AjuI",
    "AleI ",
    "AleI-v2",
    "AluBI",
    "AluI",
    "Alw21I",
    "Alw26I",
    "Alw44I",
    "AlwI",
    "AlwNI ",
    "AlwNI",
    "Ama87I",
    "Aor13HI",
    "Aor51HI ",
    "ApaI",
    "ApaLI",
    "ApeKI",
    "ApoI",
    "AscI",
    "AseI",
    "AsiGI",
    "AsiSI",
    "Asp700I",
    "Asp718I",
    "AspA2I",
    "AspEI",
    "AspI",
    "AspLEI",
    "AspS9I",
    "AssI",
    "AsuC2I",
    "AsuII",
    "AsuNHI",
    "AvaI",
    "AvaII",
    "AviII",
    "AvrII",
    "AxyI",
    "BaeGI",
    "BaeI",
    "BalI",
    "BamHI §",
    "BamHI ",
    "BamHI",
    "BanI",
    "BanII",
    "BanIII",
    "BasI",
    "BbeI",
    "BbrPI",
    "BbsI §",
    "BbsI",
    "BbuI",
    "Bbv12I",
    "BbvCI",
    "BbvI",
    "BccI",
    "BceAI",
    "BcgI",
    "BciVI",
    "BclI §",
    "BclI",
    "BcnI",
    "Bco35I",
    "BcoDI",
    "BcuI",
    "BfaI",
    "BfmI",
    "BfoI",
    "BfrI",
    "BfuAI",
    "BfuCI",
    "BglI",
    "BglII",
    "BhaI",
    "BlnI ",
    "BlpI",
    "BmcAI",
    "Bme1390I",
    "Bme142I",
    "Bme18I",
    "BmeRI",
    "BmeT110I",
    "BmgBI",
    "BmgT120I",
    "BmiI",
    "BmrFI",
    "BmrI",
    "BmsI",
    "BmtI ",
    "BmtI",
    "BoxI",
    "BplI",
    "BpmI",
    "Bpu10I",
    "Bpu1102I",
    "Bpu14I",
    "BpuAI",
    "BpuEI",
    "BpuMI",
    "BpvUI",
    "Bsa29I",
    "BsaAI",
    "BsaBI",
    "BsaHI",
    "BsaI",
    "BsaJI",
    "BsaMI",
    "BsaWI",
    "BsaXI",
    "Bsc4I",
    "BscAI",
    "BscUI",
    "Bse118I",
    "Bse1I",
    "Bse21I",
    "Bse3DI",
    "Bse8I ",
    "BseAI",
    "BseBI",
    "BseCI",
    "BseDI",
    "BseGI",
    "BseJI",
    "BseLI",
    "BseMI",
    "BseMII",
    "BseNI",
    "BsePIm",
    "BseRI",
    "BseSI",
    "BseX3I",
    "BseXI",
    "BseYI",
    "BsgI",
    "Bsh1236I",
    "Bsh1285I",
    "BshFI",
    "BshNI",
    "BshTI",
    "BshVI",
    "BsiEI",
    "BsiHKAI",
    "BsiHKCI",
    "BsiSI",
    "BsiWI §",
    "BsiWI",
    "BslFI",
    "BslI ",
    "BslI",
    "BsmAI",
    "BsmBI",
    "BsmFI ",
    "BsmFI",
    "BsmHI",
    "BsmI",
    "BsmNI",
    "BsnI",
    "Bso31I",
    "BsoBI",
    "BsoBIm",
    "Bsp119I",
    "Bsp120I",
    "Bsp1286I",
    "Bsp13I",
    "Bsp1407I",
    "Bsp143I",
    "Bsp143II",
    "Bsp1720I",
    "Bsp19I",
    "Bsp22I",
    "Bsp28I",
    "Bsp6II",
    "BspACI ",
    "BspANI",
    "BspCNI ",
    "BspCNI",
    "BspD6II",
    "BspDI",
    "BspEI",
    "BspFNI",
    "BspHI",
    "BspJ74I",
    "BspKT5I",
    "BspLI",
    "BspMAI",
    "BspMI ",
    "BspMI",
    "BspOI",
    "BspQI",
    "BspST5I",
    "BspT104I",
    "BspT107I ",
    "BspTI",
    "BspTNI",
    "BspXI",
    "BsrBI ",
    "BsrBI",
    "BsrDI ",
    "BsrDI",
    "BsrFI",
    "BsrGI",
    "BsrI",
    "BsrSI",
    "BssAI ",
    "BssECI ",
    "BssHII",
    "BssKI",
    "BssMI",
    "BssNAI",
    "BssNI",
    "BssSI",
    "BssT1I",
    "Bst1107I",
    "Bst1473II",
    "Bst16I",
    "Bst19I",
    "Bst2UI",
    "Bst4CI",
    "Bst6I",
    "Bst98I",
    "BstACI",
    "BstAFI",
    "BstAPI",
    "BstAUI",
    "BstBAI ",
    "BstBI",
    "BstDEI",
    "BstEII",
    "BstENI",
    "BstF5I",
    "BstFNI",
    "BstGZ53I",
    "BstH2I",
    "BstHHI",
    "BstKTI",
    "BstMAI",
    "BstMBI",
    "BstMCI ",
    "BstMWI",
    "BstNI",
    "BstNSI",
    "BstOI",
    "BstPAI",
    "BstPI",
    "BstSCI",
    "BstSFI",
    "BstSLI ",
    "BstSNI",
    "BstUI",
    "BstV1I ",
    "BstV1I",
    "BstV2I ",
    "BstX2I",
    "BstXI",
    "BstYI",
    "BstZ17I ",
    "BstZ17I",
    "BstZI",
    "Bsu15I",
    "Bsu36I ",
    "Bsu36I",
    "BsuRI",
    "BsuTUI",
    "BtgI",
    "BtgZI",
    "Bth1795I",
    "BtsCI",
    "BtsI-v2",
    "BtsIMutI",
    "Btu34II",
    "BtuMI",
    "BveI",
    "Cac8I",
    "CaiI",
    "CciI",
    "CciNI",
    "CelII ",
    "CfoI",
    "Cfr10I",
    "Cfr13I",
    "CjeP338II",
    "ClaI",
    "CpoI",
    "CseI",
    "CsiI",
    "Csp45I",
    "Csp6I",
    "CspAI",
    "CspCI",
    "CspI",
    "CviAII",
    "CviKI-1",
    "CviQI",
    "DdeI ",
    "DdeI",
    "DinI",
    "DpnI",
    "DpnII",
    "DraI",
    "DraII",
    "DraIII ",
    "DraIII",
    "DrdI",
    "DriI",
    "DseDI ",
    "EaeI",
    "EagI",
    "Eam1104I",
    "Eam1105I",
    "EarI ",
    "EarI",
    "EciI",
    "Ecl136II",
    "EclHKI",
    "EclXI ",
    "Eco105I",
    "Eco112I",
    "Eco125I",
    "Eco130I",
    "Eco147I",
    "Eco31I",
    "Eco32I",
    "Eco47I",
    "Eco47III",
    "Eco52I",
    "Eco53kI",
    "Eco57I",
    "Eco72I",
    "Eco81I",
    "Eco88I",
    "Eco91I",
    "EcoICRI",
    "EcoNI ",
    "EcoNI",
    "EcoO109I",
    "EcoO65I",
    "EcoP15I",
    "EcoRI §",
    "EcoRI",
    "EcoRV ",
    "EcoRV §",
    "EcoRV ",
    "EcoRV",
    "EcoT14I",
    "EcoT22I",
    "EgeI",
    "EheI",
    "ErhI",
    "Esp16I",
    "Esp23I ",
    "Esp3I",
    "FaeI",
    "FaqI",
    "FatI",
    "FauI",
    "FauNDI",
    "FbaI",
    "FblI ",
    "Fnu4HI",
    "FokI",
    "FseI",
    "FsfI",
    "Fsp4HI",
    "FspAI",
    "FspBI",
    "FspI ",
    "FspI",
    "GsuI",
    "HaeII",
    "HaeIII",
    "HapII",
    "HgaI ",
    "HgaI",
    "HhaI",
    "Hin1I",
    "Hin1II",
    "Hin6I",
    "HinHI",
    "HinP1I",
    "HincII",
    "HindII",
    "HindIII §",
    "HindIII",
    "HinfI",
    "HpaI ",
    "HpaI",
    "HpaII",
    "HphI",
    "Hpy166II",
    "Hpy188I",
    "Hpy188III",
    "Hpy8I",
    "Hpy99I",
    "HpyAV",
    "HpyCH4III",
    "HpyCH4IV",
    "HpyCH4V",
    "HpyF10VI",
    "HpyF3I",
    "Hsp92I ",
    "Hsp92II",
    "HspAI ",
    "HspAI",
    "I-CeuI",
    "I-SceI",
    "ItaI ",
    "KasI",
    "KflI",
    "Kpn2I",
    "KpnI",
    "Ksp22I",
    "KspAI",
    "Kzo9I",
    "LguI",
    "LpnI",
    "Lsp1109I",
    "LweI",
    "MabI",
    "MaeI",
    "MaeII",
    "MalI",
    "MauBI",
    "MbiI",
    "MboI",
    "MboII",
    "MfeI ",
    "MfeI",
    "MflI",
    "MhlI ",
    "MlsI",
    "MluCI",
    "MluI",
    "MluNI",
    "Mly113I",
    "MlyI",
    "MmeI",
    "MnlI",
    "Mph1103I",
    "MreI",
    "MroI",
    "MroNI",
    "MroXI",
    "MscI",
    "MseI",
    "MslI",
    "Msp20I ",
    "MspA1I",
    "MspCI",
    "MspI",
    "MspJI",
    "MspR9I",
    "MssI",
    "MunI",
    "Mva1269I",
    "Mva1312II",
    "MvaI",
    "MvnI",
    "MvrI",
    "MwoI",
    "NaeI",
    "NarI",
    "Nb.BbvCI",
    "Nb.BsmI",
    "Nb.BsrDI",
    "Nb.BssSI",
    "Nb.BtsI",
    "NciI ",
    "NciI",
    "NcoI §",
    "NcoI",
    "NdeI",
    "NdeII",
    "NgoAI",
    "NgoBI",
    "NgoCI",
    "NgoGI",
    "NgoJI",
    "NgoMI",
    "NgoMIV ",
    "NgoMIV",
    "NgoWI",
    "NheI",
    "NlaIII ",
    "NlaIII",
    "NlaIV",
    "NmeAIII",
    "NmuCI",
    "NotI §",
    "NotI",
    "NruI",
    "NsbI",
    "NsiI §",
    "NsiI",
    "NspI ",
    "NspI",
    "NspV",
    "Nt.AlwI",
    "Nt.BbvCI",
    "Nt.BsmAI",
    "Nt.BspQI",
    "Nt.BstNBI",
    "Nt.CviPII",
    "OliI",
    "PI-PspI",
    "PI-SceI",
    "PacI",
    "PaeI",
    "PaeR7I",
    "PagI",
    "PalAI ",
    "PaqCI",
    "PceI",
    "PciI",
    "PciSI",
    "PctI",
    "PdiI",
    "PdmI",
    "PfeI",
    "Pfl23II",
    "PflFI",
    "PflMI ",
    "PflMI",
    "PfoI",
    "PhaI",
    "PhoI ",
    "PinAI ",
    "Ple19I",
    "PleI",
    "PluTI",
    "PmaCI",
    "PmeI",
    "PmlI",
    "PpsI",
    "Ppu21I",
    "PpuMI",
    "PshAI ",
    "PshAI",
    "PshBI ",
    "PsiI ",
    "PsiI-v2",
    "Psp124BI",
    "Psp1406I",
    "Psp5II",
    "Psp6I",
    "PspCI ",
    "PspEI",
    "PspFI",
    "PspGI",
    "PspLI ",
    "PspN4I ",
    "PspOMI",
    "PspPI",
    "PspPPI ",
    "PspXI",
    "PstI §",
    "PstI",
    "PsuI",
    "PsyI",
    "PteI",
    "PvuI",
    "PvuII §",
    "PvuII",
    "RcaI ",
    "RgaI",
    "RruI",
    "RsaI",
    "RsaNI",
    "RseI",
    "Rsr2I",
    "RsrII ",
    "RsrII",
    "SacI",
    "SacII",
    "SalI §",
    "SalI",
    "SanDI",
    "SapI ",
    "SapI",
    "SaqAI",
    "SatI",
    "Sau3AI",
    "Sau3AIm",
    "Sau96I ",
    "Sau96I",
    "SbfI",
    "ScaI",
    "SchI ",
    "SchI",
    "ScrFI",
    "SdaI",
    "SduI",
    "SexAI",
    "SfaAI",
    "SfaNI",
    "SfcI ",
    "SfcI",
    "SfiI",
    "SfoI",
    "Sfr274I",
    "SfuI",
    "SgfI ",
    "SgrAI",
    "SgsI",
    "SinI",
    "SlaI",
    "SmaI",
    "SmiI",
    "SmiMI ",
    "SmlI",
    "SnaBI ",
    "SnaBI",
    "SpeI ",
    "SpeI ",
    "SpeI",
    "SphI ",
    "SphI §",
    "SphI",
    "SrfI",
    "Sse1825I",
    "Sse8387I ",
    "Sse9I",
    "SseBI",
    "SsiI",
    "SspI",
    "SstE37III ",
    "SstI",
    "StrI",
    "StuI ",
    "StuI",
    "StyD4I ",
    "StyD4I",
    "StyI ",
    "StyI",
    "SwaI ",
    "SwaI",
    "TaaI",
    "TaiI",
    "TaqI",
    "TaqI-v2",
    "TasI",
    "TatI",
    "TauI",
    "TfiI ",
    "TfiI",
    "TliI",
    "Tru1I",
    "Tru9I",
    "TscAI",
    "TseI",
    "Tsp45I",
    "Tsp509I",
    "TspEI ",
    "TspMI",
    "TspRI ",
    "TspRI",
    "Tth111I",
    "Uba1437I",
    "Uba1444I ",
    "Van91I",
    "Vha464I ",
    "VneI ",
    "VpaK11BI ",
    "VspI",
    "WarmStart® Nt.BstNBI",
    "XagI",
    "XapI",
    "XbaI",
    "XceI",
    "XcmI",
    "XhoI",
    "XhoII",
    "XmaCI",
    "XmaI",
    "XmaJI",
    "XmiI",
    "XmnI",
    "XspI ",
    "ZraI",
    "ZrmI",
    "Zsp2I ",
  ],
};
