// ToolSynthesisSuccessPredictor.js
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import UploadToTool from "../UploadToTool.js";
import { removeToolFile } from "../../store/project.js";
import { runSynthesisPrediction } from "../../api/tools_api.js";
import "./ToolSynthesisSuccessPredictor.css";

function ToolSynthesisSuccessPredictor({ tool }) {
  const dispatch = useDispatch();
  const selectedFiles = useSelector((state) => state.project.tools_file_ids);
  const token = useSelector((state) => state.user.access_token);
  const userId = useSelector((state) => state.user.user_id);
  const [vendor, setVendor] = useState("IDT");
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    return () => {
      selectedFiles.forEach((file) => {
        dispatch(removeToolFile(file.id));
      });
      setVendor("IDT");
      setSubmitted(false);
      setResults([]);
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    if (!selectedFiles.length) return;

    setIsLoading(true);
    setSubmitted(false);
    setError(null);

    try {
      const fileIds = selectedFiles.map((file) => file.id);
      const result = await runSynthesisPrediction(
        fileIds,
        vendor,
        userId,
        token
      );

      setResults(result.predictions || []);
      setSubmitted(true);

      // Clear files after successful submission
      selectedFiles.forEach((file) => {
        dispatch(removeToolFile(file.id));
      });
    } catch (err) {
      setError(err.message || "Failed to run synthesis prediction");
      console.error("Error running synthesis prediction:", err);
    } finally {
      setIsLoading(false);
    }
  };

  // Render the table dynamically based on the columns in the results
  const renderTable = () => {
    console.log("results in render table", results);
    if (!results.length) return null;

    // Get all unique column names from the results
    const columns = [
      ...new Set(results.flatMap((result) => Object.keys(result))),
    ];

    const formatCell = (value) => {
      if (Array.isArray(value)) {
        return value.length ? (
          <ul>
            {value.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        ) : (
          "—"
        );
      }
      return value || "—";
    };

    return (
      <table className="toolsynthesis-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>
                {column
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {results.map((result, idx) => (
            <tr key={idx}>
              {columns.map((column) => (
                <td key={column}>{formatCell(result[column])}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="toolsynthesis-container">
      <h1 className="toolsynthesis-title">{tool.title}</h1>
      <p className="toolsynthesis-description">{tool.description}</p>

      <div className="toolsynthesis-upload">
        <UploadToTool />
      </div>

      <div className="toolsynthesis-vendors">
        <h3>Select Vendor</h3>
        <div className="toolsynthesis-vendors-options">
          {["IDT", "GenScript", "Twist", "Other"].map((v) => (
            <label key={v} className="radio-group">
              <input
                type="radio"
                name="vendor"
                value={v}
                checked={vendor === v}
                onChange={() => setVendor(v)}
              />
              {v}
            </label>
          ))}
        </div>
      </div>

      <button
        className="toolsynthesis-button"
        onClick={handleSubmit}
        disabled={!selectedFiles.length || isLoading}
      >
        {isLoading ? (
          <div className="toolsynthesis-loading">
            <div className="toolsynthesis-spinner"></div>
            <span>Calculating...</span>
          </div>
        ) : (
          "Predict Synthesis Success"
        )}
      </button>

      {submitted && !isLoading && (
        <div className="toolsynthesis-results">
          <h3>Results</h3>
          {error ? (
            <div className="toolsynthesis-error">{error}</div>
          ) : results.length > 0 ? (
            renderTable()
          ) : (
            <div className="toolsynthesis-noresults">No results.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default ToolSynthesisSuccessPredictor;
