import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { setUsageCredits } from "../user.js";

export const fetchUserCredits = createAsyncThunk(
  "user/fetchUserCredits",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;
    try {
      const credits = await fetchUsageCredits(userId, token);
      dispatch(setUsageCredits(credits));
      return credits;
    } catch (error) {
      console.error("Error fetching user credits:", error);
      throw error;
    }
  }
);

export const fetchUsageCredits = async (userId, token) => {
  console.log("fetchUsageCredits called with:", { userId });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/fetch-usage-credits`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to fetch usage credits");
    }

    const result = await response.json();
    console.log("fetchUsageCredits result", result);
    return result.status === "success"
      ? result.data.credits
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in fetchUsageCredits:", error);
    throw error;
  }
};
