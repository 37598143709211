import { updateModal } from "../store/project.js";
import { registerCallback } from "./callbackRegistry.js";

export const withEditStateCheck =
  (callback, projectId) => async (dispatch, getState) => {
    const state = getState();
    const currentProject = state.project.projectList.find(
      (p) => p.project_id === projectId
    );

    if (!currentProject?.inEditState) {
      // If not in edit state, just execute the callback
      return callback();
    }

    // Register the callback and get an ID
    const callbackId = registerCallback(callback);

    // If in edit state, show the modal
    dispatch(
      updateModal({
        name: "edit_state",
        data: {
          projectId,
          callbackId,
        },
      })
    );

    // Return a promise that will be resolved when the modal action completes
    return new Promise((resolve) => {
      // The actual resolution will happen in the EditStateModal
      // after accept/reject is completed
    });
  };
