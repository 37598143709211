import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const fetchNextProjects = createAsyncThunk(
  "projects/fetchNextProjects",
  async (nextToken, { getState }) => {
    const token = getState().user.access_token;
    const userId = getState().user.user_id;
    try {
      const response = await fetchNextProjectList(userId, nextToken, token);
      return response;
    } catch (error) {
      console.error("Error fetching next projects:", error);
      throw error;
    }
  }
);

export const fetchNextProjectsReducer = {
  [fetchNextProjects.fulfilled]: (state, action) => {
    state.projectList = [...state.projectList, ...action.payload.projects];
    state.projectsNextToken = action.payload.nextToken;
  },
  [fetchNextProjects.rejected]: (state, action) => {
    state.error = action.error.message;
  },
};

export const fetchNextProjectList = async (userId, nextToken, token) => {
  console.log("fetchNextProjectList called with:", { userId, nextToken });

  const response = await fetchWithToken(
    `${API_URL}/api/fetch-next-projects`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        next_token: nextToken,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to fetch next projects");
  }

  const result = await response.json();
  return result.status === "success"
    ? {
        projects: result.data.projects,
        nextToken: result.data.next_token,
      }
    : Promise.reject(result.message);
};
