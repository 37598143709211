import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as d3 from "d3";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { FaMagic, FaSync } from "react-icons/fa";
import { MdOutlineHorizontalRule } from "react-icons/md";
import { TbCircleDashed } from "react-icons/tb";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  generateProjectInstructions,
  clearGenerateInstructionsError,
  navigateDesign,
  fetchArtifactData,
  updateModal,
  getOrderData,
  setShouldOpenGenerateInstructions,
  addFileTab,
} from "../store/project.js";
import { fetchGroups } from "../store/user.js";
import "../style/Design.css";
import Select from "react-select";
import { useAuth0 } from "@auth0/auth0-react";
import { resendVerificationEmail } from "../api/api.js";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import GenerateInstructionsPopup from "./GenerateInstructionsPopup.js";
import { updateFilesOpen } from "../api/files_api.js";

const tooltipTexts = {
  designType: "Type of design. Options include: Primer, Circular, Linear.",
  assemblyMethod:
    "Method for assembling the DNA. Options include: Traditional, Gibson Assembly, Golden Gate Assembly.",
  restrictionEnzymes:
    "Restriction enzymes used for digesting DNA. Listed 5' and 3' relative to the insert DNA.",
};

const STAR_SIZE = 12;

function Design() {
  const design = useSelector((state) => state.project.artifact?.content);
  const svgRef = useRef(null);
  const svgContainerRef = useRef(null);
  const dispatch = useDispatch();
  const isGeneratingInstructions = useSelector(
    (state) => state.project.isGeneratingInstructions
  );
  const isProjectLoading = useSelector(
    (state) => state.project.isProjectLoading
  );
  const generateInstructionsError = useSelector(
    (state) => state.project.generateInstructionsError
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    text: "",
    x: 0,
    y: 0,
  });
  const groupsObj = useSelector((state) => state.user.groups);
  const usageCredits = useSelector((state) => state.user.usageCredits);

  const projectId = useSelector((state) => state.project.currentProjectId);
  const project = useSelector((state) => {
    const projectList = state.project.projectList || [];
    return projectList.find((p) => p.project_id === projectId) || null;
  });

  const colorScale = React.useMemo(() => scaleOrdinal(schemeCategory10), []);
  const [isOldDesign, setIsOldDesign] = useState(false);

  const designUsageCredits = useSelector((state) => {
    const credits = state.project.designUsageCredits;
    try {
      return typeof credits === "string" ? JSON.parse(credits) : credits;
    } catch (e) {
      return { required_credits: 0, total_credits_used: 0 };
    }
  });

  // Calculate personal credits (total - sum of group credits)
  const personalCredits = useMemo(() => {
    const totalCredits = usageCredits || 0;
    const groupCredits = Object.values(groupsObj || {}).reduce(
      (sum, group) => sum + (group.num_credits || 0),
      0
    );
    return totalCredits - groupCredits;
  }, [usageCredits, groupsObj]);

  // Transform groups into options format for React Select
  const groupOptions = useMemo(() => {
    const options = [
      {
        value: "personal",
        label: `Personal (${personalCredits} credits)`,
        disabled: personalCredits < (designUsageCredits?.required_credits || 0),
      },
    ];

    if (groupsObj) {
      Object.values(groupsObj).forEach((group) => {
        options.push({
          value: group.group_id,
          label: `${group.name} (${group.num_credits} credits)`,
          disabled:
            group.num_credits < (designUsageCredits?.required_credits || 0),
        });
      });
    }

    return options;
  }, [groupsObj, personalCredits, designUsageCredits]);

  const { getAccessTokenSilently, user: userData } = useAuth0();

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  const shouldOpenGenerateInstructions = useSelector(
    (state) => state.project.shouldOpenGenerateInstructions
  );

  const openFiles = useSelector((state) => state.project.openFiles);
  const userId = useSelector((state) => state.user.user_id);
  const token = useSelector((state) => state.user.access_token);

  // Add new state for version info
  const [versionInfo, setVersionInfo] = useState({
    version: 1,
    isEdit: false,
  });

  // Add these state variables near the top of the Design component
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const tooltipTimeout = useRef(null);

  useEffect(() => {
    if (design) {
      setCanGoBack(design.previous_design_id !== null);
      setCanGoForward(design.next_design_id !== null);
    }
  }, [design]);

  useEffect(() => {
    if (svgRef.current && design) {
      dispatch(clearGenerateInstructionsError());
      renderDesign();
    }
    console.log(
      "Design rerender",
      design?.ready_to_clone,
      isProjectLoading,
      isOldDesign
    );
    if (design?.ready_to_clone && isProjectLoading) {
      console.log("Design is old", design);
      setIsOldDesign(true);
    } else {
      setIsOldDesign(false);
    }
  }, [design, isProjectLoading]);

  useEffect(() => {
    const tooltipEl = document.createElement("div");
    tooltipEl.className = "custom-tooltip";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.display = "none";
    tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    tooltipEl.style.color = "#dfdfdf";
    tooltipEl.style.padding = "5px";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.fontSize = "12px";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.zIndex = "1000";
    document.body.appendChild(tooltipEl);

    return () => {
      document.body.removeChild(tooltipEl);
    };
  }, []);

  useEffect(() => {
    const tooltipEl = document.querySelector(".custom-tooltip");
    if (tooltip.visible) {
      tooltipEl.style.display = "block";
      tooltipEl.style.left = `${tooltip.x}px`;
      tooltipEl.style.top = `${tooltip.y}px`;
      tooltipEl.innerHTML = tooltip.text;
    } else {
      tooltipEl.style.display = "none";
    }
  }, [tooltip]);

  useEffect(() => {
    if (isPopupOpen) {
      dispatch(fetchGroups());
    }
  }, [isPopupOpen, dispatch]);

  // Update useEffect to parse version info from s3_url
  useEffect(() => {
    if (design && project?.files) {
      const fileData = project.files[design.labkick_id];
      if (fileData?.s3_url) {
        // Extract version info from s3_url
        const match = fileData.s3_url.match(/v(\d+)(_edit)?/);
        if (match) {
          setVersionInfo({
            version: parseInt(match[1]),
            isEdit: !!match[2],
          });
          setCanGoBack(parseInt(match[1]) > 1 && !match[2]);
          setCanGoForward(!match[2]); // Enable forward if not an edit version
        }
      }
    }
  }, [design, project?.files]);

  const renderDesign = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const containerWidth = Math.min(
      svgRef.current.parentNode.offsetWidth,
      1000
    );
    const binWidth = 180;
    const padding = 20;
    const leftPadding = 80;
    const topPadding = 10;
    const partRectWidth = binWidth - 10;
    const partRectHeight = 30;
    const partSpacing = 5;
    const arrowOffset = 60;
    const arrowLineWidth = 3;
    const arrowHeadSize = 4;
    const arrowHeadOffset = 10;
    const circularPathPadding = 100;
    const borderRadius = 3;
    const backgroundColor = "#f1f1f1";

    let customColors = [
      "#f28a22",
      "#d93dea",
      "#270C56",
      "#9370DB",
      "#53A3BE",
      "#336F3D",
    ];

    // Calculate dynamic bin height
    const maxParts = Math.max(
      ...(design?.bins?.map(
        (bin) => Object.keys(bin?.part_names || {}).length
      ) || [0])
    );
    const binHeight = maxParts * (partRectHeight + partSpacing) + 40; // 40 for padding

    const totalWidth = Math.max(
      (design?.bins?.length || 0) * (binWidth + 65) + leftPadding,
      containerWidth
    );

    // Calculate the actual width of the diagram
    const diagramWidth =
      (design?.bins?.length || 0) * (binWidth + 60) + leftPadding;

    const getColor = (i, d) => {
      if (i < customColors.length) {
        return customColors[i];
      }
      return colorScale(i);
    };

    let maxBinHeight = 0;
    design?.bins?.forEach((bin) => {
      const binHeight =
        Object.keys(bin?.part_names || {}).length *
          (partRectHeight + partSpacing) +
        40;
      maxBinHeight = Math.max(maxBinHeight, binHeight);
    });

    const totalHeight =
      maxBinHeight +
      topPadding +
      (design?.design_type === "circular" ? circularPathPadding : 40);

    // Center the diagram horizontally
    const xOffset = (totalWidth - diagramWidth) / 2;

    const aspectRatio = totalWidth / totalHeight;

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
      };
    };

    svg
      .attr("viewBox", `0 0 ${totalWidth} ${totalHeight}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .style("width", "100%")
      .style("height", "100%");

    let keyboxPaddingLeft = 20;
    let keyboxPaddingTop = 0;
    // Add key box
    const keyBox = svg
      .append("g")
      .attr(
        "transform",
        `translate(${keyboxPaddingLeft}, ${keyboxPaddingTop})`
      );

    const typeIcon = keyBox.append("g").attr("transform", `translate(100, 38)`);

    console.log(design?.design_type);

    if (!design?.bins?.length) {
      svg
        .append("g")
        .attr("transform", `translate(${totalWidth / 2}, ${totalHeight / 2})`)
        .call((g) => {
          // Add logo - adjusted y position from -100 to -150
          g.append("image")
            .attr("href", "/images/K-mark.svg")
            .attr("width", 135)
            .attr("height", 135)
            .attr("x", -67.5) // Center the image (width/2)
            .attr("y", -150); // Moved up by 50px

          // Add text
          g.append("text")
            .attr("text-anchor", "middle")
            .attr("y", 20)
            .text("Please use the chat to create your design!")
            .style("font-size", "18px")
            .style("fill", "#666")
            .style("font-weight", "500");
        });
    }

    const binGroup = svg
      .append("g")
      .attr(
        "transform",
        `translate(${leftPadding + xOffset}, ${topPadding + 40})`
      );

    svg
      .append("defs")
      .append("marker")
      .attr("id", "arrowhead")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 5)
      .attr("refY", 0)
      .attr("markerWidth", arrowHeadSize)
      .attr("markerHeight", arrowHeadSize)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", "#111");

    design?.bins?.forEach((bin, index) => {
      let binHeight =
        Object.keys(bin?.part_names || {}).length *
          (partRectHeight + partSpacing) +
        40;

      let binHeightOffset = (maxBinHeight - binHeight) / 2;

      // Check if the bin is a backbone and modify the name if necessary
      let binName = bin?.name || "";
      if (design?.design_type === "primer") {
        if (bin?.is_backbone) {
          binName += " (template)";
        } else {
          binName += " (extension)";
        }
      } else if (
        bin?.is_backbone &&
        !binName.toLowerCase().includes("backbone")
      ) {
        binName += " (backbone)";
      }

      const binG = binGroup
        .append("g")
        .attr(
          "transform",
          `translate(${index * (binWidth + 60)}, ${binHeightOffset})`
        );

      const binInteractionG = binG.append("g").style("cursor", "pointer");

      binInteractionG
        .append("rect")
        .attr("width", binWidth)
        .attr("height", binHeight)
        .attr("fill", "none")
        .attr("stroke-opacity", 0.1)
        .attr("stroke", getColor(index, bin));

      binG
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", binWidth)
        .attr("height", 0)
        .attr("fill", getColor(index, bin))
        .attr("fill-opacity", 0.2);

      binG
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", binWidth)
        .attr("y2", 0)
        .attr("stroke", getColor(index, bin))
        .attr("stroke-width", 2);

      binG
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", binWidth)
        .attr("height", binHeight)
        .attr("fill", getColor(index, bin))
        .attr("opacity", 0.05);

      binG
        .append("text")
        .attr("x", binWidth / 2)
        .attr("y", -10)
        .attr("text-anchor", "middle")
        .text(`${binName}${bin?.optional ? " (optional)" : ""}`)
        .style("font-size", "12px")
        .style("font-weight", "500")
        .attr("fill", "#111")
        .style("pointer-events", "none");

      const partGroup = binG.append("g").attr("transform", `translate(5, 20)`);

      Object.entries(bin?.part_names || {}).forEach(
        ([partName, partType], partIndex) => {
          const partG = partGroup
            .append("g")
            .datum(partName)
            .attr(
              "transform",
              `translate(0, ${partIndex * (partRectHeight + partSpacing)})`
            );

          const partInteractionG = partG.append("g").style("cursor", "pointer");

          partInteractionG.on("click.open", (event, d) => {
            handleClick(d);
          });

          // Check if part is in reuse_list
          const isReused =
            design?.reuse_list && design?.reuse_list.includes(partName);

          // Add star if part is reused
          if (isReused) {
            partG
              .append("text")
              .attr("x", partRectWidth - 15) // Position in top right
              .attr("y", 15) // Small offset from top
              .attr("text-anchor", "middle")
              .text("★") // Star symbol
              .attr("fill", "#111") // Changed from #FFD700 (gold) to #111 (black)
              .attr("font-size", `${STAR_SIZE}px`)
              .style("pointer-events", "none");
          }

          // Modify the tooltip text to include reuse information
          const getPartSource = (type) => {
            switch (type) {
              case "synthesis":
              case "synthesis_to_order":
                return "Synthesis";
              case "pcr":
                return "PCR";
              case "digest":
                return "Digest";
              default:
                return type.charAt(0).toUpperCase() + type.slice(1);
            }
          };

          const handleMouseOver = (event, partName) => {
            const rect = event.target.getBoundingClientRect();

            // Find the file data for this part
            const fileData = Object.values(project.files).find(
              (file) => file.file_name === partName
            );

            const topology = fileData?.sequence_topology || "unknown";
            const tooltipText = `
            ${partName}<br>
            Source: ${getPartSource(partType)}
            ${partType === "synthesis_to_order" ? "<br>To Order" : ""}
            ${
              topology
                ? `<br>Topology: ${
                    topology.charAt(0).toUpperCase() + topology.slice(1)
                  }`
                : ""
            }
            ${isReused ? "<br>Reused Part" : ""}  
          `;

            setTooltip({
              visible: true,
              text: tooltipText,
              x: rect.left + window.scrollX,
              y: rect.top + window.scrollY - 30,
            });
            d3.select(event.target).attr("fill", "#e2e2e2");
            d3.select(event.target).attr("stroke-opacity", 1);
          };

          const handleMouseOut = (event) => {
            setTooltip({ visible: false, text: "", x: 0, y: 0 });
            d3.select(event.target).attr("stroke-opacity", 0.5);
            d3.select(event.target).attr(
              "fill",
              partType === "synthesis_to_order" ? "#e6f3ff" : backgroundColor
            );
          };

          const rect = partInteractionG
            .append("rect")
            .attr("width", partRectWidth)
            .attr("height", partRectHeight)
            .on("mouseover", (event) => handleMouseOver(event, partName))
            .on("mouseout", handleMouseOut)
            .style("pointer-events", "all")
            .attr(
              "fill",
              partType === "synthesis_to_order" ? "#e6f3ff" : backgroundColor
            )
            .attr("stroke", getColor(index, bin))
            .attr("stroke-opacity", 0.5)
            .attr("rx", borderRadius)
            .attr("ry", borderRadius);

          // Add SVG icon
          const iconWidth = 30;
          const iconHeight = 30;
          const iconX = 5;
          const iconY = (partRectHeight - iconHeight) / 2;

          let iconPath;
          switch (partType) {
            case "pcr":
              iconPath = "/images/pcr_rep.svg";
              break;
            case "digest":
              iconPath = "/images/digest_rep.svg";
              break;
            case "synthesis":
            case "synthesis_to_order":
            default:
              iconPath = "/images/synth_rep.svg";
              break;
          }

          partG
            .append("image")
            .attr("x", iconX)
            .attr("y", iconY)
            .attr("width", iconWidth)
            .attr("height", iconHeight)
            .attr("href", iconPath);

          partG
            .append("text")
            .attr("x", iconWidth + 10)
            .attr("y", partRectHeight / 2)
            .attr("dominant-baseline", "middle")
            .text(partName)
            .attr("fill", "#111")
            .style("font-size", "12px")
            .style("pointer-events", "none")
            .each(function () {
              const self = d3.select(this);
              let textLength = self.node().getComputedTextLength();
              let text = self.text();
              while (
                textLength > partRectWidth - iconWidth - 15 &&
                text.length > 0
              ) {
                text = text.slice(0, -1);
                self.text(text + "...");
                textLength = self.node().getComputedTextLength();
              }
            });
        }
      );

      if (index < design?.bins?.length - 1) {
        const arrowStart = (index + 1) * (binWidth + 60) - arrowOffset;
        const arrowEnd = (index + 1) * (binWidth + 60) - arrowHeadOffset;

        binGroup
          .append("line")
          .attr("x1", arrowStart)
          .attr("y1", binHeight / 2 + binHeightOffset)
          .attr("x2", arrowEnd)
          .attr("y2", binHeight / 2 + binHeightOffset)
          .attr("stroke", "#111")
          .attr("stroke-width", arrowLineWidth)
          .attr("marker-end", "url(#arrowhead)");
      }
    });
    if (design?.design_type === "circular" && design?.bins?.length > 0) {
      const lastBinX = (design?.bins?.length - 1) * (binWidth + 60);
      const elbowBottom = binHeight + circularPathPadding / 2;
      const elbowLeft = -arrowOffset;
      const curveRadius = 30; // Adjust this value to change the curve sharpness

      const pathData = `M${lastBinX + binWidth},${binHeight / 2}
                        L${lastBinX + binWidth + arrowOffset - curveRadius},${
        binHeight / 2
      }
                        Q${lastBinX + binWidth + arrowOffset},${
        binHeight / 2
      } ${lastBinX + binWidth + arrowOffset},${binHeight / 2 + curveRadius}
                        L${lastBinX + binWidth + arrowOffset},${
        elbowBottom - curveRadius
      }
                        Q${lastBinX + binWidth + arrowOffset},${elbowBottom} ${
        lastBinX + binWidth + arrowOffset - curveRadius
      },${elbowBottom}
                        L${elbowLeft + curveRadius},${elbowBottom}
                        Q${elbowLeft},${elbowBottom} ${elbowLeft},${
        elbowBottom - curveRadius
      }
                        L${elbowLeft},${binHeight / 2 + curveRadius}
                        Q${elbowLeft},${binHeight / 2} ${
        elbowLeft + curveRadius
      },${binHeight / 2}
                        L${arrowHeadOffset - 20},${binHeight / 2}`;

      binGroup
        .append("path")
        .attr("d", pathData)
        .attr("fill", "none")
        .attr("stroke", "#111")
        .attr("stroke-width", arrowLineWidth)
        .attr("marker-end", "url(#arrowhead)");
    }

    return (
      <div
        className="design-content"
        style={{ overflowY: "auto", height: "100%", paddingTop: "20px" }}
      >
        <div className="design-specification">
          <div className="design-specification-box">
            {renderNavigationButtons()}
            <p>
              <span
                className="label tooltip-trigger"
                onMouseEnter={(e) =>
                  setTooltip({
                    visible: true,
                    text: tooltipTexts.designType,
                    x: e.currentTarget.getBoundingClientRect().left,
                    y: e.currentTarget.getBoundingClientRect().bottom + 5,
                  })
                }
                onMouseLeave={() =>
                  setTooltip({ visible: false, text: "", x: 0, y: 0 })
                }
              >
                Design Type
              </span>
              <span>
                {design?.design_type
                  ? design?.design_type.charAt(0).toUpperCase() +
                    design?.design_type.slice(1)
                  : "Unknown"}
              </span>
            </p>
            <p>
              <span
                className="label tooltip-trigger"
                onMouseEnter={(e) =>
                  setTooltip({
                    visible: true,
                    text: tooltipTexts.assemblyMethod,
                    x: e.currentTarget.getBoundingClientRect().left,
                    y: e.currentTarget.getBoundingClientRect().bottom + 5,
                  })
                }
                onMouseLeave={() =>
                  setTooltip({ visible: false, text: "", x: 0, y: 0 })
                }
              >
                Assembly Method
              </span>{" "}
              <span>
                {design?.assembly_method_type
                  ? design?.assembly_method_type.toLowerCase() === "golden_gate"
                    ? "Golden Gate"
                    : design?.assembly_method_type.toLowerCase() === "gibson"
                    ? "Gibson"
                    : design?.assembly_method_type.toLowerCase() ===
                      "traditional"
                    ? "Traditional"
                    : design?.assembly_method_type.charAt(0).toUpperCase() +
                      design?.assembly_method_type.slice(1)
                  : "Unknown"}
              </span>
            </p>
            <p>
              <span
                className="label tooltip-trigger"
                onMouseEnter={(e) =>
                  setTooltip({
                    visible: true,
                    text: tooltipTexts.restrictionEnzymes,
                    x: e.currentTarget.getBoundingClientRect().left,
                    y: e.currentTarget.getBoundingClientRect().bottom + 5,
                  })
                }
                onMouseLeave={() =>
                  setTooltip({ visible: false, text: "", x: 0, y: 0 })
                }
              >
                Restriction Enzymes
              </span>{" "}
              <span>
                {design?.restriction_enzymes
                  ? design?.restriction_enzymes.join(", ")
                  : "None"}
              </span>
            </p>
          </div>
        </div>
        <div
          className="design-content-container"
          ref={svgContainerRef}
          style={{ width: "100%", height: "500px" }}
        >
          <svg ref={svgRef}></svg>
        </div>

        {generateInstructionsError && (
          <div className="design-error-message">
            {generateInstructionsError}
          </div>
        )}

        {isPopupOpen && (
          <GenerateInstructionsPopup onClose={() => setIsPopupOpen(false)} />
        )}
      </div>
    );
  };

  const handleClick = (d) => {
    // if tooltip, hide
    setTooltip({ visible: false, text: "", x: 0, y: 0 });

    // find the key of the object in files where files[key].file_name is equal to d
    const key = Object.keys(project.files).find(
      (key) => project.files[key].file_name === d
    );

    if (key) {
      const fileData = project.files[key];

      // Add file tab first
      dispatch(
        addFileTab({
          fileId: key,
          fileName: fileData.file_name,
          fileType: fileData.file_type,
          content: null,
          setActive: true,
        })
      );

      // Update files_open in backend
      if (projectId && userId && token) {
        const updatedFileIds = [...Object.keys(openFiles), key];
        updateFilesOpen(projectId, updatedFileIds, userId, token).catch(
          (error) => console.error("Failed to update files open:", error)
        );
      }

      // Then fetch the artifact data
      dispatch(
        fetchArtifactData({
          fileId: key,
          fileType: fileData.file_type,
        })
      );
    }
  };

  const handleGenerateInstructions = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch("https://login.labkick.ai/userinfo", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = await response.json();

      if (!userData.email_verified) {
        setIsEmailVerificationPopupOpen(true);
        return;
      }

      // Get the design usage credits before showing the popup

      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error checking email verification:", error);
      dispatch(clearGenerateInstructionsError());
      dispatch(
        generateProjectInstructions({
          projectId,
          error: "Error checking email verification status",
        })
      );
    }
  };

  const handleOrderDNA = async () => {
    try {
      const result = await dispatch(getOrderData(projectId)).unwrap();
      console.log("Order data:", result);

      dispatch(
        updateModal({
          name: "order_dna",
          data: {
            name: design?.name,
            total_constructs: result?.total_constructs || 0,
            synthesis_cost: result?.synthesis_cost || 0,
            labor_cost: result?.labor_cost || 0,
            protocol_cost: result?.protocol_cost || 0,
            total_price: result?.total_price || 0,
            sequencing_cost_one: result?.sequencing_cost_one || 0,
            sequencing_cost_two: result?.sequencing_cost_two || 0,
            sequencing_cost_three: result?.sequencing_cost_three || 0,
            total_bp: result?.total_bp || 0,
            total_synthesis_bp: result?.total_synthesis_bp || 0,
            lead_time: "5 business days once constructs are received",
            beta_message:
              "Protocol execution is in a closed beta. Price per construct decreases as more constructs are ordered. To join the beta, and have LabKick assemble your DNA for you, please click the 'Submit Request' button below and our team will reach out shortly",
          },
        })
      );
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  // Add this utility function
  const showNavigationErrorTooltip = (msg) => {
    setTooltipMessage(msg);
    setShowTooltip(true);
    if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    tooltipTimeout.current = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  };

  // Update the navigation handlers
  const handleForwardClick = () => {
    if (!versionInfo.isEdit) {
      dispatch(
        navigateDesign({
          projectId: projectId,
          fileId: design.labkick_id,
          direction: "forward",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message.includes("No later")) {
            showNavigationErrorTooltip("No later version");
          } else {
            showNavigationErrorTooltip("Unable to load version");
          }
        });
    }
  };

  const handleBackClick = () => {
    if (!versionInfo.isEdit && versionInfo.version > 1) {
      dispatch(
        navigateDesign({
          projectId: projectId,
          fileId: design.labkick_id,
          direction: "back",
        })
      )
        .unwrap()
        .catch((err) => {
          if (err.message.includes("No previous")) {
            showNavigationErrorTooltip("No earlier version");
          } else {
            showNavigationErrorTooltip("Unable to load version");
          }
        });
    }
  };

  // Update the renderNavigationButtons function
  const renderNavigationButtons = () => (
    <div className="header">
      <button
        className={`nav-button ${
          !canGoBack || versionInfo.isEdit ? "disabled" : ""
        }`}
        onClick={handleBackClick}
        data-tooltip="Previous Design"
        disabled={!canGoBack || versionInfo.isEdit}
      >
        <FaArrowLeft />
      </button>
      Design History
      <button
        className={`nav-button ${
          !canGoForward || versionInfo.isEdit ? "disabled" : ""
        }`}
        onClick={handleForwardClick}
        data-tooltip="Next Design"
        disabled={!canGoForward || versionInfo.isEdit}
      >
        <FaArrowRight />
      </button>
      {showTooltip && <div className="nav-tooltip">{tooltipMessage}</div>}
    </div>
  );

  // Add effect to handle the automatic opening of the generate instructions popup
  useEffect(() => {
    if (shouldOpenGenerateInstructions) {
      handleGenerateInstructions();
      // Reset the flag after handling it
      dispatch(setShouldOpenGenerateInstructions(false));
    }
  }, [shouldOpenGenerateInstructions]);

  // Add cleanup effect
  useEffect(() => {
    return () => {
      if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
    };
  }, []);

  // If project is loading, show loading state
  if (isProjectLoading) {
    return (
      <div className="design-content">
        <div
          className="design-content-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          Loading design...
        </div>
      </div>
    );
  }

  // If no design and not loading, show empty state
  if (!design && !isProjectLoading) {
    return (
      <div className="design-content">
        <div
          className="design-content-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <p>No design available.</p>
            <p>Please use the chat to create your design!</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="design-content">
      <div className="design-specification">
        <div className="design-specification-box">
          {renderNavigationButtons()}
          <p>
            <span
              className="label tooltip-trigger"
              onMouseEnter={(e) =>
                setTooltip({
                  visible: true,
                  text: tooltipTexts.designType,
                  x: e.currentTarget.getBoundingClientRect().left,
                  y: e.currentTarget.getBoundingClientRect().bottom + 5,
                })
              }
              onMouseLeave={() =>
                setTooltip({ visible: false, text: "", x: 0, y: 0 })
              }
            >
              Design Type
            </span>
            <span>
              {design?.design_type
                ? design?.design_type.charAt(0).toUpperCase() +
                  design?.design_type.slice(1)
                : "Unknown"}
            </span>
          </p>
          <p>
            <span
              className="label tooltip-trigger"
              onMouseEnter={(e) =>
                setTooltip({
                  visible: true,
                  text: tooltipTexts.assemblyMethod,
                  x: e.currentTarget.getBoundingClientRect().left,
                  y: e.currentTarget.getBoundingClientRect().bottom + 5,
                })
              }
              onMouseLeave={() =>
                setTooltip({ visible: false, text: "", x: 0, y: 0 })
              }
            >
              Assembly Method
            </span>{" "}
            <span>
              {design?.assembly_method_type
                ? design?.assembly_method_type.toLowerCase() === "golden_gate"
                  ? "Golden Gate"
                  : design?.assembly_method_type.toLowerCase() === "gibson"
                  ? "Gibson"
                  : design?.assembly_method_type.toLowerCase() === "traditional"
                  ? "Traditional"
                  : design?.assembly_method_type.charAt(0).toUpperCase() +
                    design?.assembly_method_type.slice(1)
                : "Unknown"}
            </span>
          </p>
          <p>
            <span
              className="label tooltip-trigger"
              onMouseEnter={(e) =>
                setTooltip({
                  visible: true,
                  text: tooltipTexts.restrictionEnzymes,
                  x: e.currentTarget.getBoundingClientRect().left,
                  y: e.currentTarget.getBoundingClientRect().bottom + 5,
                })
              }
              onMouseLeave={() =>
                setTooltip({ visible: false, text: "", x: 0, y: 0 })
              }
            >
              Restriction Enzymes
            </span>{" "}
            <span>
              {design?.restriction_enzymes
                ? design?.restriction_enzymes.join(", ")
                : "None"}
            </span>
          </p>
        </div>
      </div>
      <div className="design-content-container" ref={svgContainerRef}>
        <svg ref={svgRef}></svg>
      </div>
      {design?.ready_to_clone &&
        design?.optimized &&
        !isProjectLoading &&
        !isOldDesign && (
          <div
            className={`button-container ${
              project?.files &&
              Object.values(project.files).some(
                (file) => file.file_type === "Output"
              )
                ? "dual-buttons"
                : "single-button"
            }`}
          >
            <button
              className={`generate-instructions-button ${
                project?.files &&
                Object.values(project.files).some(
                  (file) => file.file_type === "Output"
                )
                  ? "regenerate"
                  : ""
              }`}
              onClick={handleGenerateInstructions}
              disabled={isGeneratingInstructions}
            >
              {isGeneratingInstructions ? (
                <FaSync size={24} className="spinning" />
              ) : project?.files &&
                Object.values(project.files).some(
                  (file) => file.file_type === "Output"
                ) ? (
                <FaSync size={24} />
              ) : (
                <FaMagic size={24} />
              )}
              <span>
                {isGeneratingInstructions
                  ? "Planning..."
                  : project?.files &&
                    Object.values(project.files).some(
                      (file) => file.file_type === "Output"
                    )
                  ? "Revise Work Plan"
                  : "Create Work Plan"}
              </span>
            </button>

            {project?.files &&
              Object.values(project.files).some(
                (file) =>
                  file.file_type === "Output" &&
                  file.file_name.includes("Synthesis")
              ) && (
                <button
                  className="generate-instructions-button"
                  onClick={handleOrderDNA}
                >
                  <FaMagic size={24} />
                  <span>Order DNA</span>
                </button>
              )}
          </div>
        )}

      {isPopupOpen && (
        <GenerateInstructionsPopup onClose={() => setIsPopupOpen(false)} />
      )}
    </div>
  );
}

export default Design;
