import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

import { fetchUserCredits } from "../user.js";

export const generateProjectInstructions = createAsyncThunk(
  "projects/generateProjectInstructions",
  async ({ projectId, override, groupId }, { dispatch, getState }) => {
    const token = getState().user.access_token;

    try {
      // Fire the request and don't wait for or process the response
      generateInstructions(projectId, override, groupId, token);

      // Refresh usage credits
      dispatch(fetchUserCredits());

      return null;
    } catch (error) {
      console.error("Error in generateProjectInstructions:", error);
      // Don't throw or handle errors - the notification system will handle it
      return null;
    }
  }
);

export const generateProjectInstructionsReducer = {
  [generateProjectInstructions.pending]: (state) => {
    state.isGeneratingInstructions = true;
    state.generateInstructionsError = null;
  },
  [generateProjectInstructions.fulfilled]: (state, action) => {
    state.isGeneratingInstructions = false;
    state.instructions = action.payload;
    state.generateInstructionsError = null;
  },
  [generateProjectInstructions.rejected]: (state, action) => {
    state.isGeneratingInstructions = false;
    state.generateInstructionsError = action.payload;
  },
};

export const generateInstructions = async (
  projectId,
  override,
  groupId,
  token
) => {
  console.log("generateInstructions called with:", {
    projectId,
    override,
    groupId,
  });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/generate-instructions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          project_id: projectId,
          override: override,
          group_id: groupId,
        }),
      },
      token
    );

    return response;
  } catch (error) {
    console.error("Error in generateInstructions:", error);
    throw error;
  }
};
