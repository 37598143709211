import React, { useState, useEffect, useRef } from "react";
import {
  updateModal,
  closeModal,
  manageCloningPlanAction,
} from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import { checkFilenameUnique } from "../api/files_api.js";
import "../style/Modal.css";

function CloningPlanModal() {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.project);
  const currentProject = useSelector((state) => state.project.currentProject);
  const user = useSelector((state) => state.user.data);
  const token = useSelector((state) => state.user.access_token);

  // modal.data should contain { mode: 'create' | 'rename' | 'delete', planId?: string, planName?: string }
  const { mode, planId, planName } = modal.data || {};

  const titles = {
    create: "Create Cloning Plan",
    rename: "Rename Cloning Plan",
    delete: "Delete Cloning Plan",
  };

  useEffect(() => {
    if (mode === "rename" && planName) {
      setInputValue(planName);
    } else {
      setInputValue("");
    }
  }, [mode, planName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(updateModal({ name: "", data: null }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dispatch(closeModal());
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    try {
      if (mode === "delete") {
        await dispatch(
          manageCloningPlanAction({
            projectId: currentProject.project_id,
            mode: "delete",
            planId,
            planName,
          })
        ).unwrap();
        dispatch(closeModal());
        return;
      }

      if (!inputValue.trim()) {
        setError("Plan name cannot be empty");
        return;
      }

      // Extract plan name before first period and ensure .plan extension
      const planNameToUse = `${inputValue.split(".")[0].trim()}.plan`;

      if (!planNameToUse) {
        setError("Plan name cannot be empty");
        return;
      }

      // Check if filename is unique
      const isUnique = await checkFilenameUnique(
        user.user_id,
        planNameToUse,
        token
      );

      if (
        !isUnique &&
        (mode === "create" || (mode === "rename" && planNameToUse !== planName))
      ) {
        setError("A cloning plan with this name already exists");
        return;
      }

      await dispatch(
        manageCloningPlanAction({
          projectId: currentProject.project_id,
          mode,
          planName: planNameToUse,
          planId,
        })
      ).unwrap();

      dispatch(closeModal());
    } catch (error) {
      console.error("Error managing cloning plan:", error);
      setError(
        error.message || "An error occurred while managing the cloning plan"
      );
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.stopPropagation()}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="modal-content" ref={modalRef}>
        <h2 className="modal-title" id="modal-title">
          {titles[mode]}
        </h2>
        {error && (
          <div className="modal-error-message" role="alert" aria-live="polite">
            {error}
          </div>
        )}
        {mode === "delete" ? (
          <div className="modal-delete-confirmation">
            <p>
              Are you sure you want to delete the cloning plan "{planName}"?
            </p>
            <p>This action cannot be undone.</p>
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            aria-label="Cloning plan form"
          >
            <input
              id="plan-name-input"
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={
                mode === "create" ? "Enter plan name" : "Enter new name"
              }
              className="modal-input"
              aria-invalid={!!error}
              aria-describedby={error ? "name-error" : undefined}
            />
          </form>
        )}
        <div className="modal-buttons">
          <button
            type="button"
            onClick={() => dispatch(closeModal())}
            className="modal-cancel-button"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className={`modal-submit-button ${
              mode === "delete" ? "delete" : ""
            }`}
          >
            {mode === "delete" ? "Delete" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CloningPlanModal;
