import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  generateProjectInstructions,
  clearGenerateInstructionsError,
} from "../store/project.js";
import { resendVerificationEmail } from "../api/auth_api.js";
import { setPopupMessage } from "../store/user.js";
import { useAuth0 } from "@auth0/auth0-react";

function GenerateInstructionsPopup({ onClose }) {
  const dispatch = useDispatch();
  const design = useSelector((state) => state.project.artifact.content);
  const projectId = useSelector((state) => state.project.currentProjectId);
  const project = useSelector((state) =>
    state.project.projectList.find((p) => p.project_id === projectId)
  );
  const groupsObj = useSelector((state) => state.user.groups);
  const usageCredits = useSelector((state) => state.user.usageCredits);
  const [selectedGroupId, setSelectedGroupId] = useState("personal");
  const designUsageCredits = useSelector((state) => {
    const credits = state.project.designUsageCredits;
    try {
      return typeof credits === "string" ? JSON.parse(credits) : credits;
    } catch (e) {
      return { required_credits: 0, total_credits_used: 0 };
    }
  });
  const [tooltip, setTooltip] = useState({
    visible: false,
    text: "",
    x: 0,
    y: 0,
  });
  const { getAccessTokenSilently, user: userData } = useAuth0();
  const [isEmailVerificationPopupOpen, setIsEmailVerificationPopupOpen] =
    useState(false);

  const user = useSelector((state) => state.user);
  // Calculate personal credits
  const personalCredits = useMemo(() => {
    const totalCredits = usageCredits || 0;
    const groupCredits = Object.values(groupsObj || {}).reduce(
      (sum, group) => sum + (group.num_credits || 0),
      0
    );
    return totalCredits - groupCredits;
  }, [usageCredits, groupsObj]);

  // Transform groups into options format for React Select
  const groupOptions = useMemo(() => {
    const options = [
      { value: "personal", label: `Personal (${personalCredits} credits)` },
    ];

    if (groupsObj) {
      Object.values(groupsObj).forEach((group) => {
        options.push({
          value: group.group_id,
          label: `${group.name} (${group.num_credits} credits)`,
        });
      });
    }

    return options;
  }, [groupsObj, personalCredits]);

  const calculateNumConstructs = () => {
    if (!design?.bins || !project?.files) return 0;

    return design.bins.reduce((total, bin) => {
      const binTotal = Object.entries(bin.part_names).reduce(
        (binSum, [partName]) => {
          const fileId = Object.keys(project.files).find(
            (id) => project.files[id].file_name === partName
          );

          if (fileId && project.files[fileId]) {
            return (
              binSum + parseInt(project.files[fileId].number_of_sequences || 1)
            );
          }
          return binSum + 1;
        },
        0
      );

      const binTotalWithOptional = bin.optional ? binTotal + 1 : binTotal;
      return total === 0 ? binTotalWithOptional : total * binTotalWithOptional;
    }, 0);
  };

  const handleConfirmGenerateInstructions = () => {
    dispatch(clearGenerateInstructionsError());

    // Fire and forget - don't await the response
    dispatch(
      generateProjectInstructions({
        projectId,
        override: "false",
        groupId: selectedGroupId === "personal" ? null : selectedGroupId,
      })
    );

    // Add temporary popup message with timeout
    dispatch(
      setPopupMessage(
        "Instructions generation started! You will receive an email and notification when it's complete."
      )
    );
    setTimeout(() => {
      dispatch(setPopupMessage(null));
    }, 5000);

    // Close the popup immediately
    onClose();
  };

  const handleResendVerification = async () => {
    try {
      const token = await getAccessTokenSilently();
      await resendVerificationEmail(userData, token);
      setIsEmailVerificationPopupOpen(false);
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  // Add useEffect for tooltip DOM element
  useEffect(() => {
    const tooltipEl = document.createElement("div");
    tooltipEl.className = "custom-tooltip";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.display = "none";
    tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    tooltipEl.style.color = "#dfdfdf";
    tooltipEl.style.padding = "5px";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.fontSize = "12px";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.zIndex = "1000";
    document.body.appendChild(tooltipEl);

    return () => {
      document.body.removeChild(tooltipEl);
    };
  }, []);

  // Add useEffect for tooltip visibility
  useEffect(() => {
    const tooltipEl = document.querySelector(".custom-tooltip");
    if (tooltip.visible) {
      tooltipEl.style.display = "block";
      tooltipEl.style.left = `${tooltip.x}px`;
      tooltipEl.style.top = `${tooltip.y}px`;
      tooltipEl.innerHTML = tooltip.text;
    } else {
      tooltipEl.style.display = "none";
    }
  }, [tooltip]);

  // Add keyboard handler for escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  return (
    <div
      className="artifact-confirmation-popup-overlay"
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-title"
    >
      {isEmailVerificationPopupOpen ? (
        <div
          className="artifact-confirmation-popup-dialog"
          role="alertdialog"
          aria-labelledby="verification-title"
          aria-describedby="verification-desc"
        >
          <h3 id="verification-title">Email Not Verified</h3>
          <p id="verification-desc">
            Please verify your email before generating instructions. Check your
            junk mailbox if you don't see a verification email.
          </p>
          <div className="artifact-confirmation-popup-buttons">
            <button
              onClick={handleResendVerification}
              className="artifact-confirm-button"
              aria-label="Resend verification email"
            >
              Resend Verification Email
            </button>
            <button
              onClick={() => setIsEmailVerificationPopupOpen(false)}
              className="artifact-cancel-button"
              aria-label="Close verification popup"
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div className="artifact-confirmation-popup-dialog">
          <div className="artifact-confirmation-popup-header">
            <h2 id="popup-title">
              {project?.files &&
              Object.values(project.files).some(
                (file) => file.file_type === "Output"
              )
                ? "Regenerate Instructions"
                : "Generate Instructions"}
            </h2>
            <button
              className="artifact-confirmation-popup-close"
              onClick={onClose}
              aria-label="Close popup"
            >
              ×
            </button>
          </div>

          <div className="artifact-confirmation-popup-content">
            <div className="artifact-confirmation-popup-form" role="form">
              <div className="artifact-confirmation-credits-table">
                <h3 id="credits-table-title">Credit Usage Guide</h3>
                <p className="credits-explanation" id="credits-explanation">
                  You use credits on a per-project basis. The amount of credits
                  that you use depends on the number of constructs that you are
                  generating, and whether you are doing a DNA assembly or just
                  primer design.
                </p>
                <table aria-labelledby="credits-table-title">
                  <thead>
                    <tr>
                      <th scope="col"># Constructs</th>
                      <th scope="col">DNA Assembly</th>
                      <th scope="col">Primer Design</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1-8</td>
                      <td>10</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td>9-24</td>
                      <td>25</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td>25-96</td>
                      <td>50</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td>97-384</td>
                      <td>100</td>
                      <td>4</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                className="artifact-confirmation-popup-summary"
                role="region"
                aria-label="Project summary"
              >
                <div className="artifact-confirmation-popup-summary-item">
                  <span id="constructs-label">Number of constructs</span>
                  <span
                    className="artifact-confirmation-popup-value-light"
                    aria-labelledby="constructs-label"
                  >
                    {calculateNumConstructs()}
                  </span>
                </div>
                <div className="artifact-confirmation-popup-summary-item">
                  <span
                    className={`design-tooltip-text ${
                      designUsageCredits?.total_credits_used > 0 &&
                      designUsageCredits?.required_credits > 0
                        ? "design-has-tooltip"
                        : ""
                    }`}
                    onMouseEnter={(e) => {
                      if (
                        designUsageCredits?.total_credits_used > 0 &&
                        designUsageCredits?.required_credits > 0
                      ) {
                        const rect = e.currentTarget.getBoundingClientRect();
                        setTooltip({
                          visible: true,
                          text: `${designUsageCredits.total_credits_used} credits have already been used for this project. You are using ${designUsageCredits.required_credits} incremental credits as we charge for the maximum amount of total credits that could be used for a single instruction call in a project`,
                          x: rect.left + window.scrollX + 150,
                          y: rect.top + window.scrollY - 30,
                        });
                      }
                    }}
                    onMouseLeave={() =>
                      setTooltip({ visible: false, text: "", x: 0, y: 0 })
                    }
                    tabIndex="0"
                    role="button"
                    aria-label="Required credits information"
                    onKeyPress={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        const rect = e.currentTarget.getBoundingClientRect();
                        setTooltip({
                          visible: true,
                          text: `${designUsageCredits.total_credits_used} credits have already been used for this project. You are using ${designUsageCredits.required_credits} incremental credits as we charge for the maximum amount of total credits that could be used for a single instruction call in a project`,
                          x: rect.left + window.scrollX + 150,
                          y: rect.top + window.scrollY - 30,
                        });
                      }
                    }}
                  >
                    Required Credits
                  </span>
                  <span
                    className="artifact-confirmation-popup-value-bold"
                    aria-label="Required credits amount"
                  >
                    {designUsageCredits?.required_credits !== undefined
                      ? designUsageCredits.required_credits
                      : ""}
                  </span>
                </div>
              </div>

              <div
                className={`artifact-confirmation-group-section ${
                  Object.keys(groupsObj || {}).length > 0 &&
                  designUsageCredits?.required_credits > 0
                    ? ""
                    : "hidden"
                }`}
              >
                <label
                  id="group-select-label"
                  className="group-selection-label"
                >
                  Please choose which group you would like to use a credit from
                </label>
                <Select
                  value={groupOptions.find(
                    (option) => option.value === selectedGroupId
                  )}
                  onChange={(option) => setSelectedGroupId(option.value)}
                  options={groupOptions}
                  className="protocol-dropdown"
                  classNamePrefix="protocol-dropdown"
                  isSearchable={false}
                  aria-labelledby="group-select-label"
                />
              </div>
            </div>
          </div>

          <div className="artifact-confirmation-popup-buttons">
            <button
              onClick={onClose}
              className="artifact-cancel-button"
              aria-label="Cancel operation"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmGenerateInstructions}
              className="artifact-confirm-button"
              aria-label={
                project?.files &&
                Object.values(project.files).some(
                  (file) => file.file_type === "Output"
                )
                  ? "Confirm regenerate instructions"
                  : "Confirm generate instructions"
              }
            >
              {project?.files &&
              Object.values(project.files).some(
                (file) => file.file_type === "Output"
              )
                ? "Yes, Regenerate"
                : "Yes, Generate"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default GenerateInstructionsPopup;
