import { fetchWithToken, API_URL } from "./api.js";

import { cleanProtocolMarkdown } from "../helpers.js";

export const fetchProtocols = async (protocolFiles, token) => {
  try {
    // Fetch each protocol file
    const protocols = await Promise.all(
      protocolFiles.map(async ({ fileId }) => {
        const protocolResponse = await fetchArtifact(
          fileId,
          "None",
          "None",
          [],
          false,
          null,
          token
        );
        return protocolResponse;
      })
    );

    // Process and combine protocols
    const combinedContent = protocols
      .map((protocol) => cleanProtocolMarkdown(protocol.content))
      .join("\n\n---\n\n");

    return combinedContent;
  } catch (error) {
    console.error("Error exporting protocols:", error);
    throw error;
  }
};

export const downloadFiles = async (id, type, token) => {
  try {
    console.log("downloadFiles called with:", { id, type });
    const response = await fetchWithToken(
      `${API_URL}/api/download-project`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id, type: type }),
      },
      token
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    console.log("Response:", response);
    console.log("Headers:", [...response.headers.entries()]);

    const suggestedFilename = response.headers.get("X-Suggested-Filename");
    console.log("Suggested filename:", suggestedFilename);

    const contentType = response.headers.get("Content-Type");
    console.log("Content-Type:", contentType);

    if (type === "file") {
      let fileData;
      if (contentType && contentType.includes("application/json")) {
        fileData = await response.json();
        console.log("JSON file data:", fileData);
      } else {
        fileData = await response.text();
        console.log("Text file data:", fileData);
      }

      const blob = new Blob([fileData], {
        type: contentType || "application/octet-stream",
      });
      console.log("File Blob:", blob);

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = suggestedFilename || "downloaded_file";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      const blob = await response.blob();
      console.log("Zip Blob:", blob);

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = suggestedFilename || "project.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }

    console.log("Download initiated");
    return "Download initiated";
  } catch (error) {
    console.error("Error downloading project files:", error);
    throw error;
  }
};

export const updateFileProperties = async (fileId, updates, userId, token) => {
  console.log("updateFileProperties called with:", { fileId, updates, userId });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/update-file-properties`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ file_id: fileId, updates, user_id: userId }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to update file properties");
    }

    const result = await response.json();
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in updateFileProperties:", error);
    throw error;
  }
};

export const checkFilenameUnique = async (userId, filename, token) => {
  console.log("checkFilenameUnique called with:", { userId, filename });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/check-filename-unique`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          filename: filename,
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to check filename uniqueness");
    }

    const result = await response.json();
    return result.status === "success"
      ? result.is_unique
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in checkFilenameUnique:", error);
    throw error;
  }
};

export const updateFilesOpen = async (projectId, filesOpen, userId, token) => {
  console.log("updateFilesOpen called with:", { projectId, filesOpen, userId });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/update-files-open`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          project_id: projectId,
          user_id: userId,
          files_open: filesOpen,
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to update files open");
    }

    const result = await response.json();
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in updateFilesOpen:", error);
    throw error;
  }
};
