import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { setIsLoadingFiles, setLoadingMessage } from "../project.js";
import { updateUserFile } from "../user.js";

export const uploadFile = createAsyncThunk(
  "projects/uploadFile",
  async (
    { files, projectId, chatId, userId, annotate, uploadType },
    { dispatch, getState }
  ) => {
    dispatch(setIsLoadingFiles(true));
    try {
      const token = getState().user.access_token;
      const projectIdToUse = projectId === "no_project" ? null : projectId;

      // Initialize mergedFiles with existing user files
      let mergedFiles = { ...getState().user.files } || {};
      let projectFiles = {};
      let lastResponse = null;

      // Upload files one by one
      for (const fileData of files) {
        dispatch(setLoadingMessage(`Uploading ${fileData.file.name}`));

        // Add special handling for data files
        if (uploadType === "data") {
          // Validate CSV file
          if (!fileData.file.name.toLowerCase().endsWith(".csv")) {
            throw new Error("Only CSV files are allowed for data upload");
          }
        }

        const response = await uploadFiles(
          [fileData],
          projectIdToUse,
          chatId,
          userId,
          annotate,
          token,
          uploadType
        );
        lastResponse = response;

        console.log("RESPONSE", response);
        if (response?.uploaded_files) {
          // Merge the new uploaded files with existing files
          mergedFiles = {
            ...mergedFiles,
            ...response.uploaded_files,
          };
          // Add to project files
          projectFiles = {
            ...projectFiles,
            ...response.uploaded_files,
          };
        }
      }

      console.log("mergedFiles", mergedFiles);

      // Update user files
      dispatch(
        updateUserFile({
          replaceAll: true,
          updatedFile: mergedFiles,
        })
      );

      return {
        uploadedFiles: mergedFiles,
        project_id: projectIdToUse,
        files: projectFiles, // This will be used to update the project
      };
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    } finally {
      dispatch(setIsLoadingFiles(false));
      dispatch(setLoadingMessage(""));
    }
  }
);

export const uploadFileReducer = {
  [uploadFile.fulfilled]: (state, action) => {
    const { project_id, files } = action.payload;
    if (project_id && files) {
      const updatedProjectList = state.projectList.map((project) => {
        if (project.project_id === project_id) {
          return {
            ...project,
            files: {
              ...(project.files || {}), // Preserve existing files, default to empty object if undefined
              ...files, // Add new files
            },
          };
        }
        return project;
      });

      state.projectList = updatedProjectList;

      if (state.currentProjectId === project_id) {
        state.currentProject = updatedProjectList.find(
          (p) => p.project_id === project_id
        );
      }
    }
  },
};

export const uploadFiles = async (
  files,
  projectId,
  chatId,
  userId,
  annotate,
  token,
  uploadType
) => {
  if (!userId) {
    throw new Error("User ID is required for file upload");
  }

  const formData = new FormData();
  files.forEach((fileData, index) => {
    formData.append(`files`, fileData.file);
    if (uploadType !== "data") {
      formData.append(`topology_${index}`, fileData.topology);
      // Add sequence type if it's a manual sequence creation
      if (fileData.sequenceType) {
        formData.append(`sequence_type_${index}`, fileData.sequenceType);
      }
    }
  });
  formData.append("project_id", projectId || "no_project");
  formData.append("chat_id", chatId);
  formData.append("user_id", userId);
  formData.append("annotate", annotate);
  formData.append("upload_type", uploadType || "file");

  console.log("uploadType", uploadType);
  console.log("projectId", projectId);
  console.log("chatId", chatId);
  console.log("userId", userId);
  console.log("annotate", annotate);
  console.log("token", token);

  const defaultHeaders = {
    Authorization: `Bearer ${token}`,
  };

  console.log("formData", formData);

  const response = await fetch(`${API_URL}/api/upload-files`, {
    method: "POST",
    body: formData,
    headers: defaultHeaders,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const result = await response.json();

  if (result.status !== "success") {
    throw new Error(`Upload failed: ${result.status}`);
  }

  return result.data;
};
