import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProtocolData, fetchArtifactData } from "../store/project.js";
import Select from "react-select";
import {
  protocol_list,
  pcr_protocol_list,
  digest_protocol_list,
  synthesis_to_order_protocol_list,
  gibson_protocol_list,
  golden_gate_protocol_list,
  traditional_protocol_list,
  synthesis_to_order_gene_protocol_list,
  synthesis_to_order_primer_protocol_list,
} from "../store/protocol_list.js";
import "../style/Protocol.css";
import { downloadFiles } from "../api/files_api.js"; // Add this import
import { setPopupMessage } from "../store/user.js";
import { copyMarkdownToClipboard } from "../helpers.js";

export const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  const goToNext = () => {
    setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
  };

  if (!images || images.length === 0) return null;

  return (
    <div className="carousel-container">
      <button
        className="carousel-button prev"
        onClick={goToPrevious}
        aria-label="Previous image"
      >
        ‹
      </button>

      <div className="carousel-content">
        <img
          src={images[currentIndex].src}
          alt={images[currentIndex].alt}
          className="protocol-image"
        />
        <div className="carousel-indicators">
          {images.length > 1 && (
            <span className="image-counter">
              {currentIndex + 1} / {images.length}
            </span>
          )}
        </div>
      </div>

      <button
        className="carousel-button next"
        onClick={goToNext}
        aria-label="Next image"
      >
        ›
      </button>
    </div>
  );
};

function Protocol() {
  const dispatch = useDispatch();
  //   const [markdownContent, setMarkdownContent] = useState("");
  const [editableFields, setEditableFields] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [protocolType, setProtocolType] = useState("");
  const [protocolList, setProtocolList] = useState({});
  const [selectedProtocol, setSelectedProtocol] = useState("");
  const [preferredVendor, setPreferredVendor] = useState("NEB");
  const artifact = useSelector((state) => state.project.artifact);
  const currentProject = useSelector((state) => state.project.currentProject);
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.user.access_token); // Add this line to get the access token
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [preferencesOpen, setPreferencesOpen] = useState(false);

  useEffect(() => {
    if (artifact && artifact.type === "markdown") {
      //   setMarkdownContent(artifact.content);

      const initialInputValues = {};
      const inputRegex = /<input id="(\w+)"[^>]*value="([^"]*)"[^>]*>/g;
      let match;
      while ((match = inputRegex.exec(artifact.content)) !== null) {
        initialInputValues[match[1]] = match[2];
      }
      setInputValues(initialInputValues);
      setEditableFields(initialInputValues);
    }
  }, [artifact]);

  useEffect(() => {
    if (artifact && artifact.protocol_id) {
      const protocol = protocol_list[artifact.protocol_id];
      if (protocol) {
        setProtocolType(protocol.Type);
        if (protocol.Type === "digest") {
          setPreferredVendor(artifact.digest_vendor || "NEB");
        } else if (protocol.Type === "synthesis_to_order") {
          setSelectedProtocol(artifact.protocol_id);
          setEditableFields((prev) => ({
            ...prev,
            "protocol-dropdown-2": artifact.protocol_id_2,
          }));
        } else {
          switch (protocol.Type) {
            case "pcr":
              setProtocolList(pcr_protocol_list);
              break;
            case "digest":
              setProtocolList(digest_protocol_list);
              break;
            case "synthesis_to_order":
              setProtocolList(synthesis_to_order_protocol_list);
              break;
            case "gibson":
              setProtocolList(gibson_protocol_list);
              break;
            case "golden_gate":
              setProtocolList(golden_gate_protocol_list);
              break;
            case "traditional":
              setProtocolList(traditional_protocol_list);
              break;
            default:
              setProtocolList({});
          }
          setSelectedProtocol(artifact.protocol_id);
        }
      }
    }
  }, [artifact]);

  const handleInputChange = (id, value, type = "text") => {
    if (type === "checkbox") {
      value = !editableFields[id];
    }
    setEditableFields((prev) => ({ ...prev, [id]: value }));
    setInputValues((prev) => ({ ...prev, [id]: value }));

    // Update preferredVendor when digest_vendor input changes
    if (id === "digest_vendor") {
      setPreferredVendor(value);
    }
  };

  const handleProtocolChange = (e) => {
    setSelectedProtocol(e.target.value);
  };

  const handleSubmit = () => {
    const inputs = {};
    document.querySelectorAll("input, select").forEach((element) => {
      if (element.id && !element.id.startsWith("react-select")) {
        if (element.type === "checkbox") {
          inputs[element.id] = element.checked;
        } else {
          inputs[element.id] = element.value;
        }
      }
    });

    document.querySelectorAll("span[id]").forEach((element) => {
      inputs[element.id] = element.textContent;
    });

    if (protocolType === "digest") {
      inputs["digest_vendor"] = preferredVendor;
    } else {
      inputs["protocol-dropdown"] = selectedProtocol || artifact.protocol_id;
      inputs["protocol_id"] = selectedProtocol || artifact.protocol_id;
      if (protocolType === "synthesis_to_order") {
        inputs["protocol_id_2"] =
          editableFields["protocol-dropdown-2"] || artifact.protocol_id_2;
      }
    }

    // Remove any inputs that start with 'react-select'
    const filteredInputs = Object.fromEntries(
      Object.entries(inputs).filter(([key]) => !key.startsWith("react-select"))
    );

    if (artifact && artifact.file_id) {
      dispatch(
        editProtocolData({ fileId: artifact.file_id, inputs: filteredInputs })
      )
        .unwrap()
        .then(() => {
          dispatch(setPopupMessage("Protocol changes saved successfully!"));
        })
        .catch((error) => {
          dispatch(
            setPopupMessage(
              "Failed to save protocol changes. Please try again."
            )
          );
        });
    } else {
      console.error("No file_id available for the current artifact");
      dispatch(setPopupMessage("Error: Could not identify protocol file."));
    }
    console.log(
      "Submitted Protocol:",
      filteredInputs["protocol-dropdown"] || filteredInputs["digest_vendor"]
    );
  };

  const handleArtifactClick = (fileName) => {
    if (currentProject && currentProject.files) {
      const fileId = Object.keys(currentProject.files).find(
        (key) => currentProject.files[key].file_name === fileName
      );
      if (fileId) {
        dispatch(fetchArtifactData({ fileId }));
      } else {
        console.log(`File not found: ${fileName}`);
      }
    } else {
      console.log("No current project or project files available");
    }
  };

  const processImageInText = (text) => {
    const imgRegex = /!\[([^\]]*)\]\((data:image\/[^;]+;base64,[^)]+)\)/g;
    const images = [];
    let match;

    // Collect all images
    while ((match = imgRegex.exec(text)) !== null) {
      images.push({
        alt: match[1],
        src: match[2],
      });
    }

    // If we found images, handle them appropriately
    if (images.length > 1) {
      // Multiple images - use carousel
      return <ImageCarousel images={images} />;
    } else if (images.length === 1) {
      // Single image - render directly
      return (
        <img
          src={images[0].src}
          alt={images[0].alt}
          className="protocol-image"
        />
      );
    }

    return text;
  };

  const renderMarkdownContent = () => {
    if (!artifact || !artifact.content) return null;

    if (artifact.type === "csv") {
      const title = artifact.file_name.toLowerCase().includes("fragment")
        ? "Fragments To Order"
        : artifact.file_name.toLowerCase().includes("oligo")
        ? "Oligos To Order"
        : "";

      const handleCopyClick = () => {
        navigator.clipboard.writeText(artifact.content);
        setShowCopyNotification(true);
        setTimeout(() => {
          setShowCopyNotification(false);
        }, 2000); // Notification disappears after 2 seconds
      };

      return (
        <div className="markdown-content">
          <div className="csv-header">
            <h2>{title}</h2>
            <div className="copy-button-container">
              <button
                className="copy-button"
                onClick={handleCopyClick}
                title="Copy to clipboard"
              >
                <img src="/images/copy-csv.svg" alt="Copy" />
              </button>
              {showCopyNotification && (
                <div className="copy-notification">CSV copied to clipboard</div>
              )}
            </div>
          </div>
          {artifact.content === "" ? (
            <p>
              No sequences to order! Click the checkboxes for the relevant
              sequences and then press submit changes if you would like to
              generate an order form
            </p>
          ) : (
            <pre className="csv-content">{artifact.content}</pre>
          )}
        </div>
      );
    }

    // Make sure content is a string before using replace
    const acont_tmp = String(artifact.content);

    // Remove trailing newlines before splitting into lines
    const trimmedContent = acont_tmp.replace(/\n+$/, "");
    const lines = trimmedContent.split("\n");
    let tableStartIndex = -1;
    let inList = false;
    let listItems = [];

    const processLinks = (text, lineIndex) => {
      const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
      let lastIndex = 0;
      const elements = [];
      let match;

      while ((match = linkRegex.exec(text)) !== null) {
        if (lastIndex < match.index) {
          elements.push(text.slice(lastIndex, match.index));
        }

        const [fullMatch, linkText, url] = match;
        if (url.endsWith(".md")) {
          elements.push(
            <a
              key={`link-${lineIndex}-${elements.length}`}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleArtifactClick(url);
              }}
            >
              {linkText}
            </a>
          );
        } else {
          elements.push(
            <a
              key={`link-${lineIndex}-${elements.length}`}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </a>
          );
        }

        lastIndex = match.index + fullMatch.length;
      }

      if (lastIndex < text.length) {
        elements.push(text.slice(lastIndex));
      }

      return elements;
    };

    const renderPreferences = (lines, startIndex) => {
      let endIndex = lines.findIndex(
        (line, idx) =>
          idx > startIndex && (line.startsWith("##") || line.startsWith("# "))
      );
      if (endIndex === -1) endIndex = lines.length;

      const getProtocolName = (key) => {
        if (protocol_list[key]) {
          return protocol_list[key].Name;
        }
        return key;
      };

      const formatKey = (key) => {
        // Special case replacements
        if (key === "synthesis_to_order_primer_protocol")
          return "Primer Vendor";
        if (key === "synthesis_to_order_protocol") return "Gene Vendor";

        // General case: capitalize each word and replace underscores with spaces
        return key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      };

      const formatValue = (key, value) => {
        if (key.toLowerCase().includes("protocol")) {
          return getProtocolName(value);
        }

        // Handle numeric values
        if (!isNaN(value)) {
          if (key === "primer_concentration") {
            return `${value} μM`;
          }
          if (
            key.toLowerCase().includes("size") ||
            key.toLowerCase().includes("reaction")
          ) {
            return `${value} μL`;
          }
        }
        return value;
      };

      return (
        <div key={`preferences-${startIndex}`}>
          <div
            className="collapsible-header"
            onClick={() => setPreferencesOpen(!preferencesOpen)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault();
                setPreferencesOpen(!preferencesOpen);
              }
            }}
            role="button"
            tabIndex={0}
            aria-expanded={preferencesOpen}
            aria-controls="preferences-content"
          >
            <h2>
              User Preferences
              <span className="collapsible-toggle" aria-hidden="true">
                {preferencesOpen ? "−" : "+"}
              </span>
            </h2>
          </div>
          {preferencesOpen && (
            <div
              id="preferences-content"
              className="preferences-content"
              role="region"
              aria-label="User preferences details"
            >
              {lines
                .slice(startIndex + 1, endIndex)
                .filter((line) => line.startsWith("Preference:"))
                .map((line) => {
                  const [key, value] = line
                    .replace("Preference:", "")
                    .split(":")
                    .map((s) => s.trim());
                  return { key, value };
                })
                .sort((a, b) =>
                  formatKey(a.key).localeCompare(formatKey(b.key))
                )
                .map(({ key, value }, index) => (
                  <React.Fragment key={index}>
                    <span className="preference-label">{formatKey(key)}:</span>
                    <span className="preference-value">
                      {formatValue(key, value)}
                    </span>
                  </React.Fragment>
                ))}
            </div>
          )}
        </div>
      );
    };

    const content = lines.reduce((acc, line, index) => {
      const trimmedLine = line.trimStart();

      if (line.includes("<table") && tableStartIndex === -1) {
        if (inList) {
          acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
          inList = false;
          listItems = [];
        }
        tableStartIndex = index;
      } else if (line.includes("</table>")) {
        const tableContent = lines.slice(tableStartIndex, index + 1);
        acc.push(renderTable(tableContent, index));
        tableStartIndex = -1;
      } else if (tableStartIndex === -1 && trimmedLine !== "") {
        if (trimmedLine.startsWith("## User Preferences")) {
          if (inList) {
            acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
            inList = false;
            listItems = [];
          }
          acc.push(renderPreferences(lines, index));
          // Skip all preference lines when section is closed
          if (!preferencesOpen) {
            while (
              index + 1 < lines.length &&
              lines[index + 1].startsWith("Preference:")
            ) {
              index++;
            }
          }
        } else if (!line.startsWith("Preference:")) {
          // Skip preference lines in normal processing
          if (trimmedLine.startsWith("# ")) {
            if (inList) {
              acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
              inList = false;
              listItems = [];
            }
            acc.push(
              <h1 key={index}>{processLinks(trimmedLine.slice(2), index)}</h1>
            );
          } else if (trimmedLine.startsWith("## ")) {
            if (inList) {
              acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
              inList = false;
              listItems = [];
            }
            acc.push(
              <h2 key={index}>{processLinks(trimmedLine.slice(3), index)}</h2>
            );
          } else if (trimmedLine.startsWith("### ")) {
            if (inList) {
              acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
              inList = false;
              listItems = [];
            }
            acc.push(
              <h3 key={index}>{processLinks(trimmedLine.slice(4), index)}</h3>
            );
          } else if (line.includes("<input") && !line.includes("table_input")) {
            if (inList) {
              acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
              inList = false;
              listItems = [];
            }
            acc.push(renderInput(line, index));
          } else if (trimmedLine.startsWith("- ")) {
            inList = true;
            listItems.push(
              <li key={`item-${index}`}>
                {processLinks(trimmedLine.slice(2), index)}
              </li>
            );
          } else if (trimmedLine !== "") {
            if (inList) {
              acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
              inList = false;
              listItems = [];
            }

            // Handle HTML elements
            if (
              trimmedLine.includes("<select") ||
              trimmedLine.includes("<input")
            ) {
              acc.push(
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: trimmedLine }}
                />
              );
            }
            // Handle image lines
            else if (trimmedLine.startsWith("![")) {
              acc.push(
                <div key={index}>{processImageInText(trimmedLine)}</div>
              );
            }
            // Handle regular text/links
            else {
              acc.push(<p key={index}>{processLinks(trimmedLine, index)}</p>);
            }
          }

          if (index === lines.length - 1 && inList) {
            acc.push(<ul key={`list-${index}`}>{listItems}</ul>);
          }
        }
      }

      return acc;
    }, []);

    return <div className="markdown-content">{content}</div>;
  };

  const renderInput = (line, index) => {
    const inputRegex = /<input[^>]*>/g;
    const inputs = line.match(inputRegex);

    if (inputs) {
      return (
        <p key={index} className="input-line">
          {line.split(inputRegex).map((text, i) => (
            <React.Fragment key={i}>
              {text.replace("</input>", "")}
              {inputs[i] &&
                createInputElement(inputs[i], `input-${index}-${i}`)}
            </React.Fragment>
          ))}
        </p>
      );
    }
    return <p key={index}>{line}</p>;
  };

  const createInputElement = (inputHtml, key) => {
    const idMatch = inputHtml.match(/id=['"](.*?)['"]/);
    const valueMatch = inputHtml.match(/value=['"](.*?)['"]/);
    const typeMatch = inputHtml.match(/type=['"](.*?)['"]/);
    const isSelect = inputHtml.startsWith("<select");

    if (idMatch) {
      const id = idMatch[1];
      const defaultValue = valueMatch ? valueMatch[1] : "";
      const type = typeMatch ? typeMatch[1] : "text";

      // Handle ladder_select dropdown
      if (id === "ladder_select") {
        const options = [
          {
            value: "NEB_1 kb Plus DNA Ladder",
            label: "NEB 1 kb Plus DNA Ladder",
          },
          { value: "NEB_1 kb DNA Ladder", label: "NEB 1 kb DNA Ladder" },
          { value: "NEB_100 bp DNA Ladder", label: "NEB 100 bp DNA Ladder" },
          { value: "NEB_50 bp DNA Ladder", label: "NEB 50 bp DNA Ladder" },
          {
            value: "NEB_Low Molecular Weight DNA Ladder",
            label: "NEB Low Molecular Weight DNA Ladder",
          },
          {
            value: "Thermo_GeneRuler 1 kb DNA Ladder",
            label: "Thermo GeneRuler 1 kb DNA Ladder",
          },
          {
            value: "Thermo_GeneRuler 1 kb Plus DNA Ladder",
            label: "Thermo GeneRuler 1 kb Plus DNA Ladder",
          },
          {
            value: "Thermo_GeneRuler 100 bp DNA Ladder",
            label: "Thermo GeneRuler 100 bp DNA Ladder",
          },
          {
            value: "Thermo_GeneRuler 100 bp Plus DNA Ladder",
            label: "Thermo GeneRuler 100 bp Plus DNA Ladder",
          },
          {
            value: "Thermo_GeneRuler 50 bp DNA Ladder",
            label: "Thermo GeneRuler 50 bp DNA Ladder",
          },
        ];

        return (
          <Select
            key={key}
            id={id}
            options={options}
            value={options.find(
              (opt) => opt.value === (inputValues[id] || defaultValue)
            )}
            onChange={(selectedOption) =>
              handleInputChange(id, selectedOption.value)
            }
            className="protocol-dropdown"
            classNamePrefix="protocol-dropdown"
            placeholder="Select a DNA Ladder"
          />
        );
      }

      return (
        <input
          key={key}
          id={id}
          type={type}
          value={inputValues[id] !== undefined ? inputValues[id] : defaultValue}
          onChange={(e) => handleInputChange(id, e.target.value)}
          style={{ backgroundColor: "white" }}
          aria-label={id.replace(/-/g, " ")}
        />
      );
    }
    return null;
  };

  const renderCheckbox = (line, index) => {
    const match = line.match(/id="(\w+)"/);
    if (match) {
      const id = match[1];
      const label = line.split(">")[1].split("<")[0];
      return (
        <div key={index} className="checkbox-group">
          <input
            id={id}
            type="checkbox"
            checked={editableFields[id] || false}
            onChange={() => handleInputChange(id, null, "checkbox")}
            aria-label={label}
          />
          <label htmlFor={id}>{label}</label>
        </div>
      );
    }
  };

  const renderTable = (tableLines, startIndex) => {
    const processCell = (cell, cellIndex) => {
      if (cell.includes("<br>")) {
        return cell.split("<br>").map((segment, index) => (
          <React.Fragment key={index}>
            {index > 0 && <br />}
            {segment.trim()}
          </React.Fragment>
        ));
      }
      // Handle checkboxes in tables
      if (cell.includes('<input type="checkbox"')) {
        const id = cell.match(/id="([^"]+)"/)?.[1];
        const isChecked = cell.includes("checked");
        return (
          <input
            type="checkbox"
            id={id}
            defaultChecked={isChecked}
            checked={
              editableFields[id] !== undefined ? editableFields[id] : isChecked
            }
            onChange={(e) =>
              handleInputChange(id, e.target.checked, "checkbox")
            }
            className="table-checkbox"
          />
        );
      }
      if (cell.includes("<input")) {
        const match = cell.match(/id="([^"]+)"/);
        const valueMatch = cell.match(/value="([^"]*)"/);
        if (match) {
          const id = match[1];
          const defaultValue = valueMatch ? valueMatch[1] : "";
          return (
            <input
              key={`table-input-${startIndex}-${cellIndex}`}
              id={id}
              type="text"
              value={
                inputValues[id] !== undefined ? inputValues[id] : defaultValue
              }
              onChange={(e) => handleInputChange(id, e.target.value)}
              style={{ backgroundColor: "white" }}
            />
          );
        }
      }
      return cell.trim();
    };

    return (
      <div key={startIndex} className="table-wrapper">
        <table className="editable-table">
          <thead>
            <tr>
              {tableLines[1]
                .split("|")
                .slice(1, -1)
                .map((header, index) => (
                  <th key={index}>{processCell(header, index)}</th>
                ))}
            </tr>
          </thead>
          <tbody>
            {tableLines.slice(3, -1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row
                  .split("|")
                  .slice(1, -1)
                  .map((cell, cellIndex) => (
                    <td key={cellIndex}>{processCell(cell, cellIndex)}</td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDropdown = (line, index) => {
    const match = line.match(/id="(\w+)"/);
    const optionsMatch = line.match(/options="([^"]*)"/);
    if (match && optionsMatch) {
      const id = match[1];
      const options = optionsMatch[1].split(",");
      if (!dropdownOptions[id]) {
        setDropdownOptions((prev) => ({ ...prev, [id]: options }));
      }
      return (
        <div key={index} className="dropdown-group">
          <label htmlFor={id}>{line.split(":")[0].trim()}:</label>
          <input
            id={id}
            type="text"
            value={editableFields[id] || ""}
            onChange={(e) => handleInputChange(id, e.target.value)}
            list={`${id}-options`}
          />
          <datalist id={`${id}-options`}>
            {dropdownOptions[id]
              ?.filter((option) =>
                option
                  .toLowerCase()
                  .includes((editableFields[id] || "").toLowerCase())
              )
              .map((option, optionIndex) => (
                <option key={optionIndex} value={option} />
              ))}
          </datalist>
        </div>
      );
    }
  };

  const renderResult = (index) => {
    return (
      <div key={index} className="result">
        <span>Result: </span>
        <span id="result">{editableFields.result || ""}</span>
      </div>
    );
  };

  const renderProtocolDropdown = () => {
    if (
      !protocolType ||
      artifact?.protocol_id === "errors_and_warnings" ||
      artifact?.protocol_id === "summary" ||
      artifact?.type === "csv"
    ) {
      return null;
    }

    if (protocolType === "digest") {
      const vendorOptions = [
        { value: "NEB", label: "NEB" },
        { value: "Thermo", label: "Thermo" },
      ];

      return (
        <div className="protocol-selection">
          <label id="digest-vendor-label" htmlFor="digest_vendor">
            <strong>Preferred Vendor:</strong>
          </label>
          <Select
            id="digest_vendor"
            options={vendorOptions}
            value={
              vendorOptions.find(
                (option) => option.value === preferredVendor
              ) || null
            }
            onChange={(selectedOption) => {
              const newValue = selectedOption ? selectedOption.value : "NEB";
              setPreferredVendor(newValue);
              handleInputChange("digest_vendor", newValue);
            }}
            className="protocol-dropdown"
            classNamePrefix="protocol-dropdown"
            placeholder="Select a Preferred Vendor"
            aria-labelledby="digest-vendor-label"
          />
        </div>
      );
    }

    const options = Object.entries(protocolList).map(([key, { Name }]) => ({
      value: key,
      label: Name,
    }));

    if (protocolType === "synthesis_to_order") {
      const geneOptions = Object.entries(
        synthesis_to_order_gene_protocol_list
      ).map(([key, { Name }]) => ({
        value: key,
        label: Name,
      }));

      const primerOptions = Object.entries(
        synthesis_to_order_primer_protocol_list
      ).map(([key, { Name }]) => ({
        value: key,
        label: Name,
      }));

      // Find the selected primer option
      const selectedPrimerOption = primerOptions.find(
        (option) => option.value === editableFields["protocol-dropdown-2"]
      );

      return (
        <div className="protocol-selection synthesis-to-order">
          <div className="dropdown-container">
            <label htmlFor="protocol-dropdown">
              <strong>Fragment Vendor:</strong>
            </label>
            <Select
              id="protocol-dropdown"
              options={geneOptions}
              value={geneOptions.find(
                (option) => option.value === selectedProtocol
              )}
              onChange={(selectedOption) =>
                setSelectedProtocol(selectedOption ? selectedOption.value : "")
              }
              className="protocol-dropdown"
              classNamePrefix="protocol-dropdown"
              placeholder="Select a DNA Vendor"
            />
          </div>
          <div className="dropdown-container">
            <label htmlFor="protocol-dropdown-2">
              <strong>Oligo Vendor:</strong>
            </label>
            <Select
              id="protocol-dropdown-2"
              options={primerOptions}
              value={selectedPrimerOption}
              onChange={(selectedOption) =>
                handleInputChange(
                  "protocol-dropdown-2",
                  selectedOption ? selectedOption.value : ""
                )
              }
              className="protocol-dropdown"
              classNamePrefix="protocol-dropdown"
              placeholder="Select a Primer Vendor"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="protocol-selection">
        <label htmlFor="protocol-dropdown">
          <strong>Protocol:</strong>
        </label>
        <Select
          id="protocol-dropdown"
          options={options}
          value={
            options.find((option) => option.value === selectedProtocol) || null
          }
          onChange={(selectedOption) =>
            setSelectedProtocol(selectedOption ? selectedOption.value : "")
          }
          className="protocol-dropdown"
          classNamePrefix="protocol-dropdown"
          placeholder={`Select a ${protocolType.replace("_", " ")} Protocol`}
        />
      </div>
    );
  };

  const handleDownload = () => {
    if (artifact && artifact.file_id) {
      console.log("Downloading file with file_id:", artifact);
      downloadFiles(artifact.file_id, "file", token);
    } else {
      console.error("No file_id available for the current artifact");
    }
  };

  const renderMarkdownControls = () => {
    const showDownloadButton =
      !artifact?.protocol_id?.includes("synthesis_to_order");

    const handleCopyMarkdown = async () => {
      const success = await copyMarkdownToClipboard(artifact.content);
      if (success) {
        setShowCopyNotification(true);
        setTimeout(() => {
          setShowCopyNotification(false);
        }, 2000);
      }
    };

    return (
      <div className="protocol-markdown-controls">
        {artifact?.type !== "csv" && (
          <>
            {renderProtocolDropdown()}
            <div className="protocol-button-group">
              {showDownloadButton && (
                <button
                  className="protocol-download-button"
                  onClick={handleDownload}
                  aria-label="Download protocol"
                >
                  <img
                    src="/images/download.svg"
                    alt=""
                    aria-hidden="true"
                    className="protocol-download-icon"
                  />
                </button>
              )}
              <button
                className="protocol-copy-button"
                onClick={handleCopyMarkdown}
                title="Copy to clipboard"
                aria-label="Copy protocol"
              >
                <img
                  src="/images/copy-csv.svg"
                  alt=""
                  aria-hidden="true"
                  className="protocol-copy-icon"
                />
              </button>
              {showCopyNotification && (
                <div className="copy-tooltip">Markdown copied to clipboard</div>
              )}
              {artifact.protocol_id !== "errors_and_warnings" &&
                artifact.protocol_id !== "summary" && (
                  <button
                    onClick={handleSubmit}
                    className="protocol-submit-button"
                    aria-label="Submit protocol changes"
                  >
                    Submit Changes
                  </button>
                )}
            </div>
          </>
        )}
        {artifact?.type === "csv" && (
          <div className="protocol-button-group">
            <button
              className="protocol-download-button"
              title="Download"
              onClick={handleDownload}
            >
              <img
                src="/images/download.svg"
                alt="Download"
                className="protocol-download-icon"
              />
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="protocol-content" role="main">
      {renderMarkdownControls()}
      {renderMarkdownContent()}
    </div>
  );
}

export default Protocol;
