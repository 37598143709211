import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, fetchArtifactData } from "../store/project.js";
import { handleDiffChanges } from "../store/project_reducers/handleDiffChanges.js";
import { executeCallback, removeCallback } from "../utils/callbackRegistry.js";
import "../style/Modal.css";

function EditStateModal({ onComplete }) {
  const dispatch = useDispatch();
  const artifact = useSelector((state) => state.project.artifact);

  const handleAcceptAll = async () => {
    try {
      await dispatch(
        handleDiffChanges({
          projectId: onComplete.projectId,
          action: "accept_all",
          fileIds: [],
        })
      );
      dispatch(closeModal());
      if (onComplete.callbackId) {
        executeCallback(onComplete.callbackId);
      }
    } catch (error) {
      console.error("Error accepting changes:", error);
    }
  };

  const handleRejectAll = async () => {
    try {
      await dispatch(
        handleDiffChanges({
          projectId: onComplete.projectId,
          action: "reject_all",
          fileIds: [],
        })
      ).then(() => {
        if (artifact?.file_id) {
          dispatch(fetchArtifactData({ fileId: artifact.file_id }));
        }
      });
      dispatch(closeModal());
      if (onComplete.callbackId) {
        executeCallback(onComplete.callbackId);
      }
    } catch (error) {
      console.error("Error rejecting changes:", error);
    }
  };

  const handleCancel = () => {
    if (onComplete.callbackId) {
      removeCallback(onComplete.callbackId);
    }
    dispatch(closeModal());
  };

  return (
    <div
      className="create-project-modal-overlay"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="create-project-modal-content">
        <h2 className="create-project-modal-title">Pending Changes</h2>
        <p>
          You have pending changes that need to be accepted or rejected before
          proceeding.
        </p>

        <div className="create-project-modal-buttons">
          <button
            onClick={handleCancel}
            className="create-project-cancel-button"
          >
            Cancel
          </button>
          <button
            onClick={handleRejectAll}
            className="create-project-cancel-button"
            style={{ backgroundColor: "var(--color-error)" }}
          >
            Reject All
          </button>
          <button
            onClick={handleAcceptAll}
            className="create-project-submit-button"
          >
            Accept All
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditStateModal;
