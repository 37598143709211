import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../style/ChatMessages.css";
import { components } from "./ChatMessages_helpers/components.js";
import { MessageHistory } from "./ChatMessages_helpers/MessageHistory.js";
import { StreamingResponse } from "./ChatMessages_helpers/StreamingResponse.js";

function ChatMessages({ externalMessages, chatType }) {
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const user_id = useSelector((state) => state.user.user_id);

  const {
    chat,
    currentProjectId,
    currentStreamedUpdate,
    currentProject,
    activeChat,
    currentChatId,
    currentStreamedToolCalls,
    streamProgress,
  } = useSelector((state) => state.project);

  // Add this selector
  const sendMessageError = useSelector(
    (state) => state.project.sendMessageError
  );

  // Update the initial state to track hidden tool calls instead of visible ones
  const [hiddenToolCalls, setHiddenToolCalls] = useState(new Set());

  const getLatestChatFile = () => {
    if (!currentProject?.[`${activeChat}_chat_files`]) return null;

    const chatFiles = Object.values(
      currentProject[`${activeChat}_chat_files`]
    ).sort((a, b) => {
      const dateA = new Date(b.last_opened_date);
      const dateB = new Date(a.last_opened_date);
      return dateA - dateB;
    });

    return chatFiles[0] || null;
  };

  const latestChat = getLatestChatFile();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "auto" });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [
    messages?.chat_contents,
    currentStreamedUpdate,
    currentStreamedToolCalls,
  ]);

  useEffect(() => {
    setIsScrolled(false);
    scrollToBottom();
  }, [messages, currentProjectId]);

  useEffect(() => {
    if (externalMessages) {
      setMessages({ chat_contents: externalMessages });
    } else if (currentChatId && chat[currentChatId]) {
      setMessages(chat[currentChatId]);
    } else if (latestChat && chat[latestChat.chat_id]) {
      setMessages(chat[latestChat.chat_id]);
    } else if (currentProjectId && chat[currentProjectId]) {
      setMessages(chat[currentProjectId]);
    } else {
      setMessages(null);
    }
  }, [
    chat,
    currentProjectId,
    latestChat,
    activeChat,
    currentChatId,
    externalMessages,
  ]);

  return (
    <div className="chat-messages-container">
      {currentProjectId && messages?.chat_contents?.length > 0 && (
        <div className="chat-messages-list">
          <ul className="chat-messages-list" aria-live="polite">
            {messages.chat_contents
              .filter((message) => {
                // Only show user and assistant messages
                return message.role === "user" || message.role === "assistant";
              })
              .map((message, idx, filteredMessages) => {
                const isFirstAssistantAfterUser =
                  message.role === "assistant" &&
                  (idx === 0
                    ? false
                    : filteredMessages[idx - 1].role === "user");

                return (
                  <li
                    key={idx}
                    className={`message ${
                      message.role === "user" ? "user-message" : "ai-message"
                    }`}
                    role="article"
                    aria-label={
                      message.role === "user" ? "Your message" : "AI response"
                    }
                  >
                    {message.role === "assistant" &&
                      isFirstAssistantAfterUser && (
                        <img
                          src="/images/K-mark.svg"
                          alt="LabKick AI Assistant"
                          className="ai-logo"
                        />
                      )}
                    <div className="message-content">
                      <MessageHistory
                        message={message}
                        messageIndex={idx}
                        hiddenToolCalls={hiddenToolCalls}
                        setHiddenToolCalls={setHiddenToolCalls}
                        messages={messages}
                      />
                    </div>
                  </li>
                );
              })}
            {sendMessageError && (
              <div className="error-message-container" role="alert">
                <div className="error-message-content">{sendMessageError}</div>
              </div>
            )}
            {streamProgress && !currentStreamedUpdate && (
              <div
                className={`stream-progress ${
                  !messages.chat_contents[
                    messages.chat_contents.length - 1
                  ]?.content?.trim()
                    ? "stream-progress-top"
                    : ""
                }`}
                aria-label={streamProgress}
              >
                <div className="status-message">{streamProgress}</div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </ul>
        </div>
      )}
    </div>
  );
}

export default ChatMessages;
