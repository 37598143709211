import { fetchWithToken, API_URL } from "./api.js";

export const resendVerificationEmail = async (userData, token) => {
  console.log("resendVerificationEmail called with:", { userData });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/resend-verification`,
      {
        method: "POST",
        body: JSON.stringify({
          user_id: userData.sub,
          client_id: "wIR2ovSzlTWy30MYdyeKmsHsFnjcMMds",
          identity: {
            user_id: userData.sub,
            provider: userData.sub.split("|")[0],
          },
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to resend verification email");
    }

    return await response.text();
  } catch (error) {
    console.error("Error in resendVerificationEmail:", error);
    throw error;
  }
};
