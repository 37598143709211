import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../store/project.js";
import { renameFileGlobal } from "../store/project_reducers/renameFileGlobal.js";
import "../style/Modal.css";

function UserFileModal() {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.project);
  const userId = useSelector((state) => state.user.user_id);

  // modal.data should contain { mode: 'rename', fileId: string, fileName: string }
  const { mode, fileId, fileName } = modal.data || {};

  useEffect(() => {
    if (mode === "rename" && fileName) {
      setInputValue(fileName);
    } else {
      setInputValue("");
    }
  }, [mode, fileName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(closeModal());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dispatch(closeModal());
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    try {
      if (!inputValue.trim()) {
        setError("File name cannot be empty");
        return;
      }

      await dispatch(
        renameFileGlobal({
          fileId,
          newName: inputValue,
          oldName: fileName,
          userId,
        })
      ).unwrap();

      dispatch(closeModal());
    } catch (error) {
      console.error("Error renaming file:", error);
      setError(error.message || "An error occurred while renaming the file");
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.stopPropagation()}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="modal-content" ref={modalRef}>
        <h2 className="modal-title" id="modal-title">
          Rename File
        </h2>
        {error && (
          <div className="modal-error-message" role="alert" aria-live="polite">
            {error}
          </div>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          aria-label="File rename form"
        >
          <input
            id="file-name-input"
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter new name"
            className="modal-input"
            aria-invalid={!!error}
            aria-describedby={error ? "name-error" : undefined}
          />
        </form>
        <div className="modal-buttons">
          <button
            type="button"
            onClick={() => dispatch(closeModal())}
            className="modal-cancel-button"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className="modal-submit-button"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default UserFileModal;
