import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateArtifact } from "../project.js";
import { fetchProject } from "./fetchProject.js";

export const deleteExistingProject = createAsyncThunk(
  "projects/deleteExistingProject",
  async ({ projectId }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const user_id = getState().user.user_id;
    const currentProjectId = getState().project.currentProjectId;

    const response = await deleteProjectPermanent(projectId, user_id, token);
    console.log("deleteExistingProject response", response);

    if (projectId === currentProjectId) {
      dispatch(updateArtifact(null));
    }

    // If we deleted the current project and there are other projects
    if (projectId === currentProjectId && response.length > 0) {
      await dispatch(fetchProject(response[0].project_id));
    }

    // Only return the project IDs from the response
    return {
      projectId,
      remainingProjectIds: response.map((p) => p.project_id),
    };
  }
);

export const deleteExistingProjectReducer = {
  [deleteExistingProject.fulfilled]: (state, action) => {
    const { projectId, remainingProjectIds } = action.payload;

    // Only update the project list IDs, not the full project data
    state.projectList = state.projectList.filter((p) =>
      remainingProjectIds.includes(p.project_id)
    );

    // Only clear current project if we're deleting the current project and no projects remain
    if (
      state.currentProjectId === projectId &&
      remainingProjectIds.length === 0
    ) {
      state.currentProjectId = null;
      state.currentProject = null;
    }
  },
};

export const deleteProjectPermanent = async (projectId, user_id, token) => {
  console.log("deleteProjectPermanent called with:", { projectId, user_id });
  const response = await fetchWithToken(
    `${API_URL}/api/delete-project`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId, user_id: user_id }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to delete project");
  }

  const result = await response.json();
  return result.status === "success"
    ? JSON.parse(result.data).projects
    : Promise.reject(result.message);
};
