import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const deleteProjectFile = createAsyncThunk(
  "projects/deleteProjectFile",
  async ({ projectId, fileId }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    console.log("deleteProjectFile projectId:", projectId, "fileId:", fileId);
    await deleteFileFromProject(projectId, fileId, token);

    return { projectId, fileId };
  }
);

export const deleteProjectFileReducer = {
  [deleteProjectFile.fulfilled]: (state, action) => {
    const { projectId, fileId } = action.payload;
    const project = state.projectList.find((p) => p.project_id === projectId);

    if (project && project.files[fileId]) {
      delete project.files[fileId];
      state.projectList = state.projectList.map((p) => {
        if (p.project_id === projectId) {
          return { ...p };
        }
        return p;
      });

      if (state.currentProjectId === projectId) {
        state.currentProject = { ...project };
      }
    }
  },
};

export const deleteFileFromProject = async (projectId, fileUuid, token) => {
  console.log("deleteFileFromProject called with:", { projectId, fileUuid });
  const response = await fetchWithToken(
    `${API_URL}/api/delete-file`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ project_id: projectId, file_uuid: fileUuid }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to delete file");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
