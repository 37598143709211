import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createGroup } from "../store/user_reducers/ManageGroups.js";
import { setGroups } from "../store/user.js";

const CreateGroupModal = ({ onClose }) => {
  const [groupName, setGroupName] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.user_id);
  const token = useSelector((state) => state.user.access_token);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!groupName.trim()) {
      setError("Group name cannot be empty");
      return;
    }

    try {
      const updatedGroups = await createGroup(groupName, userId, token);
      dispatch(setGroups(updatedGroups));
      onClose();
    } catch (error) {
      setError(error.message || "Failed to create group");
    }
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div
      className="modal-overlay"
      onClick={handleClose}
      role="dialog"
      aria-modal="true"
      aria-labelledby="group-modal-title"
    >
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        role="document"
      >
        <h2 id="group-modal-title" className="modal-title">
          Create New Group
        </h2>
        <form onSubmit={handleSubmit} aria-label="Create new group form">
          <input
            id="group-name"
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder="Enter group name"
            className="modal-content input"
            aria-invalid={error ? "true" : "false"}
            aria-describedby={error ? "group-error-message" : undefined}
            required
          />
          {error && (
            <p
              id="group-error-message"
              className="error-message"
              role="alert"
              aria-live="polite"
            >
              {error}
            </p>
          )}
          <div className="modal-buttons">
            <button
              type="button"
              onClick={handleClose}
              className="modal-buttons button"
              aria-label="Cancel group creation"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="modal-buttons button"
              aria-label="Create new group"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateGroupModal;
