import React from "react";

export const components = {
  a: ({ node, ...props }) => {
    const href = props.href || "";
    // Check if the link is an image
    const isImage = /\.(jpg|jpeg|png|gif|webp)(?:[?#].*)?$/i.test(href);

    if (isImage) {
      return <img src={href} alt={props.children} className="chat-image" />;
    }

    // Check if the link text is a single number
    const isSingleNumber = /^\d+$/.test(props.children[0]);
    if (isSingleNumber) {
      return (
        <sup className="chat-superscript">
          <a
            {...props}
            target="_blank"
            rel="noopener noreferrer"
            className="chat-link"
          />
        </sup>
      );
    }

    return (
      <a
        {...props}
        target="_blank"
        rel="noopener noreferrer"
        className="chat-link"
      />
    );
  },
  img: ({ node, ...props }) => {
    return <img {...props} className="chat-image" />;
  },
  h1: ({ node, ...props }) => <h1 {...props} className="chat-heading-1" />,
  h2: ({ node, ...props }) => <h2 {...props} className="chat-heading-2" />,
  h3: ({ node, ...props }) => <h3 {...props} className="chat-heading-3" />,
  h4: ({ node, ...props }) => <h4 {...props} className="chat-heading-4" />,
  h5: ({ node, ...props }) => <h5 {...props} className="chat-heading-5" />,
  h6: ({ node, ...props }) => <h6 {...props} className="chat-heading-6" />,
  span: ({ node, ...props }) => {
    if (props.className === "file-tag") {
      return (
        <span
          className="file-tag"
          data-file-tag={props["data-file-tag"]}
          style={{ display: "inline-flex" }}
        >
          {props.children}
        </span>
      );
    }
    return <span {...props} />;
  },
  p: ({ node, children }) => {
    // If this paragraph is inside a <li> (list item),
    // return a normal <p> so numbered/bulleted lists stay correct.
    if (node?.parent?.type === "listItem") {
      return <p>{children}</p>;
    } else {
      // Display paragraph text as block instead of inline to preserve newlines
      return <span style={{ display: "block" }}>{children}</span>;
    }
  },
};
