// Simple callback registry to store callbacks outside Redux
const callbacks = new Map();
let nextId = 1;

export const registerCallback = (callback) => {
  const id = `callback_${nextId++}`;
  callbacks.set(id, callback);
  return id;
};

export const executeCallback = (id) => {
  const callback = callbacks.get(id);
  if (callback) {
    callbacks.delete(id); // Clean up after execution
    return callback();
  }
  return null;
};

export const removeCallback = (id) => {
  callbacks.delete(id);
};
