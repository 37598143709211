// notebookThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCurrentProjectId } from "../project.js";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const createNewProject = createAsyncThunk(
  "projects/createNewProject",
  async ({ projectName }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const user_id = getState().user.user_id;
    const response = await createProject(projectName, user_id, token);
    dispatch(setCurrentProjectId(response.project_id));
    return response;
  }
);

export const createNewProjectReducer = {
  [createNewProject.fulfilled]: (state, action) => {
    state.projectList.unshift(action.payload);
    state.currentProjectId = action.payload.project_id;
    state.currentProject = action.payload;
  },
};

export const createProject = async (projectName, user_id, token) => {
  console.log("createProject called with:", { projectName, user_id });
  const response = await fetchWithToken(
    `${API_URL}/api/create-project`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_name: projectName,
        user_id: user_id,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to create project");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
