import React, { useState, useEffect, useRef } from "react";
import { updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/Modal.css";
import { managePythonFile } from "../store/project.js";
import { checkFilenameUnique } from "../api/files_api.js";

function PythonModal() {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.project);
  const currentProject = useSelector((state) => state.project.currentProject);
  const user = useSelector((state) => state.user.data);
  const token = useSelector((state) => state.user.access_token);

  // modal.data should contain { mode: 'create' | 'rename' | 'delete', fileId?: string, fileName?: string }
  const { mode, fileId, fileName } = modal.data || {};

  const titles = {
    create: "Create Python File",
    rename: "Rename Python File",
    delete: "Delete Python File",
  };

  useEffect(() => {
    if (mode === "rename" && fileName) {
      setInputValue(fileName);
    } else {
      setInputValue("");
    }
  }, [mode, fileName]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(updateModal({ name: "", data: null }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        dispatch(closeModal());
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch]);

  const handleSubmit = async () => {
    try {
      if (mode === "delete") {
        await dispatch(
          managePythonFile({
            projectId: currentProject.project_id,
            mode: "delete",
            fileId,
            fileName,
          })
        ).unwrap();
        dispatch(closeModal());
        return;
      }

      if (!inputValue.trim()) {
        setError("File name cannot be empty");
        return;
      }

      // Extract name before first period and ensure .py extension
      const nameBeforePeriod = inputValue.split(".")[0].trim();

      if (!nameBeforePeriod) {
        setError("File name cannot be empty");
        return;
      }

      // Add .py extension
      const finalFileName = `${nameBeforePeriod}.py`;

      // Check if filename is unique
      const isUnique = await checkFilenameUnique(
        user.user_id,
        finalFileName,
        token
      );

      if (
        !isUnique &&
        (mode === "create" || (mode === "rename" && finalFileName !== fileName))
      ) {
        setError("A Python file with this name already exists");
        return;
      }

      await dispatch(
        managePythonFile({
          projectId: currentProject.project_id,
          mode,
          fileName: finalFileName,
          fileId,
        })
      ).unwrap();

      dispatch(closeModal());
    } catch (error) {
      console.error("Error managing Python file:", error);
      setError(
        error.message || "An error occurred while managing the Python file"
      );
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={(e) => e.stopPropagation()}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="modal-content" ref={modalRef}>
        <h2 className="modal-title" id="modal-title">
          {titles[mode]}
        </h2>
        {error && (
          <div className="modal-error-message" role="alert" aria-live="polite">
            {error}
          </div>
        )}
        {mode === "delete" ? (
          <div className="modal-delete-confirmation">
            <p>Are you sure you want to delete the Python file "{fileName}"?</p>
            <p>This action cannot be undone.</p>
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            aria-label="Python file form"
          >
            <input
              id="file-name-input"
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={
                mode === "create" ? "Enter file name" : "Enter new name"
              }
              className="modal-input"
              aria-invalid={!!error}
              aria-describedby={error ? "name-error" : undefined}
            />
          </form>
        )}
        <div className="modal-buttons">
          <button
            type="button"
            onClick={() => dispatch(closeModal())}
            className="modal-cancel-button"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleSubmit}
            className={`modal-submit-button ${
              mode === "delete" ? "delete" : ""
            }`}
          >
            {mode === "delete" ? "Delete" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PythonModal;
