import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import "../style/Tools.css";
import ToolPage from "./ToolPage.js";
import {
  updateAccessToken,
  fetchUser,
  setUserStatus,
  setInitialized,
} from "../store/user.js";
import { fetchProjects } from "../store/project.js";
import FileUploadModal from "./FileUploadModal.js";

const toolsData = [
  {
    id: "annotation",
    title: "Sequence Annotation",
    description:
      "Annotate sequences from your library against public and private sequence repositories",
  },
  {
    id: "sequence_finder",
    title: "Sequence Finder",
    description:
      "Search and discover sequences across multiple databases using natural language prompts",
  },
  {
    id: "dna_analyzer",
    title: "DNA Complexity Analyzer",
    description:
      "Evaluate sequence complexity and identify potential synthesis challenges",
  },
  {
    id: "synthesis_calculator",
    title: "Synthesis Success Predictor",
    description:
      "Calculate probability of successful DNA synthesis and get cost estimates",
  },
  {
    id: "primer_designer",
    title: "Smart Primer Designer",
    description:
      "Design optimal primers with custom extensions for your PCR experiments",
    // input: sequences, left/right extensions, text/files, natural language prompt for goal
    // output: primer table with sequence, length, tm, order form
  },
  {
    id: "digest_visualizer",
    title: "Restriction Analysis",
    description:
      "Visualize restriction digests and calculate fragment patterns",
    // input: sequences, restriction enzymes, ladders (+custom), enzyme dropdown
    // output: table with fragment lengths, gel visualization
  },
  {
    id: "material_locator",
    title: "Material Locator",
    description:
      "Quickly locate reagents and materials in your lab inventory system",
    // input: natural language prompt for goal
    // output: table with reagents and locations
  },
  {
    id: "sequence_aligner",
    title: "Sequence Alignment",
    description: "Perform advanced sequence alignments with smart verification",
    comingSoon: true,
    // https://www.npmjs.com/package/react-msa-viewer?activeTab=readme
  },
  {
    id: "sequence_optimizer",
    title: "Sequence Optimizer",
    description:
      "Optimize codons, RBS, promoters and coding sequences for your host organism",
    comingSoon: true,
    // input: sequences, natural language prompt for goal
    // output: new sequences, metrics table, summary
  },

  {
    id: "mutagenesis",
    title: "Mutagenesis Planner",
    description:
      "Design site-directed mutagenesis experiments and library construction",
    comingSoon: true,
    // input: sequences, mutation list
    // output: new sequences file, primer file, reaction map of pcrs
  },
  {
    id: "library_designer",
    title: "Library Designer",
    description: "Design oligo tiling and DNA library construction strategies",
    comingSoon: true,
    // input: sequence files
    // output: files for ordering, tiling
  },
  {
    id: "cloning_assistant",
    title: "Cloning Agent",
    description:
      "Generate a cloning strategy in one command from a single picture, prompt, or sequence file using the sequences in your library and specified public databases",
    comingSoon: true,
  },
  {
    id: "dna_designer",
    title: "DNA Design Agent",
    description:
      "Generate a DNA design in one command from a single picture, prompt, or sequence file using the sequences in your library and specified public databases",
    comingSoon: true,
  },
  {
    id: "research_tracker",
    title: "Research Agent",
    description:
      "Have LabKick track, summarize, and send relevant papers to you based upon your commands and relevant lab notebook entries",
    comingSoon: true,
  },
];

function Tools() {
  const navigate = useNavigate();
  const { toolId } = useParams();
  const [selectedTool, setSelectedTool] = useState(null);
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
    useAuth0();
  const isInitialized = useSelector((state) => state.user.isInitialized);
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.project.modal);

  useEffect(() => {
    const initializeTools = async () => {
      if (!isInitialized) {
        try {
          const accessToken = await getAccessTokenSilently();
          dispatch(updateAccessToken(accessToken));
          const result = await dispatch(
            fetchUser({ token: accessToken, user_auth0: user })
          );

          if (result?.payload?.data?.data?.user_id) {
            dispatch(fetchProjects(result.payload.data.data.user_id));
            dispatch(setInitialized(true));
          }
        } catch (error) {
          console.error("Error initializing Tools:", error);
        }
      }
    };

    if (isAuthenticated && !isInitialized) {
      initializeTools();
    } else if (!isAuthenticated && !isLoading) {
      dispatch(setUserStatus("unauthenticated"));
      navigate("/");
    }
  }, [isAuthenticated, isLoading, isInitialized]);

  useEffect(() => {
    if (toolId) {
      const tool = toolsData.find((t) => t.id.toString() === toolId);
      if (tool) {
        setSelectedTool(tool);
      } else {
        navigate("/tools");
      }
    } else {
      setSelectedTool(null);
    }
  }, [toolId, navigate]);

  const handleToolClick = (tool) => {
    navigate(`/tools/${tool.id}`);
  };

  const handleBack = () => {
    navigate("/tools");
  };

  if (isLoading) {
    return (
      <div className="initial-loading-overlay" role="alert" aria-busy="true">
        <div className="loading-spinner" aria-label="Loading content"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  if (selectedTool) {
    return (
      <>
        <ToolPage tool={selectedTool} onBack={handleBack} />
        {(modal.name === "upload_general" ||
          modal.name === "upload_project") && <FileUploadModal />}
      </>
    );
  }

  return (
    <>
      <div className="tools-container">
        <h1 className="tools-title">Lab Tools</h1>
        <div className="tools-grid">
          {toolsData
            .filter((tool) => !tool.comingSoon)
            .map((tool) => (
              <div
                key={tool.id}
                className="tool-card"
                onClick={() => handleToolClick(tool)}
                role="button"
                tabIndex={0}
              >
                <div className="tool-content">
                  <div className="tool-header">
                    <h3>{tool.title}</h3>
                    <span className="beta-tag">BETA</span>
                  </div>
                  <p className="tool-description">{tool.description}</p>
                </div>
              </div>
            ))}
        </div>

        <h1 className="tools-title tools-title-upcoming">In Development</h1>
        <div className="tools-grid">
          {toolsData
            .filter((tool) => tool.comingSoon)
            .map((tool) => (
              <div
                key={tool.id}
                className="tool-card coming-soon"
                role="button"
                tabIndex={-1}
              >
                <div className="tool-content">
                  <h3>{tool.title}</h3>
                  <p className="tool-description">{tool.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>

      {(modal.name === "upload_general" || modal.name === "upload_project") && (
        <FileUploadModal is_modal={true} />
      )}
    </>
  );
}

export default Tools;
