import React from "react";
import { IoClose } from "react-icons/io5";
import "../style/FileTab.css";
import { FILE_TYPE_ICONS } from "../store/project.js";

function FileTab({
  fileName,
  fileId,
  onClose,
  isActive,
  onClick,
  fileType = "default",
}) {
  const icon = FILE_TYPE_ICONS[fileType] || FILE_TYPE_ICONS.default;

  return (
    <>
      <span className="ft-tab-icon">{icon}</span>
      <span className="ft-tab-name">{fileName}</span>
      <button
        className="ft-tab-close"
        onClick={(e) => {
          e.stopPropagation();
          onClose(fileId);
        }}
        aria-label={`Close ${fileName}`}
      >
        <IoClose />
      </button>
    </>
  );
}

export default FileTab;
