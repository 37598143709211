import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

import { updateProjectList } from "../project.js";
import { setCurrentProjectId } from "../project.js";
import { fetchChat } from "../project.js";
import { updateNotifications } from "../user.js";

export const loadProjectFromNotification = createAsyncThunk(
  "projects/loadProjectFromNotification",
  async (projectId, { getState, dispatch }) => {
    // Wait until we have a valid token and user
    const state = getState();
    if (
      !state.user.access_token ||
      !state.user.user_id ||
      state.user.isCheckingAuth
    ) {
      throw new Error("Not authenticated");
    }

    const token = state.user.access_token;
    const userId = state.user.user_id;

    try {
      const response = await loadProjectFromUrl(projectId, userId, token);

      // Update projects list with properly formatted data
      if (response.projects?.projects) {
        const formattedProjects = response.projects.projects.map((project) => ({
          ...project,
          files: project.files || {},
          user_id: response.projects.user_id,
        }));

        dispatch(updateProjectList(formattedProjects));
      }

      // Set current project
      if (response.current_project_id) {
        dispatch(setCurrentProjectId(response.current_project_id));
        dispatch(fetchChat(response.current_project_id));
      }

      // Clean up notifications related to this project
      const notifications = state.user.notifications_unread;
      const dismissedNotifications = state.user.notifications_dismissed;

      // Filter out notifications containing this project ID in their action URL
      const updatedUnread = notifications.filter(
        (notification) =>
          !notification.actionUrl?.includes(`/project/${projectId}`)
      );

      // Move filtered notifications to dismissed
      const newDismissed = [
        ...dismissedNotifications,
        ...notifications.filter((notification) =>
          notification.actionUrl?.includes(`/project/${projectId}`)
        ),
      ];

      // Update notifications in the backend
      await dispatch(
        updateNotifications({
          unread: updatedUnread,
          dismissed: newDismissed,
        })
      );

      return response;
    } catch (error) {
      console.error("Error loading project from notification:", error);
      throw error;
    }
  }
);

export const loadProjectFromUrl = async (projectId, userId, token) => {
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/load-project-url`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          project_id: projectId,
          user_id: userId,
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to load project from URL");
    }

    const result = await response.json();

    console.log("loadProjectFromUrl result", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error loading project from URL:", error);
    throw error;
  }
};
