// Helper function to check if a tool call should be initially hidden
export const isToolCallHidden = (callId, functionName, hiddenToolCalls) => {
  // Create a new Set if hiddenToolCalls is undefined/null
  const toolCalls =
    hiddenToolCalls instanceof Set ? hiddenToolCalls : new Set();

  // If the call has been manually toggled, use the toggled state
  if (toolCalls.has(callId)) {
    return true;
  } else if (toolCalls.has(`shown_${callId}`)) {
    return false;
  }

  // Otherwise use default state - hide everything except edit_dna_features
  // return functionName !== "edit_dna_features";
  return true;
};

// Mapping objects
export const fileIdentifierMapping = {
  configure_design: "design_name",
  analyze_design: "design_name",
  edit_dna_features: "filename",
  update_design_parts: "design_name",
  update_design_bins: "design_name",
  edit_dna_file: "filename",
  simulate_reaction: "filename",
  create_dna_file: "filename",
};

export const displayNameMapping = {
  // annotation_helpers
  sanitize_sequence_id: "Sanitize Sequence ID",
  find_feature_indices_by_name: "Find Feature Indices by Name",
  convert_qualifiers_to_dict: "Convert Qualifiers to Dict",
  extract_sequence_by_annotation: "Extract Sequence by Annotation",
  // bin_tools
  add_bin_to_design: "Add Bin to Design",
  remove_bin_from_design: "Remove Bin from Design",
  reorder_bins_in_design: "Reorder Bins in Design",
  update_design_bins: "Update Design Bins",
  // context_helpers
  load_seqrecord_from_filename: "Load Seqrecord from Filename",
  load_and_validate_sequence: "Load and Validate Sequence",
  get_info: "Get Info",
  get_file_id_from_name: "Get File ID from Name",
  // design_helpers
  load_design_by_name: "Load Design by Name",
  // design_tools
  create_design: "Create Design",
  edit_design: "Edit Design",
  generate_instructions: "Generate Instructions",
  configure_design: "Configure Design",
  analyze_design: "Analyze Design",
  // feature_tools
  create_feature: "Create Feature",
  update_feature: "Update Feature",
  delete_feature: "Delete Feature",
  edit_dna_features: "Edit DNA Features",
  // file_operation_helpers
  create_and_save_user_file_with_project:
    "Create and Save User File with Project",
  // file_tools
  duplicate_file: "Duplicate File",
  create_file_from_feature: "Create File from Feature",
  create_file_from_pasted_sequence: "Create File from Pasted Sequence",
  create_file_from_index_range: "Create File from Index Range",
  split_sequences: "Split Sequences",
  create_dna_file: "Create DNA File",
  // handoffs
  format_response: "Format Response",
//   complete: "Complete",
  need_more_information: "Need More Information",
  answer_question: "Answer Question",
//   handoff_to: "Handoff To",
//   summarize_results_for_support_agent: "Summarize Results for Support Agent",
//   handoff_to_dna_assembly_agent: "Handoff to DNA Assembly Agent",
//   handoff_to_seq_analysis_agent: "Handoff to Seq Analysis Agent",
//   handoff_to_search_agent: "Handoff to Search Agent",
//   handoff_to_dna_part_agent: "Handoff to DNA Part Agent",
//   handoff_to_dna_agent: "Handoff to DNA Agent",
  // part_tools
  add_part_to_design: "Add Part to Design",
  update_part_in_design: "Update Part in Design",
  remove_part_from_design: "Remove Part from Design",
  update_design_parts: "Update Design Parts",
  // persistence_helpers
  add_file_to_project: "Add File to Project",
  get_parent_file: "Get Parent File",
  copy_file_to_s3: "Copy File to S3",
  save_sequence_record: "Save Sequence Record",
  // reaction_tools
  simulate_digest: "Simulate Digest",
  simulate_pcr: "Simulate PCR",
  simulate_reaction: "Simulate Reaction",
  // sequence_helpers
  extract_sequence: "Extract Sequence",
  process_inverse_feature_extraction: "Process Inverse Feature Extraction",
  // sequence_tools
  transform_sequence: "Transform Sequence",
  edit_sequence: "Edit Sequence",
  edit_metadata: "Edit Metadata",
  edit_dna_file: "Edit DNA File",
};

export const extractFilename = (args) => {
  try {
    if (typeof args === "string") {
      args = JSON.parse(args);
    }

    // Get the function name from the context if available
    const functionName = args.function_name || args.tool_name;

    // Special handling for configure_design's nested structure
    if (functionName === "configure_design") {
      if (args.request?.design_name) {
        return args.request.design_name;
      }
      // Handle deeply nested request structure
      if (args.request?.request?.design_name) {
        return args.request.request.design_name;
      }
    }

    // Determine which key to use for the file identifier
    const fileKey = fileIdentifierMapping[functionName] || "filename";

    // Try all possible paths to find the identifier
    const paths = [
      args[fileKey],
      args.request?.[fileKey],
      args.request?.request?.[fileKey],
      args.filename,
      args.design_name,
      args.request?.filename,
      args.request?.design_name,
      args.request?.request?.filename,
      args.request?.request?.design_name,
    ];

    // Return the first valid path
    for (const path of paths) {
      if (path) return path;
    }
  } catch (e) {
    // If JSON parsing fails, try multiple regex patterns
    const patterns = [
      /"design_name":\s*"([^"]+)"/,
      /'design_name':\s*'([^']+)'/,
      /"filename":\s*"([^"]+)"/,
      /'filename':\s*'([^']+)'/,
      /design_name["']?\s*:\s*["']([^"']+)["']/i,
      /filename["']?\s*:\s*["']([^"']+)["']/i,
    ];

    const text = typeof args === "string" ? args : JSON.stringify(args);

    for (const pattern of patterns) {
      const match = text.match(pattern);
      if (match) return match[1];
    }
  }
  return null;
};

export const getDisplayName = (functionName) => {
  return displayNameMapping[functionName] || functionName;
};

export const handleToggle = (
  callId,
  isCurrentlyHidden,
  hiddenToolCalls,
  setHiddenToolCalls
) => {
  // Create a new Set if hiddenToolCalls is undefined/null
  const toolCalls =
    hiddenToolCalls instanceof Set ? hiddenToolCalls : new Set();
  const newHiddenToolCalls = new Set(toolCalls);

  if (isCurrentlyHidden) {
    // Remove both possible states
    newHiddenToolCalls.delete(callId);
    newHiddenToolCalls.add(`shown_${callId}`);
  } else {
    // Remove shown state and add hidden state
    newHiddenToolCalls.delete(`shown_${callId}`);
    newHiddenToolCalls.add(callId);
  }
  setHiddenToolCalls(newHiddenToolCalls);
};

export const shouldShowFileActions = (
  filename,
  messages,
  messageIndex,
  currentProject,
  chatType
) => {
  if (!currentProject?.files) return false;

  // For agent chat type, keep existing behavior of showing actions on latest message
  if (chatType === "agent") {
    const aiMessages = messages.chat_contents.filter(
      (m) => m.role === "assistant"
    );
    const isLatestAiMessage = messageIndex === aiMessages.length - 1;
    if (!isLatestAiMessage) return false;
  }

  // Find the file ID where the filename matches
  const fileId = Object.keys(currentProject.files).find(
    (id) => currentProject.files[id].file_name === filename
  );

  if (!fileId) return false;

  const file = currentProject.files[fileId];

  // Check if there's an edit version
  const hasEdits = file.s3_url?.includes("_edit.");
  if (!hasEdits) return false;

  // For chat type, show actions on the message that was applied
  if (chatType === "chat") {
    return file.applied_message_index === messageIndex;
  }

  return true;
};
