import React, { useState, useEffect, useRef } from "react";
import "../style/PopUpChat.css";
import ChatInput from "./ChatInput.js";
import ChatMessages from "./ChatMessages.js";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPopUpMessages,
  setPopupChatOpen,
  setIsMessageSending,
} from "../store/project.js";
import { deletePopupChat } from "../api/project_api.js";
import { withEditStateCheck } from "../utils/editStateWrapper.js";

function PopUpChat({ isOpen, onClose, position, onPositionChange }) {
  const [adjustedPosition, setAdjustedPosition] = useState(null);
  const containerRef = useRef(null);
  const dragStartRef = useRef(null);
  const hasBeenDragged = useRef(false);
  const dispatch = useDispatch();

  // Get popup messages from Redux instead of local state
  const popUpMessages = useSelector((state) => state.project.popUpChatMessages);

  // Get access token and project ID from Redux
  const token = useSelector((state) => state.user.access_token);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );

  // Add this to your existing selectors
  const isResponseLoading = useSelector((state) => state.project.isLoading);

  // Add isMessageSending to your selectors
  const isMessageSending = useSelector(
    (state) => state.project.isMessageSending
  );

  // Reset messages when popup is closed
  useEffect(() => {
    if (!isOpen) {
      dispatch(clearPopUpMessages());
      hasBeenDragged.current = false;
    }
  }, [isOpen, dispatch]);

  // Update local position when prop changes, but only if not dragged
  useEffect(() => {
    if (!isOpen) return; // Don't calculate position when closed
    if (hasBeenDragged.current) return; // Skip if dragged

    const TOP_MARGIN = 50;
    const BOTTOM_MARGIN = 10;
    const SIDE_MARGIN = 20;
    const popupHeight = popUpMessages.length > 0 ? 500 : 100;
    const popupWidth = 400;

    const maxY = window.innerHeight - BOTTOM_MARGIN - popupHeight;
    const maxX = window.innerWidth - SIDE_MARGIN - popupWidth;

    const newPosition = {
      x: Math.max(SIDE_MARGIN, Math.min(position.x, maxX)),
      y: Math.max(TOP_MARGIN, Math.min(position.y, maxY)),
    };

    setAdjustedPosition(newPosition);
  }, [position, popUpMessages.length, isOpen]);

  // Update Redux when popup opens/closes
  useEffect(() => {
    // Only update popup open state, don't clear highlighted text
    dispatch(setPopupChatOpen(isOpen));

    // Clean up when component unmounts
    return () => {
      dispatch(setPopupChatOpen(false));
    };
  }, [isOpen, dispatch]);

  const handleMouseDown = (e) => {
    // Don't initiate drag if clicking on input, messages, or close button
    if (
      e.target.closest(".popup-ephemeral-input") ||
      e.target.closest(".popup-ephemeral-messages") ||
      e.target.closest(".popup-ephemeral-close")
    ) {
      return;
    }

    // Prevent text selection during drag
    e.preventDefault();

    dragStartRef.current = {
      mouseX: e.clientX,
      mouseY: e.clientY,
      startLeft: adjustedPosition.x,
      startTop: adjustedPosition.y,
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!dragStartRef.current) return;
    hasBeenDragged.current = true; // Mark as dragged

    const deltaX = e.clientX - dragStartRef.current.mouseX;
    const deltaY = e.clientY - dragStartRef.current.mouseY;

    const newX = dragStartRef.current.startLeft + deltaX;
    const newY = dragStartRef.current.startTop + deltaY;

    // Update position with viewport bounds check
    const TOP_MARGIN = 50;
    const BOTTOM_MARGIN = 10;
    const SIDE_MARGIN = 20;
    const popupWidth = 400;
    const maxX = window.innerWidth - SIDE_MARGIN - popupWidth;
    const maxY =
      window.innerHeight -
      BOTTOM_MARGIN -
      (popUpMessages.length > 0 ? 500 : 100);

    setAdjustedPosition({
      x: Math.max(SIDE_MARGIN, Math.min(newX, maxX)),
      y: Math.max(TOP_MARGIN, Math.min(newY, maxY)),
    });
  };

  const handleMouseUp = () => {
    if (dragStartRef.current) {
      // Notify parent of the new position
      onPositionChange?.(adjustedPosition);
    }
    dragStartRef.current = null;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Handle popup close
  const handleClose = async () => {
    try {
      // Wrap the close operation in withEditStateCheck
      dispatch(
        withEditStateCheck(async () => {
          // Call the API to delete popup chat
          await deletePopupChat(currentProjectId, token);
          // Call the original onClose handler
          onClose();
        }, currentProjectId)
      );
    } catch (error) {
      console.error("Error deleting popup chat:", error);
      // Still close the popup even if API call fails
      onClose();
    }
  };

  // Don't render until we have a position and the popup should be open
  if (!isOpen || adjustedPosition === null) return null;

  return (
    <div
      ref={containerRef}
      className={`popup-ephemeral-container ${
        popUpMessages.length > 0 ? "with-messages" : ""
      }`}
      style={{
        position: "fixed",
        visibility: adjustedPosition ? "visible" : "hidden",
        left: adjustedPosition?.x ?? 0,
        top: adjustedPosition?.y ?? 0,
        zIndex: 1000,
        maxHeight: popUpMessages.length > 0 ? "500px" : "auto",
      }}
      onMouseDown={handleMouseDown}
    >
      <div className="popup-drag-handle">
        <button
          className="popup-ephemeral-close"
          onClick={handleClose}
          disabled={isMessageSending || isResponseLoading}
        >
          ×
        </button>
      </div>

      <div className="popup-ephemeral-content">
        {popUpMessages.length > 0 && (
          <div className="popup-ephemeral-messages">
            <ChatMessages externalMessages={popUpMessages} chatType="popup" />
          </div>
        )}

        <div className="popup-ephemeral-input">
          <ChatInput useEphemeral={true} chatType="popup" />
        </div>
      </div>
    </div>
  );
}

export default PopUpChat;
