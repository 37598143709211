import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateUserFile } from "../user.js";

export const renameFileGlobal = createAsyncThunk(
  "projects/renameFileGlobal",
  async ({ fileId, newName, oldName, userId }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const response = await renameFile(fileId, newName, oldName, userId, token);

    // Update the file in user.files state
    dispatch(
      updateUserFile({
        fileId,
        updatedFile: {
          ...getState().user.files[fileId],
          file_name: newName,
          file_name_lower: newName.toLowerCase(),
        },
      })
    );

    return { fileId, newName, response };
  }
);

export const renameFileGlobalReducer = {
  [renameFileGlobal.fulfilled]: (state, action) => {
    const { fileId, newName } = action.payload;

    // Update all files in projectList with null checks
    state.projectList = state.projectList.map((p) => {
      if (p?.files && p.files[fileId]) {
        return {
          ...p,
          files: {
            ...p.files,
            [fileId]: {
              ...p.files[fileId],
              file_name: newName,
              file_name_lower: newName.toLowerCase(),
            },
          },
        };
      }
      return p;
    });

    // Update currentProject if it exists and contains the file
    if (state.currentProject?.files && state.currentProject.files[fileId]) {
      state.currentProject = {
        ...state.currentProject,
        files: {
          ...state.currentProject.files,
          [fileId]: {
            ...state.currentProject.files[fileId],
            file_name: newName,
            file_name_lower: newName.toLowerCase(),
          },
        },
      };
    }
  },
};

export const renameFile = async (fileId, newName, oldName, userId, token) => {
  console.log("renameFile called with:", { fileId, newName, oldName, userId });
  const response = await fetchWithToken(
    `${API_URL}/api/rename-file`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_id: fileId,
        new_name: newName,
        old_name: oldName,
        user_id: userId,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to rename file");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
