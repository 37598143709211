import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const fetchNextChats = createAsyncThunk(
  "projects/fetchNextChats",
  async ({ chatType }, { getState }) => {
    const token = getState().user.access_token;
    const state = getState();
    const project = state.project.currentProject;
    const nextToken = project[`${chatType}_chat_next_token`];
    const projectId = state.project.currentProjectId;

    try {
      const response = await fetchNextChatBatch(
        token,
        projectId,
        chatType,
        nextToken
      );
      return { chatType, ...response };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchNextChatBatch = async (
  token,
  projectId,
  chatType,
  nextToken
) => {
  const endpoint = `${API_URL}/api/fetch-next-chat-batch`;

  const response = await fetchWithToken(
    endpoint,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        chat_type: chatType,
        next_token: nextToken,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to fetch next chat batch");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};

export const fetchNextChatsReducer = {
  [fetchNextChats.pending]: (state) => {
    state.isLoadingMoreChats = true;
    state.error = null;
  },
  [fetchNextChats.fulfilled]: (state, action) => {
    const { chatType, chats, next_token } = action.payload;

    if (state.currentProject) {
      // Update the chat files in currentProject
      state.currentProject[`${chatType}_chat_files`] = {
        ...state.currentProject[`${chatType}_chat_files`],
        ...chats,
      };

      // Update the next token in currentProject
      state.currentProject[`${chatType}_chat_next_token`] = next_token;
    }

    // Also update the chat files in the state root (if needed)

    // Update the next token in state root
    state[`${chatType}_chat_next_token`] = next_token;
    state.isLoadingMoreChats = false;
  },
  [fetchNextChats.rejected]: (state, action) => {
    state.error = action.error.message;
    state.isLoadingMoreChats = false;
  },
};
