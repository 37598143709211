import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const deleteFileFromLibrary = createAsyncThunk(
  "user/deleteFileFromLibrary",
  async ({ fileIds, fileNames, userId }, { getState }) => {
    const token = getState().user.access_token;
    console.log("files:", getState().user.files);
    const results = await deleteFilePermanent(
      fileIds,
      fileNames,
      userId,
      token
    );
    return results;
  }
);

export const deleteFileFromLibraryReducer = {
  [deleteFileFromLibrary.pending]: (state) => {
    state.status = "loading";
  },
  [deleteFileFromLibrary.fulfilled]: (state, action) => {
    state.status = "succeeded";
    action.payload.forEach((result) => {
      if (result.success) {
        delete state.data.files[result.fileId];
        delete state.files[result.fileId];
      }
    });
  },
};

export const deleteFilePermanent = async (
  fileIds,
  fileNames,
  userId,
  token
) => {
  console.log("deleteFilePermanent called with:", {
    fileIds,
    fileNames,
    userId,
    token,
  });

  const results = [];
  for (let i = 0; i < fileIds.length; i++) {
    try {
      const response = await fetchWithToken(
        `${API_URL}/api/delete-file-permanent`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            file_id: fileIds[i],
            file_name: fileNames[i],
            user_id: userId,
          }),
        },
        token
      );

      if (!response.ok) {
        throw new Error(`Failed to delete file ${fileNames[i]} permanently`);
      }

      const result = await response.json();
      if (result.status === "success") {
        results.push({ fileId: fileIds[i], success: true });
      } else {
        results.push({
          fileId: fileIds[i],
          success: false,
          message: result.message,
        });
      }
    } catch (error) {
      console.error(`Error deleting file ${fileNames[i]}:`, error);
      results.push({
        fileId: fileIds[i],
        success: false,
        message: error.message,
      });
    }
  }

  return results;
};
