import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../api/api.js";

export const fetchCredential = createAsyncThunk(
  "user/fetchCredential",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCredential();
      console.log("Credential fetched:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching credential:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchCredentialReducer = {
  [fetchCredential.pending]: (state) => {
    state.isCheckingAuth = true;
  },
  [fetchCredential.fulfilled]: (state, action) => {
    state.isCheckingAuth = false;
    console.log("Fetching credential: fulfilled", action.payload);
    state.credential = action.payload.credential;
    state.login_provider = action.payload.login_provider;
    state.access_token = action.payload.access_token;
  },
  [fetchCredential.rejected]: (state, action) => {
    state.isCheckingAuth = false;
    console.log("Fetching credential: rejected", action.payload);
    state.error = action.payload;
  },
};

export const getCredential = async () => {
  try {
    console.log("getCredential called");
    const response = await fetch(`${API_URL}/api/get-credential`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch credential");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in getCredential:", error);
    return null;
  }
};
