import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const submitOrder = createAsyncThunk(
  "projects/submitOrder",
  async ({ projectId, orderData, sequenceVerify }, { getState }) => {
    const token = getState().user.access_token;
    const response = await submitOrderRequest(
      projectId,
      orderData,
      sequenceVerify,
      token
    );
    return response;
  }
);

export const submitOrderReducer = {
  [submitOrder.fulfilled]: (state, action) => {
    // Optional: Add any state updates needed
  },
  [submitOrder.rejected]: (state, action) => {
    console.error("Failed to submit order:", action.error);
  },
};

export const submitOrderRequest = async (
  projectId,
  orderData,
  sequenceVerify,
  token
) => {
  console.log("submitOrderRequest called with:", {
    projectId,
    orderData,
    sequenceVerify,
  });

  const response = await fetchWithToken(
    `${API_URL}/api/submit-order-request`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        order_data: orderData,
        sequence_verify: sequenceVerify,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to submit order request");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
