import React, { useState, useEffect, useMemo } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector, useDispatch } from "react-redux";
import "../style/PaperViewer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { setNotebookHighlightedText } from "../store/project.js";

// Move worker initialization outside component
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Add these options outside component
const pdfOptions = {
  cMapUrl: "https://unpkg.com/pdfjs-dist@3.4.120/cmaps/",
  cMapPacked: true,
};

function PaperViewer() {
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [selectedText, setSelectedText] = useState("");
  const [highlightPosition, setHighlightPosition] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showZoomControls, setShowZoomControls] = useState(false);
  const [isWorkerLoaded, setIsWorkerLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [renderedPages, setRenderedPages] = useState(new Set());

  // Get states from Redux
  const artifact = useSelector((state) => state.project.artifact);
  const isPopupOpen = useSelector((state) => state.project.isPopupChatOpen);
  const notebookHighlightedText = useSelector(
    (state) => state.project.notebookHighlightedText
  );

  // Modify the getBaseUrl helper function to handle invalid URLs
  const getBaseUrl = (url) => {
    if (!url || typeof url !== "string") return null;
    const questionMarkIndex = url.indexOf("?");
    return questionMarkIndex !== -1 ? url.substring(0, questionMarkIndex) : url;
  };

  const fetchPdfData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      // Create a fresh copy of the blob using slice(0)
      const freshBlob = blob.slice(0);
      setPdfData(freshBlob);
    } catch (error) {
      console.error("Error fetching PDF:", error);
      setPdfData(null);
    }
  };

  // Modify the useEffect that handles artifact changes
  useEffect(() => {
    if (artifact?.content && isWorkerLoaded) {
      const newBaseUrl = getBaseUrl(artifact.content);
      const currentBaseUrl = getBaseUrl(pdfUrl);

      // Only update if the base URL has changed
      if (newBaseUrl !== currentBaseUrl) {
        setIsLoading(true);
        setPdfUrl(artifact.content);
        fetchPdfData(artifact.content);
      }
    }
  }, [artifact, isWorkerLoaded, pdfUrl]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.metaKey || e.ctrlKey) && e.key === "k") {
        e.preventDefault();
        const selection = window.getSelection();
        if (selection && selection.toString()) {
          const range = selection.getRangeAt(0);
          const rect = range.getBoundingClientRect();

          dispatch(
            setNotebookHighlightedText({
              text: selection.toString(),
              type: "pdf",
              fullText: selection.toString(),
              start: 0,
              end: 0,
            })
          );
          setHighlightPosition({
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
          });
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [dispatch]);

  // Clear highlight when popup closes
  useEffect(() => {
    if (!isPopupOpen) {
      setHighlightPosition(null);
      dispatch(setNotebookHighlightedText(null));
    }
  }, [isPopupOpen, dispatch]);

  // Add intersection observer to track visible pages
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
            const pageId = entry.target.id;
            const pageNumber = parseInt(pageId.split("_")[1]);
            setCurrentPage(pageNumber);
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    // Observe all pages
    document.querySelectorAll(".pv-page").forEach((page) => {
      observer.observe(page);
    });

    return () => observer.disconnect();
  }, [numPages]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setRenderedPages(new Set());
  };

  const onPageRenderSuccess = (pageNumber) => {
    setRenderedPages((prev) => {
      const newSet = new Set(prev);
      newSet.add(pageNumber);
      if (newSet.size === numPages) {
        setIsLoading(false);
      }
      return newSet;
    });
  };

  const onDocumentLoadError = (error) => {
    console.error("Error loading PDF:", error);
    setIsLoading(false);
  };

  const handleMouseUp = () => {
    const selection = window.getSelection().toString();
    if (selection) {
      dispatch(
        setNotebookHighlightedText({
          text: selection,
          type: "pdf",
          fullText: selection,
          start: 0,
          end: 0,
        })
      );
      setHighlightPosition(null);
    } else {
      dispatch(setNotebookHighlightedText(null));
      setHighlightPosition(null);
    }
  };

  // Scroll handler for thumbnail
  const scrollToPage = (pageNumber) => {
    const pages = document.querySelectorAll(".pv-page");
    if (pages && pages.length >= pageNumber) {
      const targetPage = pages[pageNumber - 1];
      targetPage.scrollIntoView({ behavior: "smooth", block: "start" });
      setCurrentPage(pageNumber);
    }
  };

  // Add zoom handlers
  const handleZoomIn = () => {
    setScale((prev) => Math.min(2.0, prev + 0.1));
  };

  const handleZoomOut = () => {
    setScale((prev) => Math.max(0.5, prev - 0.1));
  };

  // Add mouse position tracking for zoom controls
  const handleMouseMove = (e) => {
    const container = e.currentTarget;
    const rect = container.getBoundingClientRect();
    const mouseY = e.clientY - rect.top;
    const mouseX = e.clientX - rect.left;

    // Show controls when mouse is in bottom center area
    const bottomThreshold = rect.height - 100;
    const centerMin = rect.width * 0.4;
    const centerMax = rect.width * 0.6;

    setShowZoomControls(
      mouseY > bottomThreshold && mouseX > centerMin && mouseX < centerMax
    );
  };

  // Memoize the pages array
  const pagesArray = useMemo(() => {
    return Array.from(new Array(numPages), (_, index) => index + 1);
  }, [numPages]);

  // Memoize the thumbnail pages
  const thumbnailPages = useMemo(() => {
    if (!numPages) return null;
    return pagesArray.map((index) => (
      <div
        key={`thumb_${index}`}
        className={`pv-thumbnail ${currentPage === index ? "active" : ""}`}
        onClick={() => scrollToPage(index)}
      >
        <div className="pv-thumbnail-page-wrapper">
          <Page
            key={`thumb_page_${index}`}
            pageNumber={index}
            scale={0.2}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            loading={null}
            options={pdfOptions}
            error={<div className="pv-error">Error loading page.</div>}
          />
        </div>
        <div className="pv-thumbnail-number">{index}</div>
      </div>
    ));
  }, [numPages, currentPage, scrollToPage, pdfOptions]);

  // Memoize the main document pages
  const documentPages = useMemo(() => {
    if (!numPages) return null;
    return pagesArray.map((index) => (
      <Page
        key={`page_${index}`}
        id={`page_${index}`}
        pageNumber={index}
        scale={scale}
        renderTextLayer={true}
        renderAnnotationLayer={false}
        className="pv-page"
        loading={null}
        options={pdfOptions}
        onRenderSuccess={() => onPageRenderSuccess(index)}
        textLayerProps={{
          isEnabled: true,
          shouldEnableTextSelection: true,
          className: "textLayer",
          customTextRenderer: ({ str }) => str,
        }}
      />
    ));
  }, [numPages, scale, onPageRenderSuccess, pdfOptions]);

  // Add early return if artifact content is not a valid URL
  if (!artifact?.content || typeof artifact.content !== "string") {
    return (
      <div className="pv-error-container">
        <div className="pv-error">No PDF content available to display.</div>
      </div>
    );
  }

  if (!isWorkerLoaded || !pdfUrl) {
    return <div className="pv-loading">Loading paper...</div>;
  }

  return (
    <div
      className="pv-paper-viewer"
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={() => setShowZoomControls(false)}
    >
      {isLoading && (
        <div
          className="artifact-loading-overlay visible"
          aria-label="Loading PDF"
        >
          <div className="artifact-loading-spinner" role="progressbar" />
        </div>
      )}

      <div className="pv-container">
        <div className="pv-thumbnail-container">
          <Document
            file={pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            loading={null}
            options={pdfOptions}
            error={
              <div className="pv-error">Error loading PDF thumbnails.</div>
            }
          >
            {thumbnailPages}
          </Document>
        </div>

        <div className="pv-document-container">
          {highlightPosition && isPopupOpen && (
            <div
              className="pv-command-k-highlight persistent"
              style={{
                top: highlightPosition.top,
                left: highlightPosition.left,
                width: highlightPosition.width,
                height: highlightPosition.height,
              }}
            />
          )}

          <Document
            file={pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            loading={null}
            options={pdfOptions}
            error={
              <div className="pv-error">
                Failed to load PDF. Please check the URL.
              </div>
            }
            className="pv-main-document"
          >
            {documentPages}
          </Document>

          {showZoomControls && (
            <div className="pv-zoom-controls-floating">
              <button
                className="pv-zoom-button"
                onClick={handleZoomOut}
                aria-label="Zoom out"
              >
                <img src="/images/zoom-out.svg" alt="" aria-hidden="true" />
              </button>
              <span className="pv-zoom-level">{Math.round(scale * 100)}%</span>
              <button
                className="pv-zoom-button"
                onClick={handleZoomIn}
                aria-label="Zoom in"
              >
                <img src="/images/zoom-in.svg" alt="" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PaperViewer;
