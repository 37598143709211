import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const submitArtifactInputs = createAsyncThunk(
  "projects/submitArtifactInputs",
  async (inputs, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const projectId = getState().project.currentProjectId;
    const response = await submitArtifactInputsAPI(inputs, projectId, token);
    return response;
  }
);

export const submitArtifactInputsReducer = {
  [submitArtifactInputs.fulfilled]: (state, action) => {
    state.artifact = {
      type: "markdown",
      content: action.payload,
    };
  },
  [submitArtifactInputs.rejected]: (state, action) => {
    state.error = action.error.message;
  },
};

export const submitArtifactInputsAPI = async (inputs, projectId, token) => {
  console.log("submitArtifactInputsAPI called with:", { inputs, projectId });
  const response = await fetchWithToken(
    `${API_URL}/api/submit-artifact-inputs`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ inputs, project_id: projectId }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to submit artifact inputs");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
