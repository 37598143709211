import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const editProtocolData = createAsyncThunk(
  "projects/editProtocolData",
  async ({ fileId, inputs }, { getState }) => {
    const token = getState().user.access_token;
    console.log(
      "editProtocolData fileId",
      fileId,
      "inputs",
      inputs,
      "token",
      token
    );
    try {
      const response = await editProtocol(fileId, inputs, token);
      console.log("editProtocolData response", response);

      // Determine the type based on the response
      let type = "sequence";
      if (response.content && typeof response.content === "string") {
        type = "markdown";
      } else if (response.design) {
        type = "design";
      }

      return {
        ...response,
        type: type,
        file_id: fileId,
      };
    } catch (error) {
      console.error("Error in editProtocolData:", error);
      throw error;
    }
  }
);

export const editProtocolDataReducer = {
  [editProtocolData.pending]: (state) => {
    state.error = null;
  },
  [editProtocolData.fulfilled]: (state, action) => {
    // Update the artifact with the new content while preserving the type and file_id
    state.artifact = {
      ...state.artifact,
      ...action.payload,
      type: "markdown", // Ensure type is preserved
      file_id: action.payload.file_id || state.artifact.file_id,
    };
  },
  [editProtocolData.rejected]: (state, action) => {
    state.error = action.error.message;
    console.error("editProtocolData rejected:", action.error);
  },
};

export const editProtocol = async (fileId, inputs, token) => {
  console.log("editProtocol called with:", { fileId, inputs });

  // Convert checkbox values to explicit boolean values
  const processedInputs = Object.fromEntries(
    Object.entries(inputs).map(([key, value]) => {
      if (typeof value === "boolean") {
        return [key, value];
      } else if (value === "true" || value === "false") {
        return [key, value === "true"];
      }
      return [key, value];
    })
  );

  const response = await fetchWithToken(
    `${API_URL}/api/edit-protocol`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_id: fileId,
        inputs: processedInputs,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to edit protocol");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
