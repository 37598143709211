import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateUserFile } from "../user.js";

export const manageCloningPlanAction = createAsyncThunk(
  "projects/manageCloningPlan",
  async ({ projectId, mode, planName, planId }, { getState, dispatch }) => {
    const token = getState().user.access_token;

    // Save chat-related fields
    const currentProject = getState().project.currentProject;
    const chatFields = {
      agent_chat_files: currentProject.agent_chat_files,
      agent_chat_next_token: currentProject.agent_chat_next_token,
      chat_chat_files: currentProject.chat_chat_files,
      chat_chat_next_token: currentProject.chat_chat_next_token,
      popup_chat_files: currentProject.popup_chat_files,
    };

    try {
      const response = await manageCloningPlan(
        projectId,
        mode,
        planName,
        planId,
        token
      );

      // Merge chat fields with response
      const updatedResponse = {
        ...response,
        ...chatFields,
      };

      // Update user files with the project files
      if (response.files) {
        dispatch(
          updateUserFile({
            replaceAll: true,
            updatedFile: {
              ...getState().user.files,
              ...response.files,
            },
          })
        );
      }

      return { projectId, updatedProject: updatedResponse };
    } catch (error) {
      console.error("Error managing cloning plan:", error);
      throw error;
    }
  }
);

export const manageCloningPlanActionReducer = {
  [manageCloningPlanAction.fulfilled]: (state, action) => {
    console.log(
      "manageCloningPlanAction.fulfilled - received project:",
      action.payload
    );
    const { projectId, updatedProject } = action.payload;

    // Update project in list
    state.projectList = state.projectList.map((project) =>
      project.project_id === projectId ? updatedProject : project
    );

    // Update current project if it's the one being modified
    if (state.currentProjectId === projectId) {
      state.currentProject = updatedProject;
    }
    console.log("Updated state:", {
      projectList: state.projectList,
      currentProject: state.currentProject,
    });
  },
};

export const manageCloningPlan = async (
  projectId,
  mode,
  planName,
  planId,
  token
) => {
  console.log("=== manageCloningPlan API Call ===");
  console.log("Input parameters:", {
    projectId,
    mode,
    planName,
    planId,
    token: "REDACTED",
  });

  const response = await fetchWithToken(
    `${API_URL}/api/manage-cloning-plan`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        project_id: projectId,
        mode,
        plan_name: planName,
        plan_id: planId,
      }),
    },
    token
  );

  console.log("Response status:", response.status);

  if (!response.ok) {
    const errorText = await response.text();
    console.error("API Error:", errorText);
    throw new Error("Failed to manage cloning plan");
  }

  const result = await response.json();
  console.log("Raw API Response:", result);

  // Parse the nested project JSON string
  const parsedProject = result.data.project
    ? JSON.parse(result.data.project)
    : null;
  console.log("Parsed project data:", parsedProject);
  console.log("=== End manageCloningPlan API Call ===");

  return result.status === "success"
    ? parsedProject
    : Promise.reject(result.message);
};
