import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const getOrderData = createAsyncThunk(
  "projects/getOrderData",
  async (projectId, { getState }) => {
    const token = getState().user.access_token;
    const project = getState().project.currentProject;

    // Filter files that match the criteria using the file keys
    const fileIds = Object.keys(project?.files || {}).filter((fileId) => {
      const file = project.files[fileId];
      return (
        file.file_type === "Output" &&
        (file.file_name.includes("Order Form") ||
          file.file_name.includes("PCR") ||
          file.file_name.includes("Digest") ||
          file.file_name.includes("Golden Gate") ||
          file.file_name.includes("Gibson") ||
          file.file_name.includes("Traditional"))
      );
    });

    const response = await fetchOrderData(fileIds, projectId, token);
    return response.order_data;
  }
);

export const getOrderDataReducer = {
  [getOrderData.fulfilled]: (state, action) => {
    state.orderData = action.payload;
  },
  [getOrderData.rejected]: (state, action) => {
    console.error("Failed to fetch order data:", action.error);
    state.orderData = null;
  },
};

export const fetchOrderData = async (fileIds, projectId, token) => {
  console.log("fetchOrderData called with:", { fileIds, projectId });

  const response = await fetchWithToken(
    `${API_URL}/api/fetch-order-data`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_ids: fileIds,
        project_id: projectId,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to fetch order data");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
