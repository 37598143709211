import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchChat } from "../store/project.js";
import { fetchNextChats } from "../store/project_reducers/fetchNextChats.js";
import { withEditStateCheck } from "../utils/editStateWrapper.js";
import { FaComments } from "react-icons/fa";
import "../style/RecentChats.css";
import { updateChat } from "../store/project.js";

function RecentChats() {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const containerRef = useRef(null);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const currentProject = useSelector((state) => state.project.currentProject);
  const activeChat =
    useSelector((state) => state.project.activeChat) || "chat";
  const project = useSelector((state) => state.project.currentProject);
  const nextToken = project?.[`${activeChat}_chat_next_token`];
  const isLoadingMore = useSelector(
    (state) => state.project.isLoadingMoreChats
  );

  const formatTimeAgo = (isoDate) => {
    // Convert both times to UTC
    const now = new Date().getTime();
    const past = new Date(isoDate).getTime();

    // Ensure valid date parsing
    if (isNaN(past)) {
      console.error("Invalid date format:", isoDate);
      return "unknown";
    }

    // Get timezone offset in milliseconds
    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

    // Adjust the difference calculation for timezone
    const diffInSeconds = Math.floor((now - (past - timezoneOffset)) / 1000);

    // More precise thresholds
    if (diffInSeconds < 30) return "just now";
    if (diffInSeconds < 60) return "less than a minute";
    if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}m`;
    }
    if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours}h`;
    }
    if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days}d`;
    }
    const weeks = Math.floor(diffInSeconds / 604800);
    return `${weeks}w`;
  };

  const getRecentChats = () => {
    if (!project?.[`${activeChat}_chat_files`]) return [];
    const chats = Object.entries(project[`${activeChat}_chat_files`])
      .map(([id, file]) => ({
        id: file.chat_id,
        name: file.chat_name,
        lastModified: file.last_opened_date, // This is already in ISO format
      }))
      .sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified));

    return chats;
  };

  const handleChatClick = (chatId) => {
    if (chatId) {
      dispatch(
        withEditStateCheck(() => {
          // Get chat data directly from project state
          const chatFileData = project[`${activeChat}_chat_files`][chatId];
          if (chatFileData) {
            dispatch(
              updateChat({
                chat_id: chatId,
                project_id: currentProjectId,
                chat_contents: chatFileData.chat_contents || [],
                // Include any other relevant chat data from chatFileData
              })
            );
          }
        }, currentProjectId)
      );
    }
  };

  const handleLoadMore = async () => {
    if (nextToken) {
      await dispatch(fetchNextChats({ chatType: activeChat }));
    }
  };

  const recentChats = getRecentChats();
  const totalChats = project?.[`${activeChat}_chat_files`]
    ? Object.keys(project[`${activeChat}_chat_files`]).length
    : 0;

  if (recentChats.length === 0) return null;

  return (
    <div className="recent-chats-container" ref={containerRef}>
      <div className="recent-chats-header">
        <h3 className="recent-chats-title">Recent Chats</h3>
        {nextToken && (
          <button
            className="see-all-button"
            onClick={handleLoadMore}
            disabled={isLoadingMore}
          >
            {isLoadingMore ? "Loading..." : "See more"}
          </button>
        )}
      </div>
      <div className="recent-chats-list">
        {recentChats.map((chat) => (
          <div
            key={chat.id}
            className="recent-chat-item"
            onClick={() => handleChatClick(chat.id)}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleChatClick(chat.id);
              }
            }}
          >
            <FaComments className="chat-icon" />
            <div className="chat-info">
              <span className="chat-name">{chat.name}</span>
              <span className="chat-time">
                {formatTimeAgo(chat.lastModified)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RecentChats;
