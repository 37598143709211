import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateUserFile } from "../user.js";

export const managePythonFile = createAsyncThunk(
  "project/managePythonFile",
  async (
    { projectId, mode, fileName, fileId },
    { getState, rejectWithValue, dispatch }
  ) => {
    const token = getState().user.access_token;

    // Save chat-related fields
    const currentProject = getState().project.currentProject;
    const chatFields = {
      agent_chat_files: currentProject.agent_chat_files,
      agent_chat_next_token: currentProject.agent_chat_next_token,
      chat_chat_files: currentProject.chat_chat_files,
      chat_chat_next_token: currentProject.chat_chat_next_token,
      popup_chat_files: currentProject.popup_chat_files,
    };

    try {
      const url = `${API_URL}/api/python-files`;
      const payload = {
        project_id: projectId,
        mode,
        file_name: fileName,
        file_id: fileId,
      };

      const response = await fetchWithToken(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
        token
      );

      if (!response.ok) {
        throw new Error("Failed to manage Python file");
      }

      const result = await response.json();
      console.log("Raw API Response:", result);

      if (result.status === "success") {
        // Parse the nested project JSON string if it exists
        const parsedProject = result.data.project
          ? JSON.parse(result.data.project)
          : result.data;

        // Merge chat fields with parsed project
        const updatedProject = {
          ...parsedProject,
          ...chatFields,
        };

        // Update user files if files are present in the response
        if (parsedProject.files) {
          dispatch(
            updateUserFile({
              replaceAll: true,
              updatedFile: {
                ...getState().user.files,
                ...parsedProject.files,
              },
            })
          );
        }

        console.log("Parsed project data:", updatedProject);
        return updatedProject;
      } else {
        throw new Error(result.message || "Server returned an error");
      }
    } catch (error) {
      console.error("Error in managePythonFile:", error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const managePythonFileReducer = {
  [managePythonFile.pending]: (state) => {
    state.error = null;
    state.status = "loading";
  },
  [managePythonFile.fulfilled]: (state, action) => {
    state.status = "succeeded";

    if (!action.payload) return;

    const updatedProject = action.payload;

    // Update the project in the project list
    state.projectList = state.projectList.map((project) =>
      project.project_id === updatedProject.project_id
        ? updatedProject
        : project
    );

    // Update current project if it's the active one
    if (state.currentProjectId === updatedProject.project_id) {
      state.currentProject = updatedProject;
    }
  },
  [managePythonFile.rejected]: (state, action) => {
    state.error = action.payload || "Failed to manage Python file";
    state.status = "failed";
  },
};
