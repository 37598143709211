import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const fetchNextFiles = createAsyncThunk(
  "user/fetchNextFiles",
  async (fileType = "all", { getState }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;

    // Check if we already know we have all files for this type
    const typeKey = fileType.toLowerCase();
    if (
      typeKey !== "all" &&
      state.user.next_tokens[typeKey] === "all_files_present"
    ) {
      return null;
    }

    // Get the appropriate next token based on file type
    let nextToken;
    if (fileType === "all") {
      nextToken = state.user.files_next_token;
    } else {
      nextToken = state.user.next_tokens[typeKey];
    }

    console.log(
      "[fetchNextFiles] Using nextToken:",
      nextToken,
      "for type:",
      fileType
    );

    const response = await fetchNextUserFiles(
      userId,
      nextToken,
      token,
      fileType
    );

    console.log("[fetchNextFiles] Response received:", {
      filesCount: Object.keys(response.files || {}).length,
      nextToken: response.nextToken,
      hasNoMore: response.hasNoMore,
    });

    return { ...response, fileType };
  }
);

export const fetchNextFilesReducer = {
  [fetchNextFiles.fulfilled]: (state, action) => {
    if (!action.payload) return;

    console.log("[fetchNextFilesReducer] Starting with payload:", {
      fileType: action.payload.fileType,
      filesCount: Object.keys(action.payload.files || {}).length,
      hasNoMore: action.payload.hasNoMore,
      nextToken: action.payload.nextToken,
    });
    console.log(
      "[fetchNextFilesReducer] Current next_tokens:",
      state.next_tokens
    );

    // Merge new files with existing files
    state.files = {
      ...state.files,
      ...action.payload.files,
    };
    state.data.files = {
      ...state.data.files,
      ...action.payload.files,
    };

    // Update appropriate next token based on file type
    const typeKey = action.payload.fileType.toLowerCase();

    // If we got no files and hasNoMore is true, or we got no files at all
    if (
      action.payload.hasNoMore ||
      Object.keys(action.payload.files || {}).length === 0
    ) {
      console.log("[fetchNextFilesReducer] Setting no_more for type:", typeKey);
      if (typeKey === "all") {
        state.files_next_token = undefined;
      } else {
        state.next_tokens = {
          ...state.next_tokens,
          [typeKey]: "no_more",
        };
      }
      console.log(
        "[fetchNextFilesReducer] Updated next_tokens:",
        state.next_tokens
      );
      return;
    }

    // Normal token update
    console.log(
      "[fetchNextFilesReducer] Normal token update for type:",
      typeKey
    );
    if (typeKey === "all") {
      state.files_next_token = action.payload.nextToken;
    } else {
      state.next_tokens = {
        ...state.next_tokens,
        [typeKey]: action.payload.nextToken,
      };
    }
    console.log(
      "[fetchNextFilesReducer] Final next_tokens state:",
      state.next_tokens
    );
  },
};

export const fetchNextUserFiles = async (
  userId,
  nextToken,
  token,
  fileType
) => {
  const response = await fetchWithToken(
    `${API_URL}/api/fetch-next-files`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        next_token: nextToken,
        file_type: fileType.toLowerCase(),
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to fetch next files");
  }

  const result = await response.json();

  if (result.status === "success") {
    return {
      files: result.data.files,
      nextToken: result.data.next_token,
      hasNoMore: result.num_files === 0 && !result.has_more,
    };
  }

  return Promise.reject(result.message);
};
