import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useSelector, useDispatch } from "react-redux";
import { components } from "./components.js";
import {
  isToolCallHidden,
  extractFilename,
  getDisplayName,
  handleToggle,
  shouldShowFileActions,
} from "./helpers.js";
import { FaCheck, FaTimes, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { applyToolCall } from "../../store/project.js";
import { handleDiffChanges } from "../../store/project.js";
import { fetchArtifactData } from "../../store/project.js";
import { applyChatBlock } from "../../store/project.js";

export const MessageHistory = ({
  message,
  messageIndex,
  hiddenToolCalls,
  setHiddenToolCalls,
  messages,
}) => {
  const dispatch = useDispatch();
  const chatType = useSelector((state) => state.project.activeChat);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const currentChatId = useSelector((state) => state.project.currentChatId);
  const applyingChatBlock = useSelector(
    (state) => state.project.applyingChatBlock
  );
  const applyingToolCall = useSelector(
    (state) => state.project.applyingToolCall
  );
  const currentProject = useSelector((state) => state.project.currentProject);

  const handleAcceptReject = (filename, action) => {
    // Find file ID from filename
    const fileId = Object.keys(currentProject.files).find(
      (id) => currentProject.files[id].file_name === filename
    );

    if (!fileId) return;

    dispatch(
      handleDiffChanges({
        projectId: currentProject.project_id,
        action: action === "accept" ? "accept" : "reject",
        fileIds: [fileId],
      })
    ).then(() => {
      dispatch(
        fetchArtifactData({
          fileId: fileId,
          fileType: currentProject.files[fileId].file_type,
        })
      );
    });
  };

  const formatFileBlocks = (content) => {
    // Modified regex to capture blocks that might not end with ```
    const blockRegex =
      /(?:File:\s*`?([^\n`]+?)`?\s*\n\s*```markdown\n([\s\S]*?)(?:```|$))|(?:file:\s*`?([^\.`]+\.py)`?\s*\n\s*```python\n([\s\S]*?)(?:```|$))/gi;
    let lastIndex = 0;
    const parts = [];
    let match;

    while ((match = blockRegex.exec(content)) !== null) {
      // Add any text before the match as a text block
      if (match.index > lastIndex) {
        const textContent = content.slice(lastIndex, match.index);
        parts.push({
          type: "text",
          content: textContent,
        });
      }

      // Handle markdown blocks
      if (match[1] && match[2]) {
        const filename = match[1].trim().replace(/^(?:File:\s*)/i, "");
        parts.push({
          type: "fileBlock",
          filename: filename,
          content: match[2],
          isComplete: match[0].endsWith("```"),
          blockType: "markdown",
        });
      }
      // Handle python blocks
      else if (match[3] && match[4]) {
        const filename = match[3].trim().replace(/^(?:File:\s*)/i, "");
        parts.push({
          type: "fileBlock",
          filename: filename,
          content: match[4],
          isComplete: match[0].endsWith("```"),
          blockType: "python",
        });
      }

      lastIndex = match.index + match[0].length;
    }

    // Add any remaining text as a text block
    if (lastIndex < content.length) {
      const textContent = content.slice(lastIndex);
      parts.push({
        type: "text",
        content: textContent,
      });
    }

    return parts;
  };

  const parseMessageContent = (content) => {
    if (!content) return "";

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = DOMPurify.sanitize(content);

    const segments = [];
    let currentNode = tempDiv.firstChild;
    let currentText = "";

    const processNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        currentText += node.textContent;
      } else if (
        node.nodeType === Node.ELEMENT_NODE &&
        node.classList.contains("file-tag")
      ) {
        if (currentText) {
          segments.push({ type: "text", content: currentText });
          currentText = "";
        }
        segments.push({
          type: "file-tag",
          content: node.textContent,
          fileId: node.getAttribute("data-file-tag"),
        });
      } else if (node.childNodes.length) {
        Array.from(node.childNodes).forEach(processNode);
      }
    };

    processNode(tempDiv);
    if (currentText) {
      segments.push({ type: "text", content: currentText });
    }

    return segments;
  };

  if (!message?.content) {
    if (message?.tool_calls) {
      return message.tool_calls
        .filter((toolCall) => {
          const name = toolCall.function.name;
          return (
            name &&
            name.trim() !== "" &&
            !name.toLowerCase().includes("complete") &&
            !name.toLowerCase().includes("handoff")
          );
        })
        .map((toolCall, idx) => {
          const callId = `message_${messageIndex}_tool_${idx}`;
          const isHidden = isToolCallHidden(
            callId,
            toolCall.function.name,
            hiddenToolCalls
          );
          const args = toolCall.function.arguments || "{}";
          let parsedArgs;
          try {
            parsedArgs = JSON.parse(args);
          } catch {
            parsedArgs = args;
          }

          const filename = extractFilename(parsedArgs);
          const displayName = filename
            ? `${getDisplayName(toolCall.function.name)}: ${filename}`
            : toolCall.function.name;

          const isApplyingThisToolCall =
            applyingToolCall &&
            applyingToolCall.filename === filename &&
            (JSON.stringify(applyingToolCall.request) ===
              JSON.stringify(parsedArgs) ||
              JSON.stringify(applyingToolCall.request?.request) ===
                JSON.stringify(parsedArgs?.request));

          return (
            <div key={idx} className="file-block-container">
              <div className="file-block-header">
                <span className="file-block-filename">{displayName}</span>
                <div className="file-block-actions">
                  {chatType === "chat" && (
                    <>
                      {isApplyingThisToolCall ? (
                        <div className="file-block-spinner">
                          <div className="spinner-circle"></div>
                        </div>
                      ) : (
                        <>
                          <button
                            className="file-action-text-button"
                            onClick={() => {
                              const content =
                                typeof parsedArgs === "object"
                                  ? JSON.stringify(parsedArgs, null, 2)
                                  : parsedArgs;
                              navigator.clipboard
                                .writeText(content)
                                .then(() => console.log("Copied to clipboard"))
                                .catch((err) =>
                                  console.error("Failed to copy:", err)
                                );
                            }}
                          >
                            copy
                          </button>
                          <button
                            className="file-action-text-button"
                            onClick={() => {
                              dispatch(
                                applyToolCall({
                                  filename,
                                  projectId: currentProjectId,
                                  request: parsedArgs,
                                  functionName: toolCall.function.name,
                                })
                              )
                                .unwrap()
                                .then(() =>
                                  console.log(
                                    "Successfully applied tool call for:",
                                    filename
                                  )
                                )
                                .catch((err) =>
                                  console.error(
                                    "Failed to apply tool call:",
                                    err
                                  )
                                );
                            }}
                          >
                            apply
                          </button>
                        </>
                      )}
                    </>
                  )}
                  <button
                    className="caret-toggle"
                    onClick={() =>
                      handleToggle(
                        callId,
                        isHidden,
                        hiddenToolCalls,
                        setHiddenToolCalls
                      )
                    }
                  >
                    {isHidden ? <FaCaretDown /> : <FaCaretUp />}
                  </button>
                </div>
              </div>
              {!isHidden && (
                <div className="file-block-content">
                  <pre>
                    <code>
                      {typeof parsedArgs === "object"
                        ? JSON.stringify(parsedArgs, null, 2)
                        : parsedArgs}
                    </code>
                  </pre>
                </div>
              )}
            </div>
          );
        });
    }
    return null;
  }

  if (message.role === "tool") {
    return null;
  }

  const segments = parseMessageContent(message.content);

  return (
    <div style={{ display: "block" }}>
      {segments.map((segment, index) => {
        if (segment.type === "text") {
          const parts = formatFileBlocks(segment.content);
          return parts.map((part, partIndex) => {
            if (part.type === "text") {
              return (
                <span
                  key={`${index}-${partIndex}`}
                  style={{
                    display: message.role === "assistant" ? "block" : "inline",
                  }}
                >
                  {message.role === "assistant" ? (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={components}
                    >
                      {part.content}
                    </ReactMarkdown>
                  ) : (
                    part.content.split(/(`[^`]+`)/).map((text, i) => {
                      if (text.startsWith("`") && text.endsWith("`")) {
                        return (
                          <span key={i} className="file-tag">
                            {text.slice(1, -1)}
                          </span>
                        );
                      }
                      return text;
                    })
                  )}
                </span>
              );
            } else if (part.type === "fileBlock") {
              const showActions = shouldShowFileActions(
                part.filename,
                messages,
                messageIndex,
                currentProject,
                chatType
              );
              const isApplyingThisBlock =
                applyingChatBlock &&
                applyingChatBlock.filename === part.filename &&
                applyingChatBlock.messageIndex === messageIndex;
              return (
                <div
                  key={`${index}-${partIndex}`}
                  className="file-block-container"
                >
                  <div className="file-block-header">
                    <span className="file-block-filename">{part.filename}</span>
                    {showActions && (
                      <div className="file-block-actions">
                        <button
                          className="file-action-button reject"
                          onClick={() =>
                            handleAcceptReject(part.filename, "reject")
                          }
                          aria-label="Reject changes"
                        >
                          <FaTimes />
                        </button>
                        <button
                          className="file-action-button accept"
                          onClick={() =>
                            handleAcceptReject(part.filename, "accept")
                          }
                          aria-label="Accept changes"
                        >
                          <FaCheck />
                        </button>
                      </div>
                    )}
                    {chatType === "chat" && !showActions && (
                      <div className="file-block-actions">
                        <button
                          className="file-action-text-button"
                          onClick={() => {
                            const content = part.content;
                            navigator.clipboard
                              .writeText(content)
                              .then(() =>
                                console.log(
                                  "Copied to clipboard:",
                                  part.filename
                                )
                              )
                              .catch((err) =>
                                console.error("Failed to copy:", err)
                              );
                          }}
                        >
                          copy
                        </button>
                        {isApplyingThisBlock ? (
                          <div className="file-block-spinner">
                            <div className="spinner-circle"></div>
                          </div>
                        ) : (
                          <button
                            className="file-action-text-button"
                            onClick={() => {
                              // Get the file type from the current project files
                              const fileId = Object.keys(
                                currentProject.files
                              ).find(
                                (id) =>
                                  currentProject.files[id].file_name ===
                                  part.filename
                              );
                              const fileType = fileId
                                ? currentProject.files[fileId].file_type
                                : null;

                              // Use applyChatBlock for Python and Notebook files
                              if (
                                fileType === "Python" ||
                                fileType === "Notebook"
                              ) {
                                dispatch(
                                  applyChatBlock({
                                    filename: part.filename,
                                    projectId: currentProjectId,
                                    chatId: currentChatId,
                                    messageIndex: messageIndex,
                                  })
                                );
                              } else {
                                // For other file types, use applyToolCall
                                dispatch(
                                  applyToolCall({
                                    filename: part.filename,
                                    projectId: currentProjectId,
                                    request: part.content,
                                    functionName: part.functionName,
                                  })
                                );
                              }
                            }}
                          >
                            apply
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="file-block-content">
                    <pre>
                      <code>{part.content}</code>
                    </pre>
                  </div>
                </div>
              );
            }
            return null;
          });
        } else if (segment.type === "file-tag") {
          return (
            <span
              key={index}
              className="file-tag"
              data-file-tag={segment.fileId}
              style={{ display: "inline-flex" }}
            >
              {segment.content}
            </span>
          );
        }
        return null;
      })}
    </div>
  );
};
