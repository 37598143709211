import { fetchWithToken, API_URL } from "./api.js";

export const deletePopupChat = async (projectId, token) => {
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/delete-popup-chat`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          project_id: projectId,
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to delete popup chat");
    }

    const result = await response.json();
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in deletePopupChat:", error);
    throw error;
  }
};
