import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { Tooltip } from "react-tooltip";
import UploadToTool from "../UploadToTool.js";
import { removeToolFile } from "../../store/project.js";
import { runAnnotation } from "../../api/tools_api.js";
import "./ToolAnnotation.css";

// Create a selector for tools_file_ids
const selectToolsFileIds = (state) => state.project.tools_file_ids;

function ToolAnnotation({ tool }) {
  const selectedFiles = useSelector(selectToolsFileIds);
  const token = useSelector((state) => state.user.access_token);
  const userId = useSelector((state) => state.user.user_id);
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(false);
  const [submittedFiles, setSubmittedFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [databases, setDatabases] = useState({
    commonFeatures: true,
    fpbase: true,
    swissprot: true,
    rfam: true,
    yourLibrary: false,
  });

  const databaseInfo = {
    commonFeatures: {
      title: "Common LabKick Features",
      description: "~1300 common features from SnapGene and AddGene plasmids",
    },
    fpbase: {
      title: "FPBase",
      description: `Fluorescent protein database from <a href="https://www.fpbase.org/" target="_blank" rel="noopener noreferrer">fpbase.org</a>`,
    },
    swissprot: {
      title: "SwissProt (UniProtKB)",
      description: `Curated list of protein sequences from <a href="https://www.uniprot.org/uniprotkb" target="_blank" rel="noopener noreferrer">uniprot.org/uniprotkb</a>`,
    },
    rfam: {
      title: "Rfam",
      description: `RNA families database for regulatory elements and ncRNAs from <a href="https://rfam.org/" target="_blank" rel="noopener noreferrer">rfam.org</a>`,
    },
    yourLibrary: {
      title: "Your Library",
      description:
        "Have LabKick pull the annotations from your library and use them to annotate your new sequences.",
    },
  };

  const handleDatabaseChange = (database) => {
    setDatabases((prev) => ({
      ...prev,
      [database]: !prev[database],
    }));
  };

  React.useEffect(() => {
    if (selectedFiles.length > 10) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [selectedFiles]);

  const handleClickOutside = () => {
    setShowWarning(false);
  };

  React.useEffect(() => {
    if (showWarning) {
      document.addEventListener("click", handleClickOutside);
      return () => document.removeEventListener("click", handleClickOutside);
    }
  }, [showWarning]);

  const handleAnnotate = async () => {
    if (selectedFiles.length === 0 || selectedFiles.length > 10) return;

    setIsLoading(true);
    setError(null);

    try {
      const fileIds = selectedFiles.map((file) => file.id);
      const { success, message } = await runAnnotation(
        fileIds,
        databases,
        userId,
        token,
      );

      if (success) {
        // Clear all selected files
        selectedFiles.forEach((file) => {
          dispatch(removeToolFile(file.id));
        });

        setSubmittedFiles(message);
      } else {
        throw new Error("Annotation submission failed");
      }
    } catch (err) {
      setError(err.message || "Failed to submit files for annotation");
      console.error("Error submitting annotation:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="tool-annotation-container">
      <h1 className="tool-annotation-title">{tool.title}</h1>
      <p className="tool-annotation-description">{tool.description}</p>

      <div className="tool-annotation-upload">
        <UploadToTool />
      </div>

      {showWarning && (
        <div className="tool-annotation-warning">
          Only 10 files can be annotated at a time
        </div>
      )}

      <div className="tool-annotation-databases">
        <h3>Databases</h3>
        <div className="tool-annotation-databases-content">
          {Object.entries(databases).map(([key, value]) => (
            <div key={key} className="checkbox-group ta-tooltip-container">
              <input
                type="checkbox"
                id={key}
                checked={value}
                onChange={() => {
                  if (key === "yourLibrary") {
                    const tooltip = document.getElementById(
                      `ta-${key}-tooltip`,
                    );
                    tooltip.style.display = "block";
                    setTimeout(() => {
                      tooltip.style.display = "none";
                    }, 3000);
                  } else {
                    handleDatabaseChange(key);
                  }
                }}
              />
              <label
                htmlFor={key}
                onMouseEnter={() => {
                  const tooltip = document.getElementById(`ta-${key}-tooltip`);
                  tooltip.style.display = "block";
                }}
                onMouseLeave={() => {
                  const tooltip = document.getElementById(`ta-${key}-tooltip`);
                  tooltip.style.display = "none";
                }}
              >
                {databaseInfo[key].title}
              </label>
              <div
                id={`ta-${key}-tooltip`}
                className="ta-tooltip"
                dangerouslySetInnerHTML={{
                  __html:
                    key === "yourLibrary"
                      ? "Searching personal sequences is only available to enterprise customers. Please contact sales@labkick.ai"
                      : databaseInfo[key].description,
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="tool-annotation-credits">
        Credits required: <strong>{Math.min(selectedFiles.length, 10)}</strong>
      </div>

      <button
        className="tool-annotation-button"
        onClick={handleAnnotate}
        disabled={
          selectedFiles.length === 0 || selectedFiles.length > 10 || isLoading
        }
      >
        {isLoading ? "Submitting..." : "Annotate Files"}
      </button>

      {error && <div className="tool-annotation-error">{error}</div>}

      {submittedFiles && !error && (
        <div className="tool-annotation-success">{submittedFiles}</div>
      )}
    </div>
  );
}

export default ToolAnnotation;
