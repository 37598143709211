import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { setPopupMessage } from "../user.js";

export const fetchUserNotifications = createAsyncThunk(
  "user/fetchUserNotifications",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;

    try {
      const notificationsData = await fetchNotifications(userId, token);

      if (notificationsData?.is_changed) {
        // Check if there are new unseen notifications
        const currentUnread = state.user.notifications_unread;
        const newUnread = notificationsData.unread;

        // Compare arrays to find new unseen notifications
        const newUnseenNotifications = newUnread.filter(
          (newNotif) =>
            !currentUnread.some(
              (currentNotif) => currentNotif.id === newNotif.id
            ) && !newNotif.seen
        );

        // Only show popup if there are new unseen notifications
        if (newUnseenNotifications.length > 0) {
          const message =
            newUnseenNotifications.length === 1
              ? "You have a new notification"
              : `You have ${newUnseenNotifications.length} new notifications`;

          // Set popup message with timeout
          dispatch(setPopupMessage(message));
          setTimeout(() => {
            dispatch(setPopupMessage(null));
          }, 5000);
        }

        return notificationsData;
      }
      return null;
    } catch (error) {
      console.error("Error fetching notifications:", error);
      return null;
    }
  }
);

export const fetchUserNotificationsReducer = {
  [fetchUserNotifications.fulfilled]: (state, action) => {
    if (action.payload?.is_changed) {
      state.notifications_unread = action.payload.unread;
      state.notifications_dismissed = action.payload.dismissed;
    }
  },
};

export const fetchNotifications = async (userId, token) => {
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/get-notifications`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to fetch notifications");
    }

    const result = await response.json();
    return result.status === "success" ? result.data : null;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};
