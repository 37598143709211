
export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

export const fetchWithToken = async (url, options = {}, token) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
  };

  if (token) {
    defaultHeaders["authorization"] = `Bearer ${token}`;
  }

  const mergedOptions = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
  };

  return fetch(url, mergedOptions);
};




