import React from "react";
import { useSelector } from "react-redux";
import { FaArrowLeft } from "react-icons/fa";
import FileExplorerModal from "./FileExplorerModal.js";
import ToolAnnotation from "./tools/ToolAnnotation.js";
import ToolSequenceFinder from "./tools/ToolSequenceFinder.js";
import ToolComplexityAnalyzer from "./tools/ToolComplexityAnalyzer.js";
import ToolSynthesisSuccessPredictor from "./tools/ToolSynthesisSuccessPredictor.js";
import ToolPrimerDesign from "./tools/ToolPrimerDesign.js";
import ToolDigestVisualizer from "./tools/ToolDigestVisualizer.js";
import ToolMaterialLocator from "./tools/ToolMaterialLocator.js";
import "../style/ToolPage.css";

const toolComponents = {
  annotation: ToolAnnotation,
  sequence_finder: ToolSequenceFinder,
  dna_analyzer: ToolComplexityAnalyzer,
  synthesis_calculator: ToolSynthesisSuccessPredictor,
  primer_designer: ToolPrimerDesign,
  digest_visualizer: ToolDigestVisualizer,
  material_locator: ToolMaterialLocator,
  // Add other tools here as they're created
};

function ToolPage({ tool, onBack }) {
  const modal = useSelector((state) => state.project.modal);
  const ToolComponent = toolComponents[tool.id];

  if (!ToolComponent) {
    return null;
  }

  return (
    <div className="tool-page">
      <button className="tool-page-close" onClick={onBack}>
        &times;
      </button>
      <button className="tool-page-back" onClick={onBack}>
        <FaArrowLeft size={16} aria-hidden="true" />
        <span>Back to Tools</span>
      </button>

      <div className="tool-page-content">
        <ToolComponent tool={tool} />
      </div>

      {(modal.name === "dna_archive_general" ||
        modal.name === "dna_archive_project" ||
        modal.name === "upload_general" ||
        modal.name === "upload_project") && (
        <FileExplorerModal is_modal="True" is_tool="True" />
      )}
    </div>
  );
}

export default ToolPage;
