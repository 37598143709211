import { createSlice } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

import { fetchUser, fetchUserReducer } from "./user_reducers/fetchUser.js";
import {
  deleteFileFromLibrary,
  deleteFileFromLibraryReducer,
} from "./user_reducers/deleteFileFromLibrary.js";
import {
  fetchCredential,
  fetchCredentialReducer,
} from "./user_reducers/fetchCredential.js";
import {
  populateSampleData,
  populateSampleDataReducer,
} from "./user_reducers/populateSampleData.js";

import {
  fetchGroups,
  addMemberToGroup,
  deleteGroupThunk,
  removeGroupMemberThunk,
  changeGroupOwnerThunk,
  transferCreditsToGroupThunk,
  transferCreditsFromGroupThunk,
  renameGroupThunk,
  leaveGroupThunk,
  fetchGroupsReducer,
  addMemberToGroupReducer,
  deleteGroupThunkReducer,
  removeGroupMemberThunkReducer,
  changeGroupOwnerThunkReducer,
  transferCreditsToGroupThunkReducer,
  transferCreditsFromGroupThunkReducer,
  renameGroupThunkReducer,
  leaveGroupThunkReducer,
} from "./user_reducers/ManageGroups.js";

import {
  fetchNextFiles,
  fetchNextFilesReducer,
} from "./user_reducers/fetchNextFiles.js";

import { fetchUserCredits } from "./user_reducers/fetchUserCredits.js";

import { searchUserFiles } from "./user_reducers/searchUserFiles.js";

import {
  fetchUserNotifications,
  fetchUserNotificationsReducer,
} from "./user_reducers/fetchUserNotifications.js";

import { updateNotifications } from "./user_reducers/updateNotifications.js";

export {
  fetchUser,
  deleteFileFromLibrary,
  fetchCredential,
  populateSampleData,
  fetchGroups,
  addMemberToGroup,
  deleteGroupThunk,
  removeGroupMemberThunk,
  changeGroupOwnerThunk,
  transferCreditsToGroupThunk,
  transferCreditsFromGroupThunk,
  renameGroupThunk,
  leaveGroupThunk,
  fetchNextFiles,
  fetchUserCredits,
  searchUserFiles,
  fetchUserNotifications,
  updateNotifications,
};

const initialState = {
  data: null,
  status: "idle",
  error: null,
  credential: "init",
  login_provider: null,
  access_token: null,
  user_id: null,
  api_url: API_URL, // Add api_url to the initial state
  files: {},
  popupMessage: null, // Add popupMessage to the initial state
  usageCredits: 0, // Add this line
  groups: [],
  isCheckingAuth: true, // Add this line
  files_next_token: null,
  next_tokens: {}, // Add this new field for file type-specific tokens
  originalFiles: null,
  originalFilesToken: null,
  isSearchActive: false,
  searchTerm: "",
  notifications_unread: [],
  notifications_dismissed: [],
  isInitialized: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action) {
      if (!state.data) {
        state.data = action.payload;
      } else {
        state.data = {
          ...state.data, // Preserve existing user data
          ...action.payload, // Merge new data
        };
      }
      if (action.payload.user_id) {
        state.user_id = action.payload.user_id;
      }
      if (action.payload.files) {
        state.files = action.payload.files;
      }
    },
    updateUserFiles(state, action) {
      const { fileId, newName } = action.payload;
      console.log("Updating user files: pending");
      if (state.data.files[fileId]) {
        console.log("State data files", state.data.files);
        state.data.files[fileId].file_name = newName;
        console.log("Updating user files: succeeded", state.data.files[fileId]);
      } else {
        console.log("Updating user files: failed, file not found", fileId);
      }
    },
    setCredential(state, action) {
      state.credential = action.payload.credential;
      state.login_provider = action.payload.login_provider;
      state.access_token = action.payload.access_token;
    },
    updateAccessToken(state, action) {
      state.access_token = action.payload;
    },
    updateUserFile(state, action) {
      const { fileId, updatedFile, replaceAll } = action.payload;

      if (replaceAll && updatedFile) {
        // Replace entire files object
        if (state.data) {
          state.data.files = updatedFile;
        }
        state.files = updatedFile; // Always update state.files regardless of state.data
      } else {
        // Original single file update logic
        if (state.data && state.data.files) {
          state.data.files[fileId] = updatedFile;
        }
        if (!state.files) {
          state.files = {};
        }
        state.files[fileId] = updatedFile;
      }
    },
    setPopupMessage(state, action) {
      state.popupMessage = action.payload;
    },
    setUserStatus(state, action) {
      state.status = action.payload;
    },
    setUsageCredits(state, action) {
      state.usageCredits = action.payload;
    },
    setGroups(state, action) {
      state.groups = action.payload;
    },
    updateFilesNextToken(state, action) {
      const { token, fileType } = action.payload;
      if (fileType === "all") {
        state.files_next_token = token;
      } else {
        state.next_tokens = {
          ...state.next_tokens,
          [fileType.toLowerCase()]: token,
        };
      }
    },
    setSearchState(state, action) {
      const { isSearchActive, searchResults, searchToken, searchTerm } =
        action.payload;
      if (isSearchActive) {
        state.originalFiles = state.data.files;
        state.originalFilesToken = state.files_next_token;
        state.data.files = searchResults;
        state.files_next_token = searchToken;
        state.searchTerm = searchTerm;
      } else {
        state.data.files = state.originalFiles;
        state.files_next_token = state.originalFilesToken;
        state.originalFiles = null;
        state.originalFilesToken = null;
        state.searchTerm = "";
      }
      state.isSearchActive = isSearchActive;
    },
    setNotifications(state, action) {
      if (action.payload) {
        state.notifications_unread = action.payload.unread;
        state.notifications_dismissed = action.payload.dismissed;
      }
    },
    setInitialized: (state, action) => {
      state.isInitialized = action.payload;
    },
    updateMonthlyCreditsConsumed(state) {
      if (state.data) {
        // Convert to int, add 1, convert back to string
        const currentCredits = parseInt(
          state.data.monthly_credits_consumed || "0"
        );
        state.data.monthly_credits_consumed = (currentCredits + 1).toString();
      }
    },
  },
  extraReducers: (builder) => {
    builder;

    Object.entries(fetchUserReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(deleteFileFromLibraryReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(fetchCredentialReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(populateSampleDataReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(fetchGroupsReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(addMemberToGroupReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(deleteGroupThunkReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(removeGroupMemberThunkReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(changeGroupOwnerThunkReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(transferCreditsToGroupThunkReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(transferCreditsFromGroupThunkReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
    Object.entries(renameGroupThunkReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(leaveGroupThunkReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(fetchNextFilesReducer).forEach(([actionType, reducer]) => {
      builder.addCase(actionType, reducer);
    });
    Object.entries(fetchUserNotificationsReducer).forEach(
      ([actionType, reducer]) => {
        builder.addCase(actionType, reducer);
      }
    );
  },
});

export const {
  updateUser,
  updateUserFiles,
  updateUserFile,
  setCredential,
  setUserStatus,
  updateAccessToken,
  setPopupMessage,
  setUsageCredits,
  setGroups,
  updateFilesNextToken,
  setSearchState,
  setNotifications,
  setInitialized,
  updateMonthlyCreditsConsumed,
} = userSlice.actions;

export default userSlice.reducer;
