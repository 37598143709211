export const geneList = [
    "gene-A1BG",
    "gene-A1CF",
    "gene-A2M",
    "gene-A2ML1",
    "gene-A3GALT2",
    "gene-A4GALT",
    "gene-A4GNT",
    "gene-AAAS",
    "gene-AACS",
    "gene-AADAC",
    "gene-AADACL2",
    "gene-AADACL3",
    "gene-AADACL4",
    "gene-AADAT",
    "gene-AAGAB",
    "gene-AAK1",
    "gene-AAMDC",
    "gene-AAMP",
    "gene-AANAT",
    "gene-AAR2",
    "gene-AARD",
    "gene-AARS1",
    "gene-AARS2",
    "gene-AARSD1",
    "gene-AASDH",
    "gene-AASDHPPT",
    "gene-AASS",
    "gene-AATF",
    "gene-AATK",
    "gene-ABAT",
    "gene-ABCA1",
    "gene-ABCA10",
    "gene-ABCA12",
    "gene-ABCA13",
    "gene-ABCA2",
    "gene-ABCA3",
    "gene-ABCA4",
    "gene-ABCA5",
    "gene-ABCA6",
    "gene-ABCA7",
    "gene-ABCA8",
    "gene-ABCA9",
    "gene-ABCB1",
    "gene-ABCB10",
    "gene-ABCB11",
    "gene-ABCB4",
    "gene-ABCB5",
    "gene-ABCB6",
    "gene-ABCB7",
    "gene-ABCB8",
    "gene-ABCB9",
    "gene-ABCC1",
    "gene-ABCC10",
    "gene-ABCC11",
    "gene-ABCC12",
    "gene-ABCC2",
    "gene-ABCC3",
    "gene-ABCC4",
    "gene-ABCC5",
    "gene-ABCC6",
    "gene-ABCC8",
    "gene-ABCC9",
    "gene-ABCD1",
    "gene-ABCD2",
    "gene-ABCD3",
    "gene-ABCD4",
    "gene-ABCE1",
    "gene-ABCF1",
    "gene-ABCF2",
    "gene-ABCF3",
    "gene-ABCG1",
    "gene-ABCG2",
    "gene-ABCG4",
    "gene-ABCG5",
    "gene-ABCG8",
    "gene-ABHD1",
    "gene-ABHD10",
    "gene-ABHD11",
    "gene-ABHD12",
    "gene-ABHD12B",
    "gene-ABHD13",
    "gene-ABHD14A",
    "gene-ABHD14B",
    "gene-ABHD15",
    "gene-ABHD16A",
    "gene-ABHD16B",
    "gene-ABHD17A",
    "gene-ABHD17B",
    "gene-ABHD17C",
    "gene-ABHD18",
    "gene-ABHD2",
    "gene-ABHD3",
    "gene-ABHD4",
    "gene-ABHD5",
    "gene-ABHD6",
    "gene-ABHD8",
    "gene-ABI1",
    "gene-ABI2",
    "gene-ABI3",
    "gene-ABI3BP",
    "gene-ABITRAM",
    "gene-ABL1",
    "gene-ABL2",
    "gene-ABLIM1",
    "gene-ABLIM2",
    "gene-ABLIM3",
    "gene-ABO",
    "gene-ABR",
    "gene-ABRA",
    "gene-ABRACL",
    "gene-ABRAXAS1",
    "gene-ABRAXAS2",
    "gene-ABT1",
    "gene-ABTB1",
    "gene-ABTB2",
    "gene-ABTB3",
    "gene-ACAA1",
    "gene-ACAA2",
    "gene-ACACA",
    "gene-ACACB",
    "gene-ACAD10",
    "gene-ACAD11",
    "gene-ACAD8",
    "gene-ACAD9",
    "gene-ACADL",
    "gene-ACADM",
    "gene-ACADS",
    "gene-ACADSB",
    "gene-ACADVL",
    "gene-ACAN",
    "gene-ACAP1",
    "gene-ACAP2",
    "gene-ACAP3",
    "gene-ACAT1",
    "gene-ACAT2",
    "gene-ACBD3",
    "gene-ACBD4",
    "gene-ACBD5",
    "gene-ACBD6",
    "gene-ACBD7",
    "gene-ACCS",
    "gene-ACCSL",
    "gene-ACD",
    "gene-ACE",
    "gene-ACE2",
    "gene-ACER1",
    "gene-ACER2",
    "gene-ACER3",
    "gene-ACHE",
    "gene-ACIN1",
    "gene-ACKR1",
    "gene-ACKR2",
    "gene-ACKR3",
    "gene-ACKR4",
    "gene-ACLY",
    "gene-ACMSD",
    "gene-ACO1",
    "gene-ACO2",
    "gene-ACOD1",
    "gene-ACOT1",
    "gene-ACOT11",
    "gene-ACOT12",
    "gene-ACOT13",
    "gene-ACOT2",
    "gene-ACOT4",
    "gene-ACOT6",
    "gene-ACOT7",
    "gene-ACOT8",
    "gene-ACOT9",
    "gene-ACOX1",
    "gene-ACOX2",
    "gene-ACOX3",
    "gene-ACOXL",
    "gene-ACP1",
    "gene-ACP2",
    "gene-ACP3",
    "gene-ACP4",
    "gene-ACP5",
    "gene-ACP6",
    "gene-ACP7",
    "gene-ACR",
    "gene-ACRBP",
    "gene-ACRV1",
    "gene-ACSBG1",
    "gene-ACSBG2",
    "gene-ACSF2",
    "gene-ACSF3",
    "gene-ACSL1",
    "gene-ACSL3",
    "gene-ACSL4",
    "gene-ACSL5",
    "gene-ACSL6",
    "gene-ACSM1",
    "gene-ACSM2A",
    "gene-ACSM2B",
    "gene-ACSM3",
    "gene-ACSM4",
    "gene-ACSM5",
    "gene-ACSM6",
    "gene-ACSS1",
    "gene-ACSS2",
    "gene-ACSS3",
    "gene-ACTA1",
    "gene-ACTA2",
    "gene-ACTB",
    "gene-ACTBL2",
    "gene-ACTC1",
    "gene-ACTG1",
    "gene-ACTG2",
    "gene-ACTL10",
    "gene-ACTL6A",
    "gene-ACTL6B",
    "gene-ACTL7A",
    "gene-ACTL7B",
    "gene-ACTL8",
    "gene-ACTL9",
    "gene-ACTMAP",
    "gene-ACTN1",
    "gene-ACTN2",
    "gene-ACTN3",
    "gene-ACTN4",
    "gene-ACTR10",
    "gene-ACTR1A",
    "gene-ACTR1B",
    "gene-ACTR2",
    "gene-ACTR3",
    "gene-ACTR3B",
    "gene-ACTR3C",
    "gene-ACTR5",
    "gene-ACTR6",
    "gene-ACTR8",
    "gene-ACTRT1",
    "gene-ACTRT2",
    "gene-ACTRT3",
    "gene-ACVR1",
    "gene-ACVR1B",
    "gene-ACVR1C",
    "gene-ACVR2A",
    "gene-ACVR2B",
    "gene-ACVRL1",
    "gene-ACY1",
    "gene-ACY3",
    "gene-ACYP1",
    "gene-ACYP2",
    "gene-ADA",
    "gene-ADA2",
    "gene-ADAD1",
    "gene-ADAD2",
    "gene-ADAL",
    "gene-ADAM10",
    "gene-ADAM11",
    "gene-ADAM12",
    "gene-ADAM15",
    "gene-ADAM17",
    "gene-ADAM18",
    "gene-ADAM19",
    "gene-ADAM2",
    "gene-ADAM20",
    "gene-ADAM21",
    "gene-ADAM22",
    "gene-ADAM23",
    "gene-ADAM28",
    "gene-ADAM29",
    "gene-ADAM30",
    "gene-ADAM32",
    "gene-ADAM33",
    "gene-ADAM7",
    "gene-ADAM8",
    "gene-ADAM9",
    "gene-ADAMDEC1",
    "gene-ADAMTS1",
    "gene-ADAMTS10",
    "gene-ADAMTS12",
    "gene-ADAMTS13",
    "gene-ADAMTS14",
    "gene-ADAMTS15",
    "gene-ADAMTS16",
    "gene-ADAMTS17",
    "gene-ADAMTS18",
    "gene-ADAMTS19",
    "gene-ADAMTS2",
    "gene-ADAMTS20",
    "gene-ADAMTS3",
    "gene-ADAMTS4",
    "gene-ADAMTS5",
    "gene-ADAMTS6",
    "gene-ADAMTS7",
    "gene-ADAMTS8",
    "gene-ADAMTS9",
    "gene-ADAMTSL1",
    "gene-ADAMTSL2",
    "gene-ADAMTSL3",
    "gene-ADAMTSL4",
    "gene-ADAMTSL5",
    "gene-ADAP1",
    "gene-ADAP2",
    "gene-ADAR",
    "gene-ADARB1",
    "gene-ADARB2",
    "gene-ADAT1",
    "gene-ADAT2",
    "gene-ADAT3",
    "gene-ADCK1",
    "gene-ADCK2",
    "gene-ADCK5",
    "gene-ADCY1",
    "gene-ADCY10",
    "gene-ADCY2",
    "gene-ADCY3",
    "gene-ADCY4",
    "gene-ADCY5",
    "gene-ADCY6",
    "gene-ADCY7",
    "gene-ADCY8",
    "gene-ADCY9",
    "gene-ADCYAP1",
    "gene-ADCYAP1R1",
    "gene-ADD1",
    "gene-ADD2",
    "gene-ADD3",
    "gene-ADGB",
    "gene-ADGRA1",
    "gene-ADGRA2",
    "gene-ADGRA3",
    "gene-ADGRB1",
    "gene-ADGRB2",
    "gene-ADGRB3",
    "gene-ADGRD1",
    "gene-ADGRD2",
    "gene-ADGRE1",
    "gene-ADGRE2",
    "gene-ADGRE3",
    "gene-ADGRE5",
    "gene-ADGRF1",
    "gene-ADGRF3",
    "gene-ADGRF4",
    "gene-ADGRF5",
    "gene-ADGRG1",
    "gene-ADGRG2",
    "gene-ADGRG3",
    "gene-ADGRG4",
    "gene-ADGRG5",
    "gene-ADGRG6",
    "gene-ADGRG7",
    "gene-ADGRL1",
    "gene-ADGRL2",
    "gene-ADGRL3",
    "gene-ADGRL4",
    "gene-ADGRV1",
    "gene-ADH1A",
    "gene-ADH1B",
    "gene-ADH1C",
    "gene-ADH4",
    "gene-ADH5",
    "gene-ADH6",
    "gene-ADH7",
    "gene-ADHFE1",
    "gene-ADI1",
    "gene-ADIG",
    "gene-ADIPOQ",
    "gene-ADIPOR1",
    "gene-ADIPOR2",
    "gene-ADIRF",
    "gene-ADISSP",
    "gene-ADK",
    "gene-ADM",
    "gene-ADM2",
    "gene-ADM5",
    "gene-ADNP",
    "gene-ADNP2",
    "gene-ADO",
    "gene-ADORA1",
    "gene-ADORA2A",
    "gene-ADORA2B",
    "gene-ADORA3",
    "gene-ADPGK",
    "gene-ADPRH",
    "gene-ADPRHL1",
    "gene-ADPRM",
    "gene-ADPRS",
    "gene-ADRA1A",
    "gene-ADRA1B",
    "gene-ADRA1D",
    "gene-ADRA2A",
    "gene-ADRA2B",
    "gene-ADRA2C",
    "gene-ADRB1",
    "gene-ADRB2",
    "gene-ADRB3",
    "gene-ADRM1",
    "gene-ADSL",
    "gene-ADSS1",
    "gene-ADSS2",
    "gene-ADTRP",
    "gene-AEBP1",
    "gene-AEBP2",
    "gene-AEN",
    "gene-AFAP1",
    "gene-AFAP1L1",
    "gene-AFAP1L2",
    "gene-AFDN",
    "gene-AFF1",
    "gene-AFF2",
    "gene-AFF3",
    "gene-AFF4",
    "gene-AFG1L",
    "gene-AFG2A",
    "gene-AFG2B",
    "gene-AFG3L2",
    "gene-AFM",
    "gene-AFMID",
    "gene-AFP",
    "gene-AFTPH",
    "gene-AGA",
    "gene-AGAP1",
    "gene-AGAP2",
    "gene-AGAP3",
    "gene-AGAP4",
    "gene-AGAP5",
    "gene-AGAP6",
    "gene-AGAP9",
    "gene-AGBL1",
    "gene-AGBL2",
    "gene-AGBL3",
    "gene-AGBL4",
    "gene-AGBL5",
    "gene-AGER",
    "gene-AGFG1",
    "gene-AGFG2",
    "gene-AGGF1",
    "gene-AGK",
    "gene-AGL",
    "gene-AGMAT",
    "gene-AGMO",
    "gene-AGO1",
    "gene-AGO2",
    "gene-AGO3",
    "gene-AGO4",
    "gene-AGPAT1",
    "gene-AGPAT2",
    "gene-AGPAT3",
    "gene-AGPAT4",
    "gene-AGPAT5",
    "gene-AGPS",
    "gene-AGR2",
    "gene-AGR3",
    "gene-AGRN",
    "gene-AGRP",
    "gene-AGT",
    "gene-AGTPBP1",
    "gene-AGTR1",
    "gene-AGTR2",
    "gene-AGTRAP",
    "gene-AGXT",
    "gene-AGXT2",
    "gene-AHCTF1",
    "gene-AHCY",
    "gene-AHCYL1",
    "gene-AHCYL2",
    "gene-AHDC1",
    "gene-AHI1",
    "gene-AHNAK",
    "gene-AHNAK2",
    "gene-AHR",
    "gene-AHRR",
    "gene-AHSA1",
    "gene-AHSG",
    "gene-AHSP",
    "gene-AICDA",
    "gene-AIDA",
    "gene-AIF1",
    "gene-AIF1L",
    "gene-AIFM1",
    "gene-AIFM2",
    "gene-AIFM3",
    "gene-AIG1",
    "gene-AIM2",
    "gene-AIMP1",
    "gene-AIMP2",
    "gene-AIP",
    "gene-AIPL1",
    "gene-AIRE",
    "gene-AIRIM",
    "gene-AJAP1",
    "gene-AJM1",
    "gene-AJUBA",
    "gene-AK1",
    "gene-AK2",
    "gene-AK3",
    "gene-AK4",
    "gene-AK5",
    "gene-AK6",
    "gene-AK7",
    "gene-AK8",
    "gene-AK9",
    "gene-AKAIN1",
    "gene-AKAP1",
    "gene-AKAP10",
    "gene-AKAP11",
    "gene-AKAP12",
    "gene-AKAP13",
    "gene-AKAP14",
    "gene-AKAP17A",
    "gene-AKAP3",
    "gene-AKAP4",
    "gene-AKAP5",
    "gene-AKAP6",
    "gene-AKAP7",
    "gene-AKAP8",
    "gene-AKAP8L",
    "gene-AKAP9",
    "gene-AKIP1",
    "gene-AKIRIN1",
    "gene-AKIRIN2",
    "gene-AKNA",
    "gene-AKNAD1",
    "gene-AKR1A1",
    "gene-AKR1B1",
    "gene-AKR1B10",
    "gene-AKR1B15",
    "gene-AKR1C1",
    "gene-AKR1C2",
    "gene-AKR1C3",
    "gene-AKR1C4",
    "gene-AKR1C8",
    "gene-AKR1D1",
    "gene-AKR1E2",
    "gene-AKR7A2",
    "gene-AKR7A3",
    "gene-AKR7L",
    "gene-AKT1",
    "gene-AKT1S1",
    "gene-AKT2",
    "gene-AKT3",
    "gene-AKTIP",
    "gene-ALAD",
    "gene-ALAS1",
    "gene-ALAS2",
    "gene-ALB",
    "gene-ALCAM",
    "gene-ALDH16A1",
    "gene-ALDH18A1",
    "gene-ALDH1A1",
    "gene-ALDH1A2",
    "gene-ALDH1A3",
    "gene-ALDH1B1",
    "gene-ALDH1L1",
    "gene-ALDH1L2",
    "gene-ALDH2",
    "gene-ALDH3A1",
    "gene-ALDH3A2",
    "gene-ALDH3B1",
    "gene-ALDH3B2",
    "gene-ALDH4A1",
    "gene-ALDH5A1",
    "gene-ALDH6A1",
    "gene-ALDH7A1",
    "gene-ALDH8A1",
    "gene-ALDH9A1",
    "gene-ALDOA",
    "gene-ALDOB",
    "gene-ALDOC",
    "gene-ALG1",
    "gene-ALG10",
    "gene-ALG10B",
    "gene-ALG11",
    "gene-ALG12",
    "gene-ALG13",
    "gene-ALG14",
    "gene-ALG1L2",
    "gene-ALG2",
    "gene-ALG3",
    "gene-ALG5",
    "gene-ALG6",
    "gene-ALG8",
    "gene-ALG9",
    "gene-ALK",
    "gene-ALKAL1",
    "gene-ALKAL2",
    "gene-ALKBH1",
    "gene-ALKBH2",
    "gene-ALKBH3",
    "gene-ALKBH4",
    "gene-ALKBH5",
    "gene-ALKBH6",
    "gene-ALKBH7",
    "gene-ALKBH8",
    "gene-ALLC",
    "gene-ALMS1",
    "gene-ALOX12",
    "gene-ALOX12B",
    "gene-ALOX15",
    "gene-ALOX15B",
    "gene-ALOX5",
    "gene-ALOX5AP",
    "gene-ALOXE3",
    "gene-ALPG",
    "gene-ALPI",
    "gene-ALPK1",
    "gene-ALPK2",
    "gene-ALPK3",
    "gene-ALPL",
    "gene-ALPP",
    "gene-ALS2",
    "gene-ALS2CL",
    "gene-ALX1",
    "gene-ALX3",
    "gene-ALX4",
    "gene-ALYREF",
    "gene-AMACR",
    "gene-AMBN",
    "gene-AMBP",
    "gene-AMBRA1",
    "gene-AMD1",
    "gene-AMDHD1",
    "gene-AMDHD2",
    "gene-AMELX",
    "gene-AMELY",
    "gene-AMER1",
    "gene-AMER2",
    "gene-AMER3",
    "gene-AMFR",
    "gene-AMH",
    "gene-AMHR2",
    "gene-AMIGO1",
    "gene-AMIGO2",
    "gene-AMIGO3",
    "gene-AMMECR1",
    "gene-AMMECR1L",
    "gene-AMN",
    "gene-AMN1",
    "gene-AMOT",
    "gene-AMOTL1",
    "gene-AMOTL2",
    "gene-AMPD1",
    "gene-AMPD2",
    "gene-AMPD3",
    "gene-AMPH",
    "gene-AMT",
    "gene-AMTN",
    "gene-AMY1A",
    "gene-AMY1B",
    "gene-AMY1C",
    "gene-AMY2A",
    "gene-AMY2B",
    "gene-AMZ1",
    "gene-AMZ2",
    "gene-ANAPC1",
    "gene-ANAPC10",
    "gene-ANAPC11",
    "gene-ANAPC13",
    "gene-ANAPC15",
    "gene-ANAPC16",
    "gene-ANAPC2",
    "gene-ANAPC4",
    "gene-ANAPC5",
    "gene-ANAPC7",
    "gene-ANG",
    "gene-ANGEL1",
    "gene-ANGEL2",
    "gene-ANGPT1",
    "gene-ANGPT2",
    "gene-ANGPT4",
    "gene-ANGPTL1",
    "gene-ANGPTL2",
    "gene-ANGPTL3",
    "gene-ANGPTL4",
    "gene-ANGPTL5",
    "gene-ANGPTL6",
    "gene-ANGPTL7",
    "gene-ANGPTL8",
    "gene-ANHX",
    "gene-ANK1",
    "gene-ANK2",
    "gene-ANK3",
    "gene-ANKAR",
    "gene-ANKDD1A",
    "gene-ANKDD1B",
    "gene-ANKEF1",
    "gene-ANKFN1",
    "gene-ANKFY1",
    "gene-ANKH",
    "gene-ANKHD1",
    "gene-ANKIB1",
    "gene-ANKK1",
    "gene-ANKLE1",
    "gene-ANKLE2",
    "gene-ANKMY1",
    "gene-ANKMY2",
    "gene-ANKRA2",
    "gene-ANKRD1",
    "gene-ANKRD10",
    "gene-ANKRD11",
    "gene-ANKRD12",
    "gene-ANKRD13A",
    "gene-ANKRD13B",
    "gene-ANKRD13C",
    "gene-ANKRD13D",
    "gene-ANKRD16",
    "gene-ANKRD17",
    "gene-ANKRD18A",
    "gene-ANKRD18B",
    "gene-ANKRD2",
    "gene-ANKRD20A1",
    "gene-ANKRD22",
    "gene-ANKRD23",
    "gene-ANKRD24",
    "gene-ANKRD26",
    "gene-ANKRD27",
    "gene-ANKRD28",
    "gene-ANKRD29",
    "gene-ANKRD30A",
    "gene-ANKRD30B",
    "gene-ANKRD30BL",
    "gene-ANKRD31",
    "gene-ANKRD33",
    "gene-ANKRD33B",
    "gene-ANKRD34A",
    "gene-ANKRD34B",
    "gene-ANKRD34C",
    "gene-ANKRD35",
    "gene-ANKRD36",
    "gene-ANKRD36B",
    "gene-ANKRD36C",
    "gene-ANKRD37",
    "gene-ANKRD39",
    "gene-ANKRD40",
    "gene-ANKRD40CL",
    "gene-ANKRD42",
    "gene-ANKRD44",
    "gene-ANKRD45",
    "gene-ANKRD46",
    "gene-ANKRD49",
    "gene-ANKRD50",
    "gene-ANKRD52",
    "gene-ANKRD53",
    "gene-ANKRD54",
    "gene-ANKRD55",
    "gene-ANKRD6",
    "gene-ANKRD60",
    "gene-ANKRD61",
    "gene-ANKRD62",
    "gene-ANKRD63",
    "gene-ANKRD65",
    "gene-ANKRD66",
    "gene-ANKRD7",
    "gene-ANKRD9",
    "gene-ANKS1A",
    "gene-ANKS1B",
    "gene-ANKS3",
    "gene-ANKS4B",
    "gene-ANKS6",
    "gene-ANKUB1",
    "gene-ANKZF1",
    "gene-ANLN",
    "gene-ANO1",
    "gene-ANO10",
    "gene-ANO2",
    "gene-ANO3",
    "gene-ANO4",
    "gene-ANO5",
    "gene-ANO6",
    "gene-ANO7",
    "gene-ANO8",
    "gene-ANO9",
    "gene-ANOS1",
    "gene-ANP32A",
    "gene-ANP32B",
    "gene-ANP32D",
    "gene-ANP32E",
    "gene-ANPEP",
    "gene-ANTKMT",
    "gene-ANTXR1",
    "gene-ANTXR2",
    "gene-ANTXRL",
    "gene-ANXA1",
    "gene-ANXA10",
    "gene-ANXA11",
    "gene-ANXA13",
    "gene-ANXA2",
    "gene-ANXA2R",
    "gene-ANXA3",
    "gene-ANXA4",
    "gene-ANXA5",
    "gene-ANXA6",
    "gene-ANXA7",
    "gene-ANXA8",
    "gene-ANXA8L1",
    "gene-ANXA9",
    "gene-AOAH",
    "gene-AOC1",
    "gene-AOC2",
    "gene-AOC3",
    "gene-AOPEP",
    "gene-AOX1",
    "gene-AP1AR",
    "gene-AP1B1",
    "gene-AP1G1",
    "gene-AP1G2",
    "gene-AP1M1",
    "gene-AP1M2",
    "gene-AP1S1",
    "gene-AP1S2",
    "gene-AP1S3",
    "gene-AP2A1",
    "gene-AP2A2",
    "gene-AP2B1",
    "gene-AP2M1",
    "gene-AP2S1",
    "gene-AP3B1",
    "gene-AP3B2",
    "gene-AP3D1",
    "gene-AP3M1",
    "gene-AP3M2",
    "gene-AP3S1",
    "gene-AP3S2",
    "gene-AP4B1",
    "gene-AP4E1",
    "gene-AP4M1",
    "gene-AP4S1",
    "gene-AP5B1",
    "gene-AP5M1",
    "gene-AP5S1",
    "gene-AP5Z1",
    "gene-APAF1",
    "gene-APBA1",
    "gene-APBA2",
    "gene-APBA3",
    "gene-APBB1",
    "gene-APBB1IP",
    "gene-APBB2",
    "gene-APBB3",
    "gene-APC",
    "gene-APC2",
    "gene-APCDD1",
    "gene-APCDD1L",
    "gene-APCS",
    "gene-APEH",
    "gene-APELA",
    "gene-APEX1",
    "gene-APEX2",
    "gene-APH1A",
    "gene-APH1B",
    "gene-API5",
    "gene-APIP",
    "gene-APLF",
    "gene-APLN",
    "gene-APLNR",
    "gene-APLP1",
    "gene-APLP2",
    "gene-APMAP",
    "gene-APOA1",
    "gene-APOA2",
    "gene-APOA4",
    "gene-APOA5",
    "gene-APOB",
    "gene-APOBEC1",
    "gene-APOBEC2",
    "gene-APOBEC3A",
    "gene-APOBEC3B",
    "gene-APOBEC3C",
    "gene-APOBEC3D",
    "gene-APOBEC3F",
    "gene-APOBEC3G",
    "gene-APOBEC3H",
    "gene-APOBEC4",
    "gene-APOBR",
    "gene-APOC1",
    "gene-APOC2",
    "gene-APOC3",
    "gene-APOC4",
    "gene-APOD",
    "gene-APOE",
    "gene-APOF",
    "gene-APOH",
    "gene-APOL1",
    "gene-APOL2",
    "gene-APOL3",
    "gene-APOL4",
    "gene-APOL5",
    "gene-APOL6",
    "gene-APOLD1",
    "gene-APOM",
    "gene-APOO",
    "gene-APOOL",
    "gene-APP",
    "gene-APPBP2",
    "gene-APPL1",
    "gene-APPL2",
    "gene-APRT",
    "gene-APTX",
    "gene-AQP1",
    "gene-AQP10",
    "gene-AQP11",
    "gene-AQP12A",
    "gene-AQP12B",
    "gene-AQP2",
    "gene-AQP3",
    "gene-AQP4",
    "gene-AQP5",
    "gene-AQP6",
    "gene-AQP7",
    "gene-AQP7B",
    "gene-AQP8",
    "gene-AQP9",
    "gene-AQR",
    "gene-AR",
    "gene-ARAF",
    "gene-ARAP1",
    "gene-ARAP2",
    "gene-ARAP3",
    "gene-ARB2A",
    "gene-ARC",
    "gene-ARCN1",
    "gene-AREG",
    "gene-AREL1",
    "gene-ARF1",
    "gene-ARF3",
    "gene-ARF4",
    "gene-ARF5",
    "gene-ARF6",
    "gene-ARFGAP1",
    "gene-ARFGAP2",
    "gene-ARFGAP3",
    "gene-ARFGEF1",
    "gene-ARFGEF2",
    "gene-ARFGEF3",
    "gene-ARFIP1",
    "gene-ARFIP2",
    "gene-ARFRP1",
    "gene-ARG1",
    "gene-ARG2",
    "gene-ARGFX",
    "gene-ARGLU1",
    "gene-ARHGAP1",
    "gene-ARHGAP10",
    "gene-ARHGAP11A",
    "gene-ARHGAP11B",
    "gene-ARHGAP12",
    "gene-ARHGAP15",
    "gene-ARHGAP17",
    "gene-ARHGAP18",
    "gene-ARHGAP19",
    "gene-ARHGAP20",
    "gene-ARHGAP21",
    "gene-ARHGAP22",
    "gene-ARHGAP23",
    "gene-ARHGAP24",
    "gene-ARHGAP25",
    "gene-ARHGAP26",
    "gene-ARHGAP27",
    "gene-ARHGAP28",
    "gene-ARHGAP29",
    "gene-ARHGAP30",
    "gene-ARHGAP31",
    "gene-ARHGAP32",
    "gene-ARHGAP33",
    "gene-ARHGAP35",
    "gene-ARHGAP36",
    "gene-ARHGAP39",
    "gene-ARHGAP4",
    "gene-ARHGAP40",
    "gene-ARHGAP42",
    "gene-ARHGAP44",
    "gene-ARHGAP45",
    "gene-ARHGAP5",
    "gene-ARHGAP6",
    "gene-ARHGAP8",
    "gene-ARHGAP9",
    "gene-ARHGDIA",
    "gene-ARHGDIB",
    "gene-ARHGDIG",
    "gene-ARHGEF1",
    "gene-ARHGEF10",
    "gene-ARHGEF10L",
    "gene-ARHGEF11",
    "gene-ARHGEF12",
    "gene-ARHGEF15",
    "gene-ARHGEF16",
    "gene-ARHGEF17",
    "gene-ARHGEF18",
    "gene-ARHGEF19",
    "gene-ARHGEF2",
    "gene-ARHGEF25",
    "gene-ARHGEF26",
    "gene-ARHGEF28",
    "gene-ARHGEF3",
    "gene-ARHGEF33",
    "gene-ARHGEF35",
    "gene-ARHGEF37",
    "gene-ARHGEF38",
    "gene-ARHGEF39",
    "gene-ARHGEF4",
    "gene-ARHGEF40",
    "gene-ARHGEF5",
    "gene-ARHGEF6",
    "gene-ARHGEF7",
    "gene-ARHGEF9",
    "gene-ARID1A",
    "gene-ARID1B",
    "gene-ARID2",
    "gene-ARID3A",
    "gene-ARID3B",
    "gene-ARID3C",
    "gene-ARID4A",
    "gene-ARID4B",
    "gene-ARID5A",
    "gene-ARID5B",
    "gene-ARIH1",
    "gene-ARIH2",
    "gene-ARK2C",
    "gene-ARK2N",
    "gene-ARL1",
    "gene-ARL10",
    "gene-ARL11",
    "gene-ARL13A",
    "gene-ARL13B",
    "gene-ARL14",
    "gene-ARL14EP",
    "gene-ARL14EPL",
    "gene-ARL15",
    "gene-ARL16",
    "gene-ARL17A",
    "gene-ARL17B",
    "gene-ARL2",
    "gene-ARL2BP",
    "gene-ARL3",
    "gene-ARL4A",
    "gene-ARL4C",
    "gene-ARL4D",
    "gene-ARL5A",
    "gene-ARL5B",
    "gene-ARL5C",
    "gene-ARL6",
    "gene-ARL6IP1",
    "gene-ARL6IP4",
    "gene-ARL6IP5",
    "gene-ARL6IP6",
    "gene-ARL8A",
    "gene-ARL8B",
    "gene-ARL9",
    "gene-ARMC1",
    "gene-ARMC10",
    "gene-ARMC12",
    "gene-ARMC2",
    "gene-ARMC3",
    "gene-ARMC5",
    "gene-ARMC6",
    "gene-ARMC7",
    "gene-ARMC8",
    "gene-ARMC9",
    "gene-ARMCX1",
    "gene-ARMCX2",
    "gene-ARMCX3",
    "gene-ARMCX4",
    "gene-ARMCX5",
    "gene-ARMCX6",
    "gene-ARMH1",
    "gene-ARMH2",
    "gene-ARMH3",
    "gene-ARMH4",
    "gene-ARMS2",
    "gene-ARMT1",
    "gene-ARNT",
    "gene-ARNT2",
    "gene-ARPC1A",
    "gene-ARPC1B",
    "gene-ARPC2",
    "gene-ARPC3",
    "gene-ARPC4",
    "gene-ARPC5",
    "gene-ARPC5L",
    "gene-ARPIN",
    "gene-ARPP19",
    "gene-ARPP21",
    "gene-ARR3",
    "gene-ARRB1",
    "gene-ARRB2",
    "gene-ARRDC1",
    "gene-ARRDC2",
    "gene-ARRDC3",
    "gene-ARRDC4",
    "gene-ARRDC5",
    "gene-ARSA",
    "gene-ARSB",
    "gene-ARSD",
    "gene-ARSF",
    "gene-ARSG",
    "gene-ARSH",
    "gene-ARSI",
    "gene-ARSJ",
    "gene-ARSK",
    "gene-ARSL",
    "gene-ART1",
    "gene-ART3",
    "gene-ART4",
    "gene-ART5",
    "gene-ARTN",
    "gene-ARV1",
    "gene-ARVCF",
    "gene-ARX",
    "gene-AS3MT",
    "gene-ASAH1",
    "gene-ASAH2",
    "gene-ASAH2B",
    "gene-ASAP1",
    "gene-ASAP2",
    "gene-ASAP3",
    "gene-ASB1",
    "gene-ASB10",
    "gene-ASB11",
    "gene-ASB12",
    "gene-ASB13",
    "gene-ASB14",
    "gene-ASB15",
    "gene-ASB16",
    "gene-ASB17",
    "gene-ASB18",
    "gene-ASB2",
    "gene-ASB3",
    "gene-ASB4",
    "gene-ASB5",
    "gene-ASB6",
    "gene-ASB7",
    "gene-ASB8",
    "gene-ASB9",
    "gene-ASCC1",
    "gene-ASCC2",
    "gene-ASCC3",
    "gene-ASCL1",
    "gene-ASCL2",
    "gene-ASCL3",
    "gene-ASCL4",
    "gene-ASCL5",
    "gene-ASDURF",
    "gene-ASF1A",
    "gene-ASF1B",
    "gene-ASGR1",
    "gene-ASGR2",
    "gene-ASH1L",
    "gene-ASH2L",
    "gene-ASIC1",
    "gene-ASIC2",
    "gene-ASIC3",
    "gene-ASIC4",
    "gene-ASIC5",
    "gene-ASIP",
    "gene-ASL",
    "gene-ASMT",
    "gene-ASMTL",
    "gene-ASNS",
    "gene-ASNSD1",
    "gene-ASPA",
    "gene-ASPDH",
    "gene-ASPG",
    "gene-ASPH",
    "gene-ASPHD1",
    "gene-ASPHD2",
    "gene-ASPM",
    "gene-ASPN",
    "gene-ASPRV1",
    "gene-ASPSCR1",
    "gene-ASRGL1",
    "gene-ASS1",
    "gene-ASTE1",
    "gene-ASTL",
    "gene-ASTN1",
    "gene-ASTN2",
    "gene-ASXL1",
    "gene-ASXL2",
    "gene-ASXL3",
    "gene-ASZ1",
    "gene-ATAD1",
    "gene-ATAD2",
    "gene-ATAD2B",
    "gene-ATAD3A",
    "gene-ATAD3B",
    "gene-ATAD3C",
    "gene-ATAD5",
    "gene-ATAT1",
    "gene-ATCAY",
    "gene-ATE1",
    "gene-ATF1",
    "gene-ATF2",
    "gene-ATF3",
    "gene-ATF4",
    "gene-ATF5",
    "gene-ATF6",
    "gene-ATF6B",
    "gene-ATF7",
    "gene-ATF7IP",
    "gene-ATF7IP2",
    "gene-ATG10",
    "gene-ATG101",
    "gene-ATG12",
    "gene-ATG13",
    "gene-ATG14",
    "gene-ATG16L1",
    "gene-ATG16L2",
    "gene-ATG2A",
    "gene-ATG2B",
    "gene-ATG3",
    "gene-ATG4A",
    "gene-ATG4B",
    "gene-ATG4C",
    "gene-ATG4D",
    "gene-ATG5",
    "gene-ATG7",
    "gene-ATG9A",
    "gene-ATG9B",
    "gene-ATIC",
    "gene-ATL1",
    "gene-ATL2",
    "gene-ATL3",
    "gene-ATM",
    "gene-ATMIN",
    "gene-ATN1",
    "gene-ATOH1",
    "gene-ATOH7",
    "gene-ATOH8",
    "gene-ATOSA",
    "gene-ATOSB",
    "gene-ATOX1",
    "gene-ATP10A",
    "gene-ATP10B",
    "gene-ATP10D",
    "gene-ATP11A",
    "gene-ATP11B",
    "gene-ATP11C",
    "gene-ATP12A",
    "gene-ATP13A1",
    "gene-ATP13A2",
    "gene-ATP13A3",
    "gene-ATP13A4",
    "gene-ATP13A5",
    "gene-ATP1A1",
    "gene-ATP1A2",
    "gene-ATP1A3",
    "gene-ATP1A4",
    "gene-ATP1B1",
    "gene-ATP1B2",
    "gene-ATP1B3",
    "gene-ATP1B4",
    "gene-ATP23",
    "gene-ATP2A1",
    "gene-ATP2A2",
    "gene-ATP2A3",
    "gene-ATP2B1",
    "gene-ATP2B2",
    "gene-ATP2B3",
    "gene-ATP2B4",
    "gene-ATP2C1",
    "gene-ATP2C2",
    "gene-ATP4A",
    "gene-ATP4B",
    "gene-ATP5F1A",
    "gene-ATP5F1B",
    "gene-ATP5F1C",
    "gene-ATP5F1D",
    "gene-ATP5F1E",
    "gene-ATP5IF1",
    "gene-ATP5MC1",
    "gene-ATP5MC2",
    "gene-ATP5MC3",
    "gene-ATP5ME",
    "gene-ATP5MF",
    "gene-ATP5MG",
    "gene-ATP5MGL",
    "gene-ATP5MJ",
    "gene-ATP5MK",
    "gene-ATP5PB",
    "gene-ATP5PD",
    "gene-ATP5PF",
    "gene-ATP5PO",
    "gene-ATP6AP1",
    "gene-ATP6AP2",
    "gene-ATP6V0A1",
    "gene-ATP6V0A2",
    "gene-ATP6V0A4",
    "gene-ATP6V0B",
    "gene-ATP6V0C",
    "gene-ATP6V0D1",
    "gene-ATP6V0D2",
    "gene-ATP6V0E1",
    "gene-ATP6V0E2",
    "gene-ATP6V1A",
    "gene-ATP6V1B1",
    "gene-ATP6V1B2",
    "gene-ATP6V1C1",
    "gene-ATP6V1C2",
    "gene-ATP6V1D",
    "gene-ATP6V1E1",
    "gene-ATP6V1E2",
    "gene-ATP6V1F",
    "gene-ATP6V1G1",
    "gene-ATP6V1G2",
    "gene-ATP6V1G3",
    "gene-ATP6V1H",
    "gene-ATP7A",
    "gene-ATP7B",
    "gene-ATP8A1",
    "gene-ATP8A2",
    "gene-ATP8B1",
    "gene-ATP8B2",
    "gene-ATP8B3",
    "gene-ATP8B4",
    "gene-ATP9A",
    "gene-ATP9B",
    "gene-ATPAF1",
    "gene-ATPAF2",
    "gene-ATPSCKMT",
    "gene-ATR",
    "gene-ATRAID",
    "gene-ATRIP",
    "gene-ATRN",
    "gene-ATRNL1",
    "gene-ATRX",
    "gene-ATXN1",
    "gene-ATXN10",
    "gene-ATXN1L",
    "gene-ATXN2",
    "gene-ATXN2L",
    "gene-ATXN3",
    "gene-ATXN3L",
    "gene-ATXN7",
    "gene-ATXN7L1",
    "gene-ATXN7L2",
    "gene-ATXN7L3",
    "gene-ATXN7L3B",
    "gene-ATXN8",
    "gene-AUH",
    "gene-AUNIP",
    "gene-AUP1",
    "gene-AURKA",
    "gene-AURKAIP1",
    "gene-AURKB",
    "gene-AURKC",
    "gene-AUTS2",
    "gene-AVEN",
    "gene-AVIL",
    "gene-AVL9",
    "gene-AVP",
    "gene-AVPI1",
    "gene-AVPR1A",
    "gene-AVPR1B",
    "gene-AVPR2",
    "gene-AWAT1",
    "gene-AWAT2",
    "gene-AXDND1",
    "gene-AXIN1",
    "gene-AXIN2",
    "gene-AXL",
    "gene-AZGP1",
    "gene-AZI2",
    "gene-AZIN1",
    "gene-AZIN2",
    "gene-AZU1",
    "gene-B2M",
    "gene-B3GALNT1",
    "gene-B3GALNT2",
    "gene-B3GALT1",
    "gene-B3GALT2",
    "gene-B3GALT4",
    "gene-B3GALT5",
    "gene-B3GALT6",
    "gene-B3GALT9",
    "gene-B3GAT1",
    "gene-B3GAT2",
    "gene-B3GAT3",
    "gene-B3GLCT",
    "gene-B3GNT2",
    "gene-B3GNT3",
    "gene-B3GNT4",
    "gene-B3GNT5",
    "gene-B3GNT6",
    "gene-B3GNT7",
    "gene-B3GNT8",
    "gene-B3GNT9",
    "gene-B3GNTL1",
    "gene-B4GALNT1",
    "gene-B4GALNT2",
    "gene-B4GALNT3",
    "gene-B4GALNT4",
    "gene-B4GALT1",
    "gene-B4GALT2",
    "gene-B4GALT3",
    "gene-B4GALT4",
    "gene-B4GALT5",
    "gene-B4GALT6",
    "gene-B4GALT7",
    "gene-B4GAT1",
    "gene-B9D1",
    "gene-B9D2",
    "gene-BAALC",
    "gene-BAAT",
    "gene-BABAM1",
    "gene-BABAM2",
    "gene-BACE1",
    "gene-BACE2",
    "gene-BACH1",
    "gene-BACH2",
    "gene-BAD",
    "gene-BAG1",
    "gene-BAG2",
    "gene-BAG3",
    "gene-BAG4",
    "gene-BAG5",
    "gene-BAG6",
    "gene-BAGE",
    "gene-BAGE3",
    "gene-BAGE4",
    "gene-BAHCC1",
    "gene-BAHD1",
    "gene-BAIAP2",
    "gene-BAIAP2L1",
    "gene-BAIAP2L2",
    "gene-BAIAP3",
    "gene-BAK1",
    "gene-BAMBI",
    "gene-BANF1",
    "gene-BANF2",
    "gene-BANK1",
    "gene-BANP",
    "gene-BAP1",
    "gene-BARD1",
    "gene-BARHL1",
    "gene-BARHL2",
    "gene-BARX1",
    "gene-BARX2",
    "gene-BASP1",
    "gene-BATF",
    "gene-BATF2",
    "gene-BATF3",
    "gene-BAX",
    "gene-BAZ1A",
    "gene-BAZ1B",
    "gene-BAZ2A",
    "gene-BAZ2B",
    "gene-BBC3",
    "gene-BBIP1",
    "gene-BBLN",
    "gene-BBOF1",
    "gene-BBOX1",
    "gene-BBS1",
    "gene-BBS10",
    "gene-BBS12",
    "gene-BBS2",
    "gene-BBS4",
    "gene-BBS5",
    "gene-BBS7",
    "gene-BBS9",
    "gene-BBX",
    "gene-BCAM",
    "gene-BCAN",
    "gene-BCAP29",
    "gene-BCAP31",
    "gene-BCAR1",
    "gene-BCAR3",
    "gene-BCAS1",
    "gene-BCAS2",
    "gene-BCAS3",
    "gene-BCAS4",
    "gene-BCAT1",
    "gene-BCAT2",
    "gene-BCCIP",
    "gene-BCDIN3D",
    "gene-BCHE",
    "gene-BCKDHA",
    "gene-BCKDHB",
    "gene-BCKDK",
    "gene-BCL10",
    "gene-BCL11A",
    "gene-BCL11B",
    "gene-BCL2",
    "gene-BCL2A1",
    "gene-BCL2L1",
    "gene-BCL2L10",
    "gene-BCL2L11",
    "gene-BCL2L12",
    "gene-BCL2L13",
    "gene-BCL2L14",
    "gene-BCL2L15",
    "gene-BCL2L2",
    "gene-BCL3",
    "gene-BCL6",
    "gene-BCL6B",
    "gene-BCL7A",
    "gene-BCL7B",
    "gene-BCL7C",
    "gene-BCL9",
    "gene-BCL9L",
    "gene-BCLAF1",
    "gene-BCLAF3",
    "gene-BCO1",
    "gene-BCO2",
    "gene-BCOR",
    "gene-BCORL1",
    "gene-BCR",
    "gene-BCS1L",
    "gene-BDH1",
    "gene-BDH2",
    "gene-BDKRB1",
    "gene-BDKRB2",
    "gene-BDNF",
    "gene-BDP1",
    "gene-BEAN1",
    "gene-BECN1",
    "gene-BECN2",
    "gene-BEGAIN",
    "gene-BEND2",
    "gene-BEND3",
    "gene-BEND4",
    "gene-BEND5",
    "gene-BEND6",
    "gene-BEND7",
    "gene-BEST1",
    "gene-BEST2",
    "gene-BEST3",
    "gene-BEST4",
    "gene-BET1",
    "gene-BET1L",
    "gene-BEX1",
    "gene-BEX2",
    "gene-BEX3",
    "gene-BEX4",
    "gene-BEX5",
    "gene-BFAR",
    "gene-BFSP1",
    "gene-BFSP2",
    "gene-BGLAP",
    "gene-BGN",
    "gene-BHLHA15",
    "gene-BHLHA9",
    "gene-BHLHE22",
    "gene-BHLHE23",
    "gene-BHLHE40",
    "gene-BHLHE41",
    "gene-BHMT",
    "gene-BHMT2",
    "gene-BICC1",
    "gene-BICD1",
    "gene-BICD2",
    "gene-BICDL1",
    "gene-BICDL2",
    "gene-BICRA",
    "gene-BICRAL",
    "gene-BID",
    "gene-BIK",
    "gene-BIN1",
    "gene-BIN2",
    "gene-BIN3",
    "gene-BIRC2",
    "gene-BIRC3",
    "gene-BIRC5",
    "gene-BIRC6",
    "gene-BIRC7",
    "gene-BIRC8",
    "gene-BIVM",
    "gene-BLACAT1",
    "gene-BLCAP",
    "gene-BLID",
    "gene-BLK",
    "gene-BLM",
    "gene-BLMH",
    "gene-BLNK",
    "gene-BLOC1S1",
    "gene-BLOC1S2",
    "gene-BLOC1S3",
    "gene-BLOC1S4",
    "gene-BLOC1S5",
    "gene-BLOC1S6",
    "gene-BLTP1",
    "gene-BLTP2",
    "gene-BLTP3A",
    "gene-BLTP3B",
    "gene-BLVRA",
    "gene-BLVRB",
    "gene-BLZF1",
    "gene-BMAL1",
    "gene-BMAL2",
    "gene-BMERB1",
    "gene-BMF",
    "gene-BMI1",
    "gene-BMP1",
    "gene-BMP10",
    "gene-BMP15",
    "gene-BMP2",
    "gene-BMP2K",
    "gene-BMP3",
    "gene-BMP4",
    "gene-BMP5",
    "gene-BMP6",
    "gene-BMP7",
    "gene-BMP8A",
    "gene-BMP8B",
    "gene-BMPER",
    "gene-BMPR1A",
    "gene-BMPR1B",
    "gene-BMPR2",
    "gene-BMS1",
    "gene-BMT2",
    "gene-BMX",
    "gene-BNC1",
    "gene-BNC2",
    "gene-BNIP1",
    "gene-BNIP2",
    "gene-BNIP3",
    "gene-BNIP3L",
    "gene-BNIP5",
    "gene-BNIPL",
    "gene-BOC",
    "gene-BOD1",
    "gene-BOD1L1",
    "gene-BOD1L2",
    "gene-BOK",
    "gene-BOLA1",
    "gene-BOLA2",
    "gene-BOLA2B",
    "gene-BOLA3",
    "gene-BOLL",
    "gene-BOP1",
    "gene-BORA",
    "gene-BORCS5",
    "gene-BORCS6",
    "gene-BORCS7",
    "gene-BORCS8",
    "gene-BPGM",
    "gene-BPHL",
    "gene-BPI",
    "gene-BPIFA1",
    "gene-BPIFA2",
    "gene-BPIFA3",
    "gene-BPIFB1",
    "gene-BPIFB2",
    "gene-BPIFB3",
    "gene-BPIFB4",
    "gene-BPIFB6",
    "gene-BPIFC",
    "gene-BPNT1",
    "gene-BPNT2",
    "gene-BPTF",
    "gene-BPY2",
    "gene-BPY2B",
    "gene-BPY2C",
    "gene-BRAF",
    "gene-BRAP",
    "gene-BRAT1",
    "gene-BRCA1",
    "gene-BRCA2",
    "gene-BRCC3",
    "gene-BRD1",
    "gene-BRD10",
    "gene-BRD2",
    "gene-BRD3",
    "gene-BRD3OS",
    "gene-BRD4",
    "gene-BRD7",
    "gene-BRD8",
    "gene-BRD9",
    "gene-BRDT",
    "gene-BRF1",
    "gene-BRF2",
    "gene-BRI3",
    "gene-BRI3BP",
    "gene-BRICD5",
    "gene-BRINP1",
    "gene-BRINP2",
    "gene-BRINP3",
    "gene-BRIP1",
    "gene-BRIX1",
    "gene-BRK1",
    "gene-BRME1",
    "gene-BRMS1",
    "gene-BRMS1L",
    "gene-BROX",
    "gene-BRPF1",
    "gene-BRPF3",
    "gene-BRS3",
    "gene-BRSK1",
    "gene-BRSK2",
    "gene-BRWD1",
    "gene-BRWD3",
    "gene-BSCL2",
    "gene-BSDC1",
    "gene-BSG",
    "gene-BSN",
    "gene-BSND",
    "gene-BSPH1",
    "gene-BSPRY",
    "gene-BST1",
    "gene-BST2",
    "gene-BSX",
    "gene-BTAF1",
    "gene-BTBD1",
    "gene-BTBD10",
    "gene-BTBD16",
    "gene-BTBD17",
    "gene-BTBD18",
    "gene-BTBD19",
    "gene-BTBD2",
    "gene-BTBD3",
    "gene-BTBD6",
    "gene-BTBD7",
    "gene-BTBD8",
    "gene-BTBD9",
    "gene-BTC",
    "gene-BTD",
    "gene-BTF3",
    "gene-BTF3L4",
    "gene-BTG1",
    "gene-BTG2",
    "gene-BTG3",
    "gene-BTG4",
    "gene-BTK",
    "gene-BTLA",
    "gene-BTN1A1",
    "gene-BTN2A1",
    "gene-BTN2A2",
    "gene-BTN3A1",
    "gene-BTN3A2",
    "gene-BTN3A3",
    "gene-BTNL2",
    "gene-BTNL3",
    "gene-BTNL8",
    "gene-BTNL9",
    "gene-BTRC",
    "gene-BUB1",
    "gene-BUB1B",
    "gene-BUB3",
    "gene-BUD13",
    "gene-BUD23",
    "gene-BUD31",
    "gene-BVES",
    "gene-BYSL",
    "gene-BZW1",
    "gene-BZW2",
    "gene-C10ORF105",
    "gene-C10ORF120",
    "gene-C10ORF126",
    "gene-C10ORF143",
    "gene-C10ORF53",
    "gene-C10ORF62",
    "gene-C10ORF67",
    "gene-C10ORF71",
    "gene-C10ORF88",
    "gene-C10ORF90",
    "gene-C10ORF95",
    "gene-C11ORF16",
    "gene-C11ORF21",
    "gene-C11ORF24",
    "gene-C11ORF42",
    "gene-C11ORF52",
    "gene-C11ORF54",
    "gene-C11ORF58",
    "gene-C11ORF65",
    "gene-C11ORF68",
    "gene-C11ORF71",
    "gene-C11ORF86",
    "gene-C11ORF87",
    "gene-C11ORF91",
    "gene-C11ORF96",
    "gene-C11ORF97",
    "gene-C11ORF98",
    "gene-C12ORF4",
    "gene-C12ORF42",
    "gene-C12ORF43",
    "gene-C12ORF50",
    "gene-C12ORF54",
    "gene-C12ORF56",
    "gene-C12ORF57",
    "gene-C12ORF60",
    "gene-C12ORF71",
    "gene-C12ORF75",
    "gene-C12ORF76",
    "gene-C13ORF42",
    "gene-C13ORF46",
    "gene-C14ORF119",
    "gene-C14ORF132",
    "gene-C14ORF178",
    "gene-C14ORF180",
    "gene-C14ORF28",
    "gene-C14ORF39",
    "gene-C14ORF93",
    "gene-C15ORF39",
    "gene-C15ORF40",
    "gene-C15ORF48",
    "gene-C15ORF61",
    "gene-C15ORF62",
    "gene-C16ORF46",
    "gene-C16ORF54",
    "gene-C16ORF74",
    "gene-C16ORF78",
    "gene-C16ORF82",
    "gene-C16ORF86",
    "gene-C16ORF87",
    "gene-C16ORF89",
    "gene-C16ORF90",
    "gene-C16ORF92",
    "gene-C16ORF95",
    "gene-C16ORF96",
    "gene-C17ORF100",
    "gene-C17ORF107",
    "gene-C17ORF113",
    "gene-C17ORF114",
    "gene-C17ORF49",
    "gene-C17ORF50",
    "gene-C17ORF58",
    "gene-C17ORF67",
    "gene-C17ORF75",
    "gene-C17ORF78",
    "gene-C17ORF80",
    "gene-C17ORF99",
    "gene-C18ORF21",
    "gene-C18ORF32",
    "gene-C18ORF54",
    "gene-C18ORF63",
    "gene-C19ORF12",
    "gene-C19ORF18",
    "gene-C19ORF25",
    "gene-C19ORF33",
    "gene-C19ORF38",
    "gene-C19ORF44",
    "gene-C19ORF47",
    "gene-C19ORF53",
    "gene-C19ORF67",
    "gene-C19ORF73",
    "gene-C19ORF81",
    "gene-C19ORF84",
    "gene-C19ORF85",
    "gene-C1D",
    "gene-C1GALT1",
    "gene-C1GALT1C1",
    "gene-C1GALT1C1L",
    "gene-C1ORF105",
    "gene-C1ORF115",
    "gene-C1ORF116",
    "gene-C1ORF122",
    "gene-C1ORF127",
    "gene-C1ORF131",
    "gene-C1ORF141",
    "gene-C1ORF146",
    "gene-C1ORF159",
    "gene-C1ORF162",
    "gene-C1ORF167",
    "gene-C1ORF174",
    "gene-C1ORF185",
    "gene-C1ORF198",
    "gene-C1ORF202",
    "gene-C1ORF21",
    "gene-C1ORF210",
    "gene-C1ORF216",
    "gene-C1ORF226",
    "gene-C1ORF232",
    "gene-C1ORF35",
    "gene-C1ORF43",
    "gene-C1ORF50",
    "gene-C1ORF52",
    "gene-C1ORF53",
    "gene-C1ORF54",
    "gene-C1ORF56",
    "gene-C1ORF74",
    "gene-C1ORF87",
    "gene-C1ORF94",
    "gene-C1QA",
    "gene-C1QB",
    "gene-C1QBP",
    "gene-C1QC",
    "gene-C1QL1",
    "gene-C1QL2",
    "gene-C1QL3",
    "gene-C1QL4",
    "gene-C1QTNF1",
    "gene-C1QTNF12",
    "gene-C1QTNF2",
    "gene-C1QTNF3",
    "gene-C1QTNF4",
    "gene-C1QTNF5",
    "gene-C1QTNF6",
    "gene-C1QTNF7",
    "gene-C1QTNF8",
    "gene-C1QTNF9",
    "gene-C1QTNF9B",
    "gene-C1R",
    "gene-C1RL",
    "gene-C1S",
    "gene-C2",
    "gene-C20ORF141",
    "gene-C20ORF144",
    "gene-C20ORF173",
    "gene-C20ORF202",
    "gene-C20ORF203",
    "gene-C20ORF204",
    "gene-C20ORF96",
    "gene-C21ORF140",
    "gene-C21ORF58",
    "gene-C21ORF62",
    "gene-C21ORF91",
    "gene-C22ORF15",
    "gene-C22ORF23",
    "gene-C22ORF31",
    "gene-C22ORF39",
    "gene-C22ORF42",
    "gene-C2CD2",
    "gene-C2CD2L",
    "gene-C2CD3",
    "gene-C2CD4A",
    "gene-C2CD4B",
    "gene-C2CD4C",
    "gene-C2CD4D",
    "gene-C2CD5",
    "gene-C2CD6",
    "gene-C2ORF15",
    "gene-C2ORF42",
    "gene-C2ORF49",
    "gene-C2ORF50",
    "gene-C2ORF66",
    "gene-C2ORF68",
    "gene-C2ORF69",
    "gene-C2ORF72",
    "gene-C2ORF73",
    "gene-C2ORF74",
    "gene-C2ORF76",
    "gene-C2ORF78",
    "gene-C2ORF80",
    "gene-C2ORF81",
    "gene-C2ORF83",
    "gene-C2ORF88",
    "gene-C2ORF92",
    "gene-C3",
    "gene-C3AR1",
    "gene-C3ORF18",
    "gene-C3ORF20",
    "gene-C3ORF22",
    "gene-C3ORF33",
    "gene-C3ORF38",
    "gene-C3ORF49",
    "gene-C3ORF52",
    "gene-C3ORF62",
    "gene-C3ORF70",
    "gene-C3ORF80",
    "gene-C3ORF84",
    "gene-C3ORF85",
    "gene-C4A",
    "gene-C4B",
    "gene-C4BPA",
    "gene-C4BPB",
    "gene-C4ORF17",
    "gene-C4ORF19",
    "gene-C4ORF3",
    "gene-C4ORF33",
    "gene-C4ORF36",
    "gene-C4ORF46",
    "gene-C4ORF50",
    "gene-C4ORF51",
    "gene-C4ORF54",
    "gene-C5",
    "gene-C5AR1",
    "gene-C5AR2",
    "gene-C5ORF15",
    "gene-C5ORF22",
    "gene-C5ORF24",
    "gene-C5ORF34",
    "gene-C5ORF46",
    "gene-C5ORF47",
    "gene-C5ORF52",
    "gene-C5ORF58",
    "gene-C5ORF60",
    "gene-C5ORF63",
    "gene-C6",
    "gene-C6ORF118",
    "gene-C6ORF120",
    "gene-C6ORF132",
    "gene-C6ORF136",
    "gene-C6ORF141",
    "gene-C6ORF15",
    "gene-C6ORF163",
    "gene-C6ORF226",
    "gene-C6ORF47",
    "gene-C6ORF52",
    "gene-C6ORF58",
    "gene-C6ORF62",
    "gene-C6ORF89",
    "gene-C7",
    "gene-C7ORF25",
    "gene-C7ORF33",
    "gene-C7ORF50",
    "gene-C7ORF57",
    "gene-C8A",
    "gene-C8B",
    "gene-C8G",
    "gene-C8ORF33",
    "gene-C8ORF34",
    "gene-C8ORF48",
    "gene-C8ORF58",
    "gene-C8ORF74",
    "gene-C8ORF76",
    "gene-C8ORF82",
    "gene-C8ORF88",
    "gene-C8ORF89",
    "gene-C8ORF90",
    "gene-C9",
    "gene-C9ORF152",
    "gene-C9ORF153",
    "gene-C9ORF40",
    "gene-C9ORF43",
    "gene-C9ORF50",
    "gene-C9ORF57",
    "gene-C9ORF72",
    "gene-C9ORF78",
    "gene-C9ORF85",
    "gene-CA1",
    "gene-CA10",
    "gene-CA11",
    "gene-CA12",
    "gene-CA13",
    "gene-CA14",
    "gene-CA2",
    "gene-CA3",
    "gene-CA4",
    "gene-CA5A",
    "gene-CA5B",
    "gene-CA6",
    "gene-CA7",
    "gene-CA8",
    "gene-CA9",
    "gene-CAAP1",
    "gene-CAB39",
    "gene-CAB39L",
    "gene-CABCOCO1",
    "gene-CABIN1",
    "gene-CABLES1",
    "gene-CABLES2",
    "gene-CABP1",
    "gene-CABP2",
    "gene-CABP4",
    "gene-CABP5",
    "gene-CABP7",
    "gene-CABS1",
    "gene-CABYR",
    "gene-CACFD1",
    "gene-CACHD1",
    "gene-CACNA1A",
    "gene-CACNA1B",
    "gene-CACNA1C",
    "gene-CACNA1D",
    "gene-CACNA1E",
    "gene-CACNA1F",
    "gene-CACNA1G",
    "gene-CACNA1H",
    "gene-CACNA1I",
    "gene-CACNA1S",
    "gene-CACNA2D1",
    "gene-CACNA2D2",
    "gene-CACNA2D3",
    "gene-CACNA2D4",
    "gene-CACNB1",
    "gene-CACNB2",
    "gene-CACNB3",
    "gene-CACNB4",
    "gene-CACNG1",
    "gene-CACNG2",
    "gene-CACNG3",
    "gene-CACNG4",
    "gene-CACNG5",
    "gene-CACNG6",
    "gene-CACNG7",
    "gene-CACNG8",
    "gene-CACTIN",
    "gene-CACUL1",
    "gene-CACYBP",
    "gene-CAD",
    "gene-CADM1",
    "gene-CADM2",
    "gene-CADM3",
    "gene-CADM4",
    "gene-CADPS",
    "gene-CADPS2",
    "gene-CAGE1",
    "gene-CALB1",
    "gene-CALB2",
    "gene-CALCA",
    "gene-CALCB",
    "gene-CALCOCO1",
    "gene-CALCOCO2",
    "gene-CALCR",
    "gene-CALCRL",
    "gene-CALD1",
    "gene-CALHM1",
    "gene-CALHM2",
    "gene-CALHM3",
    "gene-CALHM4",
    "gene-CALHM5",
    "gene-CALHM6",
    "gene-CALM1",
    "gene-CALM2",
    "gene-CALM3",
    "gene-CALML3",
    "gene-CALML4",
    "gene-CALML5",
    "gene-CALML6",
    "gene-CALN1",
    "gene-CALR",
    "gene-CALR3",
    "gene-CALU",
    "gene-CALY",
    "gene-CAMK1",
    "gene-CAMK1D",
    "gene-CAMK1G",
    "gene-CAMK2A",
    "gene-CAMK2B",
    "gene-CAMK2D",
    "gene-CAMK2G",
    "gene-CAMK2N1",
    "gene-CAMK2N2",
    "gene-CAMK4",
    "gene-CAMKK1",
    "gene-CAMKK2",
    "gene-CAMKMT",
    "gene-CAMKV",
    "gene-CAMLG",
    "gene-CAMP",
    "gene-CAMSAP1",
    "gene-CAMSAP2",
    "gene-CAMSAP3",
    "gene-CAMTA1",
    "gene-CAMTA2",
    "gene-CAND1",
    "gene-CAND2",
    "gene-CANT1",
    "gene-CANX",
    "gene-CAP1",
    "gene-CAP2",
    "gene-CAPG",
    "gene-CAPN1",
    "gene-CAPN10",
    "gene-CAPN11",
    "gene-CAPN12",
    "gene-CAPN13",
    "gene-CAPN14",
    "gene-CAPN15",
    "gene-CAPN2",
    "gene-CAPN3",
    "gene-CAPN5",
    "gene-CAPN6",
    "gene-CAPN7",
    "gene-CAPN8",
    "gene-CAPN9",
    "gene-CAPNS1",
    "gene-CAPNS2",
    "gene-CAPRIN1",
    "gene-CAPRIN2",
    "gene-CAPS",
    "gene-CAPS2",
    "gene-CAPSL",
    "gene-CAPZA1",
    "gene-CAPZA2",
    "gene-CAPZA3",
    "gene-CAPZB",
    "gene-CARD10",
    "gene-CARD11",
    "gene-CARD14",
    "gene-CARD16",
    "gene-CARD18",
    "gene-CARD19",
    "gene-CARD6",
    "gene-CARD8",
    "gene-CARD9",
    "gene-CARF",
    "gene-CARHSP1",
    "gene-CARM1",
    "gene-CARMIL1",
    "gene-CARMIL2",
    "gene-CARMIL3",
    "gene-CARNMT1",
    "gene-CARNS1",
    "gene-CARS1",
    "gene-CARS2",
    "gene-CARTPT",
    "gene-CASC3",
    "gene-CASD1",
    "gene-CASK",
    "gene-CASKIN1",
    "gene-CASKIN2",
    "gene-CASP1",
    "gene-CASP10",
    "gene-CASP12",
    "gene-CASP14",
    "gene-CASP2",
    "gene-CASP3",
    "gene-CASP4",
    "gene-CASP5",
    "gene-CASP6",
    "gene-CASP7",
    "gene-CASP8",
    "gene-CASP8AP2",
    "gene-CASP9",
    "gene-CASQ1",
    "gene-CASQ2",
    "gene-CASR",
    "gene-CASS4",
    "gene-CAST",
    "gene-CASTOR1",
    "gene-CASTOR2",
    "gene-CASZ1",
    "gene-CAT",
    "gene-CATIP",
    "gene-CATSPER1",
    "gene-CATSPER2",
    "gene-CATSPER3",
    "gene-CATSPER4",
    "gene-CATSPERB",
    "gene-CATSPERD",
    "gene-CATSPERE",
    "gene-CATSPERG",
    "gene-CATSPERZ",
    "gene-CAV1",
    "gene-CAV2",
    "gene-CAV3",
    "gene-CAVIN1",
    "gene-CAVIN2",
    "gene-CAVIN3",
    "gene-CAVIN4",
    "gene-CBARP",
    "gene-CBFA2T2",
    "gene-CBFA2T3",
    "gene-CBFB",
    "gene-CBL",
    "gene-CBLB",
    "gene-CBLC",
    "gene-CBLIF",
    "gene-CBLL1",
    "gene-CBLL2",
    "gene-CBLN1",
    "gene-CBLN2",
    "gene-CBLN3",
    "gene-CBLN4",
    "gene-CBR1",
    "gene-CBR3",
    "gene-CBR4",
    "gene-CBS",
    "gene-CBX1",
    "gene-CBX2",
    "gene-CBX3",
    "gene-CBX4",
    "gene-CBX5",
    "gene-CBX6",
    "gene-CBX7",
    "gene-CBX8",
    "gene-CBY1",
    "gene-CBY2",
    "gene-CBY3",
    "gene-CC2D1A",
    "gene-CC2D1B",
    "gene-CC2D2A",
    "gene-CC2D2B",
    "gene-CCAR1",
    "gene-CCAR2",
    "gene-CCBE1",
    "gene-CCDC102A",
    "gene-CCDC102B",
    "gene-CCDC103",
    "gene-CCDC106",
    "gene-CCDC107",
    "gene-CCDC110",
    "gene-CCDC112",
    "gene-CCDC113",
    "gene-CCDC115",
    "gene-CCDC116",
    "gene-CCDC117",
    "gene-CCDC12",
    "gene-CCDC120",
    "gene-CCDC121",
    "gene-CCDC122",
    "gene-CCDC124",
    "gene-CCDC125",
    "gene-CCDC126",
    "gene-CCDC127",
    "gene-CCDC13",
    "gene-CCDC134",
    "gene-CCDC136",
    "gene-CCDC137",
    "gene-CCDC138",
    "gene-CCDC14",
    "gene-CCDC141",
    "gene-CCDC142",
    "gene-CCDC144A",
    "gene-CCDC146",
    "gene-CCDC148",
    "gene-CCDC149",
    "gene-CCDC15",
    "gene-CCDC150",
    "gene-CCDC152",
    "gene-CCDC153",
    "gene-CCDC154",
    "gene-CCDC157",
    "gene-CCDC158",
    "gene-CCDC159",
    "gene-CCDC160",
    "gene-CCDC163",
    "gene-CCDC166",
    "gene-CCDC167",
    "gene-CCDC168",
    "gene-CCDC169",
    "gene-CCDC17",
    "gene-CCDC170",
    "gene-CCDC171",
    "gene-CCDC172",
    "gene-CCDC174",
    "gene-CCDC175",
    "gene-CCDC177",
    "gene-CCDC178",
    "gene-CCDC179",
    "gene-CCDC18",
    "gene-CCDC180",
    "gene-CCDC181",
    "gene-CCDC182",
    "gene-CCDC183",
    "gene-CCDC184",
    "gene-CCDC185",
    "gene-CCDC186",
    "gene-CCDC187",
    "gene-CCDC188",
    "gene-CCDC190",
    "gene-CCDC191",
    "gene-CCDC192",
    "gene-CCDC194",
    "gene-CCDC195",
    "gene-CCDC196",
    "gene-CCDC197",
    "gene-CCDC198",
    "gene-CCDC200",
    "gene-CCDC201",
    "gene-CCDC22",
    "gene-CCDC24",
    "gene-CCDC25",
    "gene-CCDC27",
    "gene-CCDC28A",
    "gene-CCDC28B",
    "gene-CCDC3",
    "gene-CCDC30",
    "gene-CCDC32",
    "gene-CCDC33",
    "gene-CCDC34",
    "gene-CCDC38",
    "gene-CCDC39",
    "gene-CCDC40",
    "gene-CCDC42",
    "gene-CCDC43",
    "gene-CCDC47",
    "gene-CCDC50",
    "gene-CCDC51",
    "gene-CCDC54",
    "gene-CCDC57",
    "gene-CCDC59",
    "gene-CCDC6",
    "gene-CCDC60",
    "gene-CCDC61",
    "gene-CCDC62",
    "gene-CCDC63",
    "gene-CCDC65",
    "gene-CCDC66",
    "gene-CCDC68",
    "gene-CCDC69",
    "gene-CCDC7",
    "gene-CCDC70",
    "gene-CCDC71",
    "gene-CCDC71L",
    "gene-CCDC73",
    "gene-CCDC74A",
    "gene-CCDC74B",
    "gene-CCDC77",
    "gene-CCDC78",
    "gene-CCDC8",
    "gene-CCDC80",
    "gene-CCDC81",
    "gene-CCDC82",
    "gene-CCDC83",
    "gene-CCDC85A",
    "gene-CCDC85B",
    "gene-CCDC85C",
    "gene-CCDC86",
    "gene-CCDC87",
    "gene-CCDC88A",
    "gene-CCDC88B",
    "gene-CCDC88C",
    "gene-CCDC89",
    "gene-CCDC9",
    "gene-CCDC90B",
    "gene-CCDC91",
    "gene-CCDC92",
    "gene-CCDC92B",
    "gene-CCDC93",
    "gene-CCDC96",
    "gene-CCDC97",
    "gene-CCDC9B",
    "gene-CCER1",
    "gene-CCER2",
    "gene-CCHCR1",
    "gene-CCIN",
    "gene-CCK",
    "gene-CCKAR",
    "gene-CCKBR",
    "gene-CCL1",
    "gene-CCL11",
    "gene-CCL13",
    "gene-CCL14",
    "gene-CCL15",
    "gene-CCL16",
    "gene-CCL17",
    "gene-CCL18",
    "gene-CCL19",
    "gene-CCL2",
    "gene-CCL20",
    "gene-CCL21",
    "gene-CCL22",
    "gene-CCL23",
    "gene-CCL24",
    "gene-CCL25",
    "gene-CCL26",
    "gene-CCL27",
    "gene-CCL28",
    "gene-CCL3",
    "gene-CCL3L3",
    "gene-CCL4",
    "gene-CCL4L2",
    "gene-CCL5",
    "gene-CCL7",
    "gene-CCL8",
    "gene-CCM2",
    "gene-CCM2L",
    "gene-CCN1",
    "gene-CCN2",
    "gene-CCN3",
    "gene-CCN4",
    "gene-CCN5",
    "gene-CCN6",
    "gene-CCNA1",
    "gene-CCNA2",
    "gene-CCNB1",
    "gene-CCNB1IP1",
    "gene-CCNB2",
    "gene-CCNB3",
    "gene-CCNC",
    "gene-CCND1",
    "gene-CCND2",
    "gene-CCND3",
    "gene-CCNDBP1",
    "gene-CCNE1",
    "gene-CCNE2",
    "gene-CCNF",
    "gene-CCNG1",
    "gene-CCNG2",
    "gene-CCNH",
    "gene-CCNI",
    "gene-CCNI2",
    "gene-CCNJ",
    "gene-CCNJL",
    "gene-CCNK",
    "gene-CCNL1",
    "gene-CCNL2",
    "gene-CCNO",
    "gene-CCNP",
    "gene-CCNQ",
    "gene-CCNT1",
    "gene-CCNT2",
    "gene-CCNY",
    "gene-CCNYL1",
    "gene-CCNYL1B",
    "gene-CCP110",
    "gene-CCPG1",
    "gene-CCR1",
    "gene-CCR10",
    "gene-CCR2",
    "gene-CCR3",
    "gene-CCR4",
    "gene-CCR5",
    "gene-CCR6",
    "gene-CCR7",
    "gene-CCR8",
    "gene-CCR9",
    "gene-CCRL2",
    "gene-CCS",
    "gene-CCSAP",
    "gene-CCSER1",
    "gene-CCSER2",
    "gene-CCT2",
    "gene-CCT3",
    "gene-CCT4",
    "gene-CCT5",
    "gene-CCT6A",
    "gene-CCT6B",
    "gene-CCT7",
    "gene-CCT8",
    "gene-CCT8L2",
    "gene-CCZ1",
    "gene-CCZ1B",
    "gene-CD101",
    "gene-CD109",
    "gene-CD14",
    "gene-CD151",
    "gene-CD160",
    "gene-CD163",
    "gene-CD163L1",
    "gene-CD164",
    "gene-CD164L2",
    "gene-CD177",
    "gene-CD180",
    "gene-CD19",
    "gene-CD1A",
    "gene-CD1B",
    "gene-CD1C",
    "gene-CD1D",
    "gene-CD1E",
    "gene-CD2",
    "gene-CD200",
    "gene-CD200R1",
    "gene-CD200R1L",
    "gene-CD207",
    "gene-CD209",
    "gene-CD22",
    "gene-CD226",
    "gene-CD24",
    "gene-CD244",
    "gene-CD247",
    "gene-CD248",
    "gene-CD27",
    "gene-CD274",
    "gene-CD276",
    "gene-CD28",
    "gene-CD2AP",
    "gene-CD2BP2",
    "gene-CD300A",
    "gene-CD300C",
    "gene-CD300E",
    "gene-CD300H",
    "gene-CD300LB",
    "gene-CD300LD",
    "gene-CD300LF",
    "gene-CD300LG",
    "gene-CD302",
    "gene-CD320",
    "gene-CD33",
    "gene-CD34",
    "gene-CD36",
    "gene-CD37",
    "gene-CD38",
    "gene-CD3D",
    "gene-CD3E",
    "gene-CD3G",
    "gene-CD4",
    "gene-CD40",
    "gene-CD40LG",
    "gene-CD44",
    "gene-CD46",
    "gene-CD47",
    "gene-CD48",
    "gene-CD5",
    "gene-CD52",
    "gene-CD53",
    "gene-CD55",
    "gene-CD58",
    "gene-CD59",
    "gene-CD5L",
    "gene-CD6",
    "gene-CD63",
    "gene-CD68",
    "gene-CD69",
    "gene-CD7",
    "gene-CD70",
    "gene-CD72",
    "gene-CD74",
    "gene-CD79A",
    "gene-CD79B",
    "gene-CD80",
    "gene-CD81",
    "gene-CD82",
    "gene-CD83",
    "gene-CD84",
    "gene-CD86",
    "gene-CD8A",
    "gene-CD8B",
    "gene-CD8B2",
    "gene-CD9",
    "gene-CD93",
    "gene-CD96",
    "gene-CD99",
    "gene-CD99L2",
    "gene-CDA",
    "gene-CDADC1",
    "gene-CDAN1",
    "gene-CDC123",
    "gene-CDC14A",
    "gene-CDC14B",
    "gene-CDC14C",
    "gene-CDC16",
    "gene-CDC20",
    "gene-CDC20B",
    "gene-CDC23",
    "gene-CDC25A",
    "gene-CDC25B",
    "gene-CDC25C",
    "gene-CDC26",
    "gene-CDC27",
    "gene-CDC34",
    "gene-CDC37",
    "gene-CDC37L1",
    "gene-CDC40",
    "gene-CDC42",
    "gene-CDC42BPA",
    "gene-CDC42BPB",
    "gene-CDC42BPG",
    "gene-CDC42EP1",
    "gene-CDC42EP2",
    "gene-CDC42EP3",
    "gene-CDC42EP4",
    "gene-CDC42EP5",
    "gene-CDC42SE1",
    "gene-CDC42SE2",
    "gene-CDC45",
    "gene-CDC5L",
    "gene-CDC6",
    "gene-CDC7",
    "gene-CDC73",
    "gene-CDCA2",
    "gene-CDCA3",
    "gene-CDCA4",
    "gene-CDCA5",
    "gene-CDCA7",
    "gene-CDCA7L",
    "gene-CDCA8",
    "gene-CDCP1",
    "gene-CDCP2",
    "gene-CDH1",
    "gene-CDH10",
    "gene-CDH11",
    "gene-CDH12",
    "gene-CDH13",
    "gene-CDH15",
    "gene-CDH16",
    "gene-CDH17",
    "gene-CDH18",
    "gene-CDH19",
    "gene-CDH2",
    "gene-CDH20",
    "gene-CDH22",
    "gene-CDH23",
    "gene-CDH24",
    "gene-CDH26",
    "gene-CDH3",
    "gene-CDH4",
    "gene-CDH5",
    "gene-CDH6",
    "gene-CDH7",
    "gene-CDH8",
    "gene-CDH9",
    "gene-CDHR1",
    "gene-CDHR2",
    "gene-CDHR3",
    "gene-CDHR4",
    "gene-CDHR5",
    "gene-CDIN1",
    "gene-CDIP1",
    "gene-CDIPT",
    "gene-CDK1",
    "gene-CDK10",
    "gene-CDK11A",
    "gene-CDK11B",
    "gene-CDK12",
    "gene-CDK13",
    "gene-CDK14",
    "gene-CDK15",
    "gene-CDK16",
    "gene-CDK17",
    "gene-CDK18",
    "gene-CDK19",
    "gene-CDK2",
    "gene-CDK20",
    "gene-CDK2AP1",
    "gene-CDK2AP2",
    "gene-CDK3",
    "gene-CDK4",
    "gene-CDK5",
    "gene-CDK5R1",
    "gene-CDK5R2",
    "gene-CDK5RAP1",
    "gene-CDK5RAP2",
    "gene-CDK5RAP3",
    "gene-CDK6",
    "gene-CDK7",
    "gene-CDK8",
    "gene-CDK9",
    "gene-CDKAL1",
    "gene-CDKL1",
    "gene-CDKL2",
    "gene-CDKL3",
    "gene-CDKL4",
    "gene-CDKL5",
    "gene-CDKN1A",
    "gene-CDKN1B",
    "gene-CDKN1C",
    "gene-CDKN2A",
    "gene-CDKN2AIP",
    "gene-CDKN2AIPNL",
    "gene-CDKN2B",
    "gene-CDKN2C",
    "gene-CDKN2D",
    "gene-CDKN3",
    "gene-CDNF",
    "gene-CDO1",
    "gene-CDON",
    "gene-CDPF1",
    "gene-CDR2",
    "gene-CDR2L",
    "gene-CDRT15",
    "gene-CDRT15L2",
    "gene-CDRT4",
    "gene-CDS1",
    "gene-CDS2",
    "gene-CDSN",
    "gene-CDT1",
    "gene-CDV3",
    "gene-CDX1",
    "gene-CDX2",
    "gene-CDX4",
    "gene-CDY1",
    "gene-CDY1B",
    "gene-CDY2A",
    "gene-CDY2B",
    "gene-CDYL",
    "gene-CDYL2",
    "gene-CEACAM1",
    "gene-CEACAM16",
    "gene-CEACAM18",
    "gene-CEACAM19",
    "gene-CEACAM20",
    "gene-CEACAM21",
    "gene-CEACAM3",
    "gene-CEACAM4",
    "gene-CEACAM5",
    "gene-CEACAM6",
    "gene-CEACAM7",
    "gene-CEACAM8",
    "gene-CEBPA",
    "gene-CEBPB",
    "gene-CEBPD",
    "gene-CEBPE",
    "gene-CEBPG",
    "gene-CEBPZ",
    "gene-CEBPZOS",
    "gene-CECR2",
    "gene-CEL",
    "gene-CELA1",
    "gene-CELA2A",
    "gene-CELA2B",
    "gene-CELA3A",
    "gene-CELA3B",
    "gene-CELF1",
    "gene-CELF2",
    "gene-CELF3",
    "gene-CELF4",
    "gene-CELF5",
    "gene-CELF6",
    "gene-CELSR1",
    "gene-CELSR2",
    "gene-CELSR3",
    "gene-CEMIP",
    "gene-CEMIP2",
    "gene-CEMP1",
    "gene-CENATAC",
    "gene-CEND1",
    "gene-CENPA",
    "gene-CENPB",
    "gene-CENPC",
    "gene-CENPE",
    "gene-CENPF",
    "gene-CENPH",
    "gene-CENPI",
    "gene-CENPJ",
    "gene-CENPK",
    "gene-CENPL",
    "gene-CENPM",
    "gene-CENPN",
    "gene-CENPO",
    "gene-CENPP",
    "gene-CENPQ",
    "gene-CENPS",
    "gene-CENPT",
    "gene-CENPU",
    "gene-CENPV",
    "gene-CENPVL1",
    "gene-CENPVL2",
    "gene-CENPVL3",
    "gene-CENPW",
    "gene-CENPX",
    "gene-CEP104",
    "gene-CEP112",
    "gene-CEP120",
    "gene-CEP126",
    "gene-CEP128",
    "gene-CEP131",
    "gene-CEP135",
    "gene-CEP15",
    "gene-CEP152",
    "gene-CEP162",
    "gene-CEP164",
    "gene-CEP170",
    "gene-CEP170B",
    "gene-CEP19",
    "gene-CEP192",
    "gene-CEP20",
    "gene-CEP250",
    "gene-CEP290",
    "gene-CEP295",
    "gene-CEP295NL",
    "gene-CEP350",
    "gene-CEP41",
    "gene-CEP43",
    "gene-CEP44",
    "gene-CEP55",
    "gene-CEP57",
    "gene-CEP57L1",
    "gene-CEP63",
    "gene-CEP68",
    "gene-CEP70",
    "gene-CEP72",
    "gene-CEP76",
    "gene-CEP78",
    "gene-CEP83",
    "gene-CEP85",
    "gene-CEP85L",
    "gene-CEP89",
    "gene-CEP95",
    "gene-CEP97",
    "gene-CEPT1",
    "gene-CER1",
    "gene-CERCAM",
    "gene-CERK",
    "gene-CERKL",
    "gene-CERS1",
    "gene-CERS2",
    "gene-CERS3",
    "gene-CERS4",
    "gene-CERS5",
    "gene-CERS6",
    "gene-CERT1",
    "gene-CES1",
    "gene-CES2",
    "gene-CES3",
    "gene-CES4A",
    "gene-CES5A",
    "gene-CETN1",
    "gene-CETN2",
    "gene-CETN3",
    "gene-CETP",
    "gene-CFAP100",
    "gene-CFAP107",
    "gene-CFAP119",
    "gene-CFAP126",
    "gene-CFAP141",
    "gene-CFAP144",
    "gene-CFAP157",
    "gene-CFAP161",
    "gene-CFAP20",
    "gene-CFAP206",
    "gene-CFAP20DC",
    "gene-CFAP210",
    "gene-CFAP221",
    "gene-CFAP251",
    "gene-CFAP276",
    "gene-CFAP298",
    "gene-CFAP299",
    "gene-CFAP300",
    "gene-CFAP36",
    "gene-CFAP410",
    "gene-CFAP418",
    "gene-CFAP43",
    "gene-CFAP44",
    "gene-CFAP45",
    "gene-CFAP46",
    "gene-CFAP47",
    "gene-CFAP52",
    "gene-CFAP53",
    "gene-CFAP54",
    "gene-CFAP57",
    "gene-CFAP58",
    "gene-CFAP61",
    "gene-CFAP65",
    "gene-CFAP68",
    "gene-CFAP69",
    "gene-CFAP70",
    "gene-CFAP73",
    "gene-CFAP74",
    "gene-CFAP77",
    "gene-CFAP90",
    "gene-CFAP91",
    "gene-CFAP92",
    "gene-CFAP95",
    "gene-CFAP96",
    "gene-CFAP97",
    "gene-CFAP97D1",
    "gene-CFAP97D2",
    "gene-CFAP99",
    "gene-CFB",
    "gene-CFC1",
    "gene-CFC1B",
    "gene-CFD",
    "gene-CFDP1",
    "gene-CFH",
    "gene-CFHR1",
    "gene-CFHR2",
    "gene-CFHR3",
    "gene-CFHR4",
    "gene-CFHR5",
    "gene-CFI",
    "gene-CFL1",
    "gene-CFL2",
    "gene-CFLAR",
    "gene-CFP",
    "gene-CFTR",
    "gene-CGA",
    "gene-CGAS",
    "gene-CGB1",
    "gene-CGB2",
    "gene-CGB3",
    "gene-CGB5",
    "gene-CGB7",
    "gene-CGB8",
    "gene-CGGBP1",
    "gene-CGN",
    "gene-CGNL1",
    "gene-CGREF1",
    "gene-CGRRF1",
    "gene-CH25H",
    "gene-CHAC1",
    "gene-CHAC2",
    "gene-CHAD",
    "gene-CHADL",
    "gene-CHAF1A",
    "gene-CHAF1B",
    "gene-CHAMP1",
    "gene-CHAT",
    "gene-CHCHD1",
    "gene-CHCHD10",
    "gene-CHCHD2",
    "gene-CHCHD3",
    "gene-CHCHD4",
    "gene-CHCHD5",
    "gene-CHCHD6",
    "gene-CHCHD7",
    "gene-CHCT1",
    "gene-CHD1",
    "gene-CHD1L",
    "gene-CHD2",
    "gene-CHD3",
    "gene-CHD4",
    "gene-CHD5",
    "gene-CHD6",
    "gene-CHD7",
    "gene-CHD8",
    "gene-CHD9",
    "gene-CHD9NB",
    "gene-CHDH",
    "gene-CHEK1",
    "gene-CHEK2",
    "gene-CHERP",
    "gene-CHFR",
    "gene-CHGA",
    "gene-CHGB",
    "gene-CHI3L1",
    "gene-CHI3L2",
    "gene-CHIA",
    "gene-CHIC1",
    "gene-CHIC2",
    "gene-CHID1",
    "gene-CHIT1",
    "gene-CHKA",
    "gene-CHKB",
    "gene-CHL1",
    "gene-CHM",
    "gene-CHML",
    "gene-CHMP1A",
    "gene-CHMP1B",
    "gene-CHMP2A",
    "gene-CHMP2B",
    "gene-CHMP3",
    "gene-CHMP4A",
    "gene-CHMP4B",
    "gene-CHMP4C",
    "gene-CHMP5",
    "gene-CHMP6",
    "gene-CHMP7",
    "gene-CHN1",
    "gene-CHN2",
    "gene-CHODL",
    "gene-CHORDC1",
    "gene-CHP1",
    "gene-CHP2",
    "gene-CHPF",
    "gene-CHPF2",
    "gene-CHPT1",
    "gene-CHRAC1",
    "gene-CHRD",
    "gene-CHRDL1",
    "gene-CHRDL2",
    "gene-CHRFAM7A",
    "gene-CHRM1",
    "gene-CHRM2",
    "gene-CHRM3",
    "gene-CHRM4",
    "gene-CHRM5",
    "gene-CHRNA1",
    "gene-CHRNA10",
    "gene-CHRNA2",
    "gene-CHRNA3",
    "gene-CHRNA4",
    "gene-CHRNA5",
    "gene-CHRNA6",
    "gene-CHRNA7",
    "gene-CHRNA9",
    "gene-CHRNB1",
    "gene-CHRNB2",
    "gene-CHRNB3",
    "gene-CHRNB4",
    "gene-CHRND",
    "gene-CHRNE",
    "gene-CHRNG",
    "gene-CHST1",
    "gene-CHST10",
    "gene-CHST11",
    "gene-CHST12",
    "gene-CHST13",
    "gene-CHST14",
    "gene-CHST15",
    "gene-CHST2",
    "gene-CHST3",
    "gene-CHST4",
    "gene-CHST5",
    "gene-CHST6",
    "gene-CHST7",
    "gene-CHST8",
    "gene-CHST9",
    "gene-CHSY1",
    "gene-CHSY3",
    "gene-CHTF18",
    "gene-CHTF8",
    "gene-CHTOP",
    "gene-CHUK",
    "gene-CHURC1",
    "gene-CIAO1",
    "gene-CIAO2A",
    "gene-CIAO2B",
    "gene-CIAO3",
    "gene-CIAPIN1",
    "gene-CIART",
    "gene-CIB1",
    "gene-CIB2",
    "gene-CIB3",
    "gene-CIB4",
    "gene-CIBAR1",
    "gene-CIBAR2",
    "gene-CIC",
    "gene-CIDEA",
    "gene-CIDEB",
    "gene-CIDEC",
    "gene-CIITA",
    "gene-CILK1",
    "gene-CILP",
    "gene-CILP2",
    "gene-CIMAP1A",
    "gene-CIMAP1B",
    "gene-CIMAP1C",
    "gene-CIMAP1D",
    "gene-CIMAP2",
    "gene-CIMAP3",
    "gene-CIMIP1",
    "gene-CIMIP2A",
    "gene-CIMIP2B",
    "gene-CIMIP2C",
    "gene-CIMIP3",
    "gene-CIMIP4",
    "gene-CINP",
    "gene-CIP2A",
    "gene-CIPC",
    "gene-CIR1",
    "gene-CIRBP",
    "gene-CIROP",
    "gene-CISD1",
    "gene-CISD2",
    "gene-CISD3",
    "gene-CISH",
    "gene-CIST1",
    "gene-CIT",
    "gene-CITED1",
    "gene-CITED2",
    "gene-CITED4",
    "gene-CIZ1",
    "gene-CKAP2",
    "gene-CKAP2L",
    "gene-CKAP4",
    "gene-CKAP5",
    "gene-CKB",
    "gene-CKLF",
    "gene-CKM",
    "gene-CKMT1A",
    "gene-CKMT1B",
    "gene-CKMT2",
    "gene-CKS1B",
    "gene-CKS2",
    "gene-CLASP1",
    "gene-CLASP2",
    "gene-CLASRP",
    "gene-CLBA1",
    "gene-CLC",
    "gene-CLCA1",
    "gene-CLCA2",
    "gene-CLCA4",
    "gene-CLCC1",
    "gene-CLCF1",
    "gene-CLCN1",
    "gene-CLCN2",
    "gene-CLCN3",
    "gene-CLCN4",
    "gene-CLCN5",
    "gene-CLCN6",
    "gene-CLCN7",
    "gene-CLCNKA",
    "gene-CLCNKB",
    "gene-CLDN1",
    "gene-CLDN10",
    "gene-CLDN11",
    "gene-CLDN12",
    "gene-CLDN14",
    "gene-CLDN15",
    "gene-CLDN16",
    "gene-CLDN17",
    "gene-CLDN18",
    "gene-CLDN19",
    "gene-CLDN2",
    "gene-CLDN20",
    "gene-CLDN22",
    "gene-CLDN23",
    "gene-CLDN24",
    "gene-CLDN25",
    "gene-CLDN3",
    "gene-CLDN34",
    "gene-CLDN4",
    "gene-CLDN5",
    "gene-CLDN6",
    "gene-CLDN7",
    "gene-CLDN8",
    "gene-CLDN9",
    "gene-CLDND1",
    "gene-CLDND2",
    "gene-CLEC10A",
    "gene-CLEC11A",
    "gene-CLEC12A",
    "gene-CLEC12B",
    "gene-CLEC14A",
    "gene-CLEC16A",
    "gene-CLEC17A",
    "gene-CLEC18A",
    "gene-CLEC18B",
    "gene-CLEC18C",
    "gene-CLEC19A",
    "gene-CLEC1A",
    "gene-CLEC1B",
    "gene-CLEC20A",
    "gene-CLEC2A",
    "gene-CLEC2B",
    "gene-CLEC2D",
    "gene-CLEC2L",
    "gene-CLEC3A",
    "gene-CLEC3B",
    "gene-CLEC4A",
    "gene-CLEC4C",
    "gene-CLEC4D",
    "gene-CLEC4E",
    "gene-CLEC4F",
    "gene-CLEC4G",
    "gene-CLEC4M",
    "gene-CLEC5A",
    "gene-CLEC6A",
    "gene-CLEC7A",
    "gene-CLEC9A",
    "gene-CLGN",
    "gene-CLHC1",
    "gene-CLIC1",
    "gene-CLIC2",
    "gene-CLIC3",
    "gene-CLIC4",
    "gene-CLIC5",
    "gene-CLIC6",
    "gene-CLINT1",
    "gene-CLIP1",
    "gene-CLIP2",
    "gene-CLIP3",
    "gene-CLIP4",
    "gene-CLK1",
    "gene-CLK2",
    "gene-CLK3",
    "gene-CLK4",
    "gene-CLMN",
    "gene-CLMP",
    "gene-CLN3",
    "gene-CLN5",
    "gene-CLN6",
    "gene-CLN8",
    "gene-CLNK",
    "gene-CLNS1A",
    "gene-CLOCK",
    "gene-CLP1",
    "gene-CLPB",
    "gene-CLPP",
    "gene-CLPS",
    "gene-CLPSL1",
    "gene-CLPSL2",
    "gene-CLPTM1",
    "gene-CLPTM1L",
    "gene-CLPX",
    "gene-CLRN1",
    "gene-CLRN2",
    "gene-CLRN3",
    "gene-CLSPN",
    "gene-CLSTN1",
    "gene-CLSTN2",
    "gene-CLSTN3",
    "gene-CLTA",
    "gene-CLTB",
    "gene-CLTC",
    "gene-CLTCL1",
    "gene-CLTRN",
    "gene-CLU",
    "gene-CLUAP1",
    "gene-CLUH",
    "gene-CLUL1",
    "gene-CLVS1",
    "gene-CLVS2",
    "gene-CLXN",
    "gene-CLYBL",
    "gene-CMA1",
    "gene-CMAS",
    "gene-CMBL",
    "gene-CMC1",
    "gene-CMC2",
    "gene-CMC4",
    "gene-CMIP",
    "gene-CMKLR1",
    "gene-CMKLR2",
    "gene-CMPK1",
    "gene-CMPK2",
    "gene-CMSS1",
    "gene-CMTM1",
    "gene-CMTM2",
    "gene-CMTM3",
    "gene-CMTM4",
    "gene-CMTM5",
    "gene-CMTM6",
    "gene-CMTM7",
    "gene-CMTM8",
    "gene-CMTR1",
    "gene-CMTR2",
    "gene-CMYA5",
    "gene-CNBD1",
    "gene-CNBD2",
    "gene-CNBP",
    "gene-CNDP1",
    "gene-CNDP2",
    "gene-CNEP1R1",
    "gene-CNFN",
    "gene-CNGA1",
    "gene-CNGA2",
    "gene-CNGA3",
    "gene-CNGA4",
    "gene-CNGB1",
    "gene-CNGB3",
    "gene-CNIH1",
    "gene-CNIH2",
    "gene-CNIH3",
    "gene-CNIH4",
    "gene-CNKSR1",
    "gene-CNKSR2",
    "gene-CNKSR3",
    "gene-CNMD",
    "gene-CNN1",
    "gene-CNN2",
    "gene-CNN3",
    "gene-CNNM1",
    "gene-CNNM2",
    "gene-CNNM3",
    "gene-CNNM4",
    "gene-CNOT1",
    "gene-CNOT10",
    "gene-CNOT11",
    "gene-CNOT2",
    "gene-CNOT3",
    "gene-CNOT4",
    "gene-CNOT6",
    "gene-CNOT6L",
    "gene-CNOT7",
    "gene-CNOT8",
    "gene-CNOT9",
    "gene-CNP",
    "gene-CNPPD1",
    "gene-CNPY1",
    "gene-CNPY2",
    "gene-CNPY3",
    "gene-CNPY4",
    "gene-CNR1",
    "gene-CNR2",
    "gene-CNRIP1",
    "gene-CNST",
    "gene-CNTD1",
    "gene-CNTF",
    "gene-CNTFR",
    "gene-CNTLN",
    "gene-CNTN1",
    "gene-CNTN2",
    "gene-CNTN3",
    "gene-CNTN4",
    "gene-CNTN5",
    "gene-CNTN6",
    "gene-CNTNAP1",
    "gene-CNTNAP2",
    "gene-CNTNAP3",
    "gene-CNTNAP3B",
    "gene-CNTNAP3C",
    "gene-CNTNAP4",
    "gene-CNTNAP5",
    "gene-CNTRL",
    "gene-CNTROB",
    "gene-COA1",
    "gene-COA3",
    "gene-COA4",
    "gene-COA5",
    "gene-COA6",
    "gene-COA7",
    "gene-COA8",
    "gene-COASY",
    "gene-COBL",
    "gene-COBLL1",
    "gene-COCH",
    "gene-COG1",
    "gene-COG2",
    "gene-COG3",
    "gene-COG4",
    "gene-COG5",
    "gene-COG6",
    "gene-COG7",
    "gene-COG8",
    "gene-COIL",
    "gene-COL10A1",
    "gene-COL11A1",
    "gene-COL11A2",
    "gene-COL12A1",
    "gene-COL13A1",
    "gene-COL14A1",
    "gene-COL15A1",
    "gene-COL16A1",
    "gene-COL17A1",
    "gene-COL18A1",
    "gene-COL19A1",
    "gene-COL1A1",
    "gene-COL1A2",
    "gene-COL20A1",
    "gene-COL21A1",
    "gene-COL22A1",
    "gene-COL23A1",
    "gene-COL24A1",
    "gene-COL25A1",
    "gene-COL26A1",
    "gene-COL27A1",
    "gene-COL28A1",
    "gene-COL2A1",
    "gene-COL3A1",
    "gene-COL4A1",
    "gene-COL4A2",
    "gene-COL4A3",
    "gene-COL4A4",
    "gene-COL4A5",
    "gene-COL4A6",
    "gene-COL5A1",
    "gene-COL5A2",
    "gene-COL5A3",
    "gene-COL6A1",
    "gene-COL6A2",
    "gene-COL6A3",
    "gene-COL6A5",
    "gene-COL6A6",
    "gene-COL7A1",
    "gene-COL8A1",
    "gene-COL8A2",
    "gene-COL9A1",
    "gene-COL9A2",
    "gene-COL9A3",
    "gene-COLEC10",
    "gene-COLEC11",
    "gene-COLEC12",
    "gene-COLGALT1",
    "gene-COLGALT2",
    "gene-COLQ",
    "gene-COMMD1",
    "gene-COMMD10",
    "gene-COMMD2",
    "gene-COMMD3",
    "gene-COMMD4",
    "gene-COMMD5",
    "gene-COMMD6",
    "gene-COMMD7",
    "gene-COMMD8",
    "gene-COMMD9",
    "gene-COMP",
    "gene-COMT",
    "gene-COMTD1",
    "gene-COP1",
    "gene-COPA",
    "gene-COPB1",
    "gene-COPB2",
    "gene-COPE",
    "gene-COPG1",
    "gene-COPG2",
    "gene-COPRS",
    "gene-COPS2",
    "gene-COPS3",
    "gene-COPS4",
    "gene-COPS5",
    "gene-COPS6",
    "gene-COPS7A",
    "gene-COPS7B",
    "gene-COPS8",
    "gene-COPS9",
    "gene-COPZ1",
    "gene-COPZ2",
    "gene-COQ10A",
    "gene-COQ10B",
    "gene-COQ2",
    "gene-COQ3",
    "gene-COQ4",
    "gene-COQ5",
    "gene-COQ6",
    "gene-COQ7",
    "gene-COQ8A",
    "gene-COQ8B",
    "gene-COQ9",
    "gene-CORIN",
    "gene-CORO1A",
    "gene-CORO1B",
    "gene-CORO1C",
    "gene-CORO2A",
    "gene-CORO2B",
    "gene-CORO6",
    "gene-CORO7",
    "gene-CORT",
    "gene-COTL1",
    "gene-COX10",
    "gene-COX11",
    "gene-COX14",
    "gene-COX15",
    "gene-COX16",
    "gene-COX17",
    "gene-COX18",
    "gene-COX19",
    "gene-COX20",
    "gene-COX4I1",
    "gene-COX4I2",
    "gene-COX5A",
    "gene-COX5B",
    "gene-COX6A1",
    "gene-COX6A2",
    "gene-COX6B1",
    "gene-COX6B2",
    "gene-COX6C",
    "gene-COX7A1",
    "gene-COX7A2",
    "gene-COX7A2L",
    "gene-COX7B",
    "gene-COX7B2",
    "gene-COX7C",
    "gene-COX8A",
    "gene-COX8C",
    "gene-CP",
    "gene-CPA1",
    "gene-CPA2",
    "gene-CPA3",
    "gene-CPA4",
    "gene-CPA5",
    "gene-CPA6",
    "gene-CPAMD8",
    "gene-CPB1",
    "gene-CPB2",
    "gene-CPD",
    "gene-CPE",
    "gene-CPEB1",
    "gene-CPEB2",
    "gene-CPEB3",
    "gene-CPEB4",
    "gene-CPED1",
    "gene-CPHXL",
    "gene-CPHXL2",
    "gene-CPLANE1",
    "gene-CPLANE2",
    "gene-CPLX1",
    "gene-CPLX2",
    "gene-CPLX3",
    "gene-CPLX4",
    "gene-CPM",
    "gene-CPN1",
    "gene-CPN2",
    "gene-CPNE1",
    "gene-CPNE2",
    "gene-CPNE3",
    "gene-CPNE4",
    "gene-CPNE5",
    "gene-CPNE6",
    "gene-CPNE7",
    "gene-CPNE8",
    "gene-CPNE9",
    "gene-CPO",
    "gene-CPOX",
    "gene-CPPED1",
    "gene-CPQ",
    "gene-CPS1",
    "gene-CPSF1",
    "gene-CPSF2",
    "gene-CPSF3",
    "gene-CPSF4",
    "gene-CPSF4L",
    "gene-CPSF6",
    "gene-CPSF7",
    "gene-CPT1A",
    "gene-CPT1B",
    "gene-CPT1C",
    "gene-CPT2",
    "gene-CPTP",
    "gene-CPVL",
    "gene-CPXCR1",
    "gene-CPXM1",
    "gene-CPXM2",
    "gene-CPZ",
    "gene-CR1",
    "gene-CR1L",
    "gene-CR2",
    "gene-CRABP1",
    "gene-CRABP2",
    "gene-CRACD",
    "gene-CRACDL",
    "gene-CRACR2A",
    "gene-CRACR2B",
    "gene-CRADD",
    "gene-CRAMP1",
    "gene-CRAT",
    "gene-CRB1",
    "gene-CRB2",
    "gene-CRB3",
    "gene-CRBN",
    "gene-CRCP",
    "gene-CRCT1",
    "gene-CREB1",
    "gene-CREB3",
    "gene-CREB3L1",
    "gene-CREB3L2",
    "gene-CREB3L3",
    "gene-CREB3L4",
    "gene-CREB5",
    "gene-CREBBP",
    "gene-CREBL2",
    "gene-CREBRF",
    "gene-CREBZF",
    "gene-CREG1",
    "gene-CREG2",
    "gene-CRELD1",
    "gene-CRELD2",
    "gene-CREM",
    "gene-CRH",
    "gene-CRHBP",
    "gene-CRHR1",
    "gene-CRHR2",
    "gene-CRIM1",
    "gene-CRIP1",
    "gene-CRIP2",
    "gene-CRIP3",
    "gene-CRIPT",
    "gene-CRIPTO",
    "gene-CRIPTO3",
    "gene-CRISP1",
    "gene-CRISP2",
    "gene-CRISP3",
    "gene-CRISPLD1",
    "gene-CRISPLD2",
    "gene-CRK",
    "gene-CRKL",
    "gene-CRLF1",
    "gene-CRLF2",
    "gene-CRLF3",
    "gene-CRLS1",
    "gene-CRMP1",
    "gene-CRNKL1",
    "gene-CRNN",
    "gene-CROCC",
    "gene-CROCC2",
    "gene-CROT",
    "gene-CRP",
    "gene-CRPPA",
    "gene-CRTAC1",
    "gene-CRTAM",
    "gene-CRTAP",
    "gene-CRTC1",
    "gene-CRTC2",
    "gene-CRTC3",
    "gene-CRX",
    "gene-CRY1",
    "gene-CRY2",
    "gene-CRYAA",
    "gene-CRYAB",
    "gene-CRYBA1",
    "gene-CRYBA2",
    "gene-CRYBA4",
    "gene-CRYBB1",
    "gene-CRYBB2",
    "gene-CRYBB3",
    "gene-CRYBG1",
    "gene-CRYBG2",
    "gene-CRYBG3",
    "gene-CRYGA",
    "gene-CRYGB",
    "gene-CRYGC",
    "gene-CRYGD",
    "gene-CRYGN",
    "gene-CRYGS",
    "gene-CRYL1",
    "gene-CRYM",
    "gene-CRYZ",
    "gene-CRYZL1",
    "gene-CS",
    "gene-CSAD",
    "gene-CSAG1",
    "gene-CSAG2",
    "gene-CSAG3",
    "gene-CSDC2",
    "gene-CSDE1",
    "gene-CSE1L",
    "gene-CSF1",
    "gene-CSF1R",
    "gene-CSF2",
    "gene-CSF2RA",
    "gene-CSF2RB",
    "gene-CSF3",
    "gene-CSF3R",
    "gene-CSGALNACT1",
    "gene-CSGALNACT2",
    "gene-CSH1",
    "gene-CSH2",
    "gene-CSHL1",
    "gene-CSK",
    "gene-CSKMT",
    "gene-CSMD1",
    "gene-CSMD2",
    "gene-CSMD3",
    "gene-CSN1S1",
    "gene-CSN2",
    "gene-CSN3",
    "gene-CSNK1A1",
    "gene-CSNK1A1L",
    "gene-CSNK1D",
    "gene-CSNK1E",
    "gene-CSNK1G1",
    "gene-CSNK1G2",
    "gene-CSNK1G3",
    "gene-CSNK2A1",
    "gene-CSNK2A2",
    "gene-CSNK2A3",
    "gene-CSNK2B",
    "gene-CSNKA2IP",
    "gene-CSPG4",
    "gene-CSPG5",
    "gene-CSPP1",
    "gene-CSRNP1",
    "gene-CSRNP2",
    "gene-CSRNP3",
    "gene-CSRP1",
    "gene-CSRP2",
    "gene-CSRP3",
    "gene-CST1",
    "gene-CST11",
    "gene-CST2",
    "gene-CST3",
    "gene-CST4",
    "gene-CST5",
    "gene-CST6",
    "gene-CST7",
    "gene-CST8",
    "gene-CST9",
    "gene-CST9L",
    "gene-CSTA",
    "gene-CSTB",
    "gene-CSTF1",
    "gene-CSTF2",
    "gene-CSTF2T",
    "gene-CSTF3",
    "gene-CSTL1",
    "gene-CSTPP1",
    "gene-CT45A1",
    "gene-CT45A10",
    "gene-CT45A2",
    "gene-CT45A3",
    "gene-CT45A5",
    "gene-CT45A6",
    "gene-CT45A7",
    "gene-CT45A8",
    "gene-CT45A9",
    "gene-CT47A1",
    "gene-CT47A10",
    "gene-CT47A11",
    "gene-CT47A12",
    "gene-CT47A2",
    "gene-CT47A3",
    "gene-CT47A4",
    "gene-CT47A5",
    "gene-CT47A6",
    "gene-CT47A7",
    "gene-CT47A8",
    "gene-CT47A9",
    "gene-CT47B1",
    "gene-CT47C1",
    "gene-CT55",
    "gene-CT83",
    "gene-CTAG1A",
    "gene-CTAG1B",
    "gene-CTAG2",
    "gene-CTAGE1",
    "gene-CTAGE15",
    "gene-CTAGE4",
    "gene-CTAGE6",
    "gene-CTAGE8",
    "gene-CTAGE9",
    "gene-CTBP1",
    "gene-CTBP2",
    "gene-CTBS",
    "gene-CTC1",
    "gene-CTCF",
    "gene-CTCFL",
    "gene-CTDNEP1",
    "gene-CTDP1",
    "gene-CTDSP1",
    "gene-CTDSP2",
    "gene-CTDSPL",
    "gene-CTDSPL2",
    "gene-CTF1",
    "gene-CTH",
    "gene-CTHRC1",
    "gene-CTIF",
    "gene-CTLA4",
    "gene-CTNNA1",
    "gene-CTNNA2",
    "gene-CTNNA3",
    "gene-CTNNAL1",
    "gene-CTNNB1",
    "gene-CTNNBIP1",
    "gene-CTNNBL1",
    "gene-CTNND1",
    "gene-CTNND2",
    "gene-CTNS",
    "gene-CTPS1",
    "gene-CTPS2",
    "gene-CTR9",
    "gene-CTRB1",
    "gene-CTRB2",
    "gene-CTRC",
    "gene-CTRL",
    "gene-CTSA",
    "gene-CTSB",
    "gene-CTSC",
    "gene-CTSD",
    "gene-CTSE",
    "gene-CTSF",
    "gene-CTSG",
    "gene-CTSH",
    "gene-CTSK",
    "gene-CTSL",
    "gene-CTSO",
    "gene-CTSS",
    "gene-CTSV",
    "gene-CTSW",
    "gene-CTSZ",
    "gene-CTTN",
    "gene-CTTNBP2",
    "gene-CTTNBP2NL",
    "gene-CTU1",
    "gene-CTU2",
    "gene-CTXN1",
    "gene-CTXN2",
    "gene-CTXN3",
    "gene-CTXND1",
    "gene-CTXND2",
    "gene-CUBN",
    "gene-CUEDC1",
    "gene-CUEDC2",
    "gene-CUL1",
    "gene-CUL2",
    "gene-CUL3",
    "gene-CUL4A",
    "gene-CUL4B",
    "gene-CUL5",
    "gene-CUL7",
    "gene-CUL9",
    "gene-CUTA",
    "gene-CUTC",
    "gene-CUX1",
    "gene-CUX2",
    "gene-CUZD1",
    "gene-CWC15",
    "gene-CWC22",
    "gene-CWC25",
    "gene-CWC27",
    "gene-CWF19L1",
    "gene-CWF19L2",
    "gene-CWH43",
    "gene-CX3CL1",
    "gene-CX3CR1",
    "gene-CXADR",
    "gene-CXCL1",
    "gene-CXCL10",
    "gene-CXCL11",
    "gene-CXCL12",
    "gene-CXCL13",
    "gene-CXCL14",
    "gene-CXCL16",
    "gene-CXCL17",
    "gene-CXCL2",
    "gene-CXCL3",
    "gene-CXCL5",
    "gene-CXCL6",
    "gene-CXCL8",
    "gene-CXCL9",
    "gene-CXCR1",
    "gene-CXCR2",
    "gene-CXCR3",
    "gene-CXCR4",
    "gene-CXCR5",
    "gene-CXCR6",
    "gene-CXORF38",
    "gene-CXORF49",
    "gene-CXORF49B",
    "gene-CXORF51A",
    "gene-CXORF51B",
    "gene-CXORF58",
    "gene-CXORF65",
    "gene-CXORF66",
    "gene-CXXC1",
    "gene-CXXC4",
    "gene-CXXC5",
    "gene-CYB561",
    "gene-CYB561A3",
    "gene-CYB561D1",
    "gene-CYB561D2",
    "gene-CYB5A",
    "gene-CYB5B",
    "gene-CYB5D1",
    "gene-CYB5D2",
    "gene-CYB5R1",
    "gene-CYB5R2",
    "gene-CYB5R3",
    "gene-CYB5R4",
    "gene-CYB5RL",
    "gene-CYBA",
    "gene-CYBB",
    "gene-CYBC1",
    "gene-CYBRD1",
    "gene-CYC1",
    "gene-CYCS",
    "gene-CYFIP1",
    "gene-CYFIP2",
    "gene-CYGB",
    "gene-CYLC1",
    "gene-CYLC2",
    "gene-CYLD",
    "gene-CYP11A1",
    "gene-CYP11B1",
    "gene-CYP11B2",
    "gene-CYP17A1",
    "gene-CYP19A1",
    "gene-CYP1A1",
    "gene-CYP1A2",
    "gene-CYP1B1",
    "gene-CYP20A1",
    "gene-CYP21A2",
    "gene-CYP24A1",
    "gene-CYP26A1",
    "gene-CYP26B1",
    "gene-CYP26C1",
    "gene-CYP27A1",
    "gene-CYP27B1",
    "gene-CYP27C1",
    "gene-CYP2A13",
    "gene-CYP2A6",
    "gene-CYP2A7",
    "gene-CYP2B6",
    "gene-CYP2C18",
    "gene-CYP2C19",
    "gene-CYP2C8",
    "gene-CYP2C9",
    "gene-CYP2D6",
    "gene-CYP2D7",
    "gene-CYP2E1",
    "gene-CYP2F1",
    "gene-CYP2J2",
    "gene-CYP2R1",
    "gene-CYP2S1",
    "gene-CYP2U1",
    "gene-CYP2W1",
    "gene-CYP39A1",
    "gene-CYP3A4",
    "gene-CYP3A43",
    "gene-CYP3A5",
    "gene-CYP3A7",
    "gene-CYP46A1",
    "gene-CYP4A11",
    "gene-CYP4A22",
    "gene-CYP4B1",
    "gene-CYP4F11",
    "gene-CYP4F12",
    "gene-CYP4F2",
    "gene-CYP4F22",
    "gene-CYP4F3",
    "gene-CYP4F8",
    "gene-CYP4V2",
    "gene-CYP4X1",
    "gene-CYP4Z1",
    "gene-CYP51A1",
    "gene-CYP7A1",
    "gene-CYP7B1",
    "gene-CYP8B1",
    "gene-CYREN",
    "gene-CYRIA",
    "gene-CYRIB",
    "gene-CYS1",
    "gene-CYSLTR1",
    "gene-CYSLTR2",
    "gene-CYSRT1",
    "gene-CYSTM1",
    "gene-CYTH1",
    "gene-CYTH2",
    "gene-CYTH3",
    "gene-CYTH4",
    "gene-CYTIP",
    "gene-CYTL1",
    "gene-CYYR1",
    "gene-CZIB",
    "gene-D2HGDH",
    "gene-DAAM1",
    "gene-DAAM2",
    "gene-DAB1",
    "gene-DAB2",
    "gene-DAB2IP",
    "gene-DACH1",
    "gene-DACH2",
    "gene-DACT1",
    "gene-DACT2",
    "gene-DACT3",
    "gene-DAD1",
    "gene-DAG1",
    "gene-DAGLA",
    "gene-DAGLB",
    "gene-DALRD3",
    "gene-DAND5",
    "gene-DAO",
    "gene-DAOA",
    "gene-DAP",
    "gene-DAP3",
    "gene-DAPK1",
    "gene-DAPK2",
    "gene-DAPK3",
    "gene-DAPL1",
    "gene-DAPP1",
    "gene-DARS1",
    "gene-DARS2",
    "gene-DAW1",
    "gene-DAXX",
    "gene-DAZ1",
    "gene-DAZ2",
    "gene-DAZ3",
    "gene-DAZ4",
    "gene-DAZAP1",
    "gene-DAZAP2",
    "gene-DAZL",
    "gene-DBF4",
    "gene-DBF4B",
    "gene-DBH",
    "gene-DBI",
    "gene-DBN1",
    "gene-DBNDD1",
    "gene-DBNDD2",
    "gene-DBNL",
    "gene-DBP",
    "gene-DBR1",
    "gene-DBT",
    "gene-DBX1",
    "gene-DBX2",
    "gene-DCAF1",
    "gene-DCAF10",
    "gene-DCAF11",
    "gene-DCAF12",
    "gene-DCAF12L1",
    "gene-DCAF12L2",
    "gene-DCAF13",
    "gene-DCAF15",
    "gene-DCAF16",
    "gene-DCAF17",
    "gene-DCAF4",
    "gene-DCAF4L1",
    "gene-DCAF4L2",
    "gene-DCAF5",
    "gene-DCAF6",
    "gene-DCAF7",
    "gene-DCAF8",
    "gene-DCAF8L1",
    "gene-DCAF8L2",
    "gene-DCAKD",
    "gene-DCANP1",
    "gene-DCBLD1",
    "gene-DCBLD2",
    "gene-DCC",
    "gene-DCD",
    "gene-DCDC1",
    "gene-DCDC2",
    "gene-DCDC2B",
    "gene-DCDC2C",
    "gene-DCHS1",
    "gene-DCHS2",
    "gene-DCK",
    "gene-DCLK1",
    "gene-DCLK2",
    "gene-DCLK3",
    "gene-DCLRE1A",
    "gene-DCLRE1B",
    "gene-DCLRE1C",
    "gene-DCN",
    "gene-DCP1A",
    "gene-DCP1B",
    "gene-DCP2",
    "gene-DCPS",
    "gene-DCST1",
    "gene-DCST2",
    "gene-DCSTAMP",
    "gene-DCT",
    "gene-DCTD",
    "gene-DCTN1",
    "gene-DCTN2",
    "gene-DCTN3",
    "gene-DCTN4",
    "gene-DCTN5",
    "gene-DCTN6",
    "gene-DCTPP1",
    "gene-DCUN1D1",
    "gene-DCUN1D2",
    "gene-DCUN1D3",
    "gene-DCUN1D4",
    "gene-DCUN1D5",
    "gene-DCX",
    "gene-DCXR",
    "gene-DDA1",
    "gene-DDAH1",
    "gene-DDAH2",
    "gene-DDB1",
    "gene-DDB2",
    "gene-DDC",
    "gene-DDHD1",
    "gene-DDHD2",
    "gene-DDI1",
    "gene-DDI2",
    "gene-DDIAS",
    "gene-DDIT3",
    "gene-DDIT4",
    "gene-DDIT4L",
    "gene-DDN",
    "gene-DDO",
    "gene-DDOST",
    "gene-DDR1",
    "gene-DDR2",
    "gene-DDRGK1",
    "gene-DDT",
    "gene-DDTL",
    "gene-DDX1",
    "gene-DDX10",
    "gene-DDX11",
    "gene-DDX17",
    "gene-DDX18",
    "gene-DDX19A",
    "gene-DDX19B",
    "gene-DDX20",
    "gene-DDX21",
    "gene-DDX23",
    "gene-DDX24",
    "gene-DDX25",
    "gene-DDX27",
    "gene-DDX28",
    "gene-DDX31",
    "gene-DDX39A",
    "gene-DDX39B",
    "gene-DDX3X",
    "gene-DDX3Y",
    "gene-DDX4",
    "gene-DDX41",
    "gene-DDX42",
    "gene-DDX43",
    "gene-DDX46",
    "gene-DDX47",
    "gene-DDX49",
    "gene-DDX5",
    "gene-DDX50",
    "gene-DDX51",
    "gene-DDX52",
    "gene-DDX53",
    "gene-DDX54",
    "gene-DDX55",
    "gene-DDX56",
    "gene-DDX59",
    "gene-DDX6",
    "gene-DDX60",
    "gene-DDX60L",
    "gene-DEAF1",
    "gene-DECR1",
    "gene-DECR2",
    "gene-DEDD",
    "gene-DEDD2",
    "gene-DEF6",
    "gene-DEF8",
    "gene-DEFA1",
    "gene-DEFA1B",
    "gene-DEFA3",
    "gene-DEFA4",
    "gene-DEFA5",
    "gene-DEFA6",
    "gene-DEFB1",
    "gene-DEFB103A",
    "gene-DEFB103B",
    "gene-DEFB104A",
    "gene-DEFB104B",
    "gene-DEFB105A",
    "gene-DEFB105B",
    "gene-DEFB106A",
    "gene-DEFB106B",
    "gene-DEFB107A",
    "gene-DEFB107B",
    "gene-DEFB108B",
    "gene-DEFB109B",
    "gene-DEFB110",
    "gene-DEFB112",
    "gene-DEFB113",
    "gene-DEFB114",
    "gene-DEFB115",
    "gene-DEFB116",
    "gene-DEFB118",
    "gene-DEFB119",
    "gene-DEFB121",
    "gene-DEFB123",
    "gene-DEFB124",
    "gene-DEFB125",
    "gene-DEFB126",
    "gene-DEFB127",
    "gene-DEFB128",
    "gene-DEFB129",
    "gene-DEFB130A",
    "gene-DEFB130B",
    "gene-DEFB131A",
    "gene-DEFB131B",
    "gene-DEFB132",
    "gene-DEFB134",
    "gene-DEFB135",
    "gene-DEFB136",
    "gene-DEFB4A",
    "gene-DEFB4B",
    "gene-DEGS1",
    "gene-DEGS2",
    "gene-DEK",
    "gene-DELE1",
    "gene-DENND10",
    "gene-DENND11",
    "gene-DENND1A",
    "gene-DENND1B",
    "gene-DENND1C",
    "gene-DENND2A",
    "gene-DENND2B",
    "gene-DENND2C",
    "gene-DENND2D",
    "gene-DENND3",
    "gene-DENND4A",
    "gene-DENND4B",
    "gene-DENND4C",
    "gene-DENND5A",
    "gene-DENND5B",
    "gene-DENND6A",
    "gene-DENND6B",
    "gene-DENR",
    "gene-DEPDC1",
    "gene-DEPDC1B",
    "gene-DEPDC4",
    "gene-DEPDC5",
    "gene-DEPDC7",
    "gene-DEPP1",
    "gene-DEPTOR",
    "gene-DERA",
    "gene-DERL1",
    "gene-DERL2",
    "gene-DERL3",
    "gene-DERPC",
    "gene-DES",
    "gene-DESI1",
    "gene-DESI2",
    "gene-DET1",
    "gene-DEUP1",
    "gene-DEXI",
    "gene-DFFA",
    "gene-DFFB",
    "gene-DGAT1",
    "gene-DGAT2",
    "gene-DGAT2L6",
    "gene-DGCR2",
    "gene-DGCR6",
    "gene-DGCR6L",
    "gene-DGCR8",
    "gene-DGKA",
    "gene-DGKB",
    "gene-DGKD",
    "gene-DGKE",
    "gene-DGKG",
    "gene-DGKH",
    "gene-DGKI",
    "gene-DGKK",
    "gene-DGKQ",
    "gene-DGKZ",
    "gene-DGLUCY",
    "gene-DGUOK",
    "gene-DHCR24",
    "gene-DHCR7",
    "gene-DHDDS",
    "gene-DHDH",
    "gene-DHFR",
    "gene-DHFR2",
    "gene-DHH",
    "gene-DHODH",
    "gene-DHPS",
    "gene-DHRS1",
    "gene-DHRS11",
    "gene-DHRS12",
    "gene-DHRS13",
    "gene-DHRS2",
    "gene-DHRS3",
    "gene-DHRS4",
    "gene-DHRS4L2",
    "gene-DHRS7",
    "gene-DHRS7B",
    "gene-DHRS7C",
    "gene-DHRS9",
    "gene-DHRSX",
    "gene-DHTKD1",
    "gene-DHX15",
    "gene-DHX16",
    "gene-DHX29",
    "gene-DHX30",
    "gene-DHX32",
    "gene-DHX33",
    "gene-DHX34",
    "gene-DHX35",
    "gene-DHX36",
    "gene-DHX37",
    "gene-DHX38",
    "gene-DHX40",
    "gene-DHX57",
    "gene-DHX58",
    "gene-DHX8",
    "gene-DHX9",
    "gene-DIABLO",
    "gene-DIAPH1",
    "gene-DIAPH2",
    "gene-DIAPH3",
    "gene-DICER1",
    "gene-DIDO1",
    "gene-DIMT1",
    "gene-DIO1",
    "gene-DIO2",
    "gene-DIO3",
    "gene-DIP2A",
    "gene-DIP2B",
    "gene-DIP2C",
    "gene-DIPK1A",
    "gene-DIPK1B",
    "gene-DIPK1C",
    "gene-DIPK2A",
    "gene-DIPK2B",
    "gene-DIRAS1",
    "gene-DIRAS2",
    "gene-DIRAS3",
    "gene-DIS3",
    "gene-DIS3L",
    "gene-DIS3L2",
    "gene-DISC1",
    "gene-DISP1",
    "gene-DISP2",
    "gene-DISP3",
    "gene-DIXDC1",
    "gene-DKC1",
    "gene-DKK1",
    "gene-DKK2",
    "gene-DKK3",
    "gene-DKK4",
    "gene-DKKL1",
    "gene-DLAT",
    "gene-DLC1",
    "gene-DLD",
    "gene-DLEC1",
    "gene-DLEU7",
    "gene-DLG1",
    "gene-DLG2",
    "gene-DLG3",
    "gene-DLG4",
    "gene-DLG5",
    "gene-DLGAP1",
    "gene-DLGAP2",
    "gene-DLGAP3",
    "gene-DLGAP4",
    "gene-DLGAP5",
    "gene-DLK1",
    "gene-DLK2",
    "gene-DLL1",
    "gene-DLL3",
    "gene-DLL4",
    "gene-DLST",
    "gene-DLX1",
    "gene-DLX2",
    "gene-DLX3",
    "gene-DLX4",
    "gene-DLX5",
    "gene-DLX6",
    "gene-DMAC1",
    "gene-DMAC2",
    "gene-DMAC2L",
    "gene-DMAP1",
    "gene-DMBT1",
    "gene-DMBX1",
    "gene-DMC1",
    "gene-DMD",
    "gene-DMGDH",
    "gene-DMKN",
    "gene-DMP1",
    "gene-DMPK",
    "gene-DMRT1",
    "gene-DMRT2",
    "gene-DMRT3",
    "gene-DMRTA1",
    "gene-DMRTA2",
    "gene-DMRTB1",
    "gene-DMRTC1",
    "gene-DMRTC1B",
    "gene-DMRTC2",
    "gene-DMTF1",
    "gene-DMTN",
    "gene-DMWD",
    "gene-DMXL1",
    "gene-DMXL2",
    "gene-DNA2",
    "gene-DNAAF1",
    "gene-DNAAF10",
    "gene-DNAAF11",
    "gene-DNAAF2",
    "gene-DNAAF3",
    "gene-DNAAF4",
    "gene-DNAAF5",
    "gene-DNAAF6",
    "gene-DNAAF8",
    "gene-DNAAF9",
    "gene-DNAH1",
    "gene-DNAH10",
    "gene-DNAH10OS",
    "gene-DNAH11",
    "gene-DNAH12",
    "gene-DNAH14",
    "gene-DNAH17",
    "gene-DNAH2",
    "gene-DNAH3",
    "gene-DNAH5",
    "gene-DNAH6",
    "gene-DNAH7",
    "gene-DNAH8",
    "gene-DNAH9",
    "gene-DNAI1",
    "gene-DNAI2",
    "gene-DNAI3",
    "gene-DNAI4",
    "gene-DNAI7",
    "gene-DNAJA1",
    "gene-DNAJA2",
    "gene-DNAJA3",
    "gene-DNAJA4",
    "gene-DNAJB1",
    "gene-DNAJB11",
    "gene-DNAJB12",
    "gene-DNAJB13",
    "gene-DNAJB14",
    "gene-DNAJB2",
    "gene-DNAJB4",
    "gene-DNAJB5",
    "gene-DNAJB6",
    "gene-DNAJB7",
    "gene-DNAJB8",
    "gene-DNAJB9",
    "gene-DNAJC1",
    "gene-DNAJC10",
    "gene-DNAJC11",
    "gene-DNAJC12",
    "gene-DNAJC13",
    "gene-DNAJC14",
    "gene-DNAJC15",
    "gene-DNAJC16",
    "gene-DNAJC17",
    "gene-DNAJC18",
    "gene-DNAJC19",
    "gene-DNAJC2",
    "gene-DNAJC21",
    "gene-DNAJC22",
    "gene-DNAJC24",
    "gene-DNAJC25",
    "gene-DNAJC27",
    "gene-DNAJC28",
    "gene-DNAJC3",
    "gene-DNAJC30",
    "gene-DNAJC4",
    "gene-DNAJC5",
    "gene-DNAJC5B",
    "gene-DNAJC5G",
    "gene-DNAJC6",
    "gene-DNAJC7",
    "gene-DNAJC8",
    "gene-DNAJC9",
    "gene-DNAL1",
    "gene-DNAL4",
    "gene-DNALI1",
    "gene-DNASE1",
    "gene-DNASE1L1",
    "gene-DNASE1L2",
    "gene-DNASE1L3",
    "gene-DNASE2",
    "gene-DNASE2B",
    "gene-DND1",
    "gene-DNER",
    "gene-DNHD1",
    "gene-DNLZ",
    "gene-DNM1",
    "gene-DNM1L",
    "gene-DNM2",
    "gene-DNM3",
    "gene-DNMBP",
    "gene-DNMT1",
    "gene-DNMT3A",
    "gene-DNMT3B",
    "gene-DNMT3L",
    "gene-DNPEP",
    "gene-DNPH1",
    "gene-DNTT",
    "gene-DNTTIP1",
    "gene-DNTTIP2",
    "gene-DOC2A",
    "gene-DOC2B",
    "gene-DOCK1",
    "gene-DOCK10",
    "gene-DOCK11",
    "gene-DOCK2",
    "gene-DOCK3",
    "gene-DOCK4",
    "gene-DOCK5",
    "gene-DOCK6",
    "gene-DOCK7",
    "gene-DOCK8",
    "gene-DOCK9",
    "gene-DOHH",
    "gene-DOK1",
    "gene-DOK2",
    "gene-DOK3",
    "gene-DOK4",
    "gene-DOK5",
    "gene-DOK6",
    "gene-DOK7",
    "gene-DOLK",
    "gene-DOLPP1",
    "gene-DONSON",
    "gene-DOP1A",
    "gene-DOP1B",
    "gene-DOT1L",
    "gene-DPAGT1",
    "gene-DPCD",
    "gene-DPEP1",
    "gene-DPEP2",
    "gene-DPEP2NB",
    "gene-DPEP3",
    "gene-DPF1",
    "gene-DPF2",
    "gene-DPF3",
    "gene-DPH1",
    "gene-DPH2",
    "gene-DPH3",
    "gene-DPH5",
    "gene-DPH6",
    "gene-DPH7",
    "gene-DPM1",
    "gene-DPM2",
    "gene-DPM3",
    "gene-DPP10",
    "gene-DPP3",
    "gene-DPP4",
    "gene-DPP6",
    "gene-DPP7",
    "gene-DPP8",
    "gene-DPP9",
    "gene-DPPA2",
    "gene-DPPA3",
    "gene-DPPA4",
    "gene-DPPA5",
    "gene-DPRX",
    "gene-DPT",
    "gene-DPY19L1",
    "gene-DPY19L2",
    "gene-DPY19L3",
    "gene-DPY19L4",
    "gene-DPY30",
    "gene-DPYD",
    "gene-DPYS",
    "gene-DPYSL2",
    "gene-DPYSL3",
    "gene-DPYSL4",
    "gene-DPYSL5",
    "gene-DQX1",
    "gene-DR1",
    "gene-DRAM1",
    "gene-DRAM2",
    "gene-DRAP1",
    "gene-DRAXIN",
    "gene-DRC1",
    "gene-DRC3",
    "gene-DRC7",
    "gene-DRD1",
    "gene-DRD2",
    "gene-DRD3",
    "gene-DRD4",
    "gene-DRD5",
    "gene-DRG1",
    "gene-DRG2",
    "gene-DRGX",
    "gene-DRICH1",
    "gene-DROSHA",
    "gene-DRP2",
    "gene-DSC1",
    "gene-DSC2",
    "gene-DSC3",
    "gene-DSCAM",
    "gene-DSCAML1",
    "gene-DSCC1",
    "gene-DSE",
    "gene-DSEL",
    "gene-DSG1",
    "gene-DSG2",
    "gene-DSG3",
    "gene-DSG4",
    "gene-DSN1",
    "gene-DSP",
    "gene-DSPP",
    "gene-DST",
    "gene-DSTN",
    "gene-DSTYK",
    "gene-DTD1",
    "gene-DTD2",
    "gene-DTHD1",
    "gene-DTL",
    "gene-DTNA",
    "gene-DTNB",
    "gene-DTNBP1",
    "gene-DTWD1",
    "gene-DTWD2",
    "gene-DTX1",
    "gene-DTX2",
    "gene-DTX3",
    "gene-DTX3L",
    "gene-DTX4",
    "gene-DTYMK",
    "gene-DUOX1",
    "gene-DUOX2",
    "gene-DUOXA1",
    "gene-DUOXA2",
    "gene-DUS1L",
    "gene-DUS2",
    "gene-DUS3L",
    "gene-DUS4L",
    "gene-DUSP1",
    "gene-DUSP10",
    "gene-DUSP11",
    "gene-DUSP12",
    "gene-DUSP13A",
    "gene-DUSP13B",
    "gene-DUSP14",
    "gene-DUSP15",
    "gene-DUSP16",
    "gene-DUSP18",
    "gene-DUSP19",
    "gene-DUSP2",
    "gene-DUSP21",
    "gene-DUSP22",
    "gene-DUSP23",
    "gene-DUSP26",
    "gene-DUSP28",
    "gene-DUSP29",
    "gene-DUSP3",
    "gene-DUSP4",
    "gene-DUSP5",
    "gene-DUSP6",
    "gene-DUSP7",
    "gene-DUSP8",
    "gene-DUSP9",
    "gene-DUT",
    "gene-DUX1",
    "gene-DUX3",
    "gene-DUX4",
    "gene-DUX5",
    "gene-DUXA",
    "gene-DUXB",
    "gene-DVL1",
    "gene-DVL2",
    "gene-DVL3",
    "gene-DXO",
    "gene-DYDC1",
    "gene-DYDC2",
    "gene-DYM",
    "gene-DYNAP",
    "gene-DYNC1H1",
    "gene-DYNC1I1",
    "gene-DYNC1I2",
    "gene-DYNC1LI1",
    "gene-DYNC1LI2",
    "gene-DYNC2H1",
    "gene-DYNC2I1",
    "gene-DYNC2I2",
    "gene-DYNC2LI1",
    "gene-DYNLL1",
    "gene-DYNLL2",
    "gene-DYNLRB1",
    "gene-DYNLRB2",
    "gene-DYNLT1",
    "gene-DYNLT2",
    "gene-DYNLT2B",
    "gene-DYNLT3",
    "gene-DYNLT4",
    "gene-DYNLT5",
    "gene-DYRK1A",
    "gene-DYRK1B",
    "gene-DYRK2",
    "gene-DYRK3",
    "gene-DYRK4",
    "gene-DYSF",
    "gene-DYTN",
    "gene-DZANK1",
    "gene-DZIP1",
    "gene-DZIP1L",
    "gene-DZIP3",
    "gene-E2F1",
    "gene-E2F2",
    "gene-E2F3",
    "gene-E2F4",
    "gene-E2F5",
    "gene-E2F6",
    "gene-E2F7",
    "gene-E2F8",
    "gene-E4F1",
    "gene-EAF1",
    "gene-EAF2",
    "gene-EAPP",
    "gene-EARS2",
    "gene-EBAG9",
    "gene-EBF1",
    "gene-EBF2",
    "gene-EBF3",
    "gene-EBF4",
    "gene-EBI3",
    "gene-EBLN1",
    "gene-EBLN2",
    "gene-EBNA1BP2",
    "gene-EBP",
    "gene-EBPL",
    "gene-ECD",
    "gene-ECE1",
    "gene-ECE2",
    "gene-ECEL1",
    "gene-ECH1",
    "gene-ECHDC1",
    "gene-ECHDC2",
    "gene-ECHDC3",
    "gene-ECHS1",
    "gene-ECI1",
    "gene-ECI2",
    "gene-ECM1",
    "gene-ECM2",
    "gene-ECPAS",
    "gene-ECRG4",
    "gene-ECSCR",
    "gene-ECSIT",
    "gene-ECT2",
    "gene-ECT2L",
    "gene-EDA",
    "gene-EDA2R",
    "gene-EDAR",
    "gene-EDARADD",
    "gene-EDC3",
    "gene-EDC4",
    "gene-EDDM13",
    "gene-EDDM3A",
    "gene-EDDM3B",
    "gene-EDEM1",
    "gene-EDEM2",
    "gene-EDEM3",
    "gene-EDF1",
    "gene-EDIL3",
    "gene-EDN1",
    "gene-EDN2",
    "gene-EDN3",
    "gene-EDNRA",
    "gene-EDNRB",
    "gene-EDRF1",
    "gene-EEA1",
    "gene-EED",
    "gene-EEF1A1",
    "gene-EEF1A2",
    "gene-EEF1AKMT1",
    "gene-EEF1AKMT2",
    "gene-EEF1AKMT3",
    "gene-EEF1AKMT4",
    "gene-EEF1B2",
    "gene-EEF1D",
    "gene-EEF1E1",
    "gene-EEF1G",
    "gene-EEF2",
    "gene-EEF2K",
    "gene-EEF2KMT",
    "gene-EEFSEC",
    "gene-EEIG1",
    "gene-EEIG2",
    "gene-EEPD1",
    "gene-EFCAB10",
    "gene-EFCAB11",
    "gene-EFCAB12",
    "gene-EFCAB13",
    "gene-EFCAB14",
    "gene-EFCAB2",
    "gene-EFCAB3",
    "gene-EFCAB5",
    "gene-EFCAB6",
    "gene-EFCAB7",
    "gene-EFCAB8",
    "gene-EFCAB9",
    "gene-EFCC1",
    "gene-EFEMP1",
    "gene-EFEMP2",
    "gene-EFHB",
    "gene-EFHC1",
    "gene-EFHC2",
    "gene-EFHD1",
    "gene-EFHD2",
    "gene-EFL1",
    "gene-EFNA1",
    "gene-EFNA2",
    "gene-EFNA3",
    "gene-EFNA4",
    "gene-EFNA5",
    "gene-EFNB1",
    "gene-EFNB2",
    "gene-EFNB3",
    "gene-EFR3A",
    "gene-EFR3B",
    "gene-EFS",
    "gene-EFTUD2",
    "gene-EGF",
    "gene-EGFL6",
    "gene-EGFL7",
    "gene-EGFL8",
    "gene-EGFLAM",
    "gene-EGFR",
    "gene-EGLN1",
    "gene-EGLN2",
    "gene-EGLN3",
    "gene-EGR1",
    "gene-EGR2",
    "gene-EGR3",
    "gene-EGR4",
    "gene-EHBP1",
    "gene-EHBP1L1",
    "gene-EHD1",
    "gene-EHD2",
    "gene-EHD3",
    "gene-EHD4",
    "gene-EHF",
    "gene-EHHADH",
    "gene-EHMT1",
    "gene-EHMT2",
    "gene-EI24",
    "gene-EID1",
    "gene-EID2",
    "gene-EID2B",
    "gene-EID3",
    "gene-EIF1",
    "gene-EIF1AD",
    "gene-EIF1AX",
    "gene-EIF1AY",
    "gene-EIF1B",
    "gene-EIF2A",
    "gene-EIF2AK1",
    "gene-EIF2AK2",
    "gene-EIF2AK3",
    "gene-EIF2AK4",
    "gene-EIF2B1",
    "gene-EIF2B2",
    "gene-EIF2B3",
    "gene-EIF2B4",
    "gene-EIF2B5",
    "gene-EIF2D",
    "gene-EIF2S1",
    "gene-EIF2S2",
    "gene-EIF2S3",
    "gene-EIF2S3B",
    "gene-EIF3A",
    "gene-EIF3B",
    "gene-EIF3C",
    "gene-EIF3CL",
    "gene-EIF3D",
    "gene-EIF3E",
    "gene-EIF3F",
    "gene-EIF3G",
    "gene-EIF3H",
    "gene-EIF3I",
    "gene-EIF3J",
    "gene-EIF3K",
    "gene-EIF3L",
    "gene-EIF3M",
    "gene-EIF4A1",
    "gene-EIF4A2",
    "gene-EIF4A3",
    "gene-EIF4B",
    "gene-EIF4E",
    "gene-EIF4E1B",
    "gene-EIF4E2",
    "gene-EIF4E3",
    "gene-EIF4EBP1",
    "gene-EIF4EBP2",
    "gene-EIF4EBP3",
    "gene-EIF4ENIF1",
    "gene-EIF4G1",
    "gene-EIF4G2",
    "gene-EIF4G3",
    "gene-EIF4H",
    "gene-EIF5",
    "gene-EIF5A",
    "gene-EIF5A2",
    "gene-EIF5AL1",
    "gene-EIF5B",
    "gene-EIF6",
    "gene-EIPR1",
    "gene-ELAC1",
    "gene-ELAC2",
    "gene-ELANE",
    "gene-ELAPOR1",
    "gene-ELAPOR2",
    "gene-ELAVL1",
    "gene-ELAVL2",
    "gene-ELAVL3",
    "gene-ELAVL4",
    "gene-ELF1",
    "gene-ELF2",
    "gene-ELF3",
    "gene-ELF4",
    "gene-ELF5",
    "gene-ELFN1",
    "gene-ELFN2",
    "gene-ELK1",
    "gene-ELK3",
    "gene-ELK4",
    "gene-ELL",
    "gene-ELL2",
    "gene-ELL3",
    "gene-ELMO1",
    "gene-ELMO2",
    "gene-ELMO3",
    "gene-ELMOD1",
    "gene-ELMOD2",
    "gene-ELMOD3",
    "gene-ELN",
    "gene-ELOA",
    "gene-ELOA2",
    "gene-ELOB",
    "gene-ELOC",
    "gene-ELOF1",
    "gene-ELOVL1",
    "gene-ELOVL2",
    "gene-ELOVL3",
    "gene-ELOVL4",
    "gene-ELOVL5",
    "gene-ELOVL6",
    "gene-ELOVL7",
    "gene-ELP1",
    "gene-ELP2",
    "gene-ELP3",
    "gene-ELP4",
    "gene-ELP5",
    "gene-ELP6",
    "gene-ELSPBP1",
    "gene-EMB",
    "gene-EMC1",
    "gene-EMC10",
    "gene-EMC2",
    "gene-EMC3",
    "gene-EMC4",
    "gene-EMC6",
    "gene-EMC7",
    "gene-EMC8",
    "gene-EMC9",
    "gene-EMCN",
    "gene-EMD",
    "gene-EME1",
    "gene-EME2",
    "gene-EMG1",
    "gene-EMID1",
    "gene-EMILIN1",
    "gene-EMILIN2",
    "gene-EMILIN3",
    "gene-EML1",
    "gene-EML2",
    "gene-EML3",
    "gene-EML4",
    "gene-EML5",
    "gene-EML6",
    "gene-EMP1",
    "gene-EMP2",
    "gene-EMP3",
    "gene-EMSY",
    "gene-EMX1",
    "gene-EMX2",
    "gene-EN1",
    "gene-EN2",
    "gene-ENAH",
    "gene-ENAM",
    "gene-ENC1",
    "gene-ENDOD1",
    "gene-ENDOG",
    "gene-ENDOU",
    "gene-ENDOV",
    "gene-ENG",
    "gene-ENGASE",
    "gene-ENHO",
    "gene-ENKD1",
    "gene-ENKUR",
    "gene-ENO1",
    "gene-ENO2",
    "gene-ENO3",
    "gene-ENO4",
    "gene-ENOPH1",
    "gene-ENOSF1",
    "gene-ENOX1",
    "gene-ENOX2",
    "gene-ENPEP",
    "gene-ENPP1",
    "gene-ENPP2",
    "gene-ENPP3",
    "gene-ENPP4",
    "gene-ENPP5",
    "gene-ENPP6",
    "gene-ENPP7",
    "gene-ENSA",
    "gene-ENTHD1",
    "gene-ENTPD1",
    "gene-ENTPD2",
    "gene-ENTPD3",
    "gene-ENTPD4",
    "gene-ENTPD5",
    "gene-ENTPD6",
    "gene-ENTPD7",
    "gene-ENTPD8",
    "gene-ENTR1",
    "gene-ENTREP1",
    "gene-ENTREP2",
    "gene-ENTREP3",
    "gene-ENY2",
    "gene-EOGT",
    "gene-EOLA1",
    "gene-EOLA2",
    "gene-EOMES",
    "gene-EP300",
    "gene-EP400",
    "gene-EPAS1",
    "gene-EPB41",
    "gene-EPB41L1",
    "gene-EPB41L2",
    "gene-EPB41L3",
    "gene-EPB41L4A",
    "gene-EPB41L4B",
    "gene-EPB41L5",
    "gene-EPB42",
    "gene-EPC1",
    "gene-EPC2",
    "gene-EPCAM",
    "gene-EPDR1",
    "gene-EPG5",
    "gene-EPGN",
    "gene-EPHA1",
    "gene-EPHA10",
    "gene-EPHA2",
    "gene-EPHA3",
    "gene-EPHA4",
    "gene-EPHA5",
    "gene-EPHA6",
    "gene-EPHA7",
    "gene-EPHA8",
    "gene-EPHB1",
    "gene-EPHB2",
    "gene-EPHB3",
    "gene-EPHB4",
    "gene-EPHB6",
    "gene-EPHX1",
    "gene-EPHX2",
    "gene-EPHX3",
    "gene-EPHX4",
    "gene-EPM2A",
    "gene-EPM2AIP1",
    "gene-EPN1",
    "gene-EPN2",
    "gene-EPN3",
    "gene-EPO",
    "gene-EPOP",
    "gene-EPOR",
    "gene-EPPIN",
    "gene-EPPK1",
    "gene-EPRS1",
    "gene-EPS15",
    "gene-EPS15L1",
    "gene-EPS8",
    "gene-EPS8L1",
    "gene-EPS8L2",
    "gene-EPS8L3",
    "gene-EPSTI1",
    "gene-EPX",
    "gene-EPYC",
    "gene-EQTN",
    "gene-ERAL1",
    "gene-ERAP1",
    "gene-ERAP2",
    "gene-ERAS",
    "gene-ERBB2",
    "gene-ERBB3",
    "gene-ERBB4",
    "gene-ERBIN",
    "gene-ERC1",
    "gene-ERC2",
    "gene-ERCC1",
    "gene-ERCC2",
    "gene-ERCC3",
    "gene-ERCC4",
    "gene-ERCC5",
    "gene-ERCC6",
    "gene-ERCC6L",
    "gene-ERCC6L2",
    "gene-ERCC8",
    "gene-EREG",
    "gene-ERF",
    "gene-ERFE",
    "gene-ERFL",
    "gene-ERG",
    "gene-ERG28",
    "gene-ERGIC1",
    "gene-ERGIC2",
    "gene-ERGIC3",
    "gene-ERH",
    "gene-ERI1",
    "gene-ERI2",
    "gene-ERI3",
    "gene-ERICH1",
    "gene-ERICH2",
    "gene-ERICH3",
    "gene-ERICH4",
    "gene-ERICH5",
    "gene-ERICH6",
    "gene-ERICH6B",
    "gene-ERLEC1",
    "gene-ERLIN1",
    "gene-ERLIN2",
    "gene-ERMAP",
    "gene-ERMARD",
    "gene-ERMN",
    "gene-ERMP1",
    "gene-ERN1",
    "gene-ERN2",
    "gene-ERO1A",
    "gene-ERO1B",
    "gene-ERP27",
    "gene-ERP29",
    "gene-ERP44",
    "gene-ERRFI1",
    "gene-ERV3-1",
    "gene-ERVFRD-1",
    "gene-ERVMER34-1",
    "gene-ERVV-1",
    "gene-ERVV-2",
    "gene-ERVW-1",
    "gene-ESAM",
    "gene-ESCO1",
    "gene-ESCO2",
    "gene-ESD",
    "gene-ESF1",
    "gene-ESM1",
    "gene-ESPL1",
    "gene-ESPN",
    "gene-ESPNL",
    "gene-ESR1",
    "gene-ESR2",
    "gene-ESRP1",
    "gene-ESRP2",
    "gene-ESRRA",
    "gene-ESRRB",
    "gene-ESRRG",
    "gene-ESS2",
    "gene-ESX1",
    "gene-ESYT1",
    "gene-ESYT2",
    "gene-ESYT3",
    "gene-ETAA1",
    "gene-ETDA",
    "gene-ETDB",
    "gene-ETDC",
    "gene-ETF1",
    "gene-ETFA",
    "gene-ETFB",
    "gene-ETFBKMT",
    "gene-ETFDH",
    "gene-ETFRF1",
    "gene-ETHE1",
    "gene-ETNK1",
    "gene-ETNK2",
    "gene-ETNPPL",
    "gene-ETS1",
    "gene-ETS2",
    "gene-ETV1",
    "gene-ETV2",
    "gene-ETV3",
    "gene-ETV3L",
    "gene-ETV4",
    "gene-ETV5",
    "gene-ETV6",
    "gene-ETV7",
    "gene-EVA1A",
    "gene-EVA1B",
    "gene-EVA1C",
    "gene-EVC",
    "gene-EVC2",
    "gene-EVI2A",
    "gene-EVI2B",
    "gene-EVI5",
    "gene-EVI5L",
    "gene-EVL",
    "gene-EVPL",
    "gene-EVPLL",
    "gene-EVX1",
    "gene-EVX2",
    "gene-EWSR1",
    "gene-EXD1",
    "gene-EXD2",
    "gene-EXD3",
    "gene-EXO1",
    "gene-EXO5",
    "gene-EXOC1",
    "gene-EXOC1L",
    "gene-EXOC2",
    "gene-EXOC3",
    "gene-EXOC3L1",
    "gene-EXOC3L2",
    "gene-EXOC3L4",
    "gene-EXOC4",
    "gene-EXOC5",
    "gene-EXOC6",
    "gene-EXOC6B",
    "gene-EXOC7",
    "gene-EXOC8",
    "gene-EXOG",
    "gene-EXOSC1",
    "gene-EXOSC10",
    "gene-EXOSC2",
    "gene-EXOSC3",
    "gene-EXOSC4",
    "gene-EXOSC5",
    "gene-EXOSC6",
    "gene-EXOSC7",
    "gene-EXOSC8",
    "gene-EXOSC9",
    "gene-EXPH5",
    "gene-EXT1",
    "gene-EXT2",
    "gene-EXTL1",
    "gene-EXTL2",
    "gene-EXTL3",
    "gene-EYA1",
    "gene-EYA2",
    "gene-EYA3",
    "gene-EYA4",
    "gene-EYS",
    "gene-EZH1",
    "gene-EZH2",
    "gene-EZHIP",
    "gene-EZR",
    "gene-F10",
    "gene-F11",
    "gene-F11R",
    "gene-F12",
    "gene-F13A1",
    "gene-F13B",
    "gene-F2",
    "gene-F2R",
    "gene-F2RL1",
    "gene-F2RL2",
    "gene-F2RL3",
    "gene-F3",
    "gene-F5",
    "gene-F7",
    "gene-F8",
    "gene-F8A1",
    "gene-F8A2",
    "gene-F8A3",
    "gene-F9",
    "gene-FA2H",
    "gene-FAAH",
    "gene-FAAH2",
    "gene-FAAP100",
    "gene-FAAP20",
    "gene-FAAP24",
    "gene-FABP1",
    "gene-FABP12",
    "gene-FABP2",
    "gene-FABP3",
    "gene-FABP4",
    "gene-FABP5",
    "gene-FABP6",
    "gene-FABP7",
    "gene-FABP9",
    "gene-FADD",
    "gene-FADS1",
    "gene-FADS2",
    "gene-FADS3",
    "gene-FADS6",
    "gene-FAF1",
    "gene-FAF2",
    "gene-FAH",
    "gene-FAHD1",
    "gene-FAHD2A",
    "gene-FAHD2B",
    "gene-FAIM",
    "gene-FAIM2",
    "gene-FAM107A",
    "gene-FAM107B",
    "gene-FAM110A",
    "gene-FAM110B",
    "gene-FAM110C",
    "gene-FAM110D",
    "gene-FAM111A",
    "gene-FAM111B",
    "gene-FAM114A1",
    "gene-FAM114A2",
    "gene-FAM117A",
    "gene-FAM117B",
    "gene-FAM118A",
    "gene-FAM118B",
    "gene-FAM120A",
    "gene-FAM120AOS",
    "gene-FAM120B",
    "gene-FAM120C",
    "gene-FAM124A",
    "gene-FAM124B",
    "gene-FAM131A",
    "gene-FAM131B",
    "gene-FAM131C",
    "gene-FAM133A",
    "gene-FAM133B",
    "gene-FAM135A",
    "gene-FAM135B",
    "gene-FAM136A",
    "gene-FAM13A",
    "gene-FAM13B",
    "gene-FAM13C",
    "gene-FAM149A",
    "gene-FAM149B1",
    "gene-FAM151A",
    "gene-FAM151B",
    "gene-FAM153A",
    "gene-FAM153B",
    "gene-FAM156A",
    "gene-FAM156B",
    "gene-FAM161A",
    "gene-FAM161B",
    "gene-FAM162A",
    "gene-FAM162B",
    "gene-FAM163A",
    "gene-FAM163B",
    "gene-FAM167A",
    "gene-FAM167B",
    "gene-FAM168A",
    "gene-FAM168B",
    "gene-FAM169A",
    "gene-FAM170A",
    "gene-FAM170B",
    "gene-FAM171A1",
    "gene-FAM171A2",
    "gene-FAM171B",
    "gene-FAM174A",
    "gene-FAM174B",
    "gene-FAM174C",
    "gene-FAM177A1",
    "gene-FAM177B",
    "gene-FAM178B",
    "gene-FAM180A",
    "gene-FAM180B",
    "gene-FAM181A",
    "gene-FAM181B",
    "gene-FAM184A",
    "gene-FAM184B",
    "gene-FAM185A",
    "gene-FAM186A",
    "gene-FAM186B",
    "gene-FAM187A",
    "gene-FAM187B",
    "gene-FAM193A",
    "gene-FAM193B",
    "gene-FAM199X",
    "gene-FAM200A",
    "gene-FAM200B",
    "gene-FAM200C",
    "gene-FAM204A",
    "gene-FAM209A",
    "gene-FAM209B",
    "gene-FAM20A",
    "gene-FAM20B",
    "gene-FAM20C",
    "gene-FAM210A",
    "gene-FAM210B",
    "gene-FAM216A",
    "gene-FAM216B",
    "gene-FAM217A",
    "gene-FAM217B",
    "gene-FAM219A",
    "gene-FAM219B",
    "gene-FAM220A",
    "gene-FAM221A",
    "gene-FAM221B",
    "gene-FAM222A",
    "gene-FAM222B",
    "gene-FAM227A",
    "gene-FAM227B",
    "gene-FAM228A",
    "gene-FAM228B",
    "gene-FAM229A",
    "gene-FAM229B",
    "gene-FAM234A",
    "gene-FAM234B",
    "gene-FAM236A",
    "gene-FAM236B",
    "gene-FAM236C",
    "gene-FAM236D",
    "gene-FAM237A",
    "gene-FAM237B",
    "gene-FAM240A",
    "gene-FAM240B",
    "gene-FAM240C",
    "gene-FAM241A",
    "gene-FAM241B",
    "gene-FAM246A",
    "gene-FAM246B",
    "gene-FAM246C",
    "gene-FAM24A",
    "gene-FAM24B",
    "gene-FAM25A",
    "gene-FAM25C",
    "gene-FAM25G",
    "gene-FAM32A",
    "gene-FAM3A",
    "gene-FAM3B",
    "gene-FAM3C",
    "gene-FAM3D",
    "gene-FAM43A",
    "gene-FAM43B",
    "gene-FAM47A",
    "gene-FAM47B",
    "gene-FAM47C",
    "gene-FAM47E",
    "gene-FAM50A",
    "gene-FAM50B",
    "gene-FAM53A",
    "gene-FAM53B",
    "gene-FAM53C",
    "gene-FAM72A",
    "gene-FAM72B",
    "gene-FAM72C",
    "gene-FAM72D",
    "gene-FAM76A",
    "gene-FAM76B",
    "gene-FAM78A",
    "gene-FAM78B",
    "gene-FAM81A",
    "gene-FAM81B",
    "gene-FAM83A",
    "gene-FAM83B",
    "gene-FAM83C",
    "gene-FAM83D",
    "gene-FAM83E",
    "gene-FAM83F",
    "gene-FAM83G",
    "gene-FAM83H",
    "gene-FAM86B1",
    "gene-FAM86B2",
    "gene-FAM89A",
    "gene-FAM89B",
    "gene-FAM8A1",
    "gene-FAM90A1",
    "gene-FAM90A10",
    "gene-FAM90A11",
    "gene-FAM90A12",
    "gene-FAM90A13",
    "gene-FAM90A14",
    "gene-FAM90A15",
    "gene-FAM90A16",
    "gene-FAM90A17",
    "gene-FAM90A18",
    "gene-FAM90A19",
    "gene-FAM90A20",
    "gene-FAM90A22",
    "gene-FAM90A23",
    "gene-FAM90A24",
    "gene-FAM90A26",
    "gene-FAM90A3",
    "gene-FAM90A5",
    "gene-FAM90A7",
    "gene-FAM90A8",
    "gene-FAM90A9",
    "gene-FAM91A1",
    "gene-FAM98A",
    "gene-FAM98B",
    "gene-FAM98C",
    "gene-FAM9A",
    "gene-FAM9B",
    "gene-FAM9C",
    "gene-FAN1",
    "gene-FANCA",
    "gene-FANCB",
    "gene-FANCC",
    "gene-FANCD2",
    "gene-FANCD2OS",
    "gene-FANCE",
    "gene-FANCF",
    "gene-FANCG",
    "gene-FANCI",
    "gene-FANCL",
    "gene-FANCM",
    "gene-FANK1",
    "gene-FAP",
    "gene-FAR1",
    "gene-FAR2",
    "gene-FARP1",
    "gene-FARP2",
    "gene-FARS2",
    "gene-FARSA",
    "gene-FARSB",
    "gene-FAS",
    "gene-FASLG",
    "gene-FASN",
    "gene-FASTK",
    "gene-FASTKD1",
    "gene-FASTKD2",
    "gene-FASTKD3",
    "gene-FASTKD5",
    "gene-FAT1",
    "gene-FAT2",
    "gene-FAT3",
    "gene-FAT4",
    "gene-FATE1",
    "gene-FAU",
    "gene-FAXC",
    "gene-FAXDC2",
    "gene-FBF1",
    "gene-FBH1",
    "gene-FBL",
    "gene-FBLIM1",
    "gene-FBLL1",
    "gene-FBLN1",
    "gene-FBLN2",
    "gene-FBLN5",
    "gene-FBLN7",
    "gene-FBN1",
    "gene-FBN2",
    "gene-FBN3",
    "gene-FBP1",
    "gene-FBP2",
    "gene-FBRS",
    "gene-FBRSL1",
    "gene-FBXL12",
    "gene-FBXL13",
    "gene-FBXL14",
    "gene-FBXL15",
    "gene-FBXL16",
    "gene-FBXL17",
    "gene-FBXL18",
    "gene-FBXL19",
    "gene-FBXL2",
    "gene-FBXL20",
    "gene-FBXL22",
    "gene-FBXL3",
    "gene-FBXL4",
    "gene-FBXL5",
    "gene-FBXL6",
    "gene-FBXL7",
    "gene-FBXL8",
    "gene-FBXO10",
    "gene-FBXO11",
    "gene-FBXO15",
    "gene-FBXO16",
    "gene-FBXO17",
    "gene-FBXO2",
    "gene-FBXO21",
    "gene-FBXO22",
    "gene-FBXO24",
    "gene-FBXO25",
    "gene-FBXO27",
    "gene-FBXO28",
    "gene-FBXO3",
    "gene-FBXO30",
    "gene-FBXO31",
    "gene-FBXO32",
    "gene-FBXO33",
    "gene-FBXO34",
    "gene-FBXO36",
    "gene-FBXO38",
    "gene-FBXO39",
    "gene-FBXO4",
    "gene-FBXO40",
    "gene-FBXO41",
    "gene-FBXO42",
    "gene-FBXO43",
    "gene-FBXO44",
    "gene-FBXO45",
    "gene-FBXO46",
    "gene-FBXO47",
    "gene-FBXO48",
    "gene-FBXO5",
    "gene-FBXO6",
    "gene-FBXO7",
    "gene-FBXO8",
    "gene-FBXO9",
    "gene-FBXW10",
    "gene-FBXW10B",
    "gene-FBXW11",
    "gene-FBXW12",
    "gene-FBXW2",
    "gene-FBXW4",
    "gene-FBXW5",
    "gene-FBXW7",
    "gene-FBXW8",
    "gene-FBXW9",
    "gene-FCAMR",
    "gene-FCAR",
    "gene-FCER1A",
    "gene-FCER1G",
    "gene-FCER2",
    "gene-FCF1",
    "gene-FCGBP",
    "gene-FCGR1A",
    "gene-FCGR2A",
    "gene-FCGR2B",
    "gene-FCGR2C",
    "gene-FCGR3A",
    "gene-FCGR3B",
    "gene-FCGRT",
    "gene-FCHO1",
    "gene-FCHO2",
    "gene-FCHSD1",
    "gene-FCHSD2",
    "gene-FCMR",
    "gene-FCN1",
    "gene-FCN2",
    "gene-FCN3",
    "gene-FCRL1",
    "gene-FCRL2",
    "gene-FCRL3",
    "gene-FCRL4",
    "gene-FCRL5",
    "gene-FCRL6",
    "gene-FCRLA",
    "gene-FCRLB",
    "gene-FCSK",
    "gene-FDCSP",
    "gene-FDFT1",
    "gene-FDPS",
    "gene-FDX1",
    "gene-FDX2",
    "gene-FDXACB1",
    "gene-FDXR",
    "gene-FECH",
    "gene-FEM1A",
    "gene-FEM1B",
    "gene-FEM1C",
    "gene-FEN1",
    "gene-FER",
    "gene-FER1L5",
    "gene-FER1L6",
    "gene-FERD3L",
    "gene-FERMT1",
    "gene-FERMT2",
    "gene-FERMT3",
    "gene-FES",
    "gene-FETUB",
    "gene-FEV",
    "gene-FEZ1",
    "gene-FEZ2",
    "gene-FEZF1",
    "gene-FEZF2",
    "gene-FFAR1",
    "gene-FFAR2",
    "gene-FFAR3",
    "gene-FFAR4",
    "gene-FGA",
    "gene-FGB",
    "gene-FGD1",
    "gene-FGD2",
    "gene-FGD3",
    "gene-FGD4",
    "gene-FGD5",
    "gene-FGD6",
    "gene-FGF1",
    "gene-FGF10",
    "gene-FGF11",
    "gene-FGF12",
    "gene-FGF13",
    "gene-FGF14",
    "gene-FGF16",
    "gene-FGF17",
    "gene-FGF18",
    "gene-FGF19",
    "gene-FGF2",
    "gene-FGF20",
    "gene-FGF21",
    "gene-FGF22",
    "gene-FGF23",
    "gene-FGF3",
    "gene-FGF4",
    "gene-FGF5",
    "gene-FGF6",
    "gene-FGF7",
    "gene-FGF8",
    "gene-FGF9",
    "gene-FGFBP1",
    "gene-FGFBP2",
    "gene-FGFBP3",
    "gene-FGFR1",
    "gene-FGFR1OP2",
    "gene-FGFR2",
    "gene-FGFR3",
    "gene-FGFR4",
    "gene-FGFRL1",
    "gene-FGG",
    "gene-FGGY",
    "gene-FGL1",
    "gene-FGL2",
    "gene-FGR",
    "gene-FH",
    "gene-FHAD1",
    "gene-FHDC1",
    "gene-FHIP1A",
    "gene-FHIP1B",
    "gene-FHIP2A",
    "gene-FHIP2B",
    "gene-FHIT",
    "gene-FHL1",
    "gene-FHL2",
    "gene-FHL3",
    "gene-FHL5",
    "gene-FHOD1",
    "gene-FHOD3",
    "gene-FIBCD1",
    "gene-FIBIN",
    "gene-FIBP",
    "gene-FICD",
    "gene-FIG4",
    "gene-FIGLA",
    "gene-FIGN",
    "gene-FIGNL1",
    "gene-FIGNL2",
    "gene-FILIP1",
    "gene-FILIP1L",
    "gene-FIP1L1",
    "gene-FIRRM",
    "gene-FIS1",
    "gene-FITM1",
    "gene-FITM2",
    "gene-FIZ1",
    "gene-FJX1",
    "gene-FKBP10",
    "gene-FKBP11",
    "gene-FKBP14",
    "gene-FKBP15",
    "gene-FKBP1A",
    "gene-FKBP1B",
    "gene-FKBP1C",
    "gene-FKBP2",
    "gene-FKBP3",
    "gene-FKBP4",
    "gene-FKBP5",
    "gene-FKBP6",
    "gene-FKBP7",
    "gene-FKBP8",
    "gene-FKBP9",
    "gene-FKBPL",
    "gene-FKRP",
    "gene-FKTN",
    "gene-FLACC1",
    "gene-FLAD1",
    "gene-FLCN",
    "gene-FLG",
    "gene-FLG2",
    "gene-FLI1",
    "gene-FLII",
    "gene-FLNA",
    "gene-FLNB",
    "gene-FLNC",
    "gene-FLOT1",
    "gene-FLOT2",
    "gene-FLRT1",
    "gene-FLRT2",
    "gene-FLRT3",
    "gene-FLT1",
    "gene-FLT3",
    "gene-FLT3LG",
    "gene-FLT4",
    "gene-FLVCR1",
    "gene-FLVCR2",
    "gene-FLYWCH1",
    "gene-FLYWCH2",
    "gene-FMC1",
    "gene-FMN1",
    "gene-FMN2",
    "gene-FMNL1",
    "gene-FMNL2",
    "gene-FMNL3",
    "gene-FMO1",
    "gene-FMO2",
    "gene-FMO3",
    "gene-FMO4",
    "gene-FMO5",
    "gene-FMOD",
    "gene-FMR1",
    "gene-FMR1NB",
    "gene-FN1",
    "gene-FN3K",
    "gene-FN3KRP",
    "gene-FNBP1",
    "gene-FNBP1L",
    "gene-FNBP4",
    "gene-FNDC1",
    "gene-FNDC10",
    "gene-FNDC11",
    "gene-FNDC3A",
    "gene-FNDC3B",
    "gene-FNDC4",
    "gene-FNDC5",
    "gene-FNDC7",
    "gene-FNDC8",
    "gene-FNDC9",
    "gene-FNIP1",
    "gene-FNIP2",
    "gene-FNTA",
    "gene-FNTB",
    "gene-FOCAD",
    "gene-FOLH1",
    "gene-FOLR1",
    "gene-FOLR2",
    "gene-FOLR3",
    "gene-FOS",
    "gene-FOSB",
    "gene-FOSL1",
    "gene-FOSL2",
    "gene-FOXA1",
    "gene-FOXA2",
    "gene-FOXA3",
    "gene-FOXB1",
    "gene-FOXB2",
    "gene-FOXC1",
    "gene-FOXC2",
    "gene-FOXD1",
    "gene-FOXD2",
    "gene-FOXD3",
    "gene-FOXD4",
    "gene-FOXD4L1",
    "gene-FOXD4L3",
    "gene-FOXD4L4",
    "gene-FOXD4L5",
    "gene-FOXD4L6",
    "gene-FOXE1",
    "gene-FOXE3",
    "gene-FOXF1",
    "gene-FOXF2",
    "gene-FOXG1",
    "gene-FOXH1",
    "gene-FOXI1",
    "gene-FOXI2",
    "gene-FOXI3",
    "gene-FOXJ1",
    "gene-FOXJ2",
    "gene-FOXJ3",
    "gene-FOXK1",
    "gene-FOXK2",
    "gene-FOXL1",
    "gene-FOXL2",
    "gene-FOXL2NB",
    "gene-FOXL3",
    "gene-FOXM1",
    "gene-FOXN1",
    "gene-FOXN2",
    "gene-FOXN3",
    "gene-FOXN4",
    "gene-FOXO1",
    "gene-FOXO3",
    "gene-FOXO3B",
    "gene-FOXO4",
    "gene-FOXO6",
    "gene-FOXP1",
    "gene-FOXP2",
    "gene-FOXP3",
    "gene-FOXP4",
    "gene-FOXQ1",
    "gene-FOXR1",
    "gene-FOXR2",
    "gene-FOXRED1",
    "gene-FOXRED2",
    "gene-FOXS1",
    "gene-FPGS",
    "gene-FPGT",
    "gene-FPR1",
    "gene-FPR2",
    "gene-FPR3",
    "gene-FRA10AC1",
    "gene-FRAS1",
    "gene-FRAT1",
    "gene-FRAT2",
    "gene-FREM1",
    "gene-FREM2",
    "gene-FREM3",
    "gene-FREY1",
    "gene-FRG1",
    "gene-FRG2",
    "gene-FRG2B",
    "gene-FRG2C",
    "gene-FRK",
    "gene-FRMD1",
    "gene-FRMD3",
    "gene-FRMD4A",
    "gene-FRMD4B",
    "gene-FRMD5",
    "gene-FRMD6",
    "gene-FRMD7",
    "gene-FRMD8",
    "gene-FRMPD1",
    "gene-FRMPD2",
    "gene-FRMPD3",
    "gene-FRMPD4",
    "gene-FRRS1",
    "gene-FRRS1L",
    "gene-FRS2",
    "gene-FRS3",
    "gene-FRY",
    "gene-FRYL",
    "gene-FRZB",
    "gene-FSBP",
    "gene-FSCB",
    "gene-FSCN1",
    "gene-FSCN2",
    "gene-FSCN3",
    "gene-FSD1",
    "gene-FSD1L",
    "gene-FSD2",
    "gene-FSHB",
    "gene-FSHR",
    "gene-FSIP1",
    "gene-FSIP2",
    "gene-FST",
    "gene-FSTL1",
    "gene-FSTL3",
    "gene-FSTL4",
    "gene-FSTL5",
    "gene-FTCD",
    "gene-FTCDNL1",
    "gene-FTH1",
    "gene-FTHL17",
    "gene-FTL",
    "gene-FTMT",
    "gene-FTO",
    "gene-FTSJ1",
    "gene-FTSJ3",
    "gene-FUBP1",
    "gene-FUBP3",
    "gene-FUCA1",
    "gene-FUCA2",
    "gene-FUNDC1",
    "gene-FUNDC2",
    "gene-FUOM",
    "gene-FURIN",
    "gene-FUS",
    "gene-FUT1",
    "gene-FUT10",
    "gene-FUT11",
    "gene-FUT2",
    "gene-FUT3",
    "gene-FUT4",
    "gene-FUT5",
    "gene-FUT6",
    "gene-FUT7",
    "gene-FUT8",
    "gene-FUT9",
    "gene-FUZ",
    "gene-FXN",
    "gene-FXR1",
    "gene-FXR2",
    "gene-FXYD1",
    "gene-FXYD2",
    "gene-FXYD3",
    "gene-FXYD4",
    "gene-FXYD5",
    "gene-FXYD6",
    "gene-FXYD7",
    "gene-FYB1",
    "gene-FYB2",
    "gene-FYCO1",
    "gene-FYN",
    "gene-FYTTD1",
    "gene-FZD1",
    "gene-FZD10",
    "gene-FZD2",
    "gene-FZD3",
    "gene-FZD4",
    "gene-FZD5",
    "gene-FZD6",
    "gene-FZD7",
    "gene-FZD8",
    "gene-FZD9",
    "gene-FZR1",
    "gene-G0S2",
    "gene-G2E3",
    "gene-G3BP1",
    "gene-G3BP2",
    "gene-G6PC1",
    "gene-G6PC2",
    "gene-G6PC3",
    "gene-G6PD",
    "gene-GAA",
    "gene-GAB1",
    "gene-GAB2",
    "gene-GAB3",
    "gene-GAB4",
    "gene-GABARAP",
    "gene-GABARAPL1",
    "gene-GABARAPL2",
    "gene-GABBR1",
    "gene-GABBR2",
    "gene-GABPA",
    "gene-GABPB1",
    "gene-GABPB2",
    "gene-GABRA1",
    "gene-GABRA2",
    "gene-GABRA3",
    "gene-GABRA4",
    "gene-GABRA5",
    "gene-GABRA6",
    "gene-GABRB1",
    "gene-GABRB2",
    "gene-GABRB3",
    "gene-GABRD",
    "gene-GABRE",
    "gene-GABRG1",
    "gene-GABRG2",
    "gene-GABRG3",
    "gene-GABRP",
    "gene-GABRQ",
    "gene-GABRR1",
    "gene-GABRR2",
    "gene-GABRR3",
    "gene-GAD1",
    "gene-GAD2",
    "gene-GADD45A",
    "gene-GADD45B",
    "gene-GADD45G",
    "gene-GADD45GIP1",
    "gene-GADL1",
    "gene-GAGE1",
    "gene-GAGE10",
    "gene-GAGE12B",
    "gene-GAGE12C",
    "gene-GAGE12D",
    "gene-GAGE12E",
    "gene-GAGE12F",
    "gene-GAGE12G",
    "gene-GAGE12H",
    "gene-GAGE12I",
    "gene-GAGE12J",
    "gene-GAGE13",
    "gene-GAGE2A",
    "gene-GAGE2B",
    "gene-GAGE2C",
    "gene-GAGE2D",
    "gene-GAGE2E",
    "gene-GAGE4",
    "gene-GAGE5",
    "gene-GAGE6",
    "gene-GAGE7",
    "gene-GAGE8",
    "gene-GAK",
    "gene-GAL",
    "gene-GAL3ST1",
    "gene-GAL3ST2",
    "gene-GAL3ST3",
    "gene-GAL3ST4",
    "gene-GALC",
    "gene-GALE",
    "gene-GALK1",
    "gene-GALK2",
    "gene-GALM",
    "gene-GALNS",
    "gene-GALNT1",
    "gene-GALNT10",
    "gene-GALNT11",
    "gene-GALNT12",
    "gene-GALNT13",
    "gene-GALNT14",
    "gene-GALNT15",
    "gene-GALNT16",
    "gene-GALNT17",
    "gene-GALNT18",
    "gene-GALNT2",
    "gene-GALNT3",
    "gene-GALNT4",
    "gene-GALNT5",
    "gene-GALNT6",
    "gene-GALNT7",
    "gene-GALNT8",
    "gene-GALNT9",
    "gene-GALNTL5",
    "gene-GALNTL6",
    "gene-GALP",
    "gene-GALR1",
    "gene-GALR2",
    "gene-GALR3",
    "gene-GALT",
    "gene-GAMT",
    "gene-GAN",
    "gene-GANAB",
    "gene-GANC",
    "gene-GAP43",
    "gene-GAPDH",
    "gene-GAPDHS",
    "gene-GAPT",
    "gene-GAPVD1",
    "gene-GAR1",
    "gene-GAREM1",
    "gene-GAREM2",
    "gene-GARIN1A",
    "gene-GARIN1B",
    "gene-GARIN2",
    "gene-GARIN3",
    "gene-GARIN4",
    "gene-GARIN5A",
    "gene-GARIN5B",
    "gene-GARIN6",
    "gene-GARNL3",
    "gene-GARRE1",
    "gene-GARS1",
    "gene-GART",
    "gene-GAS1",
    "gene-GAS2",
    "gene-GAS2L1",
    "gene-GAS2L2",
    "gene-GAS2L3",
    "gene-GAS6",
    "gene-GAS7",
    "gene-GAS8",
    "gene-GASK1A",
    "gene-GASK1B",
    "gene-GAST",
    "gene-GATA1",
    "gene-GATA2",
    "gene-GATA3",
    "gene-GATA4",
    "gene-GATA5",
    "gene-GATA6",
    "gene-GATAD1",
    "gene-GATAD2A",
    "gene-GATAD2B",
    "gene-GATB",
    "gene-GATC",
    "gene-GATD1",
    "gene-GATD3",
    "gene-GATM",
    "gene-GBA1",
    "gene-GBA2",
    "gene-GBA3",
    "gene-GBE1",
    "gene-GBF1",
    "gene-GBGT1",
    "gene-GBP1",
    "gene-GBP2",
    "gene-GBP3",
    "gene-GBP4",
    "gene-GBP5",
    "gene-GBP6",
    "gene-GBP7",
    "gene-GBX1",
    "gene-GBX2",
    "gene-GC",
    "gene-GCA",
    "gene-GCAT",
    "gene-GCC1",
    "gene-GCC2",
    "gene-GCDH",
    "gene-GCFC2",
    "gene-GCG",
    "gene-GCGR",
    "gene-GCH1",
    "gene-GCHFR",
    "gene-GCK",
    "gene-GCKR",
    "gene-GCLC",
    "gene-GCLM",
    "gene-GCM1",
    "gene-GCM2",
    "gene-GCN1",
    "gene-GCNA",
    "gene-GCNT1",
    "gene-GCNT2",
    "gene-GCNT3",
    "gene-GCNT4",
    "gene-GCNT7",
    "gene-GCSAM",
    "gene-GCSAML",
    "gene-GCSH",
    "gene-GDA",
    "gene-GDAP1",
    "gene-GDAP1L1",
    "gene-GDAP2",
    "gene-GDE1",
    "gene-GDF1",
    "gene-GDF10",
    "gene-GDF11",
    "gene-GDF15",
    "gene-GDF2",
    "gene-GDF3",
    "gene-GDF5",
    "gene-GDF6",
    "gene-GDF7",
    "gene-GDF9",
    "gene-GDI1",
    "gene-GDI2",
    "gene-GDNF",
    "gene-GDPD1",
    "gene-GDPD2",
    "gene-GDPD3",
    "gene-GDPD4",
    "gene-GDPD5",
    "gene-GDPGP1",
    "gene-GEM",
    "gene-GEMIN2",
    "gene-GEMIN4",
    "gene-GEMIN5",
    "gene-GEMIN6",
    "gene-GEMIN7",
    "gene-GEMIN8",
    "gene-GEN1",
    "gene-GET1",
    "gene-GET3",
    "gene-GET4",
    "gene-GFAP",
    "gene-GFER",
    "gene-GFI1",
    "gene-GFI1B",
    "gene-GFM1",
    "gene-GFM2",
    "gene-GFOD1",
    "gene-GFOD2",
    "gene-GFPT1",
    "gene-GFPT2",
    "gene-GFRA1",
    "gene-GFRA2",
    "gene-GFRA3",
    "gene-GFRA4",
    "gene-GFRAL",
    "gene-GFUS",
    "gene-GFY",
    "gene-GGA1",
    "gene-GGA2",
    "gene-GGA3",
    "gene-GGACT",
    "gene-GGCT",
    "gene-GGCX",
    "gene-GGH",
    "gene-GGN",
    "gene-GGNBP2",
    "gene-GGPS1",
    "gene-GGT1",
    "gene-GGT5",
    "gene-GGT6",
    "gene-GGT7",
    "gene-GGTA1",
    "gene-GGTLC1",
    "gene-GGTLC2",
    "gene-GGTLC3",
    "gene-GH1",
    "gene-GH2",
    "gene-GHDC",
    "gene-GHITM",
    "gene-GHR",
    "gene-GHRH",
    "gene-GHRHR",
    "gene-GHRL",
    "gene-GHSR",
    "gene-GID4",
    "gene-GID8",
    "gene-GIGYF1",
    "gene-GIGYF2",
    "gene-GIMAP1",
    "gene-GIMAP2",
    "gene-GIMAP4",
    "gene-GIMAP5",
    "gene-GIMAP6",
    "gene-GIMAP7",
    "gene-GIMAP8",
    "gene-GIMD1",
    "gene-GIN1",
    "gene-GINM1",
    "gene-GINS1",
    "gene-GINS2",
    "gene-GINS3",
    "gene-GINS4",
    "gene-GIP",
    "gene-GIPC1",
    "gene-GIPC2",
    "gene-GIPC3",
    "gene-GIPR",
    "gene-GIT1",
    "gene-GIT2",
    "gene-GJA1",
    "gene-GJA10",
    "gene-GJA3",
    "gene-GJA4",
    "gene-GJA5",
    "gene-GJA8",
    "gene-GJA9",
    "gene-GJB1",
    "gene-GJB2",
    "gene-GJB3",
    "gene-GJB4",
    "gene-GJB5",
    "gene-GJB6",
    "gene-GJB7",
    "gene-GJC1",
    "gene-GJC2",
    "gene-GJC3",
    "gene-GJD2",
    "gene-GJD3",
    "gene-GJD4",
    "gene-GJE1",
    "gene-GK",
    "gene-GK2",
    "gene-GK3",
    "gene-GK5",
    "gene-GKAP1",
    "gene-GKN1",
    "gene-GKN2",
    "gene-GLA",
    "gene-GLB1",
    "gene-GLB1L",
    "gene-GLB1L2",
    "gene-GLB1L3",
    "gene-GLCCI1",
    "gene-GLCE",
    "gene-GLDC",
    "gene-GLDN",
    "gene-GLE1",
    "gene-GLG1",
    "gene-GLI1",
    "gene-GLI2",
    "gene-GLI3",
    "gene-GLI4",
    "gene-GLIPR1",
    "gene-GLIPR1L1",
    "gene-GLIPR1L2",
    "gene-GLIPR2",
    "gene-GLIS1",
    "gene-GLIS2",
    "gene-GLIS3",
    "gene-GLMN",
    "gene-GLMP",
    "gene-GLO1",
    "gene-GLOD4",
    "gene-GLOD5",
    "gene-GLP1R",
    "gene-GLP2R",
    "gene-GLRA1",
    "gene-GLRA2",
    "gene-GLRA3",
    "gene-GLRB",
    "gene-GLRX",
    "gene-GLRX2",
    "gene-GLRX3",
    "gene-GLRX5",
    "gene-GLS",
    "gene-GLS2",
    "gene-GLT1D1",
    "gene-GLT6D1",
    "gene-GLT8D1",
    "gene-GLT8D2",
    "gene-GLTP",
    "gene-GLTPD2",
    "gene-GLUD1",
    "gene-GLUD2",
    "gene-GLUL",
    "gene-GLYAT",
    "gene-GLYATL1",
    "gene-GLYATL1B",
    "gene-GLYATL2",
    "gene-GLYATL3",
    "gene-GLYCTK",
    "gene-GLYR1",
    "gene-GM2A",
    "gene-GMCL1",
    "gene-GMCL2",
    "gene-GMDS",
    "gene-GMEB1",
    "gene-GMEB2",
    "gene-GMFB",
    "gene-GMFG",
    "gene-GMIP",
    "gene-GML",
    "gene-GMNC",
    "gene-GMNN",
    "gene-GMPPA",
    "gene-GMPPB",
    "gene-GMPR",
    "gene-GMPR2",
    "gene-GMPS",
    "gene-GNA11",
    "gene-GNA12",
    "gene-GNA13",
    "gene-GNA14",
    "gene-GNA15",
    "gene-GNAI1",
    "gene-GNAI2",
    "gene-GNAI3",
    "gene-GNAL",
    "gene-GNAO1",
    "gene-GNAQ",
    "gene-GNAS",
    "gene-GNAT1",
    "gene-GNAT2",
    "gene-GNAT3",
    "gene-GNAZ",
    "gene-GNB1",
    "gene-GNB1L",
    "gene-GNB2",
    "gene-GNB3",
    "gene-GNB4",
    "gene-GNB5",
    "gene-GNE",
    "gene-GNG10",
    "gene-GNG11",
    "gene-GNG12",
    "gene-GNG13",
    "gene-GNG14",
    "gene-GNG2",
    "gene-GNG3",
    "gene-GNG4",
    "gene-GNG5",
    "gene-GNG5B",
    "gene-GNG7",
    "gene-GNG8",
    "gene-GNGT1",
    "gene-GNGT2",
    "gene-GNL1",
    "gene-GNL2",
    "gene-GNL3",
    "gene-GNL3L",
    "gene-GNLY",
    "gene-GNMT",
    "gene-GNPAT",
    "gene-GNPDA1",
    "gene-GNPDA2",
    "gene-GNPNAT1",
    "gene-GNPTAB",
    "gene-GNPTG",
    "gene-GNRH1",
    "gene-GNRH2",
    "gene-GNRHR",
    "gene-GNS",
    "gene-GOLGA1",
    "gene-GOLGA2",
    "gene-GOLGA3",
    "gene-GOLGA4",
    "gene-GOLGA5",
    "gene-GOLGA6A",
    "gene-GOLGA6B",
    "gene-GOLGA6C",
    "gene-GOLGA6D",
    "gene-GOLGA6L1",
    "gene-GOLGA6L10",
    "gene-GOLGA6L2",
    "gene-GOLGA6L22",
    "gene-GOLGA6L24",
    "gene-GOLGA6L25",
    "gene-GOLGA6L26",
    "gene-GOLGA6L4",
    "gene-GOLGA6L6",
    "gene-GOLGA6L7",
    "gene-GOLGA6L9",
    "gene-GOLGA7",
    "gene-GOLGA7B",
    "gene-GOLGA8A",
    "gene-GOLGA8B",
    "gene-GOLGA8F",
    "gene-GOLGA8G",
    "gene-GOLGA8H",
    "gene-GOLGA8J",
    "gene-GOLGA8K",
    "gene-GOLGA8M",
    "gene-GOLGA8N",
    "gene-GOLGA8O",
    "gene-GOLGA8Q",
    "gene-GOLGA8R",
    "gene-GOLGA8S",
    "gene-GOLGA8T",
    "gene-GOLGB1",
    "gene-GOLIM4",
    "gene-GOLM1",
    "gene-GOLM2",
    "gene-GOLPH3",
    "gene-GOLPH3L",
    "gene-GOLT1A",
    "gene-GOLT1B",
    "gene-GON4L",
    "gene-GON7",
    "gene-GOPC",
    "gene-GORAB",
    "gene-GORASP1",
    "gene-GORASP2",
    "gene-GOSR1",
    "gene-GOSR2",
    "gene-GOT1",
    "gene-GOT1L1",
    "gene-GOT2",
    "gene-GP1BA",
    "gene-GP1BB",
    "gene-GP2",
    "gene-GP5",
    "gene-GP6",
    "gene-GP9",
    "gene-GPA33",
    "gene-GPAA1",
    "gene-GPALPP1",
    "gene-GPAM",
    "gene-GPANK1",
    "gene-GPAT2",
    "gene-GPAT3",
    "gene-GPAT4",
    "gene-GPATCH1",
    "gene-GPATCH11",
    "gene-GPATCH2",
    "gene-GPATCH2L",
    "gene-GPATCH3",
    "gene-GPATCH4",
    "gene-GPATCH8",
    "gene-GPBAR1",
    "gene-GPBP1",
    "gene-GPBP1L1",
    "gene-GPC1",
    "gene-GPC2",
    "gene-GPC3",
    "gene-GPC4",
    "gene-GPC5",
    "gene-GPC6",
    "gene-GPCPD1",
    "gene-GPD1",
    "gene-GPD1L",
    "gene-GPD2",
    "gene-GPER1",
    "gene-GPHA2",
    "gene-GPHB5",
    "gene-GPHN",
    "gene-GPI",
    "gene-GPIHBP1",
    "gene-GPKOW",
    "gene-GPLD1",
    "gene-GPM6A",
    "gene-GPM6B",
    "gene-GPN1",
    "gene-GPN2",
    "gene-GPN3",
    "gene-GPNMB",
    "gene-GPR101",
    "gene-GPR107",
    "gene-GPR108",
    "gene-GPR119",
    "gene-GPR12",
    "gene-GPR132",
    "gene-GPR135",
    "gene-GPR137",
    "gene-GPR137B",
    "gene-GPR137C",
    "gene-GPR139",
    "gene-GPR141",
    "gene-GPR142",
    "gene-GPR143",
    "gene-GPR146",
    "gene-GPR148",
    "gene-GPR149",
    "gene-GPR15",
    "gene-GPR150",
    "gene-GPR151",
    "gene-GPR152",
    "gene-GPR153",
    "gene-GPR155",
    "gene-GPR156",
    "gene-GPR157",
    "gene-GPR158",
    "gene-GPR15LG",
    "gene-GPR160",
    "gene-GPR161",
    "gene-GPR162",
    "gene-GPR17",
    "gene-GPR171",
    "gene-GPR173",
    "gene-GPR174",
    "gene-GPR176",
    "gene-GPR179",
    "gene-GPR18",
    "gene-GPR180",
    "gene-GPR182",
    "gene-GPR183",
    "gene-GPR19",
    "gene-GPR20",
    "gene-GPR21",
    "gene-GPR22",
    "gene-GPR25",
    "gene-GPR26",
    "gene-GPR27",
    "gene-GPR3",
    "gene-GPR31",
    "gene-GPR32",
    "gene-GPR33",
    "gene-GPR34",
    "gene-GPR35",
    "gene-GPR37",
    "gene-GPR37L1",
    "gene-GPR39",
    "gene-GPR4",
    "gene-GPR42",
    "gene-GPR45",
    "gene-GPR50",
    "gene-GPR52",
    "gene-GPR55",
    "gene-GPR6",
    "gene-GPR61",
    "gene-GPR62",
    "gene-GPR63",
    "gene-GPR65",
    "gene-GPR68",
    "gene-GPR75",
    "gene-GPR78",
    "gene-GPR82",
    "gene-GPR83",
    "gene-GPR84",
    "gene-GPR85",
    "gene-GPR87",
    "gene-GPR88",
    "gene-GPR89A",
    "gene-GPR89B",
    "gene-GPRASP1",
    "gene-GPRASP2",
    "gene-GPRASP3",
    "gene-GPRC5A",
    "gene-GPRC5B",
    "gene-GPRC5C",
    "gene-GPRC5D",
    "gene-GPRC6A",
    "gene-GPRIN1",
    "gene-GPRIN2",
    "gene-GPRIN3",
    "gene-GPS1",
    "gene-GPS2",
    "gene-GPSM1",
    "gene-GPSM2",
    "gene-GPSM3",
    "gene-GPT",
    "gene-GPT2",
    "gene-GPX1",
    "gene-GPX2",
    "gene-GPX3",
    "gene-GPX4",
    "gene-GPX5",
    "gene-GPX6",
    "gene-GPX7",
    "gene-GPX8",
    "gene-GRAMD1A",
    "gene-GRAMD1B",
    "gene-GRAMD1C",
    "gene-GRAMD2A",
    "gene-GRAMD2B",
    "gene-GRAMD4",
    "gene-GRAP",
    "gene-GRAP2",
    "gene-GRAPL",
    "gene-GRB10",
    "gene-GRB14",
    "gene-GRB2",
    "gene-GRB7",
    "gene-GREB1",
    "gene-GREB1L",
    "gene-GREM1",
    "gene-GREM2",
    "gene-GREP1",
    "gene-GRHL1",
    "gene-GRHL2",
    "gene-GRHL3",
    "gene-GRHPR",
    "gene-GRIA1",
    "gene-GRIA2",
    "gene-GRIA3",
    "gene-GRIA4",
    "gene-GRID1",
    "gene-GRID2",
    "gene-GRID2IP",
    "gene-GRIFIN",
    "gene-GRIK1",
    "gene-GRIK2",
    "gene-GRIK3",
    "gene-GRIK4",
    "gene-GRIK5",
    "gene-GRIN1",
    "gene-GRIN2A",
    "gene-GRIN2B",
    "gene-GRIN2C",
    "gene-GRIN2D",
    "gene-GRIN3A",
    "gene-GRIN3B",
    "gene-GRINA",
    "gene-GRIP1",
    "gene-GRIP2",
    "gene-GRIPAP1",
    "gene-GRK1",
    "gene-GRK2",
    "gene-GRK3",
    "gene-GRK4",
    "gene-GRK5",
    "gene-GRK6",
    "gene-GRK7",
    "gene-GRM1",
    "gene-GRM2",
    "gene-GRM3",
    "gene-GRM4",
    "gene-GRM5",
    "gene-GRM6",
    "gene-GRM7",
    "gene-GRM8",
    "gene-GRN",
    "gene-GRP",
    "gene-GRPEL1",
    "gene-GRPEL2",
    "gene-GRPR",
    "gene-GRSF1",
    "gene-GRTP1",
    "gene-GRWD1",
    "gene-GRXCR1",
    "gene-GRXCR2",
    "gene-GSAP",
    "gene-GSC",
    "gene-GSC2",
    "gene-GSDMA",
    "gene-GSDMB",
    "gene-GSDMC",
    "gene-GSDMD",
    "gene-GSDME",
    "gene-GSE1",
    "gene-GSG1",
    "gene-GSG1L",
    "gene-GSG1L2",
    "gene-GSK3A",
    "gene-GSK3B",
    "gene-GSKIP",
    "gene-GSN",
    "gene-GSPT1",
    "gene-GSPT2",
    "gene-GSR",
    "gene-GSS",
    "gene-GSTA1",
    "gene-GSTA2",
    "gene-GSTA3",
    "gene-GSTA4",
    "gene-GSTA5",
    "gene-GSTCD",
    "gene-GSTK1",
    "gene-GSTM1",
    "gene-GSTM2",
    "gene-GSTM3",
    "gene-GSTM4",
    "gene-GSTM5",
    "gene-GSTO1",
    "gene-GSTO2",
    "gene-GSTP1",
    "gene-GSTT2",
    "gene-GSTT2B",
    "gene-GSTT4",
    "gene-GSTZ1",
    "gene-GSX1",
    "gene-GSX2",
    "gene-GTDC1",
    "gene-GTF2A1",
    "gene-GTF2A1L",
    "gene-GTF2A2",
    "gene-GTF2B",
    "gene-GTF2E1",
    "gene-GTF2E2",
    "gene-GTF2F1",
    "gene-GTF2F2",
    "gene-GTF2H1",
    "gene-GTF2H2",
    "gene-GTF2H2C",
    "gene-GTF2H3",
    "gene-GTF2H4",
    "gene-GTF2H5",
    "gene-GTF2I",
    "gene-GTF2IRD1",
    "gene-GTF2IRD2",
    "gene-GTF2IRD2B",
    "gene-GTF3A",
    "gene-GTF3C1",
    "gene-GTF3C2",
    "gene-GTF3C3",
    "gene-GTF3C4",
    "gene-GTF3C5",
    "gene-GTF3C6",
    "gene-GTPBP1",
    "gene-GTPBP10",
    "gene-GTPBP2",
    "gene-GTPBP3",
    "gene-GTPBP4",
    "gene-GTPBP6",
    "gene-GTPBP8",
    "gene-GTSE1",
    "gene-GTSF1",
    "gene-GTSF1L",
    "gene-GUCA1A",
    "gene-GUCA1B",
    "gene-GUCA1C",
    "gene-GUCA2A",
    "gene-GUCA2B",
    "gene-GUCD1",
    "gene-GUCY1A1",
    "gene-GUCY1A2",
    "gene-GUCY1B1",
    "gene-GUCY2C",
    "gene-GUCY2D",
    "gene-GUCY2F",
    "gene-GUF1",
    "gene-GUK1",
    "gene-GULP1",
    "gene-GUSB",
    "gene-GVQW3",
    "gene-GXYLT1",
    "gene-GXYLT2",
    "gene-GYG1",
    "gene-GYG2",
    "gene-GYPA",
    "gene-GYPB",
    "gene-GYPC",
    "gene-GYPE",
    "gene-GYS1",
    "gene-GYS2",
    "gene-GZF1",
    "gene-GZMA",
    "gene-GZMB",
    "gene-GZMH",
    "gene-GZMK",
    "gene-GZMM",
    "gene-H1-0",
    "gene-H1-1",
    "gene-H1-10",
    "gene-H1-2",
    "gene-H1-3",
    "gene-H1-4",
    "gene-H1-5",
    "gene-H1-6",
    "gene-H1-7",
    "gene-H1-8",
    "gene-H2AB1",
    "gene-H2AB2",
    "gene-H2AB3",
    "gene-H2AC1",
    "gene-H2AC11",
    "gene-H2AC12",
    "gene-H2AC13",
    "gene-H2AC14",
    "gene-H2AC15",
    "gene-H2AC16",
    "gene-H2AC17",
    "gene-H2AC18",
    "gene-H2AC19",
    "gene-H2AC20",
    "gene-H2AC21",
    "gene-H2AC25",
    "gene-H2AC4",
    "gene-H2AC6",
    "gene-H2AC7",
    "gene-H2AC8",
    "gene-H2AJ",
    "gene-H2AL3",
    "gene-H2AP",
    "gene-H2AX",
    "gene-H2AZ1",
    "gene-H2AZ2",
    "gene-H2BC1",
    "gene-H2BC10",
    "gene-H2BC11",
    "gene-H2BC12",
    "gene-H2BC12L",
    "gene-H2BC13",
    "gene-H2BC14",
    "gene-H2BC15",
    "gene-H2BC17",
    "gene-H2BC18",
    "gene-H2BC21",
    "gene-H2BC26",
    "gene-H2BC3",
    "gene-H2BC4",
    "gene-H2BC5",
    "gene-H2BC6",
    "gene-H2BC7",
    "gene-H2BC8",
    "gene-H2BC9",
    "gene-H2BK1",
    "gene-H2BN1",
    "gene-H2BW1",
    "gene-H2BW2",
    "gene-H3-3A",
    "gene-H3-3B",
    "gene-H3-4",
    "gene-H3-5",
    "gene-H3-7",
    "gene-H3C1",
    "gene-H3C10",
    "gene-H3C11",
    "gene-H3C12",
    "gene-H3C13",
    "gene-H3C14",
    "gene-H3C15",
    "gene-H3C2",
    "gene-H3C3",
    "gene-H3C4",
    "gene-H3C6",
    "gene-H3C7",
    "gene-H3C8",
    "gene-H3Y1",
    "gene-H3Y2",
    "gene-H4C1",
    "gene-H4C11",
    "gene-H4C12",
    "gene-H4C13",
    "gene-H4C14",
    "gene-H4C15",
    "gene-H4C16",
    "gene-H4C2",
    "gene-H4C3",
    "gene-H4C4",
    "gene-H4C5",
    "gene-H4C6",
    "gene-H4C7",
    "gene-H4C8",
    "gene-H4C9",
    "gene-H6PD",
    "gene-HAAO",
    "gene-HABP2",
    "gene-HABP4",
    "gene-HACD1",
    "gene-HACD2",
    "gene-HACD3",
    "gene-HACD4",
    "gene-HACE1",
    "gene-HACL1",
    "gene-HADH",
    "gene-HADHA",
    "gene-HADHB",
    "gene-HAGH",
    "gene-HAGHL",
    "gene-HAL",
    "gene-HAMP",
    "gene-HAND1",
    "gene-HAND2",
    "gene-HAO1",
    "gene-HAO2",
    "gene-HAP1",
    "gene-HAPLN1",
    "gene-HAPLN2",
    "gene-HAPLN3",
    "gene-HAPLN4",
    "gene-HAPSTR1",
    "gene-HAPSTR2",
    "gene-HARBI1",
    "gene-HARS1",
    "gene-HARS2",
    "gene-HAS1",
    "gene-HAS2",
    "gene-HAS3",
    "gene-HASPIN",
    "gene-HAT1",
    "gene-HAUS1",
    "gene-HAUS2",
    "gene-HAUS3",
    "gene-HAUS4",
    "gene-HAUS5",
    "gene-HAUS6",
    "gene-HAUS7",
    "gene-HAUS8",
    "gene-HAVCR1",
    "gene-HAVCR2",
    "gene-HAX1",
    "gene-HBA1",
    "gene-HBA2",
    "gene-HBB",
    "gene-HBD",
    "gene-HBE1",
    "gene-HBEGF",
    "gene-HBG1",
    "gene-HBG2",
    "gene-HBM",
    "gene-HBP1",
    "gene-HBQ1",
    "gene-HBS1L",
    "gene-HBZ",
    "gene-HCAR1",
    "gene-HCAR2",
    "gene-HCAR3",
    "gene-HCCS",
    "gene-HCFC1",
    "gene-HCFC1R1",
    "gene-HCFC2",
    "gene-HCG22",
    "gene-HCK",
    "gene-HCLS1",
    "gene-HCN1",
    "gene-HCN2",
    "gene-HCN3",
    "gene-HCN4",
    "gene-HCRT",
    "gene-HCRTR1",
    "gene-HCRTR2",
    "gene-HCST",
    "gene-HDAC1",
    "gene-HDAC10",
    "gene-HDAC11",
    "gene-HDAC2",
    "gene-HDAC3",
    "gene-HDAC4",
    "gene-HDAC5",
    "gene-HDAC6",
    "gene-HDAC7",
    "gene-HDAC8",
    "gene-HDAC9",
    "gene-HDC",
    "gene-HDDC2",
    "gene-HDDC3",
    "gene-HDGF",
    "gene-HDGFL1",
    "gene-HDGFL2",
    "gene-HDGFL3",
    "gene-HDHD2",
    "gene-HDHD3",
    "gene-HDHD5",
    "gene-HDLBP",
    "gene-HDX",
    "gene-HEATR1",
    "gene-HEATR3",
    "gene-HEATR4",
    "gene-HEATR5A",
    "gene-HEATR5B",
    "gene-HEATR6",
    "gene-HEATR9",
    "gene-HEBP1",
    "gene-HEBP2",
    "gene-HECA",
    "gene-HECTD1",
    "gene-HECTD2",
    "gene-HECTD3",
    "gene-HECTD4",
    "gene-HECW1",
    "gene-HECW2",
    "gene-HEG1",
    "gene-HELB",
    "gene-HELLS",
    "gene-HELQ",
    "gene-HELT",
    "gene-HELZ",
    "gene-HELZ2",
    "gene-HEMGN",
    "gene-HEMK1",
    "gene-HENMT1",
    "gene-HEPACAM",
    "gene-HEPACAM2",
    "gene-HEPH",
    "gene-HEPHL1",
    "gene-HEPN1",
    "gene-HERC1",
    "gene-HERC2",
    "gene-HERC3",
    "gene-HERC4",
    "gene-HERC5",
    "gene-HERC6",
    "gene-HERPUD1",
    "gene-HERPUD2",
    "gene-HES1",
    "gene-HES2",
    "gene-HES3",
    "gene-HES4",
    "gene-HES5",
    "gene-HES6",
    "gene-HES7",
    "gene-HESX1",
    "gene-HEXA",
    "gene-HEXB",
    "gene-HEXD",
    "gene-HEXIM1",
    "gene-HEXIM2",
    "gene-HEY1",
    "gene-HEY2",
    "gene-HEYL",
    "gene-HFE",
    "gene-HFM1",
    "gene-HGD",
    "gene-HGF",
    "gene-HGFAC",
    "gene-HGH1",
    "gene-HGS",
    "gene-HGSNAT",
    "gene-HHAT",
    "gene-HHATL",
    "gene-HHEX",
    "gene-HHIP",
    "gene-HHIPL1",
    "gene-HHIPL2",
    "gene-HHLA1",
    "gene-HHLA2",
    "gene-HIBADH",
    "gene-HIBCH",
    "gene-HIC1",
    "gene-HIC2",
    "gene-HID1",
    "gene-HIF1A",
    "gene-HIF1AN",
    "gene-HIF3A",
    "gene-HIGD1A",
    "gene-HIGD1B",
    "gene-HIGD1C",
    "gene-HIGD2A",
    "gene-HIGD2B",
    "gene-HIKESHI",
    "gene-HILPDA",
    "gene-HINFP",
    "gene-HINT1",
    "gene-HINT2",
    "gene-HINT3",
    "gene-HIP1",
    "gene-HIP1R",
    "gene-HIPK1",
    "gene-HIPK2",
    "gene-HIPK3",
    "gene-HIPK4",
    "gene-HIRA",
    "gene-HIRIP3",
    "gene-HIVEP1",
    "gene-HIVEP2",
    "gene-HIVEP3",
    "gene-HJURP",
    "gene-HJV",
    "gene-HK1",
    "gene-HK2",
    "gene-HK3",
    "gene-HKDC1",
    "gene-HLA-A",
    "gene-HLA-B",
    "gene-HLA-C",
    "gene-HLA-DMA",
    "gene-HLA-DMB",
    "gene-HLA-DOA",
    "gene-HLA-DOB",
    "gene-HLA-DPA1",
    "gene-HLA-DPB1",
    "gene-HLA-DQA1",
    "gene-HLA-DQA2",
    "gene-HLA-DQB1",
    "gene-HLA-DQB2",
    "gene-HLA-DRA",
    "gene-HLA-DRB1",
    "gene-HLA-DRB5",
    "gene-HLA-E",
    "gene-HLA-F",
    "gene-HLA-G",
    "gene-HLCS",
    "gene-HLF",
    "gene-HLTF",
    "gene-HLX",
    "gene-HM13",
    "gene-HMBOX1",
    "gene-HMBS",
    "gene-HMCES",
    "gene-HMCN1",
    "gene-HMCN2",
    "gene-HMG20A",
    "gene-HMG20B",
    "gene-HMGA1",
    "gene-HMGA2",
    "gene-HMGB1",
    "gene-HMGB2",
    "gene-HMGB3",
    "gene-HMGB4",
    "gene-HMGCL",
    "gene-HMGCLL1",
    "gene-HMGCR",
    "gene-HMGCS1",
    "gene-HMGCS2",
    "gene-HMGN1",
    "gene-HMGN2",
    "gene-HMGN3",
    "gene-HMGN4",
    "gene-HMGN5",
    "gene-HMGXB3",
    "gene-HMGXB4",
    "gene-HMHB1",
    "gene-HMMR",
    "gene-HMOX1",
    "gene-HMOX2",
    "gene-HMSD",
    "gene-HMX1",
    "gene-HMX2",
    "gene-HMX3",
    "gene-HNF1A",
    "gene-HNF1B",
    "gene-HNF4A",
    "gene-HNF4G",
    "gene-HNMT",
    "gene-HNRNPA0",
    "gene-HNRNPA1",
    "gene-HNRNPA1L2",
    "gene-HNRNPA1L3",
    "gene-HNRNPA2B1",
    "gene-HNRNPA3",
    "gene-HNRNPAB",
    "gene-HNRNPC",
    "gene-HNRNPCL1",
    "gene-HNRNPCL2",
    "gene-HNRNPCL3",
    "gene-HNRNPCL4",
    "gene-HNRNPD",
    "gene-HNRNPDL",
    "gene-HNRNPF",
    "gene-HNRNPH1",
    "gene-HNRNPH2",
    "gene-HNRNPH3",
    "gene-HNRNPK",
    "gene-HNRNPL",
    "gene-HNRNPLL",
    "gene-HNRNPM",
    "gene-HNRNPR",
    "gene-HNRNPU",
    "gene-HNRNPUL1",
    "gene-HNRNPUL2",
    "gene-HOATZ",
    "gene-HOGA1",
    "gene-HOMER1",
    "gene-HOMER2",
    "gene-HOMER3",
    "gene-HOMEZ",
    "gene-HOOK1",
    "gene-HOOK2",
    "gene-HOOK3",
    "gene-HOPX",
    "gene-HORMAD1",
    "gene-HORMAD2",
    "gene-HOXA1",
    "gene-HOXA10",
    "gene-HOXA11",
    "gene-HOXA13",
    "gene-HOXA2",
    "gene-HOXA3",
    "gene-HOXA4",
    "gene-HOXA5",
    "gene-HOXA6",
    "gene-HOXA7",
    "gene-HOXA9",
    "gene-HOXB1",
    "gene-HOXB13",
    "gene-HOXB2",
    "gene-HOXB3",
    "gene-HOXB4",
    "gene-HOXB5",
    "gene-HOXB6",
    "gene-HOXB7",
    "gene-HOXB8",
    "gene-HOXB9",
    "gene-HOXC10",
    "gene-HOXC11",
    "gene-HOXC12",
    "gene-HOXC13",
    "gene-HOXC4",
    "gene-HOXC5",
    "gene-HOXC6",
    "gene-HOXC8",
    "gene-HOXC9",
    "gene-HOXD1",
    "gene-HOXD10",
    "gene-HOXD11",
    "gene-HOXD12",
    "gene-HOXD13",
    "gene-HOXD3",
    "gene-HOXD4",
    "gene-HOXD8",
    "gene-HOXD9",
    "gene-HP",
    "gene-HP1BP3",
    "gene-HPCA",
    "gene-HPCAL1",
    "gene-HPCAL4",
    "gene-HPD",
    "gene-HPDL",
    "gene-HPF1",
    "gene-HPGD",
    "gene-HPGDS",
    "gene-HPN",
    "gene-HPR",
    "gene-HPRT1",
    "gene-HPS1",
    "gene-HPS3",
    "gene-HPS4",
    "gene-HPS5",
    "gene-HPS6",
    "gene-HPSE",
    "gene-HPSE2",
    "gene-HPX",
    "gene-HR",
    "gene-HRAS",
    "gene-HRC",
    "gene-HRCT1",
    "gene-HRG",
    "gene-HRH1",
    "gene-HRH2",
    "gene-HRH3",
    "gene-HRH4",
    "gene-HRK",
    "gene-HRNR",
    "gene-HROB",
    "gene-HRURF",
    "gene-HS1BP3",
    "gene-HS2ST1",
    "gene-HS3ST1",
    "gene-HS3ST2",
    "gene-HS3ST3A1",
    "gene-HS3ST3B1",
    "gene-HS3ST4",
    "gene-HS3ST5",
    "gene-HS3ST6",
    "gene-HS6ST1",
    "gene-HS6ST2",
    "gene-HS6ST3",
    "gene-HSBP1",
    "gene-HSBP1L1",
    "gene-HSCB",
    "gene-HSD11B1",
    "gene-HSD11B1L",
    "gene-HSD11B2",
    "gene-HSD17B1",
    "gene-HSD17B10",
    "gene-HSD17B11",
    "gene-HSD17B12",
    "gene-HSD17B13",
    "gene-HSD17B14",
    "gene-HSD17B2",
    "gene-HSD17B3",
    "gene-HSD17B4",
    "gene-HSD17B6",
    "gene-HSD17B7",
    "gene-HSD17B8",
    "gene-HSD3B1",
    "gene-HSD3B2",
    "gene-HSD3B7",
    "gene-HSDL1",
    "gene-HSDL2",
    "gene-HSF1",
    "gene-HSF2",
    "gene-HSF2BP",
    "gene-HSF4",
    "gene-HSF5",
    "gene-HSFX1",
    "gene-HSFX2",
    "gene-HSFX3",
    "gene-HSFX4",
    "gene-HSFY1",
    "gene-HSFY2",
    "gene-HSH2D",
    "gene-HSP90AA1",
    "gene-HSP90AB1",
    "gene-HSP90B1",
    "gene-HSPA12A",
    "gene-HSPA12B",
    "gene-HSPA13",
    "gene-HSPA14",
    "gene-HSPA1A",
    "gene-HSPA1B",
    "gene-HSPA1L",
    "gene-HSPA2",
    "gene-HSPA4",
    "gene-HSPA4L",
    "gene-HSPA5",
    "gene-HSPA6",
    "gene-HSPA8",
    "gene-HSPA9",
    "gene-HSPB1",
    "gene-HSPB2",
    "gene-HSPB3",
    "gene-HSPB6",
    "gene-HSPB7",
    "gene-HSPB8",
    "gene-HSPB9",
    "gene-HSPBAP1",
    "gene-HSPBP1",
    "gene-HSPD1",
    "gene-HSPE1",
    "gene-HSPG2",
    "gene-HSPH1",
    "gene-HTATIP2",
    "gene-HTATSF1",
    "gene-HTD2",
    "gene-HTN1",
    "gene-HTN3",
    "gene-HTR1A",
    "gene-HTR1B",
    "gene-HTR1D",
    "gene-HTR1E",
    "gene-HTR1F",
    "gene-HTR2A",
    "gene-HTR2B",
    "gene-HTR2C",
    "gene-HTR3A",
    "gene-HTR3B",
    "gene-HTR3C",
    "gene-HTR3D",
    "gene-HTR3E",
    "gene-HTR4",
    "gene-HTR5A",
    "gene-HTR6",
    "gene-HTR7",
    "gene-HTRA1",
    "gene-HTRA2",
    "gene-HTRA3",
    "gene-HTRA4",
    "gene-HTT",
    "gene-HUNK",
    "gene-HUS1",
    "gene-HUS1B",
    "gene-HUWE1",
    "gene-HVCN1",
    "gene-HYAL1",
    "gene-HYAL2",
    "gene-HYAL3",
    "gene-HYAL4",
    "gene-HYCC1",
    "gene-HYCC2",
    "gene-HYDIN",
    "gene-HYI",
    "gene-HYKK",
    "gene-HYLS1",
    "gene-HYOU1",
    "gene-HYPK",
    "gene-IAH1",
    "gene-IAPP",
    "gene-IARS1",
    "gene-IARS2",
    "gene-IBA57",
    "gene-IBSP",
    "gene-IBTK",
    "gene-ICA1",
    "gene-ICA1L",
    "gene-ICAM1",
    "gene-ICAM2",
    "gene-ICAM3",
    "gene-ICAM4",
    "gene-ICAM5",
    "gene-ICE1",
    "gene-ICE2",
    "gene-ICMT",
    "gene-ICOS",
    "gene-ICOSLG",
    "gene-ID1",
    "gene-ID2",
    "gene-ID3",
    "gene-ID4",
    "gene-IDE",
    "gene-IDH1",
    "gene-IDH2",
    "gene-IDH3A",
    "gene-IDH3B",
    "gene-IDH3G",
    "gene-IDI1",
    "gene-IDI2",
    "gene-IDNK",
    "gene-IDO1",
    "gene-IDO2",
    "gene-IDS",
    "gene-IDUA",
    "gene-IER2",
    "gene-IER3",
    "gene-IER3IP1",
    "gene-IER5",
    "gene-IER5L",
    "gene-IFFO1",
    "gene-IFFO2",
    "gene-IFI16",
    "gene-IFI27",
    "gene-IFI27L1",
    "gene-IFI27L2",
    "gene-IFI30",
    "gene-IFI35",
    "gene-IFI44",
    "gene-IFI44L",
    "gene-IFI6",
    "gene-IFIH1",
    "gene-IFIT1",
    "gene-IFIT1B",
    "gene-IFIT2",
    "gene-IFIT3",
    "gene-IFIT5",
    "gene-IFITM1",
    "gene-IFITM10",
    "gene-IFITM2",
    "gene-IFITM3",
    "gene-IFITM5",
    "gene-IFNA1",
    "gene-IFNA10",
    "gene-IFNA13",
    "gene-IFNA14",
    "gene-IFNA16",
    "gene-IFNA17",
    "gene-IFNA2",
    "gene-IFNA21",
    "gene-IFNA4",
    "gene-IFNA5",
    "gene-IFNA6",
    "gene-IFNA7",
    "gene-IFNA8",
    "gene-IFNAR1",
    "gene-IFNAR2",
    "gene-IFNB1",
    "gene-IFNE",
    "gene-IFNG",
    "gene-IFNGR1",
    "gene-IFNGR2",
    "gene-IFNK",
    "gene-IFNL1",
    "gene-IFNL2",
    "gene-IFNL3",
    "gene-IFNL4",
    "gene-IFNLR1",
    "gene-IFNW1",
    "gene-IFRD1",
    "gene-IFRD2",
    "gene-IFT122",
    "gene-IFT140",
    "gene-IFT172",
    "gene-IFT20",
    "gene-IFT22",
    "gene-IFT25",
    "gene-IFT27",
    "gene-IFT43",
    "gene-IFT46",
    "gene-IFT52",
    "gene-IFT56",
    "gene-IFT57",
    "gene-IFT70A",
    "gene-IFT70B",
    "gene-IFT74",
    "gene-IFT80",
    "gene-IFT81",
    "gene-IFT88",
    "gene-IFTAP",
    "gene-IGBP1",
    "gene-IGBP1C",
    "gene-IGDCC3",
    "gene-IGDCC4",
    "gene-IGF1",
    "gene-IGF1R",
    "gene-IGF2",
    "gene-IGF2BP1",
    "gene-IGF2BP2",
    "gene-IGF2BP3",
    "gene-IGF2R",
    "gene-IGFALS",
    "gene-IGFBP1",
    "gene-IGFBP2",
    "gene-IGFBP3",
    "gene-IGFBP4",
    "gene-IGFBP5",
    "gene-IGFBP6",
    "gene-IGFBP7",
    "gene-IGFBPL1",
    "gene-IGFL1",
    "gene-IGFL2",
    "gene-IGFL3",
    "gene-IGFL4",
    "gene-IGFLR1",
    "gene-IGFN1",
    "gene-IGH",
    "gene-IGHMBP2",
    "gene-IGIP",
    "gene-IGK",
    "gene-IGL",
    "gene-IGLL1",
    "gene-IGLL5",
    "gene-IGLON5",
    "gene-IGSF1",
    "gene-IGSF10",
    "gene-IGSF11",
    "gene-IGSF21",
    "gene-IGSF22",
    "gene-IGSF23",
    "gene-IGSF3",
    "gene-IGSF5",
    "gene-IGSF6",
    "gene-IGSF8",
    "gene-IGSF9",
    "gene-IGSF9B",
    "gene-IHH",
    "gene-IHO1",
    "gene-IK",
    "gene-IKBIP",
    "gene-IKBKB",
    "gene-IKBKE",
    "gene-IKBKG",
    "gene-IKZF1",
    "gene-IKZF2",
    "gene-IKZF3",
    "gene-IKZF4",
    "gene-IKZF5",
    "gene-IL10",
    "gene-IL10RA",
    "gene-IL10RB",
    "gene-IL11",
    "gene-IL11RA",
    "gene-IL12A",
    "gene-IL12B",
    "gene-IL12RB1",
    "gene-IL12RB2",
    "gene-IL13",
    "gene-IL13RA1",
    "gene-IL13RA2",
    "gene-IL15",
    "gene-IL15RA",
    "gene-IL16",
    "gene-IL17A",
    "gene-IL17B",
    "gene-IL17C",
    "gene-IL17D",
    "gene-IL17F",
    "gene-IL17RA",
    "gene-IL17RB",
    "gene-IL17RC",
    "gene-IL17RD",
    "gene-IL17RE",
    "gene-IL17REL",
    "gene-IL18",
    "gene-IL18BP",
    "gene-IL18R1",
    "gene-IL18RAP",
    "gene-IL19",
    "gene-IL1A",
    "gene-IL1B",
    "gene-IL1F10",
    "gene-IL1R1",
    "gene-IL1R2",
    "gene-IL1RAP",
    "gene-IL1RAPL1",
    "gene-IL1RAPL2",
    "gene-IL1RL1",
    "gene-IL1RL2",
    "gene-IL1RN",
    "gene-IL2",
    "gene-IL20",
    "gene-IL20RA",
    "gene-IL20RB",
    "gene-IL21",
    "gene-IL21R",
    "gene-IL22",
    "gene-IL22RA1",
    "gene-IL22RA2",
    "gene-IL23A",
    "gene-IL23R",
    "gene-IL24",
    "gene-IL25",
    "gene-IL26",
    "gene-IL27",
    "gene-IL27RA",
    "gene-IL2RA",
    "gene-IL2RB",
    "gene-IL2RG",
    "gene-IL3",
    "gene-IL31",
    "gene-IL31RA",
    "gene-IL32",
    "gene-IL33",
    "gene-IL34",
    "gene-IL36A",
    "gene-IL36B",
    "gene-IL36G",
    "gene-IL36RN",
    "gene-IL37",
    "gene-IL3RA",
    "gene-IL4",
    "gene-IL4I1",
    "gene-IL4R",
    "gene-IL5",
    "gene-IL5RA",
    "gene-IL6",
    "gene-IL6R",
    "gene-IL6ST",
    "gene-IL7",
    "gene-IL7R",
    "gene-IL9",
    "gene-IL9R",
    "gene-ILDR1",
    "gene-ILDR2",
    "gene-ILF2",
    "gene-ILF3",
    "gene-ILK",
    "gene-ILKAP",
    "gene-ILRUN",
    "gene-ILVBL",
    "gene-IMMP1L",
    "gene-IMMP2L",
    "gene-IMMT",
    "gene-IMP3",
    "gene-IMP4",
    "gene-IMPA1",
    "gene-IMPA2",
    "gene-IMPACT",
    "gene-IMPDH1",
    "gene-IMPDH2",
    "gene-IMPG1",
    "gene-IMPG2",
    "gene-INA",
    "gene-INAFM1",
    "gene-INAFM2",
    "gene-INAVA",
    "gene-INCA1",
    "gene-INCENP",
    "gene-INF2",
    "gene-ING1",
    "gene-ING2",
    "gene-ING3",
    "gene-ING4",
    "gene-ING5",
    "gene-INHA",
    "gene-INHBA",
    "gene-INHBB",
    "gene-INHBC",
    "gene-INHBE",
    "gene-INIP",
    "gene-INKA1",
    "gene-INKA2",
    "gene-INMT",
    "gene-INO80",
    "gene-INO80B",
    "gene-INO80C",
    "gene-INO80D",
    "gene-INO80E",
    "gene-INPP1",
    "gene-INPP4A",
    "gene-INPP4B",
    "gene-INPP5A",
    "gene-INPP5B",
    "gene-INPP5D",
    "gene-INPP5E",
    "gene-INPP5F",
    "gene-INPP5J",
    "gene-INPP5K",
    "gene-INPPL1",
    "gene-INS",
    "gene-INSC",
    "gene-INSIG1",
    "gene-INSIG2",
    "gene-INSL3",
    "gene-INSL4",
    "gene-INSL5",
    "gene-INSL6",
    "gene-INSM1",
    "gene-INSM2",
    "gene-INSR",
    "gene-INSRR",
    "gene-INSYN1",
    "gene-INSYN2A",
    "gene-INSYN2B",
    "gene-INTS1",
    "gene-INTS10",
    "gene-INTS11",
    "gene-INTS12",
    "gene-INTS13",
    "gene-INTS14",
    "gene-INTS15",
    "gene-INTS2",
    "gene-INTS3",
    "gene-INTS4",
    "gene-INTS5",
    "gene-INTS6",
    "gene-INTS6L",
    "gene-INTS7",
    "gene-INTS8",
    "gene-INTS9",
    "gene-INTU",
    "gene-INVS",
    "gene-IP6K1",
    "gene-IP6K2",
    "gene-IP6K3",
    "gene-IPCEF1",
    "gene-IPMK",
    "gene-IPO11",
    "gene-IPO13",
    "gene-IPO4",
    "gene-IPO5",
    "gene-IPO7",
    "gene-IPO8",
    "gene-IPO9",
    "gene-IPP",
    "gene-IPPK",
    "gene-IQANK1",
    "gene-IQCA1",
    "gene-IQCA1L",
    "gene-IQCB1",
    "gene-IQCC",
    "gene-IQCD",
    "gene-IQCE",
    "gene-IQCF1",
    "gene-IQCF2",
    "gene-IQCF3",
    "gene-IQCF5",
    "gene-IQCF6",
    "gene-IQCG",
    "gene-IQCH",
    "gene-IQCJ",
    "gene-IQCK",
    "gene-IQCM",
    "gene-IQCN",
    "gene-IQGAP1",
    "gene-IQGAP2",
    "gene-IQGAP3",
    "gene-IQSEC1",
    "gene-IQSEC2",
    "gene-IQSEC3",
    "gene-IQUB",
    "gene-IRAG1",
    "gene-IRAG2",
    "gene-IRAK1",
    "gene-IRAK1BP1",
    "gene-IRAK2",
    "gene-IRAK3",
    "gene-IRAK4",
    "gene-IREB2",
    "gene-IRF1",
    "gene-IRF2",
    "gene-IRF2BP1",
    "gene-IRF2BP2",
    "gene-IRF2BPL",
    "gene-IRF3",
    "gene-IRF4",
    "gene-IRF5",
    "gene-IRF6",
    "gene-IRF7",
    "gene-IRF8",
    "gene-IRF9",
    "gene-IRGC",
    "gene-IRGM",
    "gene-IRGQ",
    "gene-IRS1",
    "gene-IRS2",
    "gene-IRS4",
    "gene-IRX1",
    "gene-IRX2",
    "gene-IRX3",
    "gene-IRX4",
    "gene-IRX5",
    "gene-IRX6",
    "gene-ISCA1",
    "gene-ISCA2",
    "gene-ISCU",
    "gene-ISG15",
    "gene-ISG20",
    "gene-ISG20L2",
    "gene-ISL1",
    "gene-ISL2",
    "gene-ISLR",
    "gene-ISLR2",
    "gene-ISM1",
    "gene-ISM2",
    "gene-ISOC1",
    "gene-ISOC2",
    "gene-IST1",
    "gene-ISX",
    "gene-ISY1",
    "gene-ISYNA1",
    "gene-ITCH",
    "gene-ITFG1",
    "gene-ITFG2",
    "gene-ITGA1",
    "gene-ITGA10",
    "gene-ITGA11",
    "gene-ITGA2",
    "gene-ITGA2B",
    "gene-ITGA3",
    "gene-ITGA4",
    "gene-ITGA5",
    "gene-ITGA6",
    "gene-ITGA7",
    "gene-ITGA8",
    "gene-ITGA9",
    "gene-ITGAD",
    "gene-ITGAE",
    "gene-ITGAL",
    "gene-ITGAM",
    "gene-ITGAV",
    "gene-ITGAX",
    "gene-ITGB1",
    "gene-ITGB1BP1",
    "gene-ITGB1BP2",
    "gene-ITGB2",
    "gene-ITGB3",
    "gene-ITGB3BP",
    "gene-ITGB4",
    "gene-ITGB5",
    "gene-ITGB6",
    "gene-ITGB7",
    "gene-ITGB8",
    "gene-ITGBL1",
    "gene-ITIH1",
    "gene-ITIH2",
    "gene-ITIH3",
    "gene-ITIH4",
    "gene-ITIH5",
    "gene-ITIH6",
    "gene-ITK",
    "gene-ITLN1",
    "gene-ITLN2",
    "gene-ITM2A",
    "gene-ITM2B",
    "gene-ITM2C",
    "gene-ITPA",
    "gene-ITPK1",
    "gene-ITPKA",
    "gene-ITPKB",
    "gene-ITPKC",
    "gene-ITPR1",
    "gene-ITPR2",
    "gene-ITPR3",
    "gene-ITPRID1",
    "gene-ITPRID2",
    "gene-ITPRIP",
    "gene-ITPRIPL1",
    "gene-ITPRIPL2",
    "gene-ITSN1",
    "gene-ITSN2",
    "gene-IVD",
    "gene-IVL",
    "gene-IVNS1ABP",
    "gene-IWS1",
    "gene-IYD",
    "gene-IZUMO1",
    "gene-IZUMO1R",
    "gene-IZUMO2",
    "gene-IZUMO3",
    "gene-IZUMO4",
    "gene-JADE1",
    "gene-JADE2",
    "gene-JADE3",
    "gene-JAG1",
    "gene-JAG2",
    "gene-JAGN1",
    "gene-JAK1",
    "gene-JAK2",
    "gene-JAK3",
    "gene-JAKMIP1",
    "gene-JAKMIP2",
    "gene-JAKMIP3",
    "gene-JAM2",
    "gene-JAM3",
    "gene-JAML",
    "gene-JARID2",
    "gene-JAZF1",
    "gene-JCAD",
    "gene-JCHAIN",
    "gene-JDP2",
    "gene-JHY",
    "gene-JKAMP",
    "gene-JMJD1C",
    "gene-JMJD4",
    "gene-JMJD6",
    "gene-JMJD7",
    "gene-JMJD8",
    "gene-JMY",
    "gene-JOSD1",
    "gene-JOSD2",
    "gene-JPH1",
    "gene-JPH2",
    "gene-JPH3",
    "gene-JPH4",
    "gene-JPT1",
    "gene-JPT2",
    "gene-JRK",
    "gene-JRKL",
    "gene-JSRP1",
    "gene-JTB",
    "gene-JUN",
    "gene-JUNB",
    "gene-JUND",
    "gene-JUP",
    "gene-KALRN",
    "gene-KANK1",
    "gene-KANK2",
    "gene-KANK3",
    "gene-KANK4",
    "gene-KANSL1",
    "gene-KANSL1L",
    "gene-KANSL2",
    "gene-KANSL3",
    "gene-KANTR",
    "gene-KARS1",
    "gene-KASH5",
    "gene-KAT14",
    "gene-KAT2A",
    "gene-KAT2B",
    "gene-KAT5",
    "gene-KAT6A",
    "gene-KAT6B",
    "gene-KAT7",
    "gene-KAT8",
    "gene-KATNA1",
    "gene-KATNAL1",
    "gene-KATNAL2",
    "gene-KATNB1",
    "gene-KATNBL1",
    "gene-KATNIP",
    "gene-KAZALD1",
    "gene-KAZN",
    "gene-KBTBD11",
    "gene-KBTBD12",
    "gene-KBTBD13",
    "gene-KBTBD2",
    "gene-KBTBD3",
    "gene-KBTBD4",
    "gene-KBTBD6",
    "gene-KBTBD7",
    "gene-KBTBD8",
    "gene-KCMF1",
    "gene-KCNA1",
    "gene-KCNA10",
    "gene-KCNA2",
    "gene-KCNA3",
    "gene-KCNA4",
    "gene-KCNA5",
    "gene-KCNA6",
    "gene-KCNA7",
    "gene-KCNAB1",
    "gene-KCNAB2",
    "gene-KCNAB3",
    "gene-KCNB1",
    "gene-KCNB2",
    "gene-KCNC1",
    "gene-KCNC2",
    "gene-KCNC3",
    "gene-KCNC4",
    "gene-KCND1",
    "gene-KCND2",
    "gene-KCND3",
    "gene-KCNE1",
    "gene-KCNE2",
    "gene-KCNE3",
    "gene-KCNE4",
    "gene-KCNE5",
    "gene-KCNF1",
    "gene-KCNG1",
    "gene-KCNG2",
    "gene-KCNG3",
    "gene-KCNG4",
    "gene-KCNH1",
    "gene-KCNH2",
    "gene-KCNH3",
    "gene-KCNH4",
    "gene-KCNH5",
    "gene-KCNH6",
    "gene-KCNH7",
    "gene-KCNH8",
    "gene-KCNIP1",
    "gene-KCNIP2",
    "gene-KCNIP3",
    "gene-KCNIP4",
    "gene-KCNJ1",
    "gene-KCNJ10",
    "gene-KCNJ11",
    "gene-KCNJ12",
    "gene-KCNJ13",
    "gene-KCNJ14",
    "gene-KCNJ15",
    "gene-KCNJ16",
    "gene-KCNJ18",
    "gene-KCNJ2",
    "gene-KCNJ3",
    "gene-KCNJ4",
    "gene-KCNJ5",
    "gene-KCNJ6",
    "gene-KCNJ8",
    "gene-KCNJ9",
    "gene-KCNK1",
    "gene-KCNK10",
    "gene-KCNK12",
    "gene-KCNK13",
    "gene-KCNK15",
    "gene-KCNK16",
    "gene-KCNK17",
    "gene-KCNK18",
    "gene-KCNK2",
    "gene-KCNK3",
    "gene-KCNK4",
    "gene-KCNK5",
    "gene-KCNK6",
    "gene-KCNK7",
    "gene-KCNK9",
    "gene-KCNMA1",
    "gene-KCNMB1",
    "gene-KCNMB2",
    "gene-KCNMB3",
    "gene-KCNMB4",
    "gene-KCNN1",
    "gene-KCNN2",
    "gene-KCNN3",
    "gene-KCNN4",
    "gene-KCNQ1",
    "gene-KCNQ2",
    "gene-KCNQ3",
    "gene-KCNQ4",
    "gene-KCNQ5",
    "gene-KCNRG",
    "gene-KCNS1",
    "gene-KCNS2",
    "gene-KCNS3",
    "gene-KCNT1",
    "gene-KCNT2",
    "gene-KCNU1",
    "gene-KCNV1",
    "gene-KCNV2",
    "gene-KCP",
    "gene-KCTD1",
    "gene-KCTD10",
    "gene-KCTD11",
    "gene-KCTD12",
    "gene-KCTD13",
    "gene-KCTD14",
    "gene-KCTD15",
    "gene-KCTD16",
    "gene-KCTD17",
    "gene-KCTD18",
    "gene-KCTD19",
    "gene-KCTD2",
    "gene-KCTD20",
    "gene-KCTD21",
    "gene-KCTD3",
    "gene-KCTD4",
    "gene-KCTD5",
    "gene-KCTD6",
    "gene-KCTD7",
    "gene-KCTD8",
    "gene-KCTD9",
    "gene-KDELR1",
    "gene-KDELR2",
    "gene-KDELR3",
    "gene-KDF1",
    "gene-KDM1A",
    "gene-KDM1B",
    "gene-KDM2A",
    "gene-KDM2B",
    "gene-KDM3A",
    "gene-KDM3B",
    "gene-KDM4A",
    "gene-KDM4B",
    "gene-KDM4C",
    "gene-KDM4D",
    "gene-KDM4E",
    "gene-KDM4F",
    "gene-KDM5A",
    "gene-KDM5B",
    "gene-KDM5C",
    "gene-KDM5D",
    "gene-KDM6A",
    "gene-KDM6B",
    "gene-KDM7A",
    "gene-KDM8",
    "gene-KDR",
    "gene-KDSR",
    "gene-KEAP1",
    "gene-KEL",
    "gene-KERA",
    "gene-KGD4",
    "gene-KHDC1",
    "gene-KHDC1L",
    "gene-KHDC3L",
    "gene-KHDC4",
    "gene-KHDRBS1",
    "gene-KHDRBS2",
    "gene-KHDRBS3",
    "gene-KHK",
    "gene-KHNYN",
    "gene-KHSRP",
    "gene-KIAA0040",
    "gene-KIAA0232",
    "gene-KIAA0319",
    "gene-KIAA0319L",
    "gene-KIAA0408",
    "gene-KIAA0513",
    "gene-KIAA0586",
    "gene-KIAA0753",
    "gene-KIAA0825",
    "gene-KIAA0930",
    "gene-KIAA1143",
    "gene-KIAA1191",
    "gene-KIAA1210",
    "gene-KIAA1217",
    "gene-KIAA1328",
    "gene-KIAA1549",
    "gene-KIAA1549L",
    "gene-KIAA1586",
    "gene-KIAA1614",
    "gene-KIAA1671",
    "gene-KIAA1755",
    "gene-KIAA1958",
    "gene-KIAA2012",
    "gene-KIAA2013",
    "gene-KICS2",
    "gene-KIDINS220",
    "gene-KIF11",
    "gene-KIF12",
    "gene-KIF13A",
    "gene-KIF13B",
    "gene-KIF14",
    "gene-KIF15",
    "gene-KIF16B",
    "gene-KIF17",
    "gene-KIF18A",
    "gene-KIF18B",
    "gene-KIF19",
    "gene-KIF1A",
    "gene-KIF1B",
    "gene-KIF1C",
    "gene-KIF20A",
    "gene-KIF20B",
    "gene-KIF21A",
    "gene-KIF21B",
    "gene-KIF22",
    "gene-KIF23",
    "gene-KIF24",
    "gene-KIF25",
    "gene-KIF26A",
    "gene-KIF26B",
    "gene-KIF27",
    "gene-KIF2A",
    "gene-KIF2B",
    "gene-KIF2C",
    "gene-KIF3A",
    "gene-KIF3B",
    "gene-KIF3C",
    "gene-KIF4A",
    "gene-KIF4B",
    "gene-KIF5A",
    "gene-KIF5B",
    "gene-KIF5C",
    "gene-KIF6",
    "gene-KIF7",
    "gene-KIF9",
    "gene-KIFAP3",
    "gene-KIFBP",
    "gene-KIFC1",
    "gene-KIFC2",
    "gene-KIFC3",
    "gene-KIN",
    "gene-KIR2DL1",
    "gene-KIR2DL3",
    "gene-KIR2DL4",
    "gene-KIR2DS4",
    "gene-KIR3DL1",
    "gene-KIR3DL2",
    "gene-KIR3DL3",
    "gene-KIRREL1",
    "gene-KIRREL2",
    "gene-KIRREL3",
    "gene-KISS1",
    "gene-KISS1R",
    "gene-KIT",
    "gene-KITLG",
    "gene-KIZ",
    "gene-KL",
    "gene-KLB",
    "gene-KLC1",
    "gene-KLC2",
    "gene-KLC3",
    "gene-KLC4",
    "gene-KLF1",
    "gene-KLF10",
    "gene-KLF11",
    "gene-KLF12",
    "gene-KLF13",
    "gene-KLF14",
    "gene-KLF15",
    "gene-KLF16",
    "gene-KLF17",
    "gene-KLF18",
    "gene-KLF2",
    "gene-KLF3",
    "gene-KLF4",
    "gene-KLF5",
    "gene-KLF6",
    "gene-KLF7",
    "gene-KLF8",
    "gene-KLF9",
    "gene-KLHDC1",
    "gene-KLHDC10",
    "gene-KLHDC2",
    "gene-KLHDC3",
    "gene-KLHDC4",
    "gene-KLHDC7A",
    "gene-KLHDC7B",
    "gene-KLHDC8A",
    "gene-KLHDC8B",
    "gene-KLHDC9",
    "gene-KLHL1",
    "gene-KLHL10",
    "gene-KLHL11",
    "gene-KLHL12",
    "gene-KLHL13",
    "gene-KLHL14",
    "gene-KLHL15",
    "gene-KLHL17",
    "gene-KLHL18",
    "gene-KLHL2",
    "gene-KLHL20",
    "gene-KLHL21",
    "gene-KLHL22",
    "gene-KLHL23",
    "gene-KLHL24",
    "gene-KLHL25",
    "gene-KLHL26",
    "gene-KLHL28",
    "gene-KLHL29",
    "gene-KLHL3",
    "gene-KLHL30",
    "gene-KLHL31",
    "gene-KLHL32",
    "gene-KLHL33",
    "gene-KLHL34",
    "gene-KLHL35",
    "gene-KLHL36",
    "gene-KLHL38",
    "gene-KLHL4",
    "gene-KLHL40",
    "gene-KLHL41",
    "gene-KLHL42",
    "gene-KLHL5",
    "gene-KLHL6",
    "gene-KLHL7",
    "gene-KLHL8",
    "gene-KLHL9",
    "gene-KLK1",
    "gene-KLK10",
    "gene-KLK11",
    "gene-KLK12",
    "gene-KLK13",
    "gene-KLK14",
    "gene-KLK15",
    "gene-KLK2",
    "gene-KLK3",
    "gene-KLK4",
    "gene-KLK5",
    "gene-KLK6",
    "gene-KLK7",
    "gene-KLK8",
    "gene-KLK9",
    "gene-KLKB1",
    "gene-KLLN",
    "gene-KLRB1",
    "gene-KLRC1",
    "gene-KLRC2",
    "gene-KLRC3",
    "gene-KLRC4",
    "gene-KLRD1",
    "gene-KLRF1",
    "gene-KLRF2",
    "gene-KLRG1",
    "gene-KLRG2",
    "gene-KLRK1",
    "gene-KMO",
    "gene-KMT2A",
    "gene-KMT2B",
    "gene-KMT2C",
    "gene-KMT2D",
    "gene-KMT2E",
    "gene-KMT5A",
    "gene-KMT5B",
    "gene-KMT5C",
    "gene-KNCN",
    "gene-KNDC1",
    "gene-KNG1",
    "gene-KNL1",
    "gene-KNOP1",
    "gene-KNSTRN",
    "gene-KNTC1",
    "gene-KPLCE",
    "gene-KPNA1",
    "gene-KPNA2",
    "gene-KPNA3",
    "gene-KPNA4",
    "gene-KPNA5",
    "gene-KPNA6",
    "gene-KPNA7",
    "gene-KPNB1",
    "gene-KPRP",
    "gene-KPTN",
    "gene-KRAS",
    "gene-KRBA1",
    "gene-KRBA2",
    "gene-KRBOX1",
    "gene-KRBOX4",
    "gene-KRBOX5",
    "gene-KRCC1",
    "gene-KREMEN1",
    "gene-KREMEN2",
    "gene-KRI1",
    "gene-KRIT1",
    "gene-KRR1",
    "gene-KRT1",
    "gene-KRT10",
    "gene-KRT12",
    "gene-KRT13",
    "gene-KRT14",
    "gene-KRT15",
    "gene-KRT16",
    "gene-KRT17",
    "gene-KRT18",
    "gene-KRT19",
    "gene-KRT2",
    "gene-KRT20",
    "gene-KRT222",
    "gene-KRT23",
    "gene-KRT24",
    "gene-KRT25",
    "gene-KRT26",
    "gene-KRT27",
    "gene-KRT28",
    "gene-KRT3",
    "gene-KRT31",
    "gene-KRT32",
    "gene-KRT33A",
    "gene-KRT33B",
    "gene-KRT34",
    "gene-KRT35",
    "gene-KRT36",
    "gene-KRT37",
    "gene-KRT38",
    "gene-KRT39",
    "gene-KRT4",
    "gene-KRT40",
    "gene-KRT5",
    "gene-KRT6A",
    "gene-KRT6B",
    "gene-KRT6C",
    "gene-KRT7",
    "gene-KRT71",
    "gene-KRT72",
    "gene-KRT73",
    "gene-KRT74",
    "gene-KRT75",
    "gene-KRT76",
    "gene-KRT77",
    "gene-KRT78",
    "gene-KRT79",
    "gene-KRT8",
    "gene-KRT80",
    "gene-KRT81",
    "gene-KRT82",
    "gene-KRT83",
    "gene-KRT84",
    "gene-KRT85",
    "gene-KRT86",
    "gene-KRT9",
    "gene-KRTAP1-1",
    "gene-KRTAP1-3",
    "gene-KRTAP1-4",
    "gene-KRTAP1-5",
    "gene-KRTAP10-1",
    "gene-KRTAP10-10",
    "gene-KRTAP10-11",
    "gene-KRTAP10-12",
    "gene-KRTAP10-2",
    "gene-KRTAP10-3",
    "gene-KRTAP10-4",
    "gene-KRTAP10-5",
    "gene-KRTAP10-6",
    "gene-KRTAP10-7",
    "gene-KRTAP10-8",
    "gene-KRTAP10-9",
    "gene-KRTAP11-1",
    "gene-KRTAP12-1",
    "gene-KRTAP12-2",
    "gene-KRTAP12-3",
    "gene-KRTAP12-4",
    "gene-KRTAP13-1",
    "gene-KRTAP13-2",
    "gene-KRTAP13-3",
    "gene-KRTAP13-4",
    "gene-KRTAP15-1",
    "gene-KRTAP16-1",
    "gene-KRTAP17-1",
    "gene-KRTAP19-1",
    "gene-KRTAP19-2",
    "gene-KRTAP19-3",
    "gene-KRTAP19-4",
    "gene-KRTAP19-5",
    "gene-KRTAP19-6",
    "gene-KRTAP19-7",
    "gene-KRTAP19-8",
    "gene-KRTAP2-1",
    "gene-KRTAP2-2",
    "gene-KRTAP2-3",
    "gene-KRTAP2-4",
    "gene-KRTAP20-1",
    "gene-KRTAP20-2",
    "gene-KRTAP20-3",
    "gene-KRTAP20-4",
    "gene-KRTAP21-1",
    "gene-KRTAP21-2",
    "gene-KRTAP21-3",
    "gene-KRTAP22-1",
    "gene-KRTAP22-2",
    "gene-KRTAP23-1",
    "gene-KRTAP24-1",
    "gene-KRTAP25-1",
    "gene-KRTAP26-1",
    "gene-KRTAP27-1",
    "gene-KRTAP29-1",
    "gene-KRTAP3-1",
    "gene-KRTAP3-2",
    "gene-KRTAP3-3",
    "gene-KRTAP4-1",
    "gene-KRTAP4-11",
    "gene-KRTAP4-12",
    "gene-KRTAP4-16",
    "gene-KRTAP4-2",
    "gene-KRTAP4-3",
    "gene-KRTAP4-4",
    "gene-KRTAP4-5",
    "gene-KRTAP4-6",
    "gene-KRTAP4-7",
    "gene-KRTAP4-8",
    "gene-KRTAP4-9",
    "gene-KRTAP5-1",
    "gene-KRTAP5-10",
    "gene-KRTAP5-11",
    "gene-KRTAP5-2",
    "gene-KRTAP5-3",
    "gene-KRTAP5-4",
    "gene-KRTAP5-5",
    "gene-KRTAP5-6",
    "gene-KRTAP5-7",
    "gene-KRTAP5-8",
    "gene-KRTAP5-9",
    "gene-KRTAP6-1",
    "gene-KRTAP6-2",
    "gene-KRTAP6-3",
    "gene-KRTAP7-1",
    "gene-KRTAP8-1",
    "gene-KRTAP9-1",
    "gene-KRTAP9-2",
    "gene-KRTAP9-3",
    "gene-KRTAP9-4",
    "gene-KRTAP9-6",
    "gene-KRTAP9-7",
    "gene-KRTAP9-8",
    "gene-KRTAP9-9",
    "gene-KRTCAP2",
    "gene-KRTCAP3",
    "gene-KRTDAP",
    "gene-KSR1",
    "gene-KSR2",
    "gene-KTI12",
    "gene-KTN1",
    "gene-KXD1",
    "gene-KY",
    "gene-KYAT1",
    "gene-KYAT3",
    "gene-KYNU",
    "gene-L1CAM",
    "gene-L1TD1",
    "gene-L2HGDH",
    "gene-L3HYPDH",
    "gene-L3MBTL1",
    "gene-L3MBTL2",
    "gene-L3MBTL3",
    "gene-L3MBTL4",
    "gene-LACC1",
    "gene-LACRT",
    "gene-LACTB",
    "gene-LACTB2",
    "gene-LACTBL1",
    "gene-LAD1",
    "gene-LAG3",
    "gene-LAGE3",
    "gene-LAIR1",
    "gene-LAIR2",
    "gene-LALBA",
    "gene-LAMA1",
    "gene-LAMA2",
    "gene-LAMA3",
    "gene-LAMA4",
    "gene-LAMA5",
    "gene-LAMB1",
    "gene-LAMB2",
    "gene-LAMB3",
    "gene-LAMB4",
    "gene-LAMC1",
    "gene-LAMC2",
    "gene-LAMC3",
    "gene-LAMP1",
    "gene-LAMP2",
    "gene-LAMP3",
    "gene-LAMP5",
    "gene-LAMTOR1",
    "gene-LAMTOR2",
    "gene-LAMTOR3",
    "gene-LAMTOR4",
    "gene-LAMTOR5",
    "gene-LANCL1",
    "gene-LANCL2",
    "gene-LANCL3",
    "gene-LAP3",
    "gene-LAPTM4A",
    "gene-LAPTM4B",
    "gene-LAPTM5",
    "gene-LARGE1",
    "gene-LARGE2",
    "gene-LARP1",
    "gene-LARP1B",
    "gene-LARP4",
    "gene-LARP4B",
    "gene-LARP6",
    "gene-LARP7",
    "gene-LARS1",
    "gene-LARS2",
    "gene-LAS1L",
    "gene-LASP1",
    "gene-LASP1NB",
    "gene-LAT",
    "gene-LAT2",
    "gene-LATS1",
    "gene-LATS2",
    "gene-LAX1",
    "gene-LAYN",
    "gene-LBH",
    "gene-LBHD1",
    "gene-LBHD2",
    "gene-LBP",
    "gene-LBR",
    "gene-LBX1",
    "gene-LBX2",
    "gene-LCA5",
    "gene-LCA5L",
    "gene-LCAT",
    "gene-LCE1A",
    "gene-LCE1B",
    "gene-LCE1C",
    "gene-LCE1D",
    "gene-LCE1E",
    "gene-LCE1F",
    "gene-LCE2A",
    "gene-LCE2B",
    "gene-LCE2C",
    "gene-LCE2D",
    "gene-LCE3A",
    "gene-LCE3B",
    "gene-LCE3C",
    "gene-LCE3D",
    "gene-LCE3E",
    "gene-LCE4A",
    "gene-LCE5A",
    "gene-LCE6A",
    "gene-LCE7A",
    "gene-LCK",
    "gene-LCLAT1",
    "gene-LCMT1",
    "gene-LCMT2",
    "gene-LCN1",
    "gene-LCN10",
    "gene-LCN12",
    "gene-LCN15",
    "gene-LCN2",
    "gene-LCN6",
    "gene-LCN8",
    "gene-LCN9",
    "gene-LCNL1",
    "gene-LCOR",
    "gene-LCORL",
    "gene-LCP1",
    "gene-LCP2",
    "gene-LCT",
    "gene-LCTL",
    "gene-LDAF1",
    "gene-LDAH",
    "gene-LDB1",
    "gene-LDB2",
    "gene-LDB3",
    "gene-LDHA",
    "gene-LDHAL6A",
    "gene-LDHAL6B",
    "gene-LDHB",
    "gene-LDHC",
    "gene-LDHD",
    "gene-LDLR",
    "gene-LDLRAD1",
    "gene-LDLRAD2",
    "gene-LDLRAD3",
    "gene-LDLRAD4",
    "gene-LDLRAP1",
    "gene-LDOC1",
    "gene-LEAP2",
    "gene-LECT2",
    "gene-LEF1",
    "gene-LEFTY1",
    "gene-LEFTY2",
    "gene-LEKR1",
    "gene-LELP1",
    "gene-LEMD1",
    "gene-LEMD2",
    "gene-LEMD3",
    "gene-LENEP",
    "gene-LENG1",
    "gene-LENG8",
    "gene-LENG9",
    "gene-LEO1",
    "gene-LEP",
    "gene-LEPR",
    "gene-LEPROT",
    "gene-LEPROTL1",
    "gene-LETM1",
    "gene-LETM2",
    "gene-LETMD1",
    "gene-LEUTX",
    "gene-LFNG",
    "gene-LGALS1",
    "gene-LGALS12",
    "gene-LGALS13",
    "gene-LGALS14",
    "gene-LGALS16",
    "gene-LGALS2",
    "gene-LGALS3",
    "gene-LGALS3BP",
    "gene-LGALS4",
    "gene-LGALS7",
    "gene-LGALS7B",
    "gene-LGALS8",
    "gene-LGALS9",
    "gene-LGALS9B",
    "gene-LGALS9C",
    "gene-LGALSL",
    "gene-LGI1",
    "gene-LGI2",
    "gene-LGI3",
    "gene-LGI4",
    "gene-LGMN",
    "gene-LGR4",
    "gene-LGR5",
    "gene-LGR6",
    "gene-LGSN",
    "gene-LHB",
    "gene-LHCGR",
    "gene-LHFPL1",
    "gene-LHFPL2",
    "gene-LHFPL3",
    "gene-LHFPL4",
    "gene-LHFPL5",
    "gene-LHFPL6",
    "gene-LHFPL7",
    "gene-LHPP",
    "gene-LHX1",
    "gene-LHX2",
    "gene-LHX3",
    "gene-LHX4",
    "gene-LHX5",
    "gene-LHX6",
    "gene-LHX8",
    "gene-LHX9",
    "gene-LIAS",
    "gene-LIAT1",
    "gene-LIF",
    "gene-LIFR",
    "gene-LIG1",
    "gene-LIG3",
    "gene-LIG4",
    "gene-LILRA1",
    "gene-LILRA2",
    "gene-LILRA4",
    "gene-LILRA5",
    "gene-LILRA6",
    "gene-LILRB1",
    "gene-LILRB2",
    "gene-LILRB3",
    "gene-LILRB4",
    "gene-LILRB5",
    "gene-LIM2",
    "gene-LIMA1",
    "gene-LIMCH1",
    "gene-LIMD1",
    "gene-LIMD2",
    "gene-LIME1",
    "gene-LIMK1",
    "gene-LIMK2",
    "gene-LIMS1",
    "gene-LIMS2",
    "gene-LIMS3",
    "gene-LIMS4",
    "gene-LIN28A",
    "gene-LIN28B",
    "gene-LIN37",
    "gene-LIN52",
    "gene-LIN54",
    "gene-LIN7A",
    "gene-LIN7B",
    "gene-LIN7C",
    "gene-LIN9",
    "gene-LINGO1",
    "gene-LINGO2",
    "gene-LINGO3",
    "gene-LINGO4",
    "gene-LINS1",
    "gene-LIPA",
    "gene-LIPC",
    "gene-LIPE",
    "gene-LIPF",
    "gene-LIPG",
    "gene-LIPH",
    "gene-LIPI",
    "gene-LIPJ",
    "gene-LIPK",
    "gene-LIPM",
    "gene-LIPN",
    "gene-LIPT1",
    "gene-LIPT2",
    "gene-LITAF",
    "gene-LITAFD",
    "gene-LIX1",
    "gene-LIX1L",
    "gene-LKAAEAR1",
    "gene-LLCFC1",
    "gene-LLGL1",
    "gene-LLGL2",
    "gene-LLPH",
    "gene-LMAN1",
    "gene-LMAN1L",
    "gene-LMAN2",
    "gene-LMAN2L",
    "gene-LMBR1",
    "gene-LMBR1L",
    "gene-LMBRD1",
    "gene-LMBRD2",
    "gene-LMCD1",
    "gene-LMF1",
    "gene-LMF2",
    "gene-LMLN",
    "gene-LMNA",
    "gene-LMNB1",
    "gene-LMNB2",
    "gene-LMNTD1",
    "gene-LMNTD2",
    "gene-LMO1",
    "gene-LMO2",
    "gene-LMO3",
    "gene-LMO4",
    "gene-LMO7",
    "gene-LMOD1",
    "gene-LMOD2",
    "gene-LMOD3",
    "gene-LMTK2",
    "gene-LMTK3",
    "gene-LMX1A",
    "gene-LMX1B",
    "gene-LNP1",
    "gene-LNPEP",
    "gene-LNPK",
    "gene-LNX1",
    "gene-LNX2",
    "gene-LONP1",
    "gene-LONP2",
    "gene-LONRF1",
    "gene-LONRF2",
    "gene-LONRF3",
    "gene-LORICRIN",
    "gene-LOX",
    "gene-LOXHD1",
    "gene-LOXL1",
    "gene-LOXL2",
    "gene-LOXL3",
    "gene-LOXL4",
    "gene-LPA",
    "gene-LPAR1",
    "gene-LPAR2",
    "gene-LPAR3",
    "gene-LPAR4",
    "gene-LPAR5",
    "gene-LPAR6",
    "gene-LPCAT1",
    "gene-LPCAT2",
    "gene-LPCAT3",
    "gene-LPCAT4",
    "gene-LPGAT1",
    "gene-LPIN1",
    "gene-LPIN2",
    "gene-LPIN3",
    "gene-LPL",
    "gene-LPO",
    "gene-LPP",
    "gene-LPXN",
    "gene-LRAT",
    "gene-LRATD1",
    "gene-LRATD2",
    "gene-LRBA",
    "gene-LRCH1",
    "gene-LRCH2",
    "gene-LRCH3",
    "gene-LRCH4",
    "gene-LRCOL1",
    "gene-LRFN1",
    "gene-LRFN2",
    "gene-LRFN3",
    "gene-LRFN4",
    "gene-LRFN5",
    "gene-LRG1",
    "gene-LRGUK",
    "gene-LRIF1",
    "gene-LRIG1",
    "gene-LRIG2",
    "gene-LRIG3",
    "gene-LRIT1",
    "gene-LRIT2",
    "gene-LRIT3",
    "gene-LRMDA",
    "gene-LRP1",
    "gene-LRP10",
    "gene-LRP11",
    "gene-LRP12",
    "gene-LRP1B",
    "gene-LRP2",
    "gene-LRP2BP",
    "gene-LRP3",
    "gene-LRP4",
    "gene-LRP5",
    "gene-LRP6",
    "gene-LRP8",
    "gene-LRPAP1",
    "gene-LRPPRC",
    "gene-LRR1",
    "gene-LRRC1",
    "gene-LRRC10",
    "gene-LRRC10B",
    "gene-LRRC14",
    "gene-LRRC14B",
    "gene-LRRC15",
    "gene-LRRC17",
    "gene-LRRC18",
    "gene-LRRC19",
    "gene-LRRC2",
    "gene-LRRC20",
    "gene-LRRC23",
    "gene-LRRC24",
    "gene-LRRC25",
    "gene-LRRC26",
    "gene-LRRC27",
    "gene-LRRC28",
    "gene-LRRC3",
    "gene-LRRC30",
    "gene-LRRC31",
    "gene-LRRC32",
    "gene-LRRC34",
    "gene-LRRC36",
    "gene-LRRC37A",
    "gene-LRRC37A2",
    "gene-LRRC37A3",
    "gene-LRRC37B",
    "gene-LRRC38",
    "gene-LRRC39",
    "gene-LRRC3B",
    "gene-LRRC3C",
    "gene-LRRC4",
    "gene-LRRC40",
    "gene-LRRC41",
    "gene-LRRC42",
    "gene-LRRC43",
    "gene-LRRC45",
    "gene-LRRC46",
    "gene-LRRC47",
    "gene-LRRC49",
    "gene-LRRC4B",
    "gene-LRRC4C",
    "gene-LRRC51",
    "gene-LRRC52",
    "gene-LRRC53",
    "gene-LRRC55",
    "gene-LRRC56",
    "gene-LRRC57",
    "gene-LRRC58",
    "gene-LRRC59",
    "gene-LRRC61",
    "gene-LRRC63",
    "gene-LRRC66",
    "gene-LRRC69",
    "gene-LRRC7",
    "gene-LRRC70",
    "gene-LRRC71",
    "gene-LRRC72",
    "gene-LRRC73",
    "gene-LRRC74A",
    "gene-LRRC74B",
    "gene-LRRC75A",
    "gene-LRRC75B",
    "gene-LRRC8A",
    "gene-LRRC8B",
    "gene-LRRC8C",
    "gene-LRRC8D",
    "gene-LRRC8E",
    "gene-LRRC9",
    "gene-LRRCC1",
    "gene-LRRD1",
    "gene-LRRFIP1",
    "gene-LRRFIP2",
    "gene-LRRIQ1",
    "gene-LRRIQ3",
    "gene-LRRIQ4",
    "gene-LRRK1",
    "gene-LRRK2",
    "gene-LRRN1",
    "gene-LRRN2",
    "gene-LRRN3",
    "gene-LRRN4",
    "gene-LRRN4CL",
    "gene-LRRTM1",
    "gene-LRRTM2",
    "gene-LRRTM3",
    "gene-LRRTM4",
    "gene-LRSAM1",
    "gene-LRTM1",
    "gene-LRTM2",
    "gene-LRWD1",
    "gene-LSAMP",
    "gene-LSG1",
    "gene-LSM1",
    "gene-LSM10",
    "gene-LSM11",
    "gene-LSM12",
    "gene-LSM14A",
    "gene-LSM14B",
    "gene-LSM2",
    "gene-LSM3",
    "gene-LSM4",
    "gene-LSM5",
    "gene-LSM6",
    "gene-LSM7",
    "gene-LSM8",
    "gene-LSMEM1",
    "gene-LSMEM2",
    "gene-LSP1",
    "gene-LSR",
    "gene-LSS",
    "gene-LST1",
    "gene-LTA",
    "gene-LTA4H",
    "gene-LTB",
    "gene-LTB4R",
    "gene-LTB4R2",
    "gene-LTBP1",
    "gene-LTBP2",
    "gene-LTBP3",
    "gene-LTBP4",
    "gene-LTBR",
    "gene-LTC4S",
    "gene-LTF",
    "gene-LTK",
    "gene-LTN1",
    "gene-LTO1",
    "gene-LTV1",
    "gene-LUC7L",
    "gene-LUC7L2",
    "gene-LUC7L3",
    "gene-LUM",
    "gene-LURAP1",
    "gene-LURAP1L",
    "gene-LUZP1",
    "gene-LUZP2",
    "gene-LUZP4",
    "gene-LVRN",
    "gene-LXN",
    "gene-LY6D",
    "gene-LY6E",
    "gene-LY6G5B",
    "gene-LY6G5C",
    "gene-LY6G6C",
    "gene-LY6G6D",
    "gene-LY6G6F",
    "gene-LY6H",
    "gene-LY6K",
    "gene-LY6L",
    "gene-LY6S",
    "gene-LY75",
    "gene-LY86",
    "gene-LY9",
    "gene-LY96",
    "gene-LYAR",
    "gene-LYG1",
    "gene-LYG2",
    "gene-LYL1",
    "gene-LYN",
    "gene-LYNX1",
    "gene-LYPD1",
    "gene-LYPD2",
    "gene-LYPD3",
    "gene-LYPD4",
    "gene-LYPD5",
    "gene-LYPD6",
    "gene-LYPD6B",
    "gene-LYPD8",
    "gene-LYPLA1",
    "gene-LYPLA2",
    "gene-LYPLAL1",
    "gene-LYRM1",
    "gene-LYRM2",
    "gene-LYRM4",
    "gene-LYRM7",
    "gene-LYRM9",
    "gene-LYSET",
    "gene-LYSMD1",
    "gene-LYSMD2",
    "gene-LYSMD3",
    "gene-LYSMD4",
    "gene-LYST",
    "gene-LYVE1",
    "gene-LYZ",
    "gene-LYZL1",
    "gene-LYZL2",
    "gene-LYZL4",
    "gene-LYZL6",
    "gene-LZIC",
    "gene-LZTFL1",
    "gene-LZTR1",
    "gene-LZTS1",
    "gene-LZTS2",
    "gene-LZTS3",
    "gene-M1AP",
    "gene-M6PR",
    "gene-MAB21L1",
    "gene-MAB21L2",
    "gene-MAB21L3",
    "gene-MAB21L4",
    "gene-MACC1",
    "gene-MACF1",
    "gene-MACIR",
    "gene-MACO1",
    "gene-MACROD1",
    "gene-MACROD2",
    "gene-MACROH2A1",
    "gene-MACROH2A2",
    "gene-MAD1L1",
    "gene-MAD2L1",
    "gene-MAD2L1BP",
    "gene-MAD2L2",
    "gene-MADCAM1",
    "gene-MADD",
    "gene-MAEA",
    "gene-MAEL",
    "gene-MAF",
    "gene-MAF1",
    "gene-MAFA",
    "gene-MAFB",
    "gene-MAFF",
    "gene-MAFG",
    "gene-MAFK",
    "gene-MAG",
    "gene-MAGEA1",
    "gene-MAGEA10",
    "gene-MAGEA11",
    "gene-MAGEA12",
    "gene-MAGEA2",
    "gene-MAGEA2B",
    "gene-MAGEA3",
    "gene-MAGEA4",
    "gene-MAGEA6",
    "gene-MAGEA8",
    "gene-MAGEA9",
    "gene-MAGEA9B",
    "gene-MAGEB1",
    "gene-MAGEB10",
    "gene-MAGEB16",
    "gene-MAGEB17",
    "gene-MAGEB18",
    "gene-MAGEB2",
    "gene-MAGEB3",
    "gene-MAGEB4",
    "gene-MAGEB5",
    "gene-MAGEB6",
    "gene-MAGEB6B",
    "gene-MAGEC1",
    "gene-MAGEC2",
    "gene-MAGEC3",
    "gene-MAGED1",
    "gene-MAGED2",
    "gene-MAGED4",
    "gene-MAGED4B",
    "gene-MAGEE1",
    "gene-MAGEE2",
    "gene-MAGEF1",
    "gene-MAGEH1",
    "gene-MAGEL2",
    "gene-MAGI1",
    "gene-MAGI2",
    "gene-MAGI3",
    "gene-MAGIX",
    "gene-MAGOH",
    "gene-MAGOHB",
    "gene-MAGT1",
    "gene-MAIP1",
    "gene-MAJIN",
    "gene-MAK",
    "gene-MAK16",
    "gene-MAL",
    "gene-MAL2",
    "gene-MALL",
    "gene-MALRD1",
    "gene-MALSU1",
    "gene-MALT1",
    "gene-MAMDC2",
    "gene-MAMDC4",
    "gene-MAML1",
    "gene-MAML2",
    "gene-MAML3",
    "gene-MAMLD1",
    "gene-MAMSTR",
    "gene-MAN1A1",
    "gene-MAN1A2",
    "gene-MAN1B1",
    "gene-MAN1C1",
    "gene-MAN2A1",
    "gene-MAN2A2",
    "gene-MAN2B1",
    "gene-MAN2B2",
    "gene-MAN2C1",
    "gene-MANBA",
    "gene-MANBAL",
    "gene-MANEA",
    "gene-MANEAL",
    "gene-MANF",
    "gene-MANSC1",
    "gene-MANSC4",
    "gene-MAOA",
    "gene-MAOB",
    "gene-MAP10",
    "gene-MAP1A",
    "gene-MAP1B",
    "gene-MAP1LC3A",
    "gene-MAP1LC3B",
    "gene-MAP1LC3B2",
    "gene-MAP1LC3C",
    "gene-MAP1S",
    "gene-MAP2",
    "gene-MAP2K1",
    "gene-MAP2K2",
    "gene-MAP2K3",
    "gene-MAP2K4",
    "gene-MAP2K5",
    "gene-MAP2K6",
    "gene-MAP2K7",
    "gene-MAP3K1",
    "gene-MAP3K10",
    "gene-MAP3K11",
    "gene-MAP3K12",
    "gene-MAP3K13",
    "gene-MAP3K14",
    "gene-MAP3K15",
    "gene-MAP3K19",
    "gene-MAP3K2",
    "gene-MAP3K20",
    "gene-MAP3K21",
    "gene-MAP3K3",
    "gene-MAP3K4",
    "gene-MAP3K5",
    "gene-MAP3K6",
    "gene-MAP3K7",
    "gene-MAP3K7CL",
    "gene-MAP3K8",
    "gene-MAP3K9",
    "gene-MAP4",
    "gene-MAP4K1",
    "gene-MAP4K2",
    "gene-MAP4K3",
    "gene-MAP4K4",
    "gene-MAP4K5",
    "gene-MAP6",
    "gene-MAP6D1",
    "gene-MAP7",
    "gene-MAP7D1",
    "gene-MAP7D2",
    "gene-MAP7D3",
    "gene-MAP9",
    "gene-MAPK1",
    "gene-MAPK10",
    "gene-MAPK11",
    "gene-MAPK12",
    "gene-MAPK13",
    "gene-MAPK14",
    "gene-MAPK15",
    "gene-MAPK1IP1L",
    "gene-MAPK3",
    "gene-MAPK4",
    "gene-MAPK6",
    "gene-MAPK7",
    "gene-MAPK8",
    "gene-MAPK8IP1",
    "gene-MAPK8IP2",
    "gene-MAPK8IP3",
    "gene-MAPK9",
    "gene-MAPKAP1",
    "gene-MAPKAPK2",
    "gene-MAPKAPK3",
    "gene-MAPKAPK5",
    "gene-MAPKBP1",
    "gene-MAPRE1",
    "gene-MAPRE2",
    "gene-MAPRE3",
    "gene-MAPT",
    "gene-MARCHF1",
    "gene-MARCHF10",
    "gene-MARCHF11",
    "gene-MARCHF2",
    "gene-MARCHF3",
    "gene-MARCHF4",
    "gene-MARCHF5",
    "gene-MARCHF6",
    "gene-MARCHF7",
    "gene-MARCHF8",
    "gene-MARCHF9",
    "gene-MARCKS",
    "gene-MARCKSL1",
    "gene-MARCO",
    "gene-MARCOL",
    "gene-MARF1",
    "gene-MARK1",
    "gene-MARK2",
    "gene-MARK3",
    "gene-MARK4",
    "gene-MARS1",
    "gene-MARS2",
    "gene-MARVELD1",
    "gene-MARVELD2",
    "gene-MARVELD3",
    "gene-MAS1",
    "gene-MAS1L",
    "gene-MASP1",
    "gene-MASP2",
    "gene-MAST1",
    "gene-MAST2",
    "gene-MAST3",
    "gene-MAST4",
    "gene-MASTL",
    "gene-MAT1A",
    "gene-MAT2A",
    "gene-MAT2B",
    "gene-MATCAP1",
    "gene-MATCAP2",
    "gene-MATK",
    "gene-MATN1",
    "gene-MATN2",
    "gene-MATN3",
    "gene-MATN4",
    "gene-MATR3",
    "gene-MAU2",
    "gene-MAVS",
    "gene-MAX",
    "gene-MAZ",
    "gene-MB",
    "gene-MB21D2",
    "gene-MBD1",
    "gene-MBD2",
    "gene-MBD3",
    "gene-MBD3L1",
    "gene-MBD3L2",
    "gene-MBD3L2B",
    "gene-MBD3L3",
    "gene-MBD3L4",
    "gene-MBD3L5",
    "gene-MBD4",
    "gene-MBD5",
    "gene-MBD6",
    "gene-MBIP",
    "gene-MBL2",
    "gene-MBLAC1",
    "gene-MBLAC2",
    "gene-MBNL1",
    "gene-MBNL2",
    "gene-MBNL3",
    "gene-MBOAT1",
    "gene-MBOAT2",
    "gene-MBOAT4",
    "gene-MBOAT7",
    "gene-MBP",
    "gene-MBTD1",
    "gene-MBTPS1",
    "gene-MBTPS2",
    "gene-MC1R",
    "gene-MC2R",
    "gene-MC3R",
    "gene-MC4R",
    "gene-MC5R",
    "gene-MCAM",
    "gene-MCAT",
    "gene-MCC",
    "gene-MCCC1",
    "gene-MCCC2",
    "gene-MCCD1",
    "gene-MCEE",
    "gene-MCEMP1",
    "gene-MCF2",
    "gene-MCF2L",
    "gene-MCF2L2",
    "gene-MCFD2",
    "gene-MCHR1",
    "gene-MCHR2",
    "gene-MCIDAS",
    "gene-MCL1",
    "gene-MCM10",
    "gene-MCM2",
    "gene-MCM3",
    "gene-MCM3AP",
    "gene-MCM4",
    "gene-MCM5",
    "gene-MCM6",
    "gene-MCM7",
    "gene-MCM8",
    "gene-MCM9",
    "gene-MCMBP",
    "gene-MCMDC2",
    "gene-MCOLN1",
    "gene-MCOLN2",
    "gene-MCOLN3",
    "gene-MCPH1",
    "gene-MCRIP1",
    "gene-MCRIP2",
    "gene-MCRS1",
    "gene-MCTP1",
    "gene-MCTP2",
    "gene-MCTS1",
    "gene-MCTS2",
    "gene-MCU",
    "gene-MCUB",
    "gene-MCUR1",
    "gene-MDC1",
    "gene-MDFI",
    "gene-MDFIC",
    "gene-MDFIC2",
    "gene-MDGA1",
    "gene-MDGA2",
    "gene-MDH1",
    "gene-MDH1B",
    "gene-MDH2",
    "gene-MDK",
    "gene-MDM1",
    "gene-MDM2",
    "gene-MDM4",
    "gene-MDN1",
    "gene-MDP1",
    "gene-ME1",
    "gene-ME2",
    "gene-ME3",
    "gene-MEA1",
    "gene-MEAF6",
    "gene-MEAK7",
    "gene-MECOM",
    "gene-MECP2",
    "gene-MECR",
    "gene-MED1",
    "gene-MED10",
    "gene-MED11",
    "gene-MED12",
    "gene-MED12L",
    "gene-MED13",
    "gene-MED13L",
    "gene-MED14",
    "gene-MED14OS",
    "gene-MED15",
    "gene-MED16",
    "gene-MED17",
    "gene-MED18",
    "gene-MED19",
    "gene-MED20",
    "gene-MED21",
    "gene-MED22",
    "gene-MED23",
    "gene-MED24",
    "gene-MED25",
    "gene-MED26",
    "gene-MED27",
    "gene-MED28",
    "gene-MED29",
    "gene-MED30",
    "gene-MED31",
    "gene-MED4",
    "gene-MED6",
    "gene-MED7",
    "gene-MED8",
    "gene-MED9",
    "gene-MEDAG",
    "gene-MEF2A",
    "gene-MEF2B",
    "gene-MEF2C",
    "gene-MEF2D",
    "gene-MEFV",
    "gene-MEGF10",
    "gene-MEGF11",
    "gene-MEGF6",
    "gene-MEGF8",
    "gene-MEGF9",
    "gene-MEI1",
    "gene-MEI4",
    "gene-MEIG1",
    "gene-MEIKIN",
    "gene-MEIOB",
    "gene-MEIOC",
    "gene-MEIOSIN",
    "gene-MEIS1",
    "gene-MEIS2",
    "gene-MEIS3",
    "gene-MELK",
    "gene-MELTF",
    "gene-MEMO1",
    "gene-MEN1",
    "gene-MEOX1",
    "gene-MEOX2",
    "gene-MEP1A",
    "gene-MEP1B",
    "gene-MEPCE",
    "gene-MEPE",
    "gene-MERTK",
    "gene-MESD",
    "gene-MESP1",
    "gene-MESP2",
    "gene-MEST",
    "gene-MET",
    "gene-METAP1",
    "gene-METAP1D",
    "gene-METAP2",
    "gene-METRN",
    "gene-METRNL",
    "gene-METTL1",
    "gene-METTL13",
    "gene-METTL14",
    "gene-METTL15",
    "gene-METTL16",
    "gene-METTL17",
    "gene-METTL18",
    "gene-METTL21A",
    "gene-METTL21C",
    "gene-METTL22",
    "gene-METTL23",
    "gene-METTL24",
    "gene-METTL25",
    "gene-METTL25B",
    "gene-METTL26",
    "gene-METTL27",
    "gene-METTL2A",
    "gene-METTL2B",
    "gene-METTL3",
    "gene-METTL4",
    "gene-METTL5",
    "gene-METTL6",
    "gene-METTL8",
    "gene-METTL9",
    "gene-MEX3A",
    "gene-MEX3B",
    "gene-MEX3C",
    "gene-MEX3D",
    "gene-MFAP1",
    "gene-MFAP2",
    "gene-MFAP3",
    "gene-MFAP3L",
    "gene-MFAP4",
    "gene-MFAP5",
    "gene-MFF",
    "gene-MFGE8",
    "gene-MFHAS1",
    "gene-MFN1",
    "gene-MFN2",
    "gene-MFNG",
    "gene-MFRP",
    "gene-MFSD1",
    "gene-MFSD10",
    "gene-MFSD11",
    "gene-MFSD12",
    "gene-MFSD13A",
    "gene-MFSD14A",
    "gene-MFSD14B",
    "gene-MFSD2A",
    "gene-MFSD2B",
    "gene-MFSD3",
    "gene-MFSD4A",
    "gene-MFSD4B",
    "gene-MFSD5",
    "gene-MFSD6",
    "gene-MFSD6L",
    "gene-MFSD8",
    "gene-MFSD9",
    "gene-MGA",
    "gene-MGAM",
    "gene-MGAM2",
    "gene-MGARP",
    "gene-MGAT1",
    "gene-MGAT2",
    "gene-MGAT3",
    "gene-MGAT4A",
    "gene-MGAT4B",
    "gene-MGAT4C",
    "gene-MGAT4D",
    "gene-MGAT5",
    "gene-MGAT5B",
    "gene-MGLL",
    "gene-MGME1",
    "gene-MGMT",
    "gene-MGP",
    "gene-MGRN1",
    "gene-MGST1",
    "gene-MGST2",
    "gene-MGST3",
    "gene-MIA",
    "gene-MIA2",
    "gene-MIA3",
    "gene-MIB1",
    "gene-MIB2",
    "gene-MICA",
    "gene-MICAL1",
    "gene-MICAL2",
    "gene-MICAL3",
    "gene-MICALL1",
    "gene-MICALL2",
    "gene-MICB",
    "gene-MICOS10",
    "gene-MICOS13",
    "gene-MICU1",
    "gene-MICU2",
    "gene-MICU3",
    "gene-MID1",
    "gene-MID1IP1",
    "gene-MID2",
    "gene-MIDEAS",
    "gene-MIDN",
    "gene-MIEF1",
    "gene-MIEF2",
    "gene-MIEN1",
    "gene-MIER1",
    "gene-MIER2",
    "gene-MIER3",
    "gene-MIF",
    "gene-MIF4GD",
    "gene-MIGA1",
    "gene-MIGA2",
    "gene-MIIP",
    "gene-MILR1",
    "gene-MINAR1",
    "gene-MINAR2",
    "gene-MINDY1",
    "gene-MINDY2",
    "gene-MINDY3",
    "gene-MINDY4",
    "gene-MINDY4B",
    "gene-MINK1",
    "gene-MINPP1",
    "gene-MIOS",
    "gene-MIOX",
    "gene-MIP",
    "gene-MIPEP",
    "gene-MIPOL1",
    "gene-MIS12",
    "gene-MIS18A",
    "gene-MIS18BP1",
    "gene-MISFA",
    "gene-MISP",
    "gene-MISP3",
    "gene-MITD1",
    "gene-MITF",
    "gene-MIX23",
    "gene-MIXL1",
    "gene-MKI67",
    "gene-MKKS",
    "gene-MKLN1",
    "gene-MKNK1",
    "gene-MKNK2",
    "gene-MKRN1",
    "gene-MKRN2",
    "gene-MKRN2OS",
    "gene-MKRN3",
    "gene-MKS1",
    "gene-MKX",
    "gene-MLANA",
    "gene-MLC1",
    "gene-MLEC",
    "gene-MLF1",
    "gene-MLF2",
    "gene-MLH1",
    "gene-MLH3",
    "gene-MLIP",
    "gene-MLKL",
    "gene-MLLT1",
    "gene-MLLT10",
    "gene-MLLT11",
    "gene-MLLT3",
    "gene-MLLT6",
    "gene-MLN",
    "gene-MLNR",
    "gene-MLPH",
    "gene-MLST8",
    "gene-MLX",
    "gene-MLXIP",
    "gene-MLXIPL",
    "gene-MLYCD",
    "gene-MMAA",
    "gene-MMAB",
    "gene-MMACHC",
    "gene-MMADHC",
    "gene-MMD",
    "gene-MMD2",
    "gene-MME",
    "gene-MMEL1",
    "gene-MMGT1",
    "gene-MMP1",
    "gene-MMP10",
    "gene-MMP11",
    "gene-MMP12",
    "gene-MMP13",
    "gene-MMP14",
    "gene-MMP15",
    "gene-MMP16",
    "gene-MMP17",
    "gene-MMP19",
    "gene-MMP2",
    "gene-MMP20",
    "gene-MMP21",
    "gene-MMP23B",
    "gene-MMP24",
    "gene-MMP24OS",
    "gene-MMP25",
    "gene-MMP26",
    "gene-MMP27",
    "gene-MMP28",
    "gene-MMP3",
    "gene-MMP7",
    "gene-MMP8",
    "gene-MMP9",
    "gene-MMRN1",
    "gene-MMRN2",
    "gene-MMS19",
    "gene-MMS22L",
    "gene-MMUT",
    "gene-MN1",
    "gene-MNAT1",
    "gene-MND1",
    "gene-MNDA",
    "gene-MNS1",
    "gene-MNT",
    "gene-MNX1",
    "gene-MOAP1",
    "gene-MOB1A",
    "gene-MOB1B",
    "gene-MOB2",
    "gene-MOB3A",
    "gene-MOB3B",
    "gene-MOB3C",
    "gene-MOB4",
    "gene-MOBP",
    "gene-MOCOS",
    "gene-MOCS1",
    "gene-MOCS2",
    "gene-MOCS3",
    "gene-MOG",
    "gene-MOGAT1",
    "gene-MOGAT2",
    "gene-MOGAT3",
    "gene-MOGS",
    "gene-MOK",
    "gene-MON1A",
    "gene-MON1B",
    "gene-MON2",
    "gene-MORC1",
    "gene-MORC2",
    "gene-MORC3",
    "gene-MORC4",
    "gene-MORF4L1",
    "gene-MORF4L2",
    "gene-MORN1",
    "gene-MORN2",
    "gene-MORN3",
    "gene-MORN4",
    "gene-MORN5",
    "gene-MOS",
    "gene-MOSMO",
    "gene-MOSPD1",
    "gene-MOSPD2",
    "gene-MOSPD3",
    "gene-MOV10",
    "gene-MOV10L1",
    "gene-MOXD1",
    "gene-MPC1",
    "gene-MPC1L",
    "gene-MPC2",
    "gene-MPDU1",
    "gene-MPDZ",
    "gene-MPEG1",
    "gene-MPG",
    "gene-MPHOSPH10",
    "gene-MPHOSPH6",
    "gene-MPHOSPH8",
    "gene-MPHOSPH9",
    "gene-MPI",
    "gene-MPIG6B",
    "gene-MPL",
    "gene-MPLKIP",
    "gene-MPND",
    "gene-MPO",
    "gene-MPP1",
    "gene-MPP2",
    "gene-MPP3",
    "gene-MPP4",
    "gene-MPP7",
    "gene-MPPE1",
    "gene-MPPED1",
    "gene-MPPED2",
    "gene-MPRIP",
    "gene-MPST",
    "gene-MPV17",
    "gene-MPV17L",
    "gene-MPV17L2",
    "gene-MPZ",
    "gene-MPZL1",
    "gene-MPZL2",
    "gene-MPZL3",
    "gene-MR1",
    "gene-MRAP",
    "gene-MRAP2",
    "gene-MRAS",
    "gene-MRC1",
    "gene-MRC2",
    "gene-MRE11",
    "gene-MREG",
    "gene-MRFAP1",
    "gene-MRFAP1L1",
    "gene-MRFAP1L2",
    "gene-MRGBP",
    "gene-MRGPRD",
    "gene-MRGPRE",
    "gene-MRGPRF",
    "gene-MRGPRG",
    "gene-MRGPRX1",
    "gene-MRGPRX2",
    "gene-MRGPRX3",
    "gene-MRGPRX4",
    "gene-MRI1",
    "gene-MRLN",
    "gene-MRM1",
    "gene-MRM2",
    "gene-MRM3",
    "gene-MRNIP",
    "gene-MRO",
    "gene-MROH1",
    "gene-MROH2A",
    "gene-MROH2B",
    "gene-MROH5",
    "gene-MROH6",
    "gene-MROH7",
    "gene-MROH8",
    "gene-MROH9",
    "gene-MRPL1",
    "gene-MRPL10",
    "gene-MRPL11",
    "gene-MRPL12",
    "gene-MRPL13",
    "gene-MRPL14",
    "gene-MRPL15",
    "gene-MRPL16",
    "gene-MRPL17",
    "gene-MRPL18",
    "gene-MRPL19",
    "gene-MRPL2",
    "gene-MRPL20",
    "gene-MRPL21",
    "gene-MRPL22",
    "gene-MRPL23",
    "gene-MRPL24",
    "gene-MRPL27",
    "gene-MRPL28",
    "gene-MRPL3",
    "gene-MRPL30",
    "gene-MRPL32",
    "gene-MRPL33",
    "gene-MRPL34",
    "gene-MRPL35",
    "gene-MRPL36",
    "gene-MRPL37",
    "gene-MRPL38",
    "gene-MRPL39",
    "gene-MRPL4",
    "gene-MRPL40",
    "gene-MRPL41",
    "gene-MRPL42",
    "gene-MRPL43",
    "gene-MRPL44",
    "gene-MRPL45",
    "gene-MRPL46",
    "gene-MRPL47",
    "gene-MRPL48",
    "gene-MRPL49",
    "gene-MRPL50",
    "gene-MRPL51",
    "gene-MRPL52",
    "gene-MRPL53",
    "gene-MRPL54",
    "gene-MRPL55",
    "gene-MRPL57",
    "gene-MRPL58",
    "gene-MRPL9",
    "gene-MRPS10",
    "gene-MRPS11",
    "gene-MRPS12",
    "gene-MRPS14",
    "gene-MRPS15",
    "gene-MRPS16",
    "gene-MRPS17",
    "gene-MRPS18A",
    "gene-MRPS18B",
    "gene-MRPS18C",
    "gene-MRPS2",
    "gene-MRPS21",
    "gene-MRPS22",
    "gene-MRPS23",
    "gene-MRPS24",
    "gene-MRPS25",
    "gene-MRPS26",
    "gene-MRPS27",
    "gene-MRPS28",
    "gene-MRPS30",
    "gene-MRPS31",
    "gene-MRPS33",
    "gene-MRPS34",
    "gene-MRPS35",
    "gene-MRPS5",
    "gene-MRPS6",
    "gene-MRPS7",
    "gene-MRPS9",
    "gene-MRRF",
    "gene-MRS2",
    "gene-MRTFA",
    "gene-MRTFB",
    "gene-MRTO4",
    "gene-MS4A1",
    "gene-MS4A10",
    "gene-MS4A12",
    "gene-MS4A13",
    "gene-MS4A14",
    "gene-MS4A15",
    "gene-MS4A18",
    "gene-MS4A2",
    "gene-MS4A3",
    "gene-MS4A4A",
    "gene-MS4A4E",
    "gene-MS4A5",
    "gene-MS4A6A",
    "gene-MS4A6E",
    "gene-MS4A7",
    "gene-MS4A8",
    "gene-MSANTD1",
    "gene-MSANTD2",
    "gene-MSANTD3",
    "gene-MSANTD4",
    "gene-MSANTD5",
    "gene-MSANTD7",
    "gene-MSC",
    "gene-MSGN1",
    "gene-MSH2",
    "gene-MSH3",
    "gene-MSH4",
    "gene-MSH5",
    "gene-MSH6",
    "gene-MSI1",
    "gene-MSI2",
    "gene-MSL1",
    "gene-MSL2",
    "gene-MSL3",
    "gene-MSLN",
    "gene-MSMB",
    "gene-MSMO1",
    "gene-MSMP",
    "gene-MSN",
    "gene-MSR1",
    "gene-MSRA",
    "gene-MSRB1",
    "gene-MSRB2",
    "gene-MSRB3",
    "gene-MSS51",
    "gene-MST1",
    "gene-MST1R",
    "gene-MSTN",
    "gene-MSTO1",
    "gene-MSX1",
    "gene-MSX2",
    "gene-MT-ATP6",
    "gene-MT-ATP8",
    "gene-MT-CO1",
    "gene-MT-CO2",
    "gene-MT-CO3",
    "gene-MT-CYB",
    "gene-MT-ND1",
    "gene-MT-ND2",
    "gene-MT-ND3",
    "gene-MT-ND4",
    "gene-MT-ND4L",
    "gene-MT-ND5",
    "gene-MT-ND6",
    "gene-MT1A",
    "gene-MT1B",
    "gene-MT1E",
    "gene-MT1F",
    "gene-MT1G",
    "gene-MT1H",
    "gene-MT1HL1",
    "gene-MT1M",
    "gene-MT1X",
    "gene-MT2A",
    "gene-MT3",
    "gene-MT4",
    "gene-MTA1",
    "gene-MTA2",
    "gene-MTA3",
    "gene-MTAP",
    "gene-MTARC1",
    "gene-MTARC2",
    "gene-MTBP",
    "gene-MTCH1",
    "gene-MTCH2",
    "gene-MTCL1",
    "gene-MTCL2",
    "gene-MTCL3",
    "gene-MTCP1",
    "gene-MTDH",
    "gene-MTERF1",
    "gene-MTERF2",
    "gene-MTERF3",
    "gene-MTERF4",
    "gene-MTF1",
    "gene-MTF2",
    "gene-MTFMT",
    "gene-MTFP1",
    "gene-MTFR1",
    "gene-MTFR1L",
    "gene-MTFR2",
    "gene-MTG1",
    "gene-MTG2",
    "gene-MTHFD1",
    "gene-MTHFD1L",
    "gene-MTHFD2",
    "gene-MTHFD2L",
    "gene-MTHFR",
    "gene-MTHFS",
    "gene-MTHFSD",
    "gene-MTIF2",
    "gene-MTIF3",
    "gene-MTLN",
    "gene-MTM1",
    "gene-MTMR1",
    "gene-MTMR10",
    "gene-MTMR11",
    "gene-MTMR12",
    "gene-MTMR14",
    "gene-MTMR2",
    "gene-MTMR3",
    "gene-MTMR4",
    "gene-MTMR6",
    "gene-MTMR7",
    "gene-MTMR8",
    "gene-MTMR9",
    "gene-MTNR1A",
    "gene-MTNR1B",
    "gene-MTO1",
    "gene-MTOR",
    "gene-MTPAP",
    "gene-MTPN",
    "gene-MTR",
    "gene-MTRES1",
    "gene-MTREX",
    "gene-MTRF1",
    "gene-MTRF1L",
    "gene-MTRFR",
    "gene-MTRR",
    "gene-MTSS1",
    "gene-MTSS2",
    "gene-MTTP",
    "gene-MTURN",
    "gene-MTUS1",
    "gene-MTUS2",
    "gene-MTX1",
    "gene-MTX2",
    "gene-MTX3",
    "gene-MUC1",
    "gene-MUC12",
    "gene-MUC13",
    "gene-MUC15",
    "gene-MUC16",
    "gene-MUC17",
    "gene-MUC19",
    "gene-MUC2",
    "gene-MUC20",
    "gene-MUC21",
    "gene-MUC22",
    "gene-MUC3A",
    "gene-MUC3B",
    "gene-MUC4",
    "gene-MUC5AC",
    "gene-MUC5B",
    "gene-MUC6",
    "gene-MUC7",
    "gene-MUC8",
    "gene-MUCL1",
    "gene-MUCL3",
    "gene-MUL1",
    "gene-MUS81",
    "gene-MUSK",
    "gene-MUSTN1",
    "gene-MUTYH",
    "gene-MVB12A",
    "gene-MVB12B",
    "gene-MVD",
    "gene-MVK",
    "gene-MVP",
    "gene-MX1",
    "gene-MX2",
    "gene-MXD1",
    "gene-MXD3",
    "gene-MXD4",
    "gene-MXI1",
    "gene-MXRA5",
    "gene-MXRA7",
    "gene-MXRA8",
    "gene-MYADM",
    "gene-MYADML2",
    "gene-MYB",
    "gene-MYBBP1A",
    "gene-MYBL1",
    "gene-MYBL2",
    "gene-MYBPC1",
    "gene-MYBPC2",
    "gene-MYBPC3",
    "gene-MYBPH",
    "gene-MYBPHL",
    "gene-MYC",
    "gene-MYCBP",
    "gene-MYCBP2",
    "gene-MYCBPAP",
    "gene-MYCL",
    "gene-MYCN",
    "gene-MYCT1",
    "gene-MYD88",
    "gene-MYDGF",
    "gene-MYEF2",
    "gene-MYEOV",
    "gene-MYF5",
    "gene-MYF6",
    "gene-MYG1",
    "gene-MYH1",
    "gene-MYH10",
    "gene-MYH11",
    "gene-MYH13",
    "gene-MYH14",
    "gene-MYH15",
    "gene-MYH2",
    "gene-MYH3",
    "gene-MYH4",
    "gene-MYH6",
    "gene-MYH7",
    "gene-MYH7B",
    "gene-MYH8",
    "gene-MYH9",
    "gene-MYL1",
    "gene-MYL10",
    "gene-MYL11",
    "gene-MYL12A",
    "gene-MYL12B",
    "gene-MYL2",
    "gene-MYL3",
    "gene-MYL4",
    "gene-MYL5",
    "gene-MYL6",
    "gene-MYL6B",
    "gene-MYL7",
    "gene-MYL9",
    "gene-MYLIP",
    "gene-MYLK",
    "gene-MYLK2",
    "gene-MYLK3",
    "gene-MYLK4",
    "gene-MYMK",
    "gene-MYMX",
    "gene-MYNN",
    "gene-MYO10",
    "gene-MYO15A",
    "gene-MYO15B",
    "gene-MYO16",
    "gene-MYO18A",
    "gene-MYO18B",
    "gene-MYO19",
    "gene-MYO1A",
    "gene-MYO1B",
    "gene-MYO1C",
    "gene-MYO1D",
    "gene-MYO1E",
    "gene-MYO1F",
    "gene-MYO1G",
    "gene-MYO1H",
    "gene-MYO3A",
    "gene-MYO3B",
    "gene-MYO5A",
    "gene-MYO5B",
    "gene-MYO5C",
    "gene-MYO6",
    "gene-MYO7A",
    "gene-MYO7B",
    "gene-MYO9A",
    "gene-MYO9B",
    "gene-MYOC",
    "gene-MYOCD",
    "gene-MYOCOS",
    "gene-MYOD1",
    "gene-MYOF",
    "gene-MYOG",
    "gene-MYOM1",
    "gene-MYOM2",
    "gene-MYOM3",
    "gene-MYORG",
    "gene-MYOT",
    "gene-MYOZ1",
    "gene-MYOZ2",
    "gene-MYOZ3",
    "gene-MYPN",
    "gene-MYPOP",
    "gene-MYRF",
    "gene-MYRFL",
    "gene-MYRIP",
    "gene-MYSM1",
    "gene-MYT1",
    "gene-MYT1L",
    "gene-MYZAP",
    "gene-MZB1",
    "gene-MZF1",
    "gene-MZT1",
    "gene-MZT2A",
    "gene-MZT2B",
    "gene-N4BP1",
    "gene-N4BP2",
    "gene-N4BP2L1",
    "gene-N4BP2L2",
    "gene-N4BP3",
    "gene-N6AMT1",
    "gene-NAA10",
    "gene-NAA11",
    "gene-NAA15",
    "gene-NAA16",
    "gene-NAA20",
    "gene-NAA25",
    "gene-NAA30",
    "gene-NAA35",
    "gene-NAA38",
    "gene-NAA40",
    "gene-NAA50",
    "gene-NAA60",
    "gene-NAA80",
    "gene-NAAA",
    "gene-NAALAD2",
    "gene-NAALADL1",
    "gene-NAALADL2",
    "gene-NAB1",
    "gene-NAB2",
    "gene-NABP1",
    "gene-NABP2",
    "gene-NACA",
    "gene-NACA2",
    "gene-NACAD",
    "gene-NACC1",
    "gene-NACC2",
    "gene-NADK",
    "gene-NADK2",
    "gene-NADSYN1",
    "gene-NAE1",
    "gene-NAF1",
    "gene-NAGA",
    "gene-NAGK",
    "gene-NAGLU",
    "gene-NAGPA",
    "gene-NAGS",
    "gene-NAIF1",
    "gene-NAIP",
    "gene-NALCN",
    "gene-NALF1",
    "gene-NALF2",
    "gene-NAMPT",
    "gene-NANOG",
    "gene-NANOGNB",
    "gene-NANOGP8",
    "gene-NANOS1",
    "gene-NANOS2",
    "gene-NANOS3",
    "gene-NANP",
    "gene-NANS",
    "gene-NAP1L1",
    "gene-NAP1L2",
    "gene-NAP1L3",
    "gene-NAP1L4",
    "gene-NAP1L5",
    "gene-NAPA",
    "gene-NAPB",
    "gene-NAPEPLD",
    "gene-NAPG",
    "gene-NAPRT",
    "gene-NAPSA",
    "gene-NARF",
    "gene-NARS1",
    "gene-NARS2",
    "gene-NASP",
    "gene-NAT1",
    "gene-NAT10",
    "gene-NAT14",
    "gene-NAT16",
    "gene-NAT2",
    "gene-NAT8",
    "gene-NAT8B",
    "gene-NAT8L",
    "gene-NAT9",
    "gene-NATD1",
    "gene-NAV1",
    "gene-NAV2",
    "gene-NAV3",
    "gene-NAXD",
    "gene-NAXE",
    "gene-NBAS",
    "gene-NBDY",
    "gene-NBEA",
    "gene-NBEAL1",
    "gene-NBEAL2",
    "gene-NBL1",
    "gene-NBN",
    "gene-NBPF1",
    "gene-NBPF10",
    "gene-NBPF11",
    "gene-NBPF12",
    "gene-NBPF14",
    "gene-NBPF15",
    "gene-NBPF19",
    "gene-NBPF20",
    "gene-NBPF26",
    "gene-NBPF3",
    "gene-NBPF4",
    "gene-NBPF6",
    "gene-NBPF8",
    "gene-NBPF9",
    "gene-NBR1",
    "gene-NCALD",
    "gene-NCAM1",
    "gene-NCAM2",
    "gene-NCAN",
    "gene-NCAPD2",
    "gene-NCAPD3",
    "gene-NCAPG",
    "gene-NCAPG2",
    "gene-NCAPH",
    "gene-NCAPH2",
    "gene-NCBP1",
    "gene-NCBP2",
    "gene-NCBP2AS2",
    "gene-NCBP2L",
    "gene-NCBP3",
    "gene-NCCRP1",
    "gene-NCDN",
    "gene-NCEH1",
    "gene-NCF1",
    "gene-NCF2",
    "gene-NCF4",
    "gene-NCK1",
    "gene-NCK2",
    "gene-NCKAP1",
    "gene-NCKAP1L",
    "gene-NCKAP5",
    "gene-NCKAP5L",
    "gene-NCKIPSD",
    "gene-NCL",
    "gene-NCLN",
    "gene-NCMAP",
    "gene-NCOA1",
    "gene-NCOA2",
    "gene-NCOA3",
    "gene-NCOA4",
    "gene-NCOA5",
    "gene-NCOA6",
    "gene-NCOA7",
    "gene-NCOR1",
    "gene-NCOR2",
    "gene-NCR1",
    "gene-NCR2",
    "gene-NCR3",
    "gene-NCR3LG1",
    "gene-NCS1",
    "gene-NCSTN",
    "gene-NDC1",
    "gene-NDC80",
    "gene-NDE1",
    "gene-NDEL1",
    "gene-NDFIP1",
    "gene-NDFIP2",
    "gene-NDN",
    "gene-NDNF",
    "gene-NDOR1",
    "gene-NDP",
    "gene-NDRG1",
    "gene-NDRG2",
    "gene-NDRG3",
    "gene-NDRG4",
    "gene-NDST1",
    "gene-NDST2",
    "gene-NDST3",
    "gene-NDST4",
    "gene-NDUFA1",
    "gene-NDUFA10",
    "gene-NDUFA11",
    "gene-NDUFA12",
    "gene-NDUFA13",
    "gene-NDUFA2",
    "gene-NDUFA3",
    "gene-NDUFA4",
    "gene-NDUFA4L2",
    "gene-NDUFA5",
    "gene-NDUFA6",
    "gene-NDUFA7",
    "gene-NDUFA8",
    "gene-NDUFA9",
    "gene-NDUFAB1",
    "gene-NDUFAF1",
    "gene-NDUFAF2",
    "gene-NDUFAF3",
    "gene-NDUFAF4",
    "gene-NDUFAF5",
    "gene-NDUFAF6",
    "gene-NDUFAF7",
    "gene-NDUFAF8",
    "gene-NDUFB1",
    "gene-NDUFB10",
    "gene-NDUFB11",
    "gene-NDUFB2",
    "gene-NDUFB3",
    "gene-NDUFB4",
    "gene-NDUFB5",
    "gene-NDUFB6",
    "gene-NDUFB7",
    "gene-NDUFB8",
    "gene-NDUFB9",
    "gene-NDUFC1",
    "gene-NDUFC2",
    "gene-NDUFS1",
    "gene-NDUFS2",
    "gene-NDUFS3",
    "gene-NDUFS4",
    "gene-NDUFS5",
    "gene-NDUFS6",
    "gene-NDUFS7",
    "gene-NDUFS8",
    "gene-NDUFV1",
    "gene-NDUFV2",
    "gene-NDUFV3",
    "gene-NEB",
    "gene-NEBL",
    "gene-NECAB1",
    "gene-NECAB2",
    "gene-NECAB3",
    "gene-NECAP1",
    "gene-NECAP2",
    "gene-NECTIN1",
    "gene-NECTIN2",
    "gene-NECTIN3",
    "gene-NECTIN4",
    "gene-NEDD1",
    "gene-NEDD4",
    "gene-NEDD4L",
    "gene-NEDD8",
    "gene-NEDD9",
    "gene-NEFH",
    "gene-NEFL",
    "gene-NEFM",
    "gene-NEGR1",
    "gene-NEIL1",
    "gene-NEIL2",
    "gene-NEIL3",
    "gene-NEK1",
    "gene-NEK10",
    "gene-NEK11",
    "gene-NEK2",
    "gene-NEK3",
    "gene-NEK4",
    "gene-NEK5",
    "gene-NEK6",
    "gene-NEK7",
    "gene-NEK8",
    "gene-NEK9",
    "gene-NELFA",
    "gene-NELFB",
    "gene-NELFCD",
    "gene-NELFE",
    "gene-NELL1",
    "gene-NELL2",
    "gene-NEMF",
    "gene-NEMP1",
    "gene-NEMP2",
    "gene-NENF",
    "gene-NEO1",
    "gene-NEPRO",
    "gene-NES",
    "gene-NET1",
    "gene-NETO1",
    "gene-NETO2",
    "gene-NEU1",
    "gene-NEU2",
    "gene-NEU3",
    "gene-NEU4",
    "gene-NEURL1",
    "gene-NEURL1B",
    "gene-NEURL2",
    "gene-NEURL3",
    "gene-NEURL4",
    "gene-NEUROD1",
    "gene-NEUROD2",
    "gene-NEUROD4",
    "gene-NEUROD6",
    "gene-NEUROG1",
    "gene-NEUROG2",
    "gene-NEUROG3",
    "gene-NEXMIF",
    "gene-NEXN",
    "gene-NF1",
    "gene-NF2",
    "gene-NFAM1",
    "gene-NFASC",
    "gene-NFAT5",
    "gene-NFATC1",
    "gene-NFATC2",
    "gene-NFATC2IP",
    "gene-NFATC3",
    "gene-NFATC4",
    "gene-NFE2",
    "gene-NFE2L1",
    "gene-NFE2L2",
    "gene-NFE2L3",
    "gene-NFIA",
    "gene-NFIB",
    "gene-NFIC",
    "gene-NFIL3",
    "gene-NFILZ",
    "gene-NFIX",
    "gene-NFKB1",
    "gene-NFKB2",
    "gene-NFKBIA",
    "gene-NFKBIB",
    "gene-NFKBID",
    "gene-NFKBIE",
    "gene-NFKBIL1",
    "gene-NFKBIZ",
    "gene-NFRKB",
    "gene-NFS1",
    "gene-NFU1",
    "gene-NFX1",
    "gene-NFXL1",
    "gene-NFYA",
    "gene-NFYB",
    "gene-NFYC",
    "gene-NGB",
    "gene-NGDN",
    "gene-NGEF",
    "gene-NGF",
    "gene-NGFR",
    "gene-NGLY1",
    "gene-NGRN",
    "gene-NHEJ1",
    "gene-NHERF1",
    "gene-NHERF2",
    "gene-NHERF4",
    "gene-NHLH1",
    "gene-NHLH2",
    "gene-NHLRC1",
    "gene-NHLRC2",
    "gene-NHLRC3",
    "gene-NHLRC4",
    "gene-NHP2",
    "gene-NHS",
    "gene-NHSL1",
    "gene-NHSL2",
    "gene-NHSL3",
    "gene-NIBAN1",
    "gene-NIBAN2",
    "gene-NIBAN3",
    "gene-NICN1",
    "gene-NICOL1",
    "gene-NID1",
    "gene-NID2",
    "gene-NIF3L1",
    "gene-NIFK",
    "gene-NIM1K",
    "gene-NIN",
    "gene-NINJ1",
    "gene-NINJ2",
    "gene-NINL",
    "gene-NIP7",
    "gene-NIPA1",
    "gene-NIPA2",
    "gene-NIPAL1",
    "gene-NIPAL2",
    "gene-NIPAL3",
    "gene-NIPAL4",
    "gene-NIPBL",
    "gene-NIPSNAP1",
    "gene-NIPSNAP2",
    "gene-NIPSNAP3A",
    "gene-NIPSNAP3B",
    "gene-NISCH",
    "gene-NIT1",
    "gene-NIT2",
    "gene-NKAIN1",
    "gene-NKAIN2",
    "gene-NKAIN3",
    "gene-NKAIN4",
    "gene-NKAP",
    "gene-NKAPD1",
    "gene-NKAPL",
    "gene-NKD1",
    "gene-NKD2",
    "gene-NKG7",
    "gene-NKIRAS1",
    "gene-NKIRAS2",
    "gene-NKPD1",
    "gene-NKRF",
    "gene-NKTR",
    "gene-NKX1-1",
    "gene-NKX1-2",
    "gene-NKX2-1",
    "gene-NKX2-2",
    "gene-NKX2-3",
    "gene-NKX2-4",
    "gene-NKX2-5",
    "gene-NKX2-6",
    "gene-NKX2-8",
    "gene-NKX3-1",
    "gene-NKX3-2",
    "gene-NKX6-1",
    "gene-NKX6-2",
    "gene-NKX6-3",
    "gene-NLE1",
    "gene-NLGN1",
    "gene-NLGN2",
    "gene-NLGN3",
    "gene-NLGN4X",
    "gene-NLGN4Y",
    "gene-NLK",
    "gene-NLN",
    "gene-NLRC3",
    "gene-NLRC4",
    "gene-NLRC5",
    "gene-NLRP1",
    "gene-NLRP10",
    "gene-NLRP11",
    "gene-NLRP12",
    "gene-NLRP13",
    "gene-NLRP14",
    "gene-NLRP2",
    "gene-NLRP2B",
    "gene-NLRP3",
    "gene-NLRP4",
    "gene-NLRP5",
    "gene-NLRP6",
    "gene-NLRP7",
    "gene-NLRP8",
    "gene-NLRP9",
    "gene-NLRX1",
    "gene-NMB",
    "gene-NMBR",
    "gene-NMD3",
    "gene-NME1",
    "gene-NME2",
    "gene-NME3",
    "gene-NME4",
    "gene-NME5",
    "gene-NME6",
    "gene-NME7",
    "gene-NME8",
    "gene-NME9",
    "gene-NMI",
    "gene-NMNAT1",
    "gene-NMNAT2",
    "gene-NMNAT3",
    "gene-NMRAL1",
    "gene-NMRK1",
    "gene-NMRK2",
    "gene-NMS",
    "gene-NMT1",
    "gene-NMT2",
    "gene-NMU",
    "gene-NMUR1",
    "gene-NMUR2",
    "gene-NNAT",
    "gene-NNMT",
    "gene-NNT",
    "gene-NOA1",
    "gene-NOB1",
    "gene-NOBOX",
    "gene-NOC2L",
    "gene-NOC3L",
    "gene-NOC4L",
    "gene-NOCT",
    "gene-NOD1",
    "gene-NOD2",
    "gene-NODAL",
    "gene-NOG",
    "gene-NOL10",
    "gene-NOL11",
    "gene-NOL12",
    "gene-NOL3",
    "gene-NOL4",
    "gene-NOL4L",
    "gene-NOL6",
    "gene-NOL7",
    "gene-NOL8",
    "gene-NOL9",
    "gene-NOLC1",
    "gene-NOM1",
    "gene-NOMO1",
    "gene-NOMO2",
    "gene-NOMO3",
    "gene-NONO",
    "gene-NOP10",
    "gene-NOP14",
    "gene-NOP16",
    "gene-NOP2",
    "gene-NOP53",
    "gene-NOP56",
    "gene-NOP58",
    "gene-NOP9",
    "gene-NOPCHAP1",
    "gene-NOS1",
    "gene-NOS1AP",
    "gene-NOS2",
    "gene-NOS3",
    "gene-NOSIP",
    "gene-NOSTRIN",
    "gene-NOTCH1",
    "gene-NOTCH2",
    "gene-NOTCH2NLA",
    "gene-NOTCH2NLB",
    "gene-NOTCH2NLC",
    "gene-NOTCH2NLR",
    "gene-NOTCH3",
    "gene-NOTCH4",
    "gene-NOTO",
    "gene-NOTUM",
    "gene-NOVA1",
    "gene-NOVA2",
    "gene-NOX1",
    "gene-NOX3",
    "gene-NOX4",
    "gene-NOX5",
    "gene-NOXA1",
    "gene-NOXO1",
    "gene-NOXRED1",
    "gene-NPAP1",
    "gene-NPAP1L",
    "gene-NPAS1",
    "gene-NPAS2",
    "gene-NPAS3",
    "gene-NPAS4",
    "gene-NPAT",
    "gene-NPB",
    "gene-NPBWR1",
    "gene-NPBWR2",
    "gene-NPC1",
    "gene-NPC1L1",
    "gene-NPC2",
    "gene-NPDC1",
    "gene-NPEPL1",
    "gene-NPEPPS",
    "gene-NPFF",
    "gene-NPFFR1",
    "gene-NPFFR2",
    "gene-NPHP1",
    "gene-NPHP3",
    "gene-NPHP4",
    "gene-NPHS1",
    "gene-NPHS2",
    "gene-NPIPA1",
    "gene-NPIPA2",
    "gene-NPIPA3",
    "gene-NPIPA5",
    "gene-NPIPA7",
    "gene-NPIPA8",
    "gene-NPIPA9",
    "gene-NPIPB11",
    "gene-NPIPB12",
    "gene-NPIPB13",
    "gene-NPIPB15",
    "gene-NPIPB2",
    "gene-NPIPB3",
    "gene-NPIPB4",
    "gene-NPIPB5",
    "gene-NPIPB6",
    "gene-NPIPB7",
    "gene-NPIPB8",
    "gene-NPIPB9",
    "gene-NPL",
    "gene-NPLOC4",
    "gene-NPM1",
    "gene-NPM2",
    "gene-NPM3",
    "gene-NPNT",
    "gene-NPPA",
    "gene-NPPB",
    "gene-NPPC",
    "gene-NPR1",
    "gene-NPR2",
    "gene-NPR3",
    "gene-NPRL2",
    "gene-NPRL3",
    "gene-NPS",
    "gene-NPSR1",
    "gene-NPTN",
    "gene-NPTX1",
    "gene-NPTX2",
    "gene-NPTXR",
    "gene-NPVF",
    "gene-NPW",
    "gene-NPY",
    "gene-NPY1R",
    "gene-NPY2R",
    "gene-NPY4R",
    "gene-NPY4R2",
    "gene-NPY5R",
    "gene-NQO1",
    "gene-NQO2",
    "gene-NR0B1",
    "gene-NR0B2",
    "gene-NR1D1",
    "gene-NR1D2",
    "gene-NR1H2",
    "gene-NR1H3",
    "gene-NR1H4",
    "gene-NR1I2",
    "gene-NR1I3",
    "gene-NR2C1",
    "gene-NR2C2",
    "gene-NR2C2AP",
    "gene-NR2E1",
    "gene-NR2E3",
    "gene-NR2F1",
    "gene-NR2F2",
    "gene-NR2F6",
    "gene-NR3C1",
    "gene-NR3C2",
    "gene-NR4A1",
    "gene-NR4A2",
    "gene-NR4A3",
    "gene-NR5A1",
    "gene-NR5A2",
    "gene-NR6A1",
    "gene-NRAP",
    "gene-NRARP",
    "gene-NRAS",
    "gene-NRBF2",
    "gene-NRBP1",
    "gene-NRBP2",
    "gene-NRCAM",
    "gene-NRDC",
    "gene-NRDE2",
    "gene-NREP",
    "gene-NRF1",
    "gene-NRG1",
    "gene-NRG2",
    "gene-NRG3",
    "gene-NRG4",
    "gene-NRGN",
    "gene-NRIP1",
    "gene-NRIP2",
    "gene-NRIP3",
    "gene-NRK",
    "gene-NRL",
    "gene-NRM",
    "gene-NRN1",
    "gene-NRN1L",
    "gene-NRP1",
    "gene-NRP2",
    "gene-NRROS",
    "gene-NRSN1",
    "gene-NRSN2",
    "gene-NRTN",
    "gene-NRXN1",
    "gene-NRXN2",
    "gene-NRXN3",
    "gene-NSA2",
    "gene-NSD1",
    "gene-NSD2",
    "gene-NSD3",
    "gene-NSDHL",
    "gene-NSF",
    "gene-NSFL1C",
    "gene-NSG1",
    "gene-NSG2",
    "gene-NSL1",
    "gene-NSMAF",
    "gene-NSMCE1",
    "gene-NSMCE2",
    "gene-NSMCE3",
    "gene-NSMCE4A",
    "gene-NSMF",
    "gene-NSRP1",
    "gene-NSUN2",
    "gene-NSUN3",
    "gene-NSUN4",
    "gene-NSUN5",
    "gene-NSUN6",
    "gene-NSUN7",
    "gene-NT5C",
    "gene-NT5C1A",
    "gene-NT5C1B",
    "gene-NT5C2",
    "gene-NT5C3A",
    "gene-NT5C3B",
    "gene-NT5DC1",
    "gene-NT5DC2",
    "gene-NT5DC3",
    "gene-NT5DC4",
    "gene-NT5E",
    "gene-NT5M",
    "gene-NTAN1",
    "gene-NTAQ1",
    "gene-NTF3",
    "gene-NTF4",
    "gene-NTHL1",
    "gene-NTM",
    "gene-NTMT1",
    "gene-NTMT2",
    "gene-NTN1",
    "gene-NTN3",
    "gene-NTN4",
    "gene-NTN5",
    "gene-NTNG1",
    "gene-NTNG2",
    "gene-NTPCR",
    "gene-NTRK1",
    "gene-NTRK2",
    "gene-NTRK3",
    "gene-NTS",
    "gene-NTSR1",
    "gene-NTSR2",
    "gene-NUAK1",
    "gene-NUAK2",
    "gene-NUB1",
    "gene-NUBP1",
    "gene-NUBP2",
    "gene-NUBPL",
    "gene-NUCB1",
    "gene-NUCB2",
    "gene-NUCKS1",
    "gene-NUDC",
    "gene-NUDCD1",
    "gene-NUDCD2",
    "gene-NUDCD3",
    "gene-NUDT1",
    "gene-NUDT10",
    "gene-NUDT11",
    "gene-NUDT12",
    "gene-NUDT13",
    "gene-NUDT14",
    "gene-NUDT15",
    "gene-NUDT16",
    "gene-NUDT16L1",
    "gene-NUDT17",
    "gene-NUDT18",
    "gene-NUDT19",
    "gene-NUDT2",
    "gene-NUDT21",
    "gene-NUDT22",
    "gene-NUDT3",
    "gene-NUDT4",
    "gene-NUDT4B",
    "gene-NUDT5",
    "gene-NUDT6",
    "gene-NUDT7",
    "gene-NUDT8",
    "gene-NUDT9",
    "gene-NUF2",
    "gene-NUFIP1",
    "gene-NUFIP2",
    "gene-NUGGC",
    "gene-NUMA1",
    "gene-NUMB",
    "gene-NUMBL",
    "gene-NUP107",
    "gene-NUP133",
    "gene-NUP153",
    "gene-NUP155",
    "gene-NUP160",
    "gene-NUP188",
    "gene-NUP205",
    "gene-NUP210",
    "gene-NUP210L",
    "gene-NUP214",
    "gene-NUP35",
    "gene-NUP37",
    "gene-NUP42",
    "gene-NUP43",
    "gene-NUP50",
    "gene-NUP54",
    "gene-NUP58",
    "gene-NUP62",
    "gene-NUP62CL",
    "gene-NUP85",
    "gene-NUP88",
    "gene-NUP93",
    "gene-NUP98",
    "gene-NUPR1",
    "gene-NUPR2",
    "gene-NUS1",
    "gene-NUSAP1",
    "gene-NUTF2",
    "gene-NUTM1",
    "gene-NUTM2A",
    "gene-NUTM2B",
    "gene-NUTM2D",
    "gene-NUTM2E",
    "gene-NUTM2F",
    "gene-NUTM2G",
    "gene-NVL",
    "gene-NWD1",
    "gene-NWD2",
    "gene-NXF1",
    "gene-NXF2",
    "gene-NXF2B",
    "gene-NXF3",
    "gene-NXF5",
    "gene-NXN",
    "gene-NXNL1",
    "gene-NXNL2",
    "gene-NXPE1",
    "gene-NXPE2",
    "gene-NXPE3",
    "gene-NXPE4",
    "gene-NXPH1",
    "gene-NXPH2",
    "gene-NXPH3",
    "gene-NXPH4",
    "gene-NXT1",
    "gene-NXT2",
    "gene-NYAP1",
    "gene-NYAP2",
    "gene-NYNRIN",
    "gene-NYX",
    "gene-OAF",
    "gene-OARD1",
    "gene-OAS1",
    "gene-OAS2",
    "gene-OAS3",
    "gene-OASL",
    "gene-OAT",
    "gene-OAZ1",
    "gene-OAZ2",
    "gene-OAZ3",
    "gene-OBI1",
    "gene-OBP2A",
    "gene-OBP2B",
    "gene-OBSCN",
    "gene-OBSL1",
    "gene-OC90",
    "gene-OCA2",
    "gene-OCEL1",
    "gene-OCIAD1",
    "gene-OCIAD2",
    "gene-OCLN",
    "gene-OCM",
    "gene-OCM2",
    "gene-OCRL",
    "gene-OCSTAMP",
    "gene-ODAD1",
    "gene-ODAD2",
    "gene-ODAD3",
    "gene-ODAD4",
    "gene-ODAM",
    "gene-ODAPH",
    "gene-ODC1",
    "gene-ODF1",
    "gene-ODF2",
    "gene-ODF2L",
    "gene-ODF4",
    "gene-ODR4",
    "gene-OFD1",
    "gene-OGA",
    "gene-OGDH",
    "gene-OGDHL",
    "gene-OGFOD1",
    "gene-OGFOD2",
    "gene-OGFOD3",
    "gene-OGFR",
    "gene-OGFRL1",
    "gene-OGG1",
    "gene-OGN",
    "gene-OGT",
    "gene-OIP5",
    "gene-OIT3",
    "gene-OLA1",
    "gene-OLAH",
    "gene-OLFM1",
    "gene-OLFM2",
    "gene-OLFM3",
    "gene-OLFM4",
    "gene-OLFML1",
    "gene-OLFML2A",
    "gene-OLFML2B",
    "gene-OLFML3",
    "gene-OLIG1",
    "gene-OLIG2",
    "gene-OLIG3",
    "gene-OLR1",
    "gene-OMA1",
    "gene-OMD",
    "gene-OMG",
    "gene-OMP",
    "gene-ONECUT1",
    "gene-ONECUT2",
    "gene-ONECUT3",
    "gene-OOEP",
    "gene-OOSP1",
    "gene-OOSP2",
    "gene-OOSP3",
    "gene-OOSP4A",
    "gene-OOSP4B",
    "gene-OPA1",
    "gene-OPA3",
    "gene-OPALIN",
    "gene-OPCML",
    "gene-OPHN1",
    "gene-OPLAH",
    "gene-OPN1LW",
    "gene-OPN1MW",
    "gene-OPN1MW2",
    "gene-OPN1MW3",
    "gene-OPN1SW",
    "gene-OPN3",
    "gene-OPN4",
    "gene-OPN5",
    "gene-OPRD1",
    "gene-OPRK1",
    "gene-OPRL1",
    "gene-OPRM1",
    "gene-OPRPN",
    "gene-OPTC",
    "gene-OPTN",
    "gene-OR10A2",
    "gene-OR10A3",
    "gene-OR10A4",
    "gene-OR10A5",
    "gene-OR10A6",
    "gene-OR10A7",
    "gene-OR10AC1",
    "gene-OR10AD1",
    "gene-OR10AG1",
    "gene-OR10C1",
    "gene-OR10D3",
    "gene-OR10G2",
    "gene-OR10G3",
    "gene-OR10G4",
    "gene-OR10G6",
    "gene-OR10G7",
    "gene-OR10G8",
    "gene-OR10G9",
    "gene-OR10H1",
    "gene-OR10H2",
    "gene-OR10H3",
    "gene-OR10H4",
    "gene-OR10H5",
    "gene-OR10J1",
    "gene-OR10J3",
    "gene-OR10J4",
    "gene-OR10J5",
    "gene-OR10K1",
    "gene-OR10K2",
    "gene-OR10P1",
    "gene-OR10Q1",
    "gene-OR10R2",
    "gene-OR10S1",
    "gene-OR10T2",
    "gene-OR10V1",
    "gene-OR10W1",
    "gene-OR10X1",
    "gene-OR10Z1",
    "gene-OR11A1",
    "gene-OR11G2",
    "gene-OR11H1",
    "gene-OR11H12",
    "gene-OR11H2",
    "gene-OR11H4",
    "gene-OR11H6",
    "gene-OR11H7",
    "gene-OR11L1",
    "gene-OR12D1",
    "gene-OR12D2",
    "gene-OR12D3",
    "gene-OR13A1",
    "gene-OR13C2",
    "gene-OR13C3",
    "gene-OR13C4",
    "gene-OR13C5",
    "gene-OR13C7",
    "gene-OR13C8",
    "gene-OR13C9",
    "gene-OR13D1",
    "gene-OR13F1",
    "gene-OR13G1",
    "gene-OR13H1",
    "gene-OR13J1",
    "gene-OR14A16",
    "gene-OR14A2",
    "gene-OR14C36",
    "gene-OR14I1",
    "gene-OR14J1",
    "gene-OR14K1",
    "gene-OR14L1",
    "gene-OR1A1",
    "gene-OR1A2",
    "gene-OR1B1",
    "gene-OR1C1",
    "gene-OR1D2",
    "gene-OR1D4",
    "gene-OR1D5",
    "gene-OR1E1",
    "gene-OR1E2",
    "gene-OR1E3",
    "gene-OR1F1",
    "gene-OR1G1",
    "gene-OR1I1",
    "gene-OR1J1",
    "gene-OR1J2",
    "gene-OR1J4",
    "gene-OR1K1",
    "gene-OR1L1",
    "gene-OR1L3",
    "gene-OR1L4",
    "gene-OR1L6",
    "gene-OR1L8",
    "gene-OR1M1",
    "gene-OR1N1",
    "gene-OR1N2",
    "gene-OR1P1",
    "gene-OR1Q1",
    "gene-OR1S1",
    "gene-OR1S2",
    "gene-OR2A1",
    "gene-OR2A12",
    "gene-OR2A14",
    "gene-OR2A2",
    "gene-OR2A25",
    "gene-OR2A4",
    "gene-OR2A42",
    "gene-OR2A5",
    "gene-OR2A7",
    "gene-OR2AE1",
    "gene-OR2AG1",
    "gene-OR2AG2",
    "gene-OR2AJ1",
    "gene-OR2AK2",
    "gene-OR2AP1",
    "gene-OR2AT4",
    "gene-OR2B11",
    "gene-OR2B2",
    "gene-OR2B3",
    "gene-OR2B6",
    "gene-OR2C1",
    "gene-OR2C3",
    "gene-OR2D2",
    "gene-OR2D3",
    "gene-OR2F1",
    "gene-OR2F2",
    "gene-OR2G2",
    "gene-OR2G3",
    "gene-OR2G6",
    "gene-OR2H1",
    "gene-OR2H2",
    "gene-OR2J1",
    "gene-OR2J2",
    "gene-OR2J3",
    "gene-OR2K2",
    "gene-OR2L13",
    "gene-OR2L2",
    "gene-OR2L3",
    "gene-OR2L5",
    "gene-OR2L8",
    "gene-OR2M2",
    "gene-OR2M3",
    "gene-OR2M4",
    "gene-OR2M5",
    "gene-OR2M7",
    "gene-OR2S2",
    "gene-OR2T1",
    "gene-OR2T10",
    "gene-OR2T11",
    "gene-OR2T12",
    "gene-OR2T2",
    "gene-OR2T27",
    "gene-OR2T29",
    "gene-OR2T3",
    "gene-OR2T33",
    "gene-OR2T34",
    "gene-OR2T35",
    "gene-OR2T4",
    "gene-OR2T5",
    "gene-OR2T6",
    "gene-OR2T7",
    "gene-OR2T8",
    "gene-OR2V1",
    "gene-OR2V2",
    "gene-OR2W1",
    "gene-OR2W3",
    "gene-OR2Y1",
    "gene-OR2Z1",
    "gene-OR3A1",
    "gene-OR3A2",
    "gene-OR3A3",
    "gene-OR4A15",
    "gene-OR4A16",
    "gene-OR4A47",
    "gene-OR4A5",
    "gene-OR4A8",
    "gene-OR4B1",
    "gene-OR4C11",
    "gene-OR4C12",
    "gene-OR4C13",
    "gene-OR4C15",
    "gene-OR4C16",
    "gene-OR4C3",
    "gene-OR4C45",
    "gene-OR4C46",
    "gene-OR4C5",
    "gene-OR4C6",
    "gene-OR4D1",
    "gene-OR4D10",
    "gene-OR4D11",
    "gene-OR4D2",
    "gene-OR4D5",
    "gene-OR4D6",
    "gene-OR4D9",
    "gene-OR4E1",
    "gene-OR4E2",
    "gene-OR4F15",
    "gene-OR4F16",
    "gene-OR4F17",
    "gene-OR4F21",
    "gene-OR4F29",
    "gene-OR4F3",
    "gene-OR4F4",
    "gene-OR4F5",
    "gene-OR4F6",
    "gene-OR4K1",
    "gene-OR4K13",
    "gene-OR4K14",
    "gene-OR4K15",
    "gene-OR4K17",
    "gene-OR4K2",
    "gene-OR4K3",
    "gene-OR4K5",
    "gene-OR4L1",
    "gene-OR4M1",
    "gene-OR4M2",
    "gene-OR4M2B",
    "gene-OR4N2",
    "gene-OR4N4",
    "gene-OR4N4C",
    "gene-OR4N5",
    "gene-OR4P4",
    "gene-OR4Q2",
    "gene-OR4Q3",
    "gene-OR4S1",
    "gene-OR4S2",
    "gene-OR4X1",
    "gene-OR4X2",
    "gene-OR51A2",
    "gene-OR51A4",
    "gene-OR51A7",
    "gene-OR51B2",
    "gene-OR51B4",
    "gene-OR51B5",
    "gene-OR51B6",
    "gene-OR51D1",
    "gene-OR51E1",
    "gene-OR51E2",
    "gene-OR51F1",
    "gene-OR51F2",
    "gene-OR51G1",
    "gene-OR51G2",
    "gene-OR51H1",
    "gene-OR51I1",
    "gene-OR51I2",
    "gene-OR51J1",
    "gene-OR51L1",
    "gene-OR51M1",
    "gene-OR51Q1",
    "gene-OR51S1",
    "gene-OR51T1",
    "gene-OR51V1",
    "gene-OR52A1",
    "gene-OR52A5",
    "gene-OR52B2",
    "gene-OR52B4",
    "gene-OR52B6",
    "gene-OR52D1",
    "gene-OR52E1",
    "gene-OR52E2",
    "gene-OR52E4",
    "gene-OR52E5",
    "gene-OR52E6",
    "gene-OR52E8",
    "gene-OR52H1",
    "gene-OR52I1",
    "gene-OR52I2",
    "gene-OR52J3",
    "gene-OR52K1",
    "gene-OR52K2",
    "gene-OR52L1",
    "gene-OR52M1",
    "gene-OR52N1",
    "gene-OR52N2",
    "gene-OR52N4",
    "gene-OR52N5",
    "gene-OR52P1",
    "gene-OR52R1",
    "gene-OR52W1",
    "gene-OR56A1",
    "gene-OR56A3",
    "gene-OR56A4",
    "gene-OR56A5",
    "gene-OR56B1",
    "gene-OR56B4",
    "gene-OR5A1",
    "gene-OR5A2",
    "gene-OR5AC1",
    "gene-OR5AC2",
    "gene-OR5AK2",
    "gene-OR5AL1",
    "gene-OR5AN1",
    "gene-OR5AP2",
    "gene-OR5AR1",
    "gene-OR5AS1",
    "gene-OR5AU1",
    "gene-OR5B12",
    "gene-OR5B17",
    "gene-OR5B2",
    "gene-OR5B21",
    "gene-OR5B3",
    "gene-OR5C1",
    "gene-OR5D13",
    "gene-OR5D14",
    "gene-OR5D16",
    "gene-OR5D18",
    "gene-OR5F1",
    "gene-OR5G3",
    "gene-OR5H1",
    "gene-OR5H14",
    "gene-OR5H15",
    "gene-OR5H2",
    "gene-OR5H6",
    "gene-OR5H8",
    "gene-OR5I1",
    "gene-OR5J2",
    "gene-OR5K1",
    "gene-OR5K2",
    "gene-OR5K3",
    "gene-OR5K4",
    "gene-OR5L1",
    "gene-OR5L2",
    "gene-OR5M1",
    "gene-OR5M10",
    "gene-OR5M11",
    "gene-OR5M3",
    "gene-OR5M8",
    "gene-OR5M9",
    "gene-OR5P2",
    "gene-OR5P3",
    "gene-OR5T1",
    "gene-OR5T2",
    "gene-OR5T3",
    "gene-OR5V1",
    "gene-OR5W2",
    "gene-OR6A2",
    "gene-OR6B1",
    "gene-OR6B2",
    "gene-OR6B3",
    "gene-OR6C1",
    "gene-OR6C2",
    "gene-OR6C3",
    "gene-OR6C4",
    "gene-OR6C6",
    "gene-OR6C65",
    "gene-OR6C68",
    "gene-OR6C70",
    "gene-OR6C74",
    "gene-OR6C75",
    "gene-OR6C76",
    "gene-OR6F1",
    "gene-OR6J1",
    "gene-OR6K2",
    "gene-OR6K3",
    "gene-OR6K6",
    "gene-OR6M1",
    "gene-OR6N1",
    "gene-OR6N2",
    "gene-OR6P1",
    "gene-OR6Q1",
    "gene-OR6S1",
    "gene-OR6T1",
    "gene-OR6V1",
    "gene-OR6X1",
    "gene-OR6Y1",
    "gene-OR7A10",
    "gene-OR7A17",
    "gene-OR7A5",
    "gene-OR7C1",
    "gene-OR7C2",
    "gene-OR7D2",
    "gene-OR7D4",
    "gene-OR7E24",
    "gene-OR7G1",
    "gene-OR7G2",
    "gene-OR7G3",
    "gene-OR8A1",
    "gene-OR8B12",
    "gene-OR8B2",
    "gene-OR8B3",
    "gene-OR8B4",
    "gene-OR8B8",
    "gene-OR8D1",
    "gene-OR8D2",
    "gene-OR8D4",
    "gene-OR8G1",
    "gene-OR8G5",
    "gene-OR8H1",
    "gene-OR8H2",
    "gene-OR8H3",
    "gene-OR8I2",
    "gene-OR8J1",
    "gene-OR8J2",
    "gene-OR8J3",
    "gene-OR8K1",
    "gene-OR8K3",
    "gene-OR8K5",
    "gene-OR8S1",
    "gene-OR8U1",
    "gene-OR8U3",
    "gene-OR9A2",
    "gene-OR9A4",
    "gene-OR9G1",
    "gene-OR9G4",
    "gene-OR9I1",
    "gene-OR9K2",
    "gene-OR9Q1",
    "gene-OR9Q2",
    "gene-ORAI1",
    "gene-ORAI2",
    "gene-ORAI3",
    "gene-ORC1",
    "gene-ORC2",
    "gene-ORC3",
    "gene-ORC4",
    "gene-ORC5",
    "gene-ORC6",
    "gene-ORM1",
    "gene-ORM2",
    "gene-ORMDL1",
    "gene-ORMDL2",
    "gene-ORMDL3",
    "gene-OS9",
    "gene-OSBP",
    "gene-OSBP2",
    "gene-OSBPL10",
    "gene-OSBPL11",
    "gene-OSBPL1A",
    "gene-OSBPL2",
    "gene-OSBPL3",
    "gene-OSBPL5",
    "gene-OSBPL6",
    "gene-OSBPL7",
    "gene-OSBPL8",
    "gene-OSBPL9",
    "gene-OSCAR",
    "gene-OSCP1",
    "gene-OSER1",
    "gene-OSGEP",
    "gene-OSGEPL1",
    "gene-OSGIN1",
    "gene-OSGIN2",
    "gene-OSM",
    "gene-OSMR",
    "gene-OSR1",
    "gene-OSR2",
    "gene-OST4",
    "gene-OSTC",
    "gene-OSTF1",
    "gene-OSTM1",
    "gene-OSTN",
    "gene-OTC",
    "gene-OTOA",
    "gene-OTOF",
    "gene-OTOG",
    "gene-OTOGL",
    "gene-OTOL1",
    "gene-OTOP1",
    "gene-OTOP2",
    "gene-OTOP3",
    "gene-OTOR",
    "gene-OTOS",
    "gene-OTP",
    "gene-OTUB1",
    "gene-OTUB2",
    "gene-OTUD1",
    "gene-OTUD3",
    "gene-OTUD4",
    "gene-OTUD5",
    "gene-OTUD6A",
    "gene-OTUD6B",
    "gene-OTUD7A",
    "gene-OTUD7B",
    "gene-OTULIN",
    "gene-OTULINL",
    "gene-OTX1",
    "gene-OTX2",
    "gene-OVCA2",
    "gene-OVCH1",
    "gene-OVCH2",
    "gene-OVGP1",
    "gene-OVOL1",
    "gene-OVOL2",
    "gene-OVOL3",
    "gene-OXA1L",
    "gene-OXCT1",
    "gene-OXCT2",
    "gene-OXER1",
    "gene-OXGR1",
    "gene-OXLD1",
    "gene-OXNAD1",
    "gene-OXR1",
    "gene-OXSM",
    "gene-OXSR1",
    "gene-OXT",
    "gene-OXTR",
    "gene-P2RX1",
    "gene-P2RX2",
    "gene-P2RX3",
    "gene-P2RX4",
    "gene-P2RX5",
    "gene-P2RX6",
    "gene-P2RX7",
    "gene-P2RY1",
    "gene-P2RY10",
    "gene-P2RY11",
    "gene-P2RY12",
    "gene-P2RY13",
    "gene-P2RY14",
    "gene-P2RY2",
    "gene-P2RY4",
    "gene-P2RY6",
    "gene-P2RY8",
    "gene-P3H1",
    "gene-P3H2",
    "gene-P3H3",
    "gene-P3H4",
    "gene-P3R3URF",
    "gene-P4HA1",
    "gene-P4HA2",
    "gene-P4HA3",
    "gene-P4HB",
    "gene-P4HTM",
    "gene-PA2G4",
    "gene-PAAF1",
    "gene-PABIR1",
    "gene-PABIR2",
    "gene-PABIR3",
    "gene-PABPC1",
    "gene-PABPC1L",
    "gene-PABPC1L2A",
    "gene-PABPC1L2B",
    "gene-PABPC3",
    "gene-PABPC4",
    "gene-PABPC4L",
    "gene-PABPC5",
    "gene-PABPN1",
    "gene-PABPN1L",
    "gene-PACC1",
    "gene-PACRG",
    "gene-PACRGL",
    "gene-PACS1",
    "gene-PACS2",
    "gene-PACSIN1",
    "gene-PACSIN2",
    "gene-PACSIN3",
    "gene-PADI1",
    "gene-PADI2",
    "gene-PADI3",
    "gene-PADI4",
    "gene-PADI6",
    "gene-PAEP",
    "gene-PAF1",
    "gene-PAFAH1B1",
    "gene-PAFAH1B2",
    "gene-PAFAH1B3",
    "gene-PAFAH2",
    "gene-PAG1",
    "gene-PAGE1",
    "gene-PAGE2",
    "gene-PAGE2B",
    "gene-PAGE3",
    "gene-PAGE4",
    "gene-PAGE5",
    "gene-PAGR1",
    "gene-PAH",
    "gene-PAICS",
    "gene-PAIP1",
    "gene-PAIP2",
    "gene-PAIP2B",
    "gene-PAK1",
    "gene-PAK1IP1",
    "gene-PAK2",
    "gene-PAK3",
    "gene-PAK4",
    "gene-PAK5",
    "gene-PAK6",
    "gene-PALB2",
    "gene-PALD1",
    "gene-PALLD",
    "gene-PALM",
    "gene-PALM2AKAP2",
    "gene-PALM3",
    "gene-PALMD",
    "gene-PALS1",
    "gene-PALS2",
    "gene-PAM",
    "gene-PAM16",
    "gene-PAMR1",
    "gene-PAN2",
    "gene-PAN3",
    "gene-PANK1",
    "gene-PANK2",
    "gene-PANK3",
    "gene-PANK4",
    "gene-PANO1",
    "gene-PANX1",
    "gene-PANX2",
    "gene-PANX3",
    "gene-PAOX",
    "gene-PAPLN",
    "gene-PAPOLA",
    "gene-PAPOLB",
    "gene-PAPOLG",
    "gene-PAPPA",
    "gene-PAPPA2",
    "gene-PAPSS1",
    "gene-PAPSS2",
    "gene-PAQR3",
    "gene-PAQR4",
    "gene-PAQR5",
    "gene-PAQR6",
    "gene-PAQR7",
    "gene-PAQR8",
    "gene-PAQR9",
    "gene-PARD3",
    "gene-PARD3B",
    "gene-PARD6A",
    "gene-PARD6B",
    "gene-PARD6G",
    "gene-PARG",
    "gene-PARK7",
    "gene-PARL",
    "gene-PARM1",
    "gene-PARN",
    "gene-PARP1",
    "gene-PARP10",
    "gene-PARP11",
    "gene-PARP12",
    "gene-PARP14",
    "gene-PARP15",
    "gene-PARP16",
    "gene-PARP2",
    "gene-PARP3",
    "gene-PARP4",
    "gene-PARP6",
    "gene-PARP8",
    "gene-PARP9",
    "gene-PARPBP",
    "gene-PARS2",
    "gene-PARVA",
    "gene-PARVB",
    "gene-PARVG",
    "gene-PASD1",
    "gene-PASK",
    "gene-PATE1",
    "gene-PATE2",
    "gene-PATE3",
    "gene-PATE4",
    "gene-PATJ",
    "gene-PATL1",
    "gene-PATL2",
    "gene-PATZ1",
    "gene-PAWR",
    "gene-PAX1",
    "gene-PAX2",
    "gene-PAX3",
    "gene-PAX4",
    "gene-PAX5",
    "gene-PAX6",
    "gene-PAX7",
    "gene-PAX8",
    "gene-PAX9",
    "gene-PAXBP1",
    "gene-PAXIP1",
    "gene-PAXX",
    "gene-PBDC1",
    "gene-PBK",
    "gene-PBLD",
    "gene-PBOV1",
    "gene-PBRM1",
    "gene-PBX1",
    "gene-PBX2",
    "gene-PBX3",
    "gene-PBX4",
    "gene-PBXIP1",
    "gene-PC",
    "gene-PCARE",
    "gene-PCBD1",
    "gene-PCBD2",
    "gene-PCBP1",
    "gene-PCBP2",
    "gene-PCBP3",
    "gene-PCBP4",
    "gene-PCCA",
    "gene-PCCB",
    "gene-PCDH1",
    "gene-PCDH10",
    "gene-PCDH11X",
    "gene-PCDH11Y",
    "gene-PCDH12",
    "gene-PCDH15",
    "gene-PCDH17",
    "gene-PCDH18",
    "gene-PCDH19",
    "gene-PCDH20",
    "gene-PCDH7",
    "gene-PCDH8",
    "gene-PCDH9",
    "gene-PCDHA@",
    "gene-PCDHB@",
    "gene-PCDHG@",
    "gene-PCED1A",
    "gene-PCED1B",
    "gene-PCF11",
    "gene-PCGF1",
    "gene-PCGF2",
    "gene-PCGF3",
    "gene-PCGF5",
    "gene-PCGF6",
    "gene-PCID2",
    "gene-PCIF1",
    "gene-PCK1",
    "gene-PCK2",
    "gene-PCLAF",
    "gene-PCLO",
    "gene-PCM1",
    "gene-PCMT1",
    "gene-PCMTD1",
    "gene-PCMTD2",
    "gene-PCNA",
    "gene-PCNP",
    "gene-PCNT",
    "gene-PCNX1",
    "gene-PCNX2",
    "gene-PCNX3",
    "gene-PCNX4",
    "gene-PCOLCE",
    "gene-PCOLCE2",
    "gene-PCP2",
    "gene-PCP4",
    "gene-PCP4L1",
    "gene-PCSK1",
    "gene-PCSK1N",
    "gene-PCSK2",
    "gene-PCSK4",
    "gene-PCSK5",
    "gene-PCSK6",
    "gene-PCSK7",
    "gene-PCSK9",
    "gene-PCTP",
    "gene-PCYOX1",
    "gene-PCYOX1L",
    "gene-PCYT1A",
    "gene-PCYT1B",
    "gene-PCYT2",
    "gene-PDAP1",
    "gene-PDC",
    "gene-PDCD1",
    "gene-PDCD10",
    "gene-PDCD11",
    "gene-PDCD1LG2",
    "gene-PDCD2",
    "gene-PDCD2L",
    "gene-PDCD4",
    "gene-PDCD5",
    "gene-PDCD6",
    "gene-PDCD6IP",
    "gene-PDCD7",
    "gene-PDCL",
    "gene-PDCL2",
    "gene-PDCL3",
    "gene-PDE10A",
    "gene-PDE11A",
    "gene-PDE12",
    "gene-PDE1A",
    "gene-PDE1B",
    "gene-PDE1C",
    "gene-PDE2A",
    "gene-PDE3A",
    "gene-PDE3B",
    "gene-PDE4A",
    "gene-PDE4B",
    "gene-PDE4C",
    "gene-PDE4D",
    "gene-PDE4DIP",
    "gene-PDE5A",
    "gene-PDE6A",
    "gene-PDE6B",
    "gene-PDE6C",
    "gene-PDE6D",
    "gene-PDE6G",
    "gene-PDE6H",
    "gene-PDE7A",
    "gene-PDE7B",
    "gene-PDE8A",
    "gene-PDE8B",
    "gene-PDE9A",
    "gene-PDF",
    "gene-PDGFA",
    "gene-PDGFB",
    "gene-PDGFC",
    "gene-PDGFD",
    "gene-PDGFRA",
    "gene-PDGFRB",
    "gene-PDGFRL",
    "gene-PDHA1",
    "gene-PDHA2",
    "gene-PDHB",
    "gene-PDHX",
    "gene-PDIA2",
    "gene-PDIA3",
    "gene-PDIA4",
    "gene-PDIA5",
    "gene-PDIA6",
    "gene-PDIK1L",
    "gene-PDILT",
    "gene-PDK1",
    "gene-PDK2",
    "gene-PDK3",
    "gene-PDK4",
    "gene-PDLIM1",
    "gene-PDLIM2",
    "gene-PDLIM3",
    "gene-PDLIM4",
    "gene-PDLIM5",
    "gene-PDLIM7",
    "gene-PDP1",
    "gene-PDP2",
    "gene-PDPK1",
    "gene-PDPN",
    "gene-PDPR",
    "gene-PDRG1",
    "gene-PDS5A",
    "gene-PDS5B",
    "gene-PDSS1",
    "gene-PDSS2",
    "gene-PDX1",
    "gene-PDXDC1",
    "gene-PDXK",
    "gene-PDXP",
    "gene-PDYN",
    "gene-PDZD11",
    "gene-PDZD2",
    "gene-PDZD4",
    "gene-PDZD7",
    "gene-PDZD8",
    "gene-PDZD9",
    "gene-PDZK1",
    "gene-PDZK1IP1",
    "gene-PDZRN3",
    "gene-PDZRN4",
    "gene-PEA15",
    "gene-PEAK1",
    "gene-PEAK3",
    "gene-PEAR1",
    "gene-PEBP1",
    "gene-PEBP4",
    "gene-PECAM1",
    "gene-PECR",
    "gene-PEDS1",
    "gene-PEF1",
    "gene-PEG10",
    "gene-PEG3",
    "gene-PELI1",
    "gene-PELI2",
    "gene-PELI3",
    "gene-PELO",
    "gene-PELP1",
    "gene-PEMT",
    "gene-PENK",
    "gene-PEPD",
    "gene-PER1",
    "gene-PER2",
    "gene-PER3",
    "gene-PERCC1",
    "gene-PERM1",
    "gene-PERP",
    "gene-PES1",
    "gene-PET100",
    "gene-PET117",
    "gene-PEX1",
    "gene-PEX10",
    "gene-PEX11A",
    "gene-PEX11B",
    "gene-PEX11G",
    "gene-PEX12",
    "gene-PEX13",
    "gene-PEX14",
    "gene-PEX16",
    "gene-PEX19",
    "gene-PEX2",
    "gene-PEX26",
    "gene-PEX3",
    "gene-PEX5",
    "gene-PEX5L",
    "gene-PEX6",
    "gene-PEX7",
    "gene-PF4",
    "gene-PF4V1",
    "gene-PFAS",
    "gene-PFDN1",
    "gene-PFDN2",
    "gene-PFDN4",
    "gene-PFDN5",
    "gene-PFDN6",
    "gene-PFKFB1",
    "gene-PFKFB2",
    "gene-PFKFB3",
    "gene-PFKFB4",
    "gene-PFKL",
    "gene-PFKM",
    "gene-PFKP",
    "gene-PFN1",
    "gene-PFN2",
    "gene-PFN3",
    "gene-PFN4",
    "gene-PGA3",
    "gene-PGA4",
    "gene-PGA5",
    "gene-PGAM1",
    "gene-PGAM2",
    "gene-PGAM4",
    "gene-PGAM5",
    "gene-PGAP1",
    "gene-PGAP2",
    "gene-PGAP3",
    "gene-PGAP4",
    "gene-PGAP6",
    "gene-PGBD1",
    "gene-PGBD2",
    "gene-PGBD3",
    "gene-PGBD4",
    "gene-PGBD5",
    "gene-PGC",
    "gene-PGD",
    "gene-PGF",
    "gene-PGGHG",
    "gene-PGGT1B",
    "gene-PGK1",
    "gene-PGK2",
    "gene-PGLS",
    "gene-PGLYRP1",
    "gene-PGLYRP2",
    "gene-PGLYRP3",
    "gene-PGLYRP4",
    "gene-PGM1",
    "gene-PGM2",
    "gene-PGM2L1",
    "gene-PGM3",
    "gene-PGM5",
    "gene-PGP",
    "gene-PGPEP1",
    "gene-PGPEP1L",
    "gene-PGR",
    "gene-PGRMC1",
    "gene-PGRMC2",
    "gene-PGS1",
    "gene-PHACTR1",
    "gene-PHACTR2",
    "gene-PHACTR3",
    "gene-PHACTR4",
    "gene-PHAF1",
    "gene-PHAX",
    "gene-PHB1",
    "gene-PHB2",
    "gene-PHC1",
    "gene-PHC2",
    "gene-PHC3",
    "gene-PHETA1",
    "gene-PHETA2",
    "gene-PHEX",
    "gene-PHF1",
    "gene-PHF10",
    "gene-PHF11",
    "gene-PHF12",
    "gene-PHF13",
    "gene-PHF14",
    "gene-PHF19",
    "gene-PHF2",
    "gene-PHF20",
    "gene-PHF20L1",
    "gene-PHF21A",
    "gene-PHF21B",
    "gene-PHF23",
    "gene-PHF24",
    "gene-PHF3",
    "gene-PHF5A",
    "gene-PHF6",
    "gene-PHF7",
    "gene-PHF8",
    "gene-PHGDH",
    "gene-PHGR1",
    "gene-PHIP",
    "gene-PHKA1",
    "gene-PHKA2",
    "gene-PHKB",
    "gene-PHKG1",
    "gene-PHKG2",
    "gene-PHLDA1",
    "gene-PHLDA2",
    "gene-PHLDA3",
    "gene-PHLDB1",
    "gene-PHLDB2",
    "gene-PHLDB3",
    "gene-PHLPP1",
    "gene-PHLPP2",
    "gene-PHOSPHO1",
    "gene-PHOSPHO2",
    "gene-PHOX2A",
    "gene-PHOX2B",
    "gene-PHPT1",
    "gene-PHRF1",
    "gene-PHTF1",
    "gene-PHTF2",
    "gene-PHYH",
    "gene-PHYHD1",
    "gene-PHYHIP",
    "gene-PHYHIPL",
    "gene-PHYKPL",
    "gene-PI15",
    "gene-PI16",
    "gene-PI3",
    "gene-PI4K2A",
    "gene-PI4K2B",
    "gene-PI4KA",
    "gene-PI4KB",
    "gene-PIANP",
    "gene-PIAS1",
    "gene-PIAS2",
    "gene-PIAS3",
    "gene-PIAS4",
    "gene-PIBF1",
    "gene-PICALM",
    "gene-PICK1",
    "gene-PID1",
    "gene-PIDD1",
    "gene-PIERCE1",
    "gene-PIERCE2",
    "gene-PIEZO1",
    "gene-PIEZO2",
    "gene-PIF1",
    "gene-PIGA",
    "gene-PIGB",
    "gene-PIGBOS1",
    "gene-PIGC",
    "gene-PIGF",
    "gene-PIGG",
    "gene-PIGH",
    "gene-PIGK",
    "gene-PIGL",
    "gene-PIGM",
    "gene-PIGN",
    "gene-PIGO",
    "gene-PIGP",
    "gene-PIGQ",
    "gene-PIGR",
    "gene-PIGS",
    "gene-PIGT",
    "gene-PIGU",
    "gene-PIGV",
    "gene-PIGW",
    "gene-PIGX",
    "gene-PIGY",
    "gene-PIGZ",
    "gene-PIH1D1",
    "gene-PIH1D2",
    "gene-PIK3AP1",
    "gene-PIK3C2A",
    "gene-PIK3C2B",
    "gene-PIK3C2G",
    "gene-PIK3C3",
    "gene-PIK3CA",
    "gene-PIK3CB",
    "gene-PIK3CD",
    "gene-PIK3CG",
    "gene-PIK3IP1",
    "gene-PIK3R1",
    "gene-PIK3R2",
    "gene-PIK3R3",
    "gene-PIK3R4",
    "gene-PIK3R5",
    "gene-PIK3R6",
    "gene-PIKFYVE",
    "gene-PILRA",
    "gene-PILRB",
    "gene-PIM1",
    "gene-PIM2",
    "gene-PIM3",
    "gene-PIMREG",
    "gene-PIN1",
    "gene-PIN4",
    "gene-PINK1",
    "gene-PINLYP",
    "gene-PINX1",
    "gene-PIP",
    "gene-PIP4K2A",
    "gene-PIP4K2B",
    "gene-PIP4K2C",
    "gene-PIP4P1",
    "gene-PIP4P2",
    "gene-PIP5K1A",
    "gene-PIP5K1B",
    "gene-PIP5K1C",
    "gene-PIP5KL1",
    "gene-PIPOX",
    "gene-PIR",
    "gene-PIRT",
    "gene-PISD",
    "gene-PITHD1",
    "gene-PITPNA",
    "gene-PITPNB",
    "gene-PITPNC1",
    "gene-PITPNM1",
    "gene-PITPNM2",
    "gene-PITPNM3",
    "gene-PITRM1",
    "gene-PITX1",
    "gene-PITX2",
    "gene-PITX3",
    "gene-PIWIL1",
    "gene-PIWIL2",
    "gene-PIWIL3",
    "gene-PIWIL4",
    "gene-PJA1",
    "gene-PJA2",
    "gene-PJVK",
    "gene-PKD1",
    "gene-PKD1L1",
    "gene-PKD1L2",
    "gene-PKD1L3",
    "gene-PKD2",
    "gene-PKD2L1",
    "gene-PKD2L2",
    "gene-PKDCC",
    "gene-PKDREJ",
    "gene-PKHD1",
    "gene-PKHD1L1",
    "gene-PKIA",
    "gene-PKIB",
    "gene-PKIG",
    "gene-PKLR",
    "gene-PKM",
    "gene-PKMYT1",
    "gene-PKN1",
    "gene-PKN2",
    "gene-PKN3",
    "gene-PKNOX1",
    "gene-PKNOX2",
    "gene-PKP1",
    "gene-PKP2",
    "gene-PKP3",
    "gene-PKP4",
    "gene-PLA1A",
    "gene-PLA2G10",
    "gene-PLA2G12A",
    "gene-PLA2G12B",
    "gene-PLA2G15",
    "gene-PLA2G1B",
    "gene-PLA2G2A",
    "gene-PLA2G2C",
    "gene-PLA2G2D",
    "gene-PLA2G2E",
    "gene-PLA2G2F",
    "gene-PLA2G3",
    "gene-PLA2G4A",
    "gene-PLA2G4B",
    "gene-PLA2G4C",
    "gene-PLA2G4D",
    "gene-PLA2G4E",
    "gene-PLA2G4F",
    "gene-PLA2G5",
    "gene-PLA2G6",
    "gene-PLA2G7",
    "gene-PLA2R1",
    "gene-PLAA",
    "gene-PLAAT1",
    "gene-PLAAT2",
    "gene-PLAAT3",
    "gene-PLAAT4",
    "gene-PLAAT5",
    "gene-PLAC1",
    "gene-PLAC8",
    "gene-PLAC8L1",
    "gene-PLAC9",
    "gene-PLAG1",
    "gene-PLAGL1",
    "gene-PLAGL2",
    "gene-PLAT",
    "gene-PLAU",
    "gene-PLAUR",
    "gene-PLB1",
    "gene-PLBD1",
    "gene-PLBD2",
    "gene-PLCB1",
    "gene-PLCB2",
    "gene-PLCB3",
    "gene-PLCB4",
    "gene-PLCD1",
    "gene-PLCD3",
    "gene-PLCD4",
    "gene-PLCE1",
    "gene-PLCG1",
    "gene-PLCG2",
    "gene-PLCH1",
    "gene-PLCH2",
    "gene-PLCL1",
    "gene-PLCL2",
    "gene-PLCXD1",
    "gene-PLCXD2",
    "gene-PLCXD3",
    "gene-PLCZ1",
    "gene-PLD1",
    "gene-PLD2",
    "gene-PLD3",
    "gene-PLD4",
    "gene-PLD5",
    "gene-PLD6",
    "gene-PLEC",
    "gene-PLEK",
    "gene-PLEK2",
    "gene-PLEKHA1",
    "gene-PLEKHA2",
    "gene-PLEKHA3",
    "gene-PLEKHA4",
    "gene-PLEKHA5",
    "gene-PLEKHA6",
    "gene-PLEKHA7",
    "gene-PLEKHA8",
    "gene-PLEKHB1",
    "gene-PLEKHB2",
    "gene-PLEKHD1",
    "gene-PLEKHF1",
    "gene-PLEKHF2",
    "gene-PLEKHG1",
    "gene-PLEKHG2",
    "gene-PLEKHG3",
    "gene-PLEKHG4",
    "gene-PLEKHG4B",
    "gene-PLEKHG5",
    "gene-PLEKHG6",
    "gene-PLEKHG7",
    "gene-PLEKHH1",
    "gene-PLEKHH2",
    "gene-PLEKHH3",
    "gene-PLEKHJ1",
    "gene-PLEKHM1",
    "gene-PLEKHM2",
    "gene-PLEKHM3",
    "gene-PLEKHN1",
    "gene-PLEKHO1",
    "gene-PLEKHO2",
    "gene-PLEKHS1",
    "gene-PLET1",
    "gene-PLG",
    "gene-PLGLB1",
    "gene-PLGLB2",
    "gene-PLGRKT",
    "gene-PLIN1",
    "gene-PLIN2",
    "gene-PLIN3",
    "gene-PLIN4",
    "gene-PLIN5",
    "gene-PLK1",
    "gene-PLK2",
    "gene-PLK3",
    "gene-PLK4",
    "gene-PLK5",
    "gene-PLLP",
    "gene-PLN",
    "gene-PLOD1",
    "gene-PLOD2",
    "gene-PLOD3",
    "gene-PLP1",
    "gene-PLP2",
    "gene-PLPBP",
    "gene-PLPP1",
    "gene-PLPP2",
    "gene-PLPP3",
    "gene-PLPP4",
    "gene-PLPP5",
    "gene-PLPP6",
    "gene-PLPP7",
    "gene-PLPPR1",
    "gene-PLPPR2",
    "gene-PLPPR3",
    "gene-PLPPR4",
    "gene-PLPPR5",
    "gene-PLRG1",
    "gene-PLS1",
    "gene-PLS3",
    "gene-PLSCR1",
    "gene-PLSCR2",
    "gene-PLSCR3",
    "gene-PLSCR4",
    "gene-PLSCR5",
    "gene-PLTP",
    "gene-PLVAP",
    "gene-PLXDC1",
    "gene-PLXDC2",
    "gene-PLXNA1",
    "gene-PLXNA2",
    "gene-PLXNA3",
    "gene-PLXNA4",
    "gene-PLXNB1",
    "gene-PLXNB2",
    "gene-PLXNB3",
    "gene-PLXNC1",
    "gene-PLXND1",
    "gene-PM20D1",
    "gene-PM20D2",
    "gene-PMAIP1",
    "gene-PMCH",
    "gene-PMEL",
    "gene-PMEPA1",
    "gene-PMF1",
    "gene-PMFBP1",
    "gene-PMIS2",
    "gene-PML",
    "gene-PMM1",
    "gene-PMM2",
    "gene-PMP2",
    "gene-PMP22",
    "gene-PMPCA",
    "gene-PMPCB",
    "gene-PMS1",
    "gene-PMS2",
    "gene-PMVK",
    "gene-PNCK",
    "gene-PNISR",
    "gene-PNKD",
    "gene-PNKP",
    "gene-PNLDC1",
    "gene-PNLIP",
    "gene-PNLIPRP1",
    "gene-PNLIPRP2",
    "gene-PNLIPRP3",
    "gene-PNMA1",
    "gene-PNMA2",
    "gene-PNMA3",
    "gene-PNMA5",
    "gene-PNMA6A",
    "gene-PNMA6E",
    "gene-PNMA6F",
    "gene-PNMA8A",
    "gene-PNMA8B",
    "gene-PNMA8C",
    "gene-PNMT",
    "gene-PNN",
    "gene-PNO1",
    "gene-PNOC",
    "gene-PNP",
    "gene-PNPLA1",
    "gene-PNPLA2",
    "gene-PNPLA3",
    "gene-PNPLA4",
    "gene-PNPLA5",
    "gene-PNPLA6",
    "gene-PNPLA7",
    "gene-PNPLA8",
    "gene-PNPO",
    "gene-PNPT1",
    "gene-PNRC1",
    "gene-PNRC2",
    "gene-POC1A",
    "gene-POC1B",
    "gene-POC5",
    "gene-PODN",
    "gene-PODNL1",
    "gene-PODXL",
    "gene-PODXL2",
    "gene-POF1B",
    "gene-POFUT1",
    "gene-POFUT2",
    "gene-POGK",
    "gene-POGLUT1",
    "gene-POGLUT2",
    "gene-POGLUT3",
    "gene-POGZ",
    "gene-POLA1",
    "gene-POLA2",
    "gene-POLB",
    "gene-POLD1",
    "gene-POLD2",
    "gene-POLD3",
    "gene-POLD4",
    "gene-POLDIP2",
    "gene-POLDIP3",
    "gene-POLE",
    "gene-POLE2",
    "gene-POLE3",
    "gene-POLE4",
    "gene-POLG",
    "gene-POLG2",
    "gene-POLGARF",
    "gene-POLH",
    "gene-POLI",
    "gene-POLK",
    "gene-POLL",
    "gene-POLM",
    "gene-POLN",
    "gene-POLQ",
    "gene-POLR1A",
    "gene-POLR1B",
    "gene-POLR1C",
    "gene-POLR1D",
    "gene-POLR1E",
    "gene-POLR1F",
    "gene-POLR1G",
    "gene-POLR1H",
    "gene-POLR2A",
    "gene-POLR2B",
    "gene-POLR2C",
    "gene-POLR2D",
    "gene-POLR2E",
    "gene-POLR2F",
    "gene-POLR2G",
    "gene-POLR2H",
    "gene-POLR2I",
    "gene-POLR2J",
    "gene-POLR2J2",
    "gene-POLR2J3",
    "gene-POLR2K",
    "gene-POLR2L",
    "gene-POLR2M",
    "gene-POLR3A",
    "gene-POLR3B",
    "gene-POLR3C",
    "gene-POLR3D",
    "gene-POLR3E",
    "gene-POLR3F",
    "gene-POLR3G",
    "gene-POLR3GL",
    "gene-POLR3H",
    "gene-POLR3K",
    "gene-POLRMT",
    "gene-POM121",
    "gene-POM121C",
    "gene-POM121L12",
    "gene-POM121L2",
    "gene-POMC",
    "gene-POMGNT1",
    "gene-POMGNT2",
    "gene-POMK",
    "gene-POMP",
    "gene-POMT1",
    "gene-POMT2",
    "gene-POMZP3",
    "gene-PON1",
    "gene-PON2",
    "gene-PON3",
    "gene-POP1",
    "gene-POP4",
    "gene-POP5",
    "gene-POP7",
    "gene-POPDC2",
    "gene-POPDC3",
    "gene-POR",
    "gene-PORCN",
    "gene-POSTN",
    "gene-POT1",
    "gene-POTEA",
    "gene-POTEB",
    "gene-POTEB2",
    "gene-POTEB3",
    "gene-POTEC",
    "gene-POTED",
    "gene-POTEE",
    "gene-POTEF",
    "gene-POTEG",
    "gene-POTEH",
    "gene-POTEI",
    "gene-POTEJ",
    "gene-POTEM",
    "gene-POU1F1",
    "gene-POU2AF1",
    "gene-POU2AF2",
    "gene-POU2AF3",
    "gene-POU2F1",
    "gene-POU2F2",
    "gene-POU2F3",
    "gene-POU3F1",
    "gene-POU3F2",
    "gene-POU3F3",
    "gene-POU3F4",
    "gene-POU4F1",
    "gene-POU4F2",
    "gene-POU4F3",
    "gene-POU5F1",
    "gene-POU5F1B",
    "gene-POU5F2",
    "gene-POU6F1",
    "gene-POU6F2",
    "gene-PP2D1",
    "gene-PPA1",
    "gene-PPA2",
    "gene-PPAN",
    "gene-PPARA",
    "gene-PPARD",
    "gene-PPARG",
    "gene-PPARGC1A",
    "gene-PPARGC1B",
    "gene-PPAT",
    "gene-PPBP",
    "gene-PPCDC",
    "gene-PPCS",
    "gene-PPDPF",
    "gene-PPDPFL",
    "gene-PPEF1",
    "gene-PPEF2",
    "gene-PPFIA1",
    "gene-PPFIA2",
    "gene-PPFIA3",
    "gene-PPFIA4",
    "gene-PPFIBP1",
    "gene-PPFIBP2",
    "gene-PPHLN1",
    "gene-PPIA",
    "gene-PPIAL4A",
    "gene-PPIAL4C",
    "gene-PPIAL4D",
    "gene-PPIAL4E",
    "gene-PPIAL4F",
    "gene-PPIAL4G",
    "gene-PPIAL4H",
    "gene-PPIB",
    "gene-PPIC",
    "gene-PPID",
    "gene-PPIE",
    "gene-PPIF",
    "gene-PPIG",
    "gene-PPIH",
    "gene-PPIL1",
    "gene-PPIL2",
    "gene-PPIL3",
    "gene-PPIL4",
    "gene-PPIL6",
    "gene-PPIP5K1",
    "gene-PPIP5K2",
    "gene-PPL",
    "gene-PPM1A",
    "gene-PPM1B",
    "gene-PPM1D",
    "gene-PPM1E",
    "gene-PPM1F",
    "gene-PPM1G",
    "gene-PPM1H",
    "gene-PPM1J",
    "gene-PPM1K",
    "gene-PPM1L",
    "gene-PPM1M",
    "gene-PPM1N",
    "gene-PPME1",
    "gene-PPOX",
    "gene-PPP1CA",
    "gene-PPP1CB",
    "gene-PPP1CC",
    "gene-PPP1R10",
    "gene-PPP1R11",
    "gene-PPP1R12A",
    "gene-PPP1R12B",
    "gene-PPP1R12C",
    "gene-PPP1R13B",
    "gene-PPP1R13L",
    "gene-PPP1R14A",
    "gene-PPP1R14B",
    "gene-PPP1R14C",
    "gene-PPP1R14D",
    "gene-PPP1R15A",
    "gene-PPP1R15B",
    "gene-PPP1R16A",
    "gene-PPP1R16B",
    "gene-PPP1R17",
    "gene-PPP1R18",
    "gene-PPP1R1A",
    "gene-PPP1R1B",
    "gene-PPP1R1C",
    "gene-PPP1R2",
    "gene-PPP1R21",
    "gene-PPP1R26",
    "gene-PPP1R27",
    "gene-PPP1R2B",
    "gene-PPP1R2C",
    "gene-PPP1R35",
    "gene-PPP1R36",
    "gene-PPP1R37",
    "gene-PPP1R3A",
    "gene-PPP1R3B",
    "gene-PPP1R3C",
    "gene-PPP1R3D",
    "gene-PPP1R3E",
    "gene-PPP1R3F",
    "gene-PPP1R3G",
    "gene-PPP1R42",
    "gene-PPP1R7",
    "gene-PPP1R8",
    "gene-PPP1R9A",
    "gene-PPP1R9B",
    "gene-PPP2CA",
    "gene-PPP2CB",
    "gene-PPP2R1A",
    "gene-PPP2R1B",
    "gene-PPP2R2A",
    "gene-PPP2R2B",
    "gene-PPP2R2C",
    "gene-PPP2R2D",
    "gene-PPP2R3A",
    "gene-PPP2R3B",
    "gene-PPP2R3C",
    "gene-PPP2R5A",
    "gene-PPP2R5B",
    "gene-PPP2R5C",
    "gene-PPP2R5D",
    "gene-PPP2R5E",
    "gene-PPP3CA",
    "gene-PPP3CB",
    "gene-PPP3CC",
    "gene-PPP3R1",
    "gene-PPP3R2",
    "gene-PPP4C",
    "gene-PPP4R1",
    "gene-PPP4R2",
    "gene-PPP4R3A",
    "gene-PPP4R3B",
    "gene-PPP4R3C",
    "gene-PPP4R4",
    "gene-PPP5C",
    "gene-PPP6C",
    "gene-PPP6R1",
    "gene-PPP6R2",
    "gene-PPP6R3",
    "gene-PPRC1",
    "gene-PPT1",
    "gene-PPT2",
    "gene-PPTC7",
    "gene-PPWD1",
    "gene-PPY",
    "gene-PQBP1",
    "gene-PRAC1",
    "gene-PRAC2",
    "gene-PRADC1",
    "gene-PRAF2",
    "gene-PRAG1",
    "gene-PRAM1",
    "gene-PRAME",
    "gene-PRAMEF1",
    "gene-PRAMEF10",
    "gene-PRAMEF11",
    "gene-PRAMEF12",
    "gene-PRAMEF13",
    "gene-PRAMEF14",
    "gene-PRAMEF15",
    "gene-PRAMEF17",
    "gene-PRAMEF18",
    "gene-PRAMEF19",
    "gene-PRAMEF2",
    "gene-PRAMEF20",
    "gene-PRAMEF25",
    "gene-PRAMEF26",
    "gene-PRAMEF27",
    "gene-PRAMEF33",
    "gene-PRAMEF4",
    "gene-PRAMEF5",
    "gene-PRAMEF6",
    "gene-PRAMEF7",
    "gene-PRAMEF8",
    "gene-PRAMEF9",
    "gene-PRAP1",
    "gene-PRB1",
    "gene-PRB2",
    "gene-PRB3",
    "gene-PRB4",
    "gene-PRC1",
    "gene-PRCC",
    "gene-PRCD",
    "gene-PRCP",
    "gene-PRDM1",
    "gene-PRDM10",
    "gene-PRDM11",
    "gene-PRDM12",
    "gene-PRDM13",
    "gene-PRDM14",
    "gene-PRDM15",
    "gene-PRDM16",
    "gene-PRDM2",
    "gene-PRDM4",
    "gene-PRDM5",
    "gene-PRDM6",
    "gene-PRDM7",
    "gene-PRDM8",
    "gene-PRDM9",
    "gene-PRDX1",
    "gene-PRDX2",
    "gene-PRDX3",
    "gene-PRDX4",
    "gene-PRDX5",
    "gene-PRDX6",
    "gene-PREB",
    "gene-PRELID1",
    "gene-PRELID2",
    "gene-PRELID3A",
    "gene-PRELID3B",
    "gene-PRELP",
    "gene-PREP",
    "gene-PREPL",
    "gene-PREX1",
    "gene-PREX2",
    "gene-PRF1",
    "gene-PRG2",
    "gene-PRG3",
    "gene-PRG4",
    "gene-PRH1",
    "gene-PRH2",
    "gene-PRICKLE1",
    "gene-PRICKLE2",
    "gene-PRICKLE3",
    "gene-PRICKLE4",
    "gene-PRIM1",
    "gene-PRIM2",
    "gene-PRIMA1",
    "gene-PRIMPOL",
    "gene-PRKAA1",
    "gene-PRKAA2",
    "gene-PRKAB1",
    "gene-PRKAB2",
    "gene-PRKACA",
    "gene-PRKACB",
    "gene-PRKACG",
    "gene-PRKAG1",
    "gene-PRKAG2",
    "gene-PRKAG3",
    "gene-PRKAR1A",
    "gene-PRKAR1B",
    "gene-PRKAR2A",
    "gene-PRKAR2B",
    "gene-PRKCA",
    "gene-PRKCB",
    "gene-PRKCD",
    "gene-PRKCE",
    "gene-PRKCG",
    "gene-PRKCH",
    "gene-PRKCI",
    "gene-PRKCQ",
    "gene-PRKCSH",
    "gene-PRKCZ",
    "gene-PRKD1",
    "gene-PRKD2",
    "gene-PRKD3",
    "gene-PRKDC",
    "gene-PRKG1",
    "gene-PRKG2",
    "gene-PRKN",
    "gene-PRKRA",
    "gene-PRKRIP1",
    "gene-PRKX",
    "gene-PRL",
    "gene-PRLH",
    "gene-PRLHR",
    "gene-PRLR",
    "gene-PRM1",
    "gene-PRM2",
    "gene-PRM3",
    "gene-PRMT1",
    "gene-PRMT2",
    "gene-PRMT3",
    "gene-PRMT5",
    "gene-PRMT6",
    "gene-PRMT7",
    "gene-PRMT8",
    "gene-PRMT9",
    "gene-PRND",
    "gene-PRNP",
    "gene-PROB1",
    "gene-PROC",
    "gene-PROCA1",
    "gene-PROCR",
    "gene-PRODH",
    "gene-PRODH2",
    "gene-PROK1",
    "gene-PROK2",
    "gene-PROKR1",
    "gene-PROKR2",
    "gene-PROM1",
    "gene-PROM2",
    "gene-PROP1",
    "gene-PRORP",
    "gene-PRORY",
    "gene-PROS1",
    "gene-PROSER1",
    "gene-PROSER2",
    "gene-PROSER3",
    "gene-PROX1",
    "gene-PROX2",
    "gene-PROZ",
    "gene-PRPF18",
    "gene-PRPF19",
    "gene-PRPF3",
    "gene-PRPF31",
    "gene-PRPF38A",
    "gene-PRPF38B",
    "gene-PRPF39",
    "gene-PRPF4",
    "gene-PRPF40A",
    "gene-PRPF40B",
    "gene-PRPF4B",
    "gene-PRPF6",
    "gene-PRPF8",
    "gene-PRPH",
    "gene-PRPH2",
    "gene-PRPS1",
    "gene-PRPS1L1",
    "gene-PRPS2",
    "gene-PRPSAP1",
    "gene-PRPSAP2",
    "gene-PRR11",
    "gene-PRR12",
    "gene-PRR13",
    "gene-PRR14",
    "gene-PRR14L",
    "gene-PRR15",
    "gene-PRR15L",
    "gene-PRR16",
    "gene-PRR18",
    "gene-PRR19",
    "gene-PRR20A",
    "gene-PRR20B",
    "gene-PRR20C",
    "gene-PRR20D",
    "gene-PRR20E",
    "gene-PRR20G",
    "gene-PRR22",
    "gene-PRR23A",
    "gene-PRR23B",
    "gene-PRR23C",
    "gene-PRR23D1",
    "gene-PRR23D2",
    "gene-PRR23E",
    "gene-PRR27",
    "gene-PRR29",
    "gene-PRR3",
    "gene-PRR30",
    "gene-PRR32",
    "gene-PRR33",
    "gene-PRR35",
    "gene-PRR36",
    "gene-PRR4",
    "gene-PRR5",
    "gene-PRR5L",
    "gene-PRR7",
    "gene-PRR9",
    "gene-PRRC1",
    "gene-PRRC2A",
    "gene-PRRC2B",
    "gene-PRRC2C",
    "gene-PRRG1",
    "gene-PRRG2",
    "gene-PRRG3",
    "gene-PRRG4",
    "gene-PRRT1",
    "gene-PRRT1B",
    "gene-PRRT2",
    "gene-PRRT3",
    "gene-PRRT4",
    "gene-PRRX1",
    "gene-PRRX2",
    "gene-PRSS1",
    "gene-PRSS12",
    "gene-PRSS16",
    "gene-PRSS2",
    "gene-PRSS21",
    "gene-PRSS22",
    "gene-PRSS23",
    "gene-PRSS27",
    "gene-PRSS3",
    "gene-PRSS33",
    "gene-PRSS35",
    "gene-PRSS36",
    "gene-PRSS37",
    "gene-PRSS38",
    "gene-PRSS41",
    "gene-PRSS48",
    "gene-PRSS50",
    "gene-PRSS51",
    "gene-PRSS53",
    "gene-PRSS54",
    "gene-PRSS55",
    "gene-PRSS56",
    "gene-PRSS57",
    "gene-PRSS58",
    "gene-PRSS8",
    "gene-PRTFDC1",
    "gene-PRTG",
    "gene-PRTN3",
    "gene-PRUNE1",
    "gene-PRUNE2",
    "gene-PRX",
    "gene-PRXL2A",
    "gene-PRXL2B",
    "gene-PRXL2C",
    "gene-PRY",
    "gene-PRY2",
    "gene-PSAP",
    "gene-PSAPL1",
    "gene-PSAT1",
    "gene-PSCA",
    "gene-PSD",
    "gene-PSD2",
    "gene-PSD3",
    "gene-PSD4",
    "gene-PSEN1",
    "gene-PSEN2",
    "gene-PSENEN",
    "gene-PSG1",
    "gene-PSG11",
    "gene-PSG2",
    "gene-PSG3",
    "gene-PSG4",
    "gene-PSG5",
    "gene-PSG6",
    "gene-PSG7",
    "gene-PSG8",
    "gene-PSG9",
    "gene-PSIP1",
    "gene-PSKH1",
    "gene-PSKH2",
    "gene-PSMA1",
    "gene-PSMA2",
    "gene-PSMA3",
    "gene-PSMA4",
    "gene-PSMA5",
    "gene-PSMA6",
    "gene-PSMA7",
    "gene-PSMA8",
    "gene-PSMB1",
    "gene-PSMB10",
    "gene-PSMB11",
    "gene-PSMB2",
    "gene-PSMB3",
    "gene-PSMB4",
    "gene-PSMB5",
    "gene-PSMB6",
    "gene-PSMB7",
    "gene-PSMB8",
    "gene-PSMB9",
    "gene-PSMC1",
    "gene-PSMC2",
    "gene-PSMC3",
    "gene-PSMC3IP",
    "gene-PSMC4",
    "gene-PSMC5",
    "gene-PSMC6",
    "gene-PSMD1",
    "gene-PSMD10",
    "gene-PSMD11",
    "gene-PSMD12",
    "gene-PSMD13",
    "gene-PSMD14",
    "gene-PSMD2",
    "gene-PSMD3",
    "gene-PSMD4",
    "gene-PSMD5",
    "gene-PSMD6",
    "gene-PSMD7",
    "gene-PSMD8",
    "gene-PSMD9",
    "gene-PSME1",
    "gene-PSME2",
    "gene-PSME3",
    "gene-PSME3IP1",
    "gene-PSME4",
    "gene-PSMF1",
    "gene-PSMG1",
    "gene-PSMG2",
    "gene-PSMG3",
    "gene-PSMG4",
    "gene-PSORS1C1",
    "gene-PSORS1C2",
    "gene-PSPC1",
    "gene-PSPH",
    "gene-PSPN",
    "gene-PSRC1",
    "gene-PSTK",
    "gene-PSTPIP1",
    "gene-PSTPIP2",
    "gene-PTAFR",
    "gene-PTAR1",
    "gene-PTBP1",
    "gene-PTBP2",
    "gene-PTBP3",
    "gene-PTCD1",
    "gene-PTCD2",
    "gene-PTCD3",
    "gene-PTCH1",
    "gene-PTCH2",
    "gene-PTCHD1",
    "gene-PTCHD3",
    "gene-PTCHD4",
    "gene-PTCRA",
    "gene-PTDSS1",
    "gene-PTDSS2",
    "gene-PTEN",
    "gene-PTER",
    "gene-PTF1A",
    "gene-PTGDR",
    "gene-PTGDR2",
    "gene-PTGDS",
    "gene-PTGER1",
    "gene-PTGER2",
    "gene-PTGER3",
    "gene-PTGER4",
    "gene-PTGES",
    "gene-PTGES2",
    "gene-PTGES3",
    "gene-PTGES3L",
    "gene-PTGFR",
    "gene-PTGFRN",
    "gene-PTGIR",
    "gene-PTGIS",
    "gene-PTGR1",
    "gene-PTGR2",
    "gene-PTGR3",
    "gene-PTGS1",
    "gene-PTGS2",
    "gene-PTH",
    "gene-PTH1R",
    "gene-PTH2",
    "gene-PTH2R",
    "gene-PTHLH",
    "gene-PTK2",
    "gene-PTK2B",
    "gene-PTK6",
    "gene-PTK7",
    "gene-PTMA",
    "gene-PTMS",
    "gene-PTN",
    "gene-PTOV1",
    "gene-PTP4A1",
    "gene-PTP4A2",
    "gene-PTP4A3",
    "gene-PTPA",
    "gene-PTPDC1",
    "gene-PTPMT1",
    "gene-PTPN1",
    "gene-PTPN11",
    "gene-PTPN12",
    "gene-PTPN13",
    "gene-PTPN14",
    "gene-PTPN18",
    "gene-PTPN2",
    "gene-PTPN20",
    "gene-PTPN21",
    "gene-PTPN22",
    "gene-PTPN23",
    "gene-PTPN3",
    "gene-PTPN4",
    "gene-PTPN5",
    "gene-PTPN6",
    "gene-PTPN7",
    "gene-PTPN9",
    "gene-PTPRA",
    "gene-PTPRB",
    "gene-PTPRC",
    "gene-PTPRCAP",
    "gene-PTPRD",
    "gene-PTPRE",
    "gene-PTPRF",
    "gene-PTPRG",
    "gene-PTPRH",
    "gene-PTPRJ",
    "gene-PTPRK",
    "gene-PTPRM",
    "gene-PTPRN",
    "gene-PTPRN2",
    "gene-PTPRO",
    "gene-PTPRQ",
    "gene-PTPRR",
    "gene-PTPRS",
    "gene-PTPRT",
    "gene-PTPRU",
    "gene-PTPRZ1",
    "gene-PTRH1",
    "gene-PTRH2",
    "gene-PTRHD1",
    "gene-PTS",
    "gene-PTTG1",
    "gene-PTTG1IP",
    "gene-PTTG1IP2",
    "gene-PTTG2",
    "gene-PTX3",
    "gene-PTX4",
    "gene-PUDP",
    "gene-PUF60",
    "gene-PUM1",
    "gene-PUM2",
    "gene-PUM3",
    "gene-PURA",
    "gene-PURB",
    "gene-PURG",
    "gene-PUS1",
    "gene-PUS10",
    "gene-PUS3",
    "gene-PUS7",
    "gene-PUS7L",
    "gene-PUSL1",
    "gene-PVALB",
    "gene-PVALEF",
    "gene-PVR",
    "gene-PVRIG",
    "gene-PWP1",
    "gene-PWP2",
    "gene-PWWP2A",
    "gene-PWWP2B",
    "gene-PWWP3A",
    "gene-PWWP3B",
    "gene-PWWP4",
    "gene-PXDC1",
    "gene-PXDN",
    "gene-PXDNL",
    "gene-PXK",
    "gene-PXMP2",
    "gene-PXMP4",
    "gene-PXN",
    "gene-PXT1",
    "gene-PXYLP1",
    "gene-PYCARD",
    "gene-PYCR1",
    "gene-PYCR2",
    "gene-PYCR3",
    "gene-PYDC1",
    "gene-PYDC2",
    "gene-PYDC5",
    "gene-PYGB",
    "gene-PYGL",
    "gene-PYGM",
    "gene-PYGO1",
    "gene-PYGO2",
    "gene-PYHIN1",
    "gene-PYM1",
    "gene-PYROXD1",
    "gene-PYROXD2",
    "gene-PYURF",
    "gene-PYY",
    "gene-PZP",
    "gene-QARS1",
    "gene-QDPR",
    "gene-QKI",
    "gene-QNG1",
    "gene-QPCT",
    "gene-QPCTL",
    "gene-QPRT",
    "gene-QRFP",
    "gene-QRFPR",
    "gene-QRICH1",
    "gene-QRICH2",
    "gene-QRSL1",
    "gene-QSER1",
    "gene-QSOX1",
    "gene-QSOX2",
    "gene-QTRT1",
    "gene-QTRT2",
    "gene-R3HCC1",
    "gene-R3HCC1L",
    "gene-R3HDM1",
    "gene-R3HDM2",
    "gene-R3HDM4",
    "gene-R3HDML",
    "gene-RAB10",
    "gene-RAB11A",
    "gene-RAB11B",
    "gene-RAB11FIP1",
    "gene-RAB11FIP2",
    "gene-RAB11FIP3",
    "gene-RAB11FIP4",
    "gene-RAB11FIP5",
    "gene-RAB12",
    "gene-RAB13",
    "gene-RAB14",
    "gene-RAB15",
    "gene-RAB17",
    "gene-RAB18",
    "gene-RAB19",
    "gene-RAB1A",
    "gene-RAB1B",
    "gene-RAB20",
    "gene-RAB21",
    "gene-RAB22A",
    "gene-RAB23",
    "gene-RAB24",
    "gene-RAB25",
    "gene-RAB26",
    "gene-RAB27A",
    "gene-RAB27B",
    "gene-RAB28",
    "gene-RAB29",
    "gene-RAB2A",
    "gene-RAB2B",
    "gene-RAB30",
    "gene-RAB31",
    "gene-RAB32",
    "gene-RAB33A",
    "gene-RAB33B",
    "gene-RAB34",
    "gene-RAB35",
    "gene-RAB36",
    "gene-RAB37",
    "gene-RAB38",
    "gene-RAB39A",
    "gene-RAB39B",
    "gene-RAB3A",
    "gene-RAB3B",
    "gene-RAB3C",
    "gene-RAB3D",
    "gene-RAB3GAP1",
    "gene-RAB3GAP2",
    "gene-RAB3IL1",
    "gene-RAB3IP",
    "gene-RAB40A",
    "gene-RAB40AL",
    "gene-RAB40B",
    "gene-RAB40C",
    "gene-RAB41",
    "gene-RAB42",
    "gene-RAB43",
    "gene-RAB44",
    "gene-RAB4A",
    "gene-RAB4B",
    "gene-RAB5A",
    "gene-RAB5B",
    "gene-RAB5C",
    "gene-RAB5IF",
    "gene-RAB6A",
    "gene-RAB6B",
    "gene-RAB6C",
    "gene-RAB6D",
    "gene-RAB7A",
    "gene-RAB7B",
    "gene-RAB8A",
    "gene-RAB8B",
    "gene-RAB9A",
    "gene-RAB9B",
    "gene-RABAC1",
    "gene-RABEP1",
    "gene-RABEP2",
    "gene-RABEPK",
    "gene-RABGAP1",
    "gene-RABGAP1L",
    "gene-RABGEF1",
    "gene-RABGGTA",
    "gene-RABGGTB",
    "gene-RABIF",
    "gene-RABL2A",
    "gene-RABL2B",
    "gene-RABL3",
    "gene-RABL6",
    "gene-RAC1",
    "gene-RAC2",
    "gene-RAC3",
    "gene-RACGAP1",
    "gene-RACK1",
    "gene-RAD1",
    "gene-RAD17",
    "gene-RAD18",
    "gene-RAD21",
    "gene-RAD21L1",
    "gene-RAD23A",
    "gene-RAD23B",
    "gene-RAD50",
    "gene-RAD51",
    "gene-RAD51AP1",
    "gene-RAD51AP2",
    "gene-RAD51B",
    "gene-RAD51C",
    "gene-RAD51D",
    "gene-RAD52",
    "gene-RAD54B",
    "gene-RAD54L",
    "gene-RAD54L2",
    "gene-RAD9A",
    "gene-RAD9B",
    "gene-RADIL",
    "gene-RADX",
    "gene-RAE1",
    "gene-RAET1E",
    "gene-RAET1G",
    "gene-RAET1L",
    "gene-RAF1",
    "gene-RAG1",
    "gene-RAG2",
    "gene-RAI1",
    "gene-RAI14",
    "gene-RAI2",
    "gene-RALA",
    "gene-RALB",
    "gene-RALBP1",
    "gene-RALGAPA1",
    "gene-RALGAPA2",
    "gene-RALGAPB",
    "gene-RALGDS",
    "gene-RALGPS1",
    "gene-RALGPS2",
    "gene-RALY",
    "gene-RALYL",
    "gene-RAMAC",
    "gene-RAMACL",
    "gene-RAMP1",
    "gene-RAMP2",
    "gene-RAMP3",
    "gene-RAN",
    "gene-RANBP1",
    "gene-RANBP10",
    "gene-RANBP17",
    "gene-RANBP2",
    "gene-RANBP3",
    "gene-RANBP3L",
    "gene-RANBP6",
    "gene-RANBP9",
    "gene-RANGAP1",
    "gene-RANGRF",
    "gene-RAP1A",
    "gene-RAP1B",
    "gene-RAP1GAP",
    "gene-RAP1GAP2",
    "gene-RAP1GDS1",
    "gene-RAP2A",
    "gene-RAP2B",
    "gene-RAP2C",
    "gene-RAPGEF1",
    "gene-RAPGEF2",
    "gene-RAPGEF3",
    "gene-RAPGEF4",
    "gene-RAPGEF5",
    "gene-RAPGEF6",
    "gene-RAPGEFL1",
    "gene-RAPH1",
    "gene-RAPSN",
    "gene-RARA",
    "gene-RARB",
    "gene-RARG",
    "gene-RARRES1",
    "gene-RARRES2",
    "gene-RARS1",
    "gene-RARS2",
    "gene-RASA1",
    "gene-RASA2",
    "gene-RASA3",
    "gene-RASA4",
    "gene-RASA4B",
    "gene-RASAL1",
    "gene-RASAL2",
    "gene-RASAL3",
    "gene-RASD1",
    "gene-RASD2",
    "gene-RASEF",
    "gene-RASGEF1A",
    "gene-RASGEF1B",
    "gene-RASGEF1C",
    "gene-RASGRF1",
    "gene-RASGRF2",
    "gene-RASGRP1",
    "gene-RASGRP2",
    "gene-RASGRP3",
    "gene-RASGRP4",
    "gene-RASIP1",
    "gene-RASL10A",
    "gene-RASL10B",
    "gene-RASL11A",
    "gene-RASL11B",
    "gene-RASL12",
    "gene-RASSF1",
    "gene-RASSF10",
    "gene-RASSF2",
    "gene-RASSF3",
    "gene-RASSF4",
    "gene-RASSF5",
    "gene-RASSF6",
    "gene-RASSF7",
    "gene-RASSF8",
    "gene-RASSF9",
    "gene-RAVER1",
    "gene-RAVER2",
    "gene-RAX",
    "gene-RAX2",
    "gene-RB1",
    "gene-RB1CC1",
    "gene-RBAK",
    "gene-RBBP4",
    "gene-RBBP5",
    "gene-RBBP6",
    "gene-RBBP7",
    "gene-RBBP8",
    "gene-RBBP8NL",
    "gene-RBBP9",
    "gene-RBCK1",
    "gene-RBFA",
    "gene-RBFOX1",
    "gene-RBFOX2",
    "gene-RBFOX3",
    "gene-RBIS",
    "gene-RBKS",
    "gene-RBL1",
    "gene-RBL2",
    "gene-RBM10",
    "gene-RBM11",
    "gene-RBM12",
    "gene-RBM12B",
    "gene-RBM14",
    "gene-RBM15",
    "gene-RBM15B",
    "gene-RBM17",
    "gene-RBM18",
    "gene-RBM19",
    "gene-RBM20",
    "gene-RBM22",
    "gene-RBM23",
    "gene-RBM24",
    "gene-RBM25",
    "gene-RBM26",
    "gene-RBM27",
    "gene-RBM28",
    "gene-RBM3",
    "gene-RBM33",
    "gene-RBM34",
    "gene-RBM38",
    "gene-RBM39",
    "gene-RBM4",
    "gene-RBM41",
    "gene-RBM42",
    "gene-RBM43",
    "gene-RBM44",
    "gene-RBM45",
    "gene-RBM46",
    "gene-RBM47",
    "gene-RBM48",
    "gene-RBM4B",
    "gene-RBM5",
    "gene-RBM6",
    "gene-RBM7",
    "gene-RBM8A",
    "gene-RBMS1",
    "gene-RBMS2",
    "gene-RBMS3",
    "gene-RBMX",
    "gene-RBMX2",
    "gene-RBMXL1",
    "gene-RBMXL2",
    "gene-RBMXL3",
    "gene-RBMY1A1",
    "gene-RBMY1B",
    "gene-RBMY1C",
    "gene-RBMY1D",
    "gene-RBMY1E",
    "gene-RBMY1F",
    "gene-RBMY1J",
    "gene-RBP1",
    "gene-RBP2",
    "gene-RBP3",
    "gene-RBP4",
    "gene-RBP5",
    "gene-RBP7",
    "gene-RBPJ",
    "gene-RBPJL",
    "gene-RBPMS",
    "gene-RBPMS2",
    "gene-RBSN",
    "gene-RBX1",
    "gene-RC3H1",
    "gene-RC3H2",
    "gene-RCAN1",
    "gene-RCAN2",
    "gene-RCAN3",
    "gene-RCBTB1",
    "gene-RCBTB2",
    "gene-RCC1",
    "gene-RCC1L",
    "gene-RCC2",
    "gene-RCCD1",
    "gene-RCE1",
    "gene-RCHY1",
    "gene-RCL1",
    "gene-RCN1",
    "gene-RCN2",
    "gene-RCN3",
    "gene-RCOR1",
    "gene-RCOR2",
    "gene-RCOR3",
    "gene-RCSD1",
    "gene-RCVRN",
    "gene-RD3",
    "gene-RD3L",
    "gene-RDH10",
    "gene-RDH11",
    "gene-RDH12",
    "gene-RDH13",
    "gene-RDH14",
    "gene-RDH16",
    "gene-RDH5",
    "gene-RDH8",
    "gene-RDM1",
    "gene-RDX",
    "gene-REC114",
    "gene-REC8",
    "gene-RECK",
    "gene-RECQL",
    "gene-RECQL4",
    "gene-RECQL5",
    "gene-REDIC1",
    "gene-REELD1",
    "gene-REEP1",
    "gene-REEP2",
    "gene-REEP3",
    "gene-REEP4",
    "gene-REEP5",
    "gene-REEP6",
    "gene-REG1A",
    "gene-REG1B",
    "gene-REG3A",
    "gene-REG3G",
    "gene-REG4",
    "gene-REL",
    "gene-RELA",
    "gene-RELB",
    "gene-RELCH",
    "gene-RELL1",
    "gene-RELL2",
    "gene-RELN",
    "gene-RELT",
    "gene-REM1",
    "gene-REM2",
    "gene-REN",
    "gene-RENBP",
    "gene-REP15",
    "gene-REPIN1",
    "gene-REPS1",
    "gene-REPS2",
    "gene-RER1",
    "gene-RERE",
    "gene-RERG",
    "gene-RERGL",
    "gene-RESF1",
    "gene-RESP18",
    "gene-REST",
    "gene-RET",
    "gene-RETN",
    "gene-RETNLB",
    "gene-RETREG1",
    "gene-RETREG2",
    "gene-RETREG3",
    "gene-RETSAT",
    "gene-REV1",
    "gene-REV3L",
    "gene-REX1BD",
    "gene-REXO1",
    "gene-REXO2",
    "gene-REXO4",
    "gene-REXO5",
    "gene-RFC1",
    "gene-RFC2",
    "gene-RFC3",
    "gene-RFC4",
    "gene-RFC5",
    "gene-RFESD",
    "gene-RFFL",
    "gene-RFK",
    "gene-RFLNA",
    "gene-RFLNB",
    "gene-RFNG",
    "gene-RFPL1",
    "gene-RFPL2",
    "gene-RFPL3",
    "gene-RFPL4A",
    "gene-RFPL4AL1",
    "gene-RFPL4B",
    "gene-RFT1",
    "gene-RFTN1",
    "gene-RFTN2",
    "gene-RFWD3",
    "gene-RFX1",
    "gene-RFX2",
    "gene-RFX3",
    "gene-RFX4",
    "gene-RFX5",
    "gene-RFX6",
    "gene-RFX7",
    "gene-RFX8",
    "gene-RFXANK",
    "gene-RFXAP",
    "gene-RGCC",
    "gene-RGL1",
    "gene-RGL2",
    "gene-RGL3",
    "gene-RGL4",
    "gene-RGMA",
    "gene-RGMB",
    "gene-RGN",
    "gene-RGP1",
    "gene-RGPD1",
    "gene-RGPD2",
    "gene-RGPD3",
    "gene-RGPD4",
    "gene-RGPD5",
    "gene-RGPD6",
    "gene-RGPD8",
    "gene-RGR",
    "gene-RGS1",
    "gene-RGS10",
    "gene-RGS11",
    "gene-RGS12",
    "gene-RGS13",
    "gene-RGS14",
    "gene-RGS16",
    "gene-RGS17",
    "gene-RGS18",
    "gene-RGS19",
    "gene-RGS2",
    "gene-RGS20",
    "gene-RGS21",
    "gene-RGS22",
    "gene-RGS3",
    "gene-RGS4",
    "gene-RGS5",
    "gene-RGS6",
    "gene-RGS7",
    "gene-RGS7BP",
    "gene-RGS8",
    "gene-RGS9",
    "gene-RGS9BP",
    "gene-RGSL1",
    "gene-RHAG",
    "gene-RHBDD1",
    "gene-RHBDD2",
    "gene-RHBDD3",
    "gene-RHBDF1",
    "gene-RHBDF2",
    "gene-RHBDL1",
    "gene-RHBDL2",
    "gene-RHBDL3",
    "gene-RHBG",
    "gene-RHCE",
    "gene-RHCG",
    "gene-RHD",
    "gene-RHEB",
    "gene-RHEBL1",
    "gene-RHEX",
    "gene-RHNO1",
    "gene-RHO",
    "gene-RHOA",
    "gene-RHOB",
    "gene-RHOBTB1",
    "gene-RHOBTB2",
    "gene-RHOBTB3",
    "gene-RHOC",
    "gene-RHOD",
    "gene-RHOF",
    "gene-RHOG",
    "gene-RHOH",
    "gene-RHOJ",
    "gene-RHOQ",
    "gene-RHOT1",
    "gene-RHOT2",
    "gene-RHOU",
    "gene-RHOV",
    "gene-RHOXF1",
    "gene-RHOXF2",
    "gene-RHOXF2B",
    "gene-RHPN1",
    "gene-RHPN2",
    "gene-RIBC1",
    "gene-RIBC2",
    "gene-RIC1",
    "gene-RIC3",
    "gene-RIC8A",
    "gene-RIC8B",
    "gene-RICTOR",
    "gene-RIDA",
    "gene-RIF1",
    "gene-RIGI",
    "gene-RIIAD1",
    "gene-RILP",
    "gene-RILPL1",
    "gene-RILPL2",
    "gene-RIMBP2",
    "gene-RIMBP3",
    "gene-RIMBP3B",
    "gene-RIMBP3C",
    "gene-RIMKLA",
    "gene-RIMKLB",
    "gene-RIMOC1",
    "gene-RIMS1",
    "gene-RIMS2",
    "gene-RIMS3",
    "gene-RIMS4",
    "gene-RIN1",
    "gene-RIN2",
    "gene-RIN3",
    "gene-RING1",
    "gene-RINL",
    "gene-RINT1",
    "gene-RIOK1",
    "gene-RIOK2",
    "gene-RIOK3",
    "gene-RIOX1",
    "gene-RIOX2",
    "gene-RIPK1",
    "gene-RIPK2",
    "gene-RIPK3",
    "gene-RIPK4",
    "gene-RIPOR1",
    "gene-RIPOR2",
    "gene-RIPOR3",
    "gene-RIPPLY1",
    "gene-RIPPLY2",
    "gene-RIPPLY3",
    "gene-RIT1",
    "gene-RIT2",
    "gene-RITA1",
    "gene-RLBP1",
    "gene-RLF",
    "gene-RLIG1",
    "gene-RLIM",
    "gene-RLN1",
    "gene-RLN2",
    "gene-RLN3",
    "gene-RMC1",
    "gene-RMDN1",
    "gene-RMDN2",
    "gene-RMDN3",
    "gene-RMI1",
    "gene-RMI2",
    "gene-RMND1",
    "gene-RMND5A",
    "gene-RMND5B",
    "gene-RNASE1",
    "gene-RNASE10",
    "gene-RNASE11",
    "gene-RNASE12",
    "gene-RNASE13",
    "gene-RNASE2",
    "gene-RNASE3",
    "gene-RNASE4",
    "gene-RNASE6",
    "gene-RNASE7",
    "gene-RNASE8",
    "gene-RNASE9",
    "gene-RNASEH1",
    "gene-RNASEH2A",
    "gene-RNASEH2B",
    "gene-RNASEH2C",
    "gene-RNASEK",
    "gene-RNASEL",
    "gene-RNASET2",
    "gene-RND1",
    "gene-RND2",
    "gene-RND3",
    "gene-RNF10",
    "gene-RNF103",
    "gene-RNF11",
    "gene-RNF111",
    "gene-RNF112",
    "gene-RNF113A",
    "gene-RNF113B",
    "gene-RNF114",
    "gene-RNF115",
    "gene-RNF121",
    "gene-RNF122",
    "gene-RNF123",
    "gene-RNF125",
    "gene-RNF126",
    "gene-RNF128",
    "gene-RNF13",
    "gene-RNF130",
    "gene-RNF133",
    "gene-RNF135",
    "gene-RNF138",
    "gene-RNF139",
    "gene-RNF14",
    "gene-RNF141",
    "gene-RNF144A",
    "gene-RNF144B",
    "gene-RNF145",
    "gene-RNF146",
    "gene-RNF148",
    "gene-RNF149",
    "gene-RNF150",
    "gene-RNF151",
    "gene-RNF152",
    "gene-RNF157",
    "gene-RNF166",
    "gene-RNF167",
    "gene-RNF168",
    "gene-RNF169",
    "gene-RNF17",
    "gene-RNF170",
    "gene-RNF175",
    "gene-RNF180",
    "gene-RNF181",
    "gene-RNF182",
    "gene-RNF183",
    "gene-RNF185",
    "gene-RNF186",
    "gene-RNF187",
    "gene-RNF19A",
    "gene-RNF19B",
    "gene-RNF2",
    "gene-RNF20",
    "gene-RNF207",
    "gene-RNF208",
    "gene-RNF212",
    "gene-RNF212B",
    "gene-RNF213",
    "gene-RNF214",
    "gene-RNF215",
    "gene-RNF216",
    "gene-RNF217",
    "gene-RNF220",
    "gene-RNF222",
    "gene-RNF223",
    "gene-RNF224",
    "gene-RNF225",
    "gene-RNF227",
    "gene-RNF24",
    "gene-RNF25",
    "gene-RNF26",
    "gene-RNF31",
    "gene-RNF32",
    "gene-RNF34",
    "gene-RNF38",
    "gene-RNF39",
    "gene-RNF4",
    "gene-RNF40",
    "gene-RNF41",
    "gene-RNF43",
    "gene-RNF44",
    "gene-RNF5",
    "gene-RNF6",
    "gene-RNF7",
    "gene-RNF8",
    "gene-RNFT1",
    "gene-RNFT2",
    "gene-RNGTT",
    "gene-RNH1",
    "gene-RNLS",
    "gene-RNMT",
    "gene-RNPC3",
    "gene-RNPEP",
    "gene-RNPEPL1",
    "gene-RNPS1",
    "gene-RO60",
    "gene-ROBO1",
    "gene-ROBO2",
    "gene-ROBO3",
    "gene-ROBO4",
    "gene-ROCK1",
    "gene-ROCK2",
    "gene-ROGDI",
    "gene-ROM1",
    "gene-ROMO1",
    "gene-ROPN1",
    "gene-ROPN1B",
    "gene-ROPN1L",
    "gene-ROR1",
    "gene-ROR2",
    "gene-RORA",
    "gene-RORB",
    "gene-RORC",
    "gene-ROS1",
    "gene-RP1",
    "gene-RP1L1",
    "gene-RP2",
    "gene-RP9",
    "gene-RPA1",
    "gene-RPA2",
    "gene-RPA3",
    "gene-RPA4",
    "gene-RPAIN",
    "gene-RPAP1",
    "gene-RPAP2",
    "gene-RPAP3",
    "gene-RPE",
    "gene-RPE65",
    "gene-RPEL1",
    "gene-RPF1",
    "gene-RPF2",
    "gene-RPGR",
    "gene-RPGRIP1",
    "gene-RPGRIP1L",
    "gene-RPH3A",
    "gene-RPH3AL",
    "gene-RPIA",
    "gene-RPL10",
    "gene-RPL10A",
    "gene-RPL10L",
    "gene-RPL11",
    "gene-RPL12",
    "gene-RPL13",
    "gene-RPL13A",
    "gene-RPL14",
    "gene-RPL15",
    "gene-RPL17",
    "gene-RPL18",
    "gene-RPL18A",
    "gene-RPL19",
    "gene-RPL21",
    "gene-RPL22",
    "gene-RPL22L1",
    "gene-RPL23",
    "gene-RPL23A",
    "gene-RPL24",
    "gene-RPL26",
    "gene-RPL26L1",
    "gene-RPL27",
    "gene-RPL27A",
    "gene-RPL28",
    "gene-RPL29",
    "gene-RPL3",
    "gene-RPL30",
    "gene-RPL31",
    "gene-RPL32",
    "gene-RPL34",
    "gene-RPL35",
    "gene-RPL35A",
    "gene-RPL36",
    "gene-RPL36A",
    "gene-RPL36AL",
    "gene-RPL37",
    "gene-RPL37A",
    "gene-RPL38",
    "gene-RPL39",
    "gene-RPL39L",
    "gene-RPL3L",
    "gene-RPL4",
    "gene-RPL41",
    "gene-RPL5",
    "gene-RPL6",
    "gene-RPL7",
    "gene-RPL7A",
    "gene-RPL7L1",
    "gene-RPL8",
    "gene-RPL9",
    "gene-RPLP0",
    "gene-RPLP1",
    "gene-RPLP2",
    "gene-RPN1",
    "gene-RPN2",
    "gene-RPP14",
    "gene-RPP21",
    "gene-RPP25",
    "gene-RPP25L",
    "gene-RPP30",
    "gene-RPP38",
    "gene-RPP40",
    "gene-RPRD1A",
    "gene-RPRD1B",
    "gene-RPRD2",
    "gene-RPRM",
    "gene-RPRML",
    "gene-RPS10",
    "gene-RPS11",
    "gene-RPS12",
    "gene-RPS13",
    "gene-RPS14",
    "gene-RPS15",
    "gene-RPS15A",
    "gene-RPS16",
    "gene-RPS17",
    "gene-RPS18",
    "gene-RPS19",
    "gene-RPS19BP1",
    "gene-RPS2",
    "gene-RPS20",
    "gene-RPS21",
    "gene-RPS23",
    "gene-RPS24",
    "gene-RPS25",
    "gene-RPS26",
    "gene-RPS27",
    "gene-RPS27A",
    "gene-RPS27L",
    "gene-RPS28",
    "gene-RPS29",
    "gene-RPS3",
    "gene-RPS3A",
    "gene-RPS4X",
    "gene-RPS4Y1",
    "gene-RPS4Y2",
    "gene-RPS5",
    "gene-RPS6",
    "gene-RPS6KA1",
    "gene-RPS6KA2",
    "gene-RPS6KA3",
    "gene-RPS6KA4",
    "gene-RPS6KA5",
    "gene-RPS6KA6",
    "gene-RPS6KB1",
    "gene-RPS6KB2",
    "gene-RPS6KC1",
    "gene-RPS6KL1",
    "gene-RPS7",
    "gene-RPS8",
    "gene-RPS9",
    "gene-RPSA",
    "gene-RPSA2",
    "gene-RPTN",
    "gene-RPTOR",
    "gene-RPUSD1",
    "gene-RPUSD2",
    "gene-RPUSD3",
    "gene-RPUSD4",
    "gene-RRAD",
    "gene-RRAGA",
    "gene-RRAGB",
    "gene-RRAGC",
    "gene-RRAGD",
    "gene-RRAS",
    "gene-RRAS2",
    "gene-RRBP1",
    "gene-RREB1",
    "gene-RRH",
    "gene-RRM1",
    "gene-RRM2",
    "gene-RRM2B",
    "gene-RRN3",
    "gene-RRP1",
    "gene-RRP12",
    "gene-RRP15",
    "gene-RRP1B",
    "gene-RRP36",
    "gene-RRP7A",
    "gene-RRP8",
    "gene-RRP9",
    "gene-RRS1",
    "gene-RS1",
    "gene-RSAD1",
    "gene-RSAD2",
    "gene-RSBN1",
    "gene-RSBN1L",
    "gene-RSC1A1",
    "gene-RSF1",
    "gene-RSKR",
    "gene-RSL1D1",
    "gene-RSL24D1",
    "gene-RSPH1",
    "gene-RSPH10B",
    "gene-RSPH10B2",
    "gene-RSPH14",
    "gene-RSPH3",
    "gene-RSPH4A",
    "gene-RSPH6A",
    "gene-RSPH9",
    "gene-RSPO1",
    "gene-RSPO2",
    "gene-RSPO3",
    "gene-RSPO4",
    "gene-RSPRY1",
    "gene-RSRC1",
    "gene-RSRC2",
    "gene-RSRP1",
    "gene-RSU1",
    "gene-RTBDN",
    "gene-RTCA",
    "gene-RTCB",
    "gene-RTEL1",
    "gene-RTF1",
    "gene-RTF2",
    "gene-RTKN",
    "gene-RTKN2",
    "gene-RTL1",
    "gene-RTL10",
    "gene-RTL3",
    "gene-RTL4",
    "gene-RTL5",
    "gene-RTL6",
    "gene-RTL8A",
    "gene-RTL8B",
    "gene-RTL8C",
    "gene-RTL9",
    "gene-RTN1",
    "gene-RTN2",
    "gene-RTN3",
    "gene-RTN4",
    "gene-RTN4IP1",
    "gene-RTN4R",
    "gene-RTN4RL1",
    "gene-RTN4RL2",
    "gene-RTP1",
    "gene-RTP2",
    "gene-RTP3",
    "gene-RTP4",
    "gene-RTP5",
    "gene-RTRAF",
    "gene-RTTN",
    "gene-RUBCN",
    "gene-RUBCNL",
    "gene-RUFY1",
    "gene-RUFY2",
    "gene-RUFY3",
    "gene-RUFY4",
    "gene-RUNDC1",
    "gene-RUNDC3A",
    "gene-RUNDC3B",
    "gene-RUNX1",
    "gene-RUNX1T1",
    "gene-RUNX2",
    "gene-RUNX3",
    "gene-RUSC1",
    "gene-RUSC2",
    "gene-RUSF1",
    "gene-RUVBL1",
    "gene-RUVBL2",
    "gene-RWDD1",
    "gene-RWDD2A",
    "gene-RWDD2B",
    "gene-RWDD3",
    "gene-RWDD4",
    "gene-RXFP1",
    "gene-RXFP2",
    "gene-RXFP3",
    "gene-RXFP4",
    "gene-RXRA",
    "gene-RXRB",
    "gene-RXRG",
    "gene-RXYLT1",
    "gene-RYBP",
    "gene-RYK",
    "gene-RYR1",
    "gene-RYR2",
    "gene-RYR3",
    "gene-S100A1",
    "gene-S100A10",
    "gene-S100A11",
    "gene-S100A12",
    "gene-S100A13",
    "gene-S100A14",
    "gene-S100A16",
    "gene-S100A2",
    "gene-S100A3",
    "gene-S100A4",
    "gene-S100A5",
    "gene-S100A6",
    "gene-S100A7",
    "gene-S100A7A",
    "gene-S100A8",
    "gene-S100A9",
    "gene-S100B",
    "gene-S100G",
    "gene-S100P",
    "gene-S100PBP",
    "gene-S100Z",
    "gene-S1PR1",
    "gene-S1PR2",
    "gene-S1PR3",
    "gene-S1PR4",
    "gene-S1PR5",
    "gene-SAA1",
    "gene-SAA2",
    "gene-SAA4",
    "gene-SAAL1",
    "gene-SAC3D1",
    "gene-SACM1L",
    "gene-SACS",
    "gene-SAE1",
    "gene-SAFB",
    "gene-SAFB2",
    "gene-SAG",
    "gene-SAGE1",
    "gene-SALL1",
    "gene-SALL2",
    "gene-SALL3",
    "gene-SALL4",
    "gene-SAMD1",
    "gene-SAMD10",
    "gene-SAMD11",
    "gene-SAMD12",
    "gene-SAMD13",
    "gene-SAMD14",
    "gene-SAMD15",
    "gene-SAMD3",
    "gene-SAMD4A",
    "gene-SAMD4B",
    "gene-SAMD5",
    "gene-SAMD7",
    "gene-SAMD8",
    "gene-SAMD9",
    "gene-SAMD9L",
    "gene-SAMHD1",
    "gene-SAMM50",
    "gene-SAMSN1",
    "gene-SANBR",
    "gene-SAP130",
    "gene-SAP18",
    "gene-SAP25",
    "gene-SAP30",
    "gene-SAP30BP",
    "gene-SAP30L",
    "gene-SAPCD1",
    "gene-SAPCD2",
    "gene-SAR1A",
    "gene-SAR1B",
    "gene-SARAF",
    "gene-SARDH",
    "gene-SARM1",
    "gene-SARNP",
    "gene-SARS1",
    "gene-SARS2",
    "gene-SART1",
    "gene-SART3",
    "gene-SASH1",
    "gene-SASH3",
    "gene-SASS6",
    "gene-SAT1",
    "gene-SAT2",
    "gene-SATB1",
    "gene-SATB2",
    "gene-SATL1",
    "gene-SAV1",
    "gene-SAXO1",
    "gene-SAXO2",
    "gene-SAXO3",
    "gene-SAXO4",
    "gene-SAXO5",
    "gene-SAYSD1",
    "gene-SBDS",
    "gene-SBF1",
    "gene-SBF2",
    "gene-SBK1",
    "gene-SBK2",
    "gene-SBK3",
    "gene-SBNO1",
    "gene-SBNO2",
    "gene-SBSN",
    "gene-SBSPON",
    "gene-SC5D",
    "gene-SCAF1",
    "gene-SCAF11",
    "gene-SCAF4",
    "gene-SCAF8",
    "gene-SCAI",
    "gene-SCAMP1",
    "gene-SCAMP2",
    "gene-SCAMP3",
    "gene-SCAMP4",
    "gene-SCAMP5",
    "gene-SCAND1",
    "gene-SCAND3",
    "gene-SCAP",
    "gene-SCAPER",
    "gene-SCARA3",
    "gene-SCARA5",
    "gene-SCARB1",
    "gene-SCARB2",
    "gene-SCARF1",
    "gene-SCARF2",
    "gene-SCART1",
    "gene-SCCPDH",
    "gene-SCD",
    "gene-SCD5",
    "gene-SCEL",
    "gene-SCFD1",
    "gene-SCFD2",
    "gene-SCG2",
    "gene-SCG3",
    "gene-SCG5",
    "gene-SCGB1A1",
    "gene-SCGB1C1",
    "gene-SCGB1C2",
    "gene-SCGB1D1",
    "gene-SCGB1D2",
    "gene-SCGB1D4",
    "gene-SCGB2A1",
    "gene-SCGB2A2",
    "gene-SCGB2B2",
    "gene-SCGB3A1",
    "gene-SCGB3A2",
    "gene-SCGN",
    "gene-SCHIP1",
    "gene-SCIMP",
    "gene-SCIN",
    "gene-SCLT1",
    "gene-SCLY",
    "gene-SCMH1",
    "gene-SCML1",
    "gene-SCML2",
    "gene-SCML4",
    "gene-SCN10A",
    "gene-SCN11A",
    "gene-SCN1A",
    "gene-SCN1B",
    "gene-SCN2A",
    "gene-SCN2B",
    "gene-SCN3A",
    "gene-SCN3B",
    "gene-SCN4A",
    "gene-SCN4B",
    "gene-SCN5A",
    "gene-SCN7A",
    "gene-SCN8A",
    "gene-SCN9A",
    "gene-SCNM1",
    "gene-SCNN1A",
    "gene-SCNN1B",
    "gene-SCNN1D",
    "gene-SCNN1G",
    "gene-SCO1",
    "gene-SCO2",
    "gene-SCOC",
    "gene-SCP2",
    "gene-SCP2D1",
    "gene-SCPEP1",
    "gene-SCPPPQ1",
    "gene-SCRG1",
    "gene-SCRIB",
    "gene-SCRN1",
    "gene-SCRN2",
    "gene-SCRN3",
    "gene-SCRT1",
    "gene-SCRT2",
    "gene-SCT",
    "gene-SCTR",
    "gene-SCUBE1",
    "gene-SCUBE2",
    "gene-SCUBE3",
    "gene-SCX",
    "gene-SCYGR1",
    "gene-SCYGR10",
    "gene-SCYGR2",
    "gene-SCYGR3",
    "gene-SCYGR4",
    "gene-SCYGR5",
    "gene-SCYGR6",
    "gene-SCYGR7",
    "gene-SCYGR8",
    "gene-SCYGR9",
    "gene-SCYL1",
    "gene-SCYL2",
    "gene-SCYL3",
    "gene-SDAD1",
    "gene-SDC1",
    "gene-SDC2",
    "gene-SDC3",
    "gene-SDC4",
    "gene-SDCBP",
    "gene-SDCBP2",
    "gene-SDCCAG8",
    "gene-SDE2",
    "gene-SDF2",
    "gene-SDF2L1",
    "gene-SDF4",
    "gene-SDHA",
    "gene-SDHAF1",
    "gene-SDHAF2",
    "gene-SDHAF3",
    "gene-SDHAF4",
    "gene-SDHB",
    "gene-SDHC",
    "gene-SDHD",
    "gene-SDIM1",
    "gene-SDK1",
    "gene-SDK2",
    "gene-SDR16C5",
    "gene-SDR39U1",
    "gene-SDR42E1",
    "gene-SDR42E2",
    "gene-SDR9C7",
    "gene-SDS",
    "gene-SDSL",
    "gene-SEBOX",
    "gene-SEC11A",
    "gene-SEC11C",
    "gene-SEC13",
    "gene-SEC14L1",
    "gene-SEC14L2",
    "gene-SEC14L3",
    "gene-SEC14L4",
    "gene-SEC14L5",
    "gene-SEC14L6",
    "gene-SEC16A",
    "gene-SEC16B",
    "gene-SEC22A",
    "gene-SEC22B",
    "gene-SEC22C",
    "gene-SEC23A",
    "gene-SEC23B",
    "gene-SEC23IP",
    "gene-SEC24A",
    "gene-SEC24B",
    "gene-SEC24C",
    "gene-SEC24D",
    "gene-SEC31A",
    "gene-SEC31B",
    "gene-SEC61A1",
    "gene-SEC61A2",
    "gene-SEC61B",
    "gene-SEC61G",
    "gene-SEC62",
    "gene-SEC63",
    "gene-SECISBP2",
    "gene-SECISBP2L",
    "gene-SECTM1",
    "gene-SEH1L",
    "gene-SEL1L",
    "gene-SEL1L2",
    "gene-SEL1L3",
    "gene-SELE",
    "gene-SELENBP1",
    "gene-SELENOF",
    "gene-SELENOH",
    "gene-SELENOI",
    "gene-SELENOK",
    "gene-SELENOM",
    "gene-SELENON",
    "gene-SELENOO",
    "gene-SELENOP",
    "gene-SELENOS",
    "gene-SELENOT",
    "gene-SELENOV",
    "gene-SELENOW",
    "gene-SELL",
    "gene-SELP",
    "gene-SELPLG",
    "gene-SEM1",
    "gene-SEMA3A",
    "gene-SEMA3B",
    "gene-SEMA3C",
    "gene-SEMA3D",
    "gene-SEMA3E",
    "gene-SEMA3F",
    "gene-SEMA3G",
    "gene-SEMA4A",
    "gene-SEMA4B",
    "gene-SEMA4C",
    "gene-SEMA4D",
    "gene-SEMA4F",
    "gene-SEMA4G",
    "gene-SEMA5A",
    "gene-SEMA5B",
    "gene-SEMA6A",
    "gene-SEMA6B",
    "gene-SEMA6C",
    "gene-SEMA6D",
    "gene-SEMA7A",
    "gene-SEMG1",
    "gene-SEMG2",
    "gene-SENP1",
    "gene-SENP2",
    "gene-SENP3",
    "gene-SENP5",
    "gene-SENP6",
    "gene-SENP7",
    "gene-SENP8",
    "gene-SEPHS1",
    "gene-SEPHS2",
    "gene-SEPSECS",
    "gene-SEPTIN1",
    "gene-SEPTIN10",
    "gene-SEPTIN11",
    "gene-SEPTIN12",
    "gene-SEPTIN14",
    "gene-SEPTIN2",
    "gene-SEPTIN3",
    "gene-SEPTIN4",
    "gene-SEPTIN5",
    "gene-SEPTIN6",
    "gene-SEPTIN7",
    "gene-SEPTIN8",
    "gene-SEPTIN9",
    "gene-SERAC1",
    "gene-SERBP1",
    "gene-SERF1A",
    "gene-SERF1B",
    "gene-SERF2",
    "gene-SERGEF",
    "gene-SERHL2",
    "gene-SERINC1",
    "gene-SERINC2",
    "gene-SERINC3",
    "gene-SERINC4",
    "gene-SERINC5",
    "gene-SERP1",
    "gene-SERP2",
    "gene-SERPINA1",
    "gene-SERPINA10",
    "gene-SERPINA11",
    "gene-SERPINA12",
    "gene-SERPINA2",
    "gene-SERPINA3",
    "gene-SERPINA4",
    "gene-SERPINA5",
    "gene-SERPINA6",
    "gene-SERPINA7",
    "gene-SERPINA9",
    "gene-SERPINB1",
    "gene-SERPINB10",
    "gene-SERPINB11",
    "gene-SERPINB12",
    "gene-SERPINB13",
    "gene-SERPINB2",
    "gene-SERPINB3",
    "gene-SERPINB4",
    "gene-SERPINB5",
    "gene-SERPINB6",
    "gene-SERPINB7",
    "gene-SERPINB8",
    "gene-SERPINB9",
    "gene-SERPINC1",
    "gene-SERPIND1",
    "gene-SERPINE1",
    "gene-SERPINE2",
    "gene-SERPINE3",
    "gene-SERPINF1",
    "gene-SERPINF2",
    "gene-SERPING1",
    "gene-SERPINH1",
    "gene-SERPINI1",
    "gene-SERPINI2",
    "gene-SERTAD1",
    "gene-SERTAD2",
    "gene-SERTAD3",
    "gene-SERTAD4",
    "gene-SERTM1",
    "gene-SERTM2",
    "gene-SESN1",
    "gene-SESN2",
    "gene-SESN3",
    "gene-SESTD1",
    "gene-SET",
    "gene-SETBP1",
    "gene-SETD1A",
    "gene-SETD1B",
    "gene-SETD2",
    "gene-SETD3",
    "gene-SETD4",
    "gene-SETD5",
    "gene-SETD6",
    "gene-SETD7",
    "gene-SETD9",
    "gene-SETDB1",
    "gene-SETDB2",
    "gene-SETMAR",
    "gene-SETSIP",
    "gene-SETX",
    "gene-SEZ6",
    "gene-SEZ6L",
    "gene-SEZ6L2",
    "gene-SF1",
    "gene-SF3A1",
    "gene-SF3A2",
    "gene-SF3A3",
    "gene-SF3B1",
    "gene-SF3B2",
    "gene-SF3B3",
    "gene-SF3B4",
    "gene-SF3B5",
    "gene-SF3B6",
    "gene-SFI1",
    "gene-SFMBT1",
    "gene-SFMBT2",
    "gene-SFN",
    "gene-SFPQ",
    "gene-SFR1",
    "gene-SFRP1",
    "gene-SFRP2",
    "gene-SFRP4",
    "gene-SFRP5",
    "gene-SFSWAP",
    "gene-SFT2D1",
    "gene-SFT2D2",
    "gene-SFT2D3",
    "gene-SFTA2",
    "gene-SFTPA1",
    "gene-SFTPA2",
    "gene-SFTPB",
    "gene-SFTPC",
    "gene-SFTPD",
    "gene-SFXN1",
    "gene-SFXN2",
    "gene-SFXN3",
    "gene-SFXN4",
    "gene-SFXN5",
    "gene-SGCA",
    "gene-SGCB",
    "gene-SGCD",
    "gene-SGCE",
    "gene-SGCG",
    "gene-SGCZ",
    "gene-SGF29",
    "gene-SGIP1",
    "gene-SGK1",
    "gene-SGK2",
    "gene-SGK3",
    "gene-SGMS1",
    "gene-SGMS2",
    "gene-SGO1",
    "gene-SGO2",
    "gene-SGPL1",
    "gene-SGPP1",
    "gene-SGPP2",
    "gene-SGSH",
    "gene-SGSM1",
    "gene-SGSM2",
    "gene-SGSM3",
    "gene-SGTA",
    "gene-SGTB",
    "gene-SH2B1",
    "gene-SH2B2",
    "gene-SH2B3",
    "gene-SH2D1A",
    "gene-SH2D1B",
    "gene-SH2D2A",
    "gene-SH2D3A",
    "gene-SH2D3C",
    "gene-SH2D4A",
    "gene-SH2D4B",
    "gene-SH2D5",
    "gene-SH2D6",
    "gene-SH2D7",
    "gene-SH3BGR",
    "gene-SH3BGRL",
    "gene-SH3BGRL2",
    "gene-SH3BGRL3",
    "gene-SH3BP1",
    "gene-SH3BP2",
    "gene-SH3BP4",
    "gene-SH3BP5",
    "gene-SH3BP5L",
    "gene-SH3D19",
    "gene-SH3D21",
    "gene-SH3GL1",
    "gene-SH3GL2",
    "gene-SH3GL3",
    "gene-SH3GLB1",
    "gene-SH3GLB2",
    "gene-SH3KBP1",
    "gene-SH3PXD2A",
    "gene-SH3PXD2B",
    "gene-SH3RF1",
    "gene-SH3RF2",
    "gene-SH3RF3",
    "gene-SH3TC1",
    "gene-SH3TC2",
    "gene-SH3YL1",
    "gene-SHANK1",
    "gene-SHANK2",
    "gene-SHANK3",
    "gene-SHARPIN",
    "gene-SHB",
    "gene-SHBG",
    "gene-SHC1",
    "gene-SHC2",
    "gene-SHC3",
    "gene-SHC4",
    "gene-SHCBP1",
    "gene-SHCBP1L",
    "gene-SHD",
    "gene-SHE",
    "gene-SHF",
    "gene-SHFL",
    "gene-SHH",
    "gene-SHISA2",
    "gene-SHISA3",
    "gene-SHISA4",
    "gene-SHISA5",
    "gene-SHISA6",
    "gene-SHISA7",
    "gene-SHISA8",
    "gene-SHISA9",
    "gene-SHISAL1",
    "gene-SHISAL2A",
    "gene-SHISAL2B",
    "gene-SHKBP1",
    "gene-SHLD1",
    "gene-SHLD2",
    "gene-SHLD3",
    "gene-SHMT1",
    "gene-SHMT2",
    "gene-SHOC1",
    "gene-SHOC2",
    "gene-SHOX",
    "gene-SHOX2",
    "gene-SHPK",
    "gene-SHPRH",
    "gene-SHQ1",
    "gene-SHROOM1",
    "gene-SHROOM2",
    "gene-SHROOM3",
    "gene-SHROOM4",
    "gene-SHTN1",
    "gene-SI",
    "gene-SIAE",
    "gene-SIAH1",
    "gene-SIAH2",
    "gene-SIAH3",
    "gene-SIDT1",
    "gene-SIDT2",
    "gene-SIGIRR",
    "gene-SIGLEC1",
    "gene-SIGLEC10",
    "gene-SIGLEC11",
    "gene-SIGLEC12",
    "gene-SIGLEC14",
    "gene-SIGLEC15",
    "gene-SIGLEC16",
    "gene-SIGLEC5",
    "gene-SIGLEC6",
    "gene-SIGLEC7",
    "gene-SIGLEC8",
    "gene-SIGLEC9",
    "gene-SIGLECL1",
    "gene-SIGMAR1",
    "gene-SIK1",
    "gene-SIK2",
    "gene-SIK3",
    "gene-SIKE1",
    "gene-SIL1",
    "gene-SIM1",
    "gene-SIM2",
    "gene-SIMC1",
    "gene-SIN3A",
    "gene-SIN3B",
    "gene-SINHCAF",
    "gene-SIPA1",
    "gene-SIPA1L1",
    "gene-SIPA1L2",
    "gene-SIPA1L3",
    "gene-SIRPA",
    "gene-SIRPB1",
    "gene-SIRPB2",
    "gene-SIRPD",
    "gene-SIRPG",
    "gene-SIRT1",
    "gene-SIRT2",
    "gene-SIRT3",
    "gene-SIRT4",
    "gene-SIRT5",
    "gene-SIRT6",
    "gene-SIRT7",
    "gene-SIT1",
    "gene-SIVA1",
    "gene-SIX1",
    "gene-SIX2",
    "gene-SIX3",
    "gene-SIX4",
    "gene-SIX5",
    "gene-SIX6",
    "gene-SKA1",
    "gene-SKA2",
    "gene-SKA3",
    "gene-SKAP1",
    "gene-SKAP2",
    "gene-SKI",
    "gene-SKIC2",
    "gene-SKIC3",
    "gene-SKIC8",
    "gene-SKIDA1",
    "gene-SKIL",
    "gene-SKOR1",
    "gene-SKOR2",
    "gene-SKP1",
    "gene-SKP2",
    "gene-SLA",
    "gene-SLA2",
    "gene-SLAIN1",
    "gene-SLAIN2",
    "gene-SLAMF1",
    "gene-SLAMF6",
    "gene-SLAMF7",
    "gene-SLAMF8",
    "gene-SLAMF9",
    "gene-SLBP",
    "gene-SLC10A1",
    "gene-SLC10A2",
    "gene-SLC10A3",
    "gene-SLC10A4",
    "gene-SLC10A5",
    "gene-SLC10A6",
    "gene-SLC10A7",
    "gene-SLC11A1",
    "gene-SLC11A2",
    "gene-SLC12A1",
    "gene-SLC12A2",
    "gene-SLC12A3",
    "gene-SLC12A4",
    "gene-SLC12A5",
    "gene-SLC12A6",
    "gene-SLC12A7",
    "gene-SLC12A8",
    "gene-SLC12A9",
    "gene-SLC13A1",
    "gene-SLC13A2",
    "gene-SLC13A3",
    "gene-SLC13A4",
    "gene-SLC13A5",
    "gene-SLC14A1",
    "gene-SLC14A2",
    "gene-SLC15A1",
    "gene-SLC15A2",
    "gene-SLC15A3",
    "gene-SLC15A4",
    "gene-SLC15A5",
    "gene-SLC16A1",
    "gene-SLC16A10",
    "gene-SLC16A11",
    "gene-SLC16A12",
    "gene-SLC16A13",
    "gene-SLC16A14",
    "gene-SLC16A2",
    "gene-SLC16A3",
    "gene-SLC16A4",
    "gene-SLC16A5",
    "gene-SLC16A6",
    "gene-SLC16A7",
    "gene-SLC16A8",
    "gene-SLC16A9",
    "gene-SLC17A1",
    "gene-SLC17A2",
    "gene-SLC17A3",
    "gene-SLC17A4",
    "gene-SLC17A5",
    "gene-SLC17A6",
    "gene-SLC17A7",
    "gene-SLC17A8",
    "gene-SLC17A9",
    "gene-SLC18A1",
    "gene-SLC18A2",
    "gene-SLC18A3",
    "gene-SLC18B1",
    "gene-SLC19A1",
    "gene-SLC19A2",
    "gene-SLC19A3",
    "gene-SLC1A1",
    "gene-SLC1A2",
    "gene-SLC1A3",
    "gene-SLC1A4",
    "gene-SLC1A5",
    "gene-SLC1A6",
    "gene-SLC1A7",
    "gene-SLC20A1",
    "gene-SLC20A2",
    "gene-SLC22A1",
    "gene-SLC22A10",
    "gene-SLC22A11",
    "gene-SLC22A12",
    "gene-SLC22A13",
    "gene-SLC22A14",
    "gene-SLC22A15",
    "gene-SLC22A16",
    "gene-SLC22A17",
    "gene-SLC22A18",
    "gene-SLC22A2",
    "gene-SLC22A23",
    "gene-SLC22A24",
    "gene-SLC22A25",
    "gene-SLC22A3",
    "gene-SLC22A31",
    "gene-SLC22A4",
    "gene-SLC22A5",
    "gene-SLC22A6",
    "gene-SLC22A7",
    "gene-SLC22A8",
    "gene-SLC22A9",
    "gene-SLC23A1",
    "gene-SLC23A2",
    "gene-SLC23A3",
    "gene-SLC24A1",
    "gene-SLC24A2",
    "gene-SLC24A3",
    "gene-SLC24A4",
    "gene-SLC24A5",
    "gene-SLC25A1",
    "gene-SLC25A10",
    "gene-SLC25A11",
    "gene-SLC25A12",
    "gene-SLC25A13",
    "gene-SLC25A14",
    "gene-SLC25A15",
    "gene-SLC25A16",
    "gene-SLC25A17",
    "gene-SLC25A18",
    "gene-SLC25A19",
    "gene-SLC25A2",
    "gene-SLC25A20",
    "gene-SLC25A21",
    "gene-SLC25A22",
    "gene-SLC25A23",
    "gene-SLC25A24",
    "gene-SLC25A25",
    "gene-SLC25A26",
    "gene-SLC25A27",
    "gene-SLC25A28",
    "gene-SLC25A29",
    "gene-SLC25A3",
    "gene-SLC25A30",
    "gene-SLC25A31",
    "gene-SLC25A32",
    "gene-SLC25A33",
    "gene-SLC25A34",
    "gene-SLC25A35",
    "gene-SLC25A36",
    "gene-SLC25A37",
    "gene-SLC25A38",
    "gene-SLC25A39",
    "gene-SLC25A4",
    "gene-SLC25A40",
    "gene-SLC25A41",
    "gene-SLC25A42",
    "gene-SLC25A43",
    "gene-SLC25A44",
    "gene-SLC25A45",
    "gene-SLC25A46",
    "gene-SLC25A47",
    "gene-SLC25A48",
    "gene-SLC25A5",
    "gene-SLC25A51",
    "gene-SLC25A52",
    "gene-SLC25A53",
    "gene-SLC25A6",
    "gene-SLC26A1",
    "gene-SLC26A11",
    "gene-SLC26A2",
    "gene-SLC26A3",
    "gene-SLC26A4",
    "gene-SLC26A5",
    "gene-SLC26A6",
    "gene-SLC26A7",
    "gene-SLC26A8",
    "gene-SLC26A9",
    "gene-SLC27A1",
    "gene-SLC27A2",
    "gene-SLC27A3",
    "gene-SLC27A4",
    "gene-SLC27A5",
    "gene-SLC27A6",
    "gene-SLC28A1",
    "gene-SLC28A2",
    "gene-SLC28A3",
    "gene-SLC29A1",
    "gene-SLC29A2",
    "gene-SLC29A3",
    "gene-SLC29A4",
    "gene-SLC2A1",
    "gene-SLC2A10",
    "gene-SLC2A11",
    "gene-SLC2A12",
    "gene-SLC2A13",
    "gene-SLC2A14",
    "gene-SLC2A2",
    "gene-SLC2A3",
    "gene-SLC2A4",
    "gene-SLC2A4RG",
    "gene-SLC2A5",
    "gene-SLC2A6",
    "gene-SLC2A7",
    "gene-SLC2A8",
    "gene-SLC2A9",
    "gene-SLC30A1",
    "gene-SLC30A10",
    "gene-SLC30A2",
    "gene-SLC30A3",
    "gene-SLC30A4",
    "gene-SLC30A5",
    "gene-SLC30A6",
    "gene-SLC30A7",
    "gene-SLC30A8",
    "gene-SLC30A9",
    "gene-SLC31A1",
    "gene-SLC31A2",
    "gene-SLC32A1",
    "gene-SLC33A1",
    "gene-SLC34A1",
    "gene-SLC34A2",
    "gene-SLC34A3",
    "gene-SLC35A1",
    "gene-SLC35A2",
    "gene-SLC35A3",
    "gene-SLC35A4",
    "gene-SLC35A5",
    "gene-SLC35B1",
    "gene-SLC35B2",
    "gene-SLC35B3",
    "gene-SLC35B4",
    "gene-SLC35C1",
    "gene-SLC35C2",
    "gene-SLC35D1",
    "gene-SLC35D2",
    "gene-SLC35D3",
    "gene-SLC35E1",
    "gene-SLC35E2B",
    "gene-SLC35E3",
    "gene-SLC35E4",
    "gene-SLC35F1",
    "gene-SLC35F2",
    "gene-SLC35F3",
    "gene-SLC35F4",
    "gene-SLC35F5",
    "gene-SLC35F6",
    "gene-SLC35G1",
    "gene-SLC35G2",
    "gene-SLC35G3",
    "gene-SLC35G4",
    "gene-SLC35G5",
    "gene-SLC35G6",
    "gene-SLC36A1",
    "gene-SLC36A2",
    "gene-SLC36A3",
    "gene-SLC36A4",
    "gene-SLC37A1",
    "gene-SLC37A2",
    "gene-SLC37A3",
    "gene-SLC37A4",
    "gene-SLC38A1",
    "gene-SLC38A10",
    "gene-SLC38A11",
    "gene-SLC38A2",
    "gene-SLC38A3",
    "gene-SLC38A4",
    "gene-SLC38A5",
    "gene-SLC38A6",
    "gene-SLC38A7",
    "gene-SLC38A8",
    "gene-SLC38A9",
    "gene-SLC39A1",
    "gene-SLC39A10",
    "gene-SLC39A11",
    "gene-SLC39A12",
    "gene-SLC39A13",
    "gene-SLC39A14",
    "gene-SLC39A2",
    "gene-SLC39A3",
    "gene-SLC39A4",
    "gene-SLC39A5",
    "gene-SLC39A6",
    "gene-SLC39A7",
    "gene-SLC39A8",
    "gene-SLC39A9",
    "gene-SLC3A1",
    "gene-SLC3A2",
    "gene-SLC40A1",
    "gene-SLC41A1",
    "gene-SLC41A2",
    "gene-SLC41A3",
    "gene-SLC43A1",
    "gene-SLC43A2",
    "gene-SLC43A3",
    "gene-SLC44A1",
    "gene-SLC44A2",
    "gene-SLC44A3",
    "gene-SLC44A4",
    "gene-SLC44A5",
    "gene-SLC45A1",
    "gene-SLC45A2",
    "gene-SLC45A3",
    "gene-SLC45A4",
    "gene-SLC46A1",
    "gene-SLC46A2",
    "gene-SLC46A3",
    "gene-SLC47A1",
    "gene-SLC47A2",
    "gene-SLC48A1",
    "gene-SLC49A3",
    "gene-SLC49A4",
    "gene-SLC4A1",
    "gene-SLC4A10",
    "gene-SLC4A11",
    "gene-SLC4A1AP",
    "gene-SLC4A2",
    "gene-SLC4A3",
    "gene-SLC4A4",
    "gene-SLC4A5",
    "gene-SLC4A7",
    "gene-SLC4A8",
    "gene-SLC4A9",
    "gene-SLC50A1",
    "gene-SLC51A",
    "gene-SLC51B",
    "gene-SLC52A1",
    "gene-SLC52A2",
    "gene-SLC52A3",
    "gene-SLC5A1",
    "gene-SLC5A10",
    "gene-SLC5A11",
    "gene-SLC5A12",
    "gene-SLC5A2",
    "gene-SLC5A3",
    "gene-SLC5A4",
    "gene-SLC5A5",
    "gene-SLC5A6",
    "gene-SLC5A7",
    "gene-SLC5A8",
    "gene-SLC5A9",
    "gene-SLC66A1",
    "gene-SLC66A2",
    "gene-SLC66A3",
    "gene-SLC6A1",
    "gene-SLC6A11",
    "gene-SLC6A12",
    "gene-SLC6A13",
    "gene-SLC6A14",
    "gene-SLC6A15",
    "gene-SLC6A16",
    "gene-SLC6A17",
    "gene-SLC6A18",
    "gene-SLC6A19",
    "gene-SLC6A2",
    "gene-SLC6A20",
    "gene-SLC6A3",
    "gene-SLC6A4",
    "gene-SLC6A5",
    "gene-SLC6A6",
    "gene-SLC6A7",
    "gene-SLC6A8",
    "gene-SLC6A9",
    "gene-SLC7A1",
    "gene-SLC7A10",
    "gene-SLC7A11",
    "gene-SLC7A13",
    "gene-SLC7A14",
    "gene-SLC7A2",
    "gene-SLC7A3",
    "gene-SLC7A4",
    "gene-SLC7A5",
    "gene-SLC7A6",
    "gene-SLC7A6OS",
    "gene-SLC7A7",
    "gene-SLC7A8",
    "gene-SLC7A9",
    "gene-SLC8A1",
    "gene-SLC8A2",
    "gene-SLC8A3",
    "gene-SLC8B1",
    "gene-SLC9A1",
    "gene-SLC9A2",
    "gene-SLC9A3",
    "gene-SLC9A4",
    "gene-SLC9A5",
    "gene-SLC9A6",
    "gene-SLC9A7",
    "gene-SLC9A8",
    "gene-SLC9A9",
    "gene-SLC9B1",
    "gene-SLC9B2",
    "gene-SLC9C1",
    "gene-SLC9C2",
    "gene-SLCO1A2",
    "gene-SLCO1B1",
    "gene-SLCO1B3",
    "gene-SLCO1B7",
    "gene-SLCO1C1",
    "gene-SLCO2A1",
    "gene-SLCO2B1",
    "gene-SLCO3A1",
    "gene-SLCO4A1",
    "gene-SLCO4C1",
    "gene-SLCO5A1",
    "gene-SLCO6A1",
    "gene-SLF1",
    "gene-SLF2",
    "gene-SLFN11",
    "gene-SLFN12",
    "gene-SLFN12L",
    "gene-SLFN13",
    "gene-SLFN14",
    "gene-SLFN5",
    "gene-SLFNL1",
    "gene-SLIRP",
    "gene-SLIT1",
    "gene-SLIT2",
    "gene-SLIT3",
    "gene-SLITRK1",
    "gene-SLITRK2",
    "gene-SLITRK3",
    "gene-SLITRK4",
    "gene-SLITRK5",
    "gene-SLITRK6",
    "gene-SLK",
    "gene-SLMAP",
    "gene-SLN",
    "gene-SLPI",
    "gene-SLTM",
    "gene-SLU7",
    "gene-SLURP1",
    "gene-SLURP2",
    "gene-SLX1A",
    "gene-SLX1B",
    "gene-SLX4",
    "gene-SLX4IP",
    "gene-SLX9",
    "gene-SMAD1",
    "gene-SMAD2",
    "gene-SMAD3",
    "gene-SMAD4",
    "gene-SMAD5",
    "gene-SMAD6",
    "gene-SMAD7",
    "gene-SMAD9",
    "gene-SMAGP",
    "gene-SMAP1",
    "gene-SMAP2",
    "gene-SMARCA1",
    "gene-SMARCA2",
    "gene-SMARCA4",
    "gene-SMARCA5",
    "gene-SMARCAD1",
    "gene-SMARCAL1",
    "gene-SMARCB1",
    "gene-SMARCC1",
    "gene-SMARCC2",
    "gene-SMARCD1",
    "gene-SMARCD2",
    "gene-SMARCD3",
    "gene-SMARCE1",
    "gene-SMC1A",
    "gene-SMC1B",
    "gene-SMC2",
    "gene-SMC3",
    "gene-SMC4",
    "gene-SMC5",
    "gene-SMC6",
    "gene-SMCHD1",
    "gene-SMCO1",
    "gene-SMCO2",
    "gene-SMCO3",
    "gene-SMCO4",
    "gene-SMCP",
    "gene-SMCR8",
    "gene-SMDT1",
    "gene-SMG1",
    "gene-SMG5",
    "gene-SMG6",
    "gene-SMG7",
    "gene-SMG8",
    "gene-SMG9",
    "gene-SMIM1",
    "gene-SMIM10",
    "gene-SMIM10L1",
    "gene-SMIM10L2A",
    "gene-SMIM10L2B",
    "gene-SMIM11",
    "gene-SMIM12",
    "gene-SMIM13",
    "gene-SMIM14",
    "gene-SMIM15",
    "gene-SMIM17",
    "gene-SMIM18",
    "gene-SMIM19",
    "gene-SMIM2",
    "gene-SMIM20",
    "gene-SMIM21",
    "gene-SMIM22",
    "gene-SMIM23",
    "gene-SMIM24",
    "gene-SMIM26",
    "gene-SMIM27",
    "gene-SMIM28",
    "gene-SMIM29",
    "gene-SMIM3",
    "gene-SMIM30",
    "gene-SMIM31",
    "gene-SMIM32",
    "gene-SMIM33",
    "gene-SMIM34",
    "gene-SMIM35",
    "gene-SMIM36",
    "gene-SMIM38",
    "gene-SMIM39",
    "gene-SMIM40",
    "gene-SMIM41",
    "gene-SMIM42",
    "gene-SMIM43",
    "gene-SMIM44",
    "gene-SMIM45",
    "gene-SMIM46",
    "gene-SMIM47",
    "gene-SMIM5",
    "gene-SMIM6",
    "gene-SMIM7",
    "gene-SMIM8",
    "gene-SMIM9",
    "gene-SMKR1",
    "gene-SMLR1",
    "gene-SMN1",
    "gene-SMN2",
    "gene-SMNDC1",
    "gene-SMO",
    "gene-SMOC1",
    "gene-SMOC2",
    "gene-SMOX",
    "gene-SMPD1",
    "gene-SMPD2",
    "gene-SMPD3",
    "gene-SMPD4",
    "gene-SMPDL3A",
    "gene-SMPDL3B",
    "gene-SMPX",
    "gene-SMR3A",
    "gene-SMR3B",
    "gene-SMS",
    "gene-SMTN",
    "gene-SMTNL1",
    "gene-SMTNL2",
    "gene-SMU1",
    "gene-SMUG1",
    "gene-SMURF1",
    "gene-SMURF2",
    "gene-SMYD1",
    "gene-SMYD2",
    "gene-SMYD3",
    "gene-SMYD4",
    "gene-SMYD5",
    "gene-SNAI1",
    "gene-SNAI2",
    "gene-SNAI3",
    "gene-SNAP23",
    "gene-SNAP25",
    "gene-SNAP29",
    "gene-SNAP47",
    "gene-SNAP91",
    "gene-SNAPC1",
    "gene-SNAPC2",
    "gene-SNAPC3",
    "gene-SNAPC4",
    "gene-SNAPC5",
    "gene-SNAPIN",
    "gene-SNCA",
    "gene-SNCAIP",
    "gene-SNCB",
    "gene-SNCG",
    "gene-SND1",
    "gene-SNED1",
    "gene-SNF8",
    "gene-SNIP1",
    "gene-SNN",
    "gene-SNORC",
    "gene-SNPH",
    "gene-SNRK",
    "gene-SNRNP200",
    "gene-SNRNP25",
    "gene-SNRNP27",
    "gene-SNRNP35",
    "gene-SNRNP40",
    "gene-SNRNP48",
    "gene-SNRNP70",
    "gene-SNRPA",
    "gene-SNRPA1",
    "gene-SNRPB",
    "gene-SNRPB2",
    "gene-SNRPC",
    "gene-SNRPD1",
    "gene-SNRPD2",
    "gene-SNRPD3",
    "gene-SNRPE",
    "gene-SNRPF",
    "gene-SNRPG",
    "gene-SNRPN",
    "gene-SNTA1",
    "gene-SNTB1",
    "gene-SNTB2",
    "gene-SNTG1",
    "gene-SNTG2",
    "gene-SNTN",
    "gene-SNU13",
    "gene-SNUPN",
    "gene-SNURF",
    "gene-SNW1",
    "gene-SNX1",
    "gene-SNX10",
    "gene-SNX11",
    "gene-SNX12",
    "gene-SNX13",
    "gene-SNX14",
    "gene-SNX15",
    "gene-SNX16",
    "gene-SNX17",
    "gene-SNX18",
    "gene-SNX19",
    "gene-SNX2",
    "gene-SNX20",
    "gene-SNX21",
    "gene-SNX22",
    "gene-SNX24",
    "gene-SNX25",
    "gene-SNX27",
    "gene-SNX29",
    "gene-SNX3",
    "gene-SNX30",
    "gene-SNX31",
    "gene-SNX32",
    "gene-SNX33",
    "gene-SNX4",
    "gene-SNX5",
    "gene-SNX6",
    "gene-SNX7",
    "gene-SNX8",
    "gene-SNX9",
    "gene-SOAT1",
    "gene-SOAT2",
    "gene-SOBP",
    "gene-SOCS1",
    "gene-SOCS2",
    "gene-SOCS3",
    "gene-SOCS4",
    "gene-SOCS5",
    "gene-SOCS6",
    "gene-SOCS7",
    "gene-SOD1",
    "gene-SOD2",
    "gene-SOD3",
    "gene-SOHLH1",
    "gene-SOHLH2",
    "gene-SON",
    "gene-SORBS1",
    "gene-SORBS2",
    "gene-SORBS3",
    "gene-SORCS1",
    "gene-SORCS2",
    "gene-SORCS3",
    "gene-SORD",
    "gene-SORL1",
    "gene-SORT1",
    "gene-SOS1",
    "gene-SOS2",
    "gene-SOST",
    "gene-SOSTDC1",
    "gene-SOWAHA",
    "gene-SOWAHB",
    "gene-SOWAHC",
    "gene-SOWAHD",
    "gene-SOX1",
    "gene-SOX10",
    "gene-SOX11",
    "gene-SOX12",
    "gene-SOX13",
    "gene-SOX14",
    "gene-SOX15",
    "gene-SOX17",
    "gene-SOX18",
    "gene-SOX2",
    "gene-SOX21",
    "gene-SOX3",
    "gene-SOX30",
    "gene-SOX4",
    "gene-SOX5",
    "gene-SOX6",
    "gene-SOX7",
    "gene-SOX8",
    "gene-SOX9",
    "gene-SP1",
    "gene-SP100",
    "gene-SP110",
    "gene-SP140",
    "gene-SP140L",
    "gene-SP2",
    "gene-SP3",
    "gene-SP4",
    "gene-SP5",
    "gene-SP6",
    "gene-SP7",
    "gene-SP8",
    "gene-SP9",
    "gene-SPA17",
    "gene-SPAAR",
    "gene-SPACA1",
    "gene-SPACA3",
    "gene-SPACA4",
    "gene-SPACA5",
    "gene-SPACA5B",
    "gene-SPACA6",
    "gene-SPACA7",
    "gene-SPACA9",
    "gene-SPACDR",
    "gene-SPADH",
    "gene-SPAG1",
    "gene-SPAG11A",
    "gene-SPAG11B",
    "gene-SPAG16",
    "gene-SPAG17",
    "gene-SPAG4",
    "gene-SPAG5",
    "gene-SPAG6",
    "gene-SPAG7",
    "gene-SPAG8",
    "gene-SPAG9",
    "gene-SPAM1",
    "gene-SPANXA1",
    "gene-SPANXA2",
    "gene-SPANXB1",
    "gene-SPANXC",
    "gene-SPANXD",
    "gene-SPANXN1",
    "gene-SPANXN2",
    "gene-SPANXN3",
    "gene-SPANXN4",
    "gene-SPANXN5",
    "gene-SPARC",
    "gene-SPARCL1",
    "gene-SPART",
    "gene-SPAST",
    "gene-SPATA1",
    "gene-SPATA12",
    "gene-SPATA13",
    "gene-SPATA16",
    "gene-SPATA17",
    "gene-SPATA18",
    "gene-SPATA19",
    "gene-SPATA2",
    "gene-SPATA20",
    "gene-SPATA21",
    "gene-SPATA22",
    "gene-SPATA24",
    "gene-SPATA25",
    "gene-SPATA2L",
    "gene-SPATA3",
    "gene-SPATA31A1",
    "gene-SPATA31A3",
    "gene-SPATA31A5",
    "gene-SPATA31A6",
    "gene-SPATA31A7",
    "gene-SPATA31C1",
    "gene-SPATA31C2",
    "gene-SPATA31D1",
    "gene-SPATA31D3",
    "gene-SPATA31D4",
    "gene-SPATA31E1",
    "gene-SPATA31F1",
    "gene-SPATA31F3",
    "gene-SPATA31G1",
    "gene-SPATA31H1",
    "gene-SPATA32",
    "gene-SPATA33",
    "gene-SPATA4",
    "gene-SPATA45",
    "gene-SPATA46",
    "gene-SPATA6",
    "gene-SPATA6L",
    "gene-SPATA7",
    "gene-SPATA9",
    "gene-SPATC1",
    "gene-SPATC1L",
    "gene-SPATS1",
    "gene-SPATS2",
    "gene-SPATS2L",
    "gene-SPC24",
    "gene-SPC25",
    "gene-SPCS1",
    "gene-SPCS2",
    "gene-SPCS3",
    "gene-SPDEF",
    "gene-SPDL1",
    "gene-SPDYA",
    "gene-SPDYC",
    "gene-SPDYE1",
    "gene-SPDYE10",
    "gene-SPDYE11",
    "gene-SPDYE12",
    "gene-SPDYE13",
    "gene-SPDYE14",
    "gene-SPDYE15",
    "gene-SPDYE16",
    "gene-SPDYE17",
    "gene-SPDYE18",
    "gene-SPDYE2",
    "gene-SPDYE21",
    "gene-SPDYE2B",
    "gene-SPDYE3",
    "gene-SPDYE4",
    "gene-SPDYE5",
    "gene-SPDYE6",
    "gene-SPDYE8",
    "gene-SPDYE9",
    "gene-SPECC1",
    "gene-SPECC1L",
    "gene-SPEF1",
    "gene-SPEF2",
    "gene-SPEG",
    "gene-SPEGNB",
    "gene-SPEM1",
    "gene-SPEM2",
    "gene-SPEM3",
    "gene-SPEN",
    "gene-SPESP1",
    "gene-SPG11",
    "gene-SPG21",
    "gene-SPG7",
    "gene-SPHK1",
    "gene-SPHK2",
    "gene-SPHKAP",
    "gene-SPI1",
    "gene-SPIB",
    "gene-SPIC",
    "gene-SPICE1",
    "gene-SPIDR",
    "gene-SPIN1",
    "gene-SPIN2A",
    "gene-SPIN2B",
    "gene-SPIN3",
    "gene-SPIN4",
    "gene-SPINDOC",
    "gene-SPINK1",
    "gene-SPINK13",
    "gene-SPINK14",
    "gene-SPINK2",
    "gene-SPINK4",
    "gene-SPINK5",
    "gene-SPINK6",
    "gene-SPINK7",
    "gene-SPINK8",
    "gene-SPINK9",
    "gene-SPINT1",
    "gene-SPINT2",
    "gene-SPINT3",
    "gene-SPINT4",
    "gene-SPIRE1",
    "gene-SPIRE2",
    "gene-SPMAP1",
    "gene-SPMAP2",
    "gene-SPMAP2L",
    "gene-SPMIP1",
    "gene-SPMIP10",
    "gene-SPMIP11",
    "gene-SPMIP2",
    "gene-SPMIP3",
    "gene-SPMIP4",
    "gene-SPMIP5",
    "gene-SPMIP6",
    "gene-SPMIP7",
    "gene-SPMIP8",
    "gene-SPMIP9",
    "gene-SPN",
    "gene-SPNS1",
    "gene-SPNS2",
    "gene-SPNS3",
    "gene-SPO11",
    "gene-SPOCD1",
    "gene-SPOCK1",
    "gene-SPOCK2",
    "gene-SPOCK3",
    "gene-SPON1",
    "gene-SPON2",
    "gene-SPOP",
    "gene-SPOPL",
    "gene-SPOUT1",
    "gene-SPP1",
    "gene-SPP2",
    "gene-SPPL2A",
    "gene-SPPL2B",
    "gene-SPPL2C",
    "gene-SPPL3",
    "gene-SPR",
    "gene-SPRED1",
    "gene-SPRED2",
    "gene-SPRED3",
    "gene-SPRING1",
    "gene-SPRN",
    "gene-SPRR1A",
    "gene-SPRR1B",
    "gene-SPRR2A",
    "gene-SPRR2B",
    "gene-SPRR2D",
    "gene-SPRR2E",
    "gene-SPRR2F",
    "gene-SPRR2G",
    "gene-SPRR3",
    "gene-SPRR4",
    "gene-SPRR5",
    "gene-SPRTN",
    "gene-SPRY1",
    "gene-SPRY2",
    "gene-SPRY3",
    "gene-SPRY4",
    "gene-SPRYD3",
    "gene-SPRYD4",
    "gene-SPRYD7",
    "gene-SPSB1",
    "gene-SPSB2",
    "gene-SPSB3",
    "gene-SPSB4",
    "gene-SPTA1",
    "gene-SPTAN1",
    "gene-SPTB",
    "gene-SPTBN1",
    "gene-SPTBN2",
    "gene-SPTBN4",
    "gene-SPTBN5",
    "gene-SPTLC1",
    "gene-SPTLC2",
    "gene-SPTLC3",
    "gene-SPTSSA",
    "gene-SPTSSB",
    "gene-SPTY2D1",
    "gene-SPX",
    "gene-SPZ1",
    "gene-SQLE",
    "gene-SQOR",
    "gene-SQSTM1",
    "gene-SRA1",
    "gene-SRARP",
    "gene-SRBD1",
    "gene-SRC",
    "gene-SRCAP",
    "gene-SRCIN1",
    "gene-SRD5A1",
    "gene-SRD5A2",
    "gene-SRD5A3",
    "gene-SREBF1",
    "gene-SREBF2",
    "gene-SREK1",
    "gene-SREK1IP1",
    "gene-SRF",
    "gene-SRFBP1",
    "gene-SRGAP1",
    "gene-SRGAP2",
    "gene-SRGAP2B",
    "gene-SRGAP2C",
    "gene-SRGAP3",
    "gene-SRGN",
    "gene-SRI",
    "gene-SRL",
    "gene-SRM",
    "gene-SRMS",
    "gene-SRP14",
    "gene-SRP19",
    "gene-SRP54",
    "gene-SRP68",
    "gene-SRP72",
    "gene-SRP9",
    "gene-SRPK1",
    "gene-SRPK2",
    "gene-SRPK3",
    "gene-SRPRA",
    "gene-SRPRB",
    "gene-SRPX",
    "gene-SRPX2",
    "gene-SRR",
    "gene-SRRD",
    "gene-SRRM1",
    "gene-SRRM2",
    "gene-SRRM3",
    "gene-SRRM4",
    "gene-SRRM5",
    "gene-SRRT",
    "gene-SRSF1",
    "gene-SRSF10",
    "gene-SRSF11",
    "gene-SRSF12",
    "gene-SRSF2",
    "gene-SRSF3",
    "gene-SRSF4",
    "gene-SRSF5",
    "gene-SRSF6",
    "gene-SRSF7",
    "gene-SRSF8",
    "gene-SRSF9",
    "gene-SRXN1",
    "gene-SRY",
    "gene-SS18",
    "gene-SS18L1",
    "gene-SS18L2",
    "gene-SSB",
    "gene-SSBP1",
    "gene-SSBP2",
    "gene-SSBP3",
    "gene-SSBP4",
    "gene-SSC4D",
    "gene-SSC5D",
    "gene-SSH1",
    "gene-SSH2",
    "gene-SSH3",
    "gene-SSMEM1",
    "gene-SSNA1",
    "gene-SSPN",
    "gene-SSR1",
    "gene-SSR2",
    "gene-SSR3",
    "gene-SSR4",
    "gene-SSRP1",
    "gene-SST",
    "gene-SSTR1",
    "gene-SSTR2",
    "gene-SSTR3",
    "gene-SSTR4",
    "gene-SSTR5",
    "gene-SSU72",
    "gene-SSU72L1",
    "gene-SSU72L2",
    "gene-SSU72L3",
    "gene-SSU72L4",
    "gene-SSU72L5",
    "gene-SSU72L6",
    "gene-SSUH2",
    "gene-SSX1",
    "gene-SSX2",
    "gene-SSX2B",
    "gene-SSX2IP",
    "gene-SSX3",
    "gene-SSX4",
    "gene-SSX4B",
    "gene-SSX5",
    "gene-SSX7",
    "gene-ST13",
    "gene-ST14",
    "gene-ST18",
    "gene-ST3GAL1",
    "gene-ST3GAL2",
    "gene-ST3GAL3",
    "gene-ST3GAL4",
    "gene-ST3GAL5",
    "gene-ST3GAL6",
    "gene-ST6GAL1",
    "gene-ST6GAL2",
    "gene-ST6GALNAC1",
    "gene-ST6GALNAC2",
    "gene-ST6GALNAC3",
    "gene-ST6GALNAC4",
    "gene-ST6GALNAC5",
    "gene-ST6GALNAC6",
    "gene-ST7",
    "gene-ST7L",
    "gene-ST8SIA1",
    "gene-ST8SIA2",
    "gene-ST8SIA3",
    "gene-ST8SIA4",
    "gene-ST8SIA5",
    "gene-ST8SIA6",
    "gene-STAB1",
    "gene-STAB2",
    "gene-STAC",
    "gene-STAC2",
    "gene-STAC3",
    "gene-STAG1",
    "gene-STAG2",
    "gene-STAG3",
    "gene-STAM",
    "gene-STAM2",
    "gene-STAMBP",
    "gene-STAMBPL1",
    "gene-STAP1",
    "gene-STAP2",
    "gene-STAR",
    "gene-STARD10",
    "gene-STARD13",
    "gene-STARD3",
    "gene-STARD3NL",
    "gene-STARD4",
    "gene-STARD5",
    "gene-STARD6",
    "gene-STARD7",
    "gene-STARD8",
    "gene-STARD9",
    "gene-STAT1",
    "gene-STAT2",
    "gene-STAT3",
    "gene-STAT4",
    "gene-STAT5A",
    "gene-STAT5B",
    "gene-STAT6",
    "gene-STATH",
    "gene-STAU1",
    "gene-STAU2",
    "gene-STBD1",
    "gene-STC1",
    "gene-STC2",
    "gene-STEAP1",
    "gene-STEAP1B",
    "gene-STEAP2",
    "gene-STEAP3",
    "gene-STEAP4",
    "gene-STEEP1",
    "gene-STH",
    "gene-STIL",
    "gene-STIM1",
    "gene-STIM2",
    "gene-STIMATE",
    "gene-STING1",
    "gene-STIP1",
    "gene-STK10",
    "gene-STK11",
    "gene-STK11IP",
    "gene-STK16",
    "gene-STK17A",
    "gene-STK17B",
    "gene-STK19",
    "gene-STK24",
    "gene-STK25",
    "gene-STK26",
    "gene-STK3",
    "gene-STK31",
    "gene-STK32A",
    "gene-STK32B",
    "gene-STK32C",
    "gene-STK33",
    "gene-STK35",
    "gene-STK36",
    "gene-STK38",
    "gene-STK38L",
    "gene-STK39",
    "gene-STK4",
    "gene-STK40",
    "gene-STKLD1",
    "gene-STMN1",
    "gene-STMN2",
    "gene-STMN3",
    "gene-STMN4",
    "gene-STMND1",
    "gene-STMP1",
    "gene-STN1",
    "gene-STOM",
    "gene-STOML1",
    "gene-STOML2",
    "gene-STOML3",
    "gene-STON1",
    "gene-STON2",
    "gene-STOX1",
    "gene-STOX2",
    "gene-STPG1",
    "gene-STPG2",
    "gene-STPG3",
    "gene-STPG4",
    "gene-STRA6",
    "gene-STRA8",
    "gene-STRADA",
    "gene-STRADB",
    "gene-STRAP",
    "gene-STRBP",
    "gene-STRC",
    "gene-STRIP1",
    "gene-STRIP2",
    "gene-STRIT1",
    "gene-STRN",
    "gene-STRN3",
    "gene-STRN4",
    "gene-STS",
    "gene-STT3A",
    "gene-STT3B",
    "gene-STUB1",
    "gene-STUM",
    "gene-STX10",
    "gene-STX11",
    "gene-STX12",
    "gene-STX16",
    "gene-STX17",
    "gene-STX18",
    "gene-STX19",
    "gene-STX1A",
    "gene-STX1B",
    "gene-STX2",
    "gene-STX3",
    "gene-STX4",
    "gene-STX5",
    "gene-STX6",
    "gene-STX7",
    "gene-STX8",
    "gene-STXBP1",
    "gene-STXBP2",
    "gene-STXBP3",
    "gene-STXBP4",
    "gene-STXBP5",
    "gene-STXBP5L",
    "gene-STXBP6",
    "gene-STYK1",
    "gene-STYX",
    "gene-STYXL1",
    "gene-STYXL2",
    "gene-SUB1",
    "gene-SUCLA2",
    "gene-SUCLG1",
    "gene-SUCLG2",
    "gene-SUCNR1",
    "gene-SUCO",
    "gene-SUDS3",
    "gene-SUFU",
    "gene-SUGCT",
    "gene-SUGP1",
    "gene-SUGP2",
    "gene-SUGT1",
    "gene-SULF1",
    "gene-SULF2",
    "gene-SULT1A1",
    "gene-SULT1A2",
    "gene-SULT1A3",
    "gene-SULT1A4",
    "gene-SULT1B1",
    "gene-SULT1C2",
    "gene-SULT1C3",
    "gene-SULT1C4",
    "gene-SULT1E1",
    "gene-SULT2A1",
    "gene-SULT2B1",
    "gene-SULT4A1",
    "gene-SULT6B1",
    "gene-SUMF1",
    "gene-SUMF2",
    "gene-SUMO1",
    "gene-SUMO2",
    "gene-SUMO3",
    "gene-SUMO4",
    "gene-SUN1",
    "gene-SUN2",
    "gene-SUN3",
    "gene-SUN5",
    "gene-SUOX",
    "gene-SUPT16H",
    "gene-SUPT20H",
    "gene-SUPT20HL1",
    "gene-SUPT20HL2",
    "gene-SUPT3H",
    "gene-SUPT4H1",
    "gene-SUPT5H",
    "gene-SUPT6H",
    "gene-SUPT7L",
    "gene-SUPV3L1",
    "gene-SURF1",
    "gene-SURF2",
    "gene-SURF4",
    "gene-SURF6",
    "gene-SUSD1",
    "gene-SUSD2",
    "gene-SUSD3",
    "gene-SUSD4",
    "gene-SUSD5",
    "gene-SUSD6",
    "gene-SUV39H1",
    "gene-SUV39H2",
    "gene-SUZ12",
    "gene-SV2A",
    "gene-SV2B",
    "gene-SV2C",
    "gene-SVBP",
    "gene-SVEP1",
    "gene-SVIL",
    "gene-SVIP",
    "gene-SVOP",
    "gene-SVOPL",
    "gene-SWAP70",
    "gene-SWI5",
    "gene-SWSAP1",
    "gene-SWT1",
    "gene-SYAP1",
    "gene-SYBU",
    "gene-SYCE1",
    "gene-SYCE1L",
    "gene-SYCE2",
    "gene-SYCE3",
    "gene-SYCN",
    "gene-SYCP1",
    "gene-SYCP2",
    "gene-SYCP2L",
    "gene-SYCP3",
    "gene-SYDE1",
    "gene-SYDE2",
    "gene-SYF2",
    "gene-SYK",
    "gene-SYMPK",
    "gene-SYN1",
    "gene-SYN2",
    "gene-SYN3",
    "gene-SYNC",
    "gene-SYNCRIP",
    "gene-SYNDIG1",
    "gene-SYNDIG1L",
    "gene-SYNE1",
    "gene-SYNE2",
    "gene-SYNE3",
    "gene-SYNE4",
    "gene-SYNGAP1",
    "gene-SYNGR1",
    "gene-SYNGR2",
    "gene-SYNGR3",
    "gene-SYNGR4",
    "gene-SYNJ1",
    "gene-SYNJ2",
    "gene-SYNJ2BP",
    "gene-SYNM",
    "gene-SYNPO",
    "gene-SYNPO2",
    "gene-SYNPO2L",
    "gene-SYNPR",
    "gene-SYNRG",
    "gene-SYP",
    "gene-SYPL1",
    "gene-SYPL2",
    "gene-SYS1",
    "gene-SYT1",
    "gene-SYT10",
    "gene-SYT11",
    "gene-SYT12",
    "gene-SYT13",
    "gene-SYT14",
    "gene-SYT15",
    "gene-SYT15B",
    "gene-SYT16",
    "gene-SYT17",
    "gene-SYT2",
    "gene-SYT3",
    "gene-SYT4",
    "gene-SYT5",
    "gene-SYT6",
    "gene-SYT7",
    "gene-SYT8",
    "gene-SYT9",
    "gene-SYTL1",
    "gene-SYTL2",
    "gene-SYTL3",
    "gene-SYTL4",
    "gene-SYTL5",
    "gene-SYVN1",
    "gene-SZRD1",
    "gene-SZT2",
    "gene-TAAR1",
    "gene-TAAR2",
    "gene-TAAR5",
    "gene-TAAR6",
    "gene-TAAR8",
    "gene-TAAR9",
    "gene-TAB1",
    "gene-TAB2",
    "gene-TAB3",
    "gene-TAC1",
    "gene-TAC3",
    "gene-TAC4",
    "gene-TACC1",
    "gene-TACC2",
    "gene-TACC3",
    "gene-TACO1",
    "gene-TACR1",
    "gene-TACR2",
    "gene-TACR3",
    "gene-TACSTD2",
    "gene-TADA1",
    "gene-TADA2A",
    "gene-TADA2B",
    "gene-TADA3",
    "gene-TAF1",
    "gene-TAF10",
    "gene-TAF11",
    "gene-TAF11L10",
    "gene-TAF11L11",
    "gene-TAF11L12",
    "gene-TAF11L13",
    "gene-TAF11L14",
    "gene-TAF11L2",
    "gene-TAF11L3",
    "gene-TAF11L4",
    "gene-TAF11L5",
    "gene-TAF11L6",
    "gene-TAF11L7",
    "gene-TAF11L8",
    "gene-TAF11L9",
    "gene-TAF12",
    "gene-TAF13",
    "gene-TAF15",
    "gene-TAF1A",
    "gene-TAF1B",
    "gene-TAF1C",
    "gene-TAF1D",
    "gene-TAF1L",
    "gene-TAF2",
    "gene-TAF3",
    "gene-TAF4",
    "gene-TAF4B",
    "gene-TAF5",
    "gene-TAF5L",
    "gene-TAF6",
    "gene-TAF6L",
    "gene-TAF7",
    "gene-TAF7L",
    "gene-TAF8",
    "gene-TAF9",
    "gene-TAF9B",
    "gene-TAFA1",
    "gene-TAFA2",
    "gene-TAFA3",
    "gene-TAFA4",
    "gene-TAFA5",
    "gene-TAFAZZIN",
    "gene-TAGAP",
    "gene-TAGLN",
    "gene-TAGLN2",
    "gene-TAGLN3",
    "gene-TAL1",
    "gene-TAL2",
    "gene-TALDO1",
    "gene-TAMALIN",
    "gene-TAMM41",
    "gene-TANC1",
    "gene-TANC2",
    "gene-TANGO2",
    "gene-TANGO6",
    "gene-TANK",
    "gene-TAOK1",
    "gene-TAOK2",
    "gene-TAOK3",
    "gene-TAP1",
    "gene-TAP2",
    "gene-TAPBP",
    "gene-TAPBPL",
    "gene-TAPT1",
    "gene-TARBP1",
    "gene-TARBP2",
    "gene-TARDBP",
    "gene-TARM1",
    "gene-TARS1",
    "gene-TARS2",
    "gene-TARS3",
    "gene-TAS1R1",
    "gene-TAS1R2",
    "gene-TAS1R3",
    "gene-TAS2R1",
    "gene-TAS2R10",
    "gene-TAS2R13",
    "gene-TAS2R14",
    "gene-TAS2R16",
    "gene-TAS2R19",
    "gene-TAS2R20",
    "gene-TAS2R3",
    "gene-TAS2R30",
    "gene-TAS2R31",
    "gene-TAS2R33",
    "gene-TAS2R36",
    "gene-TAS2R38",
    "gene-TAS2R39",
    "gene-TAS2R4",
    "gene-TAS2R40",
    "gene-TAS2R41",
    "gene-TAS2R42",
    "gene-TAS2R43",
    "gene-TAS2R46",
    "gene-TAS2R5",
    "gene-TAS2R50",
    "gene-TAS2R60",
    "gene-TAS2R7",
    "gene-TAS2R8",
    "gene-TAS2R9",
    "gene-TASL",
    "gene-TASOR",
    "gene-TASOR2",
    "gene-TASP1",
    "gene-TAT",
    "gene-TATDN1",
    "gene-TATDN2",
    "gene-TATDN3",
    "gene-TAX1BP1",
    "gene-TAX1BP3",
    "gene-TBATA",
    "gene-TBC1D1",
    "gene-TBC1D10A",
    "gene-TBC1D10B",
    "gene-TBC1D10C",
    "gene-TBC1D12",
    "gene-TBC1D13",
    "gene-TBC1D14",
    "gene-TBC1D15",
    "gene-TBC1D16",
    "gene-TBC1D17",
    "gene-TBC1D19",
    "gene-TBC1D2",
    "gene-TBC1D20",
    "gene-TBC1D21",
    "gene-TBC1D22A",
    "gene-TBC1D22B",
    "gene-TBC1D23",
    "gene-TBC1D24",
    "gene-TBC1D25",
    "gene-TBC1D26",
    "gene-TBC1D28",
    "gene-TBC1D2B",
    "gene-TBC1D3",
    "gene-TBC1D30",
    "gene-TBC1D31",
    "gene-TBC1D32",
    "gene-TBC1D3B",
    "gene-TBC1D3C",
    "gene-TBC1D3D",
    "gene-TBC1D3E",
    "gene-TBC1D3F",
    "gene-TBC1D3G",
    "gene-TBC1D3H",
    "gene-TBC1D3I",
    "gene-TBC1D3K",
    "gene-TBC1D3L",
    "gene-TBC1D4",
    "gene-TBC1D5",
    "gene-TBC1D7",
    "gene-TBC1D8",
    "gene-TBC1D8B",
    "gene-TBC1D9",
    "gene-TBC1D9B",
    "gene-TBCA",
    "gene-TBCB",
    "gene-TBCC",
    "gene-TBCCD1",
    "gene-TBCD",
    "gene-TBCE",
    "gene-TBCEL",
    "gene-TBCK",
    "gene-TBK1",
    "gene-TBKBP1",
    "gene-TBL1X",
    "gene-TBL1XR1",
    "gene-TBL1Y",
    "gene-TBL2",
    "gene-TBL3",
    "gene-TBP",
    "gene-TBPL1",
    "gene-TBPL2",
    "gene-TBR1",
    "gene-TBRG1",
    "gene-TBRG4",
    "gene-TBX1",
    "gene-TBX10",
    "gene-TBX15",
    "gene-TBX18",
    "gene-TBX19",
    "gene-TBX2",
    "gene-TBX20",
    "gene-TBX21",
    "gene-TBX22",
    "gene-TBX3",
    "gene-TBX4",
    "gene-TBX5",
    "gene-TBX6",
    "gene-TBXA2R",
    "gene-TBXAS1",
    "gene-TBXT",
    "gene-TC2N",
    "gene-TCAF1",
    "gene-TCAF2",
    "gene-TCAIM",
    "gene-TCAP",
    "gene-TCEA1",
    "gene-TCEA2",
    "gene-TCEA3",
    "gene-TCEAL1",
    "gene-TCEAL2",
    "gene-TCEAL3",
    "gene-TCEAL4",
    "gene-TCEAL5",
    "gene-TCEAL6",
    "gene-TCEAL7",
    "gene-TCEAL8",
    "gene-TCEAL9",
    "gene-TCEANC",
    "gene-TCEANC2",
    "gene-TCERG1",
    "gene-TCERG1L",
    "gene-TCF12",
    "gene-TCF15",
    "gene-TCF19",
    "gene-TCF20",
    "gene-TCF21",
    "gene-TCF23",
    "gene-TCF24",
    "gene-TCF25",
    "gene-TCF3",
    "gene-TCF4",
    "gene-TCF7",
    "gene-TCF7L1",
    "gene-TCF7L2",
    "gene-TCFL5",
    "gene-TCHH",
    "gene-TCHHL1",
    "gene-TCHP",
    "gene-TCIM",
    "gene-TCIRG1",
    "gene-TCL1A",
    "gene-TCL1B",
    "gene-TCN1",
    "gene-TCN2",
    "gene-TCOF1",
    "gene-TCP1",
    "gene-TCP10L",
    "gene-TCP11",
    "gene-TCP11L1",
    "gene-TCP11L2",
    "gene-TCP11X1",
    "gene-TCP11X2",
    "gene-TCTA",
    "gene-TCTE1",
    "gene-TCTN1",
    "gene-TCTN2",
    "gene-TCTN3",
    "gene-TDG",
    "gene-TDO2",
    "gene-TDP1",
    "gene-TDP2",
    "gene-TDRD1",
    "gene-TDRD10",
    "gene-TDRD12",
    "gene-TDRD15",
    "gene-TDRD3",
    "gene-TDRD5",
    "gene-TDRD6",
    "gene-TDRD7",
    "gene-TDRD9",
    "gene-TDRKH",
    "gene-TDRP",
    "gene-TEAD1",
    "gene-TEAD2",
    "gene-TEAD3",
    "gene-TEAD4",
    "gene-TEC",
    "gene-TECPR1",
    "gene-TECPR2",
    "gene-TECR",
    "gene-TECRL",
    "gene-TECTA",
    "gene-TECTB",
    "gene-TEDC1",
    "gene-TEDC2",
    "gene-TEDDM1",
    "gene-TEF",
    "gene-TEFM",
    "gene-TEK",
    "gene-TEKT1",
    "gene-TEKT2",
    "gene-TEKT3",
    "gene-TEKT4",
    "gene-TEKT5",
    "gene-TEKTIP1",
    "gene-TEKTL1",
    "gene-TELO2",
    "gene-TEN1",
    "gene-TENM1",
    "gene-TENM2",
    "gene-TENM3",
    "gene-TENM4",
    "gene-TENT2",
    "gene-TENT4A",
    "gene-TENT4B",
    "gene-TENT5A",
    "gene-TENT5B",
    "gene-TENT5C",
    "gene-TENT5D",
    "gene-TEP1",
    "gene-TEPSIN",
    "gene-TERB1",
    "gene-TERB2",
    "gene-TERF1",
    "gene-TERF2",
    "gene-TERF2IP",
    "gene-TERT",
    "gene-TES",
    "gene-TESC",
    "gene-TESK1",
    "gene-TESK2",
    "gene-TESMIN",
    "gene-TESPA1",
    "gene-TET1",
    "gene-TET2",
    "gene-TET3",
    "gene-TEX10",
    "gene-TEX101",
    "gene-TEX11",
    "gene-TEX12",
    "gene-TEX13A",
    "gene-TEX13B",
    "gene-TEX13C",
    "gene-TEX13D",
    "gene-TEX14",
    "gene-TEX15",
    "gene-TEX19",
    "gene-TEX2",
    "gene-TEX22",
    "gene-TEX26",
    "gene-TEX261",
    "gene-TEX264",
    "gene-TEX28",
    "gene-TEX29",
    "gene-TEX30",
    "gene-TEX35",
    "gene-TEX36",
    "gene-TEX38",
    "gene-TEX44",
    "gene-TEX46",
    "gene-TEX47",
    "gene-TEX48",
    "gene-TEX50",
    "gene-TEX51",
    "gene-TEX52",
    "gene-TEX53",
    "gene-TEX54",
    "gene-TEX55",
    "gene-TEX9",
    "gene-TF",
    "gene-TFAM",
    "gene-TFAP2A",
    "gene-TFAP2B",
    "gene-TFAP2C",
    "gene-TFAP2D",
    "gene-TFAP2E",
    "gene-TFAP4",
    "gene-TFB1M",
    "gene-TFB2M",
    "gene-TFCP2",
    "gene-TFCP2L1",
    "gene-TFDP1",
    "gene-TFDP2",
    "gene-TFDP3",
    "gene-TFE3",
    "gene-TFEB",
    "gene-TFEC",
    "gene-TFF1",
    "gene-TFF2",
    "gene-TFF3",
    "gene-TFG",
    "gene-TFIP11",
    "gene-TFPI",
    "gene-TFPI2",
    "gene-TFPT",
    "gene-TFR2",
    "gene-TFRC",
    "gene-TG",
    "gene-TGDS",
    "gene-TGFA",
    "gene-TGFB1",
    "gene-TGFB1I1",
    "gene-TGFB2",
    "gene-TGFB3",
    "gene-TGFBI",
    "gene-TGFBR1",
    "gene-TGFBR2",
    "gene-TGFBR3",
    "gene-TGFBR3L",
    "gene-TGFBRAP1",
    "gene-TGIF1",
    "gene-TGIF2",
    "gene-TGIF2LX",
    "gene-TGIF2LY",
    "gene-TGM1",
    "gene-TGM2",
    "gene-TGM3",
    "gene-TGM4",
    "gene-TGM5",
    "gene-TGM6",
    "gene-TGM7",
    "gene-TGOLN2",
    "gene-TGS1",
    "gene-TH",
    "gene-THADA",
    "gene-THAP1",
    "gene-THAP10",
    "gene-THAP11",
    "gene-THAP12",
    "gene-THAP2",
    "gene-THAP3",
    "gene-THAP4",
    "gene-THAP5",
    "gene-THAP6",
    "gene-THAP7",
    "gene-THAP8",
    "gene-THAP9",
    "gene-THBD",
    "gene-THBS1",
    "gene-THBS2",
    "gene-THBS3",
    "gene-THBS4",
    "gene-THEM4",
    "gene-THEM5",
    "gene-THEM6",
    "gene-THEMIS",
    "gene-THEMIS2",
    "gene-THG1L",
    "gene-THNSL1",
    "gene-THNSL2",
    "gene-THOC1",
    "gene-THOC2",
    "gene-THOC3",
    "gene-THOC5",
    "gene-THOC6",
    "gene-THOC7",
    "gene-THOP1",
    "gene-THPO",
    "gene-THRA",
    "gene-THRAP3",
    "gene-THRB",
    "gene-THRSP",
    "gene-THSD1",
    "gene-THSD4",
    "gene-THSD7A",
    "gene-THSD7B",
    "gene-THSD8",
    "gene-THTPA",
    "gene-THUMPD1",
    "gene-THUMPD2",
    "gene-THUMPD3",
    "gene-THY1",
    "gene-THYN1",
    "gene-TIA1",
    "gene-TIAL1",
    "gene-TIAM1",
    "gene-TIAM2",
    "gene-TICAM1",
    "gene-TICAM2",
    "gene-TICRR",
    "gene-TIE1",
    "gene-TIFA",
    "gene-TIFAB",
    "gene-TIGAR",
    "gene-TIGD1",
    "gene-TIGD2",
    "gene-TIGD3",
    "gene-TIGD4",
    "gene-TIGD5",
    "gene-TIGD6",
    "gene-TIGD7",
    "gene-TIGIT",
    "gene-TIMD4",
    "gene-TIMELESS",
    "gene-TIMM10",
    "gene-TIMM10B",
    "gene-TIMM13",
    "gene-TIMM17A",
    "gene-TIMM17B",
    "gene-TIMM21",
    "gene-TIMM22",
    "gene-TIMM23",
    "gene-TIMM23B",
    "gene-TIMM29",
    "gene-TIMM44",
    "gene-TIMM50",
    "gene-TIMM8A",
    "gene-TIMM8B",
    "gene-TIMM9",
    "gene-TIMMDC1",
    "gene-TIMP1",
    "gene-TIMP2",
    "gene-TIMP3",
    "gene-TIMP4",
    "gene-TINAG",
    "gene-TINAGL1",
    "gene-TINCR",
    "gene-TINF2",
    "gene-TIPARP",
    "gene-TIPIN",
    "gene-TIPRL",
    "gene-TIRAP",
    "gene-TJAP1",
    "gene-TJP1",
    "gene-TJP2",
    "gene-TJP3",
    "gene-TK1",
    "gene-TK2",
    "gene-TKFC",
    "gene-TKT",
    "gene-TKTL1",
    "gene-TKTL2",
    "gene-TLCD1",
    "gene-TLCD2",
    "gene-TLCD3A",
    "gene-TLCD3B",
    "gene-TLCD4",
    "gene-TLCD5",
    "gene-TLDC2",
    "gene-TLE1",
    "gene-TLE2",
    "gene-TLE3",
    "gene-TLE4",
    "gene-TLE5",
    "gene-TLE6",
    "gene-TLE7",
    "gene-TLK1",
    "gene-TLK2",
    "gene-TLL1",
    "gene-TLL2",
    "gene-TLN1",
    "gene-TLN2",
    "gene-TLNRD1",
    "gene-TLR1",
    "gene-TLR10",
    "gene-TLR2",
    "gene-TLR3",
    "gene-TLR4",
    "gene-TLR5",
    "gene-TLR6",
    "gene-TLR7",
    "gene-TLR8",
    "gene-TLR9",
    "gene-TLX1",
    "gene-TLX2",
    "gene-TLX3",
    "gene-TM2D1",
    "gene-TM2D2",
    "gene-TM2D3",
    "gene-TM4SF1",
    "gene-TM4SF18",
    "gene-TM4SF19",
    "gene-TM4SF20",
    "gene-TM4SF4",
    "gene-TM4SF5",
    "gene-TM6SF1",
    "gene-TM6SF2",
    "gene-TM7SF2",
    "gene-TM7SF3",
    "gene-TM9SF1",
    "gene-TM9SF2",
    "gene-TM9SF3",
    "gene-TM9SF4",
    "gene-TMA16",
    "gene-TMA7",
    "gene-TMA7B",
    "gene-TMBIM1",
    "gene-TMBIM4",
    "gene-TMBIM6",
    "gene-TMC1",
    "gene-TMC2",
    "gene-TMC3",
    "gene-TMC4",
    "gene-TMC5",
    "gene-TMC6",
    "gene-TMC7",
    "gene-TMC8",
    "gene-TMCC1",
    "gene-TMCC2",
    "gene-TMCC3",
    "gene-TMCO1",
    "gene-TMCO2",
    "gene-TMCO3",
    "gene-TMCO4",
    "gene-TMCO5A",
    "gene-TMCO6",
    "gene-TMDD1",
    "gene-TMED1",
    "gene-TMED10",
    "gene-TMED2",
    "gene-TMED3",
    "gene-TMED4",
    "gene-TMED5",
    "gene-TMED6",
    "gene-TMED7",
    "gene-TMED8",
    "gene-TMED9",
    "gene-TMEFF1",
    "gene-TMEFF2",
    "gene-TMEM100",
    "gene-TMEM101",
    "gene-TMEM102",
    "gene-TMEM104",
    "gene-TMEM106A",
    "gene-TMEM106B",
    "gene-TMEM106C",
    "gene-TMEM107",
    "gene-TMEM108",
    "gene-TMEM109",
    "gene-TMEM11",
    "gene-TMEM114",
    "gene-TMEM115",
    "gene-TMEM116",
    "gene-TMEM117",
    "gene-TMEM119",
    "gene-TMEM120A",
    "gene-TMEM120B",
    "gene-TMEM121",
    "gene-TMEM121B",
    "gene-TMEM123",
    "gene-TMEM125",
    "gene-TMEM126A",
    "gene-TMEM126B",
    "gene-TMEM127",
    "gene-TMEM128",
    "gene-TMEM129",
    "gene-TMEM130",
    "gene-TMEM131",
    "gene-TMEM131L",
    "gene-TMEM132A",
    "gene-TMEM132B",
    "gene-TMEM132C",
    "gene-TMEM132D",
    "gene-TMEM132E",
    "gene-TMEM134",
    "gene-TMEM135",
    "gene-TMEM138",
    "gene-TMEM139",
    "gene-TMEM140",
    "gene-TMEM141",
    "gene-TMEM143",
    "gene-TMEM144",
    "gene-TMEM145",
    "gene-TMEM147",
    "gene-TMEM14A",
    "gene-TMEM14B",
    "gene-TMEM14C",
    "gene-TMEM150A",
    "gene-TMEM150B",
    "gene-TMEM150C",
    "gene-TMEM151A",
    "gene-TMEM151B",
    "gene-TMEM154",
    "gene-TMEM156",
    "gene-TMEM158",
    "gene-TMEM160",
    "gene-TMEM161A",
    "gene-TMEM161B",
    "gene-TMEM163",
    "gene-TMEM164",
    "gene-TMEM165",
    "gene-TMEM167A",
    "gene-TMEM167B",
    "gene-TMEM168",
    "gene-TMEM169",
    "gene-TMEM17",
    "gene-TMEM170A",
    "gene-TMEM170B",
    "gene-TMEM171",
    "gene-TMEM174",
    "gene-TMEM175",
    "gene-TMEM176A",
    "gene-TMEM176B",
    "gene-TMEM177",
    "gene-TMEM178A",
    "gene-TMEM178B",
    "gene-TMEM179",
    "gene-TMEM179B",
    "gene-TMEM18",
    "gene-TMEM181",
    "gene-TMEM182",
    "gene-TMEM183A",
    "gene-TMEM184A",
    "gene-TMEM184B",
    "gene-TMEM184C",
    "gene-TMEM185A",
    "gene-TMEM185B",
    "gene-TMEM186",
    "gene-TMEM187",
    "gene-TMEM19",
    "gene-TMEM190",
    "gene-TMEM191B",
    "gene-TMEM191C",
    "gene-TMEM192",
    "gene-TMEM196",
    "gene-TMEM198",
    "gene-TMEM199",
    "gene-TMEM200A",
    "gene-TMEM200B",
    "gene-TMEM200C",
    "gene-TMEM201",
    "gene-TMEM202",
    "gene-TMEM203",
    "gene-TMEM204",
    "gene-TMEM205",
    "gene-TMEM207",
    "gene-TMEM208",
    "gene-TMEM209",
    "gene-TMEM210",
    "gene-TMEM212",
    "gene-TMEM213",
    "gene-TMEM214",
    "gene-TMEM215",
    "gene-TMEM216",
    "gene-TMEM217",
    "gene-TMEM217B",
    "gene-TMEM218",
    "gene-TMEM219",
    "gene-TMEM220",
    "gene-TMEM221",
    "gene-TMEM222",
    "gene-TMEM223",
    "gene-TMEM225",
    "gene-TMEM225B",
    "gene-TMEM229A",
    "gene-TMEM229B",
    "gene-TMEM230",
    "gene-TMEM231",
    "gene-TMEM232",
    "gene-TMEM233",
    "gene-TMEM234",
    "gene-TMEM235",
    "gene-TMEM236",
    "gene-TMEM237",
    "gene-TMEM238",
    "gene-TMEM238L",
    "gene-TMEM239",
    "gene-TMEM240",
    "gene-TMEM241",
    "gene-TMEM242",
    "gene-TMEM243",
    "gene-TMEM244",
    "gene-TMEM245",
    "gene-TMEM247",
    "gene-TMEM248",
    "gene-TMEM249",
    "gene-TMEM25",
    "gene-TMEM250",
    "gene-TMEM252",
    "gene-TMEM253",
    "gene-TMEM254",
    "gene-TMEM255A",
    "gene-TMEM255B",
    "gene-TMEM256",
    "gene-TMEM258",
    "gene-TMEM259",
    "gene-TMEM26",
    "gene-TMEM260",
    "gene-TMEM262",
    "gene-TMEM263",
    "gene-TMEM265",
    "gene-TMEM266",
    "gene-TMEM267",
    "gene-TMEM268",
    "gene-TMEM269",
    "gene-TMEM270",
    "gene-TMEM271",
    "gene-TMEM272",
    "gene-TMEM273",
    "gene-TMEM275",
    "gene-TMEM276",
    "gene-TMEM30A",
    "gene-TMEM30B",
    "gene-TMEM31",
    "gene-TMEM33",
    "gene-TMEM35A",
    "gene-TMEM35B",
    "gene-TMEM37",
    "gene-TMEM38A",
    "gene-TMEM38B",
    "gene-TMEM39A",
    "gene-TMEM39B",
    "gene-TMEM40",
    "gene-TMEM41A",
    "gene-TMEM41B",
    "gene-TMEM42",
    "gene-TMEM43",
    "gene-TMEM44",
    "gene-TMEM45A",
    "gene-TMEM45B",
    "gene-TMEM47",
    "gene-TMEM50A",
    "gene-TMEM50B",
    "gene-TMEM51",
    "gene-TMEM52",
    "gene-TMEM52B",
    "gene-TMEM53",
    "gene-TMEM54",
    "gene-TMEM59",
    "gene-TMEM59L",
    "gene-TMEM60",
    "gene-TMEM61",
    "gene-TMEM62",
    "gene-TMEM63A",
    "gene-TMEM63B",
    "gene-TMEM63C",
    "gene-TMEM64",
    "gene-TMEM65",
    "gene-TMEM67",
    "gene-TMEM68",
    "gene-TMEM69",
    "gene-TMEM70",
    "gene-TMEM71",
    "gene-TMEM72",
    "gene-TMEM74",
    "gene-TMEM74B",
    "gene-TMEM78",
    "gene-TMEM79",
    "gene-TMEM80",
    "gene-TMEM81",
    "gene-TMEM82",
    "gene-TMEM86A",
    "gene-TMEM86B",
    "gene-TMEM87A",
    "gene-TMEM87B",
    "gene-TMEM88",
    "gene-TMEM88B",
    "gene-TMEM89",
    "gene-TMEM8B",
    "gene-TMEM9",
    "gene-TMEM91",
    "gene-TMEM92",
    "gene-TMEM94",
    "gene-TMEM95",
    "gene-TMEM97",
    "gene-TMEM98",
    "gene-TMEM9B",
    "gene-TMF1",
    "gene-TMIE",
    "gene-TMIGD1",
    "gene-TMIGD2",
    "gene-TMIGD3",
    "gene-TMLHE",
    "gene-TMOD1",
    "gene-TMOD2",
    "gene-TMOD3",
    "gene-TMOD4",
    "gene-TMPO",
    "gene-TMPPE",
    "gene-TMPRSS11A",
    "gene-TMPRSS11B",
    "gene-TMPRSS11D",
    "gene-TMPRSS11E",
    "gene-TMPRSS11F",
    "gene-TMPRSS12",
    "gene-TMPRSS13",
    "gene-TMPRSS15",
    "gene-TMPRSS2",
    "gene-TMPRSS3",
    "gene-TMPRSS4",
    "gene-TMPRSS5",
    "gene-TMPRSS6",
    "gene-TMPRSS7",
    "gene-TMPRSS9",
    "gene-TMSB10",
    "gene-TMSB15A",
    "gene-TMSB15B",
    "gene-TMSB15C",
    "gene-TMSB4X",
    "gene-TMSB4Y",
    "gene-TMT1A",
    "gene-TMT1B",
    "gene-TMTC1",
    "gene-TMTC2",
    "gene-TMTC3",
    "gene-TMTC4",
    "gene-TMUB1",
    "gene-TMUB2",
    "gene-TMX1",
    "gene-TMX2",
    "gene-TMX3",
    "gene-TMX4",
    "gene-TNC",
    "gene-TNF",
    "gene-TNFAIP1",
    "gene-TNFAIP2",
    "gene-TNFAIP3",
    "gene-TNFAIP6",
    "gene-TNFAIP8",
    "gene-TNFAIP8L1",
    "gene-TNFAIP8L2",
    "gene-TNFAIP8L3",
    "gene-TNFRSF10A",
    "gene-TNFRSF10B",
    "gene-TNFRSF10C",
    "gene-TNFRSF10D",
    "gene-TNFRSF11A",
    "gene-TNFRSF11B",
    "gene-TNFRSF12A",
    "gene-TNFRSF13B",
    "gene-TNFRSF13C",
    "gene-TNFRSF14",
    "gene-TNFRSF17",
    "gene-TNFRSF18",
    "gene-TNFRSF19",
    "gene-TNFRSF1A",
    "gene-TNFRSF1B",
    "gene-TNFRSF21",
    "gene-TNFRSF25",
    "gene-TNFRSF4",
    "gene-TNFRSF6B",
    "gene-TNFRSF8",
    "gene-TNFRSF9",
    "gene-TNFSF10",
    "gene-TNFSF11",
    "gene-TNFSF12",
    "gene-TNFSF13",
    "gene-TNFSF13B",
    "gene-TNFSF14",
    "gene-TNFSF15",
    "gene-TNFSF18",
    "gene-TNFSF4",
    "gene-TNFSF8",
    "gene-TNFSF9",
    "gene-TNIK",
    "gene-TNIP1",
    "gene-TNIP2",
    "gene-TNIP3",
    "gene-TNK1",
    "gene-TNK2",
    "gene-TNKS",
    "gene-TNKS1BP1",
    "gene-TNKS2",
    "gene-TNMD",
    "gene-TNN",
    "gene-TNNC1",
    "gene-TNNC2",
    "gene-TNNI1",
    "gene-TNNI2",
    "gene-TNNI3",
    "gene-TNNI3K",
    "gene-TNNT1",
    "gene-TNNT2",
    "gene-TNNT3",
    "gene-TNP1",
    "gene-TNP2",
    "gene-TNPO1",
    "gene-TNPO2",
    "gene-TNPO3",
    "gene-TNR",
    "gene-TNRC18",
    "gene-TNRC6A",
    "gene-TNRC6B",
    "gene-TNRC6C",
    "gene-TNS1",
    "gene-TNS2",
    "gene-TNS3",
    "gene-TNS4",
    "gene-TNXB",
    "gene-TOB1",
    "gene-TOB2",
    "gene-TOE1",
    "gene-TOGARAM1",
    "gene-TOGARAM2",
    "gene-TOLLIP",
    "gene-TOM1",
    "gene-TOM1L1",
    "gene-TOM1L2",
    "gene-TOMM20",
    "gene-TOMM20L",
    "gene-TOMM22",
    "gene-TOMM34",
    "gene-TOMM40",
    "gene-TOMM40L",
    "gene-TOMM5",
    "gene-TOMM6",
    "gene-TOMM7",
    "gene-TOMM70",
    "gene-TOMT",
    "gene-TONSL",
    "gene-TOP1",
    "gene-TOP1MT",
    "gene-TOP2A",
    "gene-TOP2B",
    "gene-TOP3A",
    "gene-TOP3B",
    "gene-TOP6BL",
    "gene-TOPAZ1",
    "gene-TOPBP1",
    "gene-TOPORS",
    "gene-TOR1A",
    "gene-TOR1AIP1",
    "gene-TOR1AIP2",
    "gene-TOR1B",
    "gene-TOR2A",
    "gene-TOR3A",
    "gene-TOR4A",
    "gene-TOX",
    "gene-TOX2",
    "gene-TOX3",
    "gene-TOX4",
    "gene-TP53",
    "gene-TP53AIP1",
    "gene-TP53BP1",
    "gene-TP53BP2",
    "gene-TP53I11",
    "gene-TP53I13",
    "gene-TP53I3",
    "gene-TP53INP1",
    "gene-TP53INP2",
    "gene-TP53RK",
    "gene-TP53TG3",
    "gene-TP53TG3B",
    "gene-TP53TG3C",
    "gene-TP53TG3D",
    "gene-TP53TG3E",
    "gene-TP53TG3F",
    "gene-TP53TG5",
    "gene-TP63",
    "gene-TP73",
    "gene-TPBG",
    "gene-TPBGL",
    "gene-TPCN1",
    "gene-TPCN2",
    "gene-TPD52",
    "gene-TPD52L1",
    "gene-TPD52L2",
    "gene-TPD52L3",
    "gene-TPGS1",
    "gene-TPGS2",
    "gene-TPH1",
    "gene-TPH2",
    "gene-TPI1",
    "gene-TPK1",
    "gene-TPM1",
    "gene-TPM2",
    "gene-TPM3",
    "gene-TPM4",
    "gene-TPMT",
    "gene-TPO",
    "gene-TPP1",
    "gene-TPP2",
    "gene-TPPP",
    "gene-TPPP2",
    "gene-TPPP3",
    "gene-TPR",
    "gene-TPRA1",
    "gene-TPRG1",
    "gene-TPRG1L",
    "gene-TPRKB",
    "gene-TPRN",
    "gene-TPRX1",
    "gene-TPRX2",
    "gene-TPSAB1",
    "gene-TPSB2",
    "gene-TPSD1",
    "gene-TPSG1",
    "gene-TPST1",
    "gene-TPST2",
    "gene-TPT1",
    "gene-TPTE",
    "gene-TPTE2",
    "gene-TPX2",
    "gene-TRA",
    "gene-TRA2A",
    "gene-TRA2B",
    "gene-TRABD",
    "gene-TRABD2A",
    "gene-TRABD2B",
    "gene-TRADD",
    "gene-TRAF1",
    "gene-TRAF2",
    "gene-TRAF3",
    "gene-TRAF3IP1",
    "gene-TRAF3IP2",
    "gene-TRAF3IP3",
    "gene-TRAF4",
    "gene-TRAF5",
    "gene-TRAF6",
    "gene-TRAF7",
    "gene-TRAFD1",
    "gene-TRAIP",
    "gene-TRAK1",
    "gene-TRAK2",
    "gene-TRAM1",
    "gene-TRAM1L1",
    "gene-TRAM2",
    "gene-TRANK1",
    "gene-TRAP1",
    "gene-TRAPPC1",
    "gene-TRAPPC10",
    "gene-TRAPPC11",
    "gene-TRAPPC12",
    "gene-TRAPPC13",
    "gene-TRAPPC14",
    "gene-TRAPPC2",
    "gene-TRAPPC2B",
    "gene-TRAPPC2L",
    "gene-TRAPPC3",
    "gene-TRAPPC3L",
    "gene-TRAPPC4",
    "gene-TRAPPC5",
    "gene-TRAPPC6A",
    "gene-TRAPPC6B",
    "gene-TRAPPC8",
    "gene-TRAPPC9",
    "gene-TRARG1",
    "gene-TRAT1",
    "gene-TRB",
    "gene-TRD",
    "gene-TRDMT1",
    "gene-TRDN",
    "gene-TREH",
    "gene-TREM1",
    "gene-TREM2",
    "gene-TREML1",
    "gene-TREML2",
    "gene-TREML4",
    "gene-TRERF1",
    "gene-TREX1",
    "gene-TREX2",
    "gene-TRG",
    "gene-TRH",
    "gene-TRHDE",
    "gene-TRHR",
    "gene-TRIAP1",
    "gene-TRIB1",
    "gene-TRIB2",
    "gene-TRIB3",
    "gene-TRIL",
    "gene-TRIM10",
    "gene-TRIM11",
    "gene-TRIM13",
    "gene-TRIM14",
    "gene-TRIM15",
    "gene-TRIM16",
    "gene-TRIM17",
    "gene-TRIM2",
    "gene-TRIM21",
    "gene-TRIM22",
    "gene-TRIM23",
    "gene-TRIM24",
    "gene-TRIM25",
    "gene-TRIM26",
    "gene-TRIM27",
    "gene-TRIM28",
    "gene-TRIM29",
    "gene-TRIM3",
    "gene-TRIM31",
    "gene-TRIM32",
    "gene-TRIM33",
    "gene-TRIM34",
    "gene-TRIM35",
    "gene-TRIM36",
    "gene-TRIM37",
    "gene-TRIM38",
    "gene-TRIM39",
    "gene-TRIM4",
    "gene-TRIM40",
    "gene-TRIM41",
    "gene-TRIM42",
    "gene-TRIM43",
    "gene-TRIM43B",
    "gene-TRIM44",
    "gene-TRIM45",
    "gene-TRIM46",
    "gene-TRIM47",
    "gene-TRIM48",
    "gene-TRIM49",
    "gene-TRIM49B",
    "gene-TRIM49C",
    "gene-TRIM49D1",
    "gene-TRIM49D2",
    "gene-TRIM5",
    "gene-TRIM50",
    "gene-TRIM51",
    "gene-TRIM51G",
    "gene-TRIM52",
    "gene-TRIM54",
    "gene-TRIM55",
    "gene-TRIM56",
    "gene-TRIM58",
    "gene-TRIM59",
    "gene-TRIM6",
    "gene-TRIM60",
    "gene-TRIM61",
    "gene-TRIM62",
    "gene-TRIM63",
    "gene-TRIM64",
    "gene-TRIM64B",
    "gene-TRIM64C",
    "gene-TRIM65",
    "gene-TRIM66",
    "gene-TRIM67",
    "gene-TRIM68",
    "gene-TRIM69",
    "gene-TRIM7",
    "gene-TRIM71",
    "gene-TRIM72",
    "gene-TRIM73",
    "gene-TRIM74",
    "gene-TRIM75",
    "gene-TRIM77",
    "gene-TRIM8",
    "gene-TRIM9",
    "gene-TRIML1",
    "gene-TRIML2",
    "gene-TRIO",
    "gene-TRIOBP",
    "gene-TRIP10",
    "gene-TRIP11",
    "gene-TRIP12",
    "gene-TRIP13",
    "gene-TRIP4",
    "gene-TRIP6",
    "gene-TRIQK",
    "gene-TRIR",
    "gene-TRIT1",
    "gene-TRMO",
    "gene-TRMT1",
    "gene-TRMT10A",
    "gene-TRMT10B",
    "gene-TRMT10C",
    "gene-TRMT11",
    "gene-TRMT112",
    "gene-TRMT12",
    "gene-TRMT13",
    "gene-TRMT1L",
    "gene-TRMT2A",
    "gene-TRMT2B",
    "gene-TRMT44",
    "gene-TRMT5",
    "gene-TRMT6",
    "gene-TRMT61A",
    "gene-TRMT61B",
    "gene-TRMT9B",
    "gene-TRMU",
    "gene-TRNAU1AP",
    "gene-TRNP1",
    "gene-TRNT1",
    "gene-TRO",
    "gene-TROAP",
    "gene-TRPA1",
    "gene-TRPC1",
    "gene-TRPC3",
    "gene-TRPC4",
    "gene-TRPC4AP",
    "gene-TRPC5",
    "gene-TRPC5OS",
    "gene-TRPC6",
    "gene-TRPC7",
    "gene-TRPM1",
    "gene-TRPM2",
    "gene-TRPM3",
    "gene-TRPM4",
    "gene-TRPM5",
    "gene-TRPM6",
    "gene-TRPM7",
    "gene-TRPM8",
    "gene-TRPS1",
    "gene-TRPT1",
    "gene-TRPV1",
    "gene-TRPV2",
    "gene-TRPV3",
    "gene-TRPV4",
    "gene-TRPV5",
    "gene-TRPV6",
    "gene-TRRAP",
    "gene-TRUB1",
    "gene-TRUB2",
    "gene-TSACC",
    "gene-TSBP1",
    "gene-TSC1",
    "gene-TSC2",
    "gene-TSC22D1",
    "gene-TSC22D2",
    "gene-TSC22D3",
    "gene-TSC22D4",
    "gene-TSEN15",
    "gene-TSEN2",
    "gene-TSEN34",
    "gene-TSEN54",
    "gene-TSFM",
    "gene-TSG101",
    "gene-TSGA10",
    "gene-TSGA10IP",
    "gene-TSGA13",
    "gene-TSHB",
    "gene-TSHR",
    "gene-TSHZ1",
    "gene-TSHZ2",
    "gene-TSHZ3",
    "gene-TSKS",
    "gene-TSKU",
    "gene-TSLP",
    "gene-TSN",
    "gene-TSNARE1",
    "gene-TSNAX",
    "gene-TSNAXIP1",
    "gene-TSPAN1",
    "gene-TSPAN10",
    "gene-TSPAN11",
    "gene-TSPAN12",
    "gene-TSPAN13",
    "gene-TSPAN14",
    "gene-TSPAN15",
    "gene-TSPAN16",
    "gene-TSPAN17",
    "gene-TSPAN18",
    "gene-TSPAN19",
    "gene-TSPAN2",
    "gene-TSPAN3",
    "gene-TSPAN31",
    "gene-TSPAN32",
    "gene-TSPAN33",
    "gene-TSPAN4",
    "gene-TSPAN5",
    "gene-TSPAN6",
    "gene-TSPAN7",
    "gene-TSPAN8",
    "gene-TSPAN9",
    "gene-TSPEAR",
    "gene-TSPO",
    "gene-TSPO2",
    "gene-TSPOAP1",
    "gene-TSPY1",
    "gene-TSPY10",
    "gene-TSPY2",
    "gene-TSPY3",
    "gene-TSPY4",
    "gene-TSPY8",
    "gene-TSPY9",
    "gene-TSPYL1",
    "gene-TSPYL2",
    "gene-TSPYL4",
    "gene-TSPYL5",
    "gene-TSPYL6",
    "gene-TSR1",
    "gene-TSR2",
    "gene-TSR3",
    "gene-TSSC4",
    "gene-TSSK1B",
    "gene-TSSK2",
    "gene-TSSK3",
    "gene-TSSK4",
    "gene-TSSK6",
    "gene-TST",
    "gene-TSTD1",
    "gene-TSTD2",
    "gene-TSTD3",
    "gene-TTBK1",
    "gene-TTBK2",
    "gene-TTC1",
    "gene-TTC12",
    "gene-TTC13",
    "gene-TTC14",
    "gene-TTC16",
    "gene-TTC17",
    "gene-TTC19",
    "gene-TTC21A",
    "gene-TTC21B",
    "gene-TTC22",
    "gene-TTC23",
    "gene-TTC23L",
    "gene-TTC24",
    "gene-TTC27",
    "gene-TTC28",
    "gene-TTC29",
    "gene-TTC3",
    "gene-TTC31",
    "gene-TTC32",
    "gene-TTC33",
    "gene-TTC34",
    "gene-TTC36",
    "gene-TTC38",
    "gene-TTC39A",
    "gene-TTC39B",
    "gene-TTC39C",
    "gene-TTC4",
    "gene-TTC5",
    "gene-TTC6",
    "gene-TTC7A",
    "gene-TTC7B",
    "gene-TTC8",
    "gene-TTC9",
    "gene-TTC9B",
    "gene-TTC9C",
    "gene-TTF1",
    "gene-TTF2",
    "gene-TTI1",
    "gene-TTI2",
    "gene-TTK",
    "gene-TTL",
    "gene-TTLL1",
    "gene-TTLL10",
    "gene-TTLL11",
    "gene-TTLL12",
    "gene-TTLL13",
    "gene-TTLL2",
    "gene-TTLL3",
    "gene-TTLL4",
    "gene-TTLL5",
    "gene-TTLL6",
    "gene-TTLL7",
    "gene-TTLL8",
    "gene-TTLL9",
    "gene-TTN",
    "gene-TTPA",
    "gene-TTPAL",
    "gene-TTR",
    "gene-TTYH1",
    "gene-TTYH2",
    "gene-TTYH3",
    "gene-TUB",
    "gene-TUBA1A",
    "gene-TUBA1B",
    "gene-TUBA1C",
    "gene-TUBA3C",
    "gene-TUBA3D",
    "gene-TUBA3E",
    "gene-TUBA4A",
    "gene-TUBA4B",
    "gene-TUBA8",
    "gene-TUBAL3",
    "gene-TUBB",
    "gene-TUBB1",
    "gene-TUBB2A",
    "gene-TUBB2B",
    "gene-TUBB3",
    "gene-TUBB4A",
    "gene-TUBB4B",
    "gene-TUBB6",
    "gene-TUBB8",
    "gene-TUBB8B",
    "gene-TUBD1",
    "gene-TUBE1",
    "gene-TUBG1",
    "gene-TUBG2",
    "gene-TUBGCP2",
    "gene-TUBGCP3",
    "gene-TUBGCP4",
    "gene-TUBGCP5",
    "gene-TUBGCP6",
    "gene-TUFM",
    "gene-TUFT1",
    "gene-TUG1",
    "gene-TULP1",
    "gene-TULP2",
    "gene-TULP3",
    "gene-TULP4",
    "gene-TUSC1",
    "gene-TUSC2",
    "gene-TUSC3",
    "gene-TUT1",
    "gene-TUT4",
    "gene-TUT7",
    "gene-TVP23A",
    "gene-TVP23B",
    "gene-TVP23C",
    "gene-TWF1",
    "gene-TWF2",
    "gene-TWIST1",
    "gene-TWIST2",
    "gene-TWNK",
    "gene-TWSG1",
    "gene-TXK",
    "gene-TXLNA",
    "gene-TXLNB",
    "gene-TXLNG",
    "gene-TXN",
    "gene-TXN2",
    "gene-TXNDC11",
    "gene-TXNDC12",
    "gene-TXNDC15",
    "gene-TXNDC16",
    "gene-TXNDC17",
    "gene-TXNDC2",
    "gene-TXNDC5",
    "gene-TXNDC8",
    "gene-TXNDC9",
    "gene-TXNIP",
    "gene-TXNL1",
    "gene-TXNL4A",
    "gene-TXNL4B",
    "gene-TXNRD1",
    "gene-TXNRD2",
    "gene-TXNRD3",
    "gene-TYK2",
    "gene-TYMP",
    "gene-TYMS",
    "gene-TYR",
    "gene-TYRO3",
    "gene-TYROBP",
    "gene-TYRP1",
    "gene-TYSND1",
    "gene-TYW1",
    "gene-TYW1B",
    "gene-TYW3",
    "gene-TYW5",
    "gene-U2AF1",
    "gene-U2AF1L4",
    "gene-U2AF2",
    "gene-U2SURP",
    "gene-UACA",
    "gene-UAP1",
    "gene-UAP1L1",
    "gene-UBA1",
    "gene-UBA2",
    "gene-UBA3",
    "gene-UBA5",
    "gene-UBA52",
    "gene-UBA6",
    "gene-UBA7",
    "gene-UBAC1",
    "gene-UBAC2",
    "gene-UBALD1",
    "gene-UBALD2",
    "gene-UBAP1",
    "gene-UBAP1L",
    "gene-UBAP2",
    "gene-UBAP2L",
    "gene-UBASH3A",
    "gene-UBASH3B",
    "gene-UBB",
    "gene-UBC",
    "gene-UBD",
    "gene-UBE2A",
    "gene-UBE2B",
    "gene-UBE2C",
    "gene-UBE2D1",
    "gene-UBE2D2",
    "gene-UBE2D3",
    "gene-UBE2D4",
    "gene-UBE2E1",
    "gene-UBE2E2",
    "gene-UBE2E3",
    "gene-UBE2F",
    "gene-UBE2G1",
    "gene-UBE2G2",
    "gene-UBE2H",
    "gene-UBE2I",
    "gene-UBE2J1",
    "gene-UBE2J2",
    "gene-UBE2K",
    "gene-UBE2L3",
    "gene-UBE2L5",
    "gene-UBE2L6",
    "gene-UBE2M",
    "gene-UBE2N",
    "gene-UBE2NL",
    "gene-UBE2O",
    "gene-UBE2Q1",
    "gene-UBE2Q2",
    "gene-UBE2QL1",
    "gene-UBE2R2",
    "gene-UBE2S",
    "gene-UBE2T",
    "gene-UBE2U",
    "gene-UBE2V1",
    "gene-UBE2V2",
    "gene-UBE2W",
    "gene-UBE2Z",
    "gene-UBE3A",
    "gene-UBE3B",
    "gene-UBE3C",
    "gene-UBE3D",
    "gene-UBE4A",
    "gene-UBE4B",
    "gene-UBFD1",
    "gene-UBIAD1",
    "gene-UBL3",
    "gene-UBL4A",
    "gene-UBL4B",
    "gene-UBL5",
    "gene-UBL7",
    "gene-UBLCP1",
    "gene-UBN1",
    "gene-UBN2",
    "gene-UBOX5",
    "gene-UBP1",
    "gene-UBQLN1",
    "gene-UBQLN2",
    "gene-UBQLN3",
    "gene-UBQLN4",
    "gene-UBQLNL",
    "gene-UBR1",
    "gene-UBR2",
    "gene-UBR3",
    "gene-UBR4",
    "gene-UBR5",
    "gene-UBR7",
    "gene-UBTD1",
    "gene-UBTD2",
    "gene-UBTF",
    "gene-UBTFL1",
    "gene-UBXN1",
    "gene-UBXN10",
    "gene-UBXN11",
    "gene-UBXN2A",
    "gene-UBXN2B",
    "gene-UBXN4",
    "gene-UBXN6",
    "gene-UBXN7",
    "gene-UBXN8",
    "gene-UCHL1",
    "gene-UCHL3",
    "gene-UCHL5",
    "gene-UCK1",
    "gene-UCK2",
    "gene-UCKL1",
    "gene-UCMA",
    "gene-UCN",
    "gene-UCN2",
    "gene-UCN3",
    "gene-UCP1",
    "gene-UCP2",
    "gene-UCP3",
    "gene-UEVLD",
    "gene-UFC1",
    "gene-UFD1",
    "gene-UFL1",
    "gene-UFM1",
    "gene-UFSP1",
    "gene-UFSP2",
    "gene-UGCG",
    "gene-UGDH",
    "gene-UGGT1",
    "gene-UGGT2",
    "gene-UGP2",
    "gene-UGT1A",
    "gene-UGT2A1",
    "gene-UGT2A3",
    "gene-UGT2B10",
    "gene-UGT2B11",
    "gene-UGT2B15",
    "gene-UGT2B17",
    "gene-UGT2B28",
    "gene-UGT2B4",
    "gene-UGT2B7",
    "gene-UGT3A1",
    "gene-UGT3A2",
    "gene-UGT8",
    "gene-UHMK1",
    "gene-UHRF1",
    "gene-UHRF2",
    "gene-UIMC1",
    "gene-ULBP1",
    "gene-ULBP2",
    "gene-ULBP3",
    "gene-ULK1",
    "gene-ULK2",
    "gene-ULK3",
    "gene-ULK4",
    "gene-UMAD1",
    "gene-UMOD",
    "gene-UMODL1",
    "gene-UMPS",
    "gene-UNC119",
    "gene-UNC119B",
    "gene-UNC13A",
    "gene-UNC13B",
    "gene-UNC13C",
    "gene-UNC13D",
    "gene-UNC45A",
    "gene-UNC45B",
    "gene-UNC50",
    "gene-UNC5A",
    "gene-UNC5B",
    "gene-UNC5C",
    "gene-UNC5CL",
    "gene-UNC5D",
    "gene-UNC79",
    "gene-UNC80",
    "gene-UNC93A",
    "gene-UNC93B1",
    "gene-UNCX",
    "gene-UNG",
    "gene-UNK",
    "gene-UNKL",
    "gene-UPB1",
    "gene-UPF1",
    "gene-UPF2",
    "gene-UPF3A",
    "gene-UPF3B",
    "gene-UPK1A",
    "gene-UPK1B",
    "gene-UPK2",
    "gene-UPK3A",
    "gene-UPK3B",
    "gene-UPK3BL1",
    "gene-UPK3BL2",
    "gene-UPP1",
    "gene-UPP2",
    "gene-UPRT",
    "gene-UQCC1",
    "gene-UQCC2",
    "gene-UQCC3",
    "gene-UQCC4",
    "gene-UQCC5",
    "gene-UQCC6",
    "gene-UQCR10",
    "gene-UQCR11",
    "gene-UQCRB",
    "gene-UQCRC1",
    "gene-UQCRC2",
    "gene-UQCRFS1",
    "gene-UQCRH",
    "gene-UQCRHL",
    "gene-UQCRQ",
    "gene-URAD",
    "gene-URB1",
    "gene-URB2",
    "gene-URGCP",
    "gene-URI1",
    "gene-URM1",
    "gene-UROC1",
    "gene-UROD",
    "gene-UROS",
    "gene-USB1",
    "gene-USE1",
    "gene-USF1",
    "gene-USF2",
    "gene-USF3",
    "gene-USH1C",
    "gene-USH1G",
    "gene-USH2A",
    "gene-USHBP1",
    "gene-USO1",
    "gene-USP1",
    "gene-USP10",
    "gene-USP11",
    "gene-USP12",
    "gene-USP13",
    "gene-USP14",
    "gene-USP15",
    "gene-USP16",
    "gene-USP17L1",
    "gene-USP17L10",
    "gene-USP17L11",
    "gene-USP17L12",
    "gene-USP17L13",
    "gene-USP17L15",
    "gene-USP17L17",
    "gene-USP17L18",
    "gene-USP17L19",
    "gene-USP17L2",
    "gene-USP17L20",
    "gene-USP17L21",
    "gene-USP17L22",
    "gene-USP17L23",
    "gene-USP17L24",
    "gene-USP17L25",
    "gene-USP17L26",
    "gene-USP17L27",
    "gene-USP17L28",
    "gene-USP17L29",
    "gene-USP17L3",
    "gene-USP17L30",
    "gene-USP17L4",
    "gene-USP17L5",
    "gene-USP17L7",
    "gene-USP17L8",
    "gene-USP18",
    "gene-USP19",
    "gene-USP2",
    "gene-USP20",
    "gene-USP21",
    "gene-USP22",
    "gene-USP24",
    "gene-USP25",
    "gene-USP26",
    "gene-USP27X",
    "gene-USP28",
    "gene-USP29",
    "gene-USP3",
    "gene-USP30",
    "gene-USP31",
    "gene-USP32",
    "gene-USP33",
    "gene-USP34",
    "gene-USP35",
    "gene-USP36",
    "gene-USP37",
    "gene-USP38",
    "gene-USP39",
    "gene-USP4",
    "gene-USP40",
    "gene-USP42",
    "gene-USP43",
    "gene-USP44",
    "gene-USP45",
    "gene-USP46",
    "gene-USP47",
    "gene-USP48",
    "gene-USP49",
    "gene-USP5",
    "gene-USP50",
    "gene-USP51",
    "gene-USP53",
    "gene-USP54",
    "gene-USP6",
    "gene-USP6NL",
    "gene-USP7",
    "gene-USP8",
    "gene-USP9X",
    "gene-USP9Y",
    "gene-USPL1",
    "gene-UST",
    "gene-UTF1",
    "gene-UTP11",
    "gene-UTP14A",
    "gene-UTP14C",
    "gene-UTP15",
    "gene-UTP18",
    "gene-UTP20",
    "gene-UTP23",
    "gene-UTP25",
    "gene-UTP3",
    "gene-UTP4",
    "gene-UTP6",
    "gene-UTRN",
    "gene-UTS2",
    "gene-UTS2B",
    "gene-UTS2R",
    "gene-UTY",
    "gene-UVRAG",
    "gene-UVSSA",
    "gene-UXS1",
    "gene-UXT",
    "gene-VAC14",
    "gene-VAMP1",
    "gene-VAMP2",
    "gene-VAMP3",
    "gene-VAMP4",
    "gene-VAMP5",
    "gene-VAMP7",
    "gene-VAMP8",
    "gene-VANGL1",
    "gene-VANGL2",
    "gene-VAPA",
    "gene-VAPB",
    "gene-VARS1",
    "gene-VARS2",
    "gene-VASH1",
    "gene-VASH2",
    "gene-VASN",
    "gene-VASP",
    "gene-VAT1",
    "gene-VAT1L",
    "gene-VAV1",
    "gene-VAV2",
    "gene-VAV3",
    "gene-VAX1",
    "gene-VAX2",
    "gene-VBP1",
    "gene-VCAM1",
    "gene-VCAN",
    "gene-VCF1",
    "gene-VCF2",
    "gene-VCL",
    "gene-VCP",
    "gene-VCPIP1",
    "gene-VCPKMT",
    "gene-VCX",
    "gene-VCX2",
    "gene-VCX3A",
    "gene-VCX3B",
    "gene-VCY",
    "gene-VCY1B",
    "gene-VDAC1",
    "gene-VDAC2",
    "gene-VDAC3",
    "gene-VDR",
    "gene-VEGFA",
    "gene-VEGFB",
    "gene-VEGFC",
    "gene-VEGFD",
    "gene-VENTX",
    "gene-VEPH1",
    "gene-VEZF1",
    "gene-VEZT",
    "gene-VGF",
    "gene-VGLL1",
    "gene-VGLL2",
    "gene-VGLL3",
    "gene-VGLL4",
    "gene-VHL",
    "gene-VHLL",
    "gene-VIL1",
    "gene-VILL",
    "gene-VIM",
    "gene-VIP",
    "gene-VIPAS39",
    "gene-VIPR1",
    "gene-VIPR2",
    "gene-VIRMA",
    "gene-VIT",
    "gene-VKORC1",
    "gene-VKORC1L1",
    "gene-VLDLR",
    "gene-VMA21",
    "gene-VMAC",
    "gene-VMO1",
    "gene-VMP1",
    "gene-VN1R1",
    "gene-VN1R2",
    "gene-VN1R4",
    "gene-VN1R5",
    "gene-VNN1",
    "gene-VNN2",
    "gene-VOPP1",
    "gene-VPREB1",
    "gene-VPREB3",
    "gene-VPS11",
    "gene-VPS13A",
    "gene-VPS13B",
    "gene-VPS13C",
    "gene-VPS13D",
    "gene-VPS16",
    "gene-VPS18",
    "gene-VPS25",
    "gene-VPS26A",
    "gene-VPS26B",
    "gene-VPS26C",
    "gene-VPS28",
    "gene-VPS29",
    "gene-VPS33A",
    "gene-VPS33B",
    "gene-VPS35",
    "gene-VPS35L",
    "gene-VPS36",
    "gene-VPS37A",
    "gene-VPS37B",
    "gene-VPS37C",
    "gene-VPS37D",
    "gene-VPS39",
    "gene-VPS41",
    "gene-VPS45",
    "gene-VPS4A",
    "gene-VPS4B",
    "gene-VPS50",
    "gene-VPS51",
    "gene-VPS52",
    "gene-VPS53",
    "gene-VPS54",
    "gene-VPS72",
    "gene-VPS8",
    "gene-VPS9D1",
    "gene-VRK1",
    "gene-VRK2",
    "gene-VRK3",
    "gene-VRTN",
    "gene-VSIG1",
    "gene-VSIG10",
    "gene-VSIG10L",
    "gene-VSIG10L2",
    "gene-VSIG2",
    "gene-VSIG4",
    "gene-VSIG8",
    "gene-VSIR",
    "gene-VSNL1",
    "gene-VSTM1",
    "gene-VSTM2A",
    "gene-VSTM2B",
    "gene-VSTM2L",
    "gene-VSTM4",
    "gene-VSTM5",
    "gene-VSX1",
    "gene-VSX2",
    "gene-VTA1",
    "gene-VTCN1",
    "gene-VTI1A",
    "gene-VTI1B",
    "gene-VTN",
    "gene-VWA1",
    "gene-VWA2",
    "gene-VWA3A",
    "gene-VWA3B",
    "gene-VWA5A",
    "gene-VWA5B1",
    "gene-VWA5B2",
    "gene-VWA7",
    "gene-VWA8",
    "gene-VWC2",
    "gene-VWC2L",
    "gene-VWCE",
    "gene-VWDE",
    "gene-VWF",
    "gene-VXN",
    "gene-WAC",
    "gene-WAPL",
    "gene-WARS1",
    "gene-WARS2",
    "gene-WAS",
    "gene-WASF1",
    "gene-WASF2",
    "gene-WASF3",
    "gene-WASHC1",
    "gene-WASHC2A",
    "gene-WASHC2C",
    "gene-WASHC3",
    "gene-WASHC4",
    "gene-WASHC5",
    "gene-WASL",
    "gene-WBP1",
    "gene-WBP11",
    "gene-WBP1L",
    "gene-WBP2",
    "gene-WBP2NL",
    "gene-WBP4",
    "gene-WDCP",
    "gene-WDFY1",
    "gene-WDFY2",
    "gene-WDFY3",
    "gene-WDFY4",
    "gene-WDHD1",
    "gene-WDPCP",
    "gene-WDR1",
    "gene-WDR11",
    "gene-WDR12",
    "gene-WDR13",
    "gene-WDR17",
    "gene-WDR18",
    "gene-WDR19",
    "gene-WDR20",
    "gene-WDR24",
    "gene-WDR25",
    "gene-WDR26",
    "gene-WDR27",
    "gene-WDR3",
    "gene-WDR31",
    "gene-WDR33",
    "gene-WDR35",
    "gene-WDR36",
    "gene-WDR37",
    "gene-WDR38",
    "gene-WDR4",
    "gene-WDR41",
    "gene-WDR43",
    "gene-WDR44",
    "gene-WDR45",
    "gene-WDR45B",
    "gene-WDR46",
    "gene-WDR47",
    "gene-WDR48",
    "gene-WDR49",
    "gene-WDR5",
    "gene-WDR53",
    "gene-WDR54",
    "gene-WDR55",
    "gene-WDR59",
    "gene-WDR5B",
    "gene-WDR6",
    "gene-WDR62",
    "gene-WDR64",
    "gene-WDR7",
    "gene-WDR70",
    "gene-WDR72",
    "gene-WDR73",
    "gene-WDR74",
    "gene-WDR75",
    "gene-WDR76",
    "gene-WDR77",
    "gene-WDR81",
    "gene-WDR82",
    "gene-WDR83",
    "gene-WDR83OS",
    "gene-WDR86",
    "gene-WDR87",
    "gene-WDR88",
    "gene-WDR89",
    "gene-WDR90",
    "gene-WDR91",
    "gene-WDR93",
    "gene-WDR97",
    "gene-WDSUB1",
    "gene-WDTC1",
    "gene-WEE1",
    "gene-WEE2",
    "gene-WFDC1",
    "gene-WFDC10A",
    "gene-WFDC10B",
    "gene-WFDC11",
    "gene-WFDC12",
    "gene-WFDC13",
    "gene-WFDC2",
    "gene-WFDC3",
    "gene-WFDC5",
    "gene-WFDC6",
    "gene-WFDC8",
    "gene-WFDC9",
    "gene-WFIKKN1",
    "gene-WFIKKN2",
    "gene-WFS1",
    "gene-WHAMM",
    "gene-WHRN",
    "gene-WIF1",
    "gene-WIPF1",
    "gene-WIPF2",
    "gene-WIPF3",
    "gene-WIPI1",
    "gene-WIPI2",
    "gene-WIZ",
    "gene-WLS",
    "gene-WNK1",
    "gene-WNK2",
    "gene-WNK3",
    "gene-WNK4",
    "gene-WNT1",
    "gene-WNT10A",
    "gene-WNT10B",
    "gene-WNT11",
    "gene-WNT16",
    "gene-WNT2",
    "gene-WNT2B",
    "gene-WNT3",
    "gene-WNT3A",
    "gene-WNT4",
    "gene-WNT5A",
    "gene-WNT5B",
    "gene-WNT6",
    "gene-WNT7A",
    "gene-WNT7B",
    "gene-WNT8A",
    "gene-WNT8B",
    "gene-WNT9A",
    "gene-WNT9B",
    "gene-WRAP53",
    "gene-WRAP73",
    "gene-WRN",
    "gene-WRNIP1",
    "gene-WSB1",
    "gene-WSB2",
    "gene-WSCD1",
    "gene-WSCD2",
    "gene-WT1",
    "gene-WTAP",
    "gene-WTIP",
    "gene-WWC1",
    "gene-WWC2",
    "gene-WWC3",
    "gene-WWOX",
    "gene-WWP1",
    "gene-WWP2",
    "gene-WWTR1",
    "gene-XAB2",
    "gene-XAF1",
    "gene-XAGE1A",
    "gene-XAGE1B",
    "gene-XAGE2",
    "gene-XAGE3",
    "gene-XAGE5",
    "gene-XBP1",
    "gene-XCL1",
    "gene-XCL2",
    "gene-XCR1",
    "gene-XDH",
    "gene-XG",
    "gene-XIAP",
    "gene-XIRP1",
    "gene-XIRP2",
    "gene-XK",
    "gene-XKR3",
    "gene-XKR4",
    "gene-XKR5",
    "gene-XKR6",
    "gene-XKR7",
    "gene-XKR8",
    "gene-XKR9",
    "gene-XKRX",
    "gene-XNDC1N",
    "gene-XPA",
    "gene-XPC",
    "gene-XPNPEP1",
    "gene-XPNPEP2",
    "gene-XPNPEP3",
    "gene-XPO1",
    "gene-XPO4",
    "gene-XPO5",
    "gene-XPO6",
    "gene-XPO7",
    "gene-XPOT",
    "gene-XPR1",
    "gene-XRCC1",
    "gene-XRCC2",
    "gene-XRCC3",
    "gene-XRCC4",
    "gene-XRCC5",
    "gene-XRCC6",
    "gene-XRN1",
    "gene-XRN2",
    "gene-XRRA1",
    "gene-XXYLT1",
    "gene-XYLB",
    "gene-XYLT1",
    "gene-XYLT2",
    "gene-YAE1",
    "gene-YAF2",
    "gene-YAP1",
    "gene-YARS1",
    "gene-YARS2",
    "gene-YBEY",
    "gene-YBX1",
    "gene-YBX2",
    "gene-YBX3",
    "gene-YDJC",
    "gene-YEATS2",
    "gene-YEATS4",
    "gene-YES1",
    "gene-YIF1A",
    "gene-YIF1B",
    "gene-YIPF1",
    "gene-YIPF2",
    "gene-YIPF3",
    "gene-YIPF4",
    "gene-YIPF5",
    "gene-YIPF6",
    "gene-YIPF7",
    "gene-YJEFN3",
    "gene-YJU2",
    "gene-YJU2B",
    "gene-YKT6",
    "gene-YLPM1",
    "gene-YME1L1",
    "gene-YOD1",
    "gene-YPEL1",
    "gene-YPEL2",
    "gene-YPEL3",
    "gene-YPEL4",
    "gene-YPEL5",
    "gene-YRDC",
    "gene-YTHDC1",
    "gene-YTHDC2",
    "gene-YTHDF1",
    "gene-YTHDF2",
    "gene-YTHDF3",
    "gene-YWHAB",
    "gene-YWHAE",
    "gene-YWHAG",
    "gene-YWHAH",
    "gene-YWHAQ",
    "gene-YWHAZ",
    "gene-YY1",
    "gene-YY1AP1",
    "gene-YY2",
    "gene-ZACN",
    "gene-ZAN",
    "gene-ZAP70",
    "gene-ZAR1",
    "gene-ZAR1L",
    "gene-ZBBX",
    "gene-ZBED1",
    "gene-ZBED2",
    "gene-ZBED3",
    "gene-ZBED4",
    "gene-ZBED5",
    "gene-ZBED6",
    "gene-ZBP1",
    "gene-ZBTB1",
    "gene-ZBTB10",
    "gene-ZBTB11",
    "gene-ZBTB12",
    "gene-ZBTB14",
    "gene-ZBTB16",
    "gene-ZBTB17",
    "gene-ZBTB18",
    "gene-ZBTB2",
    "gene-ZBTB20",
    "gene-ZBTB21",
    "gene-ZBTB22",
    "gene-ZBTB24",
    "gene-ZBTB25",
    "gene-ZBTB26",
    "gene-ZBTB3",
    "gene-ZBTB32",
    "gene-ZBTB33",
    "gene-ZBTB34",
    "gene-ZBTB37",
    "gene-ZBTB38",
    "gene-ZBTB39",
    "gene-ZBTB4",
    "gene-ZBTB40",
    "gene-ZBTB41",
    "gene-ZBTB42",
    "gene-ZBTB43",
    "gene-ZBTB44",
    "gene-ZBTB45",
    "gene-ZBTB46",
    "gene-ZBTB47",
    "gene-ZBTB48",
    "gene-ZBTB49",
    "gene-ZBTB5",
    "gene-ZBTB6",
    "gene-ZBTB7A",
    "gene-ZBTB7B",
    "gene-ZBTB7C",
    "gene-ZBTB8A",
    "gene-ZBTB8B",
    "gene-ZBTB8OS",
    "gene-ZBTB9",
    "gene-ZC2HC1A",
    "gene-ZC2HC1B",
    "gene-ZC2HC1C",
    "gene-ZC3H10",
    "gene-ZC3H11A",
    "gene-ZC3H11B",
    "gene-ZC3H11C",
    "gene-ZC3H12A",
    "gene-ZC3H12B",
    "gene-ZC3H12C",
    "gene-ZC3H12D",
    "gene-ZC3H13",
    "gene-ZC3H14",
    "gene-ZC3H15",
    "gene-ZC3H18",
    "gene-ZC3H3",
    "gene-ZC3H4",
    "gene-ZC3H6",
    "gene-ZC3H7A",
    "gene-ZC3H7B",
    "gene-ZC3H8",
    "gene-ZC3HAV1",
    "gene-ZC3HAV1L",
    "gene-ZC3HC1",
    "gene-ZC4H2",
    "gene-ZCCHC10",
    "gene-ZCCHC12",
    "gene-ZCCHC13",
    "gene-ZCCHC14",
    "gene-ZCCHC17",
    "gene-ZCCHC18",
    "gene-ZCCHC2",
    "gene-ZCCHC24",
    "gene-ZCCHC3",
    "gene-ZCCHC4",
    "gene-ZCCHC7",
    "gene-ZCCHC8",
    "gene-ZCCHC9",
    "gene-ZCRB1",
    "gene-ZCWPW1",
    "gene-ZCWPW2",
    "gene-ZDBF2",
    "gene-ZDHHC1",
    "gene-ZDHHC11",
    "gene-ZDHHC11B",
    "gene-ZDHHC12",
    "gene-ZDHHC13",
    "gene-ZDHHC14",
    "gene-ZDHHC15",
    "gene-ZDHHC16",
    "gene-ZDHHC17",
    "gene-ZDHHC18",
    "gene-ZDHHC19",
    "gene-ZDHHC2",
    "gene-ZDHHC20",
    "gene-ZDHHC21",
    "gene-ZDHHC22",
    "gene-ZDHHC23",
    "gene-ZDHHC24",
    "gene-ZDHHC3",
    "gene-ZDHHC4",
    "gene-ZDHHC5",
    "gene-ZDHHC6",
    "gene-ZDHHC7",
    "gene-ZDHHC8",
    "gene-ZDHHC9",
    "gene-ZEB1",
    "gene-ZEB2",
    "gene-ZER1",
    "gene-ZFAND1",
    "gene-ZFAND2A",
    "gene-ZFAND2B",
    "gene-ZFAND3",
    "gene-ZFAND4",
    "gene-ZFAND5",
    "gene-ZFAND6",
    "gene-ZFAT",
    "gene-ZFC3H1",
    "gene-ZFHX2",
    "gene-ZFHX3",
    "gene-ZFHX4",
    "gene-ZFP1",
    "gene-ZFP14",
    "gene-ZFP2",
    "gene-ZFP28",
    "gene-ZFP3",
    "gene-ZFP30",
    "gene-ZFP36",
    "gene-ZFP36L1",
    "gene-ZFP36L2",
    "gene-ZFP37",
    "gene-ZFP41",
    "gene-ZFP42",
    "gene-ZFP57",
    "gene-ZFP62",
    "gene-ZFP64",
    "gene-ZFP69",
    "gene-ZFP69B",
    "gene-ZFP82",
    "gene-ZFP90",
    "gene-ZFP91",
    "gene-ZFP92",
    "gene-ZFPL1",
    "gene-ZFPM1",
    "gene-ZFPM2",
    "gene-ZFR",
    "gene-ZFR2",
    "gene-ZFTA",
    "gene-ZFTRAF1",
    "gene-ZFX",
    "gene-ZFY",
    "gene-ZFYVE1",
    "gene-ZFYVE16",
    "gene-ZFYVE19",
    "gene-ZFYVE21",
    "gene-ZFYVE26",
    "gene-ZFYVE27",
    "gene-ZFYVE28",
    "gene-ZFYVE9",
    "gene-ZG16",
    "gene-ZG16B",
    "gene-ZGLP1",
    "gene-ZGPAT",
    "gene-ZGRF1",
    "gene-ZHX1",
    "gene-ZHX2",
    "gene-ZHX3",
    "gene-ZIC1",
    "gene-ZIC2",
    "gene-ZIC3",
    "gene-ZIC4",
    "gene-ZIC5",
    "gene-ZIK1",
    "gene-ZIM2",
    "gene-ZIM3",
    "gene-ZKSCAN1",
    "gene-ZKSCAN2",
    "gene-ZKSCAN3",
    "gene-ZKSCAN4",
    "gene-ZKSCAN5",
    "gene-ZKSCAN7",
    "gene-ZKSCAN8",
    "gene-ZMAT1",
    "gene-ZMAT2",
    "gene-ZMAT3",
    "gene-ZMAT4",
    "gene-ZMAT5",
    "gene-ZMIZ1",
    "gene-ZMIZ2",
    "gene-ZMPSTE24",
    "gene-ZMYM1",
    "gene-ZMYM2",
    "gene-ZMYM3",
    "gene-ZMYM4",
    "gene-ZMYM5",
    "gene-ZMYM6",
    "gene-ZMYND10",
    "gene-ZMYND11",
    "gene-ZMYND12",
    "gene-ZMYND15",
    "gene-ZMYND19",
    "gene-ZMYND8",
    "gene-ZNF10",
    "gene-ZNF100",
    "gene-ZNF101",
    "gene-ZNF106",
    "gene-ZNF107",
    "gene-ZNF112",
    "gene-ZNF114",
    "gene-ZNF117",
    "gene-ZNF12",
    "gene-ZNF121",
    "gene-ZNF124",
    "gene-ZNF131",
    "gene-ZNF132",
    "gene-ZNF133",
    "gene-ZNF134",
    "gene-ZNF135",
    "gene-ZNF136",
    "gene-ZNF138",
    "gene-ZNF14",
    "gene-ZNF140",
    "gene-ZNF141",
    "gene-ZNF142",
    "gene-ZNF143",
    "gene-ZNF146",
    "gene-ZNF148",
    "gene-ZNF154",
    "gene-ZNF155",
    "gene-ZNF157",
    "gene-ZNF16",
    "gene-ZNF160",
    "gene-ZNF165",
    "gene-ZNF169",
    "gene-ZNF17",
    "gene-ZNF174",
    "gene-ZNF175",
    "gene-ZNF177",
    "gene-ZNF18",
    "gene-ZNF180",
    "gene-ZNF181",
    "gene-ZNF182",
    "gene-ZNF184",
    "gene-ZNF185",
    "gene-ZNF189",
    "gene-ZNF19",
    "gene-ZNF195",
    "gene-ZNF197",
    "gene-ZNF2",
    "gene-ZNF20",
    "gene-ZNF200",
    "gene-ZNF202",
    "gene-ZNF205",
    "gene-ZNF207",
    "gene-ZNF208",
    "gene-ZNF211",
    "gene-ZNF212",
    "gene-ZNF213",
    "gene-ZNF214",
    "gene-ZNF215",
    "gene-ZNF217",
    "gene-ZNF219",
    "gene-ZNF22",
    "gene-ZNF221",
    "gene-ZNF222",
    "gene-ZNF223",
    "gene-ZNF224",
    "gene-ZNF225",
    "gene-ZNF226",
    "gene-ZNF227",
    "gene-ZNF229",
    "gene-ZNF23",
    "gene-ZNF230",
    "gene-ZNF232",
    "gene-ZNF233",
    "gene-ZNF234",
    "gene-ZNF235",
    "gene-ZNF236",
    "gene-ZNF239",
    "gene-ZNF24",
    "gene-ZNF248",
    "gene-ZNF25",
    "gene-ZNF250",
    "gene-ZNF251",
    "gene-ZNF253",
    "gene-ZNF254",
    "gene-ZNF256",
    "gene-ZNF257",
    "gene-ZNF26",
    "gene-ZNF260",
    "gene-ZNF263",
    "gene-ZNF264",
    "gene-ZNF266",
    "gene-ZNF267",
    "gene-ZNF268",
    "gene-ZNF273",
    "gene-ZNF274",
    "gene-ZNF275",
    "gene-ZNF276",
    "gene-ZNF277",
    "gene-ZNF28",
    "gene-ZNF280A",
    "gene-ZNF280B",
    "gene-ZNF280C",
    "gene-ZNF280D",
    "gene-ZNF281",
    "gene-ZNF282",
    "gene-ZNF283",
    "gene-ZNF284",
    "gene-ZNF285",
    "gene-ZNF286A",
    "gene-ZNF287",
    "gene-ZNF292",
    "gene-ZNF296",
    "gene-ZNF3",
    "gene-ZNF30",
    "gene-ZNF300",
    "gene-ZNF302",
    "gene-ZNF304",
    "gene-ZNF311",
    "gene-ZNF316",
    "gene-ZNF317",
    "gene-ZNF318",
    "gene-ZNF319",
    "gene-ZNF32",
    "gene-ZNF320",
    "gene-ZNF322",
    "gene-ZNF324",
    "gene-ZNF324B",
    "gene-ZNF326",
    "gene-ZNF329",
    "gene-ZNF330",
    "gene-ZNF331",
    "gene-ZNF333",
    "gene-ZNF334",
    "gene-ZNF335",
    "gene-ZNF337",
    "gene-ZNF33A",
    "gene-ZNF33B",
    "gene-ZNF34",
    "gene-ZNF341",
    "gene-ZNF343",
    "gene-ZNF345",
    "gene-ZNF346",
    "gene-ZNF347",
    "gene-ZNF35",
    "gene-ZNF350",
    "gene-ZNF354A",
    "gene-ZNF354B",
    "gene-ZNF354C",
    "gene-ZNF358",
    "gene-ZNF362",
    "gene-ZNF365",
    "gene-ZNF366",
    "gene-ZNF367",
    "gene-ZNF37A",
    "gene-ZNF382",
    "gene-ZNF383",
    "gene-ZNF384",
    "gene-ZNF385A",
    "gene-ZNF385B",
    "gene-ZNF385C",
    "gene-ZNF385D",
    "gene-ZNF391",
    "gene-ZNF394",
    "gene-ZNF395",
    "gene-ZNF396",
    "gene-ZNF397",
    "gene-ZNF398",
    "gene-ZNF404",
    "gene-ZNF407",
    "gene-ZNF408",
    "gene-ZNF41",
    "gene-ZNF410",
    "gene-ZNF414",
    "gene-ZNF415",
    "gene-ZNF416",
    "gene-ZNF417",
    "gene-ZNF418",
    "gene-ZNF419",
    "gene-ZNF420",
    "gene-ZNF423",
    "gene-ZNF425",
    "gene-ZNF426",
    "gene-ZNF428",
    "gene-ZNF429",
    "gene-ZNF43",
    "gene-ZNF430",
    "gene-ZNF431",
    "gene-ZNF432",
    "gene-ZNF433",
    "gene-ZNF436",
    "gene-ZNF438",
    "gene-ZNF439",
    "gene-ZNF44",
    "gene-ZNF440",
    "gene-ZNF441",
    "gene-ZNF442",
    "gene-ZNF443",
    "gene-ZNF444",
    "gene-ZNF445",
    "gene-ZNF446",
    "gene-ZNF449",
    "gene-ZNF45",
    "gene-ZNF451",
    "gene-ZNF454",
    "gene-ZNF460",
    "gene-ZNF461",
    "gene-ZNF462",
    "gene-ZNF467",
    "gene-ZNF468",
    "gene-ZNF469",
    "gene-ZNF470",
    "gene-ZNF471",
    "gene-ZNF473",
    "gene-ZNF474",
    "gene-ZNF475",
    "gene-ZNF479",
    "gene-ZNF48",
    "gene-ZNF480",
    "gene-ZNF483",
    "gene-ZNF484",
    "gene-ZNF485",
    "gene-ZNF486",
    "gene-ZNF487",
    "gene-ZNF488",
    "gene-ZNF490",
    "gene-ZNF491",
    "gene-ZNF492",
    "gene-ZNF493",
    "gene-ZNF496",
    "gene-ZNF497",
    "gene-ZNF500",
    "gene-ZNF501",
    "gene-ZNF502",
    "gene-ZNF503",
    "gene-ZNF506",
    "gene-ZNF507",
    "gene-ZNF510",
    "gene-ZNF511",
    "gene-ZNF512",
    "gene-ZNF512B",
    "gene-ZNF513",
    "gene-ZNF514",
    "gene-ZNF516",
    "gene-ZNF517",
    "gene-ZNF518A",
    "gene-ZNF518B",
    "gene-ZNF519",
    "gene-ZNF521",
    "gene-ZNF524",
    "gene-ZNF525",
    "gene-ZNF526",
    "gene-ZNF527",
    "gene-ZNF528",
    "gene-ZNF529",
    "gene-ZNF530",
    "gene-ZNF532",
    "gene-ZNF534",
    "gene-ZNF536",
    "gene-ZNF540",
    "gene-ZNF541",
    "gene-ZNF543",
    "gene-ZNF544",
    "gene-ZNF546",
    "gene-ZNF547",
    "gene-ZNF548",
    "gene-ZNF549",
    "gene-ZNF550",
    "gene-ZNF551",
    "gene-ZNF552",
    "gene-ZNF554",
    "gene-ZNF555",
    "gene-ZNF556",
    "gene-ZNF557",
    "gene-ZNF558",
    "gene-ZNF559",
    "gene-ZNF560",
    "gene-ZNF561",
    "gene-ZNF562",
    "gene-ZNF563",
    "gene-ZNF564",
    "gene-ZNF565",
    "gene-ZNF566",
    "gene-ZNF567",
    "gene-ZNF568",
    "gene-ZNF569",
    "gene-ZNF57",
    "gene-ZNF570",
    "gene-ZNF571",
    "gene-ZNF572",
    "gene-ZNF573",
    "gene-ZNF574",
    "gene-ZNF575",
    "gene-ZNF576",
    "gene-ZNF577",
    "gene-ZNF578",
    "gene-ZNF579",
    "gene-ZNF580",
    "gene-ZNF581",
    "gene-ZNF582",
    "gene-ZNF583",
    "gene-ZNF584",
    "gene-ZNF585A",
    "gene-ZNF585B",
    "gene-ZNF586",
    "gene-ZNF587",
    "gene-ZNF587B",
    "gene-ZNF589",
    "gene-ZNF592",
    "gene-ZNF593",
    "gene-ZNF593OS",
    "gene-ZNF594",
    "gene-ZNF595",
    "gene-ZNF596",
    "gene-ZNF597",
    "gene-ZNF598",
    "gene-ZNF599",
    "gene-ZNF600",
    "gene-ZNF605",
    "gene-ZNF606",
    "gene-ZNF607",
    "gene-ZNF608",
    "gene-ZNF609",
    "gene-ZNF610",
    "gene-ZNF611",
    "gene-ZNF613",
    "gene-ZNF614",
    "gene-ZNF615",
    "gene-ZNF616",
    "gene-ZNF618",
    "gene-ZNF619",
    "gene-ZNF620",
    "gene-ZNF621",
    "gene-ZNF622",
    "gene-ZNF623",
    "gene-ZNF624",
    "gene-ZNF625",
    "gene-ZNF626",
    "gene-ZNF627",
    "gene-ZNF628",
    "gene-ZNF629",
    "gene-ZNF630",
    "gene-ZNF638",
    "gene-ZNF639",
    "gene-ZNF641",
    "gene-ZNF644",
    "gene-ZNF646",
    "gene-ZNF648",
    "gene-ZNF649",
    "gene-ZNF652",
    "gene-ZNF653",
    "gene-ZNF654",
    "gene-ZNF655",
    "gene-ZNF658",
    "gene-ZNF66",
    "gene-ZNF660",
    "gene-ZNF662",
    "gene-ZNF664",
    "gene-ZNF665",
    "gene-ZNF667",
    "gene-ZNF668",
    "gene-ZNF669",
    "gene-ZNF670",
    "gene-ZNF671",
    "gene-ZNF672",
    "gene-ZNF674",
    "gene-ZNF675",
    "gene-ZNF676",
    "gene-ZNF677",
    "gene-ZNF678",
    "gene-ZNF679",
    "gene-ZNF680",
    "gene-ZNF681",
    "gene-ZNF682",
    "gene-ZNF683",
    "gene-ZNF684",
    "gene-ZNF687",
    "gene-ZNF688",
    "gene-ZNF689",
    "gene-ZNF69",
    "gene-ZNF691",
    "gene-ZNF692",
    "gene-ZNF695",
    "gene-ZNF696",
    "gene-ZNF697",
    "gene-ZNF699",
    "gene-ZNF7",
    "gene-ZNF70",
    "gene-ZNF700",
    "gene-ZNF701",
    "gene-ZNF703",
    "gene-ZNF704",
    "gene-ZNF705A",
    "gene-ZNF705B",
    "gene-ZNF705D",
    "gene-ZNF705G",
    "gene-ZNF706",
    "gene-ZNF707",
    "gene-ZNF708",
    "gene-ZNF709",
    "gene-ZNF71",
    "gene-ZNF710",
    "gene-ZNF711",
    "gene-ZNF713",
    "gene-ZNF714",
    "gene-ZNF716",
    "gene-ZNF717",
    "gene-ZNF718",
    "gene-ZNF721",
    "gene-ZNF722",
    "gene-ZNF723",
    "gene-ZNF724",
    "gene-ZNF726",
    "gene-ZNF727",
    "gene-ZNF728",
    "gene-ZNF729",
    "gene-ZNF730",
    "gene-ZNF732",
    "gene-ZNF735",
    "gene-ZNF736",
    "gene-ZNF737",
    "gene-ZNF738",
    "gene-ZNF74",
    "gene-ZNF740",
    "gene-ZNF746",
    "gene-ZNF747",
    "gene-ZNF749",
    "gene-ZNF750",
    "gene-ZNF75A",
    "gene-ZNF75D",
    "gene-ZNF76",
    "gene-ZNF761",
    "gene-ZNF763",
    "gene-ZNF764",
    "gene-ZNF765",
    "gene-ZNF766",
    "gene-ZNF768",
    "gene-ZNF77",
    "gene-ZNF770",
    "gene-ZNF771",
    "gene-ZNF772",
    "gene-ZNF773",
    "gene-ZNF774",
    "gene-ZNF775",
    "gene-ZNF776",
    "gene-ZNF777",
    "gene-ZNF778",
    "gene-ZNF780A",
    "gene-ZNF780B",
    "gene-ZNF781",
    "gene-ZNF782",
    "gene-ZNF783",
    "gene-ZNF784",
    "gene-ZNF785",
    "gene-ZNF786",
    "gene-ZNF787",
    "gene-ZNF789",
    "gene-ZNF79",
    "gene-ZNF790",
    "gene-ZNF791",
    "gene-ZNF792",
    "gene-ZNF793",
    "gene-ZNF799",
    "gene-ZNF8",
    "gene-ZNF80",
    "gene-ZNF800",
    "gene-ZNF804A",
    "gene-ZNF804B",
    "gene-ZNF805",
    "gene-ZNF808",
    "gene-ZNF81",
    "gene-ZNF813",
    "gene-ZNF814",
    "gene-ZNF816",
    "gene-ZNF821",
    "gene-ZNF823",
    "gene-ZNF827",
    "gene-ZNF829",
    "gene-ZNF83",
    "gene-ZNF830",
    "gene-ZNF831",
    "gene-ZNF835",
    "gene-ZNF836",
    "gene-ZNF837",
    "gene-ZNF839",
    "gene-ZNF84",
    "gene-ZNF841",
    "gene-ZNF843",
    "gene-ZNF844",
    "gene-ZNF845",
    "gene-ZNF846",
    "gene-ZNF85",
    "gene-ZNF850",
    "gene-ZNF852",
    "gene-ZNF853",
    "gene-ZNF860",
    "gene-ZNF862",
    "gene-ZNF865",
    "gene-ZNF875",
    "gene-ZNF878",
    "gene-ZNF879",
    "gene-ZNF880",
    "gene-ZNF883",
    "gene-ZNF888",
    "gene-ZNF891",
    "gene-ZNF892",
    "gene-ZNF90",
    "gene-ZNF91",
    "gene-ZNF92",
    "gene-ZNF93",
    "gene-ZNF98",
    "gene-ZNF99",
    "gene-ZNFX1",
    "gene-ZNG1A",
    "gene-ZNG1B",
    "gene-ZNG1C",
    "gene-ZNG1E",
    "gene-ZNG1F",
    "gene-ZNHIT1",
    "gene-ZNHIT2",
    "gene-ZNHIT3",
    "gene-ZNHIT6",
    "gene-ZNRD2",
    "gene-ZNRF1",
    "gene-ZNRF2",
    "gene-ZNRF3",
    "gene-ZNRF4",
    "gene-ZP1",
    "gene-ZP2",
    "gene-ZP3",
    "gene-ZP4",
    "gene-ZPBP",
    "gene-ZPBP2",
    "gene-ZPLD1",
    "gene-ZPR1",
    "gene-ZRANB1",
    "gene-ZRANB2",
    "gene-ZRANB3",
    "gene-ZRSR2",
    "gene-ZSCAN1",
    "gene-ZSCAN10",
    "gene-ZSCAN12",
    "gene-ZSCAN16",
    "gene-ZSCAN18",
    "gene-ZSCAN2",
    "gene-ZSCAN20",
    "gene-ZSCAN21",
    "gene-ZSCAN22",
    "gene-ZSCAN23",
    "gene-ZSCAN25",
    "gene-ZSCAN26",
    "gene-ZSCAN29",
    "gene-ZSCAN30",
    "gene-ZSCAN31",
    "gene-ZSCAN32",
    "gene-ZSCAN4",
    "gene-ZSCAN5A",
    "gene-ZSCAN5B",
    "gene-ZSCAN5C",
    "gene-ZSCAN9",
    "gene-ZSWIM1",
    "gene-ZSWIM2",
    "gene-ZSWIM3",
    "gene-ZSWIM4",
    "gene-ZSWIM5",
    "gene-ZSWIM6",
    "gene-ZSWIM7",
    "gene-ZSWIM8",
    "gene-ZSWIM9",
    "gene-ZUP1",
    "gene-ZW10",
    "gene-ZWILCH",
    "gene-ZWINT",
    "gene-ZXDA",
    "gene-ZXDB",
    "gene-ZXDC",
    "gene-ZYG11A",
    "gene-ZYG11B",
    "gene-ZYX",
    "gene-ZZEF1",
    "gene-ZZZ3"
  ]