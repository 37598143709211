import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchArtifactData, addFileTab } from "../store/project.js";
import { handleDiffChanges } from "../store/project.js";
import "../style/SingleFileChangePopUp.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { updateFilesOpen } from "../api/files_api.js";

function SingleFileChangePopUp() {
  const dispatch = useDispatch();
  const currentProject = useSelector((state) => state.project.currentProject);
  const artifact = useSelector((state) => state.project.artifact);
  const openFiles = useSelector((state) => state.project.openFiles);
  const userId = useSelector((state) => state.user.user_id);
  const token = useSelector((state) => state.user.access_token);

  if (!currentProject?.inEditState || !artifact) return null;

  // Get all files with edits
  const filesWithEdits = Object.entries(currentProject.files || {})
    .filter(([_, file]) => file.s3_url?.includes("_edit."))
    .map(([fileId, file]) => ({
      ...file,
      file_id: fileId,
      labkick_id: fileId,
    }))
    .sort((a, b) => a.file_name.localeCompare(b.file_name));

  if (filesWithEdits.length === 0) return null;

  // Find current file index
  const currentIndex = filesWithEdits.findIndex(
    (file) => file.file_id === artifact.file_id
  );
  const isCurrentFileEdited =
    currentProject.files[artifact.file_id]?.s3_url?.includes("_edit.");

  const handleFileClick = (fileId) => {
    const file = currentProject.files?.[fileId];
    if (!file) {
      console.error("File not found:", fileId);
      return;
    }

    dispatch(
      addFileTab({
        fileId,
        fileName: file.file_name,
        fileType: file.file_type,
        content: null,
        setActive: true,
      })
    );

    if (currentProject.project_id && userId && token) {
      const updatedFileIds = [...Object.keys(openFiles), fileId];
      updateFilesOpen(
        currentProject.project_id,
        updatedFileIds,
        userId,
        token
      ).catch((error) => console.error("Failed to update files open:", error));
    }

    dispatch(
      fetchArtifactData({
        fileId,
        fileType: file.file_type,
      })
    );
  };

  const handleAcceptReject = (action) => {
    dispatch(
      handleDiffChanges({
        projectId: currentProject.project_id,
        action: action === "accept" ? "accept" : "reject",
        fileIds: [artifact.file_id],
      })
    ).then(() => {
      dispatch(
        fetchArtifactData({
          fileId: artifact.file_id,
          fileType: currentProject.files[artifact.file_id].file_type,
        })
      );
    });
  };

  // Determine which files to show in navigation
  const prevFile =
    currentIndex > 0
      ? filesWithEdits[currentIndex - 1]
      : filesWithEdits[filesWithEdits.length - 1];
  const nextFile =
    currentIndex < filesWithEdits.length - 1
      ? filesWithEdits[currentIndex + 1]
      : filesWithEdits[0];

  if (isCurrentFileEdited) {
    return (
      <div className="singlefile-popup">
        {filesWithEdits.length > 1 && (
          <button
            className="singlefile-nav-button left"
            onClick={() => handleFileClick(prevFile.file_id)}
          >
            <FaChevronLeft />
          </button>
        )}

        <div className="singlefile-content">
          <button
            className="singlefile-button reject"
            onClick={() => handleAcceptReject("reject")}
          >
            reject
          </button>
          <button
            className="singlefile-button accept"
            onClick={() => handleAcceptReject("accept")}
          >
            accept
          </button>
        </div>

        {filesWithEdits.length > 1 && (
          <button
            className="singlefile-nav-button right"
            onClick={() => handleFileClick(nextFile.file_id)}
          >
            <FaChevronRight />
          </button>
        )}
      </div>
    );
  }

  // Show at most two files when not on an edited file
  const filesToShow =
    filesWithEdits.length > 1
      ? [filesWithEdits[0], filesWithEdits[filesWithEdits.length - 1]]
      : [filesWithEdits[0]];

  return (
    <div className="singlefile-popup">
      <div className="singlefile-content">
        {filesToShow.map((file) => (
          <div
            key={file.file_id}
            className="singlefile-filename clickable"
            onClick={() => handleFileClick(file.file_id)}
          >
            {file.file_name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SingleFileChangePopUp;
