// notebookThunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { updateUserFile } from "../user.js";
import { convertFilesOpenToOpenFiles } from "../project.js";

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (userId, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const response = await fetchProjectList(userId, token);

    // Get current user files
    const currentUserFiles = getState().user.files || {};

    // Collect all non-Output files from all projects
    const newFiles = {};
    response.projects.forEach((project) => {
      if (project.files) {
        Object.entries(project.files).forEach(([fileId, fileData]) => {
          if (fileData.file_type !== "Output" && !currentUserFiles[fileId]) {
            newFiles[fileId] = fileData;
          }
        });
      }
    });

    // Combine current files with new files
    const combinedFiles = {
      ...currentUserFiles,
      ...newFiles,
    };

    // Update user's files only if there are new files
    if (Object.keys(newFiles).length > 0) {
      dispatch(
        updateUserFile({
          replaceAll: true,
          updatedFile: combinedFiles,
        })
      );
    }

    return {
      projects: response.projects,
      nextToken: response.nextToken,
    };
  }
);

export const fetchProjectsReducer = {
  [fetchProjects.pending]: (state) => {
    state.error = null;
    state.status = "loading";
  },
  [fetchProjects.fulfilled]: (state, action) => {
    state.status = "succeeded";
    state.projectList = action.payload.projects;
    state.projectsNextToken = action.payload.nextToken;
    state.isLoading = false;
    if (state.projectList.length > 0) {
      const firstProject = state.projectList[0];
      state.currentProjectId = firstProject.project_id;
      state.currentProject = firstProject;
      state.openFiles = convertFilesOpenToOpenFiles(
        firstProject.files_open,
        firstProject.files
      );
      const openFileIds = Object.keys(state.openFiles);
      state.activeFileId = openFileIds.length > 0 ? openFileIds[0] : null;
    } else {
      state.currentProjectId = null;
      state.currentProject = null;
      state.openFiles = {};
      state.activeFileId = null;
    }
  },
  [fetchProjects.rejected]: (state, action) => {
    state.error = action.error.message;
    state.status = "failed";
  },
};

export const fetchProjectList = async (userId, token) => {
  try {
    console.log("fetchProjectList called with:", { userId });
    const url = `${API_URL}/api/project-list`;
    const payload = { userId: userId };
    const response = await fetchWithToken(
      url,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      },
      token
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === "success") {
      const projectsData = JSON.parse(data.data);
      console.log("projectsData", projectsData);
      return {
        projects: projectsData.projects.map((project) => project),
        nextToken: projectsData.projects_next_token,
      };
    } else {
      throw new Error(data.message || "Server returned an error");
    }
  } catch (error) {
    console.error("Error in fetchProjectList:", error.message);
    throw error;
  }
};
