import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const runPythonCode = createAsyncThunk(
  "projects/runPythonCode",
  async ({ fileId, projectId }, { getState }) => {
    const token = getState().user.access_token;

    // Save the chat-related fields before making the API call
    const currentProject = getState().project.currentProject;
    const chatFields = {
      agent_chat_files: currentProject.agent_chat_files,
      agent_chat_next_token: currentProject.agent_chat_next_token,
      chat_chat_files: currentProject.chat_chat_files,
      chat_chat_next_token: currentProject.chat_chat_next_token,
      popup_chat_files: currentProject.popup_chat_files,
    };

    try {
      const response = await runPythonCodeRequest(token, fileId, projectId);

      // If new files were created, merge chat fields with the response
      if (response.new_files_created && response.project) {
        response.project = {
          ...response.project,
          ...chatFields,
        };
      }

      return { result: response };
    } catch (error) {
      throw error;
    }
  }
);

export const runPythonCodeReducer = {
  [runPythonCode.pending]: (state) => {
    state.error = null;
    state.isRunningPython = true;
  },
  [runPythonCode.fulfilled]: (state, action) => {
    const { result } = action.payload;
    console.log("Python execution result:", result);

    if (result) {
      // Combine stdout with any plots that were generated
      const plotsHtml =
        result.plots
          ?.map(
            (plotData) =>
              `<img src="data:image/png;base64,${plotData}" alt="Python plot" class="py-output-image" />`
          )
          .join("\n") || "";

      state.pythonOutput = {
        stdout: (result.stdout || "") + plotsHtml,
        error: result.error || "",
      };

      console.log("Python output set to:", state.pythonOutput);

      // Check if new files were created and update project if needed
      if (result.new_files_created && result.project) {
        console.log("New files created:", result.project);
        // Calculate inEditState before updating
        const updatedProject = result.project;
        updatedProject.inEditState = Object.values(
          updatedProject.files || {}
        ).some((file) => file.s3_url && file.s3_url.includes("_edit."));

        // Update project in projectList
        state.projectList = state.projectList.map((project) =>
          project.project_id === updatedProject.project_id
            ? updatedProject
            : project
        );

        // Update currentProject if it matches
        if (state.currentProjectId === updatedProject.project_id) {
          state.currentProject = updatedProject;
        }
      }
    }
    state.isRunningPython = false;
  },
  [runPythonCode.rejected]: (state, action) => {
    console.error("Python execution failed:", action.error);
    state.error = action.error.message;
    state.isRunningPython = false;
  },
};

const runPythonCodeRequest = async (token, fileId, projectId) => {
  const endpoint = `${API_URL}/api/python/run`;

  const response = await fetchWithToken(
    endpoint,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        file_id: fileId,
        project_id: projectId,
      }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to run Python code");
  }

  const result = await response.json();
  return result.status === "success"
    ? result.data
    : Promise.reject(result.message);
};
