import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";

export const fetchGroups = createAsyncThunk(
  "user/fetchGroups",
  async (_, { getState }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;
    try {
      const groups = await fetchUsageGroups(userId, token);
      console.log("fetchGroups groups", groups);
      return groups;
    } catch (error) {
      console.error("Error fetching groups:", error);
      throw error;
    }
  }
);

export const addMemberToGroup = createAsyncThunk(
  "user/addMemberToGroup",
  async ({ groupId, email }, { getState }) => {
    const state = getState();
    const token = state.user.access_token;
    const ownerId = state.user.user_id;
    const updatedGroups = await addGroupMember(groupId, email, ownerId, token);
    console.log("addMemberToGroup result:", updatedGroups);
    return updatedGroups;
  }
);

export const deleteGroupThunk = createAsyncThunk(
  "user/deleteGroup",
  async (groupId, { getState }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await deleteGroup(groupId, userId, token);
    console.log("deleteGroupThunk result:", updatedGroups);
    return updatedGroups;
  }
);

export const removeGroupMemberThunk = createAsyncThunk(
  "user/removeGroupMember",
  async ({ groupId, userEmail }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await removeGroupMember(
      groupId,
      ownerId,
      userEmail,
      token
    );
    console.log("removeGroupMemberThunk result:", updatedGroups);
    return updatedGroups; // Return the entire groups object
  }
);

export const changeGroupOwnerThunk = createAsyncThunk(
  "user/changeGroupOwner",
  async ({ groupId, newOwnerEmail }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await changeGroupOwner(
      groupId,
      ownerId,
      newOwnerEmail,
      token
    );
    console.log("changeGroupOwnerThunk result:", updatedGroups);
    return updatedGroups;
  }
);

export const transferCreditsToGroupThunk = createAsyncThunk(
  "user/transferCreditsToGroup",
  async ({ groupId, amount }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await transferCreditsToGroup(
      groupId,
      ownerId,
      amount,
      token
    );
    console.log("transferCreditsToGroupThunk result:", updatedGroups);
    return updatedGroups;
  }
);

export const transferCreditsFromGroupThunk = createAsyncThunk(
  "user/transferCreditsFromGroup",
  async ({ groupId, amount }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await transferCreditsFromGroup(
      groupId,
      ownerId,
      amount,
      token
    );
    console.log("transferCreditsFromGroupThunk result:", updatedGroups);
    return updatedGroups;
  }
);

export const renameGroupThunk = createAsyncThunk(
  "user/renameGroup",
  async ({ groupId, newName }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await renameGroup(groupId, ownerId, newName, token);
    console.log("renameGroupThunk result:", updatedGroups);
    return updatedGroups;
  }
);

export const leaveGroupThunk = createAsyncThunk(
  "user/leaveGroup",
  async ({ groupId, userEmail }, { getState }) => {
    const state = getState();
    const token = state.user.access_token;
    const updatedGroups = await leaveGroup(groupId, userEmail, token);
    console.log("leaveGroupThunk result:", updatedGroups);
    return updatedGroups;
  }
);

export const fetchGroupsReducer = {
  [fetchGroups.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const addMemberToGroupReducer = {
  [addMemberToGroup.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const deleteGroupThunkReducer = {
  [deleteGroupThunk.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const removeGroupMemberThunkReducer = {
  [removeGroupMemberThunk.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const changeGroupOwnerThunkReducer = {
  [changeGroupOwnerThunk.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const transferCreditsToGroupThunkReducer = {
  [transferCreditsToGroupThunk.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const transferCreditsFromGroupThunkReducer = {
  [transferCreditsFromGroupThunk.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const renameGroupThunkReducer = {
  [renameGroupThunk.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const leaveGroupThunkReducer = {
  [leaveGroupThunk.fulfilled]: (state, action) => {
    state.groups = action.payload;
  },
};

export const fetchUsageGroups = async (userId, token) => {
  console.log("fetchUsageGroups called with:", { userId });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/read-usage-groups`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to fetch usage groups");
    }

    const result = await response.json();
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in fetchUsageGroups:", error);
    throw error;
  }
};

export const createGroup = async (groupName, userId, token) => {
  console.log("createGroup called with:", { groupName, userId });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/create-group`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: groupName,
          user_id: userId,
        }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to create group");
    }

    const result = await response.json();
    console.log("createGroup result", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in createGroup:", error);
    throw error;
  }
};

export const addGroupMember = async (groupId, email, ownerId, token) => {
  console.log("addGroupMember API called with:", { groupId, email, ownerId });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/add-user-to-group`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          group_id: groupId,
          owner_id: ownerId,
          user_email: email,
        }),
      },
      token
    );

    if (!response.ok)
      throw new Error(
        "Failed to add group member. Please ensure the user has already signed up with the provided email address."
      );
    const result = await response.json();
    console.log("addGroupMember API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in addGroupMember:", error);
    throw error;
  }
};

export const deleteGroup = async (groupId, userId, token) => {
  console.log("deleteGroup API called with:", { groupId, userId });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/delete-group`,
      {
        method: "POST",
        body: JSON.stringify({ group_id: groupId, user_id: userId }),
      },
      token
    );

    if (!response.ok) throw new Error("Failed to delete group");
    const result = await response.json();
    console.log("deleteGroup API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in deleteGroup:", error);
    throw error;
  }
};

export const removeGroupMember = async (groupId, ownerId, userEmail, token) => {
  console.log("removeGroupMember API called with:", {
    groupId,
    ownerId,
    userEmail,
  });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/remove-user-from-group`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          group_id: groupId,
          owner_id: ownerId,
          user_email: userEmail,
        }),
      },
      token
    );

    if (!response.ok) throw new Error("Failed to remove group member");
    const result = await response.json();
    console.log("removeGroupMember API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in removeGroupMember:", error);
    throw error;
  }
};

export const changeGroupOwner = async (
  groupId,
  ownerId,
  newOwnerEmail,
  token
) => {
  console.log("changeGroupOwner API called with:", {
    groupId,
    ownerId,
    newOwnerEmail,
  });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/change-group-ownership`,
      {
        method: "POST",
        body: JSON.stringify({
          group_id: groupId,
          owner_id: ownerId,
          new_owner_email: newOwnerEmail,
        }),
      },
      token
    );

    if (!response.ok) throw new Error("Failed to change group owner");
    const result = await response.json();
    console.log("changeGroupOwner API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in changeGroupOwner:", error);
    throw error;
  }
};

export const transferCreditsToGroup = async (
  groupId,
  ownerId,
  amount,
  token
) => {
  console.log("transferCreditsToGroup API called with:", {
    groupId,
    ownerId,
    amount,
  });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/transfer-credits-to-group`,
      {
        method: "POST",
        body: JSON.stringify({
          group_id: groupId,
          owner_id: ownerId,
          amount: amount,
        }),
      },
      token
    );

    if (!response.ok) throw new Error("Failed to transfer credits to group");
    const result = await response.json();
    console.log("transferCreditsToGroup API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in transferCreditsToGroup:", error);
    throw error;
  }
};

export const transferCreditsFromGroup = async (
  groupId,
  ownerId,
  amount,
  token
) => {
  console.log("transferCreditsFromGroup API called with:", {
    groupId,
    ownerId,
    amount,
  });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/transfer-credits-from-group`,
      {
        method: "POST",
        body: JSON.stringify({
          group_id: groupId,
          owner_id: ownerId,
          amount: amount,
        }),
      },
      token
    );

    if (!response.ok) throw new Error("Failed to transfer credits from group");
    const result = await response.json();
    console.log("transferCreditsFromGroup API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in transferCreditsFromGroup:", error);
    throw error;
  }
};

export const renameGroup = async (groupId, ownerId, newName, token) => {
  console.log("renameGroup API called with:", { groupId, ownerId, newName });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/rename-group`,
      {
        method: "POST",
        body: JSON.stringify({
          group_id: groupId,
          owner_id: ownerId,
          new_name: newName,
        }),
      },
      token
    );

    if (!response.ok) throw new Error("Failed to rename group");
    const result = await response.json();
    console.log("renameGroup API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in renameGroup:", error);
    throw error;
  }
};

export const leaveGroup = async (groupId, userEmail, token) => {
  console.log("leaveGroup API called with:", { groupId, userEmail });
  try {
    const response = await fetchWithToken(
      `${API_URL}/api/remove-user-from-group`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          group_id: groupId,
          owner_id: null, // Not needed when user is leaving
          user_email: userEmail,
        }),
      },
      token
    );

    if (!response.ok) throw new Error("Failed to leave group");
    const result = await response.json();
    console.log("leaveGroup API response:", result);
    return result.status === "success"
      ? result.data
      : Promise.reject(result.message);
  } catch (error) {
    console.error("Error in leaveGroup:", error);
    throw error;
  }
};
