import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "./Sidebar.js";
import ChatPanel from "./ChatPanel.js";
import ArtifactPanel from "./ArtifactPanel.js";
import FileBar from "./FileBar.js";
import { useSelector, useDispatch } from "react-redux";
import { populateSampleData, setPopupMessage } from "../store/user.js";
import {
  updateArtifact,
  updateModal,
  setIsChatLoading,
  updateChat,
  setActiveChat,
  resetChat,
} from "../store/project.js";
import CreateProjectModal from "./CreateProjectModal.js";
import RenameProjectModal from "./RenameProjectModal.js";
import DeleteProjectModal from "./DeleteProjectModal.js";
import FileUploadModal from "./FileUploadModal.js";
import DeleteFileFromProjectModal from "./DeleteFileFromProjectModal.js";
import EditStateModal from "./EditStateModal.js";
import { BiHelpCircle } from "react-icons/bi";
import OrderDNA from "./OrderDNA.js";
import UserFileModal from "./UserFileModal.js";

function ProtocolPage() {
  const dispatch = useDispatch();
  const { artifact, currentProjectId, modal, projectList } = useSelector(
    (state) => state.project
  );
  const isSidebarVisible = useSelector(
    (state) => state.project.isSidebarVisible
  );
  const isChatPanelOpen = useSelector((state) => state.project.isChatPanelOpen);
  const token = useSelector((state) => state.user.access_token);
  const userId = useSelector((state) => state.user.user_id);
  const userStatus = useSelector((state) => state.user.status);
  const isCheckingAuth = useSelector((state) => state.user.isCheckingAuth);
  const isAuthenticated = useSelector(
    (state) => state.user.status === "succeeded"
  );

  // State
  const [articleId, setArticleId] = useState(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [chatWidth, setChatWidth] = useState(400); // Initial chat width
  const [startX, setStartX] = useState(0);
  const [startWidth, setStartWidth] = useState(0);
  const [isHelpMenuOpen, setIsHelpMenuOpen] = useState(false);

  const activeChat = useSelector((state) => state.project.activeChat || "chat");

  // Handlers
  const handleResizeStart = useCallback(
    (e) => {
      e.preventDefault();
      setIsDragging(true);
      setStartX(e.clientX);
      setStartWidth(chatWidth);
      document.body.style.cursor = "col-resize";
    },
    [chatWidth]
  );

  const handleResizeEnd = useCallback(() => {
    setIsDragging(false);
    document.body.style.cursor = "default";
  }, []);

  const handleResize = useCallback(
    (e) => {
      if (isDragging) {
        const mainContentWidth =
          document.querySelector(".page-layout").offsetWidth;
        const deltaX = startX - e.clientX;
        const newWidth = Math.min(
          Math.max(300, startWidth + deltaX), // Min width 300px
          mainContentWidth * 0.8 // Max width 80% of main content
        );
        setChatWidth(newWidth);
      }
    },
    [isDragging, startX, startWidth]
  );

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleResize);
      window.addEventListener("mouseup", handleResizeEnd);
      document.body.style.userSelect = "none";
    }
    return () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("mouseup", handleResizeEnd);
      document.body.style.userSelect = "";
      document.body.style.cursor = "default";
    };
  }, [isDragging, handleResize, handleResizeEnd]);

  const handleSampleClick = (sampleNumber) => {
    dispatch(populateSampleData({ userId, sampleNumber }));
  };

  const removeQueryParams = () => {
    const url = new URL(window.location);
    url.searchParams.delete("success");
    url.searchParams.delete("canceled");
    window.history.replaceState({}, document.title, url.pathname);
  };

  // Article ID handling
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const articleParam = Object.keys(Object.fromEntries(searchParams)).find(
      (key) => key.startsWith("article_")
    );

    if (articleParam) {
      const id = articleParam.split("_")[1];
      localStorage.setItem("pendingArticleId", id);
      setArticleId(id);
    } else {
      const pendingId = localStorage.getItem("pendingArticleId");
      if (pendingId) {
        setArticleId(pendingId);
      }
    }
  }, []);

  useEffect(() => {
    if (articleId && token) {
      dispatch(populateSampleData({ userId, sampleNumber: 1 }));
      localStorage.removeItem("pendingArticleId");
      if (userId) {
        setArticleId(null);
      }
    }
  }, [articleId, token, userId, dispatch]);

  // Payment handling
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("success") === "true") {
      dispatch(
        setPopupMessage(
          "Payment update successful. You will receive an email confirmation."
        )
      );
      removeQueryParams();
    } else if (searchParams.get("canceled") === "true") {
      dispatch(
        setPopupMessage("Payment failed or was canceled. Please try again.")
      );
      removeQueryParams();
    }
  }, [dispatch]);

  // Initial loading state
  useEffect(() => {
    if (!isCheckingAuth) {
      if (userStatus === "succeeded") {
        setIsInitialLoading(false);
      }
    } else if (userStatus === "unauthenticated") {
      setIsInitialLoading(false);
    }
  }, [isCheckingAuth, userStatus]);

  // Keyboard handling
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Escape") {
        // Close help menu if open
        setIsHelpMenuOpen(false);

        // Close modal if open
        if (modal.name) {
          dispatch(updateModal({ name: null, data: null }));
        }
      } else if (e.metaKey || e.ctrlKey) {
        // Handle cmd/ctrl key combinations
        switch (e.key.toLowerCase()) {
          case "i":
            e.preventDefault();
            dispatch(setActiveChat("agent"));
            // Focus chat input after changing active chat
            setTimeout(() => {
              const chatInput = document.querySelector(".chatinput-input");
              if (chatInput) {
                chatInput.focus();
              }
            }, 0);
            break;
          case "l":
            e.preventDefault();
            dispatch(setActiveChat("chat"));
            // Focus chat input after changing active chat
            setTimeout(() => {
              const chatInput = document.querySelector(".chatinput-input");
              if (chatInput) {
                chatInput.focus();
              }
            }, 0);
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);
    return () => document.removeEventListener("keydown", handleKeyPress);
  }, [dispatch, modal.name, currentProjectId]);

  // Modify the chat loading effect
  useEffect(() => {
    if (currentProjectId && !isInitialLoading) {
      dispatch(setIsChatLoading(true));

      // Get the current project from projectList
      const project = projectList.find(
        (p) => p.project_id === currentProjectId
      );
      if (project) {
        // Get the chat files for the current active chat type
        const chatFileType = `${activeChat}_chat_files`;
        const chatFiles = project[chatFileType] || {};
        const latestChat = Object.values(chatFiles).sort(
          (a, b) => new Date(b.last_opened_date) - new Date(a.last_opened_date)
        )[0];

        console.log("latestChat", latestChat);

        // Reset chat if either:
        // 1. The chat files object exists but has no latest chat, or
        // 2. The chat files object is empty/null
        if (!latestChat || !Object.keys(chatFiles).length) {
          dispatch(resetChat(currentProjectId));
        }
      }
      dispatch(setIsChatLoading(false));
    }
  }, [currentProjectId, isInitialLoading, activeChat, dispatch, projectList]);

  useEffect(() => {
    if (!currentProjectId) return;

    // Find the project that just became the currentProject
    const project = projectList.find((p) => p.project_id === currentProjectId);
    console.log("NEW project", project);
  }, [currentProjectId, projectList]);

  const handleOpenDNA = () => {
    dispatch(updateModal({ name: "dna_archive_general", data: null }));
  };

  return (
    <div
      className={`container ${isSidebarVisible ? "" : "sidebar-collapsed"}`}
      role="main"
    >
      <Sidebar />
      {isInitialLoading && (
        <div className="initial-loading-overlay" role="alert" aria-busy="true">
          <div className="loading-spinner" aria-label="Loading content"></div>
        </div>
      )}
      <div className="page-layout">
        <div className="artifact-main-container">
          <FileBar />
          <ArtifactPanel />
        </div>
        <div
          className="vertical-resize-handle"
          onMouseDown={handleResizeStart}
          role="separator"
          aria-label="Resize chat panel"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleResizeStart(e);
            }
          }}
        />
        <div
          className="chat-panel"
          style={{ width: isChatPanelOpen ? `${chatWidth}px` : "32px" }}
        >
          {!isInitialLoading && (
            <>
              {isAuthenticated ? (
                projectList.length === 0 ? (
                  <div className="chat-empty-state">
                    <div className="chat-empty-content">
                      <p>Create a project to start chatting</p>
                    </div>
                  </div>
                ) : (
                  <ChatPanel />
                )
              ) : (
                // Show empty div when not authenticated
                <div className="chat-empty-state"></div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Modals */}
      {modal.name === "create_project" && <CreateProjectModal />}
      {modal.name === "rename_project" && <RenameProjectModal />}
      {modal.name === "delete_project" && <DeleteProjectModal />}
      {(modal.name === "upload_general" || modal.name === "upload_project") && (
        <FileUploadModal />
      )}
      {modal.name === "delete_file" && (
        <DeleteFileFromProjectModal isOpen={true} />
      )}
      {modal.name === "edit_state" && (
        <EditStateModal onComplete={modal.data} />
      )}
      {modal.name === "order_dna" && <OrderDNA />}
      {modal.name === "user_file" && <UserFileModal />}
    </div>
  );
}

export default ProtocolPage;
