import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithToken, API_URL } from "../../api/api.js";
import { fetchProjects } from "../project.js";

export const populateSampleData = createAsyncThunk(
  "user/populateSampleData",
  async ({ userId, sampleNumber }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    console.log("populateSampleData userId", userId);
    await addSampleProject(userId, sampleNumber, token);
    const user = await getUserInfo(userId, token);
    console.log("user after populateSampleData", user);

    // Dispatch fetchProjects to update the project list
    dispatch(fetchProjects(userId));

    return user;
  }
);

export const populateSampleDataReducer = {
  [populateSampleData.fulfilled]: (state, action) => {
    state.status = "succeeded";
    state.data = action.payload;
    if (action.payload.files) {
      state.files = action.payload.files;
    }
    console.log(`Sample data populated`);
  },
  [populateSampleData.rejected]: (state, action) => {
    state.status = "failed";
    state.error = action.error.message;
  },
};

export const getUserInfo = async (user_id, token) => {
  try {
    console.log("getUserInfo called with:", { user_id, token });
    const response = await fetchWithToken(
      `${API_URL}/api/user-info`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: user_id }),
      },
      token
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user info");
    }

    const data = await response.json();
    return data.status === "success" ? data.data : Promise.reject(data.message);
  } catch (error) {
    console.error("Error in getUserInfo:", error.message);
    throw error;
  }
};

export const addSampleProject = async (userId, sampleNumber, token) => {
  console.log("addSampleProject called with:", { sampleNumber, userId, token });
  const response = await fetchWithToken(
    `${API_URL}/api/add-sample-project`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: userId, sample_number: sampleNumber }),
    },
    token
  );

  if (!response.ok) {
    throw new Error("Failed to add sample project");
  }

  const data = await response.json();
  return data.status === "success" ? data.data : Promise.reject(data.message);
};
