export const protocol_list = {
  digest_mixed: {
    Type: "digest",
    Name: "Digest Mixed",
    Link: "https://www.example2.com",
    Extension_Multiple: "60",
  },
  neb_digest_fast: {
    Type: "digest",
    Name: "NEB Digest Fast",
    Link: "https://www.example2.com",
    Extension_Multiple: "60",
  },
  neb_digest_slow: {
    Type: "digest",
    Name: "NEB Digest Slow",
    Link: "https://www.example2.com",
    Extension_Multiple: "60",
  },
  thermo_digest_fast: {
    Type: "digest",
    Name: "Thermo Digest Fast",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  thermo_digest_slow: {
    Type: "digest",
    Name: "Thermo Digest Slow",
    Link: "https://www.example2.com",
    Extension_Multiple: "60",
  },
  geneart_seamless_plus_thermo: {
    Type: "gibson",
    Name: "GeneArt Seamless PLUS (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0006685_Rev3_UG_17May2013.pdf",
    Extension_Multiple: "60",
  },
  geneart_seamless_thermo: {
    Type: "gibson",
    Name: "GeneArt Seamless (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0006910_100009437_Rev02_PI_22May2013.pdf",
    Extension_Multiple: "60",
  },
  geneart_gibson_hifi_thermo: {
    Type: "gibson",
    Name: "GeneArt Gibson Assembly HiFi (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0019062_GeneArtGibsonHiFi_UG.pdf",
    Extension_Multiple: "60",
  },
  infusion_snap_takara: {
    Type: "gibson",
    Name: "In-Fusion Snap Assembly Multiple-Insert (Takara)",
    Link: "https://www.takarabio.com/documents/User%20Manual/In/In-Fusion%20Snap%20Assembly%20Multiple-Insert%20Cloning%20Protocol-At-A-Glance.pdf",
    Extension_Multiple: "60",
  },
  gibson_master_mix_neb: {
    Type: "gibson",
    Name: "Gibson Assembly Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/25/gibson-assembly-master-mix-assembly",
    Extension_Multiple: "60",
  },
  nebuilder_hifi_neb: {
    Type: "gibson",
    Name: "NEBuilder HiFi (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2014/11/26/nebuilder-hifi-dna-assembly-reaction-protocol",
    Extension_Multiple: "60",
  },
  geneart_type_2s_thermo: {
    Type: "golden_gate",
    Name: "GeneArt Type IIs (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/geneart_typeIIs_enzyme_mix_pps.pdf",
    Extension_Multiple: "60",
  },
  nebridge_ligase_master_mix_neb: {
    Type: "golden_gate",
    Name: "NEBridge Ligase Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2021/09/14/protocol-for-nebridge-ligase-master-mix-neb-m1100",
    Extension_Multiple: "60",
  },
  nebridge_bsmbiv2_neb: {
    Type: "golden_gate",
    Name: "NEBridge Golden Gate with BsmBI-v2 (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2020/01/15/golden-gate-assembly-protocol-for-using-neb-golden-gate-assembly-kit-bsmbi-v2-neb-e1602",
    Extension_Multiple: "60",
  },
  nebridge_bsaihfv2_neb: {
    Type: "golden_gate",
    Name: "NEBridge Golden Gate with BsaI-HFv2 (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2018/10/02/golden-gate-assembly-protocol-for-using-neb-golden-gate-assembly-mix-e1601",
    Extension_Multiple: "60",
  },
  accuprime_pfx_super_mix_thermo: {
    Type: "pcr",
    Name: "AccuPrime PFX SuperMix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/accuprimepfxsupermix_man.pdf",
    Extension_Multiple: "60",
  },
  accuprime_pfx_thermo: {
    Type: "pcr",
    Name: "AccuPrime PFX DNA Polymerase (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/accuprimepfx_man.pdf",
    Extension_Multiple: "60",
  },
  amplitaq_gold_360_thermo: {
    Type: "pcr",
    Name: "AmpliTaq Gold 360 (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/cms_061443.pdf",
    Extension_Multiple: "60",
  },
  amplitaq_gold_ld_thermo: {
    Type: "pcr",
    Name: "AmpliTaq Gold, LD (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/cms_041347.pdf",
    Extension_Multiple: "60",
  },
  amplitaq_gold_master_mix_thermo: {
    Type: "pcr",
    Name: "AmpliTaq Gold 360 Master Mix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/cms_061445.pdf",
    Extension_Multiple: "60",
  },
  amplitaq_gold_thermo: {
    Type: "pcr",
    Name: "AmpliTaq Gold (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/amplitaq_gold_dna_polymerase_man.pdf",
    Extension_Multiple: "60",
  },
  amplitaq_thermo: {
    Type: "pcr",
    Name: "AmpliTaq (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/amplitaq_dna_polymerase_man.pdf",
    Extension_Multiple: "60",
  },
  dreamtaq_2x_master_mix_thermo: {
    Type: "pcr",
    Name: "DreamTaq 2X Master Mix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0012702_DreamTaq_K1071_UG.pdf",
    Extension_Multiple: "60",
  },
  dreamtaq_thermo: {
    Type: "pcr",
    Name: "DreamTaq (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0012036_DreamTaqDNAPolymerase_500U_UG.pdf",
    Extension_Multiple: "60",
  },
  hot_start_taq_2x_master_mix_neb: {
    Type: "pcr",
    Name: "Hot Start Taq 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/27/protocol-for-a-pcr-reaction-using-hot-start-taq-2x-master-mix-m0496",
    Extension_Multiple: "60",
  },
  hot_start_taq_neb: {
    Type: "pcr",
    Name: "Hot Start Taq (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/10/04/pcr-using-hot-start-taq-dna-polymerase-m0495",
    Extension_Multiple: "60",
  },
  long_amp_hot_start_taq_2x_master_mix_neb: {
    Type: "pcr",
    Name: "LongAmp™ Hot Start Taq 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/10/17/protocol-for-longamp-hot-start-taq-2x-master-mix-m0533",
    Extension_Multiple: "50",
  },
  long_amp_hot_start_taq_neb: {
    Type: "pcr",
    Name: "LongAmp™ Hot Start Taq (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/05/30/protocol-for-longamp-hot-start-taq-2x-master-mix-m0533",
    Extension_Multiple: "50",
  },
  long_amp_taq_2x_master_mix_neb: {
    Type: "pcr",
    Name: "LongAmp® Taq 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/05/protocol-for-longamp-taq-2x-master-mix-m0287",
    Extension_Multiple: "50",
  },
  long_amp_taq_neb: {
    Type: "pcr",
    Name: "LongAmp® Taq (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/10/15/m0323-longamp-taq-dna-polymerase-protocol",
    Extension_Multiple: "50",
  },
  onetaq_2x_master_mix_neb: {
    Type: "pcr",
    Name: "OneTaq® 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/06/protocol-for-onetaq-2x-master-mix-with-standard-buffer-m0482",
    Extension_Multiple: "60",
  },
  onetaq_hot_start_2x_master_mix_neb: {
    Type: "pcr",
    Name: "OneTaq Hot Start 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/10/onetaq-hot-start-2x-master-mix-with-standard-buffer-m0484",
    Extension_Multiple: "60",
  },
  onetaq_hot_start_neb: {
    Type: "pcr",
    Name: "OneTaq Hot Start (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/05/one-taq-hot-start-dna-polymerase-m0481",
    Extension_Multiple: "60",
  },
  onetaq_hot_start_quick_load_2x_master_mix_neb: {
    Type: "pcr",
    Name: "OneTaq® Hot Start Quick-Load® 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/0001/01/01/onetaq-hot-start-2x-master-mix-with-standard-buffer-m0488",
    Extension_Multiple: "60",
  },
  onetaq_neb: {
    Type: "pcr",
    Name: "OneTaq® (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/10/11/onetaqdnapolymerasem0480",
    Extension_Multiple: "60",
  },
  onetaq_quick_load_2x_master_mix_neb: {
    Type: "pcr",
    Name: "OneTaq® Quick-Load 2X Master Mix with Standard Buffer (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/11/protocol-for-onetaq-quick-load-2x-master-mix-with-standard-buffer-m0486",
    Extension_Multiple: "60",
  },
  onetaq_quick_load_neb: {
    Type: "pcr",
    Name: "OneTaq® Quick-Load® (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2018/08/28/pcr-protocol-for-onetaq-quick-load-dna-polymerase-m0509",
    Extension_Multiple: "60",
  },
  phusion_high_fidelity_master_mix_neb: {
    Type: "pcr",
    Name: "Phusion® High-Fidelity Master Mix With HF Buffer (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/06/protocol-phusion-high-fidelity-pcr-master-mix-with-hf-buffer-m0531",
    Extension_Multiple: "30",
  },
  phusion_high_fidelity_master_mix_thermo: {
    Type: "pcr",
    Name: "Phusion High Fidelity Master Mix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0012771_Phusion_HiFi_PCR_MasterMix_100rxn_UG.pdf",
    Extension_Multiple: "30",
  },
  phusion_high_fidelity_neb: {
    Type: "pcr",
    Name: "Phusion® High-Fidelity (NEB)",
    Link: "https://www.neb.com/en-us/protocols/0001/01/01/pcr-protocol-m0530",
    Extension_Multiple: "30",
  },
  phusion_high_fidelity_thermo: {
    Type: "pcr",
    Name: "Phusion High Fidelity (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0012393_Phusion_HighFidelity_DNAPolymerase_UG.pdf",
    Extension_Multiple: "30",
  },
  phusion_hot_start_2_high_fidelity_master_mix_thermo: {
    Type: "pcr",
    Name: "Phusion 2 Hot Start High Fidelity Master Mix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0016316_Phusion_Hot_Start_II_High_Fidelity_PCR_Master_Mix_UG.pdf",
    Extension_Multiple: "30",
  },
  phusion_hot_start_2_high_fidelity_thermo: {
    Type: "pcr",
    Name: "Phusion 2 Hot Start High Fidelity (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0012401_Phusion_HotStartII_DNAPolymerase_500U_UG.pdf",
    Extension_Multiple: "30",
  },
  phusion_hot_start_2x_master_mix_neb: {
    Type: "pcr",
    Name: "Phusion® Hot Start Flex 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/08/27/protocol-for-phusion-hot-start-flex-2x-master-mix-m0536",
    Extension_Multiple: "30",
  },
  phusion_hot_start_neb: {
    Type: "pcr",
    Name: "Phusion® Hot Start Flex (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/06/protocol-for-phusion-hot-start-flex-dna-polymerase-m0535",
    Extension_Multiple: "30",
  },
  phusion_plus_master_mix_thermo: {
    Type: "pcr",
    Name: "Phusion Plus PCR Master Mix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0025052_phusion_plus_MM_UG.pdf",
    Extension_Multiple: "30",
  },
  phusion_plus_thermo: {
    Type: "pcr",
    Name: "Phusion Plus (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0025053_phusion_plus_UG.pdf",
    Extension_Multiple: "30",
  },
  platinum_2_hot_start_master_mix_thermo: {
    Type: "pcr",
    Name: "Invitrogen Platinum II Hot-Start 2X Master Mix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/BID/manuals/MAN0017535_Platinum_II_HS_PCR_MM_UG.pdf",
    Extension_Multiple: "15",
  },
  platinum_2_taq_hot_start_thermo: {
    Type: "pcr",
    Name: "Invitrogen Platinum II Taq Hot-Start (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/BID/manuals/MAN0017534_Platinum_II_Taq_HS_DNA_Pol_UG.pdf",
    Extension_Multiple: "15",
  },
  platinum_superfi_2_master_mix_thermo: {
    Type: "pcr",
    Name: "Invitrogen Platinum SuperFi II PCR Master Mix (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0018860_Platinum_SuperFi_II_PCR_MM_UG.pdf",
    Extension_Multiple: "30",
  },
  platinum_superfi_2_thermo: {
    Type: "pcr",
    Name: "Invitrogen Platinum SuperFi II (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0018859_Platinum_SuperFi_II_DNA_Pol_UG.pdf",
    Extension_Multiple: "30",
  },
  platinum_taq_high_fidelity_thermo: {
    Type: "pcr",
    Name: "Platinum Taq High Fidelity (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/0948_PlatinumTaqDNA_Polymerase_High_Fidelity.pdf",
    Extension_Multiple: "60",
  },
  q5_high_fidelity_2x_master_mix_neb: {
    Type: "pcr",
    Name: "Q5® High-Fidelity 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/12/07/protocol-for-q5-high-fidelity-2x-master-mix-m0492",
    Extension_Multiple: "30",
  },
  q5_high_fidelity_neb: {
    Type: "pcr",
    Name: "Q5® High-Fidelity (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2013/12/13/pcr-using-q5-high-fidelity-dna-polymerase-m0491",
    Extension_Multiple: "30",
  },
  q5_hot_start_high_fidelity_2x_master_mix_neb: {
    Type: "pcr",
    Name: "Q5® Hot Start High-Fidelity 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/08/30/protocol-for-q5-hot-start-high-fidelity-2x-master-mix-m0494",
    Extension_Multiple: "30",
  },
  q5_hot_start_high_fidelity_neb: {
    Type: "pcr",
    Name: "Q5® Hot Start High-Fidelity (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/08/30/pcr-using-q5-hot-start-high-fidelity-dna-polymerase-m0493",
    Extension_Multiple: "30",
  },
  taq_2x_master_mix_neb: {
    Type: "pcr",
    Name: "Taq with ThermoPol® Buffer 2X Master Mix (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/09/13/protocol-for-taq-2x-master-mix-m0270",
    Extension_Multiple: "60",
  },
  taq_2x_master_mix_thermo: {
    Type: "pcr",
    Name: "Taq 2X Master Mix",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0012622_PCR_Master_2X_K0171_UG.pdf",
    Extension_Multiple: "60",
  },
  taq_thermo: {
    Type: "pcr",
    Name: "Taq (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/0814_Taq_DNA_Polymerase_recombinant.pdf",
    Extension_Multiple: "90",
  },
  taq_thermopol_buffer_neb: {
    Type: "pcr",
    Name: "Taq with ThermoPol® Buffer (NEB)",
    Link: "https://www.neb.com/en-us/protocols/0001/01/01/taq-dna-polymerase-with-thermopol-buffer-m0267",
    Extension_Multiple: "60",
  },
  synthesis_to_order_idt_gene: {
    Type: "synthesis_to_order",
    Name: "IDT",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_genscript_gene: {
    Type: "synthesis_to_order",
    Name: "GenScript",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_twist_gene: {
    Type: "synthesis_to_order",
    Name: "Twist",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_genewiz_gene: {
    Type: "synthesis_to_order",
    Name: "Genewiz",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_thermo_gene: {
    Type: "synthesis_to_order",
    Name: "Thermo/GeneArt",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_eurofins_gene: {
    Type: "synthesis_to_order",
    Name: "Eurofins",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_idt_primer: {
    Type: "synthesis_to_order",
    Name: "IDT",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_genscript_primer: {
    Type: "synthesis_to_order",
    Name: "GenScript",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_genewiz_primer: {
    Type: "synthesis_to_order",
    Name: "Genewiz",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_thermo_plate_primer: {
    Type: "synthesis_to_order",
    Name: "Thermo/GeneArt (Plate)",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_thermo_tube_primer: {
    Type: "synthesis_to_order",
    Name: "Thermo/GeneArt (Tube)",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  synthesis_to_order_eurofins_primer: {
    Type: "synthesis_to_order",
    Name: "Eurofins",
    Link: "https://www.example1.com",
    Extension_Multiple: "60",
  },
  linker_ligation_thermo: {
    Type: "traditional",
    Name: "Linker Ligation (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0012736_Linker_Ligation_UG.pdf",
    Extension_Multiple: "60",
  },
  t4_sticky_end_dna_ligation_thermo: {
    Type: "traditional",
    Name: "T4 Sticky End DNA Ligation (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0011987_T4_DNA_Ligase_5_Weiss_1000_Weiss_U_UG.pdf",
    Extension_Multiple: "60",
  },
  t4_blunt_end_dna_ligation_thermo: {
    Type: "traditional",
    Name: "T4 Blunt End DNA Ligation (Thermo)",
    Link: "https://assets.thermofisher.com/TFS-Assets/LSG/manuals/MAN0011987_T4_DNA_Ligase_5_Weiss_1000_Weiss_U_UG.pdf",
    Extension_Multiple: "60",
  },
  instant_sticky_end_neb: {
    Type: "traditional",
    Name: "Instant Sticky-end Ligation (NEB)",
    Link: "https://www.neb.com/en-us/protocols/2012/08/27/protocol-transfer-master-mix-to-ice-prior-to-reaction-set-up-mix-tube-by-finger-flicking-before-u",
    Extension_Multiple: "60",
  },
  t4_dna_ligation_neb: {
    Type: "traditional",
    Name: "T4 DNA Ligation (NEB)",
    Link: "https://www.neb.com/en-us/protocols/0001/01/01/dna-ligation-with-t4-dna-ligase-m0202",
    Extension_Multiple: "60",
  },
  summary: {
    Type: "summary",
    Name: "Summary",
    Link: "https://labkick.ai",
    Extension_Multiple: "60",
  },
};

export const pcr_protocol_list = Object.entries(protocol_list)
  .filter(([_, protocol]) => protocol.Type === "pcr")
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});

export const digest_protocol_list = Object.entries(protocol_list)
  .filter(([_, protocol]) => protocol.Type === "digest")
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});

export const synthesis_to_order_protocol_list = Object.entries(protocol_list)
  .filter(([_, protocol]) => protocol.Type === "synthesis_to_order")
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});

export const gibson_protocol_list = Object.entries(protocol_list)
  .filter(([_, protocol]) => protocol.Type === "gibson")
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});

export const golden_gate_protocol_list = Object.entries(protocol_list)
  .filter(([_, protocol]) => protocol.Type === "golden_gate")
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});

export const traditional_protocol_list = Object.entries(protocol_list)
  .filter(([_, protocol]) => protocol.Type === "traditional")
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});

export const synthesis_to_order_gene_protocol_list = Object.entries(
  protocol_list,
)
  .filter(
    ([key, protocol]) =>
      protocol.Type === "synthesis_to_order" && key.endsWith("_gene"),
  )
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});

export const synthesis_to_order_primer_protocol_list = Object.entries(
  protocol_list,
)
  .filter(
    ([key, protocol]) =>
      protocol.Type === "synthesis_to_order" && key.endsWith("_primer"),
  )
  .reduce((acc, [key, { Name, Link }]) => {
    acc[key] = { Name, Link };
    return acc;
  }, {});
